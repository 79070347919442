import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import CategoryInputElementsCont from "./CategoryInputElementsCont";
import MapPickerInputCont from "./MapPickerInputCont";
import InputElementsCont from "./InputElementsCont";
import TabContainer from "./TabContainer";
import DetailTile from "./DetailTile";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import ImageCont from "./ImageCont";
import LoadSpinner from "./LoadSpinner";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  uploadBytes,
  getBytes,
  getBlob,
  getOrderDetailsLabelValue,
} from "../Utils";
import { UserDataContext } from "./Level1";
import DetailElementsCont from "./DetailElementsCont";
import ButtonBack from "./ButtonBack";

export default function DetailsCont(props) {
  console.log(props.passMap);
  const bottomContainer = (
    <BottomContainer
      backBtnClickHandler={() =>
        props.viewChangeHandler(props.passMap.backViewData)
      }
    />
  );

  return (
    <div className="grid grid-rows-cust-2c justify-items-center p-cust-topHead">
      <div className="w-5/6 grid grid-rows-cust-6 grid-flow-col pt-8">
        {props.passMap.labelNames.map((labelName, i) => (
          <DetailTile
            labelName={labelName}
            labelValue={getOrderDetailsLabelValue(
              labelName,
              props.passMap[props.passMap.labelDbKeys[i]],
              props.passMap
            )}
          />
        ))}
      </div>
      <div className="absolute bottom-10 w-full flex justify-center items-center">
      <ButtonBack backBtnClickHandler={()=>props.backBtnClickHandler({...props.passMap.backViewData,pageNo:props.passMap.pageNo})}/>
      </div>
    </div>
  );
}
