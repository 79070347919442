import React, { Component } from "react";
import RecordLabelUnit from "./RecordLabelUnit";
import BannerButton from "./BannerButton";
import { getStoreLabelValue } from "../Utils";

export default function RecordBanner2(props) {
  console.log(props);
  return (
    <div className="m-4 grid grid-cols-cust-2c rounded-lg bg-quaternary-light border-std items-center">
      <div className="h-full flex justify-center items-center text-4xl border-sl-no">
        {props.slNo}
      </div>
      <div className="h-full grid grid-rows-cust-2d">
        <div className="grid grid-cols-cust-4a">
          {props.labelNames.map((labelName, index) => {
            return (
              <RecordLabelUnit
                key={labelName}
                labelName={labelName}
                labelValue={getStoreLabelValue(
                  labelName,
                  props.listValues[index],
                  props.passDataMap2
                )}
              />
            );
          })}
        </div>
        {props.btnLabels.length > 0 && (
          <div className="px-4 text-right">
            {props.btnLabels.map((btnLabel, i) => (
              <BannerButton
                key={btnLabel}
                btnLabel={btnLabel}
                // btnData={props.btnData.btnDataMaps[i]}
                actionHandler={() =>
                  props.actionHandler({
                    actionName: btnLabel,
                    fnName: props.fnName,
                  })
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
