import React, { useEffect, useState } from "react";

import { getDbInpOptions } from "./projectFns";
import { styleMapProject } from "./viewProps";

export default function InputMultiCheckboxAsync(props) {
  console.log(props)

  const [selectOptionMaps,setSelectOptionMaps] = useState([])

  useEffect(()=>{
    (async () => {
      try {

        const dbSelectionSchemeMap = props.dbSelectionSchemeMap

        const selectionsFromDb = await getDbInpOptions(dbSelectionSchemeMap,props.inpValMap)

        console.log(selectionsFromDb)

        const labelKeyName = dbSelectionSchemeMap.selectOptionsLabelKey
        const valueKeyName = dbSelectionSchemeMap.selectOptionsValueKey

        const finalSelectionMaps = selectionsFromDb.map((selectionMap)=>{return {label:selectionMap[labelKeyName],value:selectionMap[valueKeyName]}})

        setSelectOptionMaps(finalSelectionMaps)

      } catch (err) {
        console.log(err)
        console.log('Error Initializing Page Data');
      }
    })();
  
  },[props.inpValMap]);


  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value
  return (
    <div className={`grid grid-cols-cust-2g gap-1 bg-${styleMapProject.inputElem.background} p-3`}>
      <div>{props.inpLabel}</div>
      <div className="flex gap-4">
        {selectOptionMaps.map((optionMap,i) => (
            <div >
                <label>{optionMap.value}</label>
                <input type="checkbox" checked={props.inpValues.includes(optionMap.value)} key={optionMap.value} onClick={(e)=>props.onChangeFn(e,optionMap.value)}/>
              </div>
          ))}
      </div>

    </div>
  );
}


function getFixedOptionLabel(inpValue,selectOptionMaps){

  for (const optionMap of selectOptionMaps){

    if(optionMap.value==inpValue){
      return optionMap.label
    }


  }
    return "NA"
}