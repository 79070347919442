import React from "react";

export default function ButtonSubmit(props) {
  return (
    <button
      className="btn-action btn-alert-high"
      onClick={() => props.submitClickHandler()}
    >
      {props.btnLabel}
    </button>
  );
}
