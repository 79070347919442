import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import image from "../images/skate-logo.png";

export default function Dialog(props) {

  console.log(props)

  return (
    <div className="flex justify-center items-center h-screen bg-loadingBack absolute top-0 w-screen ">
      <div className="h-72 grid grid-rows-3c bg-primary text-darkBackText relative rounded-lg p-4">
        <div className="w-16 h-3 bg-secondary absolute top-0 left-4"></div>
        <div className="text-xl mt-2">{props.passMap.title}</div>
        <div className="h-24 p-2 flex justify-center items-center">{props.passMap.message}</div>
        <div className=" flex justify-center items-center">
          {props.passMap.btnLabels.map((labelName)=>
             <button className="btn-action hover-white" onClick={()=>props.btnClickHandler(labelName)}>{labelName.toUpperCase()}</button>
          )}
        </div>
      </div>
    </div>
  );
}
