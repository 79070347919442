import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import FilterInput from "./FilterInput";
import BlankRecordStrip from "./BlankRecordStrip";
import RecordStrip2 from "./RecordStrip2";
import ImageCont from "./ImageCont";
import RecordBanner from "./RecordBanner";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  Timestamp,
  updateDoc,
  FieldPath,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  increment,
  arrayRemove,
  arrayUnion,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";

const pageLimit = 15;

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}

function getStripValuesColl(docs, dbNotations, stripValuesColl) {
  const rArray = [];
  docs.forEach((doc) => {
    const valueSeqs = doc.data().stringDataSeqs;
  });
}

function extractArray(docs, dbNotations) {
  const rArray = [];
  const valueSeqsArray = [];
  // dbNotations.forEach((item) => {
  //   const label = `${item}s`;
  //   rMap[label] = [];
  // });

  //const pageLimit = 3;

  docs.forEach((doc) => {
    const valueSeqs = doc.data().stringDataSeqs;

    valueSeqs.forEach((valueSeq, i) => {
      const values = valueSeq.split("^");
      valueSeqsArray.push(values);

      // values.forEach((value, i) => {
      //   const dbNotation = dbNotations[i];
      //   const key = `${dbNotation}s`;

      //   rMap[key].push(value);
      // });
    });
  });

  let tArray = [];

  valueSeqsArray.forEach((valueSeq, i) => {
    tArray.push(valueSeq);

    if ((i + 1) % pageLimit == 0) {
      rArray.push(tArray);
      tArray = [];
    }
  });

  console.log(rArray);

  return rArray;
}

export default function ButtonFilterStripListCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbNotations);
  console.log(props.passMap);

  const [stripValuesDataMap, setStripValuesDataMap] = useState({
    stripValuesSeqs: [],
    slNos: [],
  });
  const [stripFilteredValuesDataMap, setStripFilteredValuesDataMap] = useState({
    stripValuesSeqs: [],
    slNos: [],
  });
  const [stripFilteredValuesMapColl, setStripFilteredValuesMapColl] = useState(
    []
  );
  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  const [stripValueMapColl, setStripValueMapColl] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});
  const [pageStripValueMapColl, setPageStripValueMapColl] = useState([]);
  const [uidDocIdTabMap, setUidDocIdTabMap] = useState({});
  const [slNoArray, setSlNoArray] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    const collQuery = query(
      collection(db, `admin/accounts/unpaidInvoices`),
      where("docCreationDate", "!=", false),
      orderBy("docCreationDate", "desc")
    );

    const collQuery2 = query(
      collection(db, `admin/accounts/balances`),
      where("docCreationDate", "!=", false),
      orderBy("docCreationDate", "desc")
    );

    const invoiceDocs = await getDocs(collQuery);
    const invoiceRecordCount = invoiceDocs;

    if (props.listTopic == "balances") {
      const balanceDocs = await getDocs(collQuery2);
      setStripValueMapColl(
        extractAndSetValuesDataMap(balanceDocs, invoiceDocs)
      );
    } else {
      if (invoiceDocs.empty) {
        console.log("Hi");
        setPageStripValueMapColl([]);
      } else {
        setStripValueMapColl(extractAndSetValuesColl(invoiceDocs));
      }
    }
  }

  function extractAndSetValuesColl(sInvoiceDocs) {
    const tArray = [];

    let nMap = {};

    nMap["count"] = 0;

    const dbNotations = [
      "invoiceId",
      "invoiceIssueDate",
      "invoicePartyId",
      "invoicePartyName",
      "invoicePartyUid",
      "invoiceDesc",
      "invoiceAmount",
      "invoicePaymentDueDate",
    ];

    sInvoiceDocs.docs.forEach((doc) => {
      const tSubArray = [];
      const stringSeqDatas = doc.data().stringSeqDatas;

      const uids = [];
      const docIds = [];
      const map = {}; //for docid and uid relation

      stringSeqDatas.forEach((stringSeq) => {
        const tMap = {}; //each sequence data will be in the form of a map
        nMap["count"] += 1;

        //Creating a UID and DOC Id Array to facilitate invoice record transfer/deletion later on.
        const stringValues = stringSeq.split("^");
        uids.push(stringValues[dbNotations.indexOf("invoicePartyUid")]);
        docIds.push(doc.ref.id);

        //Converting the String Seq into a map
        dbNotations.forEach((dbNotation, i) => {
          tMap[dbNotation] = "";
          tMap[dbNotation] = stringValues[i];
        });

        tMap["stringSeqData"] = stringSeq;
        tMap["slNo"] = nMap["count"];
        tMap["docId"] = doc.ref.id;

        map["docIds"] = [...docIds];
        map["uids"] = [...uids];

        console.log(tMap);
        console.log(map);

        setUidDocIdTabMap({ ...map });

        tArray.push(tMap);
      });
    });
    console.log(tArray);

    if (tArray.length != 0) {
      displayPage(pageNo, tArray);
    }

    return tArray;
  }

  function extractAndSetValuesDataMap(sBalanceDocs, sInvoiceDocs) {
    const map = {};

    const dbNotations = ["partyUids", "partyIds", "partyNames"];

    dbNotations.forEach((dbNotation) => {
      map[dbNotation] = [];
    });

    map["partyBalances"] = [];

    sBalanceDocs.docs.forEach((doc) => {
      dbNotations.forEach((dbNotation) => {
        map[dbNotation] = doc.data()[dbNotation];
      });

      map.partyUids.forEach((partyUid) => {
        map["partyBalances"].push(doc.data()[partyUid]);
      });

      // map["partyUids"]=map["partyUids"].concat(doc.data().partyUids)
      // map["partyIds"]=map["partyIds"].concat(doc.data().partyIds)
      // map["partyNames"]=map["partyNames"].concat(doc.data().partyNames)
    });

    console.log(map);

    setRecordCount(map["partyUids"].length);

    const dbNotations2 = [
      "invoiceNos",
      "invoiceIssueDates",
      "partyIds",
      "partyNames",
      "partyUids",
      "invoiceDescs",
      "invoiceAmts",
      "invoicePaymentDueDates",
    ];

    const uidIndex = dbNotations2.indexOf("partyUids");
    const dueDateIndex = dbNotations2.indexOf("invoicePaymentDueDates");
    const invoiceAmtIndex = dbNotations2.indexOf("invoiceAmts");
    const issueDateIndex = dbNotations2.indexOf("invoiceIssueDates");

    // const stripValueNotations = [
    //   "partyIds",
    //   "partyNames",
    //   "latestBillDates",
    //   "unbilledBalances",
    //   "billedBalances",
    //   "dueBalances",
    //   "totalDueBalances",
    // ];

    const map2 = {};

    sInvoiceDocs.forEach((doc) => {
      const stringSeqs = doc.data().stringSeqDatas;
      const partyUids = doc.data().partyUids;

      const keyNotations = ["invStripValuesColl", "docIds"];

      if (stringSeqs.length == 0) {
      } else {
        stringSeqs.forEach((stringSeq) => {
          const stringArray = stringSeq.split("^");
          const partyUid = stringArray[uidIndex];

          if (Object.keys(map2).indexOf(partyUid) == -1) {
            map2[partyUid] = {};
            map2[partyUid]["invStripValuesColl"] = [];
            map2[partyUid]["partyStripSeqDatas"] = [];
            map2[partyUid]["partyStripSeqDocIds"] = [];
          }
          map2[partyUid]["invStripValuesColl"].push(stringArray);
          map2[partyUid]["partyStripSeqDatas"].push(stringSeq);
          map2[partyUid]["partyStripSeqDocIds"].push(doc.ref.id);
        });
      }
    });

    const fMap = {};
    const stripValueMapColl = [];

    map["partyUids"].forEach((partyUid, i) => {
      console.log(partyUid);
      console.log(map2);
      const tMap = {};
      tMap["partyUid"] = partyUid;
      tMap["dueBalance"] = 0;
      tMap["billedBalance"] = 0;
      tMap["unbilledBalance"] = 0;
      tMap["totalDueBalance"] = 0;
      tMap["partyStripSeqDatas"] = [];
      tMap["partyStripSeqDocIds"] = [];

      if (Object.keys(map2).indexOf(partyUid) == -1) {
        tMap["latestBillDate"] = "NA";
      } else {
        tMap["latestBillDate"] =
          parseInt(map2[partyUid]["invStripValuesColl"][0][issueDateIndex]) /
          1000;

        tMap["partyStripSeqDatas"] = [...map2[partyUid]["partyStripSeqDatas"]];
        tMap["partyStripSeqDocIds"] = [
          ...map2[partyUid]["partyStripSeqDocIds"],
        ];

        map2[partyUid]["invStripValuesColl"].forEach((invStripValues) => {
          const todaysDate = new Date().getTime();
          const issueDate = parseInt(invStripValues[issueDateIndex]);
          const dueDate = parseInt(invStripValues[dueDateIndex]);

          console.log(invStripValues[dueDateIndex]);

          if (issueDate > tMap["latestBillDate"]) {
            tMap["latestBillDate"] = issueDate;
          }

          console.log(dueDate, todaysDate);

          if (todaysDate > dueDate) {
            tMap["dueBalance"] += parseInt(invStripValues[invoiceAmtIndex]);
          } else {
            tMap["billedBalance"] += parseInt(invStripValues[invoiceAmtIndex]);
          }
        });

        tMap["totalDueBalance"] =
          parseInt(tMap["billedBalance"]) + parseInt(tMap["dueBalance"]);
      }

      tMap["unbilledBalance"] =
        parseInt(map["partyBalances"][i]) - parseInt(tMap["totalDueBalance"]);

      tMap["partyName"] = map["partyNames"][i];
      tMap["partyId"] = map["partyIds"][i];

      console.log(tMap);

      stripValueMapColl.push(tMap);
    });

    console.log(stripValueMapColl);

    displayPage(pageNo, stripValueMapColl);

    return stripValueMapColl;
  }

  console.log(pageNo);

  function displayPage(sPageNo, sStripValueMapColl) {
    setPageNo(sPageNo);
    console.log(sPageNo);
    const latestSl = sStripValueMapColl.length;
    // const pageLimit = 3;

    const startSlOfPage =
      pageLimit * (pageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (pageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * pageNo > latestSl ? latestSl : pageLimit * pageNo;

    assignStripValues(startSlOfPage, endSlOfPage, sStripValueMapColl);
  }

  async function fetchAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    stripValuesDataMap,
    pageNo
  ) {
    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
      ),
      where("slNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setStripValuesDataMap(
      extractAndSetValuesDataMap(dbDocs, stripValuesDataMap, pageNo)
    );
  }

  function assignStripValues(startSlNo, endSlNo, sStripValueMapColl) {
    const rArray = [];

    console.log(sStripValueMapColl);

    const mIndexStart = startSlNo - 1;
    const mIndexEnd = endSlNo - 1;

    for (let y = mIndexStart; y <= mIndexEnd; y++) {
      const tMap = { ...sStripValueMapColl[y] };
      tMap["slNo"] = y + 1;

      rArray.push(tMap);
    }

    console.log(rArray);

    setPageStripValueMapColl(rArray);
  }

  function renderPagination(recordCount, sValuesDataMap) {
    const pageCount = Math.ceil(recordCount / pageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(x + 1, sValuesDataMap)}
          className="btn-page"
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  function renderFilteredPagination(recordCount, valuesMapColl) {
    const pageCount = Math.ceil(recordCount / pageLimit);
    console.log(pageCount);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayFiltPage(x + 1, valuesMapColl)}
          className="btn-page"
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  async function displayPagesBasedOnFilter(sFilterName, sFilterValueMap) {
    setViewMode("filter");

    console.log("hellothere");

    const filterSchemeMap = { ...props.passMap.filterSchemeMap };

    const filterNameIndex = filterSchemeMap.filterNames.indexOf(sFilterName);
    const filterInputKeyNames =
      filterSchemeMap.filterInputKeys[filterNameIndex];

    const filterInputValue =
      sFilterName == "Store Branch Name"
        ? `${sFilterValueMap.storeBrandName}-${sFilterValueMap.storeBranchName}`
        : sFilterValueMap[filterSchemeMap.filterInputKeys[filterNameIndex][0]];

    const curFilterValMap = {
      filterName: sFilterName,
      filterDbKey: filterSchemeMap.filterDbKeys[filterNameIndex],
      filterInputKeys: filterSchemeMap.filterInputKeys[filterNameIndex],
      filterSeqIndex: filterSchemeMap.filterSeqIndices[filterNameIndex],
      filterInputValue: filterInputValue,
    };

    console.log(props.listTopic);

    const collQuery = query(
      collection(db, `admin/accounts/${props.listTopic}`),
      where(
        curFilterValMap.filterDbKey,
        "array-contains",
        curFilterValMap.filterInputValue
      )
    );

    const filtDbDocs = await getDocs(collQuery);

    console.log(filtDbDocs.size);
    setStripFilteredValuesMapColl(
      extractAndSetFilteredValuesMapColl(filtDbDocs, 1, curFilterValMap)
    );
  }

  function extractAndSetFilteredValuesMapColl(
    filtDbDocs,
    pageNo,
    sCurFilterValMap
  ) {
    const tArray = [];
    let nMap = {};

    nMap["count"] = 0;

    const dbNotations = [
      "invoiceId",
      "invoiceIssueDate",
      "invoicePartyId",
      "invoicePartyName",
      "invoicePartyUid",
      "invoiceDesc",
      "invoiceAmount",
      "invoicePaymentDueDate",
    ];

    filtDbDocs.docs.forEach((doc) => {
      const tSubArray = [];
      const stringSeqDatas = doc.data().stringSeqDatas;

      const uids = [];
      const docIds = [];
      const map = {}; //for docid and uid relation

      stringSeqDatas.forEach((stringSeq) => {
        const stringArray = stringSeq.split("^");

        if (
          stringArray[sCurFilterValMap.filterSeqIndex] ==
          sCurFilterValMap.filterInputValue
        ) {
          const tMap = {}; //each sequence data will be in the form of a map
          nMap["count"] += 1;

          //Creating a UID and DOC Id Array to facilitate invoice record transfer/deletion later on.
          const stringValues = stringSeq.split("^");
          uids.push(stringValues[dbNotations.indexOf("invoicePartyUid")]);
          docIds.push(doc.ref.id);

          //Converting the String Seq into a map
          dbNotations.forEach((dbNotation, i) => {
            tMap[dbNotation] = "";
            tMap[dbNotation] = stringValues[i];
          });

          tMap["stringSeqData"] = stringSeq;
          tMap["slNo"] = nMap["count"];
          tMap["docId"] = doc.ref.id;

          map["docIds"] = [...docIds];
          map["uids"] = [...uids];

          console.log(tMap);
          console.log(map);

          setUidDocIdTabMap({ ...map });

          tArray.push(tMap);
        }
      });
    });
    console.log(tArray);

    if (tArray.length != 0) {
      displayFiltPage(pageNo, tArray);
    }

    return tArray;

    // const tArray1 = [];
    // let tArray2 = [];

    // const rMap = {};
    // rMap["stripValuesSeqs"] = [];
    // rMap["slNos"] = [];

    // dbDocs.forEach((doc) => {
    //   const valueSeqs = doc.data().stringSeqDatas;

    //   console.log(doc.data());

    //   // valueSeqs.forEach((valueSeq, i) => {
    //   //   tArray1.push(valueSeq.split("^"));
    //   // });

    //   valueSeqs.forEach((valueSeq, i) => {
    //     const mIndex = sCurFilterValMap.filterSeqIndex;
    //     if (valueSeq.split("^")[mIndex] == sCurFilterValMap.filterInputValue) {
    //       const valueArray = valueSeq.split("^");

    //       valueArray.splice(4, 1); //Excluding partyUid data from the listValue Array

    //       tArray1.push(valueArray);
    //     }
    //   });

    //   //tArray2 = tArray2.concat(slNos);
    // });

    // rMap.stripValuesSeqs = [...tArray1];
    // //rMap.slNos = [...tArray2];

    // console.log(rMap);

    // displayFilteredPage(pageNo, rMap);

    // setRecordCount(rMap.stripValuesSeqs.length);

    // //displayFiltPage(pageNo, rMap);

    // //console.log(stripFilteredValuesDataMap);
    // return rMap;
  }

  function displayFiltPage(pageNo, valuesMapColl) {
    setPageNo(pageNo);
    console.log(pageNo);

    const rArray = [];

    //const size = valuesDataMap.stripValuesSeqs.length;
    const size = valuesMapColl.length;

    const startIndex =
      pageLimit * pageNo - pageLimit > size - 1
        ? size - 1
        : pageLimit * pageNo - pageLimit;
    const endIndex =
      startIndex + pageLimit - 1 > size - 1
        ? size - 1
        : startIndex + pageLimit - 1;

    console.log(startIndex, endIndex);

    if (startIndex == endIndex) {
      rArray.push(valuesMapColl[startIndex]);
    } else {
      for (let x = startIndex; x <= endIndex; x++) {
        rArray.push(valuesMapColl[x]);
      }
    }
    console.log([...rArray]);
    setPageStripValueMapColl([...rArray]);
  }

  async function btnClicked(data, tabMap) {
    const map = data.stripValueMap;
    switch (data.btnType) {
      case "Invoice":
        {
          console.log(map);
          const todaysDate = new Date().getTime();
          const dueDate = todaysDate + 1000 * 60 * 60 * 24 * 15; //Days x Milliseconds per day

          // const stringDataSeq = `${map["partyId"]}^${map["partyName"]}^${
          //   map["partyUid"]
          // }^${map["unbilledBalance"]}^${todaysDate / 1000}^${todaysDate / 1000}^${dueDate / 1000}`;

          const storeGenDetailDoc = await getDoc(
            doc(db, `storeBranches/${map["partyUid"]}`)
          );

          const storeEmail = storeGenDetailDoc.data().storeBranchEmail;

          const storeSettings = storeGenDetailDoc.data().settings;

          const storeAddress = `${storeSettings.storeBranchUnitNo}, ${storeSettings.storeBranchStreetName}, ${storeSettings.storeBranchCityName}, ${storeSettings.storeBranchProvinceName}, ${storeSettings.storeBranchCountryName} - ${storeSettings.storeBranchPostalCode}`;

          const storeContactNo = storeSettings.storeBranchContactNo;

          const payload = {
            partyId: map["partyId"],
            partyUid: map["partyUid"],
            partyName: map["partyName"],
            invoiceAmount: map["unbilledBalance"],
            invoiceBalanceDue: map["dueBalance"],
            invoiceSubject: "App Commission",
            invoicePartyAddress: storeAddress,
            invoicePartyEmail: storeEmail,
            invoicePartyContactNo: storeContactNo,
            invoicePartyContactName: "Roger",
          };

          const serverCall = httpsCallable(functions, "sendInvoice");
          const result = await serverCall(payload);
          alert(result.data);

          // const serverCall = httpsCallable(functions, "createMaster");
          // const result = await serverCall({});
        }
        break;
      case "Clear":
        {
          const dbCollQuery2 = query(
            collection(db, `admin/accounts/balances`),
            where("partyUids", "array-contains", map["partyUid"]),
            limit(1)
          );

          const balanceDoc = await getDocs(dbCollQuery2);
          const balanceDocId = balanceDoc.docs[0].ref.id;

          const docIds = data.stripValueMap.partyStripSeqDocIds;
          const stringSeqDatas = data.stripValueMap.partyStripSeqDatas;

          const mainInvoiceIds = [];

          stringSeqDatas.forEach((stringSeq) => {
            mainInvoiceIds.push(stringSeq.split("^")[0]);
          });

          const mainInvoiceCollQuery = query(
            collection(db, `admin/accounts/invoices`),
            where("invoiceId", "in", mainInvoiceIds)
          );

          const mainInvoiceDoc = await getDocs(mainInvoiceCollQuery);
          const mainInvoiceDocIds = [];

          mainInvoiceDoc.docs.forEach((doc, i) => {
            mainInvoiceDocIds.push(doc.ref.id);
          });

          const payload = {
            ...data.stripValueMap,
            balanceDocId: balanceDocId,
            mainInvoiceDocIds: mainInvoiceDocIds,
            mainInvoiceIds: mainInvoiceIds,
          };

          console.log(payload);

          const serverCall = httpsCallable(functions, "payInvoices");
          const result = await serverCall(payload);
          alert(result.data);

          // const dbCollQuery = query(
          //   collection(db, `admin/accounts/paidInvoices`),
          //   where("recordCount", "<", 100),
          //   limit(1)
          // );
          // const dbDocs = await getDocs(dbCollQuery);

          // const docIds = data.stripValueMap.partyStripSeqDocIds;
          // const stringSeqDatas = data.stripValueMap.partyStripSeqDatas;

          // console.log(stringSeqDatas);

          // if (dbDocs.empty) {
          //   map["paidInvoiceDocRef"] = doc(
          //     collection(db, "admin/accounts/paidInvoices")
          //   );
          //   setDoc(map["paidInvoiceDocRef"], {
          //     docCreationDate: serverTimestamp(),
          //     docModificationDate: serverTimestamp(),
          //     partyUids: arrayUnion(data.stripValueMap.partyUid),
          //     partyIds: arrayUnion(data.stripValueMap.partyId),
          //     partyNames: arrayUnion(data.stripValueMap.partyName),
          //     stringSeqDatas: arrayUnion(...stringSeqDatas),
          //     recordCount: increment(stringSeqDatas.length),
          //   });
          // } else {
          //   map["paidInvoiceDocRef"] = dbDocs.doc.ref;

          //   updateDoc(map["paidInvoiceDocRef"], {
          //     docModificationDate: serverTimestamp(),
          //     partyUids: arrayUnion(data.stripValueMap.partyUid),
          //     partyIds: arrayUnion(data.stripValueMap.partyId),
          //     partyNames: arrayUnion(data.stripValueMap.partyName),
          //     stringSeqDatas: arrayUnion(...stringSeqDatas),
          //     recordCount: increment(stringSeqDatas.length),
          //   });
          // }

          // const tDocIds = [];
          // const tStringSeqDatas = [];

          // docIds.forEach((docId, i) => {
          //   const mIndex = tDocIds.indexOf(docId);
          //   if (mIndex == -1) {
          //     tDocIds.push(docId);
          //     tStringSeqDatas.push([stringSeqDatas[i]]);
          //   } else {
          //     tStringSeqDatas[mIndex].push(stringSeqDatas[i]);
          //   }
          // });

          // console.log(tStringSeqDatas);

          // tDocIds.forEach(async (docId, i) => {
          //   const docRef = doc(db, `admin/accounts/unpaidInvoices/${docId}`);
          //   const recordSize = tStringSeqDatas[i].length;

          //   await updateDoc(docRef, {
          //     partyIds: arrayRemove(data.stripValueMap.partyId),
          //     partyNames: arrayRemove(data.stripValueMap.partyName),
          //     partyUids: arrayRemove(data.stripValueMap.partyUid),
          //     stringSeqDatas: arrayRemove(...tStringSeqDatas[i]),
          //     recordCount: increment(-recordSize),
          //     docModificationDate: serverTimestamp(),
          //   });
          // });

          // await updateDoc(balanceDocRef, {
          //   docModificationDate: serverTimestamp(),
          //   [map["partyUid"]]: increment(-data.stripValueMap.totalDueBalance),
          // });
        }
        break;

      case "Paid":
        {
          console.log(map);
          console.log(tabMap);

          const firstIndex = tabMap.uids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = tabMap.uids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          // const dbCollQuery = db
          //   .collection(`admin/accounts/balances`)
          //   .where("partyUids", "array-contains", payload.invoicePartyUid)
          //   .limit(1);

          // const balanceDocs = await dbCollQuery.get();

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDocs = await getDocs(dbCollQuery);
          const balanceDocId = balanceDocs.docs[0].ref.id;

          // const invoiceCollQuery = db
          //   .collection(`admin/accounts/invoices`)
          //   .where("invoiceId", "==", payload.invoiceId)
          //   .limit(1);

          // const invoiceDocs = await invoiceCollQuery.get();

          const invoiceCollQuery = query(
            collection(db, `admin/accounts/invoices`),
            where("invoiceId", "==", data.stripValueMap.invoiceId),
            limit(1)
          );

          const invoiceDocs = await getDocs(invoiceCollQuery);

          const invoiceDocId = invoiceDocs.docs[0].ref.id;

          const filteredIndices = []; //indices where the store in question has records in the specific doc ref.

          tabMap.uids.forEach((uid, i) => {
            if (
              uid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.docId == tabMap.docIds[i]
            ) {
              filteredIndices.push(i);
            }
          });

          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            invoiceDocId: invoiceDocId,
            balanceDocId: balanceDocId,
          };

          const serverCall = httpsCallable(functions, "payInvoice");
          const result = await serverCall(payload);
          alert(result.data);
        }
        break;

      case "Delete":
        {
          const firstIndex = tabMap.uids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = tabMap.uids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDocs = await getDocs(dbCollQuery);
          const balanceDocId = balanceDocs.docs[0].ref.id;

          const mainInvoiceDoc = await getDocs(
            query(
              collection(db, `admin/accounts/invoices`),
              where("invoiceId", "==", data.stripValueMap.invoiceId),
              limit(1)
            )
          );

          const mainInvoiceDocId = mainInvoiceDoc.docs[0].ref.id;

          const filteredIndices = [];

          tabMap.uids.forEach((uid, i) => {
            if (
              uid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.docId == tabMap.docIds[i]
            ) {
              filteredIndices.push(i);
            }
          });

          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            balanceDocId: balanceDocId,
            mainInvoiceDocId: mainInvoiceDocId,
          };

          const serverCall = httpsCallable(functions, "deleteInvoice");
          const result = await serverCall(payload);
          alert(result.data);
        }
        break;
      default: {
        console.log("No Options");
      }
    }
  }

  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  return (
    <React.Fragment>
      <div className="m-4 flex justify-center">
        <select onChange={(e) => setFilterName(e.target.value)}>
          {filterSchemeMap.filterNames.map((filterOptionName, i) => {
            return (
              <option key={i} value={filterOptionName}>
                {filterOptionName}
              </option>
            );
          })}
        </select>
        <div>
          <FilterInput
            filterName={filterName}
            filterSchemeMap={filterSchemeMap}
            filterValueMap={filterValueMap}
            inputChangeFn={(data) => inputChangeFn(data)}
          />
        </div>
        <button
          onClick={() => displayPagesBasedOnFilter(filterName, filterValueMap)}
        >
          Go
        </button>
      </div>
      <div className="bg-primary-100">
        <div
          className={`grid w-11/12 mx-auto mt-1 grid-cols-cust-2e font-open-sans text-sm`}
        >
          <div
            className={`title-strip grid w-full mx-auto ${
              props.listTopic == "balances"
                ? `grid-cols-cust-8a`
                : `grid-cols-cust-8b`
            } bg-secondary rounded-sm border border-borderStd `}
          >
            {props.passMap.labelNames.map((labelName, index) => {
              return (
                <div className="flex items-center justify-center title-strip-unit text-center text-darkBackText">
                  {labelName}
                </div>
              );
            })}
          </div>
          <div className="m-cust-2 flex items-center justify-center text-darkBackText bg-secondary text-center rounded-sm border border-borderStd ">
            Action
          </div>
        </div>
        {pageStripValueMapColl.length != 0 &&
          pageStripValueMapColl.map((stripValueMap) => {
            return (
              <RecordStrip2
                stripValueMap={stripValueMap}
                labelDbNotations={props.passMap.labelDbNotations}
                labelNames={props.passMap.labelNames}
                btnLabels={props.passMap.btnLabels}
                listTopic={props.listTopic}
                btnClickHandler={(data) => btnClicked(data, uidDocIdTabMap)}
              />
            );
          })}
        {pageStripValueMapColl.length == 0 && <BlankRecordStrip />}
      </div>
      <div className="flex mx-auto gap-5">
        {stripValueMapColl.length != 0 &&
          viewMode == "normal" &&
          renderPagination(recordCount, stripValueMapColl)}

        {stripValueMapColl.length != 0 &&
          viewMode == "filter" &&
          renderFilteredPagination(recordCount, stripFilteredValuesDataMap)}
      </div>
      {bottomContainer}
    </React.Fragment>
  );
}

function getDigitSerial(slNo, noOfDigits) {
  const slSize = slNo.toString().length;
  const reqSize = noOfDigits;
  const digitDiff = reqSize - slSize;

  if (digitDiff >= 0) {
    let prefix = "";

    for (let x = 0; x < digitDiff; x++) {
      prefix += "0";
    }
    return `${prefix}${slNo}`;
  } else {
    return slNo.toString();
  }
}

// if (firstIndex == lastIndex || filteredIndices.length == 1) {
//   updateDoc(
//     doc(
//       db,
//       `admin/accounts/unpaidInvoices/${data.stripValueMap.docId}`
//     ),
//     {
//       stringSeqDatas: arrayRemove(data.stripValueMap.stringSeqData),
//       partyIds: arrayRemove(data.stripValueMap.invoicePartyId),
//       partyUids: arrayRemove(data.stripValueMap.invoicePartyUid),
//       partyNames: arrayRemove(data.stripValueMap.invoicePartyName),
//       recordCount: increment(-1),
//       docModificationDate: serverTimestamp(),
//     }
//   );
// } else {
//   updateDoc(
//     doc(
//       db,
//       `admin/accounts/unpaidInvoices/${data.stripValueMap.docId}`
//     ),
//     {
//       stringSeqDatas: arrayRemove(data.stripValueMap.stringSeqData),
//       recordCount: increment(-1),
//       docModificationDate: serverTimestamp(),
//     }
//   );
// }

// updateDoc(balanceDocs.docs[0].ref, {
//   [data.stripValueMap.invoicePartyUid]: increment(
//     -parseInt(data.stripValueMap.invoiceAmount)
//   ),
//   docModificationDate: serverTimestamp(),
// });

// const invoiceCollQuery = query(
//   collection(db, `admin/accounts/invoices`),
//   where("invoiceId", "==", data.stripValueMap.invoiceId),
//   limit(1)
// );

// const invoiceDocs = await getDocs(invoiceCollQuery);

// const dbCollQuery = query(
//   collection(db, `admin/accounts/balances`),
//   where(
//     "partyUids",
//     "array-contains",
//     data.stripValueMap.invoicePartyUid
//   ),
//   limit(1)
// );

// const balanceDocs = await getDocs(dbCollQuery);

//   const dbCollQuery2 = query(
//     collection(db, `admin/accounts/paidInvoices`),
//     where("recordCount", "<", 100),
//     limit(1)
//   );

//   const paidInvoiceDocs = await getDocs(dbCollQuery2);

//   const dbCollQuery3 = query(
//     collection(db, `admin/accounts/transactions`),
//     where("recordCount", "<", 100),
//     limit(1)
//   );

//   const transactionDocs = await getDocs(dbCollQuery3);
//   console.log("PointA");
//   if (paidInvoiceDocs.empty) {
//     const newPaidInvoiceDocRef = doc(
//       collection(db, `admin/accounts/paidInvoices`)
//     );
//     setDoc(newPaidInvoiceDocRef, {
//       stringSeqDatas: arrayUnion(data.stripValueMap.stringSeqData),
//       invoicePartyIds: arrayUnion(data.stripValueMap.invoicePartyId),
//       invoicePartyUids: arrayUnion(data.stripValueMap.invoicePartyUid),
//       invoicePartyNames: arrayUnion(
//         data.stripValueMap.invoicePartyName
//       ),
//       recordCount: increment(1),
//       docCreationDate: serverTimestamp(),
//       docModificationDate: serverTimestamp(),
//     });
//   } else {
//     updateDoc(paidInvoiceDocs.docs[0].ref, {
//       stringSeqDatas: arrayUnion(data.stripValueMap.stringSeqData),
//       invoicePartyIds: arrayUnion(data.stripValueMap.invoicePartyId),
//       invoicePartyUids: arrayUnion(data.stripValueMap.invoicePartyUid),
//       invoicePartyNames: arrayUnion(
//         data.stripValueMap.invoicePartyName
//       ),
//       recordCount: increment(1),
//       docModificationDate: serverTimestamp(),
//     });
//   }

//   //Registering Transaction
//   const adminAccountGenDetailsDocRef = doc(db, `admin/accounts/`);

//   const adminAccountGenDetailsDoc = await getDoc(
//     adminAccountGenDetailsDocRef
//   );
//   const latestTransactionSl =
//     adminAccountGenDetailsDoc.data().latestTransactionSl;

//   const newTransactionSl = getDigitSerial(latestTransactionSl + 1, 6);

//   const newTransactionId = "TR" + newTransactionSl;
//   const transactionStringSeq = `${newTransactionId}^${
//     new Date().getTime() / 1000
//   }^${data.stripValueMap.invoicePartyId}^${
//     data.stripValueMap.invoicePartyName
//   }^${data.stripValueMap.invoicePartyUid}^Payment of Invoice No:${
//     data.stripValueMap.invoiceId
//   }^${data.stripValueMap.invoiceAmount}^Cash/Card`;

//   if (transactionDocs.empty) {
//     const newTransactionDocRef = doc(
//       collection(db, `admin/accounts/transactions`)
//     );

//     setDoc(newTransactionDocRef, {
//       stringSeqDatas: arrayUnion(transactionStringSeq),
//       transactionPartyIds: arrayUnion(
//         data.stripValueMap.invoicePartyId
//       ),
//       transactionPartyUids: arrayUnion(
//         data.stripValueMap.invoicePartyUid
//       ),
//       transactionPartyNames: arrayUnion(
//         data.stripValueMap.invoicePartyName
//       ),
//       recordCount: increment(1),
//       docCreationDate: serverTimestamp(),
//       docModificationDate: serverTimestamp(),
//     });
//   } else {
//     updateDoc(transactionDocs.docs[0].ref, {
//       stringSeqDatas: arrayUnion(transactionStringSeq),
//       transactionPartyIds: arrayUnion(
//         data.stripValueMap.invoicePartyId
//       ),
//       transactionPartyUids: arrayUnion(
//         data.stripValueMap.invoicePartyUid
//       ),
//       transactionPartyNames: arrayUnion(
//         data.stripValueMap.invoicePartyName
//       ),
//       recordCount: increment(1),
//       docModificationDate: serverTimestamp(),
//     });
//   }

//   if (firstIndex == lastIndex || filteredIndices.length == 1) {
//     updateDoc(
//       doc(
//         db,
//         `admin/accounts/unpaidInvoices/${data.stripValueMap.docId}`
//       ),
//       {
//         stringSeqDatas: arrayRemove(data.stripValueMap.stringSeqData),
//         partyIds: arrayRemove(data.stripValueMap.invoicePartyId),
//         partyUids: arrayRemove(data.stripValueMap.invoicePartyUid),
//         partyNames: arrayRemove(data.stripValueMap.invoicePartyName),
//         recordCount: increment(-1),
//         docModificationDate: serverTimestamp(),
//       }
//     );
//   } else {
//     updateDoc(
//       doc(
//         db,
//         `admin/accounts/unpaidInvoices/${data.stripValueMap.docId}`
//       ),
//       {
//         stringSeqDatas: arrayRemove(data.stripValueMap.stringSeqData),
//         recordCount: increment(-1),
//         docModificationDate: serverTimestamp(),
//       }
//     );
//   }

//   updateDoc(invoiceDocs.docs[0].ref, {
//     invoiceStatus: "paid",
//     docModificationDate: serverTimestamp(),
//   });

//   updateDoc(balanceDocs.docs[0].ref, {
//     [data.stripValueMap.invoicePartyUid]: increment(
//       -parseInt(data.stripValueMap.invoiceAmount)
//     ),
//     docModificationDate: serverTimestamp(),
//   });
