import { useState } from "react"
import BtnNormalModal from "./BtnNormalModal"
import Loader2 from "./Loader2"
import { getBtnColorBasedOnBtnAction } from "./projectFns"

export default function ModalBoxOpConfPrompt6(props) {

    const [showMessage,setShowMessage]= useState(false)
    const [opCompletionMessage,setOpCompletionMessage] = useState("")
    const [opCompletedCond,setOpCompletedCond] = useState(false)
    const [opInProgressCond,setOpInProgressCond] = useState(false)
    const [opResult,setOpResult] = useState({})
  
    console.log(props) 
  
  
    async function opFunction(fn){
  
      setOpInProgressCond(true);
  
      const result = await fn();

      console.log(result)
      
      setOpInProgressCond(false);
      setOpCompletedCond(true);

      setOpResult(result)
      
      if(result.status){
        setOpCompletionMessage(props.passMap.opSuccessMsg ? props.passMap.opSuccessMsg : result.message)

      }else{
        console.log(result.errorMessage)
        setOpCompletionMessage(result.message)
      }
  
  
      setShowMessage(true)
  
    }
  
    function promptCloseFn(actionType,opCompletionStatus,opResult){
  
      if(opCompletionStatus){
        props.passMap.afterOpCompletionFn(opResult)
        // props.opCompletionHandler(props.passMap.afterOpCompletionFn)
      }
  
      // if(actionType=="delete"){
      //   props.opCompletionHandler(props.passMap.actionType,opCompletionStatus)
      // }
  
      props.promptClose()
    }
  
    return (
      <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack z-50">
        <div className="grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-4 px-4 gap-y-24 items-center rounded-lg">

          <div className="h-full w-fit flex mx-auto pt-4 px-8 text-2xl font-bold">
              {opCompletedCond ? "Result" : opInProgressCond ? "In Progress" : "Confirmation"}
          </div>
            
  
            {!opCompletedCond && !opInProgressCond && <div className="flex items-center justify-center text-center text-lg">{props.passMap.message}</div>}
  
            {opCompletedCond && !opInProgressCond && <div className="flex items-center justify-center text-center  text-lg">{opCompletionMessage}
            </div>}
  
            {!opCompletedCond && opInProgressCond && <div className="flex-center"><Loader2/></div>}
  
          <div className="w-full">
            <div className="flex items-center justify-center gap-4">
              {!showMessage && 
                    <BtnNormalModal label={props.passMap.opBtnLabel} color={getBtnColorBasedOnBtnAction(props.passMap.opBtnAction)} clickHandler={async () => await opFunction(props.passMap.opFn)} />
                }
              {/* <button className="btn-action-sm hover-black" onClick={() => promptCloseFn(props.passMap.actionType,opCompletedCond)}> */}
              <BtnNormalModal label={`${showMessage ? `OK` : `Back`}`} imgWidth={20} svgColorClass="f-color-white" clickHandler={()=>promptCloseFn(props.passMap.actionType,opCompletedCond,opResult)} color="black"/>
              {/* {showMessage ? "Ok" : "Close"}
            </button> */}
            </div>
          </div>
        </div>
      </div>
    )
  }