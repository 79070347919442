import { convertJSDateStrToFbTimestampInEventObj, getDateStrFromJSDate } from "./genFunctions"

export default function AttendanceCont(props){
    console.log(props)


    return(<div className="block h-full p-6">
        <div className="flex flex-center gap-8">
            <div>
                <span>Total Classes: </span>
                {props.editCond && 
                    <input type="number"
                        value={props.totalClassCount}
                        onChange={e=>props.classCountChangeHandler(e.target.value)}
                    /> 
                }
                {!props.editCond &&  
                    <span className="font-bold">{props.totalClassCount}</span>
                }
            </div>
            <div>
                <span>Attendance Date: </span>
                {props.editCond && 
                    <input type="date"
                        value={props.attendanceDate ? getDateStrFromJSDate(props.attendanceDate.toDate(),"protocol") : null}
                        onChange={e=>props.attendanceDateChangeHandler(convertJSDateStrToFbTimestampInEventObj(e).target.value)}
                    />
                }
                {!props.editCond &&  
                    <span className="font-bold">{props.attendanceDate ? getDateStrFromJSDate(props.attendanceDate.toDate()) : "NA"}</span>
                }
            </div>

         </div>
         <div className="flex flex-wrap h-full flex-wrap flex-col overflow-x-auto gap-4 p-4">
        {props.students.length > 0 && props.students.map((student,i)=>{
            if(props.editCond){

                return(
                    <div className="grid-cols-cust-4g grid h-8 w-cust-25rem bg-primary rounded">
                    <div className="text-darkBackText flex items-center px-2 ">{student.studentRollNo}</div>
                    <div className="text-darkBackText flex items-center px-2 ">{student.name}</div>
                    <input type="number"
                        value={student.attendance}
                        max={props.totalClassCount}
                        min={0}
                        onChange={e=>props.attendanceChangeHandler({event:e,student:student,studentIndex:i,totalClassCount:props.totalClassCount})}
                    />
                    <div className="text-sm flex flex-center text-darkBackText">{student.attendanceAvg ? student.attendanceAvg : ""}</div>
                    </div>
                )

            }else{
                return(
                    <div className="grid-cols-cust-3i grid h-8 w-cust-25rem bg-primary rounded">
                    <div className="text-primary flex items-center px-2 border-r bg-secondary rounded-l">{student.studentRollNo}</div>
                    <div className="text-darkBackText flex items-center px-2 ">{student.name}</div>
                    <div className="flex flex-center text-secondary">{student.attendance ? student.attendance : ""}</div>
                    </div>
                )
            }
        }

            )}
            {props.students.length==0 && <div className="flex flex-center h-full text-3xl">No Records</div>}
        </div>
    </div>)
}