import React from "react";

//Used in Manage Customers  26.03.24
export default function RecordLabelUnit4(props) {

  const textColor = props.textColorScheme=="green" ? "text-accent-green" : "text-secondary-2"
  return (
    <div className="p-2 h-20 grid grid-cols-cust-2d bg-primary-400 items-center">
      <div className="text-sm text-left">{props.labelName}</div>
      <div className={`font-medium ${textColor} font-montserrat break-words`}>{props.labelValue}</div>
    </div>
  );
}
