import React from "react";
import { getBtnImgSize } from "./HelperViewFns";

export default function FunctionButton(props) {
  /*     function clickFunction(){
        props.onClickFnIntermediate();
    } */

  return (
    <div
      onClick={() => props.clickHandler()}
      className="relative flex flex-col items-center justify-center text-center h-36 w-36 rounded-lg cursor-pointer bg-primary-50 hover:bg-primary-200"
    >
      {/* <ButtonIcon Image={props.btnImage} section="Dashboard Function" /> */}
      <img className={`${getBtnImgSize("Dashboard Function")} m-2 svg-${props.btnColor}`} src={props.btnImage}/>
      <p className="text-sm">{props.btnText}</p>
      {parseInt(props.btnNotification) > 0 && (
        <p className="absolute top-2 right-3 w-6 h-6 rounded-xl text-darkBackText bg-secondary">
          {props.btnNotification.toString()}
        </p>
      )}
    </div>
  );
}
