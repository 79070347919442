import React, { Component } from "react";
import RecordLabelUnit from "./RecordLabelUnit";
import ActionButton from "./ActionButton";
import { getStoreLabelValue, getDate, getStoreLabelValueAdmin } from "../Utils";

export default function RecordStrip2(props) {
  console.log(props);

 // const stripColWidth = props.listTopic=="transactionAdmin"?`grid-columns-7a`:`grid-cols-cust-7b`;

  function checkAndReturnValue(labelName,value,settingsObj) {

    if(props.listTopic.includes("Admin")){

     return getStoreLabelValueAdmin(labelName,
        value,"CAD")

    }else{
     return getStoreLabelValue(
        labelName,
        value,
        settingsObj
      )
    }

  }

  
  return (
    // <div className="w-11/12 mt-1 mx-auto">
      <div
        className={`title-strip grid mx-auto mt-1 ${props.stripColWidth}
         bg-quartenaryLight rounded-sm border border-borderStd`}
      >
        {props.labelDbKeys.map((dbKeys, i) => {
          return (
            <div className="value-strip-unit text-center text-lightBackText">
              {checkAndReturnValue(
                props.labelNames[i],
                props.stripValueMap[dbKeys],
                props.passDataMap2
              )}
            </div>
          );
        })}
      </div>

  );
}

// typeof props.passDataMap[props.labelDbNotations[index]] == "object"
//   ? props.passDataMap[props.labelDbNotations[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbNotations[index]]


      {/* <div className="m-cust-2 grid grid-cols-2">
        {props.btnLabels.map((btnLabelName, i) => (
          <button
            className="btn-strip"
            onClick={() =>
              props.btnClickHandler({
                btnType: btnLabelName,
                stripValueMap: props.stripValueMap,
              })
            }
          >
            {btnLabelName}
          </button>
        ))}
      </div> */}
    {/* </div> */}