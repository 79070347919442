import { ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import {
  Timestamp,
  arraySortAscending,
  child,
  database,
  db,
  doc,
  functions,
  get,
  getValue,
  httpsCallable,
  remoteConfig,
  runTransaction,
  serverTimestamp,
  update
} from "../Utils";
import { ReactComponent as EmployeeIcon } from "../images/mechanic.svg";
import { ReactComponent as ToolIcon } from "../images/tool.svg";
import ButtonBack from "./ButtonBack";
import ButtonIconTitle from "./ButtonIconTitle";
import ButtonSubmit from "./ButtonSubmit";
import LoadSpinner from "./LoadSpinner";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxMessagePromptWithMultipleOps from "./ModalBoxMessagePromptWithMultipleOps";

export default function FnSelectCont2(props) {
  console.log(props);
  const [displaySelections, setDisplaySelections] = useState(true);
  const [selectionTopic, setSelectionTopic] = useState(props.passMap.topic);
  const [selectionMapColl, setSelectionMapColl] = useState([
    { name: "", uid: props.passMap.uid, category: props.passMap.category },
  ]);
  const [markedItems, setMarkedItems] = useState([]);
  const [selAccountMap, setSelAccountMap] = useState({});
  const [showModalBox, setShowModalBox] = useState(false);
  const [selColorClass, setSelColorClass] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showPromptOp, setShowPromptOp] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [sMap, setSMap] = useState({});

  useEffect(() => {
    initializeSelections(props.passMap);
  }, []);

  async function initializeSelections(map) {
    console.log(map);
    switch (map.topic) {
      case "Mark Attendance":
        {
          const employeesMap = await get(
            child(ref(database), `employees/${map.uid}`)
          );

          const mapColl = [];

          Object.keys(employeesMap.val()).forEach((keyName) => {
            const map = {};
            map["name"] = employeesMap.val()[keyName].name;
            map["isAbsent"] = employeesMap.val()[keyName].isAbsent;
            map["isDisabled"] = employeesMap.val()[keyName].isDisabled;
            map["uid"] = keyName;
            map["category"] = "Employee";
            map["btnColorClass"] = map["isAbsent"] ? "" : "svg-red";

            mapColl.push(map);
          });

          console.log(mapColl);

          setSelectionMapColl([...mapColl]);
        }
        break;
    }
  }

  function selectionFn(e, map) {


    if (props.passMap.topic == "Mark Attendance") {
      setShowModalBox(true);
      setSelColorClass("svg-red");

      const cSelectionMapColl = [...selectionMapColl];
      const tArray = [];
      cSelectionMapColl.forEach((cMap, i) => {
        if (cMap.uid == map.uid) {
          cMap.isAbsent = !map.isAbsent;
          cMap.btnColorClass = cMap.isAbsent ? "" : "svg-red";
        }
        tArray.push(cMap);
      });

      setSelectionMapColl([...tArray]);
    }

    //setSelAccountMap(map);
  }

  async function disableAccount(map) {
    const serverCall = httpsCallable(functions, "disableUser");
    const result = await serverCall({ uid: map.uid });
    alert(result.data);
  }




  async function submitClickHandle() {
    setShowSpinner(true)
    const mapColl = [...selectionMapColl];
   
    const enforceServerCond = getValue(remoteConfig,"enforce_server_functions").asBoolean();

    if(enforceServerCond){

      const dbData={
        mapColl:mapColl,
        storeBranchUid:props.passMap.uid,

      }

      const cloudFnCall = httpsCallable(functions, "markAttendance");
      const result = await cloudFnCall(dbData);
      displayPrompt(result.data);
      console.log(result.data);
      setShowSpinner(false);
      
    }else{


    const tMap = {};
    tMap["empAvlCount"]=0
    let count=0
    const updates = {};

    for(const map of mapColl){

      updates[`employees/${props.passMap.uid}/${map.uid}/isAbsent`] = map.isAbsent;
      // count=!map.isAbsent?count++:count;
      tMap["empAvlCount"] = map.isAbsent ? tMap["empAvlCount"]:tMap["empAvlCount"]+1;
      //count = map.isAbsent? count:count+1;
    }

    console.log(`count = ${count}`)
    console.log(` tMap["empAvlCount"] = ${ tMap["empAvlCount"]}`)

   //tMap["empAvlCount"] = count

   console.log(count)

   let capacity=0;

console.log("start3")

let estdWaitTime = 0

    update(ref(database), updates)
      .then( async () => {

        let count = 0;

        let waitTimeDbDocNames = ["waitTimeData","waitTimeDataProfile"] ;
        
     for await (const waitTimeDbDocName of waitTimeDbDocNames){

          const i = waitTimeDbDocNames.indexOf(waitTimeDbDocName);

           runTransaction(db, async (t) => {

          const waitTimeDoc = await t.get(
            doc(db,`storeBranches/${props.passMap.uid}/storeBranchGenDetails/${waitTimeDbDocName}`)
          );
          const waitTimeData = waitTimeDoc.data();
          console.log("start4")
          //const empCount = waitTimeData.employeesAvailableCount;
          console.log(` count = ${ count}`)
          console.log(` tMap["empAvlCount"] = ${ tMap["empAvlCount"]}`)
          //Condition if tools are in consideration for wait times
          const toolsConsideredCond = false;

          const toolCount = waitTimeData.toolsAvailableCount
          const empCount = tMap["empAvlCount"]
          capacity = (toolsConsideredCond)?(empCount < toolCount ? empCount : toolCount):empCount

            
          let allJobWaitTimes = waitTimeData.queueJobTimes;
          const allOrderStatuses = waitTimeData.queueOrderStatuses;

          if(capacity==0){

            tMap["finRefTimestamp"]=Timestamp.now()

          }else{            
              if(allJobWaitTimes.length==0){
            
                  tMap["estdWaitTime"]=0;
                  tMap["minCapTime"]=0;
                  tMap["finRefTimestamp"]=Timestamp.now()

              }else{
            
                    //const noOfJobs = allJobWaitTimes.length==0 ? 0 : jobTimes.length;

                    const noOfJobs = allJobWaitTimes.length;
                    const refTime = waitTimeData.capacityRefTime;
                    //const refTime = waitTimeData.capacityRefTime==undefined?Timestamp.now():waitTimeData.capacityRefTime;
                    const timeDiff = Timestamp.now().toMillis() - refTime.toMillis();

                    const minutesDiff = timeDiff / 60000;
                    console.log(minutesDiff);

                    const isWithinCapacity = waitTimeData.queueJobTimes.length<capacity;

                    console.log(`allJobWaitTimes = ${allJobWaitTimes}`)
                    console.log(`capacity = ${capacity}`)


                    const withinCapWaitTimes = isWithinCapacity?[...allJobWaitTimes]:allJobWaitTimes.slice(0,capacity);

                    console.log(`withinCapWaitTimes = ${withinCapWaitTimes}`)
                    console.log(`tMap["empAvlCount"] = ${tMap["empAvlCount"]}`)

                    let updatedCapWaitTimes = withinCapWaitTimes.map((latestWaitTimeRecorded,i) => {

                      if(allOrderStatuses[i]=="accepted"){ 
                        //If orderstatus is still in accepted, then time deduction will not be applicable and hence this condition. 
                        //jobStages[0] is nothing but orderstatus , mentioned so for understanding
                        return latestWaitTimeRecorded
                      }else{

                        if (latestWaitTimeRecorded - minutesDiff < allJobWaitTimes[i]*0.5 ) {  
                          return allJobWaitTimes[i]*0.5;
                        } else {
                          return latestWaitTimeRecorded - minutesDiff;
                        }

                      }
                  })


            

                  allJobWaitTimes=isWithinCapacity?[...updatedCapWaitTimes]:updatedCapWaitTimes.concat(allJobWaitTimes.slice(capacity))
                



                    let queueBlocks = [];
                    let tArray = [];
                    let counter=0;

                        allJobWaitTimes.forEach((it, i) => {
                          counter++;
                          tArray.push(it);
                  
                          if (counter % capacity == 0 ) {
                            queueBlocks.push(tArray);
                            tArray = [];
                            counter=0;
                          }else if(allJobWaitTimes.length==i+1){
                            queueBlocks.push(tArray);
                          }
                        })

        

                    const queueBlocks2 = [...queueBlocks];



                    const batchIndex = Math.floor(noOfJobs / capacity);

                    let tMinWaitTimes = [...queueBlocks2[0]];

                    for (let x = 1; x < batchIndex; x++) { // We iterate upto batchIndex-1, because in the end there is no ascending sort, if there was ascending sort in the end then we should do to batch index-2 and then add the minTime Array with the batchIndex-1 array and then asc sort it 
                      const tArr1 = [...tMinWaitTimes]; //tArr1 is the block array which has consolidated wait time 
                      const tArr2 = [...queueBlocks2[x]]; //tArr2  is the next block which will be added and made to a single array - see fArr

                      const ascArr1 = arraySortAscending(tArr1);

                      const fArr = ascArr1.map((it, i) => {
                        return it + tArr2[i];  //Adding the array elements. 
                      });

                      tMinWaitTimes = [...fArr];
                    }

                    const minWaitTimes = arraySortAscending([...tMinWaitTimes]);

                    console.log(arraySortAscending(minWaitTimes));

                    const isFutureOrderWithinCapacity = allJobWaitTimes.length < capacity; //This will determine if estd wait time will be applicable or not*did this  as i feel allJobWaitTimes.length does not include subsequent future order
                    const posIndex = allJobWaitTimes.length % capacity; //Position Index is the Index of the Next Order in the queue, to find the minimum wait time

                    tMap["estdWaitTime"] = isFutureOrderWithinCapacity?0:arraySortAscending(minWaitTimes)[posIndex];
                  // const isWithinCapacity = noOfJobs / capacity <= 1;

                    estdWaitTime = tMap["estdWaitTime"]

                    const inCapacityWaitTimes = isFutureOrderWithinCapacity?0:allJobWaitTimes.slice(0,capacity)
                    tMap["minCapTime"] = isFutureOrderWithinCapacity?0:arraySortAscending(inCapacityWaitTimes)[0];
                    // const outOfCapJobTimes =
                    //   jobTimes.length > capacity ? [...jobTimes.slice(capacity)] : [];
                    // tMap["finJobTimes"] = [...finCapArray, ...outOfCapJobTimes];
                    tMap["finRefTimestamp"] = serverTimestamp()
                      // allJobWaitTimes.length > capacity ? refTime : Timestamp.now();
                  }
          }

              t.update(
                doc(
                  db,
                  `storeBranches/${props.passMap.uid}/storeBranchGenDetails/${waitTimeDbDocName}`
                ),
                {
                  employeesAvailableCount: tMap["empAvlCount"],
                  capacityRefTime:  tMap["finRefTimestamp"],
                  queueJobTimes:allJobWaitTimes,
                  docModificationDate: serverTimestamp(),
                }
              );

                  return estdWaitTime

            }).then(async (estdWaitTime) => {

              const waitTimeDbNode = waitTimeDbDocName == "waitTimeData" ? "waitTimes" : "waitTimesProfile";

              if(capacity!=0){
                console.log("pass wait time is",estdWaitTime)
                console.log("object wait time is",tMap["estdWaitTime"])
                await update(ref(database, `${waitTimeDbNode}/${props.passMap.uid}/`), {
                  estdWaitTime: estdWaitTime,
                  minCapTime: tMap["minCapTime"],
                  refTime: Timestamp.now().toMillis(),
                });
                count++;

                if(count==waitTimeDbDocNames.length){
                displayPrompt("Attendance Marked Successfully");
                }
                setShowSpinner(false)
              }else{
                count++;
                if(count==waitTimeDbDocNames.length){
                  displayPrompt("Attendance Marked Successfully");
                }
                setShowSpinner(false)
              }
        
            });
        
        }})
            .catch((error) => {
              displayPrompt(`Error:${error.message}`)
              setShowSpinner(false)
         });

    }

  }

  function displayPrompt(message,title,status){
    setShowSpinner(false)
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message
    })
  }



  const bottomContainer = (
    <div className="flex justify-center items-center gap-4">
      <ButtonSubmit
        btnLabel="Submit"
        btnAlertLevel="High "
        submitClickHandler={() => submitClickHandle()}
      />
      <ButtonBack backBtnClickHandler={() => props.backBtnClickHandler()} />
    </div>
  );

  return (
    <div className="h-screen grid grid-rows-cust-2c p-cust-topHead pb-28 px-6">
      <div className="relative flex gap-4 items-center justify-center rounded bg-quaternary-light border-std">
      <div className="flex flex-wrap items-center justify-center gap-8">
        {selectionMapColl.map((map, i) => {
          return (
            <ButtonIconTitle
              className=""
              btnColorClass={map.btnColorClass}
              section="Selection"
              btnTitle={map.name}
              btnIconSize="h-20 w-20"
              btnIcon={getIcon(map.category)}
              clickHandler={(e) => selectionFn(e, map)}
            ></ButtonIconTitle>
          );
        })}
        </div>
        <div className="absolute flex items-center gap-4 bottom-10 right-10">
          <div className="flex items-center gap-2"><span class="solid-circle bg-primary"></span> ABSENT</div>
          <div className="flex items-center gap-2"><span class="solid-circle bg-secondary"></span> PRESENT</div>
        </div>
      </div>
      <div className="absolute bottom-0 h-24 w-full flex justify-center items-center gap-4">
        <ButtonSubmit
          btnLabel="Update"
          btnAlertLevel="High"
          submitClickHandler={() => submitClickHandle()}
        />
        <ButtonBack backBtnClickHandler={() => props.backBtnClickHandler()} />
      </div>
      {showPromptOp && <ModalBoxMessagePromptWithMultipleOps passMap={promptMap} promptClose={()=>setShowPromptOp(false)}/>}
      {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
      {showSpinner && <LoadSpinner/>}
    </div>
  );
}

function getIcon(category) {
  switch (category) {
    case "Employee": {
      return EmployeeIcon;
    }
    case "Tool": {
      return ToolIcon;
    }
  }
}


