import React, { useEffect, useState } from "react";
import { getDbInpOptions } from "./projectFns";
import { styleMapProject } from "./viewProps";

export default function InputListAsync(props) {

  const [selectOptionMaps,setSelectOptionMaps] = useState([])

  useEffect(()=>{
    (async () => {
      try {
        const dbSelectionSchemeMap = props.dbSelectionSchemeMap

        const selectionsFromDb = await getDbInpOptions(dbSelectionSchemeMap)

        console.log(selectionsFromDb)

        const labelKeyName = dbSelectionSchemeMap.selectOptionsLabelKey
        const valueKeyName = dbSelectionSchemeMap.selectOptionsValueKey

        const finalSelectionMaps = selectionsFromDb.map((selectionMap)=>{return {label:selectionMap[labelKeyName],value:selectionMap[valueKeyName]}})

        setSelectOptionMaps(finalSelectionMaps)

      } catch (err) {
        console.log(err)
        console.log('Error Initializing Page Data');
      }
    })();
  
  },[]);


  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value
  return (
    <div className={`grid grid-cols-cust-2g gap-1 bg-${styleMapProject.inputElem.background} p-3`}>
      <label>{props.inputLabel}</label>
      <select
        className="pl-2"
        type={props.inputType}
        name={props.inputName}
        onChange={props.onChangeFn}
        // defaultValue={props.fieldValue}
        value={props.inputValue }
      >
        <option>Please Select</option>
        {selectOptionMaps.map((optionMap, i) => (
          <option key={optionMap.value} value={optionMap.value}>
            {optionMap.label}
          </option>
        ))}
      </select>
    </div>
  );
}
