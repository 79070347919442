import React, { Component, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ref } from "firebase/database";
import TabContainer from "./TabContainer";
import {
  functions,
  httpsCallable,
  electron,
  pageSizes,
  getSerialNo,
  db,
  query,
  collection,
  where,
  doc,
  getDoc,
  updateDoc,
  runTransaction,
  Timestamp,
  serverTimestamp,
  database,
  onValue,
  set,
  child,
  get,
  push,
  update,
  getTimeLabel,
} from "../Utils";
import BottomContainer from "./BottomContainer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart, Line } from "react-chartjs-2";
import { useEffect } from "react";
import { getDocs } from "firebase/firestore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.size = 18;

export default function ChartCont(props) {


  console.log(props.passMap.topic);

  const labels = ["January", "February", "March"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [4, 5, 8],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: [9, 4, 7],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const topicIndex = props.passMap.chartTopics.indexOf(props.passMap.topic);

  const [datasetKey, setDatasetKey] = useState("");
  const [chartTopic, setChartTopic] = useState(props.passMap.topic);
  const [chartReady,setChartReady]=useState(false)
  const [compDataMap, setCompDataMap] = useState({});
  const [filCategories, setFilCategories] = useState([
    ...props.passMap.filCategories[topicIndex],
  ]);
  const [fil1Value, setFil1Value] = useState(
    props.passMap.filCategories[topicIndex][0]
  );
  const [fil2Value, setFil2Value] = useState(
    props.passMap.filValues[topicIndex][0][0]
  );
  const [fil3Value, setFil3Value] = useState(
    props.passMap.filValues[topicIndex][1][0]
  );
  const [fil4Value, setFil4Value] = useState(
    props.passMap.filValues[topicIndex][2][0]
  );


  const [tabFocusIndex,setTabFocusIndex]=useState(0);
  //const [curTabName, setCurTabName] = useState("Hour");
  //const [tabChanged, setTabChanged] = useState(false);

  const [xAxisLabel,setXAxisLabel]=useState(props.passMap.axisLabels[topicIndex][0][0])
  const [yAxisLabel,setYAxisLabel]=useState(props.passMap.axisLabels[topicIndex][1][0])


  const options = {
    responsive: true,
    scales: {
      x: {
         title:{
          display:true,
          align:"centre",
          text:xAxisLabel,
          color:"#000000",
          font:{
            size:14,
            weight:"500",
        
          }
         },
      },
      y: { 
        title:{
          display:true,
          align:"centre",
          text:yAxisLabel,
          color:"#000000",
          font:{
            size:14,
            weight:"500"
          }
        },
        ticks: {
          precision:0
      },
      },
    },
    plugins: {
      // title:{
      //   display:true,
      //   text:"Nishanth"
      // },
      legend: {
    
        // title:{
        //   display:true,
        //   text:"Nishantyh"
        // },
        position: "right",
      },
    },
  };

  const [chartData, setChartData] = useState({ ...data });
  const [chartOptions, setChartOptions] = useState({ ...options });

  useEffect(() => {
    initializePageWithData(compDataMap, fil2Value, fil3Value, fil4Value);
  }, []);

  useEffect(() => {
    
  }, [xAxisLabel,yAxisLabel]);

  console.log(compDataMap);

  async function initializePageWithData(
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value
  ) {
    // const fil2Value = props.passMap.chartFilValues[topicIndex][0][0];
    // const fil3Value = props.passMap.chartFilValues[topicIndex][1][0];
    // const fil4Value = props.passMap.chartFilValues[topicIndex][2][0];

    if (!Object.keys(compDataMap).includes(fil2Value)) {
            //The past order collection is queried to get the data
      const collQuery = query(
        collection(db, `storeBranches/${props.passMap.uid}/pastOrders`),
        where("orderYear", "==", fil2Value)
      );

      const docColl = await getDocs(collQuery);

      console.log(fil2Value);

      setCompDataMap(
        extractAndSetDataMap(
          docColl,
          compDataMap,
          fil2Value,
          fil3Value,
          fil4Value
        )
      );
    }
  }

  function extractAndSetDataMap(
    docColl,
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value
  ) {
    console.log("comin");
    console.log(docColl.size);
    console.log(fil2Value, fil3Value, fil4Value);
    const tMap = { ...compDataMap };

    // if (!Object.keys(tMap).includes(fil2Value)) {
    tMap[fil2Value] = {};
    tMap["All"] = tMap["All"] || {};
    tMap["All"]["All"] = tMap["All"]["All"] || {};

    const fil3Values = props.passMap.filValues[topicIndex][1];
    const fil4Values = props.passMap.filValues[topicIndex][2];

    //Creating Scheme Map for the Data Map
    fil3Values.forEach((fil3Value) => {
      tMap[fil2Value][fil3Value] = {}; //Eg tMap[2022][Skate Sharpening]={}
      tMap["All"][fil3Value] = tMap["All"][fil3Value] || {};
      fil4Values.forEach((fil4Value, i) => {
        const labelsForFil4 = props.passMap.chartLabels[topicIndex][i];
        const zeroArr = labelsForFil4.map((it) => {
          return 0;
        });
        tMap[fil2Value][fil3Value][fil4Value] = [...zeroArr]; //Eg tMap[2022][Skate Sharpening][Hour]=[0,0,0,0,0]
        tMap["All"][fil3Value][fil4Value] = tMap["All"][fil3Value][
          fil4Value
        ] || [...zeroArr];
      });
    });

    console.log(tMap);

    const fil4Index = fil4Values.indexOf(fil4Value);
    const fil4ChartLabelsColl = [...props.passMap.chartLabels[topicIndex]];

    // const monthFil4Index =
    //   props.passMap.filValues[topicIndex][2].indexOf("Month");
    // const dayFil4Index =
    //   props.passMap.filValues[topicIndex][2].indexOf("Day");

    const tArr = [];

    docColl.docs.forEach((doc, i) => {
      const valueSeqs = doc.data().stringSeqDatas;

      console.log(valueSeqs);

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");

        console.log(valueSeqArr);

        const filRefDbKeys = props.passMap.filRefDbKeys[topicIndex]; //Ex- "orderCompletionDateTime"
        const valueSeqDbKeys = props.passMap.valueSeqDbKeys;
        const refValues = [];

        filRefDbKeys.forEach((filRefKey, i) => {
          const mIndex = valueSeqDbKeys.indexOf(filRefKey);
          refValues.push(valueSeqArr[mIndex]); //Final Output Ex - [8712128721,"Skate Profiling",8712128721]
        });

        console.log(refValues);

        console.log(fil2Value, getCompRefValue(refValues[0], fil1Value));

        //eg if (2022==getCompRefValue(3438923992389,"Year"))
        if (fil2Value == getCompRefValue(refValues[0], fil1Value)) {
          const fil3Entry = refValues[1]; //Ex - Skate Sharpening

          fil4Values.forEach((fil4Value, i) => {
            //Inserting Values for all fil4Labels (ex- hour, day, month) to the final child array which will be the data for the chart
            const fil4Entry = getCompRefValue(refValues[2], fil4Value); //Ex - If Hour then 4 or 5 or 13
            const fil4EntryIndex = fil4ChartLabelsColl[i].indexOf(fil4Entry);
            console.log(fil4Value, fil4EntryIndex);
            tMap[fil2Value][fil3Entry][fil4Value][fil4EntryIndex] += 1; //Incrementing the position of the fil4Entry
            tMap[fil2Value]["All"][fil4Value][fil4EntryIndex] += 1;
            tMap["All"][fil3Entry][fil4Value][fil4EntryIndex] += 1;
            tMap["All"]["All"][fil4Value][fil4EntryIndex] += 1;
            //Ex - tMap[2022][Skate Sharpening][Hour]=[0,0,1,0,0]
          });
        }
      });
    });

    console.log(tMap);
    setChartReady(true)
    props.stopSpinner()

    updateChart(tMap, fil2Value, fil3Value, fil4Value);

    return tMap;
    // }
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  function updateChart(sCompDataMap, fil2Value, fil3Value, fil4Value) {
    const mIndex = props.passMap.filValues[topicIndex][2].indexOf(fil4Value);
    const labels = props.passMap.chartLabels[topicIndex][mIndex];

    console.log(sCompDataMap);

    const data = {
      labels: labels,
      datasets: [
        {
          label: fil2Value,
          data: sCompDataMap[fil2Value][fil3Value][fil4Value],
          borderColor: "#C73C3C",
          backgroundColor: "#C73C3C",
        },
      ],
    };

    console.log(data);

    // setDatasetKey(`${fil2Value}-1`);
    setChartOptions({ ...options });

    setChartData({ ...data });
  }

  const tabNames=["Hour","Day","Month"]

  function fil2ValueChange(e, compDataMap, fil3Value, fil4Value) {
    const recFil2Value =
      e.target.value == "All" ? e.target.value : parseInt(e.target.value);
    setFil2Value(recFil2Value);

    if (recFil2Value != "All") {
      initializePageWithData(compDataMap, recFil2Value, fil3Value, fil4Value);
    } else {
      updateChart(compDataMap, recFil2Value, fil3Value, fil4Value);
    }
    console.log(e.target.value);
  }

  function fil3ValueChange(e, compDataMap, fil2Value, fil4Value) {
    const recFil3Value = e.target.value;
    setFil3Value(recFil3Value);
    // console.log(e.target.value);
    // initializePageWithData(
    //   compDataMap,
    //   fil2Value,
    //   e.target.value,
    //   fil4Value
    // );
    updateChart(compDataMap, fil2Value, recFil3Value, fil4Value);
  }

  function fil4ValueChange(recFil4Value, compDataMap, fil2Value, fil3Value) {
    setFil4Value(recFil4Value);
    updateChart(compDataMap, fil2Value, fil3Value, recFil4Value);
  }

  function tabClickHandler(selectedIndex,tabName,compDataMap, fil2Value, fil3Value) {
    //setTabChanged(true);
    setTabFocusIndex(selectedIndex)
    //setCurTabName(tabName);

    options.scales.x.title.text = props.passMap.axisLabels[topicIndex][0][selectedIndex]
    //options.scales.y.title.text = 


    // setXAxisLabel(props.passMap.axisLabels[topicIndex][0][selectedIndex])
    fil4ValueChange(tabName, compDataMap, fil2Value, fil3Value)
  }

  return (
    <div className="h-cust-90vh grid grid-rows-cust-3f">
      <div className="flex justify-center items-center gap-16">
        <div className="flex gap-4">
          <div>Year</div>
          <select
            onChange={(e) =>
              fil2ValueChange(e, compDataMap, fil3Value, fil4Value)
            }
          >
            {props.passMap.filValues[topicIndex][0].map((optionName) => {
              return <option>{optionName}</option>;
            })}
          </select>
        </div>
        
        <div className="flex gap-4">
          <div>
            Job</div>
          <select
            onChange={(e) =>
              fil3ValueChange(e, compDataMap, fil2Value, fil4Value)
            }
          >
            {props.passMap.filValues[topicIndex][1].map((optionName) => {
              return <option value={optionName}>{optionName}</option>;
            })}
          </select>
        </div>
        <TabContainer
          // tabNameList={props.passMap.inputCategories}
          tabNameList={tabNames}
          tabFocusIndex={tabFocusIndex}
          tabClickHandler={(focusIndex) => tabClickHandler(focusIndex,tabNames[focusIndex],compDataMap, fil2Value, fil3Value)}
        />
        {/* <div className="flex gap-1">
        <button className="btn-tab"
          onClick={(e) =>
            fil4ValueChange("Hour", compDataMap, fil2Value, fil3Value)
          }
        >
          Hour
        </button>
        <button className="btn-tab"
          onClick={(e) =>
            fil4ValueChange("Day", compDataMap, fil2Value, fil3Value)
          }
        >
          Day
        </button>
        <button className="btn-tab"
          onClick={(e) =>
            fil4ValueChange("Month", compDataMap, fil2Value, fil3Value)
          }
        >
          Month
        </button>
        </div> */}
      </div>
      <div className="w-cust-70rem mx-auto items-center justify-center">
        {chartReady&&
        <Line options={{ ...chartOptions }} data={{ ...chartData }} />
        }
      </div>
      {bottomContainer}
    </div>
  );
}

function getCompRefValue(value, filValue) {
  switch (filValue) {
    case "Year": {
      return new Date(parseInt(value) * 1000).getFullYear();
    }
    case "Month": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getMonth(), "Month");
    }
    case "Day": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getDay(), "Day");
    }
    case "Hour": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getHours(), "Hour");
    }
  }
}
