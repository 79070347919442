import React, { Component,useState,useContext, useEffect } from 'react'
import OrderInfoBanner from './OrderInfoBanner'
import BottomContainer from './BottomContainer'
import {collection,query,where,getDocs,collectionGroup,onSnapshot} from "firebase/firestore"
import {firebase,getAuth,getFirestore,firebaseConfig,initializeApp,onAuthStateChanged,useNavigate} from "../Utils";
import { UserDataContext} from './Level1';


const db=getFirestore(firebase);

export default function OrderDetailsView(props){

    const [orderList,setOrderList]=useState("acceptedOrders");
    const [orderDoc,setOrderDoc]=useState({});

    const iUserDataContext = useContext(UserDataContext);

    const userUid = iUserDataContext.uid;

    console.log(userUid);

    useEffect(async ()=>{

        const collectionRef= collection(db,`stores/OBrfRjWCUYyUqP0TCfUn/orders`);

        const q = query(collectionRef,where("orderId","==",props.orderId));
    
        const snapshot = await getDocs(q);

            const x=[];
            snapshot.forEach((doc)=>{
                x.push(doc.data())
            });
    
            setOrderDoc(x[0]);

            return(()=>{

            })

    },[]);





    if(orderList=="acceptedOrders"){  

        return(

            <div className="grid grid-rows-cust-2c">
                <div>Skate Brand :{orderDoc.orderSkateBrandName}</div>
            </div>
        )

    }else if(orderList=="pastOrders"){ 

        return(
            <div></div>
        )


    }
     
}
