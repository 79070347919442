import React, { useRef, useState } from "react";
import { addSkatesInputSchemeMap, editCustomerAccountInputObj, editSkatesInputSchemeMap, queryAndGetAllDocsInCollection, skateUnitCardSchemeMap } from "../Utils";
import FunctionButton from "./FunctionButton";
import { getImgAsset } from "./ImageSourceHelper";
import InputCont2 from "./InputCont2";
import ListCont from "./ListCont";
import LoadSpinner from "./LoadSpinner";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";

export default function ModalBoxInteractive(props) {
  const qrCanvas = useRef(null);

  const [opState, setOpState] = useState("QR Generation");
  const [modalBtnClicked, setModalBtnClicked] = useState(false);
  const [modalDataMap, setModalDataMap] = useState({});
  const [flowIndex,setFlowIndex]=useState(0)
  const [selectionName,setSelectionName] = useState("")
  const [modalContentViewType,setModalContentViewType] = useState("selection")
  const [modalContentTopic,setModalContentTopic] = useState("manageCustomer")
  const [modalTitle,setModalTitle] = useState("Choose Function")
  const [showPrompt,setShowPrompt] = useState(false)
  const [promptMap,setPromptMap] = useState({})
  const [showSpinner,setShowSpinner] = useState(false)
  const [viewStackArray,setViewStackArray] = useState([{type:"selection",title:"Choose Function",topic:"manageCustomer",dataMap:{}}])



  console.log(props)


  function modalClose(){
    props.modalClose()
    // props.btnClickHandler({inputDataMap:{fnName:"Back"}})
  }


  function getPassMap(passMap,selection){
    const tMap = JSON.parse(JSON.stringify(passMap))
    const motherDataMap = tMap.motherDataMap;
    const selDataMap = motherDataMap[selection];

    tMap["dataMap"] = {...selDataMap};
    
    delete tMap.motherDataMap

    return tMap;
  }




  function addToViewStackArray(viewStackArray,type,title,topic,dataMap){
          
        const nextViewMap = {
          type:type,
          title:title,
          topic:topic,
          dataMap:dataMap
        }

    const tempViewStackArray = [...viewStackArray]
    tempViewStackArray.push(nextViewMap)
    setViewStackArray(tempViewStackArray)

    }


  async function actionFn(viewStackArray,fnName,curViewType,map){
    switch(fnName){
      case "Edit Customer Details":{

        const dataMap = props.passMap

         addToViewStackArray(viewStackArray,"input",fnName,"editCustomerDetails",dataMap)
      }
      break;
      case "Edit/Delete Skates":{
        //get the skate details of the customer

            if(curViewType=="initial"){ 

            const customerUid = props.passMap.customerUid;

            const customerSkateMapList = await queryAndGetAllDocsInCollection(`customers/${customerUid}/skates`);
           
            const dataMap={
              dataList:customerSkateMapList,
              schemeMap:skateUnitCardSchemeMap
            }

            addToViewStackArray(viewStackArray,"listVertical",fnName,"editSkate",dataMap)

          }else{
            setModalDataMap({})
            setModalContentTopic("editSkate")
            setModalContentViewType("input")
          }
   
      }
      break;
      case "Add Skate":{

          addToViewStackArray(viewStackArray,"input",fnName,"addSkate",{})
      }
    }
  }



  function submitProcessFn(inProgressCond){

   setShowSpinner(inProgressCond)

  }

//this function causes the views in the modal to go back to the span mentioned. 
//backspan is a parameter because in some cases like a delete, we will need to go back some views 
  function backViewFn(backSpan,viewStackArray){
   
    const fBackSpan = backSpan ? backSpan : 1;

    const tempViewStackArray = [...viewStackArray]

    for(let i=0;i<fBackSpan;i++){ 
      tempViewStackArray.pop()
    }

    setViewStackArray(tempViewStackArray)
  }
  
  const viewMap ={
    type:"",
    topic:"",
    dataMap:{}
  }


  function childInterfaceFn(viewStackArray,childPassMap,childViewType,childViewTopic){

    switch(childViewType){
      case "listVertical":{

        switch(childViewTopic){
          case "editSkate":{
            console.log(childPassMap)

            // const dataMap={
            //   dataList:customerSkateMapList,
            //   schemeMap:skateUnitCardSchemeMap
            // }

            addToViewStackArray(viewStackArray,"input","Edit Skate","editSkate",childPassMap)

          }
        }
        
      }
    }

  }



  function displayPrompt(title,message,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }



  //function getModalContentView(viewType,topic,modalMap){
    function getModalContentView(viewStackArray){
      const lastIndex = viewStackArray.length-1;
      const viewMap = viewStackArray[lastIndex]

      const viewType = viewMap.type
      const viewTopic = viewMap.topic
      const viewDataMap = viewMap.dataMap

      const modalTitle = viewMap.title;


    
    const jsxBlock = [];


    switch(viewType){
      case "selection":{

            if(viewTopic=="editSkate"){

            }else{

              const selections = props.schemeMap.selectionNames;

            for (const [i,selection] of selections.entries()){
              //const jsx = <div onClick={()=>actionFn(viewStackArray,selection,"initial")}>{selection}</div>
              const jsx =  <FunctionButton
              key={selection}
              clickHandler={()=>actionFn(viewStackArray,selection,"initial")}
              btnImage={getImgAsset(props.schemeMap.selectionImgKeys[i])}
              btnText={selection}
             // btnNotification={props.fnNotificationsArray[index]}
            />
              jsxBlock.push(jsx)
            }

              return jsxBlock
            }
      }
      case "input":{

        switch(viewTopic){
          case "editSkate":
            return(
              <InputCont2
              schemeMap={{fnType:"modal",...editSkatesInputSchemeMap}}
              domainMap={props.passMap}
              dataMap={viewDataMap}
              submitProcessHandler={(inProgressCond)=>submitProcessFn(inProgressCond)}
              backBtnClickHandler={(span)=>backViewFn(span,viewStackArray)}
              displayPromptHandler={(title,msg)=>displayPrompt(title,msg)}
              modalBtnClickHandler={(inputDataMap)=>actionFn({inputDataMap,...getPassMap(props.passMap,selectionName)})}
              modalBtnClickCloseHandler={()=>setModalBtnClicked(false)}
            />)
            case "addSkate" :
              return(
              <InputCont2
              // view={contView}
              schemeMap={{fnType:"modal",confmMsg:"Do you want to proceed to add the skate?",...addSkatesInputSchemeMap}}
              domainMap={props.passMap}
              submitProcessHandler={(inProgressCond)=>submitProcessFn(inProgressCond)}
              backBtnClickHandler={(span)=>backViewFn(span,viewStackArray)}
              displayPromptHandler={(title,msg)=>displayPrompt(title,msg)}
              modalBtnClickHandler={(inputDataMap)=>actionFn({inputDataMap,...getPassMap(props.passMap,selectionName)})}
              modalBtnClickCloseHandler={()=>setModalBtnClicked(false)}
            />
              )
            case "editCustomerDetails" :
              return(
                <InputCont2
                // view={contView}
                schemeMap={{fnType:"modal",confmMsg:"Do you want to edit customer details?",...editCustomerAccountInputObj}}
                domainMap={props.passMap}
                dataMap={viewDataMap}
                submitProcessHandler={(inProgressCond)=>submitProcessFn(inProgressCond)}
                backBtnClickHandler={(span)=>backViewFn(span,viewStackArray)}
                displayPromptHandler={(title,msg)=>displayPrompt(title,msg)}
                modalBtnClickHandler={(inputDataMap)=>actionFn({inputDataMap,...getPassMap(props.passMap,selectionName)})}
                modalBtnClickCloseHandler={()=>setModalBtnClicked(false)}
              />
                )

        }

        if(viewTopic=="editSkate"){

        }else if(viewTopic=="addSkate"){
        return(
          <div>
        <InputCont2
        // view={contView}
        schemeMap={{fnType:"modal",confmMsg:"Do you want to proceed to add the skate?",...addSkatesInputSchemeMap}}
        domainMap={props.passMap}
        submitProcessHandler={(inProgressCond)=>submitProcessFn(inProgressCond)}
        backBtnClickHandler={(span)=>backViewFn(span,viewStackArray)}
        displayPromptHandler={(title,msg)=>displayPrompt(title,msg)}
        modalBtnClickHandler={(inputDataMap)=>actionFn({inputDataMap,...getPassMap(props.passMap,selectionName)})}
        modalBtnClickCloseHandler={()=>setModalBtnClicked(false)}
      />
      </div>
        )}

        // switch(topic){
        //   case "customerAccount":{
        //     for (const selection of dataMap.selections){
        //       const jsx = <div onClick={()=>changeView(selection)}>{selection}</div>
        //       jsxBlock.push(jsx)
        //     }
    
        //     return jsxBlock
        //   }
        // }

      }
      case "listVertical":{

        return (
          <ListCont
            listType = {"Selection Banner"}
            schemeMap = {viewDataMap.schemeMap}
            passList = {viewDataMap.dataList}
            backBtnClickHandler={(span)=>backViewFn(span,viewStackArray)}
            parentInterfaceFnHandler = {(childDataMap)=>childInterfaceFn(viewStackArray,childDataMap,"listVertical","editSkate")}
          />
        )

      }
    }
  }

  


  return (
      <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
          <div className="relative grid grid-rows-cust-2i h-5/6 w-4/5 bg-quaternary-light m-4 items-center justify-center rounded-lg">
              <div className="btn-modal-close"  onClick={() => modalClose()}>
                X
            </div>
            <div className="absolute bottom-4 right-8"  onClick={() => modalClose()}>
               {props.modalTitle}
            </div>
              <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
                {viewStackArray[viewStackArray.length-1]["title"]}
              </div>

                <div className="flex justify-center flex-wrap gap-4 px-4">

                  {/* {getModalContentView(modalContentViewType,modalContentTopic,modalDataMap)} */}
                  
                  {getModalContentView(viewStackArray)}

                  {/* {fnLabels.map((label)=>
                    <div onClick={()=>showView(label)}>{label}</div>
                  )} */}
                {/* {flowIndex==0 && props.passMap.selections.length!=0 && 
                  props.passMap.selections.map((selection,i)=>{
                    return(
                      <button className="btn-action-sm btn-alert-high"
                      onClick={() => selectionFn(selection,flowIndex)}
                    >{getMonthYearAsLabel(selection)}</button>
                    )
                  })
                }

                {flowIndex==0 && props.passMap.selections.length==0 && 
                  <div>No Jobs Found</div>
                }

                {flowIndex==1 && 
                 <div className="flex flex-col justify-center items-center">
                  <div> {getMonthYearAsLabel(selectionName)}</div>
                  <InputCont
                    // view={contView}
                    passMap={getPassMap(props.passMap,selectionName)}
                    modalBtnClicked={modalBtnClicked}
                    modalDataMap={modalDataMap}
                    modalBtnClickHandler={(inputDataMap)=>props.btnClickHandler({inputDataMap,...getPassMap(props.passMap,selectionName)})}
                    modalBtnClickCloseHandler={()=>setModalBtnClicked(false)}
                  />
                  </div>
                } */}
  
                </div>
                {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
                {showSpinner && <LoadSpinner isModal={true}/>}
          </div>
 
   
      </div>
  );
}
