import React, { Component } from "react";
import { getSettingsLabelValue,getFSettingsLabelValue } from "../Utils";

export default function ImgInputTile(props) {

  console.log(props.inputName)
  function checkAndDisable(editStatus, inputName) {
    if (!editStatus) {
      return true;
    } else {
    }
  }

  const colPosition= `col-start-${props.colStart} col-end-${props.colEnd}`

  return (
    <div className={`grid ${``} grid-cols-cust-2g gap-1 bg-quaternary-xlight p-3`}>
      <label>{props.inputLabel}</label>
<input
    className="pl-2"
    type="file"
    name={props.inputName}
    disabled={!props.editStatus}
    onChange={props.onChangeFn}
></input>  
    </div>
  );
}
