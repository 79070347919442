import React, { useEffect, useState } from "react";
//import { useNavigate } from 'react-router-dom';
import skateLogo from "../images/app-logo.svg";
import LoadSpinner from "./LoadSpinner";
//import { initializeApp } from "firebase/app";
import {
  addCustomerExternalInputObj,
  addStoreBrandRequestInputObj,
  decodeFbAuthError,
  fetchAndActivate,
  getAuth,
  getValue,
  remoteConfig,
  sendEmailVerification,
  signInWithEmailAndPassword,
  styleProxyMap,
  useNavigate
} from "../Utils";

//import { getFunctions, httpsCallable } from "firebase/functions";

import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxMessagePromptWithMultipleOps from "./ModalBoxMessagePromptWithMultipleOps";
import ModalBoxMessagePromptWithOperation from "./ModalBoxMessagePromptWithOperation";
//import { check } from "../../functions";
import { sendPasswordResetEmail } from "firebase/auth";
import BtnNormal from "./BtnNormal";
import { getAppStage } from "./projectFns";
//import { firebaseConfig } from '../firebaseWebConfiguration';
//import {getAuth,signInWithEmailAndPassword} from "firebase/auth";

export default function LoginScreen(props) {
  const auth = getAuth();

  const navigate = useNavigate();
  const [showPromptOp, setShowPromptOp] = useState(false);
  const [showPromptOp2, setShowPromptOp2] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showSpinner,setShowSpinner]=useState(false);
  const [remoteValMap,setRemoteValMap] = useState({})

  useEffect((()=>{

    //const val = getValue(remoteConfig,"check_email_verification")

    fetchAndActivate(remoteConfig).then((valA)=>{
      console.log("remoteConfig Value",getValue(remoteConfig,"check_email_verification").asBoolean())

      const remoteValue = getValue(remoteConfig,"check_email_verification").asBoolean()
      
      setRemoteValMap({
        checkEmailVerification:remoteValue
      })

    }).catch((err)=>{
      console.log("error in remoteFetch",err)
    })



  }),[])

  function handleChange(event) {
    if (event.target.name == "email") {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  }

  async function signIn(email, password) {

    setShowSpinner(true)
    const dialogTitle = "Sign In"

    try {

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const idTokenResult = await auth.currentUser.getIdTokenResult()
      const userRole = idTokenResult.claims.role;

      const allowedRoles = ["Institute","Student","Super Admin","Master"]
      const restrictedRoles = ["Employee","Customer"]

      if(allowedRoles.includes(userRole)){
        
      if(remoteValMap.checkEmailVerification){

    
        if(auth.currentUser.emailVerified){

          navigate(props.userMode=="store"?"/dashboard":"/dashboard-admin", { replace: true });

        }else{

          const opBtnLabels = ["Send Verification Link","Change Email"]
          const opBtnAlertLevels = ["low","high"]
          const opSuccessMsgs  = ["Password reset link sent to your email",""]

          const obj = {
            userUid:auth.currentUser.uid,
            userEmail:auth.currentUser.email,
          }

          const message = "Your email id is not verified, please kindly verify your email-id through the verification link that was sent after sign up, alternatively you can initiate a verification link by clicking the Send Verification button below. In case your email id is wrong, you can request for a change of email id"

          const verifMailFn = async () => {
            // const actionCodeSettings = {
            //   url:process.env.REACT_APP_FB_CONTINUE_URL,
            //   ios:{
            //     bundleid:"com.ncodemedia.hockeycaddyDev"
            //   },
            //   android:{
            //     packageName:"com.ncodemedia.hockeycaddy",
            //     installApp:true
            //   },
            //   handleCodeInApp:true,
            // }
            await sendEmailVerification(auth.currentUser)
          }

          const changeEmailFn = async () => {
            navigate('/changeEmail',{state:obj})
          }


          setPromptMap({
            status:false,
            title:dialogTitle,
            message:message,
            opBtnLabels:opBtnLabels,
            opBtnAlertLevels:opBtnAlertLevels,
            opSuccessMsgs:opSuccessMsgs,
            opFns: [verifMailFn,changeEmailFn]
        })

        setShowPromptOp2(true);

      }

      }else{
        navigate(props.userMode=="store"?"/dashboard":"/dashboard-admin", { replace: true });
      }

      }else{
        if(restrictedRoles.includes(userRole)){
          displayPrompt(dialogTitle,"It seems your user role is not applicable to this platform. If you are a Customer please download and install the 'Hockey Caddy' App or if your a store employee please use the 'Hockey Caddy Service' App. These apps can be found in the Apple App Store or Android Playstore based on your mobile platform.")
        }else{
          displayPrompt(dialogTitle,"Invalid user role, please inform support")
        }
      }

      setShowSpinner(false)

    } catch (error) {

  
      const opBtnLabels = ["Send Password Reset Link"]
      const opBtnAlertLevels = ["low"]
      const opSuccessMsgs  = ["Password reset link sent to your email"]

      const passwordResetFn = async () => {
        const actionCodeSettings = {
          // url:process.env.REACT_APP_FB_CONTINUE_URL,
          url:"https://skate-smith-pro.web.app/",
          ios:{
            bundleid:"com.ncodemedia.hockeycaddyDev"
          },
          android:{
            packageName:"com.ncodemedia.hockeycaddy",
            installApp:true
          },
          handleCodeInApp:false,
        }
        await sendPasswordResetEmail(auth,email,actionCodeSettings)
      }

      const opFns = [passwordResetFn]

      if(decodeFbAuthError(error.code) == "No User Found"){


        displayPrompt(dialogTitle,decodeFbAuthError(error.code))

      }else{
        setPromptMap({
          status:false,
          title:dialogTitle,
          message:decodeFbAuthError(error.code),
          opBtnLabels:opBtnLabels,
          // opBtnAlertLevels:opBtnAlertLevels,
          opSuccessMsgs:opSuccessMsgs,
          opFns:opFns
        })


  

        setShowPromptOp2(true);
      }
      setShowSpinner(false)

    }
  }



  function displayPrompt(title,message,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }

  function btnClickFn(btnName){
    setShowSpinner(true)
    if(btnName=="resetPassword"){
      navigate("/resetPassword")
    }else if(btnName=="signup"){
      navigate("/signup",{state:addStoreBrandRequestInputObj})
    }else if(btnName=="signupCustomer"){
      navigate("/signup",{state:addCustomerExternalInputObj})
    }
  }

  console.log(process.env.REACT_APP_MODE)
  return (
    <div className={`h-screen grid grid-rows-cust-3g justify-center ${styleProxyMap[props.backgroundColor]}`}>
       <div className="absolute top-10 left-10 text-red text-lg">{getAppStage(process.env.REACT_APP_MODE)}</div>
      <div className="h-full flex items-center justify-center">
        <img className="h-60" src={skateLogo} />
      </div>
      <div className="flex flex-col gap-2">
          <label className="text-sm">Login Email</label>
            <input
              className="m-0 rounded-sm"
              type="text"
              name="email"
              onChange={(event) => handleChange(event)}
            ></input>
            <br />
            <label className="text-sm">Password</label>
            <input
            className="m-0 rounded-sm"
              type="password"
              name="password"
              onChange={(event) => handleChange(event)}
            ></input>
            <br />
            <BtnNormal spClass="mx-auto" color="primaryPale" label="Sign In" type="action" clickHandler={() => signIn(email, password)}/>

      </div>
      <div className="absolute flex-center bottom-10 w-screen gap-8">
        {/* <img src={dndNcodeLogo} width={150}/> */}
            {/* <BtnNormal spClass="margin-auto" color="primaryPale" label="Request Sign-Up" type="action" clickHandler={() => btnClickFn("signup")}/> */}
       </div>
       {showPromptOp && <ModalBoxMessagePromptWithOperation passMap={promptMap} promptClose={()=>setShowPromptOp(false)}/>}
       {showPromptOp2 && <ModalBoxMessagePromptWithMultipleOps passMap={promptMap} promptClose={()=>setShowPromptOp2(false)}/>}
       {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
       {showSpinner && <LoadSpinner />}
    </div>
  );
}
