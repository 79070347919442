import React, { useState } from "react";

import ButtonModalOp from "./ButtonModalOp";




export default function ModalBoxOpConfPrompt3(props) {

  const [showMessage,setShowMessage]= useState(false)
  const [message,setMessage] = useState(props.passMap.message)
  const [opComplCond,setOpComplCond] = useState(false)
  const [opSuccessCond, setOpSuccessCond] = useState(false);
  const [opInProgressCond,setOpInProgressCond] = useState(false)

  console.log(props) 

  async function opFunction(passMap){
    setOpInProgressCond(true);
    const op = await passMap.opFn(passMap.valMap);
    console.log(op.data.message)
    if(op.data.status){
      setOpSuccessCond(true)
    }
    setMessage(op.data.message)
    setOpInProgressCond(false);
    setOpComplCond(true)

  }

  function promptCloseFn(actionType,opCompletionStatus){
    if(actionType=="delete"){
      props.opCompletionHandler(props.passMap.actionType,opCompletionStatus)
    }
    props.promptClose()
  }

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-4 px-4 gap-y-24 items-center rounded-lg">
        <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
        {opComplCond ? "Result" : "Confirmation"}
        </div>
        <div className="flex items-center justify-center text-center">{message}</div>
        <div className="w-full">
          <div className="flex items-center justify-center gap-4">
            {!opInProgressCond && 
              <React.Fragment>
                {!opComplCond && 
                    <React.Fragment>
                      <ButtonModalOp  label="Yes, Proceed" alertLevel="high" clickHandler={async () => await opFunction(props.passMap)} />
                      <ButtonModalOp  label="Close" alertLevel="low" clickHandler={() => promptCloseFn(props.passMap.actionType,opComplCond)} />
                    </React.Fragment>
                  }
                  {opComplCond && 
                     <button className="btn-action-sm hover-black" onClick={() => promptCloseFn(props.passMap.actionType,opComplCond)}>
                       {opSuccessCond ? "Ok" : "Close"}
                     </button>
                  }
              </React.Fragment>
            }
            {opInProgressCond && <div className="loader"></div>}

          </div>
        </div>
      </div>
    </div>
  )
}
