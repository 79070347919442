import React from "react";
import {
  getInpDynOptions,
} from "../Utils";
import { inpOptionsKeyAliasMap, inputDepOptionsMap } from "./DataList";
import { styleMapProject } from "./viewProps";




// export default function DynamicInputList(props){

//     console.log(props)

//     //@1 States
//     const [regexResult,setRegexResult] = useState(true)
//     const regexPattern = getRegexPattern(props.regexDomain)


//     //@2 Functions

//     //@21 
//     function onChangeHandler(e,regexPattern,inputName){
//         props.onChangeFn(e,inputName)
//         console.log(e.target.value)
//         setRegexResult(regexPattern.test(e.target.value))
//       }

//       const labelCompClass = "flex items-center"
//       const tileColor = !Object.keys(props).includes("inpValidCond") || props.inpValidCond ?  "bg-secondaryTransparent2 " : "bg-red" ;
//       //@3 return
//     return(  
//     <div className={`grid w-full h-12 ${props.orientation=="vertical" ? `grid-rows w-cust-15rem` : `grid-cols-cust-3c w-cust-35rem` } 
//     ${tileColor} p-1 my-2 text-darkBackText rounded-md`}>
//    <div className={`${labelCompClass} justify-center`}>{props.slNo}</div>
//       <label className={`${labelCompClass} justify-start px-2`}>{props.inputLabel}</label>
      
       
//     <select 
//         className={`${labelCompClass} text-lightBackText`}
//         value={props.inputValue}
//          onChange={(e)=>onChangeHandler(e,regexPattern,props.inputName)}>
//               <option>Please Select</option>
//             {getDepOptions(inputDepOptionsMap,inpOptionsKeyAliasMap[props.inputName],props.inputName,props.parentValues).map((optionName)=>
//             <option value={optionName}>{optionName}</option>)}

//         </select>  
//       {/* {!regexResult && <span className="text-secondary text-xs">*Invalid Input</span>} */}
//     </div>

//     )    
// }

export default function DynamicInputList(props) {
  console.log(props)
  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value
  return (
    <div className={`grid grid-cols-cust-2g gap-1 bg-${styleMapProject.inputElem.background} p-3`}>
      <label>{props.inputLabel}</label>
      <select
        className="pl-2"
        type={props.inputType}
        name={props.inputName}
        onChange={props.onChangeFn}
        value={props.inputValue}
        // defaultValue={props.fieldValue}
        // value={props.fieldValue}
      >
        {/* <option>Please Select</option> */}
        <option>Please Select</option>
        {getInpDynOptions(inputDepOptionsMap,inpOptionsKeyAliasMap[props.inputName],props.inputName,props.parentValues).map((optionName,i)=>
            <option key={optionName} value={optionName}>{optionName}</option>)}
        {/* {props.selectOptions.map((option, i) => (
          <option key={option} value={option} selected={i == 0}>
            {option}
          </option>
        ))} */}
      </select>
    </div>
  );
}



