import React, { useState } from "react";
import { getRegexPattern } from "../Utils";
import { getBlankValueforInpList, getInpBackgroundColor } from "./projectFns";
import { styleMapProject } from "./viewProps";

export default function InputListNew(props) {
  console.log(props)
    //@1 States
    const [regexResult,setRegexResult] = useState(true)
    const regexPattern = getRegexPattern(props.inpRegexDomain)


    //@2 Functions

    //@21 
    function onChangeHandler(e,regexPattern,inpKey){
        props.onChangeFn(e,inpKey)
        console.log(e.target.value)
        setRegexResult(regexPattern.test(e.target.value))
      }


      function getInpElement(propsObj,regResult){

        if(propsObj.accessMode=="assess" || (propsObj.accessMode=="resubmit" && !propsObj.resubInpCond)){
          return(<div onClick={()=>props.simpleInpAssessorClickHandler()}
          className={`flex items-center px-2 h-full text-lightBackText w-full text-l ${props.resubInpCond==true ? `bg-redPale` : `bg-quaternary-light`}`}>{props.inpValue}</div>)
        }else{
          return(
          <select 
          className={`${labelCompClass} text-lightBackText ${getInpBackgroundColor(props.accessMode,regResult)}`}
          value={props.inpValue}
           onChange={(e)=>onChangeHandler(e,regexPattern,props.inpKey)}>
              <option value={getBlankValueforInpList(props.selectOptions)}>Please Select</option>
              {props.selectOptions.map((optionName)=>
              <option value={optionName}>{optionName}</option>)}
           </select> )

        }

      }


      const tileColor = !Object.keys(props).includes("inpValidCond") || props.inpValidCond ?  "bg-secondary" : "bg-red" ;
      const labelCompClass = "flex items-center"
      const height = props.inpLabel.length>25 ? `` : `h-12`;
      //@3 return
    return(  
    // <div className={`grid ${height} w-full ${props.orientation=="vertical" ? `grid-rows w-cust-15rem` : `grid-cols-cust-3c w-cust-35rem` } 
    // ${tileColor} p-1 my-2 text-darkBackText rounded-md`}>
    <div className={`grid grid-cols-cust-2g gap-1 bg-${styleMapProject.inputElem.background} p-3`}>
      {/* <div className={`${labelCompClass} justify-center`}>{props.slNo}</div> */}
      <label className={`${labelCompClass} justify-start px-2`}>{`${props.inpLabel} ${props.inpMandCond ? `*` : ``}`}</label>

       {getInpElement(props,regexResult)}
       

      {/* {!regexResult && <span className="text-secondary text-xs">*Invalid Input</span>} */}
    </div>

    )    
}



function getBlankValue(valueArr){

  switch(typeof valueArr[0]){
    case "string": return ""
    case "number": return 0
    default:return false
  }


}