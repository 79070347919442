import React, { useRef, useState } from "react";
import ButtonModalOp from "./ButtonModalOp";
import { checkAndFinalizeInputDataObj, createInitialObj, getInputValue, revisePassMap } from "./HelperFns";
import InputElementsContModal from "./InputElementsContModal";

export default function ModalBoxWithInput2(props) {
  const qrCanvas = useRef(null);

  console.log(props)
  
  const passDataObj = { ...revisePassMap(props.passMap) };
  const inpTemplObj =
  props.passMap.opType == "edit"
    ? {...props.passMap.inpValMap}
    : createInitialObj(passDataObj); 


const [inpDataObj, setInpDataObj] = useState({ ...inpTemplObj });
// const [passDataObj, setPassDataObj] = useState({ ...initPassDataObj }); 
const [inpImg, setInpImg] = useState("");
const [opState, setOpState] = useState("QR Generation");
const [modalBtnClicked, setModalBtnClicked] = useState(false);
const [modalDataMap, setModalDataMap] = useState({});
const [opSuccessCond, setOpSuccessCond] = useState(false);
const [opComplCond, setOpComplCond] = useState(false);
const [opInProgressCond, setOpInProgressCond] = useState(false);
const [opComplMessage, setOpComplMessage] = useState("");
const [valdCount, setValdCount] = useState(0);
//const [mandCount, setMandCount] = useState(0);


  async function opFn(fn,i){
    setOpInProgressCond(true)

    const payload = {
      ...inpDataObj,
      ...props.passMap.extraValMap
    }

    const data = {
      payload:payload
    }


    try{

    const op = await fn(data);
    console.log(op)
    setOpSuccessCond(true);
    setOpComplMessage(op.data.message);
    setOpInProgressCond(false)
    }catch(error){

      console.log(error.message)
      setOpComplMessage(error.message);
      setOpInProgressCond(false)
    }
  }


  async function opFnSubmit(fn,inpDataObj){
    setOpInProgressCond(true)

    console.log(inpDataObj,props.passMap.extraValMap)
    const payload = {
      ...inpDataObj,
      ...props.passMap.extraValMap
    }

    console.log(payload)



    try{
      const op = await fn(payload);
      console.log(op);
      setOpSuccessCond(true);
      setOpComplMessage(op.data.message);

      if(op.data.status){
        setOpSuccessCond(true)
      }

      setOpInProgressCond(false);
      setOpComplCond(true)
    }catch(error){
      console.log(error.message)
      setOpComplMessage(error.message);
      setOpInProgressCond(false)
    }
  }


  function disableClicked(e) {
    if (e.target.checked) {
      props.fnHandler("disable");
    } else {
      props.fnHandler("enable");
    }
  }

  function modalClose(){
    props.btnClickHandler({inputDataMap:{fnName:"Back"}})
  }


  function modalBtnClickFn(data){
   // console.log(data);

    setModalDataMap({fnName:data});

    setModalBtnClicked(true);
   // props.btnClickHandler({inputDataMap:{fnName:"Back"}}
  }


  function inpChangeFn(e) {

    const rInputName = e.target.name;
    const rInputType = e.target.type;
    let rInputValue = getInputValue(e.target);
    console.log(e.target);
    console.log(rInputValue);
  
    if (rInputType == "file") {
      setInpImg(URL.createObjectURL(rInputValue));
    }
  
    const tempObj = { ...inpDataObj }; //copying the 'Input Object State' into a temp object to then set as 'Input Object State'
    // Above doing this so, is because if tempObj=inputDataObj, then changing the tempObj will also chaneg the input object and
    //then when I set the State, the state will not update as the temp and input objects are the same
  
    tempObj[rInputName] = rInputValue;
  
    setInpDataObj(checkAndFinalizeInputDataObj(tempObj)); //Input Object Set
    console.log("setInputDataObj=",checkAndFinalizeInputDataObj(tempObj));
    console.log(inpDataObj);
  }

  return (
      <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
          <div className="relative grid grid-rows-cust-3i h-3/6 w-2/5 bg-quaternary-light m-4 items-center justify-center rounded-lg">
              <div className="btn-modal-close"  onClick={() => props.modalCloseHandler()}>
                X
            </div>
              <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
                {props.passMap.title}
              </div>
    
            <div>

            {!opComplCond && 
              <InputElementsContModal
                imgRequired={false}
                layout="versatile"
                inpTemplObj={inpTemplObj}
                passDataObj={passDataObj}
                inpDataObj={inpDataObj}
                inpChangeFn={(data)=>inpChangeFn(data)}
                changeValdCountFn = {(val)=>setValdCount(valdCount+val)}
              />
            }
            {opComplCond && <div className="p-4 text-center">{opComplMessage}</div>}
            </div>
            <div className="flex justify-center">     
             {!opComplCond && valdCount>=0 && 
               <React.Fragment>
                  {!opInProgressCond && 
                    <React.Fragment>
                    <ButtonModalOp label={props.passMap.opBtnLabelSubmit} alertLevel={"high"} clickHandler={async() => await opFnSubmit(props.passMap.opFnSubmit,inpDataObj)} />
                    {props.passMap.opBtnLabels.map((btnLabel,i)=>
                    <ButtonModalOp key={i} label={btnLabel} alertLevel={"opBtnAlertLevels" in props.passMap ? props.passMap.opBtnAlertLevels[i] : "low" } clickHandler={async() => await opFn(props.passMap.opFns[i],i)} />
                    )}
                   </React.Fragment>
                  }
                  {opInProgressCond && <div className="loader"></div>}
              </React.Fragment>
            }
              {(opComplCond && opSuccessCond) && 
                <ButtonModalOp label="Ok" alertLevel="low" clickHandler={()=>props.modalCloseHandler()} />
              }
              {(opComplCond && !opSuccessCond) && 
                <ButtonModalOp label="Back" alertLevel="low" clickHandler={()=>setOpComplCond(false)} />
              }

           
            </div>
    
          </div>
   
      </div>
  );
}
