import {
  collection,
  doc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import numToWordConverter from "number-to-words";
import pdfMake from "pdfmake/build/pdfmake.js";
import React, { useEffect, useState } from "react";
import {
  arrangeNumArrayInAscendingOrder,
  db,
  functions,
  generateIncrementNumArray,
  getDateStringFromMillis,
  getDoc,
  httpsCallable,
  limit,
  orderBy,
  styleProxyMap,
  toSentenceCase
} from "../Utils";
import BottomContainer from "./BottomContainer";
import FilterInput from "./FilterInput";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordBannerSmall from "./RecordBannerSmall";



const pageLimit = 15;

//@start
export default function RecordBannerFilterhCont(props) {

  const [motherListDataMap, setMotherListDataMap] = useState({});
  const [motherFilListDataMap, setMotherFilListDataMap] = useState({
    valueSeqs: [],
  });
  const [queryListDataMapColl, setQueryListDataMapColl] = useState([]);
  const [pageStripValueMapColl, setPageStripValueMapColl] = useState([]);
  const [slNoArray, setSlNoArray] = useState([]);
  const [filterDateName, setFilterDateName] = useState(
   "Today"
  );
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});

  const [recordCount, setRecordCount] = useState(0);
  const [filtRecordCount, setFiltRecordCount] = useState(0);
  const [pageNo, setPageNo] = useState(Object.keys(props.passMap).includes("pageNo")?props.passMap.pageNo:1);
  const [viewMode, setViewMode] = useState("normal");
  const [isLoading,setIsLoading] = useState(false)
  const [showSpinner,setShowSpinner] = useState(false)
  const [reverseSlNosCond,setReverseSlNosCond] = useState(true)
  const [showModalBox,setShowModalBox]=useState(false)
  const [modalDataMap,setModalDataMap] = useState({})


  useEffect(() => {
   // initializePageWithData();
  }, []);


  console.log(props)

  async function initializePageWithData() {
    const collQuery = query(
      collection(db, getDbCollPath(props)),
      where("docCreationDate", "!=", false),
      orderBy("docCreationDate","desc"),limit(2)
    );

    const dbDocs = await getDocs(collQuery);

    if(dbDocs.size!=0){ // check if dbdocs are not empty
      setMotherListDataMap(
        extractAndSetValuesDataMap(dbDocs,motherListDataMap,pageNo));
    }else{
      setPageStripValueMapColl([])
      props.stopSpinner()
      setIsLoading(false);
    }


  }



  function extractAndSetValuesDataMap(dbDocs,sMotherListDataMap,pageNo){
    
    let latestSlNo = ""

    if(Object.keys(sMotherListDataMap).length==0){
        latestSlNo = dbDocs.docs[0].data().recordSlNos[dbDocs.docs[0].data().recordSlNos.length-1]
        setRecordCount(latestSlNo);
    }else{
      latestSlNo = arrangeNumArrayInAscendingOrder(sMotherListDataMap.dbSlNos).reverse()[0]
    }

    console.log(latestSlNo)

    //const pageNoLimits = getPageNoLimits(pageNo,latestSlNo)

    const valueSeqDbKeys= props.passMap.valueSeqDbKeys;

    
    //creating the empty data map
    const eMap = {};
    const tArray1=[];

    eMap["slNos"]=[]
    eMap["dbSlNos"]=[]
    valueSeqDbKeys.forEach((key)=>{
      eMap[`${key}s`] = [];
    })

    console.log(eMap);
    console.log(valueSeqDbKeys)


    dbDocs.forEach((doc) => {
      const stringSeqDatas = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;

      stringSeqDatas.forEach((stringSeqData,i)=>{

          const stringSeqDataArray = stringSeqData.split("^");
          const dbSlNo = slNos[i]

          console.log(stringSeqDataArray)
          //const appSlNo = //dbSlNo; For displaying old record first
          const appSlNo = reverseSlNosCond?latestSlNo-dbSlNo+1:dbSlNo; //For displaying latest record first - As we need the latest record to be tabulated first hence we need to reverse the slNos

          eMap["slNos"].push(appSlNo) // Pushing the Sl No of the record
          eMap["dbSlNos"].push(dbSlNo) // required to find the latestSlNo in this function - refer top

          Object.keys(eMap).forEach((key)=>{ //Pushing rest of the data of the record
            const keySingular = key.substring(0,key.length-1)
            const keyIndex = valueSeqDbKeys.indexOf(keySingular)
            console.log(key,keyIndex)
            if(keyIndex!=-1){
              eMap[key].push(stringSeqDataArray[keyIndex]) //key field holds an array
            }
          })

          console.log(eMap);

      })

    });

    //Adding the db extract map to the MotherMap if Mother Map has already been populated

    if(Object.keys(sMotherListDataMap).length!=0){
      Object.keys(eMap).forEach((key)=>{
        eMap[key]=[...sMotherListDataMap[key],...eMap[key]]
      })
  }

    console.log(eMap)
    //console.log(stripValueMapColl);
   // setStripValuesMap({...eMap})
    displayPage(pageNo, {...eMap},latestSlNo);
    return eMap;
  }//end of extractAndSetValuesDataMap

  function extractAndSetFiltBannerListDataMap(dbDocs,filValue,filDbKeyArrayName) {
    

    const valueSeqDbKeys= props.passMap.valueSeqDbKeys;


    //creating the empty data map
    let eMap = {};
    const tArray1=[];

    eMap["slNos"]=[]
    eMap["dbSlNos"]=[]
    valueSeqDbKeys.forEach((key)=>{
      eMap[`${key}s`] = [];
    })

    //index of the filDbKey in the value seq
    const mIndex = valueSeqDbKeys.indexOf(filDbKeyArrayName.substring(0,filDbKeyArrayName.length-1))

    console.log(mIndex,filDbKeyArrayName,filValue)

    let slNoCount=0


    for(const doc of Array.from(dbDocs)){
      const stringSeqDatas = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;

      const tMap={}
      tMap["slNos"]=[]
      valueSeqDbKeys.forEach((key)=>{
        tMap[`${key}s`] = [];
      })

      stringSeqDatas.forEach((stringSeqData,i)=>{


          const stringSeqDataArray = stringSeqData.split("^");
          const slNo = slNoCount

    console.log(stringSeqDataArray[mIndex],filValue)
//Checking if the record matches the filter condition
          if(stringSeqDataArray[mIndex]==filValue){
             slNoCount++;

             //eMap["slNos"].push(slNoCount) // Pushing the Sl No of the record
              tMap["slNos"].push(slNoCount)

            Object.keys(tMap).forEach((key)=>{ //Pushing rest of the data of the record
              const keySingular = key.substring(0,key.length-1)
              const keyIndex = valueSeqDbKeys.indexOf(keySingular)
              if(keyIndex!=-1){
                tMap[key].push(stringSeqDataArray[keyIndex])
              }
            })
          }


      })



        Object.keys(tMap).forEach((key)=>{
          if(key!="slNos"){
            tMap[key].reverse()
          }
        });
      
  
      console.log(tMap);
  
      Object.keys(tMap).forEach((key)=>{
        eMap[key] = eMap[key].concat(tMap[key])
      });
      console.log(eMap);

    };



    console.log(eMap)


    const reverseEMap = {}

    if(!reverseSlNosCond){
    Object.keys(eMap).forEach((key)=>{
        reverseEMap[key]=[...eMap[key]]
        reverseEMap[key].reverse()
    })
    reverseEMap["slNos"] = [...eMap["slNos"]]
   }


   eMap = reverseSlNosCond ? {...eMap} : {...reverseEMap};
  //  console.log(reverseEMap)
  //  console.log(eMap)

    const sFiltRecordCount = eMap["slNos"].length
    console.log(`filterCount=${sFiltRecordCount}`)

    setFiltRecordCount(eMap["slNos"].length);

   // const rMap = reverseSlNosCond ? reverseEMap : eMap;


    //console.log(stripValueMapColl);
   // setStripValuesMap({...eMap})
    displayFiltPage(1, {...eMap},sFiltRecordCount);
    return eMap
  }

  function extractAndSetFiltListDataMap(dbDocs,filValue,filDbKeyArrayName) {
    

    const valueSeqDbKeys= props.passMap.valueSeqDbKeys;


    //creating the empty data map
    let eMap = {};
    const tArray1=[];

    eMap["slNos"]=[]
    eMap["dbSlNos"]=[]
    valueSeqDbKeys.forEach((key)=>{
      eMap[`${key}s`] = [];
    })

    //index of the filDbKey in the value seq
    const mIndex = valueSeqDbKeys.indexOf(filDbKeyArrayName.substring(0,filDbKeyArrayName.length-1))

    console.log(mIndex,filDbKeyArrayName,filValue)

    let slNoCount=0


    for(const doc of Array.from(dbDocs)){
      const stringSeqDatas = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;

      const tMap={}
      tMap["slNos"]=[]
      valueSeqDbKeys.forEach((key)=>{
        tMap[`${key}s`] = [];
      })

      stringSeqDatas.forEach((stringSeqData,i)=>{


          const stringSeqDataArray = stringSeqData.split("^");
          const slNo = slNoCount

    console.log(stringSeqDataArray[mIndex],filValue)
//Checking if the record matches the filter condition
          if(stringSeqDataArray[mIndex]==filValue){
             slNoCount++;

             //eMap["slNos"].push(slNoCount) // Pushing the Sl No of the record
              tMap["slNos"].push(slNoCount)

            Object.keys(tMap).forEach((key)=>{ //Pushing rest of the data of the record
              const keySingular = key.substring(0,key.length-1)
              const keyIndex = valueSeqDbKeys.indexOf(keySingular)
              if(keyIndex!=-1){
                tMap[key].push(stringSeqDataArray[keyIndex])
              }
            })
          }


      })



        Object.keys(tMap).forEach((key)=>{
          if(key!="slNos"){
            tMap[key].reverse()
          }
        });
      
  
      console.log(tMap);
  
      Object.keys(tMap).forEach((key)=>{
        eMap[key] = eMap[key].concat(tMap[key])
      });
      console.log(eMap);

    };



    console.log(eMap)


    const reverseEMap = {}

    if(!reverseSlNosCond){
    Object.keys(eMap).forEach((key)=>{
        reverseEMap[key]=[...eMap[key]]
        reverseEMap[key].reverse()
    })
    reverseEMap["slNos"] = [...eMap["slNos"]]
   }


   eMap = reverseSlNosCond ? {...eMap} : {...reverseEMap};
  //  console.log(reverseEMap)
  //  console.log(eMap)

    const sFiltRecordCount = eMap["slNos"].length
    console.log(`filterCount=${sFiltRecordCount}`)

    setFiltRecordCount(eMap["slNos"].length);

   // const rMap = reverseSlNosCond ? reverseEMap : eMap;


    //console.log(stripValueMapColl);
   // setStripValuesMap({...eMap})
    displayFiltPage(1, {...eMap},sFiltRecordCount);
    return eMap
  }



  function displayPage(sPageNo, sMotherListDataMap,sRecordCount) {
    
    setPageNo(sPageNo);
    setViewMode("normal")

    console.log(sMotherListDataMap)
    const motherRecordSlNos = sMotherListDataMap.slNos
    const motherRecordDbSlNos = sMotherListDataMap.dbSlNos
    //const latestSl = arrangeNumArrayInAscendingOrder(motherRecordSlNos).reverse()[0] // The size of the sl No array will give us the last sl no
   
    const latestSl = sRecordCount

    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * sPageNo > latestSl ? latestSl : pageLimit * sPageNo;

      
    console.log(startSlOfPage, endSlOfPage);
    console.log(motherRecordSlNos)

    const startDbSlOfPage = reverseSlNosCond?sRecordCount-startSlOfPage+1:startSlOfPage
    const endDbSlOfPage = reverseSlNosCond?sRecordCount-endSlOfPage+1:endSlOfPage

//Step: Checking for missing Sl No Record in the Mother Data
    const slQueryArray = [];
    [startDbSlOfPage, endDbSlOfPage].forEach((item) => {
      if (motherRecordDbSlNos.indexOf(item) == -1) {
        slQueryArray.push(item);
      }
    });


    // [startSlOfPage, endSlOfPage].forEach((item) => {
    //   if (motherRecordSlNos.indexOf(item) == -1) {
    //     slQueryArray.push(item);
    //   }
    // });

    if (slQueryArray.length == 0) {
      assignStripValues(startSlOfPage, endSlOfPage, sMotherListDataMap);
    } else {
      fetchDbAndSetStripValues(
        startDbSlOfPage,
        endDbSlOfPage,
        slQueryArray,
        sMotherListDataMap,
        sPageNo
      );
    }

  
  }

  async function fetchDbAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    sMotherListDataMap,
    pageNo
  ) {

  

    const collQuery = query(
      collection(
        db,
        getDbCollPath(props)
      ),
      where("recordSlNos", "array-contains-any", slQueryArray)
    );
    

    const dbDocs = await getDocs(collQuery);
    setMotherListDataMap(
      extractAndSetValuesDataMap(dbDocs,sMotherListDataMap,pageNo)
    );
  }

  function assignStripValues(startSlNo, endSlNo, sMotherListDataMap) {
    const rArray = [];

    const mIndexStart = startSlNo;
    const mIndexEnd = endSlNo;

    console.log(mIndexStart,mIndexEnd)

    const slNosArray = sMotherListDataMap.slNos;

    //empty data map


    const map2s = {};   // if it is maiden time, then sMotherListDataMap will be {}

    // //Creating an Intialized Array Map
    //   map2s["slNos"]=[] 
    //   props.passMap.labelDbKeys.forEach((dbKey)=>{
    //     map2s[`${dbKey}s`] = [];
    //   })
  
    const tArr=[];
        for (let y = mIndexStart; y <= mIndexEnd; y++) {
          const tMap = {};
          const mIndex = slNosArray.indexOf(y);
          tMap["slNo"]=y;
          props.passMap.valueSeqDbKeys.forEach((dbKey)=>{  
            tMap[dbKey]=sMotherListDataMap[`${dbKey}s`][mIndex]   
            })
          tArr.push(tMap)
        }

    console.log(tArr);

    setPageStripValueMapColl([...tArr]);
    setIsLoading(false)
  }



  function renderPagination(
    sPageLimit,
    motherListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {

    console.log(sRecordCount);
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    console.log(`date in renderPagination=${new Date().getMilliseconds()}`)

    const rArray = [];

    console.log(motherListValuesCollMap);



    // for (let x = 1; x <=pageCount; x++) {
    //   // const element = (
    //   //   <button
    //   //      key="a"
    //   //     onClick={() => displayPage(x, motherListValuesCollMap,sRecordCount)}
    //   //     className={`p-cust-3 ${
    //   //       x == sPageNo ? `border-page-no-indicator` : ``
    //   //     }`}
    //   //   >{`${x}`}</button>
    //   // );
    //   rArray.push(element);
    // }

    const element = (
      <select value={sPageNo} className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayPage(e.target.value,motherListValuesCollMap,sRecordCount)}>
        {generateIncrementNumArray(1,pageCount).map(slNo =>
          <option value={slNo}>{slNo}</option>
        )
        }
      </select>
  );

    return element;
  }

  function renderFilterPagination(sPageLimit,
    sMotherFilListDataMap,
    sRecordCount,
    sPageNo) {
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    console.log(`date in renderFilPage=${new Date().toTimeString()}`)

    // const rArray = [];

    // for (let x = 1; x <= pageCount; x++) {
    //   const element = (
    //     <button
    //       onClick={() => displayFiltPage(x, sMotherFilListDataMap)}
    //       className={`p-cust-3 ${
    //         x == sPageNo ? `border-page-no-indicator` : ``
    //       }`}
    //     >{`${x}`}</button>
    //   );
    //   rArray.push(element);
    // }

    // return rArray;

    const element = (
      <select className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayFiltPage(e.target.value, sMotherFilListDataMap)}>
        {generateIncrementNumArray(1,pageCount).map(pageNo =>
          <option value={pageNo}>{pageNo}</option>
        )
        }
      </select>
  );

    return element;
  }

  async function displayPagesBasedOnFilter(sFilterName, sFilterValueMap) {
    setViewMode("filter");

    console.log("hellothere");

    const filterSchemeMap = { ...props.passMap.filterSchemeMap };

    const filterNameIndex = filterSchemeMap.filterNames.indexOf(sFilterName);
    const filterInputKeyNames =
      filterSchemeMap.filterInputKeys[filterNameIndex];

    const filterInputValue =
      sFilterName == "Store Branch Name"
        ? `${sFilterValueMap.storeBrandName}-${sFilterValueMap.storeBranchName}`
        : sFilterValueMap[filterSchemeMap.filterInputKeys[filterNameIndex][0]];

    const curFilterValMap = {
      filterName: sFilterName,
      filterDbKey: filterSchemeMap.filterDbKeys[filterNameIndex],
      filterInputKeys: filterSchemeMap.filterInputKeys[filterNameIndex],
      filterSeqIndex: filterSchemeMap.filterSeqIndices[filterNameIndex],
      filterInputValue: filterInputValue,
    };

    console.log(props.listTopic);

    const collQuery = query(
      collection(db, `admin/accounts/${props.listTopic}`),
      where(
        curFilterValMap.filterDbKey,
        "array-contains",
        curFilterValMap.filterInputValue
      )
    );

    const filtDbDocs = await getDocs(collQuery);

    console.log(filtDbDocs.size);
    setMotherFilListDataMap(
      extractAndSetFilteredValuesMapColl(filtDbDocs, 1, curFilterValMap)
    );
  }

  function extractAndSetFilteredValuesMapColl(
    filtDbDocs,
    pageNo,
    sCurFilterValMap
  ) {
    const tArray = [];
    let nMap = {};

    nMap["count"] = 0;

    const dbNotations = [
      "invoiceId",
      "invoiceIssueDate",
      "invoicePartyId",
      "invoicePartyName",
      "invoicePartyUid",
      "invoiceDesc",
      "invoiceAmount",
      "invoicePaymentDueDate",
    ];

    filtDbDocs.docs.forEach((doc) => {
      const tSubArray = [];
      const stringSeqDatas = doc.data().stringSeqDatas;

      const uids = [];
      const docIds = [];
      const map = {}; //for docid and uid relation

      stringSeqDatas.forEach((stringSeq) => {
        const stringArray = stringSeq.split("^");

        if (
          stringArray[sCurFilterValMap.filterSeqIndex] ==
          sCurFilterValMap.filterInputValue
        ) {
          const tMap = {}; //each sequence data will be in the form of a map
          nMap["count"] += 1;

          //Creating a UID and DOC Id Array to facilitate invoice record transfer/deletion later on.
          const stringValues = stringSeq.split("^");
          uids.push(stringValues[dbNotations.indexOf("invoicePartyUid")]);
          docIds.push(doc.ref.id);

          //Converting the String Seq into a map
          dbNotations.forEach((dbNotation, i) => {
            tMap[dbNotation] = "";
            tMap[dbNotation] = stringValues[i];
          });

          tMap["stringSeqData"] = stringSeq;
          tMap["slNo"] = nMap["count"];
          tMap["docId"] = doc.ref.id;

          map["docIds"] = [...docIds];
          map["uids"] = [...uids];

          console.log(tMap);
          console.log(map);



          tArray.push(tMap);
        }
      });
    });
    console.log(tArray);

    if (tArray.length != 0) {
      displayFiltPage(pageNo, tArray);
    }

    return tArray;


  }

  function displayFiltPage(sPageNo, sMotherFilListDataMap,sRecordCount) {

    setPageNo(sPageNo);
    //setFiltRecordCount(sRecordCount)
    // setViewMode("filter")

    const rArray = [];

    //const latestSl = sRecordCount;

    // console.log(sMotherFilListDataMap)

    const latestSl = sMotherFilListDataMap.slNos.length;

   // const latestSl = arrangeNumArrayInAscendingOrder(sMotherFilListDataMap.slNos).reverse()[0]

    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo - 1) + 1;
    const endSlOfPage =
      (pageLimit * sPageNo) > latestSl ? latestSl : (pageLimit * sPageNo);

      
    assignStripValues(startSlOfPage, endSlOfPage, sMotherFilListDataMap);

    // console.log(startSlOfPage, endSlOfPage);

    // if (startIndex == endIndex) {
    //   rArray.push(valuesMapColl[startIndex]);
    // } else {
    //   for (let x = startIndex; x <= endIndex; x++) {
    //     rArray.push(valuesMapColl[x]);
    //   }
    // }
    // console.log([...rArray]);
    // setPageStripValueMapColl([...rArray]);
  }



  async function btnClicked(data, tabMap,pageNo) {
    const map = data.stripValueMap;
    switch (data.btnType) {
      case "View":{
        setShowSpinner(true)
  
        const invoiceId = props.listTopic == "balances" ? "INVOICE NOT GENERATED : FOR VIEWING PURPOSE ONLY" : map.invoiceId;
  
        let invMap = {}
  
        console.log(map)
  
  
        const docDbCall = query(collection(db,`admin/accounts/invoices`),where("invoiceId","==",invoiceId));
        const dbDocs = await getDocs(docDbCall);
    
        invMap = dbDocs.docs[0].data();
  
        invMap = {...invMap,...map}
  
        console.log(invMap)
          
    
        //Step 6a: Define font files - this is required 
              var fonts = {
                Roboto: {
                  normal: 'fonts/Roboto-Regular.ttf',
                  bold: 'fonts/Roboto-Medium.ttf',
                  italics: 'fonts/Roboto-Italic.ttf',
                  bolditalics: 'fonts/Roboto-MediumItalic.ttf'
                }
              };
    
        function getJobCountRangeAndCostStrMap(blockComps,comp,i,remainderJobCount){
          //returns a map which gives the range of the tarriff block, tarriff of the block 
          //and string of the range to print on the invoice
  
              const compComps = comp.split("x")
              const rMap={}
              rMap["jobCountRangeStr"]= ""
              rMap["unbilledJobCount"]= ""
              rMap["jobCountRangeTariff"]=""
              rMap["jobCountRangeTotal"]=""
          
              //precComps = Previous Block Comp
              if(i==0){ //first component
                rMap.jobCountRangeStr = `jobs 1 to ${compComps[0]}`
                rMap.unbilledJobCount = remainderJobCount<compComps[0]?remainderJobCount:compComps[0]
                rMap.jobCountRangeTariff = compComps[1]
                rMap.jobCountRangeTotal = parseInt(rMap.unbilledJobCount)*parseFloat(compComps[1]);
              }else if(i==blockComps.length-1){ //last component
                const precComps = blockComps[i-1].split("x");
                rMap.jobCountRangeStr = `jobs ${parseInt(precComps[0])+1} and above`
                rMap.unbilledJobCount = remainderJobCount;//(dMap.invoiceJobCount-parseInt(precComps[0]))
                rMap.jobCountRangeTariff = compComps[1]
                rMap.jobCountRangeTotal = parseInt(rMap.unbilledJobCount)*parseFloat(compComps[1]);
              }else{ //middle components
                const precComps = blockComps[i-1].split("x");
                const blkJobCount = (parseInt(compComps[0])-parseInt(precComps[0]))
                rMap.jobCountRangeStr = `jobs ${parseInt(precComps[0])+1} to ${compComps[0]}`
                rMap.unbilledJobCount = remainderJobCount<blkJobCount?remainderJobCount:blkJobCount
              // rMap.unbilledJobCount = (parseInt(compComps[0])-parseInt(precComps[0]))
                rMap.jobCountRangeTariff = compComps[1]
                rMap.jobCountRangeTotal = rMap.unbilledJobCount*parseFloat(compComps[1]);
              }
              return rMap;
        }
    
            //Data for rows in the invoice pdf - for PDF Make
    
            const blockComps = invMap["invoicePartyTariffSeqData"].split("^"); //Array of each tarrif comp (jobs in the tarriff slab x rate - 3x0.03) ex [3x0.02,4x0.1]
            const rows = [];
            let remJobCount  = invMap.invoiceJobCount; //Jobs for which the invoice is billed
  
    
            blockComps.every((comp,i)=>{
              const tArr=[]
              const dataObj = getJobCountRangeAndCostStrMap(blockComps,comp,i,remJobCount)
              remJobCount-=dataObj.unbilledJobCount
              if(remJobCount>=0){
                tArr.push(`${i+1}`)
                tArr.push(`Fee for ${dataObj.jobCountRangeStr}`)
                tArr.push(dataObj.jobCountRangeTariff.toString())
                tArr.push(dataObj.unbilledJobCount.toString()) //pushing to index 3
                tArr.push(dataObj.jobCountRangeTotal.toFixed(2).toString()) //pushing to index 4
                rows.push(tArr)
                return true
              }else{
                return false
              }
            })
        
            console.log(rows)
        
            let totalJobCount=0;
            let totalAmount=0;
        
            rows.forEach((rowData,i)=>{
              totalJobCount+=parseInt(rowData[3]) // as job count is in index 3 of row array
              totalAmount+=parseFloat(rowData[4])// as amount is in index 4 of row array
            })
    
        //Step 6b: Initialize the PDF Make Api
        // var PDFMake = require('pdfmake');
        // var printer = new PDFMake(fonts);
    
        // var printer = new pdfMake(fonts);
    
        pdfMake.fonts = {  
          // download default Roboto font from cdnjs.com
          Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
          },
        
        }
    
    
        //Step 6c: Initialize Information to be put up
    
        pdfMake.tableLayouts = {
        exampleLayout: {
          hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return 0;
            }
            return (i === node.table.headerRows) ? 1 : 0.5;
          },
          vLineWidth: function (i) {
            return 0;
          },
          hLineColor: function (i) {
            return i === 1 ? '#bbb' : '#bbb';
          },
          paddingLeft: function (i) {
            return i === 0 ? 0 : 8;
          },
          paddingRight: function (i, node) {
            return (i === node.table.widths.length - 1) ? 0 : 8;
          }
        }
      };
  
    
    const discountAmount = totalAmount*invMap.invoiceDiscount/100;
    const invoiceTotal = totalAmount-discountAmount;
    
    
      
    const currencyStrComp = parseFloat(invMap.invoiceAmount).toFixed(2).toString().split(".");
    
    let invoiceAmtInWords="";
    
    const currencyUnitName="DOLLARS"
    const currencySubUnitName="CENTS"
    
    console.log(currencyStrComp)
    
    if(currencyStrComp.length==2){
    const currencyMainComp = numToWordConverter.toWords(parseInt(currencyStrComp[0])).toUpperCase();
    const currencyDecComp= numToWordConverter.toWords(parseInt(getDecimalValue(currencyStrComp[1],2))).toUpperCase();
    invoiceAmtInWords=`${currencyMainComp} ${currencyUnitName} AND ${currencyDecComp} ${currencySubUnitName}`
    }else{
    const currencyMainComp = numToWordConverter.toWords(parseInt(currencyStrComp[0]));
    invoiceAmtInWords=`${currencyMainComp} ${currencyUnitName}`
    }
    
    function getDecimalValue(decComp,count){
    
      if(decComp.toString().length<count){
        const zerosToBeAdded = count-decComp.toString().length
        console.log(zerosToBeAdded)
        let zeroStrings= "";
        
        for(let x=0;x<zerosToBeAdded;x++){
          zeroStrings+='0'
        }
        console.log(`${decComp.toString}${zeroStrings}`)
        return `${decComp.toString()}${zeroStrings}`
      }else{
    
        return decComp.toString().substring(0,count);
      }
    }
    
    
       const docDefinition = {
    
          content: [
            // if you don't need styles, you can use a simple string to define a paragraph
            {text:invMap.invoiceDesc,
            fontSize: 18, 
            bold:true, 
            alignment:
            "center"},
            {columns:[
              {
                width:'30%',
                stack:[{text:'TO,'},
                {text:`${invMap.invoicePartyName}`},
                {text:`${invMap.invoicePartyAddress}`},
                {text:`${invMap.invoicePartyEmail}`},
                {text:'ATTN:',margin:[0,20,0,0]},
                {text:`${invMap.invoicePartyContactPersonName}`,bold:true},
                {text:`${invMap.invoicePartyContactPersonContactNo}`,bold:true},
                ],
                fontSize: 10, 
                alignment:"left"
              },
              {
                width:'70%',
                stack:[
                {text:`${getDateStringFromMillis(parseInt(invMap.invoiceIssueDate))}`},
                {text:`${invMap.invoiceId}`},
                {text:`${invMap.invoicePartyId}`,bold:true},
                {text:`DUE DATE: ${getDateStringFromMillis(parseInt(invMap.invoicePaymentDueDate))}`}],
                fontSize: 10, 
                alignment:"right",
                lineHeight:1.4
              }
            ]},
              {
                layout: 'exampleLayout', // optional
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 1,
                  widths: [ '10%', '50%', '20%', '10%','10%' ],  
                  body: [
                    [ {text:'Sl No',fillColor: '#eeeeee'}, {text:'Description',fillColor: '#eeeeee'}, {text:'Tariff',fillColor: '#eeeeee'},{text:'Job Count',fillColor: '#eeeeee'}, {text:'Total',fillColor: '#eeeeee'}],
                    ...rows,
                    [ {colSpan:3,rowSpan:1,text:'Total',fillColor:'#eeeeee'},'','',{text:totalJobCount},{text:totalAmount.toFixed(2)}],
                    [ {colSpan:3,rowSpan:1,text:'Discount',fillColor:'#eeeeee'},'','',{text:`${invMap.invoiceDiscount}%`},{text:discountAmount.toFixed(2)}],
                    [ {colSpan:4,rowSpan:1,text:'Amount Payable',fillColor:'#eeeeee'},'','','',{text:invoiceTotal.toFixed(2)}],
                    [ {colSpan:5,rowSpan:1,text:invoiceAmtInWords,fillColor:'#eeeeee'},"","","",""]
                  ],      
                },
                fontSize:10,
                margin:[0,20,0,20],
                defaultStyle: {
                  font: 'Helvetica'
                }
            
              }
          ],
        };
    
        //pdfMake.createPdf(docDefinition).download();
    
    
    
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Invoice(${map.invoiceId}).pdf`;
          document.body.append(link);
    
          link.click();
          link.remove();
          setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    
         });
    
         //pdfDocGenerator.download()
    
          console.log("hello")
          setShowSpinner(false);
          
  
  
      }
      break;
      case "View Details":{

        const uData={};
        uData["fnName"] = "Check Past Orders";
        uData["dbData"] = data.stripValueMap;
        uData["pageNo"] = pageNo
  
        props.actionHandler(uData);
  
        console.log(data);
        console.log(tabMap)
      }
      break;
      case "Invoice":
        {

          console.log(map)
     
          const todaysDate = new Date().getTime();
          const dueDate = todaysDate + 1000 * 60 * 60 * 24 * 15; //Days x Milliseconds per day

          //Step 1: Fetching the Store Branch Doc - For info on store
          const storeGenDetailDoc = await getDoc(
            doc(db, `storeBranches/${map["partyUid"]}`)
          );

          const storeEmail = storeGenDetailDoc.data().storeBranchEmail;
          const storeSettings = storeGenDetailDoc.data().settings;
          const storeAddress = `${storeSettings.storeBranchUnitNo}, ${storeSettings.storeBranchStreetName}, ${storeSettings.storeBranchCityName}, ${storeSettings.storeBranchProvinceName}, ${storeSettings.storeBranchCountryName} - ${storeSettings.storeBranchPostalCode}`;
          const storeContactNo = storeSettings.storeBranchContactNo;
          const storeBranchContactPersonName=storeSettings.storeBranchContactPersonName;
          const storeBranchContactPersonContactNo=storeSettings.storeBranchContactPersonContactNo;

        //Step 2: Creation of the Server Call Payload - Adding details from the Strip Map and the 
        //general Info that we got from Step 1
          const partyTariffSeq=(map["partyTariffType"]=="general")?props.passMap.feeTariffDetails.tariffStrSeqData:map["partyTariffSeq"];
        
          const payload = {
            partyId: map["partyId"],
            partyUid: map["partyUid"],
            partyName: map["partyName"],
            partyTariffType:map["partyTariffType"],
            partyTariffSeq:partyTariffSeq,
            unbilledJobCount: map["unbilledJobCount"],
            invoiceBalanceDue: map["dueBalance"],
            invoiceSubject: "App Commission",
            invoicePartyAddress: storeAddress,
            invoicePartyEmail: storeEmail,
            invoicePartyContactNo: storeContactNo,
            invoicePartyContactPersonName: storeBranchContactPersonName,
            invoicePartyContactPersonContactNo: storeBranchContactPersonContactNo,
            balanceDocId:map["balanceDocId"]
          };

          //Step 3: Server Call
          const serverCall = httpsCallable(functions, "sendInvoice");
          const result = await serverCall(payload);
          alert(result.data);

        }
        break;
      case "Clear":
        {
          const dbCollQuery2 = query(
            collection(db, `admin/accounts/balances`),
            where("partyUids", "array-contains", map["partyUid"]),
            limit(1)
          );

          const balanceDoc = await getDocs(dbCollQuery2);
          const balanceDocId = balanceDoc.docs[0].ref.id;

          const docIds = data.stripValueMap.stripSeqDocIds;
          const stringSeqDatas = data.stripValueMap.stripSeqDatas;

          const mainInvoiceIds = [];

          stringSeqDatas.forEach((stringSeq) => {
            mainInvoiceIds.push(stringSeq.split("^")[0]);
          });

          const mainInvoiceCollQuery = query(
            collection(db, `admin/accounts/invoices`),
            where("invoiceId", "in", mainInvoiceIds)
          );

          const mainInvoiceDoc = await getDocs(mainInvoiceCollQuery);
          const mainInvoiceDocIds = [];

          mainInvoiceDoc.docs.forEach((doc, i) => {
            mainInvoiceDocIds.push(doc.ref.id);
          });

          const payload = {
            ...data.stripValueMap,
            balanceDocId: balanceDocId,
            mainInvoiceDocIds: mainInvoiceDocIds,
            mainInvoiceIds: mainInvoiceIds,
          };

          console.log(payload);

          const serverCall = httpsCallable(functions, "payInvoices");
          const result = await serverCall(payload);
          alert(result.data);

        }
        break;

      case "Paid":
        {
          console.log(map);
          console.log(tabMap);

          // const dbCollQuery = db
          //   .collection(`admin/accounts/balances`)
          //   .where("partyUids", "array-contains", payload.invoicePartyUid)
          //   .limit(1);

          // const balanceDocs = await dbCollQuery.get();

          //Step 1: Fetching the admin account balance document where the store is featured - 
          //To get important details of the invoice

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDoc = await getDocs(dbCollQuery).docs[0];
          const balanceDocId = balanceDoc.ref.id;


    //Step 2: Fetching the Invoice Doc - 
          const invoiceCollQuery = query(
            collection(db, `admin/accounts/invoices`),
            where("invoiceId", "==", data.stripValueMap.invoiceId),
            limit(1)
          );

          const invoiceDoc = await getDocs(invoiceCollQuery).docs[0];
          const invoiceDocId = invoiceDoc.ref.id;

          const filteredIndices = []; //indices where the store in question has records in the specific doc ref.

          tabMap.uids.forEach((uid, i) => {
            if (
              uid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.docId == tabMap.docIds[i]
            ) {
              filteredIndices.push(i);
            }
          });

          //Get the First and Last Occurence of the Uid in the Party UID - Unpaid Invoice Doc Id
          const firstIndex = tabMap.uids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = tabMap.uids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            invoiceDocId: invoiceDocId,
            balanceDocId: balanceDocId,
          };

          const serverCall = httpsCallable(functions, "payInvoice");
          const result = await serverCall(payload);
          alert(result.data);
        }
        break;

      case "Gen":{
        const serverCall = httpsCallable(functions, "genInvoice");
        //const result = await serverCall(payload);
       // alert(result.data);
      }
      break;
      case "Delete":
        {
          const firstIndex = tabMap.uids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = tabMap.uids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDocs = await getDocs(dbCollQuery);
          const balanceDocId = balanceDocs.docs[0].ref.id;

          const mainInvoiceDoc = await getDocs(
            query(
              collection(db, `admin/accounts/invoices`),
              where("invoiceId", "==", data.stripValueMap.invoiceId),
              limit(1)
            )
          );

          const mainInvoiceDocId = mainInvoiceDoc.docs[0].ref.id;

          const filteredIndices = [];

          tabMap.uids.forEach((uid, i) => {
            if (
              uid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.docId == tabMap.docIds[i]
            ) {
              filteredIndices.push(i);
            }
          });

          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            balanceDocId: balanceDocId,
            mainInvoiceDocId: mainInvoiceDocId,
          };

          const serverCall = httpsCallable(functions, "deleteInvoice");
          const result = await serverCall(payload);
          alert(result.data);
        }
        break;
      default: {
        console.log("No Options");
      }
    }
  }

  async function filterClickFn(e,filterName,motherListDataMap){
    console.log(e.target.parentElement)
    console.log(filterName)
    setViewMode("filter")

    console.log("topic name="+props.listTopic)

    let filtValue = ""
   // const inputNodes = e.target.parentElement.getElementsByTagName("input");
   const inputNodes = e.target.parentElement.getElementsByClassName("filter-inputs");

    let filValType=""

    let filDbKeyName = ""
    let filDbKeyArrayName = ""

    console.log("before")

    switch(filterName){
      case "By First Name":{
        filDbKeyName="customerFirstName"
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "By Last Name":{
        filDbKeyName="customerLastName"
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "By Full Name":{
        filDbKeyName="customerName"
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "By Email":{
        filDbKeyName="customerEmail"
        filValType="email"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "By Phone No":{
        filDbKeyName="customerContactNo"
        filValType="phoneNo"
        filtValue = toSentenceCase(`${inputNodes[0].value}${inputNodes[1].value}`);
      }
      break;
      case "By Customer ID":{
        filDbKeyName="customerId"
        filValType="id"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "Customer ID":
      case "Party ID":{
        if(props.listTopic=="pastOrder"){
          filDbKeyArrayName="orderCustomerIds"
        }else if(props.listTopic=="transactionAdmin" || props.listTopic=="transaction"  ){
          filDbKeyArrayName="transactionPartyIds"
        }
        filtValue = `${inputNodes[0].value}`;
      }
      break;
      case "Customer Name":
      case "Party Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderCustomerNames":"transactionPartyNames"
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "Employee Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderResponsibleEmployeeNames":""
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
    
      }
      break;
      case "Job Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderJobNames":""
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "Store Branch Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderJobNames":props.listTopic=="transaction"?"transactionPartyNames":"transactionPartyNames"
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value} - ${inputNodes[1].value}`);
      }
      break;
    }


    console.log(`filtDbKey = ${filDbKeyName}, filValue = ${filtValue}`)

    const caseCorrectedFilValue = filValType=="name" ? toSentenceCase(filtValue) : filValType=="id" ?  filtValue.toUpperCase() : filValType=="email" ? filtValue.toLowerCase() : filtValue

    generateFilterDataMap(caseCorrectedFilValue,filDbKeyName,motherListDataMap);


}

async function generateFilterDataMap(filValue,filDbKeyName,sMotherListDataMap){

  const tArray1 = [];
  const map={}

  console.log(`viewMode in generateFilterMapFn = ${viewMode}`)

  console.log(filValue,filDbKeyName)

  const dbCollQuery = query(collection(db,"customers")
  ,where("customerNoAppCond","==",true),
  where(filDbKeyName,"==",filValue));

  const dbDocs = await getDocs(dbCollQuery);


  if(dbDocs.docs.length==0){
//    setPageStripValueMapColl([])
    setQueryListDataMapColl([])
  }else{
    console.log(sMotherListDataMap)
    console.log(dbDocs.docs[0].data())

    const tempQueryListDataMapColl = []

    for(const doc of Array.from(dbDocs.docs)){
      tempQueryListDataMapColl.push(doc.data())
    }

    //setMotherFilListDataMap(extractAndSetFiltBannerListDataMap(dbDocs,filValue,filDbKeyName))
    setQueryListDataMapColl(tempQueryListDataMapColl)
  }



}



  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  function resetFilterClickFn(sMotherListDataMap,event){
    setQueryListDataMapColl([])
    // setViewMode("normal");
    // setFilterName(props.passMap.filterSchemeMap.filterNames[0])
    // displayPage(1,sMotherListDataMap)
  }


  if(!isLoading){
      props.stopSpinner()
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );


  function actionHandler(data){

    // setModalDataMap(data)
    // setShowModalBox(true)
    const dataMap = {
      dataMap:data,
      modalTitle:data.customerName,
      modalType:"modalBoxInteractive"
    }

    props.subViewHandler(true,"modal",dataMap,props.passMap.moduleTopic)
  }

  //@render
  return (
    <React.Fragment>
    {
  <div className={`h-screen grid ${styleProxyMap.gridRows["2c"]} p-cust-topHead`}>

    <div className="h-full grid grid-rows-cust-2m" >
        <div className="flex flex-col justify-center items-center py-12">
            <div className="flex flex-row">
              {/* <div className="">
              <FilterDateUnit schemeMap={props.passMap.filterSchemeMap} filterSubmitHandler={(data)=>filterDateChangeFn(data)}/>
              </div> */}
              <select className="bg-primary rounded px-8 text-darkBackText" onChange={(e) => setFilterName(e.target.value)} value={filterName}>
                {props.passMap.filterSchemeMap.filterNames.map(
                  (filterOptionName, i) => {
                    return (
                      <option key={i} value={filterOptionName}>
                        {filterOptionName}
                      </option>
                    );
                  }
                )}
              </select>
              <div>
                <FilterInput
                  filterName={filterName}
                  filterSchemeMap={filterSchemeMap}
                  filterValueMap={filterValueMap}
                  inputChangeFn={(data) => inputChangeFn(data)}
                  filterClickFn={(data,filterName)=>filterClickFn(data,filterName)}
                  resetFilterClickFn={(event)=>resetFilterClickFn(motherListDataMap,event)}
                />
              </div>
              {/* <button
                className="btn-filter mx-1"
                onClick={() =>
                  displayPagesBasedOnFilter(filterName, filterValueMap)
                }
              >
                Go
              </button> */}
            </div>
            <div className="flex mt-8">
              {/* {viewMode=="normal" && renderPagination(pageLimit, motherListDataMap, recordCount, pageNo)}
              {viewMode=="filter" && renderFilterPagination(pageLimit, motherFilListDataMap, filtRecordCount, pageNo)} */}
            </div>
         </div>
          
        <div className="px-4 h-full grid-cols-cust-2b">

          <div className="grid grid-cols-cust-2e mx-auto mt-1 text-sm">
                  {/* <div
                    className={`grid mx-auto w-full ${stripColWidth} bg-secondary rounded-sm border border-borderStd`}
                  >
                    {props.passMap.labelNames.map((labelName, index) => {
                      return (
                        <div className="title-strip-unit flex justify-center items-center text-center text-darkBackText">
                          {labelName}
                        </div>
                      );
                    })}
                  </div>
                    <div className="flex items-center justify-center text-darkBackText bg-secondary text-center rounded-sm border border-borderStd mx-2 ">
                      Action
                    </div> */}
          </div>
            
            <div className = {queryListDataMapColl.length > 5 ? `overflow-scroll` : ``} >
                  {queryListDataMapColl.length > 0 && queryListDataMapColl.map((dataMap,i) => {
                    return (
                    <RecordBannerSmall
                      key={dataMap.customerId}
                      slNo={i+1}
                      labelNames={props.passMap.labelNames}
                      labelDbKeys={props.passMap.labelDbKeys}
                      fnName={props.passMap.fnName}
                      passDataMap={dataMap}
                      passMap={props.passMap}
                      passDataMap2={props.passMap.settings}
                      actionHandler={(data) => actionHandler(data)}
                  />)
                    })
                  }
                            {queryListDataMapColl.length == 0 && 
                            <div className="pb-16">
                            <NoAvailableDisplay textName="No Records Found"/>
                            </div>
                }
                  </div>
        </div>
    </div>

        <div className="absolute w-full bottom-10 flex justify-center align-center">
        {bottomContainer}
        </div>
      </div>
      }
      {/* {showModalBox && 
          <ModalBoxInteractive 
            passMap={modalDataMap} 
            btnClickHandler={(data)=>{}}
          />
          } */}
      </React.Fragment>
    );
}

//@ex-functions
function getDigitSerial(slNo, noOfDigits) {
  const slSize = slNo.toString().length;
  const reqSize = noOfDigits;
  const digitDiff = reqSize - slSize;

  if (digitDiff >= 0) {
    let prefix = "";

    for (let x = 0; x < digitDiff; x++) {
      prefix += "0";
    }
    return `${prefix}${slNo}`;
  } else {
    return slNo.toString();
  }
}


function getDbCollPath(props){
  console.log(props.listTopic)
  switch(props.listTopic){
    case "paidInvoice":{
      return `storeBranches/${props.passMap.storeBranchUid}/paidInvoices`
    }
    case "paidInvoiceAdmin":{
      return `admin/accounts/paidInvoices`
    }
    case "transactionAdmin":{
      return `admin/accounts/transactions`
    }
    case "transaction":{
      return `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
    }
    case "pastOrder":{
      return `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
    }
    case "manageCustomer":{
      return `customers/`
    }
  }
}

