import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { collection, doc, getCountFromServer, getDoc, getDocs, orderBy, query, serverTimestamp, setDoc, where, writeBatch } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  db,
  generateIncrementNumArray,
  getArraySum,
  getDateObjFromDayOfYearAndYear,
  getDayOfTheYear,
  getTimeLabel
} from "../Utils";
import BottomContainer from "./BottomContainer";
import { chartOptionsMap } from "./DataList";
import FilterAssembly from "./FilterAssembly";
import FloatingButton from "./FloatingButton";
import InputDate from "./InputDate";
import TabContainer from "./TabContainer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = "DMSans";

export default function ChartCont5(props) {
  
  console.log(props.passMap.topic);

  const labels = ["January", "February", "March"];

  const data = {
    labels,
    datasets: [
      // {
      //   label: "Dataset 1",
      //   data: [0, 0, 0],
      //   borderColor: "rgb(255, 99, 132)",
      //   backgroundColor: "rgba(255, 99, 132, 0.5)",
      // }
    ],
  };
  const topicIndex = props.passMap.chartTopics.indexOf(props.passMap.topic);

  const filValues = [...props.passMap.filValues[topicIndex]]
  

  const [datasetKey, setDatasetKey] = useState("");
  const [chartTopic, setChartTopic] = useState(props.passMap.topic);
  const [compDataMap, setCompDataMap] = useState({});
  // const [filValues, setFilValues] = useState([
  //   ...props.passMap.filValues[topicIndex],
  // ]);
  const [chartReady,setChartReady]=useState(false)

  const [filCategories, setFilCategories] = useState([
    ...props.passMap.filCategories[topicIndex],
  ]);
  const [fil1Value, setFil1Value] = useState(
    props.passMap.filCategories[topicIndex][0]
  );
  const [fil2Value, setFil2Value] = useState(
    props.passMap.filValues[topicIndex][0][0]
  );
  const [fil3Value, setFil3Value] = useState(
    props.passMap.filValues[topicIndex][1][0]
  );

  const [fil4Value, setFil4Value] = useState(
    props.passMap.filValues[topicIndex][2][0]
  );
  const [filAValue, setFilAValue] = useState(getFirstValueFromSelection(props.passMap.filValues[topicIndex][1][0]));
  const [fil5aValue, setFil5aValue] = useState(
    "0000-00-00"
  );
  const [fil5bValue, setFil5bValue] = useState(
    "0000-00-00"
  );


  const filASelectionMap = getSelectionMap(props.passMap.filValues[topicIndex][1]);



  const [xAxisLabel,setXAxisLabel]=useState(props.passMap.axisLabels[topicIndex][0][0])
  const [yAxisLabel,setYAxisLabel]=useState(props.passMap.axisLabels[topicIndex][1][0])



  function getSelectionMap(topics){

    const map = {}

    topics.forEach((topic,i)=>{

      const selectObj = chartOptionsMap[topic];

      if(Array.isArray(selectObj)){
        map[topic] = selectObj;
      }else{
        const newSelectObj = []
        const parentSelections = Object.keys(selectObj);

        parentSelections.forEach((parentSelection,i)=>{
            const childSelections = selectObj[parentSelection];
            childSelections.forEach((childSelection)=>newSelectObj.push(`${parentSelection}^${childSelection}`))
        })
        map[topic] = newSelectObj;
      }

    })

    return map;

  }

  function getFirstValueFromSelection(selectionTopic){
  

    switch(selectionTopic){
      case "Hollow Radius":{
        return chartOptionsMap[selectionTopic][0]
      }
      break;
      case "Profile":{
        const selection1 = Object.keys(chartOptionsMap[selectionTopic])[0]
        const selection2 = chartOptionsMap[selectionTopic][selection1][0]

        return `${selection1}^${selection2}`
      }
      break;

    }
  }

  console.log(props.passMap)

  const options = {
    responsive: true,
    scales: {
      x: {
         title:{
          display:true,
          align:"centre",
          text:props.passMap.axisLabels[topicIndex][0][0],
          color:"#000000",
          font:{
            size:22,
            weight:"500",
        
          }
         },
      },
      y: {
        min:0,
        type:'linear',
        ticks:{
          callback: function (value) { if (Number.isInteger(value)) { return value; } },
          // stepSize:1,
        },
        title:{
          display:true,
          align:"centre",
          text:props.passMap.axisLabels[topicIndex][1][0],
          color:"#000000",
          font:{
            size:22,
            weight:"500"
          }
        }
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };


  options.scales.x.title.text=xAxisLabel
  options.scales.y.title.text=yAxisLabel

  const [chartData, setChartData] = useState({ ...data });
  const [chartOptions, setChartOptions] = useState({ ...options });

  const [tabFocusIndex,setTabFocusIndex]=useState(0);

  useEffect( () => {

    initializePageWithData(
      compDataMap,
      fil2Value,
      fil3Value,
      fil4Value,
      filAValue
    );

    // const dbDoc = await getDoc(doc(db,`storeBranches/${props.passMap.uid}/stats`,`skate`));

    // const tMap = dbDoc.data().data;

    // updateChart(JSON.parse(tMap), fil2Value, fil3Value, fil4Value, filAValue,filValues);


  }, []);

  console.log(compDataMap);

  async function initializePageWithData(
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue
  ) {

        props.startSpinner()

        const statTopic = props.passMap.topic.toLowerCase()
        const specLabel =  props.passMap.specLabel



    const storeBranchRootPath = `storeBranches/${props.passMap.uid}`;
    const storeGenDetailsCollPath = `${storeBranchRootPath}/storeBranchGenDetails`
    const statTopicCollectionPath = `${storeGenDetailsCollPath}/statData/${statTopic}Stats`;

    let tMap = {...compDataMap}

    const fil2Values =  filValues[0]
    const fil3Values = filValues[1];
    const fil4Values = filValues[2];
    const filAValues = filValues[3];

    const currentYearCond = fil2Value == fil2Values[0]
    let pastOrderFetchReqd = false;
    let statDataMapWriteReqd = false;
    let syncSlNoWriteReqd = false;

    const chartDataDbFetchAndWriteReqd = !(fil2Value in tMap);

    let dataSyncRefreshCond = false;


    if(chartDataDbFetchAndWriteReqd){

  
        const dbDoc = await getDoc(doc(db,`${storeGenDetailsCollPath}`,`statData`));

        const dataSyncLatestPastOrderSlNo = dbDoc.data()["dataSyncLatestPastOrderSlNoMap"][statTopic];
        const dataSyncRefreshRefDateTime = dbDoc.data()["dataSyncRefreshRefDateTimeMap"][statTopic];

        let selectedYearPastOrderLatestSlNo = null;

        let yearStatDataMapPresentCond = false;

        //this map is used to store the doc ids of each statDataMap so that it will be useful in updating the corresponding docs later
        //the template will be map = {"Hollow Radius":"hsdihadhsdihddiuhfdui"}
        let statDataDocIdMap = {};

        if(dataSyncLatestPastOrderSlNo!=0){

            const docCountCall = await getCountFromServer(query(collection(db,`${statTopicCollectionPath}`),where("year","==",fil2Value)));

            yearStatDataMapPresentCond = docCountCall.data().count > 0;

          }

        const noStatDataMapCond = dataSyncLatestPastOrderSlNo==0 || !yearStatDataMapPresentCond

        console.log(dataSyncLatestPastOrderSlNo,yearStatDataMapPresentCond)


    //A. creating a template map or getting the dataMap which doubles up as a template

      if(noStatDataMapCond){ 
        //for a new account, past orders have never been referred to get data
        //create a dataMap template

        tMap = getChart5DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values);
        pastOrderFetchReqd = true;



      }else{
          //check if the selected year stat data doc is available
          const dbDocsColl = await getDocs(query(collection(db,`${statTopicCollectionPath}`),where("year","==",fil2Value)));

          tMap = {
            [fil2Value]:{}
          }

         const refreshRefDateTime = dbDocsColl.docs[0].data().refreshRefDateTime 
         dataSyncRefreshCond =  dataSyncRefreshRefDateTime > refreshRefDateTime

         console.log(dataSyncRefreshCond)

          dbDocsColl.docs.forEach((doc,i)=>{

            const year = doc.data().year;
            const specValue = doc.data()[specLabel];


              if(!dataSyncRefreshCond){ 
                //here the tMap is filled with data from the statDataMap, suppose dataSyncRefresh is asked for no data is collected

                const dataMap = JSON.parse(doc.data().data);

                tMap[fil2Value][specValue] = {...dataMap[fil2Value][specValue]}

             }

 
             //the doc ids will be required even if dataRefresh is asked for because we want to write on the existing statDocs
             statDataDocIdMap[specValue] = {
              docId: doc.id,
              refreshRefDateTime:refreshRefDateTime
             }
            
          })

          if(dataSyncRefreshCond){
            //since for dataSyncRefresh we will need to re-fresh all data, hence we are assigning a template object
            tMap = getChart5DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values);
          }

          console.log(tMap)

          pastOrderFetchReqd = dataSyncRefreshCond || currentYearCond;

      }
      

      //B. Getting External Data if required and writing the updated data

      if(pastOrderFetchReqd){



        const querySyncSlNo = dataSyncRefreshCond ? 0 : currentYearCond ? dataSyncLatestPastOrderSlNo : 0;


        let collQuery = "";
        collQuery = query(collection(db,`${storeBranchRootPath}/pastOrders`),where("orderYear","==",fil2Value),where("latestRecordSlNo",">",querySyncSlNo),orderBy("latestRecordSlNo","desc"))

      //getting content from past orders
      const pastOrderDocColl = await getDocs(collQuery);

      statDataMapWriteReqd = !pastOrderDocColl.empty

      if(statDataMapWriteReqd){
          //Functions : 1) Adding the pastOrder data to base tMap
          //2) Update the stat skate datamap on db
          //3) Update the sync no on db

          selectedYearPastOrderLatestSlNo = pastOrderDocColl.docs[0].data().latestRecordSlNo;

          syncSlNoWriteReqd = selectedYearPastOrderLatestSlNo > dataSyncLatestPastOrderSlNo
              
          tMap = extractAndAddDataToChartMap(props,{...tMap},topicIndex,pastOrderDocColl,fil2Value,fil3Values,fil4Values,querySyncSlNo);

          const batch = writeBatch(db);

          for(const specValue of fil3Values){
              const dataMap = {
                [fil2Value]:{
                  [specValue]:tMap[fil2Value][specValue]
                }
              }

              const uploadMap = {
                year:fil2Value,
                [specLabel]:specValue,
                data:JSON.stringify(dataMap),
           
              }

              const docUpdateAndNotCreateCond = !noStatDataMapCond || specValue in statDataDocIdMap;
          
              if(docUpdateAndNotCreateCond){
                batch.update(doc(db,statTopicCollectionPath,statDataDocIdMap[specValue]["docId"]),{...uploadMap,refreshRefDateTime:dataSyncRefreshRefDateTime,docModificationDate:serverTimestamp()})
              }else{
                batch.set(doc(collection(db,statTopicCollectionPath)),{...uploadMap,refreshRefDateTime:dataSyncRefreshRefDateTime,docCreationDate:serverTimestamp()})
              }
 
          }

          if(syncSlNoWriteReqd){
      batch.update(doc(db,`${storeGenDetailsCollPath}`,'statData'),{[`dataSyncLatestPastOrderSlNoMap.${statTopic.toLowerCase()}`]:selectedYearPastOrderLatestSlNo})
          }

          batch.commit()
      }

    }

    }
    
  
    console.log(tMap)
    updateChart(tMap, fil2Value, fil3Value, fil4Value, filAValue,filValues);
    
    setCompDataMap({...tMap})

    setChartReady(true)
    props.stopSpinner()



    //setChartData(tMap)


    function extractAndAddDataToChartMap(props,map,topicIndex,pastOrderDocColl,fil2Value,fil3Values,fil4Values,querySyncSlNo){
      //2: getting refValues
      pastOrderDocColl.docs.forEach((doc, i) => {


        //putting this condition because to factor for the fact to only extract part of the records (new records) from the past order doc
        //this happens when an updated StatMap has to update itself when a new past order record is created 
   

          const valueSeqs = doc.data().stringSeqDatas;

          for(let i=valueSeqs.length-1;i>=0;i--){

            const recordSlNo = doc.data().recordSlNos[i];

            if(recordSlNo <= querySyncSlNo) { 
              //this is to save time and computing power, since we are iterating backward and we only need to extract the data of new past orders records
              //comparing the latestSyncSlNo withe the record sl no will only iterate over new past order records
              break;
            }
  
           // valueSeqs.forEach((valueSeq, i) => {
                  const valueSeq = valueSeqs[i]
                  const valueSeqSegmentColl = valueSeq.split("~").map((it)=>it.split("^"));      
          
                  const filRefDbKeys = props.passMap.filRefDbKeys[topicIndex]; //Ex- "orderCompletionDateTime"
                  const valueSeqDbKeys = props.passMap.valueSeqDbKeys;
                  const refValues = [];
          
          
                  filRefDbKeys.forEach((filRefKey, j) => {
                    if (j != 3) {
          
                      const value = filRefKey===null ? fil3Value : findAndGetRefValueFromDataSeq(valueSeqSegmentColl,valueSeqDbKeys,filRefKey)            
                      refValues.push(value)
        
                    } else {
                      // if it is a variable key selection filter ex - Avg Wait Time, Avg Job Time etc
                      const varFilRefDbKeys = [...filRefKey];
                      const tArr = [];
                      varFilRefDbKeys.forEach((varFilRefKeys) => {
        
                        tArr.push(findAndGetRefValueFromDataSeq2(valueSeqSegmentColl,valueSeqDbKeys,varFilRefKeys));
              
                      });
                      refValues.push(tArr);
                    }
                  });
          
          
                //3 : Filling the Chart Data Map based on conditional
                  if (fil2Value == getCompRefValue(refValues[0], fil1Value)) {
          
                    fil3Values.forEach((fil3Entry,k)=>{
          
                      const filAValues = filASelectionMap[fil3Entry];
          
                      filAValues.forEach((filAValue) => {
                        let filAEntry = 0;
          
                        filAEntry = refValues[3][k] ? refValues[3][k] : 0;
          
                        if(filAValue==filAEntry){ //Ex for filAValue - CAGONE^20ft
                
                          fil4Values.forEach((fil4Value, m) => {
                            //Inserting Values for all fil4Labels (ex- hour, day, month) to the final child array which will be the data for the chart
                            const fil4Entry = getCompRefValue(refValues[2], fil4Value);
                          
                            const fil4ChartLabelsColl = [...props.passMap.chartLabels[topicIndex]];
                            const fil4EntryIndex = fil4ChartLabelsColl[m].indexOf(fil4Entry);
            
                              //  tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                              //    fil4EntryIndex
                              //  ].push(1); //Incrementing the position of the fil4Entry
        
        
                              map[fil2Value][fil3Entry][filAValue][fil4Value][fil4EntryIndex] += 1
        
                          });
          
                    }
                    });
          
          
                    })
          
                  }
        
              
  
          //});
          }


      });
      return map;
    };

  }




  function getChart5DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values){

    const map = {...tMap};

    // if (!Object.keys(tMap).includes(fil2Value)) {
    map[fil2Value] = {};

    console.log(fil3Values)

    //STEP 1 : Creating Scheme Map for Chart Data
    fil3Values.forEach((iFil3Value) => {
      const aFil3Value =
        iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[1] : iFil3Value.split("^")[0];

      map[fil2Value][aFil3Value] = {}; //Eg map[2022][Skate Sharpening]={}

      const filAValues = filASelectionMap[aFil3Value];

      filAValues.forEach((filAValue) => {
        map[fil2Value][aFil3Value][filAValue] = {};


        fil4Values.forEach((fil4Value, i) => {
          const labelsForFil4 = props.passMap.chartLabels[topicIndex][i];
          const zeroAvgArr = labelsForFil4.map((it) => {return 0;});
          // const zeroItArr = labelsForFil4.map((it) => {return [];});
          map[fil2Value][aFil3Value][filAValue][fil4Value] = [...zeroAvgArr];
      //    map[fil2Value][fil3Value][filAValue][`${fil4Value}Items`] = [...zeroItArr]; //Eg map[2022][Skate Sharpening][Hour]=[0,0,0,0,0]


        });
      });
    });


    return map;
  }



  async function extractAndSetDataMap(
    docColl,
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue
  ) {
 

    console.log(fil2Value, fil3Value, fil4Value);
    const tMap = { ...compDataMap };
    let tMap2 = {}
    console.log(filAValue)

    // if (!Object.keys(tMap).includes(fil2Value)) {
    tMap[fil2Value] = {};
    // tMap["All"] = tMap["All"] || {};
    // tMap["All"]["All"] = tMap["All"]["All"] || {};


    tMap2[fil2Value] = {};
    // tMap2["All"] = tMap["All"] || {};
    // tMap2["All"]["All"] = tMap["All"]["All"] || {};


    let fil3Values = [];
    const fil4Values = filValues[2];
    let filAValues = filValues[3];

    let aFil3Value = "";


    if (fil3Value == "DB Fetch") {//Filter values which cannot be hardcoded and have to be fetched on the database condition, example- name of employees
      // const employeesRef = rbaseRef(database, `employees/${props.passMap.uid}`);

      // const empData = await get(employeesRef);

      // const data = { ...empData.val() };
      // const tArr = [];
      // Object.keys(data).forEach((key) => {
      //   tArr.push(`${data[key]["name"]}^${key}`);
      // });
      // fil3Values = [...tArr];
      // fil3Values.push("All");
      // console.log(fil3Values);

      // const tFilValues = [...filValues];
      // tFilValues.splice(1, 1, fil3Values);
      // console.log(tFilValues)
      // setFilValues(tFilValues);
      // //aFil3Value = fil3Values[0].split("^")[1];
      // aFil3Value = fil3Values[0];
      // setFil3Value(aFil3Value);
    } else {
      fil3Values = filValues[1];
      aFil3Value = fil3Value;
    }


    //STEP 1 : Creating Scheme Map for Chart Data
    fil3Values.forEach((iFil3Value) => {
      const fil3Value =
        iFil3Value.split("^").length > 1
          ? iFil3Value.split("^")[1]
          : iFil3Value.split("^")[0];
      tMap[fil2Value][fil3Value] = {}; //Eg tMap[2022][Skate Sharpening]={}
      // tMap["All"][fil3Value] = tMap["All"][fil3Value] || {};

      tMap2[fil2Value][fil3Value] = {}; //Eg tMap[2022][Skate Sharpening]={}
      // tMap2["All"][fil3Value] = tMap["All"][fil3Value] || {};


     filAValues = filASelectionMap[fil3Value];

     console.log(filAValues)

      filAValues.forEach((filAValue) => {
        tMap[fil2Value][fil3Value][filAValue] = {};
        // tMap["All"][fil3Value][filAValue] =
        //   tMap["All"][fil3Value][filAValue] || {};

          tMap2[fil2Value][fil3Value][filAValue] = {};
          // tMap2["All"][fil3Value][filAValue] =
          //   tMap2["All"][fil3Value][filAValue] || {};


       // tMap["All"]["All"][filAValue] = tMap["All"]["All"][filAValue] || {};
        fil4Values.forEach((fil4Value, i) => {
          const labelsForFil4 = props.passMap.chartLabels[topicIndex][i];
          const zeroAvgArr = labelsForFil4.map((it) => {
            return 0;
          });
          // const zeroItArr = labelsForFil4.map((it) => {
          //   return [];
          // });

          const zeroItArr = labelsForFil4.map((it) => {
            return {"arr":[]};
          });


          console.log(tMap2);

          tMap[fil2Value][fil3Value][filAValue][fil4Value] = [...zeroAvgArr];
          tMap2[fil2Value][fil3Value][filAValue][`${fil4Value}Items`] = [
            ...zeroItArr,
          ]; //Eg tMap[2022][Skate Sharpening][Hour]=[0,0,0,0,0]
          // tMap["All"][fil3Value][filAValue][fil4Value] = tMap["All"][fil3Value][
          //   filAValue
          // ][fil4Value] || [...zeroAvgArr];
          // tMap2["All"][fil3Value][filAValue][`${fil4Value}Items`] = tMap["All"][
          //   fil3Value
          // ][filAValue][`${fil4Value}Items`] || [...zeroItArr];

          // tMap["All"]["All"][filAValue][fil4Value] = tMap["All"]["All"][
          //   filAValue
          // ][fil4Value] || [...zeroAvgArr];
          // tMap["All"]["All"][filAValue][`${fil4Value}Items`] = tMap["All"][
          //   "All"
          // ][filAValue][`${fil4Value}Items`] || [...zeroItArr];
        });
      });
    });
//End of STEP 1;
    console.log(tMap);

    // const fil4Index = fil4Values.indexOf(fil4Value);


    // const monthFil4Index =
    //   filValues[2].indexOf("Month");
    // const dayFil4Index =
    //   filValues[2].indexOf("Day");


     //STEP 2 : Extracting Reference Values from the Past Order Seq based on the chart filters i.e. FilVal2, FilVal3, FilVal4 etc

    docColl.docs.forEach((doc, i) => {
      const valueSeqs = doc.data().stringSeqDatas;

      console.log(valueSeqs);

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqSegmentColl = valueSeq.split("~").map((it)=>it.split("^"));
        const valueSeqArr = valueSeq.split("^");

        console.log(valueSeqArr)

        const filRefDbKeys = props.passMap.filRefDbKeys[topicIndex]; //Ex- "orderCompletionDateTime"
        const valueSeqDbKeys = props.passMap.valueSeqDbKeys;
        const refValues = [];

        console.log(filRefDbKeys);
        console.log(valueSeqSegmentColl);
        console.log(valueSeqDbKeys);

        filRefDbKeys.forEach((filRefKey, i) => {
          if (i != 3) {
            // const mIndex = valueSeqDbKeys.indexOf(filRefKey);

             const value = filRefKey===null ? fil3Value : findAndGetRefValueFromDataSeq(valueSeqSegmentColl,valueSeqDbKeys,filRefKey)

             console.log(filRefKey,value)
        
              refValues.push(value)
          // refValues.push(valueSeqArr[mIndex]); //Final Output Ex - [8712128721,"Skate Profiling",8712128721]
          } else {
            // if it is a variable key selection filter ex - Avg Wait Time, Avg Job Time etc
            const varFilRefDbKeys = [...filRefKey];
            const tArr = [];
            varFilRefDbKeys.forEach((varFilRefKeys) => {
              //const mIndex = valueSeqDbKeys.indexOf(varFilRefKey);
              tArr.push(findAndGetRefValueFromDataSeq2(valueSeqSegmentColl,valueSeqDbKeys,varFilRefKeys));
     
            });
            refValues.push(tArr);
          }
        });

        console.log(refValues);

        console.log(fil2Value, getCompRefValue(refValues[0], fil1Value));

        //eg if (2022==getCompRefValue(3438923992389,"Year"))

             //STEP 3 : Filling the Chart Data Map based on conditional
        if (fil2Value == getCompRefValue(refValues[0], fil1Value)) {
          const fil3Entry = refValues[1]; //Ex - Hollow Radius

          console.log(filAValues)

          fil3Values.forEach((fil3Entry,i)=>{

            filAValues = filASelectionMap[fil3Entry];

            filAValues.forEach((filAValue, j) => {
              let filAEntry = 0;
            //let resultOp = filAValue.includes("No of ") ? "Count" : "Average";
              let resultOp =  "Count"
 
              filAEntry = refValues[3][i] ? refValues[3][i] : 0;
 
               console.log(filAValue,filAEntry)
               
               if(filAValue==filAEntry){
       
                 fil4Values.forEach((fil4Value, i) => {
                   //Inserting Values for all fil4Labels (ex- hour, day, month) to the final child array which will be the data for the chart
                   const fil4Entry = getCompRefValue(refValues[2], fil4Value);
                 
                   const fil4ChartLabelsColl = [...props.passMap.chartLabels[topicIndex]];
                   const fil4EntryIndex = fil4ChartLabelsColl[i].indexOf(fil4Entry);
 
                   console.log(fil2Value,fil3Entry,filAValue,fil4Entry,fil4Value,fil4EntryIndex);
 
 
                  console.log(tMap2)
                   console.log(filAValue,filAEntry)
 
                    //  tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                    //    fil4EntryIndex
                    //  ].push(1); //Incrementing the position of the fil4Entry

                    tMap2[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                      fil4EntryIndex
                    ]["arr"].push(1); //Incrementing the position of the fil4Entry
 
                    //  tMap["All"][fil3Entry][filAValue][`${fil4Value}Items`][
                    //    fil4EntryIndex
                    //  ].push(filAEntry); //Incrementing the position of the fil4Entry
                   
 
                   console.log(tMap)
 
                   tMap[fil2Value][fil3Entry][filAValue][fil4Value][fil4EntryIndex] = tMap2[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][fil4EntryIndex]["arr"].length

 
                   // tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                   //   fil4EntryIndex
                   // ].push(filAEntry); //Incrementing the position of the fil4Entry
 
                   // tMap[fil2Value]["All"][filAValue][fil4Value][fil4EntryIndex] =
                   //   resultOp == "Count"
                   //     ? tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                   //         fil4EntryIndex
                   //       ].length
                   //     : getArrayAvg(
                   //         tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                   //           fil4EntryIndex
                   //         ]
                   //       ); //Incrementing the position of the fil4Entry
 
                  //  tMap["All"][fil3Entry][filAValue][fil4Value][fil4EntryIndex] = tMap["All"][fil3Entry][filAValue][`${fil4Value}Items`][fil4EntryIndex].length; 
 
                       
 
                   // tMap["All"]["All"][filAValue][`${fil4Value}Items`][
                   //   fil4EntryIndex
                   // ].push(filAEntry); //Incrementing the position of the fil4Entry
 
                   // tMap["All"]["All"][filAValue][fil4Value][fil4EntryIndex] =
                   //   resultOp == "Count"
                   //     ? tMap["All"]["All"][filAValue][`${fil4Value}Items`][
                   //         fil4EntryIndex
                   //       ].length
                   //     : getArrayAvg(
                   //         tMap["All"]["All"][filAValue][`${fil4Value}Items`][
                   //           fil4EntryIndex
                   //         ]
                   //       ); //Incrementing the position of the fil4Entry
 
             
 
                 });
 
           }
           });


          })
        }
      });
    });

    console.log(tMap);
    setChartReady(true)
    props.stopSpinner()

    
    setDoc(doc(db,`storeBranches/${props.passMap.uid}/stats`,`skate`),{data:JSON.stringify(tMap),docModificationDate:serverTimestamp()}).then(()=>{
      console.log("success")
   }).catch((error)=>{
    console.log("oh no")
    console.log(error.message)
   });

    updateChart(tMap, fil2Value, aFil3Value, fil4Value, filAValue,filValues);
    return { ...tMap };
    // }
  }


  
  function findAndGetRefValueFromDataSeq(seqValuesColl,seqKeysColl,filRefKey){

    if(seqKeysColl.length == 1){

      const index = seqKeysColl[0].indexOf(filRefKey)
      return seqValuesColl[0][index]

    }else if(seqKeysColl.length > 1){
      if(seqKeysColl[0].includes(filRefKey)){
        const indexI = seqKeysColl[0].indexOf(filRefKey)
        return seqValuesColl[0][indexI]
      }else{
        for(let i=1;i<seqKeysColl.length;i++){
          if(seqKeysColl[i].includes(filRefKey)){
            const indexJ = seqKeysColl[i].indexOf(filRefKey);
            if(seqValuesColl.length > i && seqValuesColl[i].length > indexJ){
              return seqValuesColl[i][indexJ]
            }else{
              return null
            }
          }
        }
      }
    }

  }

  function findAndGetRefValueFromDataSeq2(seqValuesColl,seqKeysColl,sFilRefKeys){
    const values = []

    if(seqKeysColl.length == 1){

      sFilRefKeys.forEach((key,i)=>{
          const index = seqKeysColl[0].indexOf(key);
          values.push(seqValuesColl[0][index])
      });

      return values.join("^")

      // const index = seqKeysColl[0].indexOf(filRefKey)
      // return seqValuesColl[0][index]

    }else if(seqKeysColl.length > 1){

      sFilRefKeys.forEach((key,i)=>{

        if(seqKeysColl[0].includes(key)){
          const indexI = seqKeysColl[0].indexOf(key)
          values.push(seqValuesColl[0][indexI])
          //return seqValuesColl[0][indexI]
        }else{
          for(let i=1;i<seqKeysColl.length;i++){
            if(seqKeysColl[i].includes(key)){
              const indexJ = seqKeysColl[i].indexOf(key);
              if(seqValuesColl.length > i && seqValuesColl[i].length > indexJ){
                values.push(seqValuesColl[i][indexJ])
                //return seqValuesColl[i][indexJ]
              }else{
               // return null
              }
            }
          }
        }
      })
    }

    return values.join("^")


  }



  function tabClickHandler(selectedIndex,tabName,compDataMap, fil2Value, fil3Value, filAValue) {
    //setTabChanged(true);
    setTabFocusIndex(selectedIndex)

    options.scales.x.title.text = props.passMap.axisLabels[topicIndex][0][selectedIndex]


    //setCurTabName(tabName);
    fil4ValueChange(tabName, compDataMap, fil2Value, fil3Value, filAValue)
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );



  
  function updateChart(
    sCompDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue,
    filValues
  ) {
    const mIndex = filValues[2].indexOf(fil4Value);
    const labels = props.passMap.chartLabels[topicIndex][mIndex];




    const colors = ["#C73C3C","#3C3C3C","#C7C7C7"]

    let data = {}


    
    if(fil3Value=="All"){

      console.log(filValues)

      const fFilValues = [...filValues[1]];
      //if it is topic index 1, it means the fil3Values are dynamically generated - example employees. 
      //We cannot have a set values for employees and hence it has to fetch the database

      console.log(fFilValues)

      fFilValues.pop();

      const datasets = [];

      fFilValues.forEach((value,i)=>{
        console.log(value)

        const map = {};
        const valueStr = value.toString();
       
        const fFil3Value = valueStr.split("^").length > 1 ? valueStr.split("^")[1] : valueStr
        const fFil3Label = valueStr.split("^").length > 1 ? valueStr.split("^")[0] : valueStr
       
        //console.log(valueStr.split("^"),valueStr.split("^").length,valueStr.split("^")[1],value,valueStrValComp,valueStrLabelComp)

        map["label"] = fFil3Label;
        map["data"] = sCompDataMap[fil2Value][fFil3Value][filAValue][fil4Value];
        map["borderColor"] = colors[i]
        map["backgroundColor"] = colors[i]

        datasets.push(map);
      })

      data={
        labels: labels,
        datasets: datasets
      }


    }else{



    const fFil3Value = fil3Value.split("^").length > 1 ? fil3Value.split("^")[1] : fil3Value
    const fFil3Label = fil3Value.split("^").length > 1 ? fil3Value.split("^")[0] : fil3Value 

    console.log(sCompDataMap,fil2Value,fFil3Value,filAValue,fil4Value)

    data = {
        labels: labels,
      datasets: [
        {
          label: `${fFil3Label} - ${filAValue.split("^").join(" ")} (Total : ${getArraySum(sCompDataMap[fil2Value][fFil3Value][filAValue][fil4Value])})`,
          data: sCompDataMap[fil2Value][fFil3Value][filAValue][fil4Value],  
          borderColor: "#C73C3C",
          backgroundColor: "#C73C3C",
        },
      ],
    };

  }


    console.log(data);

    // setDatasetKey(`${fil2Value}-1`);
    setChartOptions({ ...options });

    setChartData({ ...data });
  }





  function updateChartByDateRange(sCompDataMap, fil2Value, fil3Value,filAValue, dateRangeStartStr,dateRangeEndStr,filValues) {
    //const mIndex = props.passMap.filValues[topicIndex][2].indexOf(fil4Value);
   // const labels = props.passMap.chartLabels[topicIndex][mIndex];


  //  console.log(sCompDataMap)
  //  console.log(fil2Value,fil3Value,filAValue)
  //  console.log(sCompDataMap[fil2Value][fil3Value]["Date Range"])

    //const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][fil3Value][filAValue]["Date Range"]);

    let data = {}
    const colors = ["#C73C3C","#3C3C3C","#C7C7C7"]

    if(fil3Value=="All"){

      const alLFil3Values = [...filValues[1]];

      alLFil3Values.pop();

      const datasets = [];

      alLFil3Values.forEach((iFil3Value,i)=>{
        const map = {};
       // const valueStr = iFil3Value.toString();

        const fFil3Value = iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[1] : iFil3Value
        const fFil3Label = iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[0] : iFil3Value 

        const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][fFil3Value][filAValue]["Date Range"]);

        data["labels"] = dateRangeLabelAndData["rangeLabels"]
        map["label"] = fFil3Label;
        map["data"] = dateRangeLabelAndData["rangeLabelValues"];
        map["borderColor"] = colors[i]
        map["backgroundColor"] = colors[i]

        datasets.push(map);
      })

     data["datasets"] = [...datasets]


    }else{

      console.log(fil2Value,fil3Value,filAValue)

      const fFil3Value = fil3Value.split("^").length > 1 ? fil3Value.split("^")[1] : fil3Value
      const fFil3Label = fil3Value.split("^").length > 1 ? fil3Value.split("^")[0] : fil3Value 

      const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][fFil3Value][filAValue]["Date Range"]);

    data = {
      labels: dateRangeLabelAndData["rangeLabels"],
      datasets: [
        {
          label: `${fFil3Label} - ${filAValue.split("^").join(" ")} (Total : ${getArraySum(dateRangeLabelAndData["rangeLabelValues"],)})`,
          data: dateRangeLabelAndData["rangeLabelValues"],
          borderColor: "#C73C3C",
          backgroundColor: "#C73C3C",
        },
      ],
    };

  }


  
    setChartOptions({ ...options });

    setChartData({ ...data });
  }




  function getDateRangeLabelAndDataMap(startDate,endDate,chartValues){

    console.log(chartValues)
    console.log(startDate,endDate)

    const rMap={};

    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    console.log(date2.getFullYear())
    console.log(date1.getFullYear())

    if(isNaN(date1.getFullYear())){
      date1.setFullYear(date2.getFullYear())
      date1.setMonth(0);
      date1.setDate(1);
    }else if(isNaN(date2.getFullYear())){
      date2.setFullYear(date1.getFullYear())
      date2.setMonth(11);
      date2.setDate(31);
    }

    console.log(date1,date2)

    const date1Map = {};
    const date2Map = {};

    date1Map["dayOfTheYear"] = getDayOfTheYear(date1);
    date2Map["dayOfTheYear"] = getDayOfTheYear(date2);


    const startIndex =  date1Map["dayOfTheYear"]-1; //Exact Index as in Array.slice the start index is inclusive. Also as year array starts from 1 and not 0, so day 1 will have an index of 1-1=0;
    const endIndex = date2Map["dayOfTheYear"]; //End Index in Array.slice is exclusive.

   console.log(chartValues)

    rMap["rangeLabelValues"] = chartValues.slice(startIndex,endIndex);
    rMap["rangeLabels"] = getDateRangeLabels(date1,date2);

    
    
    function getDateRangeLabels(date1,date2){

    const date1Map = {};
    const date2Map = {};

      date1Map["dayOfTheYear"] = getDayOfTheYear(date1);
      date2Map["dayOfTheYear"] = getDayOfTheYear(date2);
      // date1Map["month"] = getMonth(date1);
      // date2Map["month"] = getMonth(date2);

      const rangeIncrements = generateIncrementNumArray(date1Map["dayOfTheYear"],date2Map["dayOfTheYear"]);

      const arr = [...rangeIncrements]

      const arr1 = arr.map((it,i)=>
        getValue(it,date1.getFullYear())
    )



    
    
    
    function getValue(dayOfYear,year){        
    const dateObj = getDateObjFromDayOfYearAndYear(dayOfYear,year)
    console.log(dateObj)
    const monthNo = dateObj.getMonth()+1;
    const dayOfMonth = dateObj.getDate();
    console.log(`${dayOfMonth}/${monthNo}`)
    return `${dayOfMonth}/${monthNo}`
    }

      return arr1;


    }


    return rMap



  }



  function fil5aValueChange(recFil5aValue,fil5bValue, compDataMap, fil2Value, fil3Value,filAValue) {
    console.log(recFil5aValue)
    console.log(new Date(recFil5aValue))
    setFil5aValue(recFil5aValue);
    console.log(compDataMap);
    updateChartByDateRange(compDataMap, fil2Value, fil3Value,filAValue,recFil5aValue,fil5bValue,filValues,filValues);
  }

  function fil5bValueChange(fil5aValue,recFil5bValue, compDataMap, fil2Value, fil3Value,filAValue) {
    setFil5bValue(recFil5bValue);

    updateChartByDateRange(compDataMap, fil2Value, fil3Value,filAValue, fil5aValue,recFil5bValue,filValues);
  }

  function fil2ValueChange(e, compDataMap, fil3Value, fil4Value, filAValue) {
    const recFil2Value =
      e.target.value == "All" ? e.target.value : parseInt(e.target.value);
    setFil2Value(recFil2Value);

    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");

    if (recFil2Value != "All") {
      initializePageWithData(
        compDataMap,
        recFil2Value,
        fil3Value,
        fil4Value,
        filAValue
      );
    } else {
      setChartReady(true)
      props.stopSpinner()
      updateChart(compDataMap, recFil2Value, fil3Value, fil4Value, filAValue,filValues);
    }
    console.log(e.target.value);
  }

  //on change of fil3 value, the selections of filA should generate
  function fil3ValueChange(e, compDataMap, fil2Value, fil4Value, filAValue,filValues) {
    const recFil3Value = e.target.value;
    const newFilAValue = Array.isArray(chartOptionsMap[recFil3Value]) ? chartOptionsMap[recFil3Value][0] : "";

    setFil3Value(recFil3Value);
    console.log(compDataMap);
    console.log(e.target.value);

    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");


    setChartReady(true)
    props.stopSpinner()
    updateChart(compDataMap, fil2Value, recFil3Value, fil4Value, newFilAValue,filValues,filValues);
  }


  function filAValueChange(e, compDataMap, fil2Value, fil3Value) {
    
    const recFilAValue = e.target.value;

    console.log(recFilAValue)
    const selIndex =  props.passMap.filValues[topicIndex][3].indexOf(recFilAValue)
    //options.scales.y.title.text = props.passMap.axisLabels[topicIndex][1][selIndex]

    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");

    setFilAValue(recFilAValue);
    updateChart(compDataMap, fil2Value, fil3Value, fil4Value, recFilAValue,filValues);
  }


  function fil4ValueChange(
    recFil4Value,
    compDataMap,
    fil2Value,
    fil3Value,
    filAValue
  ) {
    setFil4Value(recFil4Value);
    setChartReady(true)
    props.stopSpinner()
    updateChart(compDataMap, fil2Value, fil3Value, recFil4Value, filAValue,filValues);
  }

  const tabNames=props.passMap.filValues[topicIndex][2]

    //To prevent date range from appearing when the year is selected as All
    function getTabNames(tabNames,fil2Value){
      if(fil2Value=="All"){
  
        const index = tabNames.indexOf("Date Range");
        tabNames.splice(index,1);
      
      }
        return tabNames;
      
    }

    function filA1ValueChange(){

    }


    function getFilASelections(compDataMap, fil2Value, fil3Value, fil4Value){

      const values = chartOptionsMap[fil3Value];

      console.log(values)
      
      if(typeof values == "object"){

          if(Array.isArray(values)){

            const selections =   values.map((value)=><option value={value}>{value}</option>)

            return selections;

            return (<select
              key={``}
              onChange={(e) =>
                filA1ValueChange(e, compDataMap, fil2Value, fil3Value, fil4Value)
              }
            >{selections}</select>)
            
          }else{

            const selections1 = Object.keys(values);

              return(<div><select onChange={(e) =>
                filAValueChange(e, compDataMap, fil2Value, fil3Value, fil4Value)
              }>{selections1}</select> <select></select></div>)
          
          }
    }
  }


  return (
    <div className="grid p-cust-topHead pb-28">
      <div className="flex justify-center items-center gap-16">
        <div className="flex gap-4 p-8">
          <div>{filCategories[0]}</div>
          <select
            key={`${props.passMap.topic}-1`}
            onChange={(e) =>
              fil2ValueChange(e, compDataMap, fil3Value, fil4Value, filAValue)
            }
          >
            {filValues[0].map((optionName) => {
              return <option value={optionName}>{optionName}</option>;
            })}
          </select>

        </div>
        <div className="flex gap-4">
            <div>{filCategories[1]}</div>
            <select
              key={`${props.passMap.topic}-2`}
              onChange={(e) =>
                fil3ValueChange(e, compDataMap, fil2Value, fil4Value, filAValue,filValues)
              }
            >
              {filValues[1].map((optionName) => {
                return (
                  <option
                    value={
                      optionName
                      // optionName.split("^").length == 2
                      //   ? optionName.split("^")[1]
                      //   : optionName.split("^")[0]
                    }
                  >
                    {optionName.split("^")[0]}
                  </option>
                );
              })}
            </select>
        </div>

          <div className="flex gap-4">
              <div>{filCategories[2]}</div>
              {props.passMap.filRefDbKeys[topicIndex][3][props.passMap.filValues[topicIndex][1].indexOf(fil3Value)].length == 1 && 
                  <select
                    key={`${props.passMap.topic}-3`}
                    onChange={(e) =>
                      filAValueChange(e, compDataMap, fil2Value, fil3Value, fil4Value)
                    }
                  >
                  
                    {getFilASelections(compDataMap, fil2Value, fil3Value, fil4Value)}

                  </select> 
              }

              {props.passMap.filRefDbKeys[topicIndex][3][props.passMap.filValues[topicIndex][1].indexOf(fil3Value)].length == 2 && 
              
              <FilterAssembly
                selectionMap = {chartOptionsMap[fil3Value]}
                onValueSelected = {(eventMaskObj)=>filAValueChange(eventMaskObj, compDataMap, fil2Value, fil3Value, fil4Value)}
              />
              }



        </div>
        <div className="flex gap-4">
        <TabContainer
          // tabNameList={props.passMap.inputCategories}
          tabNameList={getTabNames(tabNames,fil2Value)}
          tabFocusIndex={tabFocusIndex}
          tabClickHandler={(focusIndex) => tabClickHandler(focusIndex,tabNames[focusIndex],compDataMap, fil2Value, fil3Value, filAValue)}
        />

{fil4Value=="Date Range" && fil2Value != "All" &&
        <div>
            <InputDate
              key={`a`} //passDataObj has all data from props object
              inputName={""}
              inputValue={fil5aValue}
              selYearValue={fil2Value}
              onChangeFn={(e) => fil5aValueChange(e.target.value,fil5bValue,compDataMap, fil2Value, fil3Value, filAValue)}
            />
        <InputDate
              key={`b`} //passDataObj has all data from props object
              inputName={""}
              inputValue={fil5bValue}
              selYearValue={fil2Value}
           
              onChangeFn={(e) => fil5bValueChange(fil5aValue,e.target.value,compDataMap, fil2Value, fil3Value, filAValue)}
            />
        </div>

        }


        </div>

      </div>
      <div className="w-9/12 mx-auto items-center justify-center">
      {chartReady&&
        <Line options={{ ...chartOptions }} data={{ ...chartData }} />
      }
      </div>
      <FloatingButton btnLabel="Back" positionRight="10" positionTop="20" btnClickHandler={()=>props.backBtnClickHandler()} />
      {/* <div className="absolute w-full h-24 bottom-0 flex justify-center items-center">
        {bottomContainer}
        </div> */}
    </div>
  );
}

function getCompRefValue(value, filValue) {
  switch (filValue) {
    case "Year": {
      return new Date(parseInt(value) * 1000).getFullYear();
    }
    case "Month": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getMonth(), "Month");
    }
    case "Day": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getDay(), "Day");
    }
    case "Hour": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getHours(), "Hour");
    }
    case "Date Range": {
      return getTimeLabel(getDayOfTheYear(new Date(parseInt(value) * 1000)), filValue);
    }
  }
}
