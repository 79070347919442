import React, { useEffect, useState } from "react";
import BottomContainer from "./BottomContainer";
import RecordBtnStrip from "./RecordBtnStrip";


//import DMSansBold from "./DMSans-Bold.ttf"


// const pdfDocument = require("pdfkit")
// const blobStream = require("blob-stream")

//import blobStream from '../libs/blob-stream';
//import pdfkit from '../libs/pdfkit';
//import PDFDocument from '../libs/pdfkit-table.js'



import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";
import {
  db,
  generateIncrementNumArray,
  generateInvoice,
  limit,
  orderBy
} from "../Utils";
import NoAvailableDisplay from "./NoAvailableDisplay";

const pageLimit = 5;

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}

// Font.register({
//   family: 'DMSans',
//   fonts: [
//     {
//       src: DMSansBold,
//     }
//   ],
// });

function getDbCollPath(props){
  switch(props.passMap.listTopic){
    case "Unpaid Invoice":{
      return `admin/accounts/unpaidInvoices`
    }
  }
}


export default function StripListBtnCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );



  console.log(props.passMap);

  const [compListDataMap, setCompListDataMap] = useState({
    valueSeqs: [],
    slNos: [],
  });



  const [pageStripValuesColl,setPageStripValuesColl]=useState([])

  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});
  const [recordCount, setRecordCount] = useState(
    0
  );
  const [pageNo, setPageNo] = useState(0);
  const [viewMode, setViewMode] = useState("normal");
  const [isLoading,setIsLoading] = useState(true)

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {

    console.log(props.passMap)

      const collQuery = query(
        collection(
          db,
          getDbCollPath(props)
        ),
        where("invoicePartyUids","array-contains",props.passMap.storeBranchUid),
        orderBy("docCreationDate", "desc"),
        limit(2) //Limiting 2, because each transaction document will hold 100 transaction values, so 2 docs will obviously fill the page
      );
    

    const dbDocs = await getDocs(collQuery);

    console.log(dbDocs.size)

    if (dbDocs.size != 0) {
      setCompListDataMap(
        extractAndSetViewDataMap( dbDocs, compListDataMap, 1)
      );
    }else{
      props.stopSpinner()
      setIsLoading(false)
    }
  }

  function extractAndSetViewDataMap(
    dbDocs,
    compListDataMap,
    pageNo
  ) {
    const tArray1 = [];
    let slNoCount=0;

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringSeqDatas;


      console.log(doc.data());

      //Extraction of data for the strips

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");
        const topicKeyIndex  = props.passMap.valueSeqDbKeys.indexOf("invoicePartyUid");

        if(valueSeqArr[topicKeyIndex]==props.passMap.storeBranchUid){

          slNoCount++;

          const uArr=[]
  
          props.passMap.labelDbKeys.forEach((dbNotation)=>{
            console.log(dbNotation)
            const mIndex = props.passMap.valueSeqDbKeys.indexOf(dbNotation)
  
            if(dbNotation=="slNo"){ //Checking if key is slNo,as sl No is to be put first and it is not in the database
              uArr.unshift(slNoCount)
            }else{
              if(mIndex!=-1){ //checking if the key is common in both the string value seq and the required db key list
                uArr.push(valueSeqArr[mIndex])
              }
            }
          })

        tArray1.push(uArr);
        }

      });

    });

    compListDataMap.valueSeqs = [...tArray1];

    const sRecordCount = compListDataMap.valueSeqs.length;
    setRecordCount(sRecordCount)

    displayPage(sRecordCount, pageNo, compListDataMap);

    console.log(compListDataMap);
    props.stopSpinner()
    setIsLoading(false)
  
    return compListDataMap;
  }

  console.log(pageNo);


  function displayPage(sRecordCount, sPageNo, compListDataMap) {
    if (sPageNo !== pageNo) {
      setPageNo(sPageNo);
      console.log(sPageNo);

      const latestSl =
        props.passMap.listTopic == "Transaction"
          ? props.passMap.latestTransactionSl
          : sRecordCount;
     // const slNoArray = compListDataMap.slNos;

      console.log(latestSl);


      const startSlOfPage =
        pageLimit * (sPageNo - 1) + 1 > sRecordCount
          ? sRecordCount
          : pageLimit * (sPageNo - 1) + 1;
      const endSlOfPage =
        pageLimit * sPageNo > sRecordCount ? sRecordCount : pageLimit * sPageNo;

        const rArray=[];

        for (let y = startSlOfPage; y <= endSlOfPage; y++) {
          const stripValueArray = [...compListDataMap.valueSeqs[y-1]];
          //stripValueArray.unshift(y);
          rArray.push(stripValueArray);
        }


        setPageStripValuesColl([...rArray])
        props.stopSpinner()
        setIsLoading(false)
    }
  }

  console.log(pageStripValuesColl)



  async function fetchAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    compListDataMap,
    pageNo
  ) {

    //const docName = props.passMap.listTopic=="Transaction"?"storeBranchTransactions":props.passMap.listTopic=="Past Order"?"pastOrders":""

    const collQuery = query(
      collection(
        db,
        getDbCollPath(props)
      ),
      where("recordNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setCompListDataMap(
      extractAndSetViewDataMap(dbDocs, compListDataMap, pageNo)
    );
  }

  function renderPagination(
    sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {

    console.log(sRecordCount);
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    // const rArray = [];

    // console.log(sListValuesCollMap);

    // for (let x = 0; x < pageCount; x++) {
    //   const element = (
    //     <button
    //       onClick={() => displayPage(sRecordCount, x + 1, sListValuesCollMap)}
    //       className={`p-cust-3 ${
    //         x + 1 == sPageNo ? `border-page-no-indicator` : ``
    //       }`}
    //     >{`${x + 1}`}</button>
    //   );
    //   rArray.push(element);
    // }

    // return rArray;

    const element = (
      <select className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayPage(sRecordCount,e.target.value+1,  sListValuesCollMap)}>
        {generateIncrementNumArray(1,pageCount).map(pageNo =>
          <option value={pageNo}>{pageNo}</option>
        )
        }
      </select>)

      return element
  }

  async function btnClicked(data, tabMap) {
    const map = data.stripValues;
    switch (data.btnType) {
      case "View":
        {

        console.log(map)
        const invoiceId =map.invoiceId;

    
        const docDbCall = query(collection(db,`admin/accounts/invoices`),where("invoiceId","==",map[1]));
        const dbDocs = await getDocs(docDbCall);

        const invMap = dbDocs.docs[0].data();

        generateInvoice(invMap)

        }
        break;

      }

    }

  
  

  return (
    <React.Fragment>
    {!isLoading && 
    <div className="h-screen grid grid-rows-cust-2c p-cust-topHead">
    {pageStripValuesColl.length != 0 &&
    <div className="pb-16">
      <div className="flex flex-col justify-center items-center overflow-y-auto p-8">
        <div className="flex">
          {viewMode=="normal" && renderPagination(pageLimit, compListDataMap, recordCount, pageNo)}
        </div>
      </div>
      <div className="w-11/12 grid gap-4 grid-cols-cust-2e mx-auto mt-1 text-sm">
        <div
          className={`grid mx-auto w-full grid-columns-${props.passMap.labelDbKeys.length}a bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
          <div className="flex items-center justify-center text-darkBackText bg-secondary text-center rounded-sm border border-borderStd ">
            Action
          </div>
      </div>


                {pageStripValuesColl.map((stripValues) => {
            return (
              <RecordBtnStrip
                stripValues={stripValues}
                labelNames={props.passMap.labelNames}
                passMap2={props.passMap.settings}
                btnLabels={props.passMap.btnLabels}
                btnAlertLevels={props.passMap.btnAlertLevels}
                btnClickHandler={(data)=>btnClicked(data)}
              />
            )})}
      
      </div>

      }
      {pageStripValuesColl.length == 0 &&
      <div className="pb-16">
                      <NoAvailableDisplay textName="No Records Found"/>
                      </div>
        }
      <div className="absolute bottom-0 h-24 w-full flex justify-center items-center gap-4">
         {bottomContainer}
      </div>
    </div>

   }
   </React.Fragment>
  );
}



