import React from "react";

export default function RecordLabelUnit2(props) {

  const textColor = props.textColorScheme=="green" ? "text-accent-green" : "text-secondary-2"
  return (
    <div className={`p-2 h-14 grid ${props.showLabelCond ? `grid-cols-cust-2d` : ``} bg-primary-400 items-center text-center border-inner-light`}>
            {props.showLabelCond && 
              <div className="text-sm text-left">{props.labelName}</div>
            }
      <div className={`font-medium ${textColor} text-lg font-montserrat break-words`}>{props.labelValue}</div>
    </div>
  );
}
