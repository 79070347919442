import { React, useState } from "react";
import skateLogo from "../images/app-logo.svg";
import InputTile2 from "./InputTile2";
import LoadSpinner from "./LoadSpinner";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";

import {
  functions,
  httpsCallable
} from "../Utils.js";

export default function AccountDeleteRequest(props) {
  /*     function clickFunction(){
        props.onClickFnIntermediate();
    } */

    const [email,setEmail] = useState("")
    const [showPrompt,setShowPrompt] = useState(false)
    const [promptMap,setPromptMap] = useState({})
    const [showSpinner,setShowSpinner]=useState(false);

    console.log(props)

    function onChangeFn(e){
      const value = e.target.value;
      setEmail(value)
    }


    async function requestAccountDeletion(email){
      setShowSpinner(true)

      const cloudFnCall = httpsCallable(functions, "requestAccountDeletion");
      const op = await cloudFnCall({
        email:email
      });

      setShowSpinner(false)
        displayPrompt("Account Deletion Request",op.data.message)
     
    }

    
  function displayPrompt(title,message,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }

  return (
    <div className="h-screen p-16 bg-quaternary-light">

      <div className="w-4/12 flex flex-col items-center">
         <img className="w-40" src={skateLogo} />
         <div className="text-4xl font-bold py-4 text-center">ACCOUNT DELETION REQUEST</div>
      </div>

      <div className="my-16 text-xl">
          Please kindly fill in your email id and submit inorder to request account deletion. We will send you a verification mail to your email id, which will provide you a link to delete your account and related data.
      </div>

      <div className="flex flex-col justify-center items-center gap-y-8">
            <InputTile2
            inputType="text"
            inputName="email"
            inputLabel="Email"
            regexDomain="email"
            onChangeFn={(e)=>onChangeFn(e)}
            />

            <button className="btn-action" onClick={async ()=>await requestAccountDeletion(email)}>Submit</button>
      </div>



      {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
      {showSpinner && <LoadSpinner />}
    </div>
  );
}
