import React from "react";

export default function SelectionBanner(props) {

  console.log(props);


  function getCardUnits(props){
    const jsxBlock = []

    const slNoUnit = <div className="h-full flex col-start-2 col-end-3 row-start-2 row-end-4 justify-end items-center text-4xl">
      {props.slNo}
    </div>
        jsxBlock.push(slNoUnit)

    const title =  <div className="h-full flex col-start-1 col-end-2 row-start-1 row-end-2 justify-start items-center text-2xl text-secondary-3">
    {props.schemeMap.labelTitleDbNotations.map((dbNotation,i)=>{
    if(i==props.schemeMap.labelTitleDbNotations.length-1){
      return `${props.dataMap[dbNotation]}` //For the end item - no space is needed
    }else{
      return `${props.dataMap[dbNotation]} ` //For other words where a space is needed after
    }

  })}
    </div>
    jsxBlock.push(title)


    for(const [i,dbNotation] of props.schemeMap.labelBodyDbNotations.entries()){

      const rowStart = props.schemeMap.labelBodyRowPositions[i][0]
      const rowEnd = props.schemeMap.labelBodyRowPositions[i][1]
      
      const colStart = props.schemeMap.labelBodyColPositions[i][0]
      const colEnd = props.schemeMap.labelBodyColPositions[i][1]

      const horizontalAlign = colEnd==3 ? `justify-end` : `justify-start`

      const value = props.schemeMap.labelBodyDbNotations[i].map((it)=>props.dataMap[it]).join(" ")
      const jsxUnit = <div className={`h-full flex col-start-${colStart} col-end-${colEnd} row-start-${rowStart} row-end-${rowEnd} ${horizontalAlign} items-center text-l`}>
      {value}
      </div>

      jsxBlock.push(jsxUnit)

    }


    return jsxBlock

  }



  return (
    <div className="m-4">
      <div 
      className="grid grid-cols-2m grid-rows-4 w-cust-30rem h-cust-15rem btn-theme rounded-lg bg-primary border-std items-center text-quaternary-light px-6"
      onClick={()=>props.clickHandler(props.dataMap)}>
        {/* <div className="h-full flex col-start-2 col-end-3 row-start-2 row-end-4 justify-center items-center text-4xl border-sl-no">
          {props.slNo}
        </div> */}
        {getCardUnits(props)}

        {/* <div className="h-full grid grid-rows-cust-2f grid-cols-cust-4a">
          {props.labelNames.map((labelName, index) => {
            return (
              <RecordLabelUnit
                key={labelName}
                colorScheme={props.bannerColorScheme}
                labelName={labelName}
                labelValue={getStoreLabelValue(
                  labelName,
                  props.passDataMap[props.labelDbKeys[index]],
                  props.passDataMap2
                )}
              />
            );
          })}
        </div> */}
      </div>

    </div>
  );
}

// typeof props.passDataMap[props.labelDbNotations[index]] == "object"
//   ? props.passDataMap[props.labelDbNotations[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbNotations[index]]
