import React, { Component } from "react";
import { getSettingsLabelValue,getFSettingsLabelValue,getDateInputStringFromMillis } from "../Utils";

export default function InputDate(props) {
  console.log(props)
  console.log(props.inputName)

  function getMinDateValue(yearInt){
    console.log(typeof yearInt)

    return `${yearInt}-01-01`

  }

  function getMaxDateValue(yearInt){

    const todaysDate = new Date()
    console.log(yearInt,todaysDate.getFullYear())

    if(yearInt==todaysDate.getFullYear()){
      console.log(todaysDate.getUTCMilliseconds())
      return getDateInputStringFromMillis(todaysDate.getTime())

    }else if(yearInt<todaysDate.getFullYear()){
      return `${yearInt}-12-31`
    }
    

  }



  return (
      <input
        className="pl-2"
        type="date"
        name={props.inputName}
        value={props.inputValue}
        onChange={props.onChangeFn}
        min={getMinDateValue(props.selYearValue)}
        max={getMaxDateValue(props.selYearValue)}
      ></input>
  );
}
