import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import RecordStrip from "./RecordStrip";
import FilterInput from "./FilterInput";
import ImageCont from "./ImageCont";
import RecordBanner from "./RecordBanner";
import NoAvailableDisplay from "./NoAvailableDisplay";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";

const pageLimit = 5;

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}

function getDbCollPath(props){
  switch(props.passMap.listTopic){
    case "Unpaid Invoice":{
      return `admin/accounts/unpaidInvoices`
    }
  }
}


export default function StripListCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );



  console.log(props.passMap);

  const [compListDataMap, setCompListDataMap] = useState({
    valueSeqs: [],
    slNos: [],
  });



  const [pageStripValuesColl,setPageStripValuesColl]=useState([])

  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});
  const [recordCount, setRecordCount] = useState(
    0
  );
  const [pageNo, setPageNo] = useState(0);
  const [viewMode, setViewMode] = useState("normal");

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {


      const collQuery = query(
        collection(
          db,
          getDbCollPath(props)
        ),
        where("invoicePartyUids","array-contains",props.passMap.storeBranchUid),
        orderBy("docCreationDate", "desc"),
        limit(2) //Limiting 2, because each transaction document will hold 100 transaction values, so 2 docs will obviously fill the page
      );
    

    const dbDocs = await getDocs(collQuery);

    console.log(dbDocs.size)




    if (dbDocs.size != 0) {
      setCompListDataMap(
        extractAndSetViewDataMap( dbDocs, compListDataMap, 1)
      );
    }
  }

  function extractAndSetViewDataMap(
    dbDocs,
    compListDataMap,
    pageNo
  ) {
    const tArray1 = [];
    let slNoCount=0;

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringSeqDatas;


      console.log(doc.data());

      //Extraction of data for the strips

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");
        const topicKeyIndex  = props.passMap.valueSeqDbKeys.indexOf("invoicePartyUid")

        if(valueSeqArr[topicKeyIndex]==props.passMap.storeBranchUid){

          slNoCount++;

          const uArr=[]
  
          props.passMap.labelDbNotations.forEach((dbNotation)=>{
            console.log(dbNotation)
            const mIndex = props.passMap.valueSeqDbKeys.indexOf(dbNotation)
  
            if(dbNotation=="slNo"){ //Checking if key is slNo,as sl No is to be put first and it is not in the database
              uArr.unshift(slNoCount)
            }else{
              if(mIndex!=-1){ //checking if the key is common in both the string value seq and the required db key list
                uArr.push(valueSeqArr[mIndex])
              }
            }
          })

        tArray1.push(uArr);
        }

      });

    });

    compListDataMap.valueSeqs = [...tArray1];

    const sRecordCount = compListDataMap.valueSeqs.length;
    setRecordCount(sRecordCount)

    displayPage(sRecordCount, pageNo, compListDataMap);

    console.log(compListDataMap);
    props.stopSpinner()
  
    return compListDataMap;
  }

  console.log(pageNo);


  function displayPage(sRecordCount, sPageNo, compListDataMap) {
    if (sPageNo !== pageNo) {
      setPageNo(sPageNo);
      console.log(sPageNo);

      const latestSl =
        props.passMap.listTopic == "Transaction"
          ? props.passMap.latestTransactionSl
          : sRecordCount;
     // const slNoArray = compListDataMap.slNos;

      console.log(latestSl);


      const startSlOfPage =
        pageLimit * (sPageNo - 1) + 1 > sRecordCount
          ? sRecordCount
          : pageLimit * (sPageNo - 1) + 1;
      const endSlOfPage =
        pageLimit * sPageNo > sRecordCount ? sRecordCount : pageLimit * sPageNo;

        const rArray=[];

        for (let y = startSlOfPage; y <= endSlOfPage; y++) {
          const stripValueArray = [...compListDataMap.valueSeqs[y-1]];
          //stripValueArray.unshift(y);
          rArray.push(stripValueArray);
        }


        setPageStripValuesColl([...rArray])

   

      //console.log(slNoArray);

 

      // [startSlOfPage, endSlOfPage].forEach((item) => {
      //   if (slNoArray.indexOf(item) == -1) {
      //     slQueryArray.push(item);
      //   }
      // });

     // assignStripValues(startSlOfPage, endSlOfPage, compListDataMap); //If values of page asked are available

      // if (slQueryArray.length == 0) {
      //   assignStripValues(startSlOfPage, endSlOfPage, compListDataMap); //If values of page asked are available
      // } else {
      //   fetchAndSetStripValues(
      //     startSlOfPage,
      //     endSlOfPage,
      //     slQueryArray,
      //     compListDataMap,
      //     sPageNo
      //   );
      // }
    }
  }

  console.log(pageStripValuesColl)



  async function fetchAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    compListDataMap,
    pageNo
  ) {

    //const docName = props.passMap.listTopic=="Transaction"?"storeBranchTransactions":props.passMap.listTopic=="Past Order"?"pastOrders":""

    const collQuery = query(
      collection(
        db,
        getDbCollPath(props)
      ),
      where("recordNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setCompListDataMap(
      extractAndSetViewDataMap(dbDocs, compListDataMap, pageNo)
    );
  }

  function renderPagination(
    sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {

    console.log(sRecordCount);
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    const rArray = [];

    console.log(sListValuesCollMap);

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(sRecordCount, x + 1, sListValuesCollMap)}
          className={`p-cust-3 ${
            x + 1 == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  
  

  return (
    <div className="grid grid-rows-cust-2c gap-y-4">
    {pageStripValuesColl.length != 0 &&
    <div>
      <div className="h-40 flex flex-col justify-center items-center">
        <div className="flex mt-8 border-page-no">
          {viewMode=="normal" && renderPagination(pageLimit, compListDataMap, recordCount, pageNo)}
        </div>
      </div>
      <div className="bg-primary-100">
        <div
          className={`grid w-11/12 mx-auto mt-1 grid-columns-${props.passMap.labelDbNotations.length}a bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>

                {pageStripValuesColl.map((stripValues) => {
            return (
              <RecordStrip
                stripValues={stripValues}
                labelNames={props.passMap.labelNames}
                passMap2={props.passMap.settings}
              />
            )})}
      
      </div>
      </div>
      }
      {pageStripValuesColl.length == 0 && 
                      <NoAvailableDisplay textName="No Records Found"/>
        }

      {bottomContainer}
    </div>
  );
}

