

import { child, get } from "firebase/database";
import { collection, collectionGroup, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database, db, functions as fbFunctions, httpsCallable, rbaseRef } from "../Utils";
import AttendanceCont from "./AttendanceCont";
import BtnNormalCompact from "./BtnNormalCompact";
import { callFbFunction, getFirestoreDocSnapshotByPath } from "./DbHelperFns";
import FnSelectFilterContModule from "./FnSelectFilterContModule";
import { convertRbaseTimestampObjToFbTimestamp, getPercentage, naturalSortMapsByKey } from "./genFunctions";
import InputWithControllerCont from "./InputWithControllerCont";
import LoadSpinner from "./LoadSpinner";
import MarksCont from "./MarksCont";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxOpConfPrompt6 from "./ModalBoxOpConfPrompt6";
import ModalDelegateInput from "./ModalDelegateInput";
import { createBlankValueMap, fetchAndGenerateAttendanceSheet, fetchAndGenerateMarksSheet, fetchAndGenerateStudentAttendanceSheet, fetchAndGenerateStudentMarksSheet, generateInitValMapFromPreInpMap, getOpPromptMap, getSectionAttendanceDataMap, getSectionMarksDataMap, getTempStudentMarksSheetDataMap } from "./projectFns";
import StripListFilterContViewAttendance from "./StripListFilterContViewAttendance";
import StripListFilterContViewMarks from "./StripListFilterContViewMarks";
import { editStudentInpSchemeMap, viewStudentAttendanceAdvanceSchemeMap, viewStudentMarksListAdvanceSchemeMap } from "./viewProps";
const XLSX = require('xlsx')
//import { ReactComponent as EmployeeStatIcon } from "../images/employee-stat.svg";
// const qrCode = window.require("qrcode");
// const PDFDocument = window.require("pdfkit");
// const blobStream = window.require("blob-stream");

//@start
export default function FnSelelectFilterContStudents(props) {

  console.log(props)
  const categories = props.schemeMap.categories
  const mapKeys = props.schemeMap.mapKeys
  const catDbKeys = props.schemeMap.catDbKeys
  const navTreeNodeKeys = props.schemeMap.navTreeNodeKeys

  // const [navTreeMaps, setNavTreeMaps] = useState([...getInitNavTreeMaps(props.passMap.navTreeStartIndex,categories)
  // ]);

  const [navTreeMaps, setNavTreeMaps] = useState([]);

  const [navTreePosIndex, setPosIndex] = useState(props.passMap.navTreeStartIndex);
  const [selUnitMap,setSelUnitMap]=useState({});

  const [students,setStudents] = useState([])
  const [showSpinner,setShowSpinner] = useState(false)

  const [showPrompt,setShowPrompt] = useState(false)
  const [showPromptOp,setShowPromptOp] = useState(false)
  const [promptMap,setPromptMap] = useState({})
  const [promptOpMap,setPromptOpMap] = useState({})

  const [showModalDelegate,setShowModalDelegate] = useState(false)

  const [totalClassCount,setTotalClassCount] = useState(0)
  const [attendanceDate,setAttendanceDate] = useState("")

  const [dataMap,setDataMap] = useState({})
  
  const [viewTopicName,setViewTopicName] = useState("")
  const [viewTopicLabel,setViewTopicLabel] = useState("")

  useEffect(()=>{
    // (async () => await initializeData())()

  },[])



  async function initializeData(){

  //   const queryRef = rbaseQuery(rbaseRef(database,'exams/hJ5K76eDSjamTD7Iu24h69XZPUl2/I'),orderByChild('subjectName'),equalTo('Chemistry'));

    // const snapshot = await get(child(rbaseRef(database),'institutes/hJ5K76eDSjamTD7Iu24h69XZPUl2/name'))
    // const snapshot = await get(queryRef)
    
    // console.log(snapshot.val())
    // onValue(queryRef,(snapshot)=>{
    //         console.log(snapshot.val())
    //       }) 

  }


  //@2
  async function functions(fnName,data){

  }


  //@3
  async function functions(fnName,data){


    switch(fnName){
      case "initializeData":{
        try{

     
          console.log(data)

          const navTreeNodeValMap = data.navTreeNodeValMap;
          const instituteUid = navTreeNodeValMap.instituteUid
          const className = navTreeNodeValMap.className
          const sectionName = navTreeNodeValMap.sectionName
          const subjectName = navTreeNodeValMap.subjectName


          const collQuery =  query(collection(db,`institutes/${instituteUid}/attendance`),
          where("className","==",className),where("sectionName","==",sectionName),where("subjectName","==",subjectName));

      const collDocs = await getDocs(collQuery)

      let attendanceDoc = null

      console.log(collDocs,collDocs.size,collDocs.docs.length)

      let attendanceMap = {};

      if(collDocs.size==0){
        setTotalClassCount(0)
        setAttendanceDate("")
      }else{
         attendanceDoc = collDocs.docs[0].data();
    

        attendanceMap = attendanceDoc.studentAttendanceMap

        setTotalClassCount(attendanceDoc.totalClassCount)
        setAttendanceDate(attendanceDoc.attendanceDate)

      }

          const mapOfMapsRbaseCall = await get(child(rbaseRef(
            database
          ),`students/${instituteUid}/${className}/${sectionName}`));
               
          const  mapOfMaps = mapOfMapsRbaseCall.val()
          console.log(mapOfMaps)
    
          const tColl = []
    
          Object.keys(mapOfMaps).forEach((key,i)=>{
                  const tMap = {}
                  tMap["uid"] = key;
                  tMap["attendance"] = key in attendanceMap ? attendanceMap[key] : 0;
                  tMap["attendanceAvg"] = key in attendanceMap ? attendanceDoc ? getPercentage(tMap["attendance"],attendanceDoc.totalClassCount,2) : 0 : 0;
                  tMap["studentRollNo"] = mapOfMaps[key].studentRollNo
                  // tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
                  tMap["name"] = mapOfMaps[key].name

                  tColl.push(tMap);
                });

                
                const sortedStudents = naturalSortMapsByKey(tColl,"studentRollNo")
                console.log(tColl,sortedStudents)

                setStudents(sortedStudents)
    
          }catch(error){
            console.log("rbase fetch error")
          }
    
        // setViewSequence(1)
      }
      break;
      case "attendanceChange":{
        console.log(data.changeStudentMap,data);

        const attendance = data.changeStudentMap.event.target.value;


        const studentIndex = data.changeStudentMap.studentIndex;
        const totalClassCount = data.changeStudentMap.totalClassCount;

        const tStudents = [...data.dataMap.students];

        console.log(attendance,totalClassCount)

        if(parseInt(attendance) <= parseInt(totalClassCount)){
          tStudents[studentIndex]["attendance"] = parseFloat(attendance)
          tStudents[studentIndex]["attendanceAvg"] = getPercentage(attendance,totalClassCount,2)
          setStudents(tStudents)
        }
      }
      break;

      case "examPresenceChange":{


        const dataMap = data.dataMap
        const presentCond = data.changeStudentMap.event.target.checked;

        const studentIndex = data.changeStudentMap.studentIndex;

        const tDataMap = {...dataMap};

        tDataMap.students[studentIndex]["presentCond"] = presentCond;
        tDataMap.students[studentIndex]["mark"] = 0;

        setDataMap(tDataMap)
      
      }
      break;

      case "marksChange":{
  
        const dataMap = data.dataMap

        const mark = data.changeStudentMap.event.target.value;

        const studentUid = data.changeStudentMap.student.uid;
        const studentIndex = data.changeStudentMap.studentIndex;

        const tDataMap = {...dataMap};

        if(parseFloat(mark) <= parseFloat(dataMap.totalMarksCount)){
          tDataMap.students[studentIndex]["mark"] = parseFloat(mark)
          setDataMap(tDataMap)
        }
      }
      break;
      case "saveMarks":{
        
        console.log(data)

        setShowSpinner(true)

        const dataMap = data.dataMap

        const navTreeNodeDbMapObj = data.navTreeNodeDbMapObj;
        const examMap = navTreeNodeDbMapObj["exam"]
        const instituteMap = navTreeNodeDbMapObj["institute"]

        const studentMarksMap = {}
        const studentUids = []

        for (const [j,studentMap] of dataMap.students.entries()){
          if(studentMap.presentCond){
            studentUids.push(studentMap.uid)
            studentMarksMap[studentMap.uid] = studentMap.mark
          }
        }

        console.log(studentMarksMap,studentUids)

        const payload = {
          examName:data.navTreeNodeValMap.examName,
          examDate:convertRbaseTimestampObjToFbTimestamp(examMap.examDate),
          examMaxMarks:examMap.examMaxMarks,
          examDescription:examMap.examDescription,
          examType:examMap.examType,
          subjectName:examMap.subjectName,
          className:data.navTreeNodeValMap.className,
          sectionName:data.navTreeNodeValMap.sectionName,
          instituteUid:data.navTreeNodeValMap.instituteUid,
          instituteName:instituteMap.name,
          examAttendedStudentUids:studentUids,
          studentExamMarksMap:studentMarksMap
        }


        console.log(payload)


        const fbFunctionsCall = httpsCallable(fbFunctions, "insertMarks");
        const callResult = await fbFunctionsCall(payload);
        setShowSpinner(false)

        const opResult = callResult.data;

        if(!opResult.status){
          console.log(opResult.errorMessage)
        }

        displayPrompt(opResult.message,"Save Result")

      
      }
      break;

      case "generateMarksSheet":{
        console.log(data)

        setShowSpinner(true)

        
        const dataMap = data.dataMap

        const selectionNodeDbMapObj = data.navTreeNodeDbMapObj
        const sheetRowDataMaps = dataMap.students

        const pdfDataMap = {
          selectionNodeDbMapObj:selectionNodeDbMapObj,
          sheetRowDataMaps:sheetRowDataMaps
        }

      //  await generatePdfStudent({},pdfDataMap,"examMarks",{},"download");

        await fetchAndGenerateMarksSheet(pdfDataMap)

        setShowSpinner(false)
      }
      break;
      
      case "inputMarksSpreadsheet":{

        // setModalDelegateMap({
        //   title:"Upload Spreadsheet",
        // })
        
 
        setShowModalDelegate(true)

      }
      break;
      case "saveAttendance":{
          console.log(data)

          setShowSpinner(true)
          const dataMap = data.dataMap

          const navTreeNodeDbMapObj = data.navTreeNodeDbMapObj;
          const totalClassCount = dataMap.totalClassCount

          const instituteMap = navTreeNodeDbMapObj["institute"]

          const studentAttendanceMap = {}


          for (const [j,studentMap] of dataMap.students.entries()){
              studentAttendanceMap[studentMap.uid] = studentMap.attendance
          }

          console.log(studentAttendanceMap)


          const payload = {
            subjectName:data.navTreeNodeValMap.subjectName,
            className:data.navTreeNodeValMap.className,
            sectionName:data.navTreeNodeValMap.sectionName,
            instituteUid:data.navTreeNodeValMap.instituteUid,
            instituteName:instituteMap.name,
            attendanceDate:dataMap.attendanceDate,
            totalClassCount:parseInt(dataMap.totalClassCount),
            studentAttendanceMap:studentAttendanceMap
          }


          console.log(payload)

          const callResult = await callFbFunction("updateAttendance",payload);
          setShowSpinner(false)

          const opResult = callResult;

          if(!opResult.status){
            console.log(opResult.errorMessage)
          }

          displayPrompt(opResult.message,"Save Result")

      
      }
      break;

      case "generateAttendanceSheet":{
        console.log(data)

        const dataMap = data.dataMap

        const attendanceDataMap = {
          totalClassCount:dataMap.totalClassCount,
          attendanceDate:dataMap.attendanceDate
        }
         
        const selectionNodeDbMapObj = {...data.navTreeNodeDbMapObj,"attendance":attendanceDataMap}
        const sheetRowDataMaps = dataMap.students


        const pdfDataMap = {
          selectionNodeDbMapObj:selectionNodeDbMapObj,
          sheetRowDataMaps:sheetRowDataMaps
        }

        setShowSpinner(true)

        await fetchAndGenerateAttendanceSheet(pdfDataMap)

        setShowSpinner(false)
      }

      break;

      case "sendPerformanceSummary":{

        console.log(data)

        const instituteUid = data.navTreeNodeValMap.instituteUid
        const className = data.navTreeNodeValMap.className
        const sectionName = data.navTreeNodeValMap.sectionName

        const studentUid = data.selMap.uid

        const studentMap = await getDoc(doc(db,`institutes/${instituteUid}/students/${studentUid}`))

        const querySnapshot = await getDocs(query(collection(db,`institutes/${instituteUid}/examMarks`),where("className","==",className),where("sectionName","==",sectionName)));

          // const examPerformanceMap = getExamPerformanceMap(querySnapshot.docs,studentUid);

          const payload = {
            instituteUid:instituteUid,
            className:className,
            sectionName:sectionName,
            instituteName:data.navTreeNodeDbMapObj["institute"].name,
            instituteShortName:data.navTreeNodeDbMapObj["institute"].instituteShortName,
            studentUid:data.selMap.uid,
            studentName:data.selMap.name,
            // examMonthYear:"Jun 2024",
            // attendanceAvg:"98",
            // classesConducted:"88",
            // ...examPerformanceMap,
            studentAccountEmail:studentMap.data().studentParentEmail,
            studentParentContactNo:studentMap.data().studentParentContactNo.slice(1)
          }

          console.log(payload)

          function getExamPerformanceMap(examMarksDbDocs,studentUid){

            let totalMarks = 0

            const performanceMap = {
              avgMarks:0,
              avgMarksData:[],
              examsConducted:examMarksDbDocs.length,
              examsAttended:0,
              subjectAvgMarksMap:{},
            }

            examMarksDbDocs.forEach((doc)=>{
              const docData = doc.data()

              if(Object.keys(docData.studentExamMarksMap).includes(studentUid)){
                const marksScored = docData.studentExamMarksMap[studentUid]
                performanceMap.examsAttended += 1;
                totalMarks += marksScored

                const subjectName = docData.subjectName;
                subjectName in performanceMap.subjectAvgMarksMap ? performanceMap.subjectAvgMarksMap[subjectName] += marksScored : performanceMap.subjectAvgMarksMap[subjectName] = marksScored
              }
            })

            performanceMap.avgMarks = totalMarks/performanceMap.examsAttended;
            performanceMap.avgMarksData = [performanceMap.avgMarks,performanceMap.examsAttended]

            return performanceMap;

          }

          async function getStudentMapForInstitute(studentUid){

            const querySnapshot = await getDocs(query(collectionGroup(db,"students"),where("studentUid","==",studentUid)))

            return querySnapshot.docs[0].data()
          };

          setShowSpinner(true)

        const callable = httpsCallable(fbFunctions,"sendStudentPerformance")
        const result = await callable(payload)

        setShowSpinner(false)

        displayPrompt(result.data.message,"Student Performance",result.data.status)

        // alert(result)
      }
      break;
      case "generateStudentMarksSheet":{
        console.log(data)
        
        await fetchAndGenerateStudentMarksSheet(data.dataMap.passMap)
      }
      break;
      case "generateStudenAttendanceSheet":{

        console.log(data)
      
        await fetchAndGenerateStudentAttendanceSheet(data.dataMap.passMap)
      }
      break;
      case "updateStudentDetails":{
        console.log(data)
        const inpValMap = {...data.inpValMap}

        inpValMap["contactNo"] = inpValMap.studentParentContactNoComps.length == 2 ? `${inpValMap.studentParentContactNoComps.join("")}`:null;
        inpValMap.contactNoComps = [...inpValMap.studentParentContactNoComps]

        const payload = {
          ...inpValMap
        }
          const inpSchemeMap = data.inpSchemeMap

          const opFn = async () =>{
            const callResult = await callFbFunction(inpSchemeMap.opDbFnName,payload);
            return callResult
          }
          const afterOpCompletionFn = () => {}
          const promptMap = getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn);

          setPromptOpMap(promptMap)
          setShowPromptOp(true)

      }
      break;
      case "uploadPdf":{
        console.log(data)

        const passMap = {
          ...data.navTreeNodeDbMapObj.student,
          studentUid:data.selMap.uid,
          instituteUid:data.navTreeNodeValMap.instituteUid,
          className:data.navTreeNodeValMap.className,
          sectionName:data.navTreeNodeValMap.sectionName,
          instituteAcademicYear:data.navTreeNodeDbMapObj.institute.instituteAcademicYear,
          instituteName:data.navTreeNodeDbMapObj.institute.instituteName,
        }
        // const studentExamMarksObj = getStudentExamMarksDataMapObj(passMap);
        const pdfDataMap = await getTempStudentMarksSheetDataMap(passMap)

        console.log(pdfDataMap)

        const result = await callFbFunction("uploadStudentPdf",pdfDataMap)
        displayPrompt(result.message,"Result")
      }
      break;


    }
          
  }


  async function functionsDelegate(fnName,data){

    switch(fnName){
      case "marksInputDelegate":{

        console.log(data)

        const e = data.event;
        const tDataMap = {...data.dataMap}

        try{

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);

        fileReader.onload = (ev) => {
            const data = ev.target.result;
            const workbook = XLSX.read(data,{type:'binary'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName]
            const parsedData = XLSX.utils.sheet_to_json(sheet,{cellDates:true});

            const spreadsheetMarksMap = {}  // Map which contains key as rollnumber and property as marks so that it is easily absordbed into the student map

            console.log(parsedData);

            parsedData.forEach((rowMap,i)=>{
              const rollNo = rowMap["studentRollNo"] ? rowMap["studentRollNo"].toString() : null;
              const mark = rowMap["studentMark"];

              if(rollNo){
                spreadsheetMarksMap[rollNo.toString()] = mark;
              }
            })

            console.log(spreadsheetMarksMap);

            console.log(tDataMap)

            const studentMaps = tDataMap.students.map((studentMap)=>{
              const studentRollNo = studentMap.studentRollNo;

              if(studentRollNo.toString() in spreadsheetMarksMap){
                console.log(studentMap,spreadsheetMarksMap[studentRollNo])

                const spreadsheetInpMark = spreadsheetMarksMap[studentRollNo]

                if( spreadsheetInpMark <= tDataMap.totalMarksCount){
                  studentMap["presentCond"] = true; 
                  studentMap["mark"] = spreadsheetInpMark
                }else{
                  studentMap["presentCond"] = false; 
                }
                
              }else{
                studentMap["presentCond"] = false; 
              }

              return studentMap
            });

            tDataMap["students"] = [...studentMaps]

            console.log(tDataMap)

            setDataMap({...tDataMap})
            // setViewSequence(1)
       
        }
          
        }catch(error){
          console.log(error.message)

          displayPrompt("Error : Please check the inputted file","Input Failed",false)

        }

  }

}

  }


  async function functionsView(fnName,data){

    console.log(fnName,data)

        switch(fnName){
        case "viewMarks":
        case "addMarks":{


          try{
              console.log(data)
              const dataMap = await getSectionMarksDataMap(data);
              console.log(dataMap)
              setDataMap(dataMap)

          }catch(error){
            console.log(error)
            console.log("rbase fetch error")
          }
          setViewTopicName(fnName)
          setViewTopicLabel(data.viewFnLabel)
          }
          break;
        case "viewAttendance":
        case "addAttendance":{

            try{
                console.log(data)
                const dataMap = await getSectionAttendanceDataMap(data);
                console.log(dataMap)
                setDataMap(dataMap)
  
            }catch(error){
              console.log("rbase fetch error")
            }
            setViewTopicName(fnName)
            }
            break;
            

        case "viewStudentMarks":{
          console.log(data)

          const dataMap = data.dataMap;

          setDataMap({...dataMap,passMap:{
            ...data.navTreeNodeDbMapObj.student,
            studentUid:data.selMap.uid,
            instituteUid:data.navTreeNodeValMap.instituteUid,
            className:data.navTreeNodeValMap.className,
            sectionName:data.navTreeNodeValMap.sectionName,
            instituteAcademicYear:data.navTreeNodeDbMapObj.institute.instituteAcademicYear,
            instituteName:data.navTreeNodeDbMapObj.institute.instituteName,
          }})

          setViewTopicName(fnName)

        }
        break;
        case "viewStudentAttendance":{
          console.log(data)

          const dataMap = data.dataMap;

          setDataMap({...dataMap,passMap:{
            ...data.navTreeNodeDbMapObj.student,
            studentUid:data.selMap.uid,
            instituteUid:data.navTreeNodeValMap.instituteUid,
            className:data.navTreeNodeValMap.className,
            sectionName:data.navTreeNodeValMap.sectionName,
            instituteAcademicYear:data.navTreeNodeDbMapObj.institute.instituteAcademicYear,
            instituteName:data.navTreeNodeDbMapObj.institute.instituteName,
          }})

          setViewTopicName(fnName)

        }
        break;

        case "editStudentDetails":{
          console.log(data)

          const dataMap = data.dataMap;


          const studentDocSnapshot = await getFirestoreDocSnapshotByPath(`institutes/${data.navTreeNodeValMap.instituteUid}/students/${data.navTreeNodeDbMapObj.student.uid}`);

          const studentDoc = studentDocSnapshot.data()

          setDataMap({...studentDoc
          })

    

        }
        break;
      }
      setViewTopicName(fnName)
      setViewTopicLabel(data.viewFnLabel)
  }


  function getFnView(viewTopicName,dataMap){

    console.log(dataMap)

    switch(viewTopicName){
      case "markAttendance": {
        return (
          <div></div>
        // <AttendanceCont students={data.students} totalClassCount={data.totalClassCount} attendanceDate={data.attendanceDate}
        //   attendanceChangeHandler={(changeStudentMap)=>functions("attendanceChange",{changeStudentMap:changeStudentMap,students:students})}
        //   classCountChangeHandler={(newTotalClassCount)=>setTotalClassCount(newTotalClassCount)}
        //   attendanceDateChangeHandler={(newAttendanceDate)=>setAttendanceDate(newAttendanceDate)}
        //   examPresenceChangeHandler={(changeStudentMap)=>functions("examPresenceChange",{changeStudentMap:changeStudentMap,students:students})}/>
        )
      }
      case "manageStudents":{
        return (
          <div></div>
          // <ManageStudentCont 
          //   // schemeMap={manageInstituteSelectionSchemeMap.subSelectionMapObj["classAndSections"]} 
          //   // instituteUid={navTreeNodeValMap["instituteUid"]} 
          //   // parentSelectionMap={{uid:navTreeNodeValMap["instituteUid"]}}
          //   // navTreeNodeValMap={navTreeNodeValMap}
          // />
        )
      }
      case "viewMarks":{
        return (
          <MarksCont 
              students={dataMap.students}
              totalMarksCount={dataMap.totalMarksCount}
              examDescription={dataMap.examDescription}
              editCond={false}
              marksChangeHandler={(changeStudentMap)=>functions("marksChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}
              totalMarksCountChangeHandler={(newTotalMarksCount)=>setDataMap({...dataMap,totalMarksCount:newTotalMarksCount})}
              examPresenceChangeHandler={(changeStudentMap)=>functions("examPresenceChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}/>
        )
      }

      case "addMarks":{
        return (
          <MarksCont 
              students={dataMap.students}
              totalMarksCount={dataMap.totalMarksCount}
              examDescription={dataMap.examDescription}
              editCond={true}
              marksChangeHandler={(changeStudentMap)=>functions("marksChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}
              totalMarksCountChangeHandler={(newTotalMarksCount)=>setDataMap({...dataMap,totalMarksCount:newTotalMarksCount})}
              examPresenceChangeHandler={(changeStudentMap)=>functions("examPresenceChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}/>
        )
      }
      case "viewAttendance":{
        return (
          <AttendanceCont 
            editCond={false}
            students={dataMap.students} 
            totalClassCount={dataMap.totalClassCount} 
            attendanceDate={dataMap.attendanceDate}
            attendanceChangeHandler={(changeStudentMap)=>functions("attendanceChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}
            classCountChangeHandler={(newTotalClassCount)=>setDataMap({...dataMap,totalClassCount:newTotalClassCount})}
            attendanceDateChangeHandler={(newAttendanceDate)=>setDataMap({...dataMap,attendanceDate:newAttendanceDate})}
      />
        )
      }
      case "addAttendance":{
        return (
          <AttendanceCont 
            editCond={true}
            students={dataMap.students} 
            totalClassCount={dataMap.totalClassCount} 
            attendanceDate={dataMap.attendanceDate}
            attendanceChangeHandler={(changeStudentMap)=>functions("attendanceChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}
            classCountChangeHandler={(newTotalClassCount)=>setDataMap({...dataMap,totalClassCount:newTotalClassCount})}
            attendanceDateChangeHandler={(newAttendanceDate)=>setDataMap({...dataMap,attendanceDate:newAttendanceDate})}
      />
        )
      }

      case "viewStudentMarks":{
        return (
          <StripListFilterContViewMarks  
            stripValueMapSource="internal"
            schemeMap={viewStudentMarksListAdvanceSchemeMap}
            passMap={{...dataMap.passMap
            }}
          />
        )
      }

      case "viewStudentAttendance":{
        return (
          <StripListFilterContViewAttendance
            stripValueMapSource="internal"
            schemeMap={viewStudentAttendanceAdvanceSchemeMap}
            passMap={{...dataMap.passMap
            }}
          />
        )
      }
      case "editStudentDetails":{
        const inpSchemeMap = editStudentInpSchemeMap
        return (
          <InputWithControllerCont
            initInpValMap={generateInitValMapFromPreInpMap(createBlankValueMap(inpSchemeMap),{...dataMap})}
            inpElemArrangmentScheme="two-column"
            inpSchemeMap={inpSchemeMap}
            submitClickHandler={(data)=>functions(inpSchemeMap.insertViewFnName,data)}
          />
        )
      }
    }
  
  }
  
  function getFnBtnView(viewTopicName,dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapObj){

    switch(viewTopicName){
      case "viewMarks":{
        return(<div>
          <BtnNormalCompact spClass="" color="greenDark" label="View Marks Sheet" clickHandler={()=> functions("generateMarksSheet",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
        </div>)
      }
      case "addMarks":{
        return(<div className="flex gap-4 ">
            <BtnNormalCompact spClass="" color="greenDark" label="Save Marks" clickHandler={()=> functions("saveMarks",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
            <BtnNormalCompact spClass="" color="greenDark" label="View Marks Sheet" clickHandler={()=> functions("generateMarksSheet",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
            <BtnNormalCompact spClass="" color="secondaryDark" label="Input Marks (Spreadsheet)" clickHandler={()=> functions("inputMarksSpreadsheet",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
        </div>)
      }
      case "addAttendance":{
        return(<div className="flex gap-4 ">
            <BtnNormalCompact spClass="" color="greenDark" label="Save Attendance" clickHandler={()=> functions("saveAttendance",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
            <BtnNormalCompact spClass="" color="greenDark" label="Generate Attendance Sheet" clickHandler={()=> functions("generateAttendanceSheet",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
        </div>)
      }
      case "viewAttendance":{
        return(<div className="flex gap-4 ">
            <BtnNormalCompact spClass="" color="greenDark" label="Generate Attendance Sheet" clickHandler={()=> functions("generateAttendanceSheet",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
        </div>)
      }
      case "viewStudentMarks":{
        return(<div>
          <BtnNormalCompact spClass="" color="greenDark" label="View Marks Sheet" clickHandler={()=> functions("generateStudentMarksSheet",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
        </div>)
      }
      case "viewStudentAttendance":{

        return(<div>
          <BtnNormalCompact spClass="" color="greenDark" label="View Attendance Sheet" clickHandler={()=> functions("generateStudenAttendanceSheet",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
        </div>)
      }
      case "editStudentDetails":{

        return(<div>
          {/* <BtnNormalCompact spClass="" color="red" label="Update Student Details" clickHandler={()=> functions("updateStudentDetails",{dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/> */}
        </div>)
      }
      default :{
        return(<React.Fragment>
        </React.Fragment>)
      }
    }

  }
  
  
  function displayPrompt(message,title,status){
    setShowSpinner(false)
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message
    })
  }



//@return
  return (
    <div className="h-screen w-full absolute top-0 p-cust-topHead sm:p-cust-topHeadSm">
         <FnSelectFilterContModule
                   topicFnLabel={viewTopicLabel}
                   topicFnView={()=>getFnView(viewTopicName,dataMap)}
                   topicFnBtnView={(navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)=>getFnBtnView(viewTopicName,dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)}
                   schemeMap={props.schemeMap}
                   passMap={props.passMap}
                   mapOfMaps={props.mapOfMaps}
                   listenMaps={props.listenMaps
                   }
                   fnHandler={(fnName,data)=>functions(fnName,{...data,dataMap})}
                   viewChangeFnHandler={(fnName,data)=>functionsView(fnName,{...data,dataMap})}
                   actionHandler={(data)=>props.actionHandler(data)}
                   backBtnClickHandler={() => props.backBtnClickHandler()}
         />
        {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}

        {showPromptOp && 
              <ModalBoxOpConfPrompt6
                passMap={promptOpMap}
                showSpinnerHandler={(visibilityCond)=>setShowSpinner(visibilityCond)}
                promptClose={()=>setShowPromptOp(false)}
                // opCompletionHandler={(closeCallBack)=>closeCallBack()}
              />

        }


        {showModalDelegate && 
              <ModalDelegateInput
                passMap={{title:"Upload Spreadsheet",opBtnLabels:["OK"],opBtnAlertLevels:["low"],opFns:[()=>{console.log("yellow")}]}}
                inpSchemeMap={{
                  inpKeys:["spreadsheet"],
                  inpLabels:["Spreadsheet",],
                  inpMandKeys:["spreadsheet",],
                  inpRegexDomains:["file"],
                  inpTypes:["file"],
                  inpUiTypes:["standard"],
                 }}
                delegateFnHandler={(inpData)=>functionsDelegate("marksInputDelegate",{...inpData,dataMap:dataMap})}
                showSpinnerHandler={(visibilityCond)=>setShowSpinner(visibilityCond)}
                modalCloseHandler={()=>setShowModalDelegate(false)}
              />

        }
          
       {showSpinner && <LoadSpinner/>}
       </div>
);

}






