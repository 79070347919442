import React, { useState } from "react";
import { getRegexPattern, getSettingsLabelValue } from "../Utils";
import DualInputWrapper from "./DualInputWrapper";
import { styleMapProject } from "./viewProps";

export default function InputTile(props) {

  console.log(props)

  if(props.inputName=="storeBranchEndTime" || props.inputLabel == "End Time"){
    console.log(props)
  }

  const [inpValdOkCond,setInpValdOkCond] = useState(true)
  const [deductedVald,setDeductedVald] = useState(false)

  console.log(props.inputName)
  function checkAndDisable(editStatus, inputName) {
    if (!editStatus) {
      return true;
    } else {
    }
  }



  const regexPattern = getRegexPattern(props.regexDomain);


  function onChangeHandler(e,regexPattern,deductedVald,noOfInputs){

    props.onChangeFn(e)
    console.log(e.target.value)


    let inpVal = e.target.value;

    if(noOfInputs==2){
      inpVal =  `${inpVal[0]}${inpVal[1]}`
    }


    const valdResult = !props.inpMandCond && inpVal === "" ? true : regexPattern.test(inpVal);

    if(!valdResult && !deductedVald){
      setDeductedVald(true)
      props.changeValdCount(-1)
    }

    if(valdResult && deductedVald){
      setDeductedVald(false)
      props.changeValdCount(1)
    }

  
    setInpValdOkCond(valdResult)

  }

  const dualInputTypes = ["phoneNo"]
  const isDualInput = dualInputTypes.includes(props.inputType)

  let isLocationData = false;

  if(!isDualInput){
  isLocationData=props.inputName.toLowerCase().includes("longitude") || props.inputName.toLowerCase().includes("latitude");
  }

  return (
    <div className={`grid ${props.orientation=="vertical" ? `grid-rows w-cust-15rem` : `grid-cols-cust-2h gap-1` } bg-${styleMapProject.inputElem.background} p-3 rounded`}>
      <label>{props.inputLabel}</label>
      <div className="flex">
      {isLocationData && !isDualInput && 
      <input
        className="pl-2"
        value={getSettingsLabelValue(props.inputLabel, props.inputValue)}
        type={props.inputType}
        name={props.inputName}
        min={
          props.inputName == "storeBranchLatitude"
            ? -90.00
            : props.inputName == "storeBranchLongitude"
            ? -180.00
            : 0
        }
        max={
          props.inputName == "storeBranchLatitude"
            ? 90.00
            : props.inputName == "storeBranchLongitude"
            ? 180.00
            : 0
        }
        step={
          props.inputName == "storeBranchLatitude" || props.inputName == "storeBranchLongitude"?.01:1 
        }
        checked={props.inputValue}
        disabled={!props.editStatus}
        // onChange={props.onChangeFn}
        onChange={(e)=>onChangeHandler(e,regexPattern,deductedVald)}
      ></input>

      }


      {!isLocationData && !isDualInput && 

<input
    className="pl-2 bg-whiteTransparent90 border-none rounded"
    value={getSettingsLabelValue(props.inputLabel, props.inputValue)}
    type={props.inputType}
    name={props.inputName}
    checked={props.inputValue}
    disabled={!props.editStatus}
    onChange={(e)=>onChangeHandler(e,regexPattern,deductedVald,1)}
></input>
      }

    {isDualInput && 
        <DualInputWrapper
        values={getSettingsLabelValue(props.inputLabel, props.inputValue)}
        inpType={props.inputType}
        inpName={props.inputName}
        checked={props.inputValue}
        disabled={!props.editStatus}
        onChangeFn={(eMap)=>onChangeHandler(eMap,regexPattern,deductedVald,2)}
        />
      }

      </div>
      {!inpValdOkCond && <span className="text-red text-xs">*Invalid Input</span>}

    </div>
  );
}
