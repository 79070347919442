import React from "react";

export default function FloatingButton(props) {

  const btnGenClass = props.btnGenClass ? props.btnGenCLass : "btn-action"
  const btnColor = props.btnColor ? ``:``
  // const leftPos = props.positionLeft ? `left-${props.positionLeft}` : ``;
  // const rightPos = props.positionRight ? `right-${props.positionRight}` : ``;
  // const topPos = props.positionTop ? `top-${props.positionTop}` : ``;
  // const bottomPos = props.positionBottom ? `bottom-${props.positionBottom}` : ``;

  const positionProps = ["positionLeft", "positionRight","positionTop","positionBottom"];
  const positionClassPrefix = ["left","right","top","bottom"];

  function getPositionClass(positionClassPrefix,positionProps,props){

    return positionClassPrefix.map((prefix,i)=>{

      const propsKey = positionProps[i];
      const propsValue = props[propsKey];

      return propsValue ? `${prefix}-${propsValue}` : ``

    }).join(" ")
  }


  return (
    <button className={`absolute ${btnGenClass} ${getPositionClass(positionClassPrefix,positionProps,props)}`} onClick={() => props.btnClickHandler()}>
      {props.btnLabel}
    </button>
  );
}
