import React from "react";
import { getDateStrFromJSDate, getDateTimeFromMySqlTimestampString, getOnlyDateFromMySqlTimestampString, getPercentage } from "./genFunctions";

export default function RecordMapStripAdvance(props) {
  console.log(props);

  function getGridPositionMobile(colStartEnds,rowStartEnds){

    console.log(colStartEnds,rowStartEnds)
    console.log(`sm:col-start-${colStartEnds[0]} sm:col-end-${colStartEnds[1]} sm:row-start-${rowStartEnds[0]} sm:row-end-${rowStartEnds[1]}`)
  
    return `sm:col-start-${colStartEnds[0]} sm:col-end-${colStartEnds[1]} sm:row-start-${rowStartEnds[0]} sm:row-end-${rowStartEnds[1]}`
  
  }

  function getBorderClassMobile(gridRowStartEndSchemeArray,gridColStartEndSchemeArray){

    let rowBorderClass = gridRowStartEndSchemeArray[0] != 1 ? `sm:border-t` : ``
    let colBorderClass = gridColStartEndSchemeArray[0] != 1 ? `sm:border-l` : ``

    console.log(rowBorderClass + colBorderClass)

    return `${rowBorderClass} ${colBorderClass}`
  }



  function getMapRecordCellValue(stripValueMap,cellDbKey,cellComputeMap,props){

    console.log(cellComputeMap,stripValueMap)

    const statusKeys =["new","assessed","submitted","resubmissionRequested","resubmission","resubmitted","inReview","resubmissionReceived","reportGenerated"]
    const statusLabels = ["New","Assessed","Submitted","Resubmission Requested","Resubmission","Resubmitted","In Review","Resubmission Received","Report Generated"]

    const cellDataType = cellComputeMap.dataType;
    const computeOpType = cellComputeMap.computeOpType;
    const computeKey = cellComputeMap.computeKey;
    const initComputeKeyScheme = cellComputeMap.computeKeyScheme;

    const computeKeySchemes = Array.isArray(initComputeKeyScheme) ?  [...initComputeKeyScheme] : [initComputeKeyScheme]

    const valueComps = computeKeySchemes.map((keyScheme,k)=>{
      switch(keyScheme){
        case "mapObjLength":{
          const mapObjKey = Array.isArray(computeKey) ? computeKey[k] : computeKey
          return Object.keys(props.dataMapObj.dbMap[mapObjKey]).length
        }
        case "mapObjProperty":{
          const mapObjKey = Array.isArray(computeKey) ? computeKey[k].split(".")[0] : computeKey.split(".")[0]
          const mapProperty = Array.isArray(computeKey) ? computeKey[k].split(".")[1] : computeKey.split(".")[1]
          return props.dataMapObj.dbMap[mapObjKey][mapProperty]
        }
        case "baseMapChildMapProperty":{ //base map is stripValueMap here
          const childMapKeyOnBaseMap = Array.isArray(computeKey) ? getRealisedKeyValue(stripValueMap,computeKey[k].split(".")[0]) : getRealisedKeyValue(stripValueMap,computeKey.split(".")[0])
          const childMapProperty = Array.isArray(computeKey) ? getRealisedKeyValue(stripValueMap,computeKey[k].split(".")[1]) : getRealisedKeyValue(stripValueMap,computeKey.split(".")[1])
          return stripValueMap[childMapKeyOnBaseMap][childMapProperty]

        }
        default:{
          const mapProperty = Array.isArray(computeKey) ? computeKey[k].split(".")[0] : computeKey.split(".")[0]
          return stripValueMap[mapProperty]
        }
      }
    });

    
    function getRealisedKeyValue(dataMap,key){
      return key[0]=="$" ? stripValueMap[key.slice(1)] : key;
     }


    const cellValue = (function(){
      switch(computeOpType){
        case "addition":{
          return valueComps.reduce((ac,cv)=>ac+cv,0)
        }
        case "fractionStrExpression":{

          if(valueComps[0] && valueComps[1]){
              return `${valueComps[0]} / ${valueComps[1]}`
          }else{
            return `NA`
          }
        }
        case "percentage":{

          if(valueComps[0] && valueComps[1]){
              return getPercentage(valueComps[0],valueComps[1],2)
          }else{
            return ``
          }
        }
        default:return valueComps[0]
      }
    })()

    return getFormattedCellValue(cellDataType,cellValue);


    function getFormattedCellValue(dataType,initCellValue){
      switch(dataType){
        case "status":  return initCellValue ? statusLabels[statusKeys.indexOf(initCellValue)] : "NA";
        case "dateTime": return initCellValue ? getDateTimeFromMySqlTimestampString(initCellValue) : "NA"
        case "date": return initCellValue ? getOnlyDateFromMySqlTimestampString(initCellValue) : "NA"
        case "date-timestamp": return initCellValue ? getDateStrFromJSDate(initCellValue.toDate()) : "NA"
        case "slNo": return props.autoGenSlNoCond ? props.pageLimit * (props.pageNo-1) + props.slNo : initCellValue
        case "arrayData" : return initCellValue.join(", ")
        case "boolean" : return initCellValue ? "Yes" : "No";
        case "list": return Array.isArray(initCellValue) ? initCellValue.join(" , ") : initCellValue;
        case "percentage": return initCellValue ? `${initCellValue}%` : "NA";
        default:return initCellValue
      }
    }


    // const keyType = "keyType" in cellComputeMap ? cellDataTypeMap.keyType : null

    // if(keyType){ //for map data types

    //   switch(dataType){
    //     case "percentage" : {
    //       const cellDbKeys = cellDbKey.split("^")
    //       const keyTypes = keyType.split("^")

    //       const numeratorKey = cellDbKeys[0]
    //       const numeratorKeyType = keyTypes[0]

    //       const denominatorKey = cellDbKeys[1]
    //       const denominatorKeyType = keyTypes[1]

    //       const numeratorVal = getRecordMapStripAdvanceVal(props.stripValueMap,numeratorKey,numeratorKeyType)
    //       const denominatorVal = getRecordMapStripAdvanceVal(props.stripValueMap,denominatorKey,denominatorKeyType)

    //       return getPercentage(numeratorVal,denominatorVal,2)
    //     }
    //     default : return getRecordMapStripAdvanceVal(props.stripValueMap,cellDbKey,keyType)
    //   }


    // }else{ // for simple data types

    //   const initCellValue = stripValueMap[cellDbKey]
    //   switch(valType){
    //     case "status":  return initCellValue ? statusLabels[statusKeys.indexOf(initCellValue)] : "NA";
    //     case "dateTime": return initCellValue ? getDateTimeFromMySqlTimestampString(initCellValue) : "NA"
    //     case "date": return initCellValue ? getOnlyDateFromMySqlTimestampString(initCellValue) : "NA"
    //     case "date-timestamp": return initCellValue ? getDateStrFromJSDate(initCellValue.toDate()) : "NA"
    //     case "slNo": return props.autoGenSlNoCond ? props.pageLimit * (props.pageNo-1) + props.slNo : initCellValue
    //     case "arrayData" : return initCellValue.join(", ")
    //     case "boolean" : return initCellValue ? "Yes" : "No";
    //     case "list": return Array.isArray(initCellValue) ? initCellValue.join(" , ") : initCellValue;
    //     default:return initCellValue
    //   }
    // }

  }

  function getSlNo(propsObj){
    return props.pageLimit * (props.pageNo-1) + props.slNo
  }

  const stripColorClass = props.stripColor ? `bg-${props.stripColor}` : `bg-quaternaryLight`

  return (
    <div className={`grid mt-1 ${props.schemeMap.stripGridColsSchemeMain} ${stripColorClass} rounded-sm border border-borderStd`}>
      <div className="flex-center ">
        {getSlNo(props)}
      </div>
      <div className={`grid ${props.schemeMap.stripGridColsSchemeBody} ${props.schemeMap.stripGridColRowSchemeMobile ? props.schemeMap.stripGridColRowSchemeMobile:``}`}>
      {props.schemeMap.cellDbKeys.map((cellDbKey,i) => {
                if(i!=0){
                return (
                  <div className={`border-l text-center text-lightBackText ${getGridPositionMobile(props.schemeMap.stripGridMobileLabelColStartEnds[i],props.schemeMap.stripGridMobileLabelRowStartEnds[i])} ${getBorderClassMobile(props.schemeMap.stripGridMobileLabelRowStartEnds[i],props.schemeMap.stripGridMobileLabelColStartEnds[i])} sm:p-1`}>
                      {getMapRecordCellValue(props.stripValueMap,cellDbKey,
                      { dataType:props.schemeMap.cellDataTypes[i],
                        computeOpType:props.schemeMap.cellComputeOpTypes[i],
                        computeKeyScheme:props.schemeMap.cellComputeKeySchemes[i],
                        computeKey:props.schemeMap.cellComputeKeys[i]
                      },props)}
                  </div>
                );
                }
              })}
              </div>

        
            
{/* 
      {props.schemeMap.cellDbKeys.map((cellDbKey,i) => {
        return (
          <div className="value-strip-unit text-center text-lightBackText">
            {getMapRecordCellValue(props.stripValueMap[cellDbKey],props.schemeMap.cellDataTypes[i],props)}
          </div>
        );
      })} */}
    </div>
  );
}


function getRecordMapStripAdvanceVal(stripValueMap,cellDbKey,keyType){

  console.log(stripValueMap,cellDbKey,keyType)
  if(keyType=="map"){
    const cellDbKeyCompStr = cellDbKey
    const realisedCellDbKeyComps =  cellDbKeyCompStr.split(".").map((it)=> it[0]=="$" ? stripValueMap[it.slice(1)] : it);
    return realisedCellDbKeyComps.reduce((ac,cv)=> ac[cv],stripValueMap)
  }else{
    return stripValueMap[cellDbKey]
  }
}

//row-start-1, row-start-2, row-start-3, row-start-4, row-start-5, row-start-6
//col-start-1 col-start-2 col-start-3 col-start-4 col-start-5 col-start-6
//row-end-1, row-end-2, row-end-3, row-end-4, row-end-5, row-end-6
//col-end-1 col-end-2 col-end-3 col-end-4 col-end-5 col-end-6