import React, { Component,useState,useEffect } from 'react'
import OrderInfoBanner from './OrderInfoBanner'
import BottomContainer from './BottomContainer'
import {firebase,getAuth,firebaseConfig,initializeApp,onAuthStateChanged,useNavigate,getFirestore} from "../Utils";
import {collection,query,where,getDocs,collectionGroup,onSnapshot} from "firebase/firestore"

const db=getFirestore(firebase);


export default function OrderListView(props){


    const [docs, setDocs] = useState([]);



    const orderList = props.listType;

   


    useEffect(async()=>{

        const orderStatus = (orderList=="Past Orders")?"completed":(orderList=="Accepted Orders")?"accepted":(orderList=="Current Orders")?"inProgress":"pendingCollection";

        const orders = query(collectionGroup(db,'orders'),where("orderStoreId","==","AAAA01"),where("orderStatus","==",orderStatus));
  
        const querySnapshot = await getDocs(orders);
        let x=[];
        querySnapshot.forEach((doc)=>{
            console.log("Generated");
            console.log(doc.data());
            x.push(doc.data());      
          });
        
        setDocs(x);
  
        const orderQueryUnsubscribe = onSnapshot(orders,(querySnapshot)=>{
          x=[];
          querySnapshot.forEach((doc)=>{
            console.log("Generated");
            console.log(doc.data());
            x.push(doc.data());      
          })
  
          setDocs(x);
        },(error)=>{
          console.log(error.message);
        });
  
        return(()=>{
          orderQueryUnsubscribe();
        })
        
      },[])
  
      const bottomContainer = <BottomContainer btmContClickIntermediate2={props.btmContClickIntermediate1}/>

    if(orderList=="Accepted Orders"){  
        return(
            <div className="grid grid-rows-cust-2c">
                    <div className="grid grid-rows-6 bg-primary-400 items-center p-6 gap-1.5">
                        {
                        docs.map((data,i)=><OrderInfoBanner key={i}
                        slNo={i+1} 
                        customerName={data.orderCustomerName} 
                        orderSkateDetail={data.orderSkateBrandName} 
                        orderAcceptedTime=""  
                        orderAcceptedBy={data.orderAcceptedEmployeeName} 
                        orderId={data.orderId} 
                        skateId={data.orderSkateId}
                        orderContClickIntermediate={()=>props.orderContClickHandler(data.orderId)}/>)
                    }
                </div>
                    {bottomContainer}
            </div>
        )

    }else if(orderList=="Current Orders"){ 

        return(
            <div className="grid grid-rows-cust-2c">
            <div className="grid grid-rows-6 bg-primary-400 items-center p-6 gap-1.5">
                {
                docs.map((data,i)=><OrderInfoBanner key={i}
                slNo={i+1} 
                customerName={data.orderCustomerName} 
                orderSkateDetail={data.orderSkateBrandName} 
                orderAcceptedTime=""  
                orderAcceptedBy={data.orderAcceptedEmployeeName} 
                orderId={data.orderId} 
                skateId={data.orderSkateId}
                orderContClickIntermediate={props.orderContClickHandler}/>)
            }
    </div>
                 {bottomContainer}
    </div>
        )


    }
     
}



/*

        return(

            <div className="grid grid-rows-cust-2c">
                    <div className="grid grid-rows-6 bg-primary-400 items-center p-6 gap-1.5">
                        {
                        docs.map((data,i)=><OrderInfoBanner key={i}
                        slNo={i+1} 
                        customerName={data.orderCustomerName} 
                        orderSkateDetail={data.orderSkateBrandName} 
                        orderAcceptedTime=""  
                        orderAcceptedBy={data.orderAcceptedEmployeeName} 
                        orderId={data.orderId} 
                        skateId={data.orderSkateId}
                        onClick=""/>)
                    }
                    </div>
                    <BottomContainer 
                    btmContClickIntermediate={props.btmContClickHandler}/>
            </div>
        )

        */