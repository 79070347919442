import { ReactComponent as AddSkateIcon } from "../images/add-skate.svg";
import { ReactComponent as ManageSkateIcon } from "../images/manage-skate.svg";
import { ReactComponent as ManageUserAccountIcon } from "../images/manage-user-account.svg";


const imgMap = {
    manageUserAccount:ManageUserAccountIcon,
    addSkate:AddSkateIcon,
    manageSkate:ManageSkateIcon
   }


  export function getImgAsset(imgKey){
    return imgMap[imgKey]
   }