import React from "react";

export default function RecordLabelDynamicUnit(props) {

  function getFinalDynamicValue(labelName,dynamicValue,refValue){
    if(labelName=="Duration"){
      console.log(refValue)
      console.log(typeof refValue)
      const diffInMillis = dynamicValue-refValue;
      return `${(diffInMillis/(1000*60)).toFixed(2)} MINS`
    }
  }

  return (
    <div className={`p-2 grid ${props.showLabelCond ? `grid-cols-cust-2d` : ``} bg-primary-400 items-center text-center border-inner-light`}>
      {props.showLabelCond && 
      <div className="text-sm ">{props.labelName}</div>}
      <div className="font-medium text-lg text-secondary-2 font-montserrat break-words">{getFinalDynamicValue(props.labelName,props.dynamicValue,props.labelRefValue)}</div>
    </div>
  );
}
