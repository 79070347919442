import React from "react";

import ButtonModalOp from "./ButtonModalOp";




export default function ModalBoxOpConfPrompt2(props) {

  console.log(props) 

  async function opFunction(fn){

    fn();
    props.promptClose()
  }

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-4 px-4 gap-y-24 items-center rounded-lg">
        <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
        Confirmation
        </div>
        <div className="flex items-center justify-center text-center">{props.passMap.message}</div>
        <div className="w-full">
          <div className="flex items-center justify-center gap-4">

                <ButtonModalOp  label="Yes, Proceed" alertLevel="high" clickHandler={() => opFunction(props.passMap.opFn)} />
            
            <button className="btn-action-sm hover-black" onClick={() => props.promptClose()}>
            Close
          </button>
          </div>
        </div>
      </div>
    </div>
  )
}
