

import { child, get } from "firebase/database";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database, db, functions as fbFunctions, httpsCallable, rbaseRef } from "../Utils";
import AttendanceCont from "./AttendanceCont";
import BtnNormal from "./BtnNormal";
import FnSelectFilterContModule from "./FnSelectFilterContModule";
import { getPercentage, naturalSortMapsByKey } from "./genFunctions";
import LoadSpinner from "./LoadSpinner";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import { generatePdfStudent } from "./projectFns";
//import { ReactComponent as EmployeeStatIcon } from "../images/employee-stat.svg";
// const qrCode = window.require("qrcode");
// const PDFDocument = window.require("pdfkit");
// const blobStream = window.require("blob-stream");

//@start
export default function FnSelelectFilterContAttendance(props) {

  console.log(props)
  const categories = props.schemeMap.categories
  const mapKeys = props.schemeMap.mapKeys
  const catDbKeys = props.schemeMap.catDbKeys
  const navTreeNodeKeys = props.schemeMap.navTreeNodeKeys

  // const [navTreeMaps, setNavTreeMaps] = useState([...getInitNavTreeMaps(props.passMap.navTreeStartIndex,categories)
  // ]);

  const [navTreeMaps, setNavTreeMaps] = useState([]);

  const [navTreePosIndex, setPosIndex] = useState(props.passMap.navTreeStartIndex);
  const [selUnitMap,setSelUnitMap]=useState({});

  const [students,setStudents] = useState([])
  const [showSpinner,setShowSpinner] = useState(false)

  const [showPrompt,setShowPrompt] = useState(false)
  const [promptMap,setPromptMap] = useState({})

  const [totalClassCount,setTotalClassCount] = useState(0)
  const [attendanceDate,setAttendanceDate] = useState("")

  const [dataMap,setDataMap] = useState({students:[],totalClassCount:0,attendanceDate:""})



  useEffect(()=>{
    (async () => await initializeData())()

  },[])



  async function initializeData(){

  //   const queryRef = rbaseQuery(rbaseRef(database,'exams/hJ5K76eDSjamTD7Iu24h69XZPUl2/I'),orderByChild('subjectName'),equalTo('Chemistry'));

    // const snapshot = await get(child(rbaseRef(database),'institutes/hJ5K76eDSjamTD7Iu24h69XZPUl2/name'))
    // const snapshot = await get(queryRef)
    
    // console.log(snapshot.val())
    // onValue(queryRef,(snapshot)=>{
    //         console.log(snapshot.val())
    //       }) 

  }


  //@2
  async function functions(fnName,data){

  }


  //@3
  async function functions(fnName,data){


    switch(fnName){
      case "initializeData":{
        try{

          console.log(data)

          const navTreeNodeValMap = data.navTreeNodeValMap;
          const instituteUid = navTreeNodeValMap.instituteUid
          const className = navTreeNodeValMap.className
          const sectionName = navTreeNodeValMap.sectionName
          const subjectName = navTreeNodeValMap.subjectName


          const collQuery =  query(collection(db,`institutes/${instituteUid}/attendance`),
          where("className","==",className),where("sectionName","==",sectionName),where("subjectName","==",subjectName));

      const collDocs = await getDocs(collQuery)

      let attendanceDoc = null

      console.log(collDocs,collDocs.size,collDocs.docs.length)

      let attendanceMap = {};

      let dataMap={}

      if(collDocs.size==0){

        dataMap["totalClassCount"] = 0
        dataMap["attendanceDate"] = ""
      
      }else{
         attendanceDoc = collDocs.docs[0].data();
    

        attendanceMap = attendanceDoc.studentAttendanceMap

        dataMap["totalClassCount"] = attendanceDoc.totalClassCount
        dataMap["attendanceDate"] = attendanceDoc.attendanceDate

        // setTotalClassCount(attendanceDoc.totalClassCount)
        // setAttendanceDate(attendanceDoc.attendanceDate)

      }

          const mapOfMapsRbaseCall = await get(child(rbaseRef(
            database
          ),`students/${instituteUid}/${className}/${sectionName}`));
               
          const  mapOfMaps = mapOfMapsRbaseCall.val()
          console.log(mapOfMaps)
    
          const tColl = []
    
          Object.keys(mapOfMaps).forEach((key,i)=>{
                  const tMap = {}
                  tMap["uid"] = key;
                  tMap["attendance"] = key in attendanceMap ? attendanceMap[key] : 0;
                  tMap["attendanceAvg"] = key in attendanceMap ? attendanceDoc ? getPercentage(tMap["attendance"],attendanceDoc.totalClassCount,2) : 0 : 0;
                  tMap["studentRollNo"] = mapOfMaps[key].studentRollNo
                  // tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
                  tMap["name"] = mapOfMaps[key].name

                  tColl.push(tMap);
                });

                const sortedStudents = naturalSortMapsByKey(tColl,"studentRollNo");

                dataMap["students"] = sortedStudents
                
                setDataMap(dataMap)
    
                // setStudents(sortedStudents)
    
          }catch(error){
            console.log("rbase fetch error")
          }
    
        // setViewSequence(1)
      }
      break;
      case "attendanceChange":{
        console.log(data.changeStudentMap,data.students);

        const attendance = data.changeStudentMap.event.target.value;

        const dataMap = data.dataMap


        const studentIndex = data.changeStudentMap.studentIndex;
        const totalClassCount = data.changeStudentMap.totalClassCount;

        const tDataMap = {...dataMap};

        console.log(attendance,totalClassCount)

        if(parseInt(attendance) <= parseInt(totalClassCount)){
          tDataMap.students[studentIndex]["attendance"] = parseFloat(attendance)
          tDataMap.students[studentIndex]["attendanceAvg"] = getPercentage(attendance,totalClassCount,2)

          setDataMap(tDataMap)
        }
      }
      break;

    //   case "examPresenceChange":{


    //     const presentCond = data.changeStudentMap.event.target.checked;

    //     const studentIndex = data.changeStudentMap.studentIndex;

    //     const tStudents = [...data.students];

    //     tStudents[studentIndex]["presentCond"] = presentCond;
    //     tStudents[studentIndex]["mark"] = 0;

    //     setStudents(tStudents)
      
    //   }
    //   break;
      case "saveAttendance":{
        
        console.log(data)

        setShowSpinner(true)
        const dataMap = data.dataMap

        const navTreeNodeDbMapObj = data.navTreeNodeDbMapObj;
        const totalClassCount = dataMap.totalClassCount

        const instituteMap = navTreeNodeDbMapObj["institute"]

        const studentAttendanceMap = {}


        for (const [j,studentMap] of dataMap.students.entries()){
            studentAttendanceMap[studentMap.uid] = studentMap.attendance
        }

        console.log(studentAttendanceMap)


        const payload = {
          subjectName:data.navTreeNodeValMap.subjectName,
          className:data.navTreeNodeValMap.className,
          sectionName:data.navTreeNodeValMap.sectionName,
          instituteUid:data.navTreeNodeValMap.instituteUid,
          instituteName:instituteMap.name,
          attendanceDate:dataMap.attendanceDate,
          totalClassCount:parseInt(dataMap.totalClassCount),
          studentAttendanceMap:studentAttendanceMap
        }


        console.log(payload)


        const fbFunctionsCall = httpsCallable(fbFunctions, "updateAttendance");
        const callResult = await fbFunctionsCall(payload);
        setShowSpinner(false)

        const opResult = callResult.data;

        if(!opResult.status){
          console.log(opResult.errorMessage)
        }

        displayPrompt(opResult.message,"Save Result")

      
      }
      break;

      case "generateAttendanceSheet":{
        console.log(data)

        const dataMap = data.dataMap

        const attendanceDataMap = {
          totalClassCount:dataMap.totalClassCount,
          attendanceDate:dataMap.attendanceDate
        }
         
        const selectionNodeDbMapObj = {...data.navTreeNodeDbMapObj,"attendance":attendanceDataMap}
        const sheetRowDataMaps = dataMap.students


        const pdfDataMap = {
          selectionNodeDbMapObj:selectionNodeDbMapObj,
          sheetRowDataMaps:sheetRowDataMaps
        }

        setShowSpinner(true)

        await generatePdfStudent({},pdfDataMap,"attendance",{},"download");

        setShowSpinner(false)
      }

    }
          

  }


  function getFnView(dataMap){
    return(<AttendanceCont students={dataMap.students} totalClassCount={dataMap.totalClassCount} attendanceDate={dataMap.attendanceDate}
      attendanceChangeHandler={(changeStudentMap)=>functions("attendanceChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}
      classCountChangeHandler={(newTotalClassCount)=>setDataMap({...dataMap,totalClassCount:newTotalClassCount})}
      attendanceDateChangeHandler={(newAttendanceDate)=>setDataMap({...dataMap,attendanceDate:newAttendanceDate})}
      />)
  }
  
  function getFnBtnView(dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapObj){
    return(<React.Fragment>
      <BtnNormal spClass="" color="greenDark" label="Save" clickHandler={()=> functions("saveAttendance",{dataMap:dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
      <BtnNormal spClass="" color="secondaryDark" label="Generate Attendance Sheet" clickHandler={()=> functions("generateAttendanceSheet",{dataMap:dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
    </React.Fragment>)
  }
  
  
  function displayPrompt(message,title,status){
    setShowSpinner(false)
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message
    })
  }


//@return
  return (
    <div className="h-screen w-full absolute top-0 p-cust-topHead">
         <FnSelectFilterContModule
                   topicFnName="Mark Attendance"
                   topicFnView={()=>getFnView(dataMap)}
                   topicFnBtnView={(navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)=>getFnBtnView(dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)}
                   schemeMap={props.schemeMap}
                   passMap={props.passMap}
                   mapOfMaps={props.mapOfMaps}
                   listenMaps={props.listenMaps
                   }
                   fnHandler={(fnName,data)=>functions(fnName,data)}
                   actionHandler={(data)=>props.actionHandler(data)}
                   backBtnClickHandler={() => props.backBtnClickHandler()}
         />
        {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
       {showSpinner && <LoadSpinner/>}
       </div>
);

}




