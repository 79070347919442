import React, { useContext, useEffect, useState } from "react";
import ButtonBack from "./ButtonBack";
import ButtonSubmit from "./ButtonSubmit";
import CategoryInputElementsCont from "./CategoryInputElementsCont";
import Dialog from "./Dialog";
import InputElementsCont from "./InputElementsCont";
import InputLocationCont from "./InputLocationCont";
import { UserDataContext } from "./Level1";
import LoadSpinner from "./LoadSpinner";
import TabContainer from "./TabContainer";

export default function InputTabCont(props) {
  const userContext = useContext(UserDataContext); //Main User Data of the Current User Broadcasted from Level 1

  console.log(userContext);
  console.log(props.passMap);

  const [submitClicked, setSubmitClicked] = useState(false);
  const [curTabName, setCurTabName] = useState("General");
  const [tabChanged, setTabChanged] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMap, setDialogMap] = useState({
    mode:"",
    title:"",
    message:"",
  });
  const [tabFocusIndex,setTabFocusIndex]=useState(0)
  const [valdCount, setValdCount] = useState(0);

  console.log("In Input Cont ");
  useEffect(() => {}, [curTabName]);

  function dialogBtnClicked(data){

    console.log(data)
    
    if(data=="Ok"){
      setShowDialog(false)
    }
    
    }


  function dialogFn(map){
    setDialogMap({...map});
    setShowDialog(true)
  }


  function submitClose() {
    setSubmitClicked(false);
    setShowSpinner(false);
  }

  function submitClickStart() {
    setShowSpinner(true);
    setSubmitClicked(true);
  }

  function tabClickHandler(selectedIndex,tabName) {
    setTabChanged(true);
    setTabFocusIndex(selectedIndex)
    setCurTabName(tabName);
  }

  function checkAndRenderSettingsSection(passDataObj, tabName, submitClicked,valdCount) {
    console.log(passDataObj);
    console.log(tabName);
    switch (tabName) {
      case "General":
        return (
          <InputElementsCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            submitClicked={submitClicked}
            imgRequired={props.passMap.inputDomain == "Store Branch"}
            submitCloseHandler={() => submitClose()}
            dialogHandler={(map)=>dialogFn(map)}
            tabName={tabName}
            changeValdCount = {(val)=>setValdCount(valdCount+val)}
          />
        );
        break;
      case "Service":
        return (
          <CategoryInputElementsCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            imgRequired={false}
            submitClicked={submitClicked}
            submitCloseHandler={() => submitClose()}
            dialogHandler={(map)=>dialogFn(map)}
            tabName={tabName}
            changeValdCount = {(val)=>setValdCount(valdCount+val)}
          />
        );
        break;
      case "Location":
        return (
          <InputLocationCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            submitClicked={submitClicked}
            imgRequired={false}
            submitCloseHandler={() => submitClose()}
            dialogHandler={(map)=>dialogFn(map)}
            tabName={tabName}
            changeValdCount = {(val)=>setValdCount(valdCount+val)}
          />
        );
        break;
      case "Availability":
        return (
          <InputElementsCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            imgRequired={false}
            submitClicked={submitClicked}
            submitCloseHandler={() => submitClose()}
            tabName={tabName}
          />
        );
        break;
      default:
        return <React.Fragment />;
    }
  }


  function getSpecificCategorySettingsInputDataMap(obj, categoryName) {
    console.log(obj.inputCategories);
    console.log(categoryName);

    const catSpecificKeys = [...obj.categorySpecificKeys, "dataMap"];
    //catSpecificKeys.push("dataMap"); // Pushing "Data Map" as this will not be present in the scheme Map

    const catIndex = obj.inputCategories.indexOf(categoryName);
    const rMap = createInitialCategoryObj(obj, catIndex);

    console.log(rMap);

    const objKeys = Object.keys(obj);
    const catUnspecificKeys = objKeys.filter(
      (key) => !catSpecificKeys.includes(key)
    );

    console.log(catUnspecificKeys);
    console.log(catSpecificKeys);

    catSpecificKeys.forEach((key) => {
      console.log(obj);
      rMap[key] = obj[key][catIndex];
    });

    catUnspecificKeys.forEach((key) => {
      rMap[key] = obj[key];
    });

    console.log(rMap)

    return rMap;
  }

  function createInitialCategoryObj(templateObj, catIndex) {
    const obj = {};

    templateObj[templateObj.mainKey][catIndex].forEach((field) => {
      obj[field] = "";
    });

    console.log(obj)
    return obj;
  }

  return (
    <div className="h-screen grid grid-rows-cust-3c justify-items-center p-cust-topHead">
      <TabContainer
        tabNameList={props.passMap.inputCategories}
        tabFocusIndex={tabFocusIndex}
        tabClickHandler={(focusIndex,name) => tabClickHandler(focusIndex,name)}
      />
      {props.passMap.fnType == "settings" &&
        checkAndRenderSettingsSection(props.passMap, curTabName, submitClicked,valdCount)}
      {props.passMap.fnType == "others" && (
        <InputElementsCont
          passMap={props.passMap} //passDataObj has all data from props object
          submitClicked={submitClicked}
          submitCloseHandler={() => submitClose()}
          tabName=""
        />
      )}
      {showSpinner && <LoadSpinner />}
      {showDialog && <Dialog btnClickHandler={(data)=>dialogBtnClicked(data)} passMap={dialogMap}/>}
      <div className="absolute bottom-0 h-24 w-full flex justify-center items-center gap-4">
        {valdCount>=0 &&
        <ButtonSubmit
          submitClickHandler={() => submitClickStart()}
          btnLabel="Submit"
        />
        }
        <ButtonBack
          backBtnClickHandler={() => props.backBtnClickHandler()}
        />
      </div>
    </div>
  );
}
