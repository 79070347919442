import React, { useRef, useState } from "react";
import { getMonthYearAsLabel } from "../Utils";
import InputCont from "./InputCont";

export default function ModalBoxInvoice(props) {
  const qrCanvas = useRef(null);

  const [opState, setOpState] = useState("QR Generation");
  const [modalBtnClicked, setModalBtnClicked] = useState(false);
  const [modalDataMap, setModalDataMap] = useState({});
  const [flowIndex,setFlowIndex]=useState(0)
  const [selectionName,setSelectionName] = useState("")

  function disableClicked(e) {
    if (e.target.checked) {
      props.fnHandler("disable");
    } else {
      props.fnHandler("enable");
    }
  }

  function modalClose(){
    props.btnClickHandler({inputDataMap:{fnName:"Back"}})
  }


  const flowNames = ["SELECT MONTH","FINALIZE AND SEND"]


  function modalBtnClickFn(data){
   // console.log(data);

    setModalDataMap({fnName:data});

    setModalBtnClicked(true);
   // props.btnClickHandler({inputDataMap:{fnName:"Back"}}
  }

  function selectionFn(selection,xFlowIndex){
    setSelectionName(selection)
    setFlowIndex(xFlowIndex+1)
  }


  function getPassMap(passMap,selection){
    const tMap = JSON.parse(JSON.stringify(passMap))
    const motherDataMap = tMap.motherDataMap;
    const selDataMap = motherDataMap[selection];

    tMap["dataMap"] = {...selDataMap};
    
    delete tMap.motherDataMap

    return tMap;
  }

  return (
      <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
          <div className="relative grid grid-rows-cust-3i h-3/6 w-2/5 bg-quaternary-light m-4 items-center justify-center rounded-lg">
              <div className="btn-modal-close"  onClick={() => modalClose()}>
                X
            </div>
              <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
                {flowNames[flowIndex]}
              </div>

                <div className="flex justify-center flex-wrap gap-4 px-4">
                {flowIndex==0 && props.passMap.selections.length!=0 && 
                  props.passMap.selections.map((selection,i)=>{
                    return(
                      <button className="btn-action-sm btn-alert-high"
                      onClick={() => selectionFn(selection,flowIndex)}
                    >{getMonthYearAsLabel(selection)}</button>
                    )
                  })
                }

                {flowIndex==0 && props.passMap.selections.length==0 && 
                  <div>No Jobs Found</div>
                }

                {flowIndex==1 && 
                 <div className="flex flex-col justify-center items-center">
                  <div> {getMonthYearAsLabel(selectionName)}</div>
                  <InputCont
                    // view={contView}
                    passMap={getPassMap(props.passMap,selectionName)}
                    modalBtnClicked={modalBtnClicked}
                    modalDataMap={modalDataMap}
                    modalBtnClickHandler={(inputDataMap)=>props.btnClickHandler({inputDataMap,...getPassMap(props.passMap,selectionName)})}
                    modalBtnClickCloseHandler={()=>setModalBtnClicked(false)}
                  />
                  </div>
                }
  
                </div>

          <div className="flex gap-4 justify-center items-center">
          {flowIndex ==1 && 
              <React.Fragment>
                  <button className="btn-action-sm btn-alert-high"
                    onClick={() => modalBtnClickFn("Send Invoice")}
                  >Send Invoice</button>

                  <button className="btn-action-sm hover-black"
                    onClick={() => modalBtnClickFn("Preview Invoice")}
                  >Preview</button>

                <button className="btn-action-sm hover-black"
                    onClick={() => setFlowIndex(flowIndex-1)}
                  >Back</button>
            </React.Fragment>
            }
          </div>
          </div>
   
      </div>
  );
}
