import React from "react";

export default function StatSection(props) {


  let colCount=0

  if(Object.keys(props.statSectionUnitLabels).length>3 && props.statSectionTitle.split(" ")[0]=="Earnings"){
    colCount=`grid-columns-2 p-8 h-auto gap-4`
  }else{
    colCount=`grid-columns-${Object.keys(props.statSectionUnitLabels).length}`
  }

  function getFinalLabelName(labelName){

    if(labelName=="Estd Wait Time"){
      return "Estd Wait Time (Mins)"
    }

    return labelName
  }

  console.log(props)
  return (
    <div className="relative px-4 flex flex-col">
        <div className="bg-secondary text-primary text-center px-4 mb-8 text-lg border rounded mx-auto sm:text-sm">{props.statSectionTitle}</div>
        <div className={`h-1/3 grid ${colCount} mb-8 mr-4 rounded`}>

    
          {props.statSectionUnitLabels.map((statUnitLabel,i)=>{
            return(
              <div className="h-full flex flex-col items-center justify-center text-secondary text-center">
                <div className="text-6xl sm:text-4xl">{props.statSectionUnitValues[i]}</div>
                <div className="sm:text-sm">{statUnitLabel}</div>
              </div>
            )
          })}
          
    </div>
</div>
  );
}
