import { Wrapper } from "@googlemaps/react-wrapper";
import React, { useContext, useEffect, useState } from "react";
import {
  functions,
  getBlob,
  httpsCallable,
  ref,
  storage
} from "../Utils";
import InputTile from "./InputTile";
import { UserDataContext } from "./Level1";
import MyMapComponent from "./MapCont";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";

//const db=getFirestore(firebase);

//function to create an empty field object with keys of the templateObj passed to it
function createInitialObj(templateObj) {
  const obj = {};

  console.log(templateObj);

  if (templateObj.inputDomain == "Add Prepaid Scheme") {
    obj["inputDomain"] = templateObj.inputDomain;
    obj["jobNames"] = templateObj.jobSettings.jobNames;
    obj["jobCosts"] = templateObj.jobSettings.jobCosts;
    obj["jobItemNames"] = templateObj.jobSettings.jobItemNames;
  }

  templateObj[templateObj.mainKey].forEach((field, i) => {
    if (
      templateObj.elemTypes[i] == "standard" ||
      templateObj.elemTypes[i] == "display"
    ) {
      if (templateObj.editStatus[i] == false) {
        switch (templateObj.inputNames[i]) {
          case "prepaidSchemeCurrency":
            obj[field] = templateObj.settings.storeBranchCurrency;
            break;
          default:
            obj[field] = "";
        }
      } else {
        obj[field] = "";
      }
    } else {
      const selectedValue = templateObj.selectOptions[i][0];
      obj[field] = selectedValue;
    }
  });

  console.log(obj);

  return obj;
}

function checkAndFinalizeInputDataObj(tempObj) {
  if (tempObj.inputDomain == "Add Prepaid Scheme") {
    const matchIndex = tempObj.jobNames.indexOf(tempObj.prepaidSchemeJobName);

    tempObj.prepaidSchemeCost = parseInt(
      tempObj.prepaidSchemeNoOfJobsCharged * tempObj.jobCosts[matchIndex]
    );

    tempObj["prepaidSchemeJobItemName"] = tempObj.jobItemNames[matchIndex];

    // tempObj.prepaidSchemeValidityStart="" //Keeping this blank as I may need it for the future
  }

  return tempObj;
}

function getInputValue(inputNode) {
  console.log(inputNode);
  const inputType = inputNode.type;
  const inputName = inputNode.name;

  switch (inputType) {
    case "file":
      return inputNode.files[0];
      break;
    case "checkbox":
      return inputNode.checked;
      break;
    case "number":
      if (
        inputName.toLowerCase().includes("latitude") ||
        inputName.toLowerCase().includes("longitude")
      ) {
        return parseFloat(inputNode.value);
      } else {
        return parseInt(inputNode.value);
      }

      break;
    case "time":
      return getSecondsFromTimeString(inputNode.value);
      break;
    default:
      return inputNode.value;
  }
}

function getSecondsFromTimeString(timeString) {
  console.log(timeString);
  return (
    parseFloat(timeString.split(":")[0]) * 60 * 60 +
    parseFloat(timeString.split(":")[1]) * 60
  );
}

function revisePassMap(passMap) {
  if (passMap.inputDomain == "Add Prepaid Scheme") {
    const offeredJobNames = passMap.jobSettings.jobNames;
    const matchIndex = passMap.inputNames.indexOf("prepaidSchemeJobName");
    passMap.selectOptions[matchIndex] = offeredJobNames;
  }

  return passMap;
}

//@start
export default function InputLocationCont(props) {
console.log(props)

  const userContext = useContext(UserDataContext); //Main User Data of the Current User Broadcasted from Level 1
  const initPassDataObj = { ...revisePassMap(props.passMap) }; // Will contain data on input field names which will populate the input form

  const userUid = userContext.uid;

  const inputTemplObj =
    props.passMap.opType == "edit"
      ? { ...props.passMap.dataMap }
      : createInitialObj(initPassDataObj); //Template Object of the 'Input Data Object'
  console.log(inputTemplObj);
  ///WARNING - THERE SEEMS TO BE AN ISSUE IN THE UPDATING OF BELOW PLEASE CHECK WHEN FREE
  const [inputDataObj, setInputDataObj] = useState({ ...inputTemplObj });
  const [passDataObj, setPassDataObj] = useState({ ...initPassDataObj }); //Assigning the 'Input Template Object' as initial value for 'Input Object - State'
  const [inputDomain, setInputDomain] = useState("");
  // const [modeName, setModeName] = useState(props.tabName);
  const [inputImg, setInputImg] = useState("");
  const [editStatusArray, setEditStatusArray] = useState([
    ...props.passMap.editStatus,
  ]);
  const [inputDataObj2, setInputDataObj2] = useState({ ...inputTemplObj });
  const [latitude,setLatitude] = useState("");
  const [longitude,setLongitude] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});

  const [lat2,setLat2] = useState(initPassDataObj.dataMap.storeBranchLatitude==""?0:parseFloat(initPassDataObj.dataMap.storeBranchLatitude))
  const [long2,setLong2] = useState(initPassDataObj.dataMap.storeBranchLongitude==""?0:parseFloat(initPassDataObj.dataMap.storeBranchLongitude))
  console.log(initPassDataObj);
  console.log(inputDataObj);

  console.log(props.passMap);

  console.log(props.passMap.dataMap);

  useEffect(() => {
    (async () => {
      passDataObj.imgRequired
        ? setInputImg(
            URL.createObjectURL(await getFile(passDataObj.dataMap.storeImage))
          )
        : setInputImg("");
      setInputDomain(inputDataObj.inputDomain);
    })();
  }, []);

  useEffect(() => {
    //setInputDataObj({ ...props.passMap.dataMap });
  }, [props.tabName]);

  console.log(inputDataObj);
  console.log(props.tabName);

  useEffect(() => {
    if (props.submitClicked) {
      console.log("Submit Clicked and Executed");
      createDbRecord();
    }
  }, [props.submitClicked]);

  function displayPrompt(message,title,status){
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ?? 'Info',
      message:message
    })
  }

  async function getFile(fileLocation) {
    const locationRef = ref(storage, fileLocation);
    const file = await getBlob(locationRef);
    return file;
  }

  async function createDbRecord() {

      const serverCallFnName = "setStoreBranchLocation"
      let dbWriteOk = true;

        Object.keys(inputDataObj).every((key) => {
          console.log(inputDataObj);
          console.log(inputDataObj[key]);

          if (isNaN(inputDataObj[key])) {
            console.log("skddk");
            const map = {
              mode: "info",
              title: "Input Required",
              btnLabels: ["Ok"],
              message: "Please input all fields in order to submit",
            };
            props.dialogHandler(map);

            dbWriteOk = false;
            return false;
          } else {
            //adding decimals if not present
            const cordinateString = inputDataObj[key].toString();

            inputDataObj[key] = parseFloat(cordinateString);
            console.log(inputDataObj[key]);
          }
          return true;
        });

        if(dbWriteOk){
        const setSettingsServerCall = httpsCallable(
          functions,
          serverCallFnName
        );
        console.log(inputDataObj);

        const callResult = await setSettingsServerCall({ ...inputDataObj });
        displayPrompt(callResult.data);

        }
    
    props.submitCloseHandler();
  }

  function inputChangeFn(e, passDataObj) {
    const rInputName = e.target.name;
    const rInputType = e.target.type;
    const rInputValue = getInputValue(e.target);
    console.log(e.target);
    console.log(rInputValue);


    if (rInputType == "file") {
      setInputImg(URL.createObjectURL(rInputValue));
    }

    if (rInputName == "ovrStoreAvlCond") {
      //Enabling Store Availability Option when override is true
      const tMap = { ...passDataObj };
      const tArr = [...editStatusArray];
      const mIndex = tMap["inputNames"].indexOf("storeAvlCond");
      const mIndex1 = tMap["inputNames"].indexOf("storeStartTime");
      const mIndex2 = tMap["inputNames"].indexOf("storeEndTime");

      console.log(tArr);
      console.log(tMap);
      if (rInputValue) {
        tArr.splice(mIndex, 1, true);
        tArr.splice(mIndex1, 1, false);
        tArr.splice(mIndex2, 1, false);
      } else {
        tArr.splice(mIndex, 1, false);
        tArr.splice(mIndex1, 1, true);
        tArr.splice(mIndex2, 1, true);
      }
      setEditStatusArray([...tArr]);
    } else if (rInputName == "ovrWaitTimeCond") {
      const tMap = { ...passDataObj };
      const tArr = [...editStatusArray];
      const mIndex = tMap["inputNames"].indexOf("ovrWaitTime");
      if (rInputValue) {
        tArr.splice(mIndex, 1, true);
      } else {
        tArr.splice(mIndex, 1, false);
      }
      setEditStatusArray([...tArr]);
    }

    console.log(inputDataObj);
    console.log(inputDomain);
    const tempObj = { ...inputDataObj }; //copying the 'Input Object State' into a temp object to then set as 'Input Object State'
    // Above doing this so, is because if tempObj=inputDataObj, then changing the tempObj will also chaneg the input object and
    //then when I set the State, the state will not update as the temp and input objects are the same

    console.log(tempObj);
    tempObj[rInputName] = rInputValue;

    setInputDataObj(checkAndFinalizeInputDataObj(tempObj)); //Input Object Set

    console.log(inputDataObj);
  }




  function latLongHandler(data){

    setLatitude(data['lat'])
    setLongitude(data['lng'])

    setLat2(data['lat'])
    setLong2(data['lng'])

    setInputDataObj({
      storeBranchLatitude:data['lat'],
      storeBranchLongitude:data['lng']
    })

  }

  const rows = props.layout == "versatile" ? `` : `grid-rows-cust-11`;
  const cols = props.imgRequired ? `grid-cols-2 w-cust-60rem` : `w-cust-30rem`;

    const center = {
     lat: -3.745,
     lng: -38.523
    }

  console.log(passDataObj);

  console.log(passDataObj.dataMap['storeBranchLatitude']);

  return (

  <div className="flex flex-col items-center">
    <div className="w-cust-70rem  text-sm bg-quaternary-xlight py-2 px-4 rounded">{`INFO : To set your location, please move your pointer to the exact location on the map and click for the co-ordinates to be fetched and filled into the input box. Alternatively, you can manually input your latitude and longitude in the input box (for negetive values, type the number and then add the '-' at the begining).`}</div>

    <div className="flex items-center justify-center p-10 w-full gap-x-2">
      {/* <MapCont passLatLong = {(data)=>latLongHandler(data)} /> */}
      <div className="flex justify-end">
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
          <MyMapComponent center={{
            lat:lat2,
            lng:long2,
          }} zoom={4} passLatLong = {(data)=>latLongHandler(data)} classValues="h-cust-30rem w-cust-70rem rounded-l-lg"/>
       </Wrapper>
      </div>


      {/* <div className={`grid grid-flow-row ${rows} ${cols} gap-1`}>
        {checkAndRenderInputElements(
          inputTemplObj,
          passDataObj,
          inputDataObj,
          props.catIndex
        )}
      </div> */}

 

<div className={`h-cust-30rem grid-rows-cust-2f grid gap-y-2`}>
      {Object.keys(inputTemplObj).map((key,i) => {
        return(
              <div className="flex items-center bg-quaternary-xlight">
              <InputTile
                key={`${passDataObj.inputNames[i]}-${i}`} //passDataObj has all data from props object
                inputName={passDataObj.inputNames[i]}
                inputType={passDataObj.inputTypes[i]}
                inputValue={inputDataObj[passDataObj.inputNames[i]]}
                onChangeFn={(e) => inputChangeFn(e, passDataObj)}
                inputLabel={passDataObj.inputLabels[i]}
                editStatus={editStatusArray != null ? editStatusArray[i] : true}
                opType={passDataObj.opType}
                regexDomain={Object.keys(passDataObj).includes("inputRegexDomains")?passDataObj.inputRegexDomains[i]:""}
                changeValdCount={(val)=>props.changeValdCount(val)}
                fieldValue={
                  passDataObj.opType == "edit"
                    ? passDataObj.dataMap[passDataObj.inputNames[i]]
                    : ""
                }
                orientation="vertical"/>
                </div>)})}

      </div>
      {/* <button onClick={() => alert(inputDataObj["latitude"])}>Check</button> */}
    </div>
    {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
    </div>
  );
}
