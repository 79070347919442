

export const btnStyleMap = {
    color:{
        high:"red",
        medium:"primaryPale",
        low:"primaryPale",
        default:"blackPale" 
        // critical:"red",
        // nonCritical:"bg-primaryPale",
        // revert:"bg-black",
        // default:"bg-black" 
    }
}