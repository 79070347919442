import React from "react";
import { styleMapProject } from "./viewProps";

export default function InputList(props) {
  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value
  return (
    <div className={`grid grid-cols-cust-2g gap-1 bg-${styleMapProject.inputElem.background} p-3`}>
      <label>{props.inputLabel}</label>
      <select
        className="pl-2"
        type={props.inputType}
        name={props.inputName}
        onChange={props.onChangeFn}
        // defaultValue={props.fieldValue}
        value={props.inputValue }
      >
         <option>Please Select</option>
        {props.selectOptions.map((option, i) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
