import React, { Component, Font } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import RecordStrip from "./RecordStrip";
import RecordBtnStrip from "./RecordBtnStrip";
import FilterInput from "./FilterInput";
import ImageCont from "./ImageCont";
import RecordBanner from "./RecordBanner";




import NoAvailableDisplay from "./NoAvailableDisplay";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
  generatePdf,
  getDecimalValue,
  getDateStringFromMillis,
  getJobCountRangeAndCostStrMap,
  generateInvoice,
  generatePdf2
} from "../Utils";
import { UserDataContext } from "./Level1";

const pageLimit = 5;



function getDbCollPath(props){
  switch(props.passMap.listTopic){
    case "Unpaid Invoice":{
      return `admin/accounts/unpaidInvoices`
    }
  }
}


export default function TestContFirestore(props) {

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );
  
  const [inputVal,setInputVal] = useState("")
  const [testCond,setTestCond] = useState("")

  useEffect(() => {
    initializePageWithData();
  }, []);

  function initializePageWithData(){
  }


  function inputChangeFn(e){
    setInputVal(e.target.value)
  }


 async function testFunction(inputValue){

  const timeServerCall = httpsCallable(functions, "checkRole");
  const time = await timeServerCall({});
  alert(time.data);

  
  // getDocs(collection(db,inputValue),where("partyIds","!=",false),limit(1)).then((result)=>{
  //     alert(result)
  //     setTestCond("passed")
  //   }).catch((error)=>{
  //     alert(error)
  //     setTestCond("failed")
  //   })

    
    // const result = await getDocs(collection(db,inputValue),where("partyIds","!=",false),limit(1));

    // alert(result)

  }

  async function sendMail(){

    const keys = ["subject","htmlContent","sender","to","replyTo","headers","params"];

    // const payload = {
    //   subject: "Test mail",
    //   htmlContent:`<div style="height:300px;width:400px;background:green;">Hello</div>`,
    //   sender:{ "name": "John", "email": "info@hockeycaddy.ca" },
    //   to:[{ "name": "Michael", "email": "nds.professional@gmail.com" }],
    //   replyTo:{ "name": "Sunny", "email": "sunny@hockeycaddy.ca" },
    // }


    const payload = {
      "sender": {
        "name": "Harry",
        "email": "info@hockeycaddy.ca"
      },
      "replyTo": {
        "email": "naman@hockeycaddy.ca",
        "name": "Naman"
      },
      "to": [
        {
          "email": "nds.professional@gmail.com",
          "name": "Jimmy"
        }
      ],
      "htmlContent": "<!DOCTYPE html> <html> <body> <h1>Confirm you email</h1> <p>Please confirm your email address by clicking on the link below</p> </body> </html>",
      "subject": "Verification",
      templateId:1,
      params:{
        "name":"Nishanth Salian",
        "verifLink":"https://www.hockeycaddy.ca/support"
      },
      "attachmentReqCond":true
    }
    

    const serverCall = httpsCallable(functions, "sendTestMail");
    const result = await serverCall(payload);
    alert(result.data);
  

  }


  return (
    <React.Fragment>
      <div className="flex justify-center items-center gap-4">
        <input type="text" onChange={(e)=>setInputVal(e.target.value)}></input>
        <button onClick={()=>testFunction(inputVal)}>Check</button>
        <div className={`h-4 bg-green`}>{testCond}</div>

        <button onClick={()=>sendMail()}>Test Mail Send</button>
        <button onClick={()=>generatePdf2()}>Generate Pdf</button>
      </div>
      {bottomContainer}
   </React.Fragment>
  );
}



