import React, { Component } from "react";
import ButtonIcon from "./ButtonIcon";
import skateLogo from "../images/app-logo.svg";

export default function SupportScreen(props) {
  /*     function clickFunction(){
        props.onClickFnIntermediate();
    } */

    console.log(props)

  return (
    <div className="h-screen p-16 bg-quaternary-light">

      <div className="w-2/12">
         <img className="" src={skateLogo} />
         <div className="text-5xl font-bold py-4 text-center">SUPPORT</div>
      </div>

      <div className="my-16 text-xl">
          We are updating our website. In the meantime please write to <b>info@hockeycaddy.ca</b> for any info/query and we will get back promptly.
      </div>



    </div>
  );
}
