
import { getDateStrFromJSDate } from "./genFunctions";
import { pdfGenerateSchemeMap } from "./viewProps";


const viewSchemeMapAssmt={}
const schemeMapAssmt={}

export function generateStudentExamMarksSheet(inpSchemeMap,dataMapObj){
    console.log(dataMapObj)

    const schemeMap = pdfGenerateSchemeMap.studentExamMarks

    const listRecordMaps = dataMapObj.listRecordMaps
    const genDataMap = dataMapObj.generalDataMap

    const colors={
        lightGrey:"#f2f2f2",
        darkGrey:"#737373"
       }

    const content = [];

    const headerContent = generateHeaderContent(schemeMap,genDataMap)
    content.push(headerContent)


    const headerDetailsContent = generateHeaderDetailsCont(schemeMap,genDataMap)
    content.push(headerDetailsContent)

    const bodyTableContent = generateBodyTableContent(schemeMap,listRecordMaps)
    content.push(bodyTableContent);


function generateBodyTableContent(schemeMap,listRecordMaps){   

    const uiMap = {}
    uiMap["layout"] = "minSpaceRowSection";
    uiMap["fontSize"] = 7;
    uiMap["font"] = "Roboto";
    uiMap["bold"] = true;


    const tableMap={}
    tableMap["headerRows"]=0;
    tableMap["widths"]=["5%","10%","65%","10%","10%"]

    const tableBodyRows = []

        const rowArr=[];


        schemeMap.tableColTitles.forEach((title,i)=>{

                const textMap={
                    text:`${title}`,
                    alignment:"center",
                    fillColor:colors.lightGrey,
                    colSpan:1,
                    rowSpan: 1
                };

                rowArr.push(textMap)
            
        });

        tableBodyRows.push(rowArr);

        console.log(tableBodyRows)
       
        let slNoCount = 1;
    
        listRecordMaps.forEach((valMap,i)=>{

            console.log(valMap)

            const rowArr=[];
           

            for(const key of schemeMap.tableColValueKeys){

            // genSchemeMap.tableColValueKeys.forEach((key,j)=>{
                let text = ""
                if(key=="slNo"){
                    text = slNoCount;
                    slNoCount+=1;
                }else if(key=="presentCond"){
                    text = valMap[key] == true ? "Yes" : "No"
                }else{
                    text = valMap[key]
                }
 
                const textMap={
                    text:`${text}`,
                    alignment:"center",
                }

                rowArr.push(textMap)
            //})
        }

            tableBodyRows.push(rowArr);

        });

        console.log(tableBodyRows)

        tableMap["body"] = tableBodyRows

        uiMap["table"]=tableMap
        return uiMap;
    }

    // content.push(generateContractorEvalContents())
    // content.push(generateLegendContentEval())
    // content.push(generateFooterContentsEval())

    return content;


    


    function getEqualColumnWidths(itemCount){
        const unitWidth = `${(100/itemCount).toFixed(2)}%`

        const equalColWidths=[]

        for(let x=0;x<itemCount;x++){
            equalColWidths.push(unitWidth)
        }

        console.log(equalColWidths)
        return equalColWidths
    }

    function generateHeaderDetailsCont(schemeMap,genDataMap){
        console.log(genDataMap)

        const labels = schemeMap.headDetailLabels
        const mapObjKeys = schemeMap.headDetailMapObjKeys
        const mapKeys = schemeMap.headDetailMapKeys
        const dataTypes = schemeMap.headDetailDataTypes

        const uiMap = {}
        uiMap["layout"] = "minSpaceRowSection";
        uiMap["fontSize"] = 8;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

        const tableMap={}
        tableMap["headerRows"]= 0;
        tableMap["widths"]=['10%','23%','10%','23%','10%','24%']


        const tableBodyRows = [];

        let textMaps = []
        let rowNo = 1;
        let colNo = 1;

        console.log(genDataMap,mapObjKeys,mapKeys)

        for(const [i,label] of labels.entries()){
            const labelTextMap  = {
                text:label.toUpperCase(),
                alignment:"center",
                fillColor:colors.lightGrey,
                bold:true
            }

            const valueTextMap  = {
                // text:dataTypes[i] == "date-timestamp" ? getDateStrFromJSDate(genDataMap[mapObjKeys[i]][mapKeys[i]].toDate()) : genDataMap[mapObjKeys[i]][mapKeys[i]],
                text:genDataMap[mapKeys[i]],
                alignment:"center",
                bold:true
            }

            textMaps.push(labelTextMap)
            textMaps.push(valueTextMap)

            colNo+=2;

            const tableColLimit = tableMap["widths"].length;


            if(colNo > tableColLimit){// we are at the end of the row
                colNo = 1;
                rowNo++;

                tableBodyRows.push(textMaps)
                textMaps = []
            }else if(i==labels.length-1){// incase the remaining cols are less than the limit

                textMaps.push("")
                textMaps.push("")

                tableBodyRows.push(textMaps)
            }

        }


        console.log(tableBodyRows)

    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap


    }


    function generateHeaderContent(schemeMap,genDataMap){
        const uiMap = {}
        uiMap["layout"] = "sectionHead";
        uiMap["fontSize"] = 18;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

    
        const tableMap={}
        tableMap["headerRows"]=0;
        tableMap["widths"]=['100%']
    
        const tableBodyRows = []
        let rowArr=[];

        let textMap = {
            text:genDataMap[schemeMap.headRowTitle1Key].toUpperCase(),
            alignment:"center",
            bold:true
        }
    

 
        rowArr.push(textMap);
    
        tableBodyRows.push(rowArr);
    
        rowArr=[]
        // rowArr.push('') // for the image

        const value = schemeMap.headRowTitle2Label

        textMap = {
            text:value.toUpperCase(),
            alignment:"center",
            fontSize:12
        }
        rowArr.push(textMap)

        tableBodyRows.push(rowArr);
    
    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap
    
    }



}

export function generateExamMarksSheet(inpSchemeMap,dataMapObj){
    console.log(dataMapObj)

    const schemeMap = pdfGenerateSchemeMap.examMarks

    const listRecordMaps = dataMapObj.listRecordMaps
    const genDataMap = dataMapObj.generalDataMap

    const colors={
        lightGrey:"#f2f2f2",
        darkGrey:"#737373"
       }

    const content = [];

    const headerContent = generateHeaderContent(schemeMap,genDataMap)
    content.push(headerContent)


    const headerDetailsContent = generateHeaderDetailsCont(schemeMap,genDataMap)
    content.push(headerDetailsContent)

    const bodyTableContent = generateBodyTableContent(schemeMap,listRecordMaps)
    content.push(bodyTableContent);


function generateBodyTableContent(schemeMap,listRecordMaps){   

    const uiMap = {}
    uiMap["layout"] = "minSpaceRowSection";
    uiMap["fontSize"] = 7;
    uiMap["font"] = "Roboto";
    uiMap["bold"] = true;


    const tableMap={}
    tableMap["headerRows"]=0;
    tableMap["widths"]=["5%","10%","65%","10%","10%"]

    const tableBodyRows = []

        const rowArr=[];


        schemeMap.tableColTitles.forEach((title,i)=>{

                const textMap={
                    text:`${title}`,
                    alignment:"center",
                    fillColor:colors.lightGrey,
                    colSpan:1,
                    rowSpan: 1
                };

                rowArr.push(textMap)
            
        });

        tableBodyRows.push(rowArr);

        console.log(tableBodyRows)
       
        let slNoCount = 1;
    
        listRecordMaps.forEach((valMap,i)=>{

            console.log(valMap)

            const rowArr=[];
           

            for(const key of schemeMap.tableColValueKeys){

            // genSchemeMap.tableColValueKeys.forEach((key,j)=>{
                let text = ""
                if(key=="slNo"){
                    text = slNoCount;
                    slNoCount+=1;
                }else if(key=="presentCond"){
                    text = valMap[key] == true ? "Yes" : "No"
                }else{
                    text = valMap[key]
                }
 
                const textMap={
                    text:`${text}`,
                    alignment:"center",
                }

                rowArr.push(textMap)
            //})
        }

            tableBodyRows.push(rowArr);

        });

        console.log(tableBodyRows)

        tableMap["body"] = tableBodyRows

        uiMap["table"]=tableMap
        return uiMap;
    }

    // content.push(generateContractorEvalContents())
    // content.push(generateLegendContentEval())
    // content.push(generateFooterContentsEval())

    return content;


    


    function getEqualColumnWidths(itemCount){
        const unitWidth = `${(100/itemCount).toFixed(2)}%`

        const equalColWidths=[]

        for(let x=0;x<itemCount;x++){
            equalColWidths.push(unitWidth)
        }

        console.log(equalColWidths)
        return equalColWidths
    }

    function generateHeaderDetailsCont(schemeMap,genDataMap){
        console.log(genDataMap)

        const labels = schemeMap.headDetailLabels
        const mapObjKeys = schemeMap.headDetailMapObjKeys
        const mapKeys = schemeMap.headDetailMapKeys
        const dataTypes = schemeMap.headDetailDataTypes

        const uiMap = {}
        uiMap["layout"] = "minSpaceRowSection";
        uiMap["fontSize"] = 8;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

        const tableMap={}
        tableMap["headerRows"]= 0;
        tableMap["widths"]=['10%','23%','10%','23%','10%','24%']


        const tableBodyRows = [];

        let textMaps = []
        let rowNo = 1;
        let colNo = 1;

        console.log(genDataMap,mapObjKeys,mapKeys)

        for(const [i,label] of labels.entries()){
            const labelTextMap  = {
                text:label.toUpperCase(),
                alignment:"center",
                fillColor:colors.lightGrey,
                bold:true
            }

            const valueTextMap  = {
                // text:dataTypes[i] == "date-timestamp" ? getDateStrFromJSDate(genDataMap[mapObjKeys[i]][mapKeys[i]].toDate()) : genDataMap[mapObjKeys[i]][mapKeys[i]],
                text:genDataMap[mapKeys[i]],
                alignment:"center",
                bold:true
            }

            textMaps.push(labelTextMap)
            textMaps.push(valueTextMap)

            colNo+=2;

            const tableColLimit = tableMap["widths"].length;


            if(colNo > tableColLimit){// we are at the end of the row
                colNo = 1;
                rowNo++;

                tableBodyRows.push(textMaps)
                textMaps = []
            }else if(i==labels.length-1){// incase the remaining cols are less than the limit

                textMaps.push("")
                textMaps.push("")

                tableBodyRows.push(textMaps)
            }

        }


        console.log(tableBodyRows)

    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap


    }


    function generateHeaderContent(schemeMap,genDataMap){
        const uiMap = {}
        uiMap["layout"] = "sectionHead";
        uiMap["fontSize"] = 18;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

    
        const tableMap={}
        tableMap["headerRows"]=0;
        tableMap["widths"]=['100%']
    
        const tableBodyRows = []
        let rowArr=[];

        let textMap = {
            text:genDataMap[schemeMap.headRowTitle1Key].toUpperCase(),
            alignment:"center",
            bold:true
        }
    

 
        rowArr.push(textMap);
    
        tableBodyRows.push(rowArr);
    
        rowArr=[]
        // rowArr.push('') // for the image

        const value = schemeMap.headRowTitle2Label

        textMap = {
            text:value.toUpperCase(),
            alignment:"center",
            fontSize:12
        }
        rowArr.push(textMap)

        tableBodyRows.push(rowArr);
    
    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap
    
    }



}

export function generateInstituteSectionSheet(schemeMap,dataMapObj){
    console.log(dataMapObj)

    const listRecordMaps = dataMapObj.listRecordMaps
    const genDataMap = dataMapObj.generalDataMap

    const colors={
        lightGrey:"#f2f2f2",
        darkGrey:"#737373"
       }

    const content = [];

    const headerContent = generateHeaderContent(schemeMap,genDataMap)
    content.push(headerContent)


    const headerDetailsContent = generateHeaderDetailsCont(schemeMap,genDataMap)
    content.push(headerDetailsContent)

    const bodyTableContent = generateBodyTableContent(schemeMap,listRecordMaps)
    content.push(bodyTableContent);


function generateBodyTableContent(schemeMap,listRecordMaps){   

    const uiMap = {}
    uiMap["layout"] = "minSpaceRowSection";
    uiMap["fontSize"] = 7;
    uiMap["font"] = "Roboto";
    uiMap["bold"] = true;


    const tableMap={}
    tableMap["headerRows"]=0;
    tableMap["widths"]=schemeMap.tableColWidths

    const tableBodyRows = []

        const rowArr=[];


        schemeMap.tableColTitles.forEach((title,i)=>{

                const textMap={
                    text:`${title}`,
                    alignment:"center",
                    fillColor:colors.lightGrey,
                    colSpan:1,
                    rowSpan: 1
                };

                rowArr.push(textMap)
            
        });

        tableBodyRows.push(rowArr);

        console.log(tableBodyRows)
       
        let slNoCount = 1;
    
        listRecordMaps.forEach((valMap,i)=>{

            console.log(valMap)

            const rowArr=[];
           

            for(const key of schemeMap.tableColValueKeys){

            // genSchemeMap.tableColValueKeys.forEach((key,j)=>{
                let text = ""
                if(key=="slNo"){
                    text = slNoCount;
                    slNoCount+=1;
                }else if(key=="presentCond"){
                    text = valMap[key] == true ? "Yes" : "No"
                }else{
                    text = valMap[key]
                }
 
                const textMap={
                    text:`${text}`,
                    alignment:"center",
                }

                rowArr.push(textMap)
            //})
        }

            tableBodyRows.push(rowArr);

        });

        console.log(tableBodyRows)

        tableMap["body"] = tableBodyRows

        uiMap["table"]=tableMap
        return uiMap;
    }

    // content.push(generateContractorEvalContents())
    // content.push(generateLegendContentEval())
    // content.push(generateFooterContentsEval())

    return content;


    


    function getEqualColumnWidths(itemCount){
        const unitWidth = `${(100/itemCount).toFixed(2)}%`

        const equalColWidths=[]

        for(let x=0;x<itemCount;x++){
            equalColWidths.push(unitWidth)
        }

        console.log(equalColWidths)
        return equalColWidths
    }

    function generateHeaderDetailsCont(schemeMap,genDataMap){
        console.log(genDataMap)

        const labels = schemeMap.headDetailLabels
        const mapObjKeys = schemeMap.headDetailMapObjKeys
        const mapKeys = schemeMap.headDetailMapKeys
        const dataTypes = schemeMap.headDetailDataTypes

        const uiMap = {}
        uiMap["layout"] = "minSpaceRowSection";
        uiMap["fontSize"] = 8;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

        const tableMap={}
        tableMap["headerRows"]= 0;
        tableMap["widths"]=['10%','23%','10%','23%','10%','24%']


        const tableBodyRows = [];

        let textMaps = []
        let rowNo = 1;
        let colNo = 1;

        console.log(genDataMap,mapObjKeys,mapKeys)

        for(const [i,label] of labels.entries()){
            const labelTextMap  = {
                text:label.toUpperCase(),
                alignment:"center",
                fillColor:colors.lightGrey,
                bold:true
            }

            const valueTextMap  = {
                // text:dataTypes[i] == "date-timestamp" ? getDateStrFromJSDate(genDataMap[mapObjKeys[i]][mapKeys[i]].toDate()) : genDataMap[mapObjKeys[i]][mapKeys[i]],
                text:genDataMap[mapKeys[i]],
                alignment:"center",
                bold:true
            }

            textMaps.push(labelTextMap)
            textMaps.push(valueTextMap)

            colNo+=2;

            const tableColLimit = tableMap["widths"].length;


            if(colNo > tableColLimit){// we are at the end of the row
                colNo = 1;
                rowNo++;

                tableBodyRows.push(textMaps)
                textMaps = []
            }else if(i==labels.length-1){// incase the remaining cols are less than the limit

                textMaps.push("")
                textMaps.push("")

                tableBodyRows.push(textMaps)
            }

        }


        console.log(tableBodyRows)

    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap


    }


    function generateHeaderContent(schemeMap,genDataMap){
        const uiMap = {}
        uiMap["layout"] = "sectionHead";
        uiMap["fontSize"] = 18;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

    
        const tableMap={}
        tableMap["headerRows"]=0;
        tableMap["widths"]=['100%']
    
        const tableBodyRows = []
        let rowArr=[];

        let textMap = {
            text:genDataMap[schemeMap.headRowTitle1Key].toUpperCase(),
            alignment:"center",
            bold:true
        }
    

 
        rowArr.push(textMap);
    
        tableBodyRows.push(rowArr);
    
        rowArr=[]
        // rowArr.push('') // for the image

        const value = schemeMap.headRowTitle2Label

        textMap = {
            text:value.toUpperCase(),
            alignment:"center",
            fontSize:12
        }
        rowArr.push(textMap)

        tableBodyRows.push(rowArr);
    
    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap
    
    }



}

export function generateStudentSheet(schemeMap,dataMapObj){
    console.log(dataMapObj)


    const listRecordMaps = dataMapObj.listRecordMaps
    const genDataMap = dataMapObj.generalDataMap

    const colors={
        lightGrey:"#f2f2f2",
        darkGrey:"#737373"
       }

    const content = [];

    const headerContent = generateHeaderContent(schemeMap,genDataMap)
    content.push(headerContent)


    const headerDetailsContent = generateHeaderDetailsCont(schemeMap,genDataMap)
    content.push(headerDetailsContent)

    const bodyTableContent = generateBodyTableContent(schemeMap,listRecordMaps)
    content.push(bodyTableContent);


function generateBodyTableContent(schemeMap,listRecordMaps){   

    const uiMap = {}
    uiMap["layout"] = "minSpaceRowSection";
    uiMap["fontSize"] = 7;
    uiMap["font"] = "Roboto";
    uiMap["bold"] = true;


    const tableMap={}
    tableMap["headerRows"]=0;
    tableMap["widths"]=schemeMap.tableColWidths;

    const tableBodyRows = []

        const rowArr=[];


        schemeMap.tableColTitles.forEach((title,i)=>{

                const textMap={
                    text:`${title}`,
                    alignment:"center",
                    fillColor:colors.lightGrey,
                    colSpan:1,
                    rowSpan: 1
                };

                rowArr.push(textMap)
            
        });

        tableBodyRows.push(rowArr);

        console.log(tableBodyRows)
       
        let slNoCount = 1;
    
        listRecordMaps.forEach((valMap,i)=>{

            console.log(valMap)

            const rowArr=[];
           

            for(const key of schemeMap.tableColValueKeys){

            // genSchemeMap.tableColValueKeys.forEach((key,j)=>{
                let text = ""
                if(key=="slNo"){
                    text = slNoCount;
                    slNoCount+=1;
                }else if(key=="presentCond"){
                    text = valMap[key] == true ? "Yes" : "No"
                }else{
                    text = valMap[key]
                }
 
                const textMap={
                    text:`${text}`,
                    alignment:"center",
                }

                rowArr.push(textMap)
            //})
        }

            tableBodyRows.push(rowArr);

        });

        console.log(tableBodyRows)

        tableMap["body"] = tableBodyRows

        uiMap["table"]=tableMap
        return uiMap;
    }

    // content.push(generateContractorEvalContents())
    // content.push(generateLegendContentEval())
    // content.push(generateFooterContentsEval())

    return content;


    


    function getEqualColumnWidths(itemCount){
        const unitWidth = `${(100/itemCount).toFixed(2)}%`

        const equalColWidths=[]

        for(let x=0;x<itemCount;x++){
            equalColWidths.push(unitWidth)
        }

        console.log(equalColWidths)
        return equalColWidths
    }

    function generateHeaderDetailsCont(schemeMap,genDataMap){
        console.log(genDataMap)

        const labels = schemeMap.headDetailLabels
        const mapObjKeys = schemeMap.headDetailMapObjKeys
        const mapKeys = schemeMap.headDetailMapKeys
        const dataTypes = schemeMap.headDetailDataTypes

        const uiMap = {}
        uiMap["layout"] = "minSpaceRowSection";
        uiMap["fontSize"] = 8;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

        const tableMap={}
        tableMap["headerRows"]= 0;
        tableMap["widths"]=['10%','23%','10%','23%','10%','24%']


        const tableBodyRows = [];

        let textMaps = []
        let rowNo = 1;
        let colNo = 1;



        console.log(genDataMap,mapObjKeys,mapKeys)

        for(const [i,label] of labels.entries()){
            const labelTextMap  = {
                text:label.toUpperCase(),
                alignment:"center",
                fillColor:colors.lightGrey,
                bold:true
            }

            const valueTextMap  = {
                // text:dataTypes[i] == "date-timestamp" ? getDateStrFromJSDate(genDataMap[mapObjKeys[i]][mapKeys[i]].toDate()) : genDataMap[mapObjKeys[i]][mapKeys[i]],
                text:genDataMap[mapKeys[i]],
                alignment:"center",
                bold:true
            }

            textMaps.push(labelTextMap)
            textMaps.push(valueTextMap)

            colNo+=2;

            const tableColLimit = tableMap["widths"].length;


            if(colNo > tableColLimit){// we are at the end of the row
                colNo = 1;
                rowNo++;

                tableBodyRows.push(textMaps)
                textMaps = []
            }
            
            if(i==labels.length-1){// check at end of the value array

                if(labels.length == tableColLimit){ // since it is end of row, no need of adding blank values

                }else{
                    const lastFilledCol = colNo-1
                    const blankColCount = tableColLimit - lastFilledCol;

                    for(let j=1;j<=blankColCount;j++){
                        textMaps.push("")
                    }
                    tableBodyRows.push(textMaps)
                }
            }

        }

        console.log(tableBodyRows)
    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap


    }


    function generateHeaderContent(schemeMap,genDataMap){
        const uiMap = {}
        uiMap["layout"] = "sectionHead";
        uiMap["fontSize"] = 18;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

    
        const tableMap={}
        tableMap["headerRows"]=0;
        tableMap["widths"]=['100%']
    
        const tableBodyRows = []
        let rowArr=[];

        let textMap = {
            text:genDataMap[schemeMap.headRowTitle1Key].toUpperCase(),
            alignment:"center",
            bold:true
        }
    

 
        rowArr.push(textMap);
    
        tableBodyRows.push(rowArr);
    
        rowArr=[]
        // rowArr.push('') // for the image

        const value = schemeMap.headRowTitle2Label

        textMap = {
            text:value.toUpperCase(),
            alignment:"center",
            fontSize:12
        }
        rowArr.push(textMap)

        tableBodyRows.push(rowArr);
    
    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap
    
    }



}

// export function generateExamMarksSheet(inpSchemeMap,dataMapObj){
//     console.log(dataMapObj)

//     const schemeMap = pdfGenerateSchemeMap.examMarks

//     const valMaps = dataMapObj.sheetRowDataMaps
//     const selectionMapObj = dataMapObj.selectionNodeDbMapObj

//     const colors={
//         lightGrey:"#f2f2f2",
//         darkGrey:"#737373"
//        }

//     const content = [];

//     const headerContent = generateHeaderContent(selectionMapObj)

//     content.push(headerContent)


//     const headerDetailsContent = generateHeaderDetailsCont(selectionMapObj)

//     content.push(headerDetailsContent)


//     const bodyTableContent = generateBodyTableContent(valMaps)

//     content.push(bodyTableContent);


// function generateBodyTableContent(){   

//     const uiMap = {}
//     uiMap["layout"] = "minSpaceRowSection";
//     uiMap["fontSize"] = 7;
//     uiMap["font"] = "Roboto";
//     uiMap["bold"] = true;


//     const tableMap={}
//     tableMap["headerRows"]=0;
//     tableMap["widths"]=["5%","10%","65%","10%","10%"]

//     const tableBodyRows = []

//         const rowArr=[];


//         schemeMap.tableColTitles.forEach((title,i)=>{

//                 const textMap={
//                     text:`${title}`,
//                     alignment:"center",
//                     fillColor:colors.lightGrey,
//                     colSpan:1,
//                     rowSpan: 1
//                 };

//                 rowArr.push(textMap)
            
//         });

//         tableBodyRows.push(rowArr);

//         console.log(tableBodyRows)
       
//         let slNoCount = 1;
    
//         valMaps.forEach((valMap,i)=>{

//             console.log(valMap)

//             const rowArr=[];
//             const genSchemeMap = pdfGenerateSchemeMap.examMarks

//             for(const key of genSchemeMap.tableColValueKeys){

//             // genSchemeMap.tableColValueKeys.forEach((key,j)=>{
//                 let text = ""
//                 if(key=="slNo"){
//                     text = slNoCount;
//                     slNoCount+=1;
//                 }else if(key=="presentCond"){
//                     text = valMap[key] == true ? "Yes" : "No"
//                 }else{
//                     text = valMap[key]
//                 }
 
//                 const textMap={
//                     text:`${text}`,
//                     alignment:"center",
//                 }

//                 rowArr.push(textMap)
//             //})
//         }

//             tableBodyRows.push(rowArr);

//         });

//         tableMap["body"] = tableBodyRows

//         uiMap["table"]=tableMap
//         return uiMap;
//     }

//     // content.push(generateContractorEvalContents())
//     // content.push(generateLegendContentEval())
//     // content.push(generateFooterContentsEval())

//     return content;


    


//     function getEqualColumnWidths(itemCount){
//         const unitWidth = `${(100/itemCount).toFixed(2)}%`

//         const equalColWidths=[]

//         for(let x=0;x<itemCount;x++){
//             equalColWidths.push(unitWidth)
//         }

//         console.log(equalColWidths)
//         return equalColWidths
//     }

//     function generateHeaderDetailsCont(selMapObj){
//         console.log(selMapObj)

//         const labels = schemeMap.headDetailLabels
//         const mapObjKeys = schemeMap.headDetailMapObjKeys
//         const mapKeys = schemeMap.headDetailMapKeys
//         const dataTypes = schemeMap.headDetailDataTypes



//         const uiMap = {}
//         uiMap["layout"] = "minSpaceRowSection";
//         uiMap["fontSize"] = 8;
//         uiMap["font"] = "Roboto";
//         uiMap["bold"] = true;

//         const tableMap={}
//         tableMap["headerRows"]= 0;
//         tableMap["widths"]=['10%','23%','10%','23%','10%','24%']


//         const tableBodyRows = [];

//         let textMaps = []
//         let rowNo = 1;
//         let colNo = 1;

//         console.log(selMapObj,mapObjKeys,mapKeys)

//         for(const [i,label] of labels.entries()){
//             const labelTextMap  = {
//                 text:label.toUpperCase(),
//                 alignment:"center",
//                 fillColor:colors.lightGrey,
//                 bold:true
//             }

//             const valueTextMap  = {
//                 // text:dataTypes[i] == "date-timestamp" ? getDateStrFromJSDate(selMapObj[mapObjKeys[i]][mapKeys[i]].toDate()) : selMapObj[mapObjKeys[i]][mapKeys[i]],
//                 text:selMapObj[mapObjKeys[i]][mapKeys[i]],
//                 alignment:"center",
//                 bold:true
//             }

//             textMaps.push(labelTextMap)
//             textMaps.push(valueTextMap)

//             colNo+=2;

//             const tableColLimit = tableMap["widths"].length;


//             if(colNo > tableColLimit){// we are at the end of the row
//                 colNo = 1;
//                 rowNo++;

//                 tableBodyRows.push(textMaps)
//                 textMaps = []
//             }else if(i==labels.length-1){// incase the remaining cols are less than the limit

//                 textMaps.push("")
//                 textMaps.push("")

//                 tableBodyRows.push(textMaps)
//             }

//         }


//         console.log(tableBodyRows)

    
//         tableMap["body"] = tableBodyRows
    
//         uiMap["table"]=tableMap
//         return uiMap


//     }


//     function generateHeaderContent(selMapObj){
//         const uiMap = {}
//         uiMap["layout"] = "sectionHead";
//         uiMap["fontSize"] = 14;
//         uiMap["font"] = "Roboto";
//         uiMap["bold"] = true;
    
//         let insitutueName = selMapObj["institute"]["name"];
     
    
//         const tableMap={}
//         tableMap["headerRows"]=0;
//         tableMap["widths"]=['20%','80%']
    
//         const tableBodyRows = []
//         let rowArr=[];
//         let imageMap = {
//             image:"app-logo.png",
//             width:50,
//             alignment:"center",
//             rowSpan:2
//         }

//         let textMap = {
//             text:insitutueName.toUpperCase(),
//             alignment:"center",
//             bold:true
//         }
    

//         rowArr.push(imageMap);
//         rowArr.push(textMap);
    
//         tableBodyRows.push(rowArr);
    
//         rowArr=[]
//         rowArr.push('') // for the image

//         const value = schemeMap.headRowTitle

//         textMap = {
//             text:value.toUpperCase(),
//             alignment:"center",
//             fontSize:12
//         }
//         rowArr.push(textMap)

//         tableBodyRows.push(rowArr);
    
    
//         tableMap["body"] = tableBodyRows
    
//         uiMap["table"]=tableMap
//         return uiMap
    
//     }



// }

export function generateAttendanceSheet(inpSchemeMap,dataMapObj){

    const schemeMap = pdfGenerateSchemeMap.attendance

    console.log(dataMapObj)

    const valMaps = dataMapObj.sheetRowDataMaps
    const selectionMapObj = dataMapObj.selectionNodeDbMapObj

    const colors={
        lightGrey:"#f2f2f2",
        darkGrey:"#737373"
       }

    const content = [];

    const headerContent = generateHeaderContent(selectionMapObj)

    content.push(headerContent)


    const headerDetailsContent = generateHeaderDetailsCont(selectionMapObj)

    content.push(headerDetailsContent)


    const bodyTableContent = generateBodyTableContent(valMaps)

    content.push(bodyTableContent);


function generateBodyTableContent(){   

    const uiMap = {}
    uiMap["layout"] = "minSpaceRowSection";
    uiMap["fontSize"] = 7;
    uiMap["font"] = "Roboto";
    uiMap["bold"] = true;


    const tableMap={}
    tableMap["headerRows"]=0;
    tableMap["widths"]=["5%","10%","65%","10%","10%"]

    const tableBodyRows = []

        const rowArr=[];
  

        schemeMap.tableColTitles.forEach((title,i)=>{

                const textMap={
                    text:`${title}`,
                    fillColor:colors.lightGrey,
                    alignment:"center",
                    colSpan:1,
                    rowSpan: 1
                };

                rowArr.push(textMap)
            
        });

        tableBodyRows.push(rowArr);

        console.log(tableBodyRows)
       
        let slNoCount = 1;
    
        valMaps.forEach((valMap,i)=>{

            console.log(valMap)

            const rowArr=[];
    

            for(const key of schemeMap.tableColValueKeys){

            // genSchemeMap.tableColValueKeys.forEach((key,j)=>{
                let text = ""
                if(key=="slNo"){
                    text = slNoCount;
                    slNoCount+=1;
                }else if(key=="presentCond"){
                    text = valMap[key] == true ? "Yes" : "No"
                }else{
                    text = valMap[key]
                }
 
                const textMap={
                    text:`${text}`,
                    alignment:"center",
                }

                rowArr.push(textMap)
            //})
        }

            tableBodyRows.push(rowArr);

        });

        tableMap["body"] = tableBodyRows

        uiMap["table"]=tableMap
        return uiMap;
    }


    return content;


    function getEqualColumnWidths(itemCount){
        const unitWidth = `${(100/itemCount).toFixed(2)}%`

        const equalColWidths=[]

        for(let x=0;x<itemCount;x++){
            equalColWidths.push(unitWidth)
        }

        console.log(equalColWidths)
        return equalColWidths
    }

    function generateHeaderDetailsCont(selMapObj){
        const labels = schemeMap.headDetailLabels
        const mapObjKeys = schemeMap.headDetailMapObjKeys
        const mapKeys = schemeMap.headDetailMapKeys
        const dataTypes = schemeMap.headDetailDataTypes

        const uiMap = {}
        uiMap["layout"] = "minSpaceRowSection";
        uiMap["fontSize"] = 8;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;

        const tableMap={}
        tableMap["headerRows"]= 0;
        tableMap["widths"]=['7.5%','5%','10%','5%','10%','22.5%','12.5%','10%','10%','7.5%']


        const tableBodyRows = [];

        let textMaps = []
        let rowNo = 1
        let colNo = 1

           for(const [i,label] of labels.entries()){


            const labelTextMap  = {
                text:label.toUpperCase(),
                alignment:"center",
                fillColor:colors.lightGrey,
                bold:true
            }

            const valueTextMap  = {
                text:dataTypes[i] == "date-timestamp" ? getDateStrFromJSDate(selMapObj[mapObjKeys[i]][mapKeys[i]].toDate()) : selMapObj[mapObjKeys[i]][mapKeys[i]],
                alignment:"center",
                bold:true
            }

            textMaps.push(labelTextMap)
            textMaps.push(valueTextMap)

            colNo+=2;

            const tableColLimit = tableMap["widths"].length;


            if(colNo > tableColLimit){// we are at the end of the row
                colNo = 1;
                rowNo++;

                tableBodyRows.push(textMaps)
                textMaps = []
            }else if(i==labels.length-1){// incase the remaining cols are less than the limit

                textMaps.push("")
                textMaps.push("")

                tableBodyRows.push(textMaps)
            }

        }

        console.log(tableBodyRows)

    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap


    }


    function generateHeaderContent(selMapObj){
        const uiMap = {}
        uiMap["layout"] = "sectionHead";
        uiMap["fontSize"] = 14;
        uiMap["font"] = "Roboto";
        uiMap["bold"] = true;
    
        let insitutueName = selMapObj["institute"]["name"];
     
    
        const tableMap={}
        tableMap["headerRows"]=0;
        tableMap["widths"]=['20%','80%']
    
        const tableBodyRows = []
        let rowArr=[];
        let imageMap = {
            image:"app-logo.png",
            width:50,
            alignment:"center",
            rowSpan:2
        }

        let textMap = {
            text:insitutueName.toUpperCase(),
            alignment:"center",
            bold:true
        }
    

        rowArr.push(imageMap);
        rowArr.push(textMap);
    
        tableBodyRows.push(rowArr);
    
        rowArr=[]
        rowArr.push('') // for the image

        const value = schemeMap.headRowTitle

        textMap = {
            text:value.toUpperCase(),
            alignment:"center",
            fontSize:12
        }
        rowArr.push(textMap)

        tableBodyRows.push(rowArr);
    
    
        tableMap["body"] = tableBodyRows
    
        uiMap["table"]=tableMap
        return uiMap
    
    }



}


function getTodaysDate(){
    const date = new Date();
    return `${to2Digits(date.getDate())}-${to2Digits(date.getMonth()+1)}-${date.getFullYear()}`;
  }


  function to2Digits(number){

    if(number<10){
      return "0"+number;
    }else if(number>=10 && number <100){
      return number;
    }
    
    }




function getAffCondValue(value){
    return value==0 ? "Do not agree" : value==1 ? "Agree" : "NA"
}

function getFileValue(value){
    return value==false || value==0 ? "Not Attached" : "Attached"
}

function getInputTable(inpSchemeMap,valueMap,uiType,x){

const endSlNos = [19]
const tableUiTypes = ["standard"]




}


function writeRotatedText(text) {
    var ctx, canvas = document.createElement('canvas');
    // I am using predefined dimensions so either make this part of the arguments or change at will 
    canvas.width = 100;
    canvas.height = 600;
    ctx = canvas.getContext('2d');
    ctx.font = '22pt Arial';
    ctx.save();
    ctx.translate(70,600);
    ctx.rotate(-0.5*Math.PI);
    ctx.fillStyle = '#000';
    ctx.fillText(text , 0, 0);
    ctx.restore();
    return canvas.toDataURL();
  };