import React, { useState } from "react";
import BtnNormal from "./BtnNormal";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordMapStrip from "./RecordMapStrip";


export default function StripListContSinglePage(props) {


  const [uploadStartSlNo,setUploadStartSlNo] = useState(props.pageStripValueDataMap.uploadStartSlNo)
  const [uploadEndSlNo,setUploadEndSlNo] = useState(props.pageStripValueDataMap.uploadEndSlNo)

  console.log(props)
  return (
    <div className={`grid w-full gap-y-4`}>
      {props.pageStripValueDataMap.multipleUploadCond && 
      <div className="flex justify-center gap-4">
            <div>
                <span>Upload Start Sl No</span>
                <input type="number"
                    value={uploadStartSlNo}
                    onChange={e=>setUploadStartSlNo(parseInt(e.target.value))}
                />
            </div>
            <div>
                <span>Upload End Sl No</span>
                <input type="number"
                    value={uploadEndSlNo}
                    onChange={e=>setUploadEndSlNo(parseInt(e.target.value))}
                />
            </div>

            <BtnNormal label="Update" color="primary" clickHandler={()=>props.uploadSlNoChangeHandler({startSlNo:uploadStartSlNo,endSlNo:uploadEndSlNo})}/>
        
      </div>}
    {props.pageStripValueDataMap.pageStripValueMapColl.length != 0 &&
    <div>
      <div className="bg-primary-100">
        <div className={`grid w-11/12 mx-auto mt-1 ${props.schemeMap.stripGridColsSchemeMain} bg-secondary rounded-sm border border-borderStd `}>
        <div className={`text-center border-r`}>
          SL NO
        </div>

        <div
          className={`grid ${props.schemeMap.stripGridColsSchemeBody}`}
        >
          {props.schemeMap.cellLabelNames.map((cellLabelName,i) => {
                if(i!=0){
            return (
              <div className={`text-center ${i<props.schemeMap.cellLabelNames.length-1 ? `border-r` : ``}`}>
                {cellLabelName}
              </div>
            );
          }
          })}
        </div>
        </div>

                {props.pageStripValueDataMap.pageStripValueMapColl.map((stripValueMap,i) => {
            return (
              <RecordMapStrip
                stripValueMap={stripValueMap}
                schemeMap={props.schemeMap}
                autoGenSlNoCond={true}
                slNo={i+1}
                boldTextCond={checkRecordInUploadOperation(props.pageStripValueDataMap.uploadStartSlNo,props.pageStripValueDataMap.uploadEndSlNo,i+1)}
                // stripColor={props.resultMode && checkRecordInUploadOperation(props.pageStripValueDataMap.uploadStartSlNo,props.pageStripValueDataMap.uploadEndSlNo,i+1) ? props.successfullBatchSlNos.includes(i+1) ? "greenTransparent30" : "redTransparent30" : "secondaryTransparent10"}
                stripColor={getStripColor(stripValueMap.uploadStatus)}
                pageLimit={20}
                pageNo={1}
              />
            )})}
      
      </div>
      </div>
      }
      {props.pageStripValueDataMap.pageStripValueMapColl.length == 0 && 
                      <NoAvailableDisplay textName="No Records Found"/>
        }

    
    </div>
  );
}

function checkRecordInUploadOperation(uploadStartSlNo,uploadEndSlNo,recordSlNo){

  return recordSlNo >= uploadStartSlNo && recordSlNo <= uploadEndSlNo

}


function getStripColor(uploadStatus){
  switch(uploadStatus){
    case "inQueue": return "secondaryTransparent10"
    case "successfull" : return "greenTransparent30"
    case "failed" : return "redTransparent30"
    default: return null
  }
}
