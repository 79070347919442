import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  db,
  generateIncrementNumArray,
  limit,
  orderBy
} from "../Utils";
import BottomContainer from "./BottomContainer";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordStrip from "./RecordStrip";

const pageLimit = 5;


function getDbCollPath(props){
  switch(props.passMap.listTopic){
    case "Transaction":{
      return `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
    }
    case "Past Order":{
      return `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
    }
    case "Unpaid Invoice":{
      return `admin/accounts/unpaidInvoices`
    }
    case "Paid Invoice":{
      return `admin/accounts/paidInvoices`
    }
  }
}


export default function StripListFilterCont2(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  console.log(props)

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbKeys);
  console.log(props.passMap);

  const [compListDataMap, setCompListDataMap] = useState({
    valueSeqs: [],
    slNos: [],
  });

  const [compFilListDataMap, setCompFilListDataMap] = useState({
    valueSeqs: [],
  });

  const [pageStripValuesColl,setPageStripValuesColl]=useState([])


  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  const [slNoArray, setSlNoArray] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});
  const [recordCount, setRecordCount] = useState(
    0
  );
  const [pageNo, setPageNo] = useState(0);
  const [viewMode, setViewMode] = useState("normal");
  const [isLoading,setIsLoading] = useState(true)

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    const tMap = {};

      tMap["collQuery"] = query(
        collection(
          db,
          getDbCollPath(props)
        ),
        where("invoicePartyUids","array-contains",props.passMap.storeBranchUid),
        orderBy("docCreationDate", "desc"),
        limit(2) //Limiting 2, because each transaction document will hold 100 transaction values, so 2 docs will obviously fill the page
      );
    

    const dbDocs = await getDocs(tMap["collQuery"]);

    console.log(dbDocs.size)

    if (dbDocs.size != 0) {
      setCompListDataMap(
        extractAndSetViewDataMap(dbDocs, compListDataMap, 1)
      );
    }else{
      setPageStripValuesColl([])
      props.stopSpinner()
      setIsLoading(false)
    }
  }

  function extractAndSetViewDataMap(
    dbDocs,
    compListDataMap,
    pageNo
  ) {
    const tArray1 = [];
    let slNoCount=0;

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringSeqDatas;


      console.log(doc.data());

      //Extraction of data for the strips

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");
        const topicKeyIndex  = props.passMap.valueSeqDbKeys.indexOf("invoicePartyUid")

        if(valueSeqArr[topicKeyIndex]==props.passMap.storeBranchUid){

          slNoCount++;

          const uArr=[]
  
          props.passMap.labelDbKeys.forEach((dbNotation)=>{
    
            const mIndex = props.passMap.valueSeqDbKeys.indexOf(dbNotation)

    

  
            if(dbNotation=="slNo"){ //Checking if key is slNo,as sl No is to be put first and it is not in the database
              uArr.unshift(slNoCount)
            }else{
              if(mIndex!=-1){ //checking if the key is common in both the string value seq and the required db key list
                uArr.push(valueSeqArr[mIndex])
              }
            }
          })

        tArray1.push(uArr);
        }

      });

    });

    compListDataMap.valueSeqs = [...tArray1];

    const sRecordCount = compListDataMap.valueSeqs.length;
    setRecordCount(sRecordCount)

    displayPage(sRecordCount, pageNo, compListDataMap);

    console.log(compListDataMap);
    props.stopSpinner()
    setIsLoading(false)
  
    return compListDataMap;
  }

  console.log(pageNo);



  function displayPage(sRecordCount, sPageNo, compListDataMap) {
    if (sPageNo !== pageNo) {
      setPageNo(sPageNo);
      console.log(sPageNo);

      const latestSl = sRecordCount
     // const slNoArray = compListDataMap.slNos;

      console.log(latestSl);


      const startSlOfPage =
        pageLimit * (sPageNo - 1) + 1 > sRecordCount
          ? sRecordCount
          : pageLimit * (sPageNo - 1) + 1;
      const endSlOfPage =
        pageLimit * sPageNo > sRecordCount ? sRecordCount : pageLimit * sPageNo;

        const rArray=[];

        for (let y = startSlOfPage; y <= endSlOfPage; y++) {
          const stripValueArray = [...compListDataMap.valueSeqs[y-1]];
          //stripValueArray.unshift(y);
          rArray.push(stripValueArray);
        }


        setPageStripValuesColl([...rArray])
        props.stopSpinner()
        setIsLoading(false)
  }
}

  console.log(pageStripValuesColl)


  async function fetchAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    compListDataMap,
    pageNo
  ) {

    //const docName = props.passMap.listTopic=="Transaction"?"storeBranchTransactions":props.passMap.listTopic=="Past Order"?"pastOrders":""

    const collQuery = query(
      collection(
        db,
        getDbCollPath(props)
      ),
      where("recordNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setCompListDataMap(
      extractAndSetViewDataMap(dbDocs, compListDataMap, pageNo)
    );
  }

  function renderPagination(
    sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {

    console.log(sRecordCount);
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    const rArray = [];

    console.log("collMap",sListValuesCollMap);

    // for (let x = 0; x < pageCount; x++) {
    //   const element = (
    //     <button
    //       onClick={() => displayPage(sRecordCount, x + 1, sListValuesCollMap)}
    //       className={`p-cust-3 ${
    //         x + 1 == sPageNo ? `border-page-no-indicator` : ``
    //       }`}
    //     >{`${x + 1}`}</button>
    //   );
    //   rArray.push(element);
    // }

    // return rArray;
    const element = (
      <select value={sPageNo} className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayPage(sRecordCount, e.target.value, sListValuesCollMap)}>
        {generateIncrementNumArray(1,pageCount).map(slNo =>
          <option value={slNo}>{slNo}</option>
        )
        }
      </select>
  );

  return element;
  }

  function renderFilterPagination(sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo) {
    const pageCount = Math.ceil(recordCount / sPageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayFilPage(sRecordCount, x + 1, sListValuesCollMap)}
          className={`p-cust-3 ${
            x + 1 == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  function renderFilPagination(){

  }



  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

 

  async function filterClickFn(e,filterName){
      console.log(e.target.parentElement)
      console.log(filterName)
      setViewMode("filter")

      let filDbArrayName = ""

      console.log("before")

      switch(filterName){
        case "Date":{

        }
        break;
        case "Party ID":{

          filDbArrayName=props.passMap.listTopic=="Transaction"?"transactionPartyIds":"orderCustomerIds"
        }
        break;
        case "Party Name":{
          filDbArrayName=props.passMap.listTopic=="Transaction"?"transactionPartyNames":"orderCustomerNames"
         
        }
        break;
        case "Employee Name":{

        }
        break;
        case "Job Name":{

        }
        break;
      }

      const inputNodes = e.target.parentElement.getElementsByTagName("input");

      const filValue = inputNodes[0].value


    
      const collQuery = query(
        collection(
          db,
          getDbCollPath(props)
        ),
        where(filDbArrayName,"array-contains",filValue),
        orderBy("docCreationDate", "desc") 
      );

      const dbDocs = await getDocs(collQuery);

      console.log(dbDocs.size)

      generateFilterDataMap(dbDocs,filValue,filDbArrayName);


  }

  function generateFilterDataMap(dbDocs,filValue,filDbArrayName){

    const tArray1 = [];

    const map={}

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringSeqDatas

      //Extraction of data for the strips

      let slCount=0;

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");
        const uArr=[]

        const filDbKey = filDbArrayName.substring(0,filDbArrayName.length-1)

        const filKeyIndex = props.passMap.valueSeqDbKeys.indexOf(filDbKey);

        if(valueSeqArr[filKeyIndex]==filValue){ //Do push operation only if the filterValue is available in the seq
          slCount++; //Initialize the Sl No
        props.passMap.labelDbKeys.forEach((dbNotation)=>{
     
          const mIndex = props.passMap.valueSeqDbKeys.indexOf(dbNotation)
          if(dbNotation=="slNo"){ //Checking if key is slNo,as sl No is to be put first and it is not in the database
            uArr.unshift(slCount)
          }else{
            if(mIndex!=-1){ //checking if the key is common in both the string value seq and the required db key list
              uArr.push(valueSeqArr[mIndex])
            }
          }
        })
        tArray1.push(uArr);
      }
      });

    });

    map["valueSeqs"]=[...tArray1];


    console.log(map)
    setCompFilListDataMap({...map})

 
    const iRecordCount = map.valueSeqs.length
    setRecordCount(iRecordCount)
    displayFilPage(iRecordCount,1, map);
  }


  function displayFilPage(sRecordCount,sPageNo,compFilListDataMap){

      setPageNo(sPageNo);
  
      const latestSl = sRecordCount;
  
      const slNoArray = compFilListDataMap.slNos;
  
      const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > sRecordCount
        ? sRecordCount
        : pageLimit * (sPageNo - 1) + 1;
  
    const endSlOfPage =
      pageLimit * sPageNo > sRecordCount ? sRecordCount : pageLimit * sPageNo;
  
      const rArray = [];

  
      for (let y = startSlOfPage; y <= endSlOfPage; y++) {
        console.log(compFilListDataMap)
        console.log(startSlOfPage)
        const stripValueArray = [...compFilListDataMap.valueSeqs[y-1]];
        //stripValueArray.unshift(y);
        rArray.push(stripValueArray);
      }
  
      console.log(rArray);
  
      setPageStripValuesColl(rArray);
  
    
  }
  

  return (
    <React.Fragment>
      {!isLoading &&
    <div className="h-screen grid grid-rows-cust-2c p-cust-topHead">
      {pageStripValuesColl.length != 0 &&
      <div>
      <div className="flex flex-col justify-center items-center p-8">
        <div className="flex flex-row">
          {/* <select onChange={(e) => setFilterName(e.target.value)}>
            {props.passMap.filterSchemeMap.filterNames.map(
              (filterOptionName, i) => {
                return (
                  <option key={i} value={filterOptionName}>
                    {filterOptionName}
                  </option>
                );
              }
            )}
          </select>
          <div>
            <FilterInput
              filterName={filterName}
              filterSchemeMap={filterSchemeMap}
              filterValueMap={filterValueMap}
              inputChangeFn={(data) => inputChangeFn(data)}
              filterClickFn={(data,filterName)=>filterClickFn(data,filterName)}
            />
          </div> */}
          {/* <button
            className="btn-filter mx-1"
            onClick={() =>
              displayPagesBasedOnFilter(filterName, filterValueMap)
            }
          >
            Go
          </button> */}
        </div>
        <div className="flex mt-8">
          {viewMode=="normal" && renderPagination(pageLimit, compListDataMap, recordCount, pageNo)}
          {viewMode=="filter" && renderFilterPagination(pageLimit, compFilListDataMap, recordCount, pageNo)}
        </div>
      </div>
      <div className="bg-primary-100 overflow-y-auto pb-16">
        <div
          className={`title-strip grid w-11/12 mx-auto mt-1 grid-cols-cust-${props.passMap.labelDbKeys.length}a bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
       {
                pageStripValuesColl.map((stripValues) => {
            return (
              <RecordStrip
                stripValues={stripValues}
                labelNames={props.passMap.labelNames}
                passMap2={props.passMap.settings}
              />
            )})
        }
        </div>
        </div>
  }

        {pageStripValuesColl.length == 0 && 
              <div className="pb-16">
                      <NoAvailableDisplay textName="No Records Found"/>
                      </div>
        }

        {/* {stripValuesSeqs.length != 0 &&
          stripValuesSeqs.map((stripValues) => {
            return (
              <RecordStrip
                stripValues={stripValues}
                labelNames={props.passMap.labelNames}
              />
            );
          })} */}

      {/* <div className="flex mx-auto gap-5">
        {viewMode == "normal" && renderPagination(viewMode,recordCount, compListDataMap)}

        {viewMode == "filter" &&
          renderFiltere
          dPagination(recordCount, compFilListDataMap)}
      </div> */}
      <div className="absolute bottom-0 h-24 w-full flex justify-center items-center gap-4">
      {bottomContainer}
      </div>
    </div>
     }
    </React.Fragment>
  );
}




