import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import ImageCont from "./ImageCont";
import RecordMapBanner from "./RecordMapBanner";
import NoAvailableDisplay from "./NoAvailableDisplay";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  orderBy,
  doc,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";

//@start
export default function BannerListCont(props) {
  const userContext = useContext(UserDataContext);
  const userUid = userContext.userUid;

  console.log(props)

  const [bannerValueMapColl, setBannerValueMapColl] = useState([]);
  const [bannerValuesDataMap, setBannerValuesDataMap] = useState({});
  const [isLoading,setIsLoading]=useState(true)

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {

    const collQuery = getDbCollQuery(props)
    const dbDocs = await getDocs(collQuery);

    console.log(dbDocs.size)

    if (!dbDocs.empty) {
      setBannerValuesDataMap(extractAndSetValuesDataMap(dbDocs));
    } else {
      setBannerValuesDataMap({});
      setIsLoading(false)
      props.stopSpinner()
    }
  }

  function extractAndSetValuesDataMap(dbDocs) {
    const tArray = [];
    let rMap = {};
    rMap["bannerValuesMapColl"] = [];
    rMap["slNos"] = [];

    const dbNotations = [...props.passMap.dataDbKeys];

    dbDocs.docs.forEach((doc, i) => {
      const docIds = [];
      const map = {}; //for docid and uid relation

      const tMap = {}; //will extract all the dbNotation values

      dbNotations.forEach((dbNotation) => {
        tMap[dbNotation] = doc.data()[dbNotation];
        tMap["docId"] = doc.ref.id;
      });

      rMap.bannerValuesMapColl.push(tMap);
      rMap.slNos.push(i + 1);
    });

    console.log(rMap)

    setIsLoading(false)
    props.stopSpinner()

    return rMap;
  }

  //console.log(props.passMap.dataMaps);

  
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

//@render
  return (
    <React.Fragment>
      {!isLoading &&
      <div className="grid grid-rows-cust-2c">
        <div>
        {Object.keys(bannerValuesDataMap).length !== 0 &&
          bannerValuesDataMap.bannerValuesMapColl.map((map, index) => {
            return (
              <RecordMapBanner
                key={bannerValuesDataMap.slNos[index]}
                slNo={index + 1}
                labelNames={props.passMap.labelNames}
                labelDbKeys={props.passMap.labelDbKeys}
                btnAlertLevels={props.passMap.btnAlertLevels}
                btnLabels={checkAndReturnBtnLabels(props.passMap, map)}
                fnName={props.passMap.fnName}
                passDataMap={map}
                passDataMap2={props.passMap.settings}
                actionHandler={(data) => props.actionHandler(data)}
              />
            );
          })}
        {Object.keys(bannerValuesDataMap).length == 0 && (
            <NoAvailableDisplay textName="No Records Found"/>
        )}
        </div>
          {bottomContainer}
      </div>
    }
    </React.Fragment>
  );
}

function checkAndReturnBtnLabels(passMap, dataMap) {
  const fnName = passMap.fnName;

  console.log(passMap);
  console.log(dataMap);
  console.log(fnName)

  if (fnName == "Manage Prepaid Scheme") {
    if (dataMap.prepaidSchemeStatus == "hold") {
      console.log([passMap.btnLabels[0], "Release"]);
      return [passMap.btnLabels[0], "Release"];
    } else {
      return passMap.btnLabels;
    }
  } else if (fnName == "Check Current Orders") {
    if (dataMap.orderStatus == "pendingPayment") {
      return passMap.btnLabels;
    } else {
      return [passMap.btnLabels[0]];
    }
  }else {
    return passMap.btnLabels;
  }
}

function getDbCollQuery(props){
  console.log(props.listTopic)

  switch(props.listTopic){
    case "acceptedOrder":{
      const collQuery = query(collection(db,`storeBranches/${props.passMap.storeBranchUid}/currentOrders`),
      where("orderStatus","==","accepted"))
      return collQuery;
    }
    case "currentOrder":{
      const collQuery = query(collection(db,`storeBranches/${props.passMap.storeBranchUid}/currentOrders`),
      where("orderStatus","!=","accepted"))
      return collQuery;
    }

    case "clearCollections":{
      const collQuery = query(collection(db,`storeBranches/${props.passMap.storeBranchUid}/currentOrders`),
      where("orderStatus","==","pendingCollection"))
      return collQuery;
    }
    case "managePpdCardScheme":{
      const collQuery = query(collection(db,`storeBranches/${props.passMap.storeBranchUid}/prepaidSchemes`),
      where("prepaidSchemeId","!=","false"))
      return collQuery;
    }
    case "prepaidCardRequests":{
      const collQuery = query(collection(db,`storeBranches/${props.passMap.storeBranchUid}/prepaidCardRequests`),
      where("prepaidCardSchemeId","!=","false"))
      return collQuery;
    }
  }
}




