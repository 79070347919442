import React, { useEffect, useState } from "react";
import { getRegexPattern } from "../Utils";

export default function InputListWithWritable(props) {
  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value

  const [showWritable,setShowWritable] = useState(getShowWritableCond(props.inputValues[0]))
  const [inpValdOkCond,setInpValdOkCond] = useState(true)
  const [deductedVald,setDeductedVald] = useState(false)

  const regexPattern = getRegexPattern(props.regexDomain);

  console.log(props)

  useEffect(()=>{

    setShowWritable(getShowWritableCond(props.inputValues[0]))

  },[props.inputValues[0]])



  function getShowWritableCond(inp1){
    console.log(inp1)
    const passValues = ["Others"]

    return passValues.includes(inp1)

  }



  function onChangeHandler(e,regexPattern,deductedVald,noOfInputs){

    props.onChangeFn(e)
    console.log(e.target.value)


    let inpVal = e.target.value;

    if(noOfInputs==2){
      inpVal =  `${inpVal[0]}${inpVal[1]}`
    }


    const valdResult = !props.inpMandCond && inpVal === "" ? true : regexPattern.test(inpVal);

    if(!valdResult && !deductedVald){
      setDeductedVald(true)
      props.changeValdCount(-1)
    }

    if(valdResult && deductedVald){
      setDeductedVald(false)
      props.changeValdCount(1)
    }

  
    setInpValdOkCond(valdResult)

  }


  function onChangeFn(e){
    props.onChangeFn(e)
  }


  return (
    <div className="grid grid-cols-cust-2g bg-quaternary-xlight p-3">
      <label>{props.inputLabel}</label>
      <select
        className="p-1 mb-2"
        type={props.inputTypes[0]}
        name={props.inputNames[0]}
        onChange={props.onChangeFn}
        // defaultValue={props.fieldValue}
        value={props.inputValues[0]}
      >
         <option>Please Select</option>
        {props.selectOptions.map((option, i) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div></div>
      {showWritable && 
      <input
          className="pl-2"
          value={props.inputValues[1]}
          type={props.inputTypes[1]}
          name={props.inputNames[1]}
          checked={props.inputValues[1]}
          disabled={!props.editStatus[1]}
          onChange={(e)=>onChangeHandler(e,regexPattern,deductedVald,1)}
      ></input>
      }
    </div>
  );
}
