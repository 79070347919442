import React, { Component } from "react";
import FunctionButton from "./FunctionButton";
import { Navigate, useNavigate } from "react-router-dom";

export default function NotifUnit(props) {

  const statNames=["sample"]

  console.log(props)
  return (
    <div className="relative grid mb-8 py-2 rounded bg-secondary">
      <div className="cont-stat-head-left bg-primary">{}</div>
      {statNames.map((statName)=>{
        return(
          <div className="h-full flex flex-col text-darkBackText text-center px-2">
            {/* <div className="text-6xl">{props.dataMap[statName.toLowerCase()]}</div> */}
            <div>{props.passMap.desc}</div>
        </div>
        )
      })}
</div>
  );
}
