
import React, { Component } from "react";
import FunctionButton from "./FunctionButton";
import { Navigate, useNavigate } from "react-router-dom";
import NotifUnit from "./NotifUnit";

export default function NotifBlock(props) {
  console.log(props)
  return (
    <React.Fragment>
         <div className="h-full flex flex-col px-4 text-darkBackText">
    {props.notifDataMap.map((notifMap,i)=>{
      return( <NotifUnit
        passMap={notifMap}/>)
    })}
    {props.notifDataMap.length==0 &&
      <div className="h-full w-full flex justify-center items-center text-xl text-center">No notifications at the moment</div>
    }
    </div>
    </React.Fragment>

  )
}
