import React from "react";


export default function ActionBtnCont(props) {
  console.log(props);

  return (
    <div className="flex items-center gap-8">

          <button
            className="btn-action-2"
            onClick={() => props.fnHandler("edit")}
          >
            Edit
          </button>
          {/* {props.topic == "Manage Employees" && (
            <button
              className="btn-action-2"
              onClick={() => props.modalCloseHandler()}
            >
              Chat
            </button>
          )} */}
                    {props.selAccMap.isDisabled && 
          <button
            className="btn-action-2 red"
            onClick={() => props.fnHandler("enable")}
          >
            Disabled
          </button>
          }

{!props.selAccMap.isDisabled &&           
        <button
            className="btn-action-2 hover-black"
            onClick={() => props.fnHandler("disable")}
          >
            Disable
          </button>}

          <button
            className="btn-action-2 hover-red"
            onClick={()=>props.fnHandler("delete")}
          >
            Delete
          </button>
          



{/*  
        <div>
          <label for="disabled">Disabled</label>
          <input
            name="disabled"
            type="checkbox"
            checked={props.isDisabled}
            //onChange={(e) => disableClicked(e)}
          />
        </div> */}
    </div>
  );
}

