import { useState } from "react";

import BtnNormal from "./BtnNormal";
import ModalBoxOpConfPrompt3 from "./ModalBoxOpConfPrompt3";
import { generateBlankInpValdMap, getRegexPattern } from "./projectFns";
import { checkAndRenderInputElements3 } from "./viewFns";

export default function InputWithControllerCont(props){

    console.log(props);

    const [showSpinner, setShowSpinner] = useState(false);
    const [showPromptConf, setShowPromptConf] = useState(false);
    const [promptMap, setPromptMap] = useState({});
    const [valdCount, setValdCount] = useState(0);
    const [inpValMap,setInpValMap] = useState(props.initInpValMap)
    const [inpValdMap,setInpValdMap] = useState(generateBlankInpValdMap(props.inpSchemeMap))


    const [showPhotoViewer,setShowPhotoViewer] = useState(false)

    const [openFileMap,setOpenFileMap] = useState({})
    const [showModalBoxFileViewer,setShowModalBoxFileViewer] = useState(false)
  
    console.log(inpValdMap)
  


  
  
    function inpChangeFn(data,inpValMap,inpValdMap,inpSchemeMap){

      console.log(data,inpSchemeMap,inpValMap,inpValdMap)

      const e = data.event;
      const inpUiIndex = data.inpUiIndex;
  

      const inpKey = inpSchemeMap.inpKeys[inpUiIndex]
      const inpType = inpSchemeMap.inpTypes[inpUiIndex]
      const inpUiType = inpSchemeMap.inpUiTypes[inpUiIndex];
      const inpRegexDomain = inpSchemeMap.inpRegexDomains[inpUiIndex];

      const arrayUiTypes = ["db-multi-checkbox"]

      let rInputValue = ""

  
      const tValMap = {...inpValMap}
      const tValdMap = {...inpValdMap}
      
      if(arrayUiTypes.includes(inpUiType)){

        rInputValue = data.checkboxSelectedValues;

        if(inpType=="checkbox"){
          const checkedCond = e.target.checked;
          const checkboxValue = data.checkboxValue

          if(checkedCond){
   
            if(!rInputValue.includes(checkboxValue)){
              rInputValue.push(checkboxValue)
            }
          }else{
            if(rInputValue.includes(checkboxValue)){
              const checkboxValueIndex = rInputValue.indexOf(checkboxValue)
              rInputValue.splice(checkboxValueIndex,1)
            }
          }
         
        }

        tValdMap[inpKey] = rInputValue.length>0;
      }else{
        rInputValue = inpType == "file" ? e.target.files[0] : inpType == "number" ? parseInt(e.target.value) : e.target.value;

        const regexPattern = getRegexPattern(inpRegexDomain)
        tValdMap[inpKey] = Array.isArray(rInputValue) ? regexPattern.test(rInputValue.join("")) : regexPattern.test(rInputValue)
      }
      
      
      tValMap[inpKey] = rInputValue

      // if(inpKey=="studentGender" || inpKey=="studentBloodGroup"){
      //   alert(inpRegexDomain)
      // }
  
  
      console.log(tValMap)
        
      console.log(tValdMap)
  
      console.log(checkIfAllInputsOk(tValMap,tValdMap,inpSchemeMap))
  
  
      setInpValMap(tValMap)
      setInpValdMap(tValdMap)
  }
  
  
  async function inpElemOutputFns(fnName,data){
    
    switch(fnName){
      case "viewFile":{
  
  }
  
    }
  }
  
  
  function checkIfAllInputsOk(inpValMap,inpValdMap,inpSchemeMap){

    console.log(inpValMap,inpValdMap,inpSchemeMap)
  
    //Step 1:Checking if all inputs are valid
    let inpValdCheckCond = inpValdMapPassCheck(inpValdMap)

  
    function inpValdMapPassCheck(inpValdMap){
      return !Object.values(inpValdMap).includes(false);
    }
  

    if(!inpValdCheckCond){
      return false;
    }
  
  
    //Step 2:Checking if all mandatory inputs are inputted, inpMandKeyCheckCond value determines that
    let inpMandKeyCheckCond = inpMandKeyPassCheck(inpValMap,inpSchemeMap)

    function inpMandKeyPassCheck(inpValMap,inpSchemeMap){
      console.log(inpValMap,inpSchemeMap)
      let checkCond  = true;
  
      inpSchemeMap.inpMandKeys.every((key,i)=>{
        if(!inpValMap[key]){
          checkCond = false;
          return false;
        }
        return true;
      });
  
      return checkCond;
    }
  
    if(!inpMandKeyCheckCond){
      return false;
    };
  
  
    return true;
  }
  
  

  const inpArrangementClass = (function(){
        switch(props.inpElemArrangmentScheme){
          case "two-column": return "grid grid-cols-2"
          case "three-column": return "grid grid-cols-3"
          case "horizontal": return "flex"
          case "vertical":
          default : return "flex flex-col"

        }
  })()
  
  
    return (
      <div className={`grid justify-center h-full gap-4`}>
          <div className={`h-full w-full`}>
              <div className="justify-center">
                <div className={`h-full ${inpArrangementClass} gap-2`}>
                {checkAndRenderInputElements3(props.inpSchemeMap,inpValMap,inpChangeFn,inpValdMap,inpElemOutputFns)}
                </div>
              </div>
           </div>
  
        {/* {showPhotoViewer && <InputImgViewer img={inpImg} closeClickHandler={()=>setShowPhotoViewer(false)}/>} */}
        {/* {showModalBoxFileViewer && 
                  <ModalBoxFileViewer3
                      fileMap={openFileMap}
                      closeClickHandler={()=>setShowModalBoxFileViewer(false)}
                  />
        } */}
{/*   
        {showSpinner && <LoadSpinner />} */}
            <div className=" flex gap-4 justify-center items-center">

              {checkIfAllInputsOk(inpValMap,inpValdMap,props.inpSchemeMap) &&
                <BtnNormal
                label="Submit"
                color="primary"
                clickHandler={()=>props.submitClickHandler({inpValMap:inpValMap,inpSchemeMap:props.inpSchemeMap})}
                />
              }
              {/* {props.containerType=="modal" &&  <BtnNormal spClass="font-bold" label="X" svgColorClass="f-color-white" clickHandler={()=>props.exitHandler()} color="red" toolTipLabel="Close Window" toolTipLabelPostion="bottom"/>}
  
              {props.containerType != "modal" &&  <BtnNormal spClass="font-bold" img={backImg} imgWidth={getImgWidth(20,screenWidth)} svgColorClass="f-color-white" clickHandler={()=>props.exitHandler()} color="black"/>} */}
            </div>
      {showPromptConf && <ModalBoxOpConfPrompt3 passMap={promptMap} promptClose={()=>setShowPromptConf(false)}/>}
      </div>
    );
}