import React, { Component } from "react";

export default function Switch(props) {
  return (
    <div className="flex justify-center items-center bg-primary-300">
      <input
        key={props.rowIndex}
        className="pl-2"
        //value={props.inputType == "file" ? undefined : props.inputValue}
        type={props.inputType}
        name={props.inputName}
        checked={props.inputValue}
        onChange={props.onChangeFn}
      ></input>
    </div>
  );
}
