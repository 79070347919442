import React, { useEffect, useState } from "react";
import { addIcon } from "./Assets";
import BtnNormalSelAction from "./BtnNormalSelAction";
import ButtonIconTitle from "./ButtonIconTitle";
import { getBtnImgSize } from "./HelperViewFns";
import LoadSpinner from "./LoadSpinner";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxWithInputNew from "./ModalBoxWithInputNew";
import { convertMapObjToArray, getRbaseValueByPath, getRealisedRbasePath, getSelectionOpacityStyleClass, updateRbaseOnPath } from "./projectFns";

export default function ClassAndSectionSubCont(props){

    console.log(props)

    const navFlowCond = false;

    const [classNames,setClassNames] = useState([]);
    const [navTreeMaps,setNavTreeMaps] = useState([]);
    const [navTreePosIndex, setNavTreePosIndex] = useState(0);
    const [dbJsonNodeValMap,setDbJsonNodeValMap] = useState({...props.navTreeNodeValMap});

    const [selUnitMap,setSelUnitMap]=useState({});
    
    const [dynamicBtnLabels,setDynamicBtnLabels] = useState([])

    const [showModalInput,setShowModalInput] = useState(false)
    const [showPrompt,setShowPrompt] = useState(false)
    const [promptMap,setPromptMap] = useState({})
    const [showSpinner,setShowSpinner]  = useState(false)

    const [sectionsByClassMapObj,setSectionsByClassMapObj] = useState([])
    const [showActionBtnCont,setShowActionBtnCont] = useState(false)
  
    useEffect(()=>{
      (async () => {
        try {
  
          const rbasePathString = props.schemeMap.selectionRbaseDataPath[navTreePosIndex]

          const classMapObj = await getRbaseValueByPath(getRealisedRbasePath(rbasePathString,dbJsonNodeValMap))

          const tClassNames = classMapObj ? convertMapObjToArray(classMapObj) : []
          console.log(tClassNames)

          const navTreeMap = {
            category:"Class",
            parentUid:"NA",
            selections:tClassNames,
            dbMapObj:classMapObj
          }

          const tNavTreeMaps = []
          tNavTreeMaps.push(navTreeMap)
          setNavTreeMaps(tNavTreeMaps)
  
        } catch (err) {
          console.log(err)
          console.log('Error Initializing Page Data');
        }
      })();
    
    },[props.inpValMap]);

    function updateSelections(maps,action){
        console.log(maps)
              
        return maps.map((it,i)=>{
      
          console.log(it,i,action)
          // it["isSelected"] = action=="default" ? maps[i].isSelected : false; 
          it["isSelected"] = false; 
      
          // if(action=="default" && maps[i].isSelected){
          //   console.log(it)
          //   setSelUnitMap(it)
          // }
          return it
        });
      }

    async function updateView(navTreeMaps,navTreePosIndex,dbJsonNodeValMap){
        const tNavTreeMaps = JSON.parse(JSON.stringify(navTreeMaps))
          
        const rbasePathString = props.schemeMap.selectionRbaseDataPath[navTreePosIndex]

        const categoryDbMapObj = await getRbaseValueByPath(getRealisedRbasePath(rbasePathString,dbJsonNodeValMap))

        const selections = categoryDbMapObj ? convertMapObjToArray(categoryDbMapObj) : []
        console.log(selections)

        const navTreeMap = tNavTreeMaps[navTreePosIndex];

        navTreeMap.selections = selections
        navTreeMap.dbMapObj = categoryDbMapObj


        tNavTreeMaps.splice(navTreePosIndex,1,navTreeMap);
        setNavTreeMaps(tNavTreeMaps)
    }


    async function updateNavTreeMaps(newPosIndex,navTreeMaps,selMap,action,navTreeNodeIds,navTreeNodeValMap){

        console.log(selMap)
        console.log(newPosIndex,navTreeMaps)
    
        const tNavTreeMaps = JSON.parse(JSON.stringify(navTreeMaps))
        const selUid = selMap.uid;
       
    
        if(navTreeMaps.length-1 > newPosIndex){//it means back is pressed
    
          tNavTreeMaps.pop()
          const curNavTreeMap = tNavTreeMaps[newPosIndex]
    
          const selectionMaps =  curNavTreeMap.selections;
          curNavTreeMap["selections"] = updateSelections(selectionMaps,"default");
          tNavTreeMaps.pop()
          tNavTreeMaps.push(curNavTreeMap)
          // }
    
        }else if(navTreeMaps.length-1 == newPosIndex){//this is to stay in the same heirarchy level and perform changes in the present list of accounts - like select, disable, enable
            

          const curNavTreeMap = tNavTreeMaps[navTreeMaps.length-1]
          console.log(navTreeMaps,curNavTreeMap)
          //const mapOfMaps =  parentUid=="NA" ? props.listenMaps[mapKeys[newPosIndex]] : convertSelectionsToMapOfMaps(navTreeMaps[newPosIndex].selections);
        //   const dbMapObj =  navTreeMaps[newPosIndex].dbMapObj
    
          const updatedSelections = updateSelectionsOnSelect(curNavTreeMap["selections"],selMap.name,action)

          console.log(updatedSelections)
    
          curNavTreeMap["selections"] = [...updatedSelections]
    
          tNavTreeMaps.pop()
          tNavTreeMaps.push(curNavTreeMap)
    
        }
        // else if(newPosIndex > navTreeMaps.length-1){//going deeper the heirerarchy - enter
        //   setShowActionBtnCont(false)
    
        //   const seqUnitMap = {}
        //   seqUnitMap["category"] = categories[newPosIndex];
        //   seqUnitMap["parentUid"] = selUid;
    
        //   const mapOfMapsKey = mapKeys[newPosIndex];
        //   const selectionFetchSource = selectionFetchSources[newPosIndex]
    
        //   let mapOfMaps = {}
    
        //   //adding the next navTreeMap based on the selection
        //   switch(selectionFetchSource){
        //     case "rBase":{
        //       if(mapOfMapsKey.split("_")[0] == "fetchFromRbase"){
        //         const rbaseNodeName = mapOfMapsKey.split("_")[1];
        //         const rbasePathStr = props.schemeMap.selectionRbaseDataPath[newPosIndex]
        //         //const rbasePath = getRbasePathForAccountSelection(rbaseNodeName,navTreeNodeIds);
        //         const rbasePath = getRbasePathForAccountSelection(rbasePathStr,navTreeNodeValMap);
        
        //         console.log(rbasePath);
        
        //         // function getRbasePathForAccountSelection(rbaseNodeName,navTreeNodeIds){
        //         //   console.log(navTreeNodeIds)
        //         //   const path = `${rbaseNodeName}/${navTreeNodeIds.join("/")}`
        //         //   return path;
        //         // }
        
        //         function getRbasePathForAccountSelection(rbasePathStr,navTreeNodeValMap){
        //           console.log(navTreeNodeIds)
        //           const path = rbasePathStr.split("/").map((pathComp)=>{
        //             if(pathComp[0]=="$"){
        //               const keyComp = pathComp.slice(1);
        //               const actualPath = navTreeNodeValMap[keyComp]
        //               return actualPath
        //             }else{
        //               return pathComp
        //             }
        //           }).join("/")
        //           // const path = `${rbaseNodeName}/${navTreeNodeIds.join("/")}`
        //           return path;
        //         }
        
        //         const mapOfMapsRbaseRef = rbaseRef(
        //           database
        //         );
        
        //         try{
        //         const mapOfMapsRbaseCall = await get(child(mapOfMapsRbaseRef,rbasePath));
        //               mapOfMaps = mapOfMapsRbaseCall.val()
        //               console.log(mapOfMaps)
        //         }catch(error){
        //           console.log("rbase fetch error")
        //         }
        //       }
        //     }
        //     break;
        //     case "memory":{
    
        //       mapOfMaps = {
        //         "General Info":{
        //           accountName:"General Info",
        //           isDisabled:false
        //         },
        //         "Class & Section":{
        //           accountName:"Class & Section",
        //           isDisabled:false
        //         }
        //       }
    
        //     }
        //   }
    
    
    
        //   // mapOfMaps = selUid=="NA" ? props.listenMaps[mapKeys[newPosIndex]] : props.listenMaps[mapKeys[newPosIndex]][selUid];
        //   console.log(selUid)
        //   console.log(mapOfMaps)
        //   seqUnitMap["mapOfMaps"] = mapOfMaps
        //   seqUnitMap["selections"] = generateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex);
        //   tNavTreeMaps.push(seqUnitMap)
        // }
    
        console.log(tNavTreeMaps)
    
        setNavTreePosIndex(newPosIndex)
        setNavTreeMaps(tNavTreeMaps);
      }


    function updateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex,selUid,action){
        console.log(mapOfMaps,newPosIndex,selUid,action)
      
        const tColl = [];
              
        Object.keys(mapOfMaps).forEach((nodeValue,i)=>{
          const tMap = {}
        //   tMap["uid"] = key;
          tMap["name"] = nodeValue
        //   tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
          tMap["accountName"] = mapOfMaps[nodeValue].accountName;
          tMap["isSelected"] = selUid==nodeValue ? true : action=="default" ? mapOfMaps[nodeValue].isSelected : false; 
          tMap["isDisabled"] = (action=="enable" || action=="disable") && selUid==nodeValue ? action=="disable" : mapOfMaps[nodeValue].isDisabled;
         // tMap["category"] = categories[newPosIndex];
      
          if(selUid==nodeValue){
            setSelUnitMap(tMap)
          }
      
          if(action=="default" && mapOfMaps[nodeValue].isSelected){
            console.log(tMap)
            setSelUnitMap(tMap)
          }
      
          tColl.push(tMap);
        });
      
        console.log(tColl)
        return tColl;
      }


    function updateSelectionsOnSelect(selections,selNodeVal,action){

        return selections.map((selection,i)=>{
    
            if(selection.name==selNodeVal){
                selection["isSelected"] = true;
            }else{
                selection["isSelected"] = false;
            }

            return selection;
        })
        
    }

    async function functions(fnName,data){

        const tNavTreeMaps = "navTreeMaps" in data ? [...data.navTreeMaps] : []
        const navTreePosIndex = "navTreePosIndex" in data ? data.navTreePosIndex : -1;
        const navTreeMap =  tNavTreeMaps.length > 0 && navTreePosIndex != -1 ? tNavTreeMaps[navTreePosIndex] : {}
        const tDbJsonNodeValMap = "dbJsonNodeValMap" in data ? {...data.dbJsonNodeValMap} : {}

        switch(fnName){
            case "classClick":{
                setShowActionBtnCont(true);
            }
            break;
            case "manageSections":{


            }
            break;
            case "add":{
                setShowModalInput(true)
            }
            break;
            case "back":{
                setNavTreePosIndex(navTreePosIndex-1)
            }
            break;
            case "enter":{
                console.log(data)
                tDbJsonNodeValMap[props.schemeMap.navTreeNodeKeys[navTreePosIndex]] = data.selMap[props.schemeMap.navTreeNodeDbGenKeys[navTreePosIndex]]

                const newNavTreePosIndex = navTreePosIndex + 1
                const rbasePathString = props.schemeMap.selectionRbaseDataPath[newNavTreePosIndex]


                console.log(rbasePathString,dbJsonNodeValMap)
                const dbMapObj = await getRbaseValueByPath(getRealisedRbasePath(rbasePathString,tDbJsonNodeValMap))

                console.log(dbMapObj)

                const tSelections = dbMapObj ? convertMapObjToArray(dbMapObj) : []
                console.log(tSelections)
      
                const navTreeMap = {
                  category:"Section",
                  parentUid:"NA",
                  parentNodeValue:"", //check later
                  selections:tSelections
                }
                
            
                tNavTreeMaps.push(navTreeMap)
                setNavTreePosIndex(newNavTreePosIndex)
                setNavTreeMaps(tNavTreeMaps)
                setDbJsonNodeValMap(tDbJsonNodeValMap)
            }
            break;
            case "select":{
                 
                setSelUnitMap(data.selMap);
                setShowActionBtnCont(true);
         
                updateNavTreeMaps(navTreePosIndex,tNavTreeMaps,data.selMap,"select")
            }
            break;
            case "selectActionClick":{
                switch(data.btnLabel){
                    case "Manage Sections":{

                        if(checkForSaveProgress(navTreeMaps[navTreePosIndex])){

                            displayPrompt("Save Progress","There are changes pending to be saved, please save changes before proceeding")
                        }else{

                            functions("enter",data)
                        }

                    }
                }
            }
            break;
            case "addInpSubmit":{
                console.log(data)

                setShowModalInput(false)
                const category = navTreeMap.category;

                const name = category=="Class" ? data.data.inpValMap.className :  data.data.inpValMap.sectionName 

                const newSelMap = {
                    name:name,
                    isAdded:true,
                    isSelected:false
                }

                function checkForUniqueValuesInSelections(selections,submitValue){
                    
                    for(const selection of selections){

                        if(selection.name==submitValue){
                            return false
                        }
                    }

                    return true
                }

                if(checkForUniqueValuesInSelections(navTreeMap["selections"],newSelMap.name)){
                    navTreeMap["selections"].push(newSelMap)
                
                    tNavTreeMaps.splice(navTreePosIndex,1,navTreeMap)
                 
                    setNavTreeMaps(tNavTreeMaps)
                }else{
                        displayPrompt("Duplicate Input","Please input values other than the ones already existing")
                }



            }
            break;
            case "saveProgress":{
           
                console.log(data)

                const category = navTreeMap.category;

                const dbInsertMapObj = {}
                let firestoreInsertMapObj={}

                navTreeMap.selections.forEach((navTreeMap)=>{
                    navTreeMap.isAdded = true;

                    const dbInsertMap = {
                        name:navTreeMap.name,
                        isDisabled:false
                    }

                    dbInsertMapObj[navTreeMap.name]=dbInsertMap

                    //Custom for Firestore

                        // if(category=="Class"){
                        //     if(!("classNames" in firestoreInsertMapObj)){
                        //         firestoreInsertMapObj["classNames"] = []
                        //     }

                        //     firestoreInsertMapObj["classNames"].push(navTreeMap.name);
                            
                        // }else if(category=="Section"){
                        //     if(!("sectionNames" in firestoreInsertMapObj)){
                        //         firestoreInsertMapObj["sectionNames"] = []
                        //     }
                        //     firestoreInsertMapObj["sectionNames"].push(navTreeMap.name);
                        // }
                });


                const rbasePathString = props.schemeMap.selectionRbaseDataPath[navTreePosIndex]
                const rBasePath = getRealisedRbasePath(rbasePathString,tDbJsonNodeValMap)

                // const firestoreCollPath = `institutes`
                // const firestoreDocPath = `${tDbJsonNodeValMap["instituteUid"]}`

                setShowSpinner(true)
                const opRbase = await updateRbaseOnPath(rBasePath,dbInsertMapObj);

                if(opRbase.status){
                    setShowSpinner(false)
                    updateView(tNavTreeMaps,navTreePosIndex,tDbJsonNodeValMap)
                }

               
                // const opFirestore = await updateFirestoreOnPath(firestoreCollPath,firestoreDocPath,dbInsertMapObj)

                
            }
        }
    }

    function displayPrompt(title,message,status){

        setShowPrompt(true);
        setPromptMap({
          status: status ? status : false,
          title:title,
          message:message
        })
      }

    return(
    <div className="relative grid grid-rows-cust-2o justify-center gap-8 border border-secondaryTransparent80 rounded m-4 p-4">
        <div className="flex items-center justify-center gap-8">
            {navTreeMaps.length>0 && navTreeMaps[navTreePosIndex].selections.map((map)=>
                    <ButtonIconTitle
                        key={map.accountName}
                        isDisabled={map.isDisabled}
                        btnColorClass={getSelectionOpacityStyleClass(
                        map.isDisabled,
                        map.isSelected,
                        map.isAdded
                        )}
                        section="Selection"
                        btnColor={getSelectionBtnColor(map)}
                        btnTitle={map.name}
                        btnImgSize={getBtnImgSize("Dashboard Function")}
                        btnImg={props.schemeMap.selectionImgs[navTreePosIndex]}
                        //clickHandler={(e) => selectionFn(e, map, i)
                        clickHandler={(e)=>functions("select",{navTreeMaps:navTreeMaps,navTreePosIndex:navTreePosIndex,selMap:map})}
                    ></ButtonIconTitle>
            )}
            {navTreeMaps.length>0 &&
                <ButtonIconTitle 
                    section="Selection" 
                    btnImgSize={getBtnImgSize("Dashboard Function")} 
                    btnTitle={`Add ${navTreeMaps[navTreePosIndex]["category"]}`}  
                    btnImg={addIcon}
                    clickHandler={(e)=>functions("add",{navTreeMaps:navTreeMaps,navTreePosIndex:navTreePosIndex})}
                />
            }
            </div>
            <div className="absolute top-5 right-5 flex items-center gap-4">
                {showActionBtnCont && 
                    <React.Fragment>
                            {props.schemeMap.selectionFnBtnLabels[navTreePosIndex].map((btnLabel,i)=>
                                            <BtnNormalSelAction spClass=" text-textDarkBackText text-sm" color={props.schemeMap.selectionFnBtnColors[navTreePosIndex][i]} label={btnLabel} clickHandler={() => functions("selectActionClick",{
                                                btnLabel:btnLabel,
                                                selMap:selUnitMap,
                                                navTreeMaps:navTreeMaps,
                                                navTreePosIndex:navTreePosIndex,
                                                dbJsonNodeValMap:dbJsonNodeValMap})}/>
                            )}
                    </React.Fragment>
                }
                {navTreePosIndex > 0 &&  <BtnNormalSelAction spClass="border  text-darkBackText text-sm" color="blackPale" label="Back" clickHandler={() => functions("back",{btnLabel:"Back",
                                        selMap:selUnitMap,
                                        navTreeMaps:navTreeMaps,
                                        navTreePosIndex:navTreePosIndex,
                                        dbJsonNodeValMap:dbJsonNodeValMap})}/>
                   
                }
                {checkForSaveProgress(navTreeMaps[navTreePosIndex]) &&
                                    <BtnNormalSelAction spClass="border  text-darkBackText text-sm" color="green" label="Save" clickHandler={() => functions("saveProgress",{btnLabel:"Save",
                                        selMap:selUnitMap,
                                        navTreeMaps:navTreeMaps,
                                        navTreePosIndex:navTreePosIndex,
                                        dbJsonNodeValMap:dbJsonNodeValMap})}/>
                }
            </div>
            {navTreePosIndex > 0 && 
            <div className="absolute top-5 left-5 text-5xl opacity-50 text-secondaryDark">{dbJsonNodeValMap[props.schemeMap.navTreeNodeKeys[navTreePosIndex-1]]}</div>}

        {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
        {showModalInput && <ModalBoxWithInputNew inpSchemeMap={props.schemeMap.addInpSchemeMapObj[navTreeMaps[navTreePosIndex]["category"]]} passMap={{title:navTreeMaps[navTreePosIndex]["category"]}} submitClickHandler={(data)=>functions("addInpSubmit",{data,navTreeMaps:navTreeMaps,navTreePosIndex:navTreePosIndex,dynamicBtnLabels:dynamicBtnLabels})} closeClickHandler={()=>setShowModalInput(false)}/>} 
        {showSpinner && <LoadSpinner/>}
            
    </div>
    )
}


function getUnitClickCourseAction(intermediateSelectCond,navTreePosIndex,navTreeLength){

    if(intermediateSelectCond){
          return "select"
    }else{
           return "enter"
    }
  
  }


  function getSelectionBtnColor(selMap){

    console.log(selMap)

    if(selMap.isAdded){
         return selMap.isSelected ? "greenDark" : "green"
    }else{
          return selMap.isSelected ? "primary" : "primaryPale"
    }

  }


  function checkForSaveProgress(navTreeMap){

    if(navTreeMap){

        for(const selMap of navTreeMap.selections){

            if(selMap.isAdded){
                return true
            }

        }
        return false;
    }
    return false
  }