import React from "react";
import BottomContainer from "./BottomContainer";
import SelectionBanner from "./SelectionBanner";


//const db=getFirestore(firebase);

//function to create an empty field object with keys of the templateObj passed to it
function createInitialObj(templateObj) {
  const obj = {};

  templateObj[templateObj.mainKey].forEach((field) => {
    obj[field] = "";
  });

  return obj;
}

export default function ListCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  console.log(props);
  // console.log(props.passMap.labelNames);
  // console.log(props.passMap.labelDbNotations);
  // console.log(props.passMap);
  // console.log(props.passListDataArray);

  return (
    <React.Fragment>
      <div className="grid">
      {props.passList.length > 0 && 
        <div className="flex flex-row overflow-scroll">
        {props.passList.map((dataMap, index) => {
          return (
            <SelectionBanner
              key={index}
              slNo={index + 1}
              dataMap={dataMap}
              schemeMap={props.schemeMap}
              // labelNames={props.passMap.labelNames}
              // labelDbNotations={props.passMap.labelDbNotations}
              // fnName={props.passMap.fnName}
              // passDataMap={dataMap}
              // passDataMap2={props.passMap.settings}
              clickHandler={(data) => props.parentInterfaceFnHandler(data,"card")}
            />
          );
        })}
        </div>}
        {props.passList.length == 0 && (
          <div className="h-48">
            No Records Found
         </div>
        )}

        <div className="p-8">
        <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
        </div>
      </div>

    </React.Fragment>
  );
}

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}
