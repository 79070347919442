import { React, useState } from "react";
import { getRegexPattern } from "../Utils";
import { getInputElement } from "./HelperViewFns";

export default function Input(props) {

  console.log(props)

  const [inpValdOkCond,setInpValdOkCond] = useState(true)
  const [deductedVald,setDeductedVald] = useState(false)


  const regexPattern = getRegexPattern(props.regexDomain);


  function onChangeHandler(e,inpKey,deductedVald){

    props.onChangeFn(e,inpKey)
    console.log(e.target.value)

    const inpVal = e.target.value.toString();
    const inpMandCond = "inpMandCond" in props ? props.inpMandCond : false;

    console.log(regexPattern)

    const valdResult = !inpMandCond && inpVal === "" ? true : regexPattern.test(inpVal);

    if(!valdResult && !deductedVald){
      setDeductedVald(true)
      props.changeValdCount(-1)
    }

    if(valdResult && deductedVald){
      setDeductedVald(false)
      props.changeValdCount(1)
    }
    
    setInpValdOkCond(valdResult)
  }



  function getStyleClass(inpValdOkCond){

    return inpValdOkCond ? `` : `bg-secondary-light-transparent`
  }


  return (
    <div className={``}>
      {getInputElement(props,onChangeHandler,deductedVald,getStyleClass(inpValdOkCond))}
    </div>
  );
}
