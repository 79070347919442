import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserDataContext } from "./Level1";

export default function ButtonModalOp(props) {

  return (
    <button className={`btn-action-sm btn-alert-${props.alertLevel}`} onClick={() => props.clickHandler() }>
      {props.label}
    </button> 
  );
}
