import React, { useState } from "react";

import { styleProxyMap } from "../Utils";

export default function BtnNormal(props) {

  console.log(props)

  const [showToolTip, setShowToolTip] = useState(false)

  const textColor = !props.textColor ? `text-darkBackText`: `${styleProxyMap.color[props.textColor]}`;
  const imgLabelCond = props.img && props.label;

  return (
    <div onMouseOver={()=>{if(props.toolTipLabel){setShowToolTip(true)}}} onMouseOut={()=>setShowToolTip(false)} className={`btn-hover ${imgLabelCond ? `grid grid-cols-cust-2j` : ``} cursor-pointer ${textColor} py-1 mb-1 px-6 rounded ${props.spClass} 
    bg-${props.color}`
    } 
    onClick={props.clickHandler}>
      <div className="relative">
        <div className={`flex items-center justify-center ${imgLabelCond ? `mr-2` : ``}`}><img className={props.svgColorClass} src={props.img} width={props.imgWidth} ></img></div>
        {props.label && <div className="none flex items-center justify-center font-bold text-center whitespace-nowrap">{props.label}</div>}
        {showToolTip && 
            <div className="absolute left-full bottom-full m-1 bg-golden rounded p-1 text-darkBackText text-sm text-nowrap font-semiBold">{props.toolTipLabel}</div>
        }
      </div>
   </div>
  );
}