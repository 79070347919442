import {
  collection,
  doc
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import {
  db,
  functions,
  getBlob,
  httpsCallable,
  ref,
  serverTimestamp,
  setDoc,
  storage,
  uploadBytes
} from "../Utils";
import ImageCont from "./ImageCont";
import InputRowBlock from "./InputRowBlock";
import { UserDataContext } from "./Level1";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";

//const db=getFirestore(firebase);

//function to create an empty field object with keys of the templateObj passed to it
function createInitialObj(templateObj) {
  const obj = {};

  templateObj[templateObj.mainKey].forEach((field) => {
    obj[field] = "";
  });

  return obj;
}

function getInputValue(inputNode,inputType) {



  switch (inputType) {
    case "file":
      return inputNode.files[0];

    case "checkbox":
      return inputNode.checked;
 
    case "number":
      return parseInt(inputNode.value);
    case "number-dec":
    return parseFloat(inputNode.value);
  
    default:
      return inputNode.value;
  }
}

export default function CategoryInputCont(props) {
  const userContext = useContext(UserDataContext); //Main User Data of the Current User Broadcasted from Level 1

  console.log(userContext);

  const passDataObj = { ...props.passMap }; // Will contain data on input field names which will populate the input form

  console.log(passDataObj);
  const userUid = userContext.uid;

  const inputTemplateObj =
    props.passMap.opType == "edit"
      ? { ...props.passMap.dataMap }
      : createInitialObj(passDataObj); //Template Object of the 'Input Data Object'

  const [inputDataObj, setInputDataObj] = useState(inputTemplateObj); //Assigning the 'Input Data Object' as initial value for 'Input Object - State'
  const [inputImg, setInputImg] = useState("");

  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});


  useEffect(() => {
    (async () => {
      passDataObj.imgRequired
        ? setInputImg(
            URL.createObjectURL(await getFile(passDataObj.dataMap.logoLocation))
          )
        : setInputImg("");
    })();
  }, []);

  useEffect(() => {
    if (props.submitClicked) {
      console.log("Submit Clicked and Executed");
      createDbRecord();
    }
  }, [props.submitClicked]);

  async function getFile(fileLocation) {
    const locationRef = ref(storage, fileLocation);
    const file = await getBlob(locationRef);
    return file;
  }

  function displayPrompt(message,title,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ?? 'Info',
      message:message
    })
  }

  async function createDbRecord() {
    const type = passDataObj.operationType; //Capturing the Operation Type ex - Doc Addition, Account Creation
    let dbWriteOk = false;

    if (type == "Doc Addition") {
      console.log("Started Creation");

      const functionName =
        passDataObj.inputDomain == "Prepaid Card" ? "addPrepaidScheme" : "";

      const tInputDataObj = { ...inputDataObj };

      tInputDataObj.prepaidSchemeValidityStart = Date.parse(
        inputDataObj.prepaidSchemeValidityStart
      ); //converting to milliseconds so that it is suitable to store as a Timestamp

      const payload = {
        ...tInputDataObj,
        creatorRoleId: userContext.userRoleId,
        creatorUid: userContext.userUid,
        creatorName: userContext.userName,
      };

      if (functionName == "addPrepaidScheme") {
        const storeBrandInfoMap = {
          prepaidSchemeStoreBrandId: passDataObj.storeBrandId,
          prepaidSchemeStoreBrandName: passDataObj.storeBrandName,
          prepaidSchemeStoreBrandUid: passDataObj.storeBrandUid,
        };
        Object.assign(payload, storeBrandInfoMap);
      }

      const addDocServerCall = httpsCallable(functions, functionName);
      const result = await addDocServerCall(payload);

      displayPrompt(result.data);

      const op = await setDoc(doc(collection(db, `${passDataObj.dbPath}`)), {
        ...inputDataObj,
        docCreationDate: serverTimestamp(),
      });
    } else if (type == "Doc Updation") {
      console.log(inputDataObj);

      if (props.passMap.imgRequired) {
        if (typeof inputDataObj["logoLocation"] != "string") {
          console.log(inputDataObj["logoLocation"]);
          const fileExtension = inputDataObj["logoLocation"].name.split(".")[1];
          const fileRef = ref(
            storage,
            `${userContext.dbType}/${userContext.userUid}/brandLogo/brandLogo.${fileExtension}`
          );

          try {
            const uploadSnapshot = await uploadBytes(
              fileRef,
              inputDataObj["logoLocation"]
            );

            inputDataObj["logoLocation"] = uploadSnapshot.metadata.fullPath;
          } catch (error) {
            console.log(error);
          }
        }
      }

      try {
        await setDoc(
          doc(db, `${userContext.dbType}/${userContext.userUid}/`),
          { serviceSettings: inputDataObj },
          { merge: true }
        );
      } catch (error) {
        console.log(error);
      }

      displayPrompt("Settings Updated Successfully");
    } else if (type == "Settings Updation") {
      const serverCallFnName =
        props.tabName == "General"
          ? "setStoreGeneralSettings"
          : props.tabName == "Service"
          ? "setStoreJobSettings"
          : "setStoreBranchLocation";

      console.log(inputDataObj);

      if(props.tabName=="Service"){

        if(!inputDataObj.isJobOffered.includes(true) || inputDataObj.jobCosts.includes(NaN) 
        ||inputDataObj.jobEstdWaitTimes.includes(NaN)){
          const map = {
            mode:"info",
            title:"Input Required",
            btnLabels:["Ok"],
            message:"You need to specify a minimum of one job"
          }
          props.dialogHandler(map)
          dbWriteOk=false;
  
        }else{
          dbWriteOk=true
        }
      }else if (props.tabName=="Location"){

      }

      console.log(serverCallFnName);
      console.log(props.tabName);

      const filteredInputDataObj = checkAndReturnSettingsObject(
        inputDataObj,
        props.tabName
      );
      console.log(filteredInputDataObj);

      if(dbWriteOk){
        const setSettingsServerCall = httpsCallable(functions, serverCallFnName);

        const callResult = await setSettingsServerCall({...filteredInputDataObj})
        displayPrompt(callResult.data);
       }
    } else if (type == "Account Creation") {
      //For Employee, Store Branch and other Account Creation
      console.log("Started Account Creation");

      console.log(inputDataObj);
      console.log(userContext);

      const cinputDataObj = { ...inputDataObj }; //Creating a copy of the 'Input Object-State'
      //as I need to modify the name for Store Branches to include the Store Brands.

      cinputDataObj.name =
        passDataObj.inputDomain == "Store Branch"
          ? `${userContext.displayName} - ${inputDataObj.name}`
          : inputDataObj.name;
      //Above - For store branch I am adding the Store Brand name

      console.log(passDataObj);
      //'Payload' which will be sent to the Cloud Function Server to create Account
      const payload = {
        ...compileAddAccountPayload(cinputDataObj, passDataObj),
        creatorRoleId: userContext.userRoleId,
        creatorUid: userContext.userUid,
        inputDomain: passDataObj.inputDomain,
      };

      console.log(payload);

      const addAccountServerCall = httpsCallable(functions, "signUp");
      const callResult = await addAccountServerCall(payload);
      displayPrompt(callResult.data);
    }
    props.submitCloseHandler();
  }

  function inputArrayChangeFn(e, rowIndex,inputType) {
    console.log(rowIndex);

    const rInputName = e.target.name;

    console.log(e.target.value,inputType)

    const rInputValue = getInputValue(e.target,inputType);

    console.log(rInputValue);

    if (inputType == "file") {
      setInputImg(URL.createObjectURL(rInputValue));
    }

    const tempObj = { ...inputDataObj }; //copying the 'Input Object State' into a temp object to then set as 'Input Object State'
    // Above doing this so, is because if tempOvj=inputDataObj, then changing the tempObj will also chaneg the input object and
    //then when I set the State, the state will not update as the temp and input objects are the same

    //const rInputIndex = tempObj[rInputName].indexOf(rInputName); //Find the index of the servie type in the array pack
    tempObj[rInputName][rowIndex] = rInputValue;
    console.log(tempObj);

    setInputDataObj(tempObj); //Input Object Set
  }

  function compileAddAccountPayload(inputDataObj, formatInputDataObj) {
    const rMap = {}; //Creating a blank array which will hold all required values

    Object.keys(inputDataObj).forEach((key) => {
      //Iterating the keys array of the 'Current Inputted Object'
      console.log(key);
      const index = formatInputDataObj["inputNames"].indexOf(key); //Checking the index of the current key with the 'Format Object' inputNames array

      if (formatInputDataObj.inputTreeTypes[index] != "main") {
        //Checking for values which have to be inserted in a submap
        const branchName = formatInputDataObj.inputTreeTypes[index]; //capturing the fieldName in the format object
        console.log(branchName);
        if (Object.keys(rMap).indexOf(branchName) == -1) {
          rMap[branchName] = {};
        }

        rMap[branchName][key] = inputDataObj[key]; //inserting the value in the sub-branch of the object
      } else {
        rMap[key] = inputDataObj[key]; //If not a sub branch, the key value is inserted in the main tree
      }
    });

    return rMap;
  }

  return (
    <div className="grid justify-items-center w-full p-10">
    <div className={"w-3/5"}>
      <div //heading
        className={`grid grid-cols-cust-8s gap-1 bg-secondary text-darkBackText rounded-lg py-2 mb-2`}
      >
        {props.passMap.inputNames.map((inputName, index) => (
          <div className="flex justify-center items-center bg-primary-500 ">
            {props.passMap.inputLabels[index]}
          </div>
        ))}
      </div>

      {props.passMap.dataMap.jobNames.map((inputName, i) => (
        <div
          className={`grid grid-cols-cust-8s gap-1 py-2 bg-quaternary-light rounded-lg my-1`}
        >
          <InputRowBlock
            passMap={props.passMap}
            inputDataObj={inputDataObj}
            rowIndex={i}
            inputChangeFn={(e, rowIndex,inpType) => inputArrayChangeFn(e, rowIndex,inpType)}
            changeValdCount = {(val)=>props.changeValdCount(val)}
          />
        </div>
      ))}
      {props.imgRequired && <ImageCont image={inputImg} />}
    </div>
    {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
    </div>
  );
}

function checkAndReturnSettingsObject(inputDataObj, tabName) {
  console.log(tabName);
  if (tabName == "Service") {
    const rMap = {};
    rMap["jobNames"] = [];
    rMap["jobCosts"] = [];
    rMap["jobEstdWaitTimes"] = [];
    rMap["jobItemNames"] = [];

    inputDataObj.isJobOffered.forEach((it, i) => {
      if (it) {
        rMap["jobNames"].push(inputDataObj["jobNames"][i]);
        rMap["jobCosts"].push(inputDataObj["jobCosts"][i]);
        rMap["jobEstdWaitTimes"].push(inputDataObj["jobEstdWaitTimes"][i]);
        rMap["jobItemNames"].push(inputDataObj["jobItemNames"][i]);
      }
    });
    console.log(rMap);
    return rMap;
  }

  console.log(inputDataObj);

  return inputDataObj;
}
