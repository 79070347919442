import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { applyActionCode, checkActionCode, confirmPasswordReset, getAuth, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, verifyPasswordResetCode } from "firebase/auth";
import { child, get, getDatabase, onValue, push, query as rbaseQuery, ref as rbaseRef, set, update } from "firebase/database";
import { Timestamp, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, increment, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { activate, fetchAndActivate, fetchConfig, getRemoteConfig, getValue } from "firebase/remote-config";
import { getBlob, getBytes, getStorage, ref, uploadBytes } from "firebase/storage";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import { BrowserRouter, Link, Route, Routes, useNavigate } from 'react-router-dom';
import { inpOptionsKeyAliasMap, inpOptionsMap } from "./components/DataList.js";
import { firebaseConfigDev, firebaseConfigProd } from "./firebaseWebConfiguration.js";
import pdfMakeVfsLocal from "./vfs_fonts.js";

import appLogoImg from "./images/app-logo.png";
// import pdfFont from "pdfmake/build/vfs_fonts.js"
import numToWordConverter from "number-to-words";

// import pdfMake from "pdfmake/build/pdfmake.js"


// const pdfMake = require('pdfmake/build/pdfmake.js')
// const pdfFonts = require('pdfmake/build/vfs_fonts.js')

pdfMake.vfs = pdfMakeVfsLocal.pdfMake.vfs;



const fireabaseConfig = process.env.REACT_APP_MODE=="development" ? firebaseConfigDev : firebaseConfigProd 


const firebase = initializeApp(fireabaseConfig);

const db=getFirestore(firebase);
const functions = getFunctions(firebase);
const storage = getStorage(firebase);
const auth = getAuth(firebase);
const database = getDatabase(firebase)
const analytics = getAnalytics(firebase)
const remoteConfig = getRemoteConfig(firebase)

logEvent(analytics,"notification_received")

remoteConfig.settings.minimumFetchIntervalMillis = 1000*1; //1 sec

remoteConfig.defaultConfig = {
  "enforce_server_functions": false,
  "check_email_verification": false
};


const appLogo = getBase64ImgFromImageComp(appLogoImg)



const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const daysShort = ["Sun","Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//const dayHours = [0,1,2,3,4,5,6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const dayHours = generateIncrementNumArray(0,23);

const jobTypes = ["Skate Sharpening","Skate Profiling","Skate Repair"];

const maxDaysInAYear = 366;

const daysOfTheYear = generateIncrementNumArray(1,maxDaysInAYear);


const baseYear = 2023;



const avlYears = getAvlYears();
const avlYearsFilter = [...getAvlYears(),"All"]

export const styleProxyMap={
  gridCols:{
    "2-file":'grid-cols-cust-2-file',
    "3f":'grid-cols-cust-3f',
    "3x":'grid-cols-cust-3x',
    "3y":'grid-cols-cust-3y',
    "4f":'grid-cols-cust-4f',
    "4y":'grid-cols-cust-4y',
    "4z":'grid-cols-cust-4z',
    "5y":'grid-cols-cust-5y',
    "5z":'grid-cols-cust-5z',
    "6x":'grid-cols-cust-6x',
    "6y":'grid-cols-cust-6y',
    "6z":'grid-cols-cust-6z',
    "7y":'grid-cols-cust-7y',
    "8y":'grid-cols-cust-8y',
    "9c":'grid-cols-cust-9c',
    "11y":'grid-cols-cust-12y',
    "12y":'grid-cols-cust-12y',
    "13y":'grid-cols-cust-12y',
  },
  gridRows:{
    "2c":'grid-rows-cust-2c',
    "2i":'grid-rows-cust-2i'
  },
  color:{
    "primary":'bg-primary',
    "primaryPale":'bg-primaryPale',
    "secondary":'bg-secondary',
    "red":'bg-red',
    "green":'bg-green',
    "blue":'bg-blue',
    "black":'bg-black',
    "blackPale":'bg-blackPale',
    "orange":'bg-orange',
    "greenTrans":"bg-greenTrans",
    "greenDark":"bg-greenDark",
    "redTrans":"bg-redTrans",
    "golden":"bg-golden",
    "white":"bg-quaternary-light"
  },
  paddingTop:{
    "topHead":"cust-topHead"
  },
  height:{
    "800px":'800px'
  }
}


const skateInputObj={
    inputNames:["customerId","customerName","skateBrandName","skateRadiusHollow","skateSize"],
    inputTypes:["text","text","text","text","text"],
    inputLabels:["Customer ID","Customer Name","Skate Brand Name","Radius of Hollow","Size"],
    mainKey:"inputNames",
    dbPath:"customers/${userUid}/skates/",
    operationType:"Doc Addition"
}

const strMap = {
  dshBtn:{
    pastOrders:"Past Orders",
    acceptedOrders:'Accepted Orders',
    currentOrders:"Current Orders",
    addPpdCardScheme:"Add Prepaid Scheme",
    delPpdCardScheme:"Delete Prepaid Scheme",
    managePpdCardScheme:"Manage Prepaid Scheme",
    checkPpdCardRequest:"Manage Customer Requests",
    addWorker:"Add Employee",
    manageWorker:"Manage Employees",
    addCustomer:"Add Customer",
    manageCustomer:"Manage Customers",
    addStoreBranch:"Add Store Branch",
    editStoreBranch:"Manage Store Branches",
    commissions:"Commissions",
    transactions:"Transactions",
    invoices:"Invoices",
    unpdInvoices:"Unpaid Invoices",
    manageAccount:"Manage User Account",
    balances:"Balances",
    storeBrandSignUpRequest:"Brand Sign Up Requests"
  },
  gen:{
    date:"Date",
    time:"Time",
    dateTime:"Date/Time"
  },
  customer:{
    name:"Customer Name",
    IdNo:"Customer ID",
  },
  ppdCard:{
    schemeName:"Prepaid Scheme Name",
    schemeIdNo:"Prepaid Scheme ID",
    requestIdNo:"Request ID",
    requestType:"Prepaid Card Request"
  },
  transaction:{
    amountToCharge:"Amount Chargeable",
    acceptPayment:"Accept Payment"
  }
}

const unpaidInvoiceDataSeqKeys = ["invoiceId","invoiceIssueDate","invoicePartyId","invoicePartyName","invoicePartyUid","invoiceDesc","invoiceTotalAmount","invoiceJobCount","invoicePaymentDueDate","invoiceMonthYear"];


const paidInvoiceDataSeqKeys = ["invoiceId","invoiceIssueDate","invoicePartyId","invoicePartyName","invoicePartyUid","invoiceDesc","invoiceTotalAmount","invoiceJobCount","invoicePaymentDueDate","invoiceMonthYear","invoicePaymentDate"]; // Its same as above with one additional key i.e. invoicePaymentDate

const addToolInputObj={
  inputNames:["toolBrandName","toolModelName","toolSerialNo","toolMfgDate"],
  inputTypes:["text","text","text","date"],
  inputTreeTypes:["main","main","main","main"],
  elemTypes:["standard","standard","standard","standard"],
  inputLabels:["Brand Name","Model","Serial No","Manufacture Date"],
  editStatus:[true,true,true,true],
  mainKey:"inputNames",
  dbPath:"stores/${userUid}/tools/",
  opType:"add",
  operationType:"Local Doc Addition",
  inputDomain:"Add Tool"
}



const addEmployeeInputObj={
    inputNames:["name","email","password"],
    inputTypes:["text","email","password"],
    mandInpKeys:["name","email","password"],
    inputTreeTypes:["main","main","main"],
    elemTypes:["standard","standard","standard"],
    inputLabels:["Employee Name","Employee Login Email Id","Employee Login Password"],
    inputRegexDomains:["personName","email","password"],
    confmMsg:"This will add an employee account, do you wish to proceed?",
    editStatus:[true,true,true],
    mainKey:"inputNames",
    dbPath:"stores/${userUid}/employees/",
    opType:"add",
    operationType:"Account Creation",
    inputDomain:"Employee"
}

export const addCustomerExternalInputObj={
  inputNames:["firstName","lastName","email","contactNoComps","password"],
  inputTypes:["text","text","email","phoneNo","password"],
  inputLabels:["First Name","Last Name","Customer Login Email Id","Customer Contact No","Customer Login Password"],
  inputRegexDomains:["personName","personName","email","phoneNo","password"],
  mandInpKeys:["firstName","email","password"],
  inputTreeTypes:["main","main","main","main","main"],
  elemTypes:["standard","standard","standard-control","standard","standard-control"],
  controlObjs:{
    "autoAssign":{
      "email":{
        value:"auto-assign@hockeycaddy.ca",
        visibility:false
      },
      "password":{
        value:"password",
        visibility:false
      },
    }
  },
  confmMsg:"This will add the customer. The customer will be in Auto Mode (No App). Please ensure the customer is aware of the terms and conditions and privacy policy conditions before proceeding to creation. Do you wish to continue?",
  editStatus:[true,true,true,true,true],
  executionFnName:"signUpCustomer",
  mainKey:"inputNames",
  dbPath:"stores/${userUid}/employees/",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Customer"
}

export const addCustomerInputObj={
  inputNames:["autoAssign","firstName","lastName","email","contactNoComps","password"],
  inputTypes:["checkbox","text","text","email","phoneNo","password"],
  mandInpKeys:["firstName","email","password"],
  inputTreeTypes:["main","main","main","main","main","main"],
  elemTypes:["option","standard","standard","standard-control","standard","standard-control"],
  controlObjs:{
    "autoAssign":{
      "email":{
        value:"auto-assign@hockeycaddy.ca",
        visibility:false
      },
      "password":{
        value:"password",
        visibility:false
      },
    }
  },
  inputLabels:["Auto-Assign Credentials","First Name","Last Name","Customer Login Email Id","Customer Contact No","Customer Login Password"],
  inputRegexDomains:["","personName","personName","email","phoneNo","password"],
  confmMsg:"This will add the customer. The customer will be in Auto Mode (No App). Please ensure the customer is aware of the terms and conditions and privacy policy conditions before proceeding to creation. Do you wish to continue?",
  editStatus:[true,true,true,true,true,true],
  mainKey:"inputNames",
  dbPath:"stores/${userUid}/employees/",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Customer"
}

export const inviteCustomerBatchInputObj={
  inputNames:["name","email"],
  inputTypes:["text","email"],
  mandInpKeys:["email"],
  elemTypes:["standard","standard"],
  inputLabels:["Customer Name","Customer Email"],
  inputRegexDomains:["personName","email"],
  batchDisplayInpKeys:["name","email"],
  batchUniqueInpKeys:["email"],
  editStatus:[true],
  confmMsg:"This will add the customer. The customer will be in Auto Mode (No App). Please ensure the customer is aware of the terms and conditions and privacy policy conditions before proceeding to creation. Do you wish to continue?",
  mainKey:"inputNames",
  dbPath:"",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Customer",
  moduleTopic:"addCustomer"
}

export const manageCustomerSchemeMap={
  // labelNames:["Order ID",strMap.gen.date,strMap.customer.name,"Job Name","Employee Name","Item Name","Job Cost","Payment Mode"],
  // labelDbKeys:["orderId","orderStartDateTime","orderCustomerName","orderJobName","orderResponsibleEmployeeName","orderJobItemDesc","orderCost","orderPaymentMode"],
  labelNames:["Name","Phone No","Email"],
  labelDbKeys:["customerName","customerContactNo","customerEmail"],
  btnLabels:[],
  excludedDbDataIndex:[5],
  filterSchemeMap:{
    filterDateNames:["Today","This Week","Last Week", "This Month","By Month","By Year"],
    filterNames:["By First Name","By Last Name","By Full Name","By Email", "By Phone No","By Customer ID"],
    filterDbKeys:["orderCustomerIds","orderCustomerNames","orderResponsibleEmployeeNames","orderJobNames","orderJobNames"],
    filterInputKeys:[["customerFirstName"],["customerLastName"],["customerName"],["customerEmail"],["customerContactNoCountryCode","customerContactNoComponent"],["orderJobName"]],
    inputPlaceholderNames:[["Enter First Name"],["Enter Last Name"],["Enter Full Name"],["Enter Email"],["","Enter Phone No"],["Enter Customer ID"]],
    filterSeqIndices:[1,2,3,4,5],
    filterInputTypes:[["text"],["text"],["text"],["text"],["select","text"],["text"]  ],
  },
  btnLabels:["View Details"],
  valueSeqDbKeys:["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  "orderCustomerUid","orderJobName","orderItemId","orderItemDesc","orderAcceptToJobStartDuration",
  "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  "orderEstdCompletionTime","orderCost","orderPaymentMode","orderCurrency"],
  moduleTopic:"manageCustomer",
  fnTopic:"manageCustomer",
  dbDomain:"customer",

  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}


export const manageCustomerModalSchemeMap={
  selectionNames:["Edit Customer Details","Add Skate","Edit/Delete Skates"],
  selectionImgKeys:["manageUserAccount","addSkate","manageSkate"]
}

export const addStoreBrandModalInputSchemeMap={
  inputNames:["storeBrandName","email","password"],
  inputLabels:["Brand Name","Email","Password"],
  inputTreeTypes:["main","main","main"],
  inputTypes:["text","email","password"],
  mandInpKeys:["name","email","password"],
  inputRegexDomains:["entityName","email","password"],
  confmMsg:"This will add a Super Admin Account, Do you wish to proceed?",
  elemTypes:["standard","standard","standard"],
  editStatus:[true,true,true],
  preInpConds:[true,true,false],
  mainKey:"inputNames",
  opBtnLabels:["Sign Up"],
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Store Brand"
}

const addSuperAdminInputObj={
  inputNames:["name","email","password"],
  inputLabels:["Name","Email","Password"],
  inputTreeTypes:["main","main","main"],
  inputTypes:["text","email","password"],
  mandInpKeys:["name","email","password"],
  inputRegexDomains:["entityName","email","password"],
  confmMsg:"This will add a Super Admin Account, Do you wish to proceed?",
  elemTypes:["standard","standard","standard"],
  editStatus:[true,true,true],
  mainKey:"inputNames",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Super Admin"
}


const addStoreBrandInputObj2={
  inputNames:["name","email","password","logoLocation","prepaidCardValidity"],
  inputLabels:["Branch Name","Email","Password","Logo","Validity of Prepaid Card"],
  inputTreeTypes:["main","main","main","settings","settings"],
  inputTypes:["text","email","password","file","text"],
  mainKey:"inputNames",
  dbPath:"stores/${userUid}/",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Store Branch"
}

const addStoreBranchInputObj={
  inputNames:["storeBranchName","email","password","storeBranchCurrency"],
  inputLabels:["Branch Name","Email of Branch Account","Password of Branch Account","Currency of Branch"],
  inputTreeTypes:["main","main","main","main"],
  inputTypes:["text","email","password","select"],
  mandInpKeys:["storeBranchName","email","password"],
  confmMsg:"This will add a Store Branch Account, Do you wish to proceed?",
  inputRegexDomains:["entityName","email","password",""],
  elemTypes:["standard","standard","standard","selection"],
  editStatus:[true,true,true,true],
  selectOptions:[[],[],[],["CAD"]],
  settings:{
    settings:{
      storeBranchContactPersonName:"",
      storeBranchContactPersonContactNoComps:["",""],
      storeBranchContactPersonContactNo:"",
      storeBranchUnitNo:"",
      storeBranchStreetName:"",
      storeBranchCityName:"",
      storeBranchProvinceName:"",
      storeBranchCountryName:"",
      storeBranchPostalCode:"",
      storeBranchContactNoComps:["",""],
      storeBranchContactNo:"",
      storeBranchCurrency:"",
      storeBranchDescription:"",
      storeBranchPayConfirmationCond:true
    },
    jobSettings:{
      jobCosts:[],
      jobEstdWaitTimes:[],
      jobNames:[],
      jobItemNames:[] 
    },
    location:{
      storeBranchLatitude:"",
      storeBranchLongitude:""
    },
    availability:{
      storeBranchStartTime:"",
      storeBranchEndTime:""
    }
  },
  mainKey:"inputNames",
  dbPath:"stores/${userUid}/",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Store Branch"
}

export const resetPasswordInputObj={
  inputNames:["password"],
  inputTypes:["password"],
  mandInpKeys:["password"],
  inputTreeTypes:["main"],
  elemTypes:["standard"],
  inputLabels:["New Password"],
  inputRegexDomains:["password"],
  confmMsg:"This will permanently update your password. Do you wish to continue?",
  editStatus:[true],
  mainKey:"inputNames",
  dbPath:"stores/${userUid}/employees/",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Customer"
}



export const addStoreBrandInputObj={
  inputNames:["storeBrandName","email","password"],
  inputTypes:["text","email","password"],
  mandInpKeys:["storeBrandName","email","password"],
  inputTreeTypes:["main","main","main"],
  elemTypes:["standard","standard","standard"],
  inputLabels:["Store Brand Name","Email","Password"],
  inputRegexDomains:["entityName","email","password"],
  confmMsg:"This will add the store brand, do you wish to proceed?",
  editStatus:[true,true,true],
  executionFnName:"signUp",
  mainKey:"inputNames",
  dbPath:"stores",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Store Brand",
}


export const addStoreBrandRequestInputObj={
  inputNames:["contactPersonName","storeBrandName","email","contactNoComps","message"],
  inputTypes:["text","text","email","phoneNo","textarea"],
  inputTreeTypes:["main","main","main","main","main"],
  elemTypes:["standard","standard","standard","standard","standard"],
  inputLabels:["Contact Person Name","Store Name","Email","Contact No","Message"],
  inputRegexDomains:["personName","entityName","email","phoneNo","message"],
  editStatus:[true,true,true,true,true],
  executionFnName:"requestSignUp",
  mainKey:"inputNames",
  dbPath:"stores",
  opType:"add",
  operationType:"Account Creation",
  inputDomain:"Store",
}



// const editSettingsInputObjStoreBrand={
//   inputNames:["logoLocation","prepaidCardValidity","country"],
//   inputLabels:["Logo","Validity of Prepaid Card",
//   "Country"],
//   elemTypes:["standard","switch","standard"],
//   selectOptions:[[],["Yes","No"],[]],
//   mainKey:"inputNames",
//   dbPath:"customers/${userUid}/skates/",
//   opType:"edit",
//   operationType:"Doc Updation",
//   inputDomain:"Store Brand Settings"
// }

export const editUserInputObj={
  inputNames:["userEmail","password"],
  inputLabels:["Email","Password"],
  inputTypes:["email","password"],
  elemTypes:["standard","standard"],
  inputRegexDomains:["email","password"],
  mandInpKeys:[],
  selectOptions:[[],[]],
  mainKey:"inputNames",
  editStatus:[true,true],
  dbPath:"",
  opType:"edit",
  operationType:"Doc Updation",
  inputDomain:"Edit User"
}

export const editCustomerAccountInputObj={
  inputNames:["customerFirstName","customerLastName","customerContactNoComps","customerEmail","customerNoAppCond"],
  inputLabels:["First Name","Last Name","Contact No","Email","No Privacy Mode"],
  inputTypes:["text","text","phoneNo","text","checkbox"],
  elemTypes:["standard","standard","standard","standard","standard"],
  inputRegexDomains:["personName","personName","phoneNo","email",""],
  mandInpKeys:["customerFirstName","customerEmail"],
  selectOptions:[[],[]],
  mainKey:"inputNames",
  editStatus:[true,true,true,true,true],
  dbPath:"",
  opType:"edit",
  executionType:"server",
  dbDomain:"customer",
  inputTopic:"customer",
  executionFnName:"updateCustomerSettings",
  confmMessageBeforeExecution:"Do you want to proceed to edit the customer account?",
  operationType:"Doc Updation",
  inputDomain:"Edit Customer"
}

export const addSkatesInputSchemeMap={
  inputNames:[["skateBrandName","skateBrandNameOther"]
  ,"skateModelName","skateBlade1Type","skateBlade2Type","skateBlade3Type",["skateSharpeningType","skateSharpeningTypeDesc"],"skateSizeType",
  "skateSize","skateWidth","skateHollowRadius","skateProfileFamily","skateProfileRadius"
],
  inputLabels:["Brand","Model","Blade 1 Type","Blade 2 Type","Blade 3 Type","Sharpening Type","Skate Size","Sizes","Width","Hollow","Profile Family","Profile Radius"],
  inputTypes:[["selection","text"],"text","text","text","text",["text","text"],"text",
  "text","text","text","text","text"
],
  elemTypes:["selection-writable","standard","standard","standard","standard","selection-writable","selection","dynamic-selection","selection","selection","selection","dynamic-selection"],
  inputRegexDomains:[["","modelName"],"modelName","","personName","",["",""],"personName","","","personName","",""],
  mandInpKeys:["skateBrandName","skateModelName","skateSizeType",
  "skateSize","skateWidth","skateHollowRadius","skateProfileFamily","skateProfileRadius"],
  selectOptions:[[],[],[],[],[],[],[],[],[],[],[],[]],
  mainKey:"inputNames",
  editStatus:[[true,true],true,true,true,true,[true,true],true,true,true,true,true,true],
  dbPath:"storeBranches/${userUid}/employees/",
  opType:"add",
  dbDomain:"customer",
  inputTopic:"skates",
  executionType:"local",
  confmMessageBeforeExecution:"Do you want to proceed to add the skates?",
  operationType:"Doc Addition",
  inputDomain:"Add Skate"
}

export const editSkatesInputSchemeMap={
  inputNames:[["skateBrandName","skateBrandNameOther"]
  ,"skateModelName","skateBlade1Type","skateBlade2Type","skateBlade3Type",["skateSharpeningType","skateSharpeningTypeDesc"],"skateSizeType",
  "skateSize","skateWidth","skateHollowRadius","skateProfileFamily","skateProfileRadius"
],
  inputLabels:["Brand","Model","Blade 1 Type","Blade 2 Type","Blade 3 Type","Sharpening Type","Skate Size","Sizes","Width","Hollow","Profile Family","Profile Radius"],
  inputTypes:["text","text","text","text","text",["text","text"],"text",
  "text","text","text","text","text"
],
  elemTypes:["selection-writable","standard","standard","standard","standard","selection-writable","selection","dynamic-selection","selection","selection","selection","dynamic-selection"],
  inputRegexDomains:[["","modelName"],"","","personName","",["",""],"personName","","","personName","",""],
  mandInpKeys:["skateBrandName","skateModelName","skateSizeType",
  "skateSize","skateWidth","skateHollowRadius","skateProfileFamily","skateProfileRadius"],
  selectOptions:[[],[],[],[],[],[],[],[],[],[],[],[]],
  mainKey:"inputNames",
  editStatus:[[true,true],true,true,true,true,[true,true],true,true,true,true,true,true],
  dbPath:"storeBranches/${userUid}/employees/",
  opType:"edit",
  deleteFnReqdCond:true,
  dbDomain:"customer",
  inputTopic:"skates",
  executionType:"local",
  confmMessageBeforeExecution:"Do you want to proceed to edit the skates?",
  operationType:"Doc Updation",
  inputDomain:"Edit Skate"

}



const editEmployeeInputObj={
  inputNames:["userName","userEmail","password"],
  inputLabels:["Name","Email","Password"],
  inputTypes:["text","email","password"],
  elemTypes:["standard","standard","standard"],
  inputRegexDomains:["personName","email","password"],
  mandInpKeys:["userName","userEmail"],
  selectOptions:[[],[],[]],
  mainKey:"inputNames",
  editStatus:[true,true,true],
  dbPath:"storeBranches/${userUid}/employees/",
  opType:"edit",
  operationType:"Doc Updation",
  inputDomain:"Edit Employee Details"
}

const editToolInputObj={
  inputNames:["toolBrandName","toolModelName","toolSerialNo"],
  inputLabels:["Brand Name","Model Name",
  "Serial No"],
  inputTypes:["text","text",
  "text"],
  elemTypes:["standard","standard","standard"],
  selectOptions:[[],[],[]],
  mainKey:"inputNames",
  editStatus:[true,true,true],
  dbPath:"storeBranches/${userUid}/tools/",
  opType:"edit",
  operationType:"Doc Updation",
  inputDomain:"Edit Tool Details"
}


//@2
// const editSettingsStoreBranchInputSchemeMap ={
//   inputCategories:["General","Service","Location","Availability"],
//   inputNames:[["storeBranchContactPersonName","storeBranchContactPersonContactNo","storeBranchUnitNo",
//   "storeBranchStreetName","storeBranchCityName","storeBranchProvinceName","storeBranchCountryName",
//   "storeBranchPostalCode","storeBranchContactNo","storeBranchDescription","storeBranchPayConfirmationCond","storeImage"],
//   ["slNo","jobNames","jobCosts","jobEstdWaitTimes","isJobOffered"],
//   ["storeBranchLatitude","storeBranchLongitude"],
//   ["storeStartTime","storeEndTime","ovrStoreAvlCond","storeAvlCond","estdWaitTime","ovrWaitTimeCond","ovrWaitTime"]],
//   inputLabels:[["Contact Person","Person Contact No","Unit No",
//   "Street","City","Province/State","Country",
//   "Postal Code","Store Contact No","Store Description","Pay Confirmation","Store Image"],
//   ["Sl No","Job Name","Cost/Job","Estd Wait Time (Mins)","Offered"],
//   ["Latitude","Longitude"],["Start Time","End Time","Override Availability","Store Availability","Estimated Wait Duration - Actual (Mins)","Override Wait Duration","Set Estd Wait Duration - Manual Override (Mins)"]],
//   mandInpKeys:[[],["jobCosts","jobEstdWaitTimes"],[],[]],
//   inputRegexDomains:[["personName","phoneNo","standard",
//   "standard","personName","personName","personName",
//   "standard","phoneNo","standard","",""],["","","positiveNumOrDec","positiveNumOrDec",""],
//   ["latitude","longitude"],["Start Time","End Time","Override Availability","Store Availability","Estimated Wait Duration (Mins)","Override Wait Duration","Set Estd Wait Duration (Mins)"]],
//   inputTypes:[["text","tel","text",
//   "text","select","select","select",
//   "text","text","text","radio","file"],["text","select","number-dec","number-dec","checkbox"],
//   ["number","number"],["time","time","checkbox","checkbox","text","checkbox","number"]],
//   elemTypes:[["standard",
//   "standard","standard",
//   "standard","dynamic-selection","dynamic-selection","selection",
//   "standard","standard","standard","switch","image"],["Sl No","selection","standard","standard","standard"],
//   ["standard","standard"],["standard","standard","standard","standard-dependant","display","standard","standard-dependant"]],
//   // selectOptions:[[[],[],[],[],[],
//   // ["Alberta","British Columbia","Manitoba","New Brunswick","Newfoundland and Labrador","Northwest Territories","Nova Scotia","Nunavut","Ontario","Quebec","Saskatchewan","Yukon"],["Canada"],
//   // [],[],[],[]],[[],["Skate Sharpening"],[],[]],[[],[]],[[],[],[],[],[],[],[]]],
//  editStatus:[[true,true,true,
//  true,true,true,true,true,true,true,true,true,true],[true,true,true,true,true],[true,true],
//  [true,true,true,false,false,true,true]],
//   categorySpecificKeys:["inputNames","inputLabels","inputTypes","inputRegexDomains","elemTypes","editStatus"],
//   mainKey:"inputNames",
//   dbPath:"customers/${userUid}/skates/",
//   opType:"edit",
//   operationType:"Settings Updation",
//   inputDomain:"Store Branch"
// }



const editSettingsStoreBranchInputSchemeMap ={
  inputCategories:["General","Service","Location","Availability"],
  // inputNames:[["storeBranchContactPersonName","storeBranchContactPersonContactNo","storeBranchUnitNo",
  // "storeBranchStreetName","storeBranchCountryName","storeBranchProvinceName","storeBranchCityName",
  // "storeBranchPostalCode","storeBranchContactNo","storeBranchDescription","storeBranchPayConfirmationCond","storeImage"],
  // ["slNo","jobNames","jobCosts","jobEstdWaitTimes","isJobOffered"],
  // ["storeBranchLatitude","storeBranchLongitude"],
  // ["storeStartTime","storeEndTime","ovrStoreAvlCond","storeAvlCond","estdWaitTime","ovrWaitTimeCond","ovrWaitTime"]],
  inputNames:[["storeBranchContactPersonName","storeBranchContactPersonContactNoComps","storeBranchUnitNo",
  "storeBranchStreetName","storeBranchCountryName","storeBranchProvinceName","storeBranchCityName",
  "storeBranchPostalCode","storeBranchContactNoComps","storeBranchDescription","storeBranchPayConfirmationCond","storeImage"],
  ["slNo","jobNames","jobCosts","jobEstdWaitTimes","isJobOffered"],
  ["storeBranchLatitude","storeBranchLongitude"],
  ["storeStartTime","storeEndTime","ovrStoreAvlCond","storeAvlCond","estdWaitTime","ovrWaitTimeCond","ovrWaitTime"]],
  inputLabels:[["Contact Person","Person Contact No","Unit No",
  "Street","Country","Province/State","City",
  "Postal Code","Store Contact No","Store Description","Pay Confirmation","Store Image"],
  ["Sl No","Job Name","Cost/Job","Estd Wait Time (Mins)","Offered"],
  ["Latitude","Longitude"],["Start Time","End Time","Override Availability","Store Availability","Estimated Wait Duration - Actual (Mins)","Override Wait Duration","Set Estd Wait Duration - Manual Override (Mins)"]],
  mandInpKeys:[[],["jobCosts","jobEstdWaitTimes"],[],[]],
  inputRegexDomains:[["personName","phoneNo","standard",
  "standard","personName","personName","personName",
  "standard","phoneNo","standard","",""],["","","positiveNumOrDec","positiveNumOrDec",""],
  ["latitude","longitude"],["Start Time","End Time","Override Availability","Store Availability","Estimated Wait Duration (Mins)","Override Wait Duration","Set Estd Wait Duration (Mins)"]],
  inputTypes:[["text","phoneNo","text",
  "text","select","select","select",
  "text","phoneNo","text","radio","file"],["text","select","number-dec","number-dec","checkbox"],
  ["number","number"],["time","time","checkbox","checkbox","text","checkbox","number"]],
  elemTypes:[["standard",
  "standard","standard",
  "standard","selection","dynamic-selection","dynamic-selection",
  "standard","standard","standard","switch","image"],["Sl No","selection","standard","standard","standard"],
  ["standard","standard"],["standard","standard","standard","standard-dependant","display","standard","standard-dependant"]],
  // selectOptions:[[[],[],[],[],[],
  // ["Alberta","British Columbia","Manitoba","New Brunswick","Newfoundland and Labrador","Northwest Territories","Nova Scotia","Nunavut","Ontario","Quebec","Saskatchewan","Yukon"],["Canada"],
  // [],[],[],[]],[[],["Skate Sharpening"],[],[]],[[],[]],[[],[],[],[],[],[],[]]],
 editStatus:[[true,true,true,
 true,true,true,true,true,true,true,true,true,true],[true,true,true,true,true],[true,true],
 [true,true,true,false,false,true,true]],
  categorySpecificKeys:["inputNames","inputLabels","inputTypes","inputRegexDomains","elemTypes","editStatus"],
  mainKey:"inputNames",
  dbPath:"customers/${userUid}/skates/",
  opType:"edit",
  operationType:"Settings Updation",
  inputDomain:"Store Branch"
}




const editSettingsSuperAdminInputSchemeMap={
  inputCategories:["General"],
  inputNames:[["unitNo","streetName","cityName","provinceName",
  "countryName","postalCode","contactNoComps","contactPersonName","contactPersonEmail","salesPersonName",],[],[],[]],
  inputLabels:[["Unit No",
  "Street Name","City Name","Province Name","Country",
  "Postal Code","Contact No","Contact Person Name","Contact Person Email","Sales Person Name"],[],[],[]],
  inputTypes:[["text",
  "text","text","text","text",
  "text","phoneNo","text","text","text"],[],[],[]],
  elemTypes:[["standard",
  "standard","standard","standard","standard",
  "standard","standard","standard","standard","standard"],["Sl No","selection","standard","standard","standard"],["standard","standard"],["standard","standard","standard","standard","display","standard","standard"]],
  selectOptions:[[[],[],[],
  [],[],["Alberta","British Columbia","Manitoba","New Brunswick","Newfoundland and Labrador","Northwest Territories","Nova Scotia","Nunavut","Ontario","Quebec","Saskatchewan","Yukon"],["Canada"],
  [],[],[],[],[]],[[],["Skate Sharpening"],[],[]],[[],[]],[[],[],[],[],[],[],[]]],
 editStatus:[[true,
 true,true,true,true,true,true,true,true,true],[true,true,true,true,true],[true,true],[true,true,true,false,false,true,false]],
  categorySpecificKeys:["inputNames","inputLabels","inputTypes","elemTypes","selectOptions","editStatus"],
  mainKey:"inputNames",
  dbPath:"customers/${userUid}/skates/",
  opType:"edit",
  operationType:"Settings Updation",
  inputDomain:"Super Admin"
}


// export const inpOptionsKeyAliasMap = {
//   storeBranchCountryName:"applicableCountries",
//   storeBranchProvinceName:"provinces",
//   storeBranchCityName:"cities",
//   storeBranchCurrency:"applicableCurrencies",
//   jobNames:"skateMaintenanceJobs",
//   skateBrandName:"skateBrandNames",
//   skateSharpeningType:"skateSharpeningTypes",
//   skateSizeType:"skateSizeTypes",
//   skateSize:"skateSizes",
//   skateProfileFamily:"skateProfileFamilies",
//   skateWidth:"skateWidths",
//   skateHollowRadius:"skateHollowRadii",
//   skateProfileRadius:"skateProfileRadii",
//   studentClassName:"instituteClassNames",
//   //storeBranchProvinceNames:inputOptionsMap.storeBranchProvinceName,
//   //storeBranchCityeNames:inputOptionsMap.storeBranchCityName
// }



export function getInpOptions(inpKey){
  console.log(inpKey)
  const optionKey = inpKey in inpOptionsKeyAliasMap ? inpOptionsKeyAliasMap[inpKey] : inpKey;
  // console.log(inpKey,inpOptionsMap[optionKey])
  return inpOptionsMap[optionKey]

}



// export function getInpOptions(inpKey){

//   if(inpKey in inpOptionsKeyAliasMap){
//     return inpOptionsKeyAliasMap[inpKey]
//   }else{
//     if(inpKey in inpOptionsMap){
//       return inpOptionsMap[inpKey]
//     }
//   }

//   return []
// }


export function getInpOptionsParentValues(valueMap,inpKey,parentKeys){
  console.log(valueMap,inpKey,parentKeys)

  // const parentKeys = inpDynOptionsParentKeyMap[inpKey];
  const count = parentKeys.length;
 

  return count == 2 ? [valueMap[parentKeys[0]],valueMap[parentKeys[1]]] : [valueMap[parentKeys[0]]]
}


export function getInpDynOptions(optionsMap,keyAlias,inpKey,depValues){

  console.log(optionsMap,keyAlias,depValues)
  const count = depValues.length;

  //temporary
  if(depValues.includes("") || depValues.includes("Please Select")){
    return []
  }

  if(count>1){
    if(depValues[1]=="" || depValues[1]=="Please Select"){
      //to take into consideration when State is Selected then the parentValue[1] is ""
      return []
    }else if(optionsMap[keyAlias][depValues[0]][depValues[1]]==undefined){
      //to take into consideration the undefined value when State is changed with District already chosen, example i select Andra and Rajahmundry and select a pincode, now if i select Karnataka State, the value then resolves to optionsMap[Karnataka][Rajahmundry] which is undefined
      return []
    }
  }

  console.log(optionsMap,keyAlias,depValues)

  return count==2 ? optionsMap[keyAlias][depValues[0]][depValues[1]] : optionsMap[keyAlias][depValues[0]]
}


export function getModalInteractiveSchemeMap(moduleTopic){

  switch(moduleTopic){
    case "manageCustomer":{
      return manageCustomerModalSchemeMap
    }
  }

}


const editSettingsStoreBrandInputSchemeMap={
  inputCategories:["General","Branding"],
  inputNames:[["prepaidCardValidity",
  "logo"],["slNo","jobNames","jobCosts","jobEstdWaitTimes","isJobOffered"]],
  inputLabels:[["Prepaid Card Validity",
  "Logo"],["Prepaid Card Validity","Job Name","Cost/Job","Estd Wait Time","Offered"]],
  inputTypes:[["checkbox",
  "file"],["text","select","number","number","checkbox"]],
  mandInpKeys:[],
  elemTypes:[["standard",
  "standard"],["Sl No","selection","standard","standard","standard"]],
  selectOptions:[[["Yes","No"],[]],[[],["Skate Sharpening"],[],[]],[[],[]],[[],[],[],[],[],[],[]]],
 editStatus:[[true,
 true],[true,true,true,true,true],[true,true],[true,true,true,false,false,true,false]],
  categorySpecificKeys:["inputNames","inputLabels","inputTypes","elemTypes","selectOptions","editStatus"],
  mainKey:"inputNames",
  opType:"edit",
  operationType:"Settings Updation",
  inputDomain:"Store Brand"
}

const addPrepaidSchemeInputSchemeMap={
  inputNames:["prepaidSchemeName",
  "prepaidSchemeNoOfJobsCharged","prepaidSchemeNoOfJobsOffered","prepaidSchemeJobName"
  ,"prepaidSchemeCurrency","prepaidSchemeCost"],
  inputLabels:["Scheme Name","No of Jobs Charged",
  "No of Jobs Offered","Job Name","Currency","Cost"],
  inputTypes:["text","number","number","select","text","number"],
  elemTypes:["standard","standard","standard",
  "selection","display","display"],
  editStatus:[true,true,true,
  true,false,false],
  selectOptions:[[],[],[],[],[],[]],
  mainKey:"inputNames",
  dbPath:"storeBranches/${userUid}/prepaidCardSchemes/",
  opType:"add",
  operationType:"Doc Addition",
  inputDomain:"Add Prepaid Scheme"
}

//@dash - with prepaid scheme
// const viewDashboardStoreBranchSchemeMap={
//   viewSectionNames:["Orders","Prepaid Cards","Employees","Tools","Analytics","App Fees"],
//   fnBtnNames:[[strMap.dshBtn.pastOrders,strMap.dshBtn.acceptedOrders,strMap.dshBtn.currentOrders,"Transactions"],
//   [strMap.dshBtn.addPpdCardScheme,strMap.dshBtn.managePpdCardScheme,strMap.dshBtn.checkPpdCardRequest],
//   [strMap.dshBtn.addWorker,strMap.dshBtn.manageWorker,"Mark Attendance"],["Add Tool","Manage Tools"]
//   ,["Job Stats","Time Stats","Employee Stats"],["Unpaid Invoices","Paid Invoices"]],
//   fnViews:[["StripListFilterCont3","BannerListListenerCont","BannerListListenerCont","StripListFilterCont3"],
//   ["InputCont","BannerListCont","BannerListListenerCont"],
//   ["InputCont","FnSelectCont","FnSelectCont2"],["InputCont","FnSelectCont"],
//   ["ChartCont","ChartCont2","ChartCont2"],["StripListBtnCont","StripListFilterCont2"]],
//   fnNotifications:[[0,0,0,0],[0,0,0],[0,0,0],[0,0],[0,0,0,0],[0,0]],
//   checks:[[],[["serviceAdded"],[],[]],[[],[],[]],[[],[]],[]]
// }

//@dash - without prepaid scheme and tools
const viewDashboardStoreBranchSchemeMap={
  viewSectionNames:["Orders","Employees","Customers","Analytics","App Fees","Test"],
  fnBtnNames:[
  [strMap.dshBtn.pastOrders,strMap.dshBtn.currentOrders,"Display Current Orders","Transactions"],
  [strMap.dshBtn.addWorker,strMap.dshBtn.manageWorker,"Mark Attendance"],
  [strMap.dshBtn.addCustomer,"Invite Customers",strMap.dshBtn.manageCustomer],
  ["Job Stats","Employee Stats","Skate Stats"],
  ["Unpaid Invoices","Paid Invoices"],["Firestore"]],
  fnViews:[
  ["StripListFilterBtnCont3","BannerListListenerCont","BannerListListenerCont2","StripListFilterCont3"],
  ["InputCont","FnSelectCont","FnSelectCont2"],
  ["InputCont","BatchInputCont","RecordBannerFilterCont"],
  ["ChartCont4","ChartCont4","ChartCont5"],
  ["StripListBtnCont","StripListFilterCont2"],["TestContFirestore"]],
  fnNotifications:[[0,0,0,0],[0,0,0],[0,0,0],[0,0,0],[0,0],[0]]
}

// const viewDashboardSuperAdminSchemeMap={
//   viewSectionNames:["Students",
//   "Exams",
//   "User Accounts"],
//   fnBtnLabels:[["Add Student","Manage Students"],
//   ["Add Exam","Manage Exams"],
//   ["Add Institute","Manage User Accounts","Institute Sign Up Requests"]], 
//   fnViews:[["StripListFilterBtnCont","StripListFilterBtnCont"],
//   ["FnSelectCont3","StripListBtnCont2"],
//   ["No Change","No Change","No Change"]],
//   fnBtnImgs:[[addStudentImg,manageStudentImg],
//   [null,null],
//   [null,null,null]],
//   fnNotifications:[[0,0,],
//   [0,0],
//   [0,0,0]],
// }



const viewDashboardMasterSchemeMap={
  viewSectionNames:["Admin Control"],
  fnBtnNames:[["Add Super Admin","Edit Super Admin"]], 
  fnViews:[["InputCont","FnSelectCont"]],
  fnNotifications:[[0,0]]
}




const viewDashboardStoreBrandSchemeMap={
  viewSectionNames:["Stores"],
  fnBtnNames:[
  [strMap.dshBtn.addStoreBranch,"Manage Store Branches"]],
  fnViews:[
  ["InputCont","FnSelectCont5"]],
  fnNotifications:[[0,0]]
}

//Scheme for Check Prepaid Card Request
const viewPrepaidCardRequestRecordBannerSchemeMap={
  labelNames:[strMap.ppdCard.requestIdNo,strMap.gen.date,strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,"Job Name","No of Jobs Offered","Cost"],
  labelDbKeys:["prepaidCardRequestId","prepaidCardRequestDate","prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardJobName","prepaidCardNoOfJobsOffered","prepaidCardCost"],
  btnLabels:["Approve","Reject"],
  btnAlertLevels:["High","Low"],
  dataDbKeys:["prepaidCardRequestId","prepaidCardRequestDate","prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardJobName","prepaidCardNoOfJobsOffered","prepaidCardCost"],
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}


const managePrepaidSchemeMap={
  valDbKeys:["prepaidSchemeId","docCreationDate","prepaidSchemeName","prepaidSchemeJobName","prepaidSchemeNoOfJobsCharged","prepaidSchemeNoOfJobsOffered","prepaidSchemeCost","prepaidSchemeStatus"],
  
  valLabels:["Scheme ID","Scheme Issue Date",
  "Country"],
  elemTypes:["standard","switch","standard"],
  selectOptions:[[],["Yes","No"],[]],
  mainKey:"inputNames",
  dbPath:"customers/${userUid}/skates/",
  opType:"edit",
  dataDbKeys:["prepaidSchemeId","docCreationDate","prepaidSchemeName","prepaidSchemeJobName","prepaidSchemeNoOfJobsCharged","prepaidSchemeNoOfJobsOffered","prepaidSchemeCost","prepaidSchemeStatus"],
  operationType:"Doc Updation",
  inputDomain:"Store Brand Settings"
}
//Scheme for Manage Prepaid Card Request
const managePrepaidSchemeBannerMap={
  labelNames:["Scheme ID","Issue Date",strMap.ppdCard.schemeName,"Job Name","Jobs Offered","Jobs Charged","Cost","Status"],
  labelDbKeys:["prepaidSchemeId","docCreationDate","prepaidSchemeName","prepaidSchemeJobName","prepaidSchemeNoOfJobsCharged","prepaidSchemeNoOfJobsOffered","prepaidSchemeCost","prepaidSchemeStatus"],
  btnLabels:["Delete","Hold"],
  btnAlertLevels:["High","Low"],
  dataDbKeys:["prepaidSchemeId","docCreationDate","prepaidSchemeName","prepaidSchemeJobName","prepaidSchemeNoOfJobsCharged","prepaidSchemeNoOfJobsOffered","prepaidSchemeCost","prepaidSchemeStatus"],
  // dataDbNotations:["prepaidSchemeId","docCreationDate","prepaidSchemeName","prepaidSchemeJobName","prepaidSchemeNoOfJobsCharged","prepaidSchemeNoOfJobsOffered","prepaidSchemeCost","prepaidSchemeStatus"]
}

const skateDetailsSchemeMap={
  labelNames:["Skate ID","Skate Brand","Skate Model","Skate Size Type", "Size", "Width","Hollow","Profile Family","Profile Radius","Sharpening Type"],
  dbKeys:["skateId","skateBrandName","skateModelName","skateSizeType","skateSize","skateWidth","skateHollowRadius","skateProfileFamily","skateProfileRadius","skateSharpeningType"]
}

const viewCurrentOrdersRecordBannerSchemeMap={
  labelNames:["QR",strMap.gen.date,strMap.customer.name,"Job Name","Employee Name","Item Name","Job Cost","Job Status"],
  labelDbKeys:["orderItemQrId","docCreationDate","orderItemAssigneeName","orderJobName","orderStageResponsiblePersonnelName","orderItemDesc","orderCost","orderStatus","orderCustomerId"],
  btnLabels:["More Info"],
  btnAlertLevels:["Low"],
  btnAvlblties:[true],
  dataDbKeys:["orderItemQrId","docCreationDate","orderCustomerName","orderJobName","orderStageResponsiblePersonnelName","orderJobItemName","orderCost","orderStatus","orderCustomerId","orderCustomerUid"
  ,"orderStageDateTimes","orderStageResponsiblePersonnelNames","orderStageResponsiblePersonnelUids","orderStages","orderStageResponsiblePersonnelUid","orderStoreBranchId","orderStoreBranchUid","orderStoreFullName","docUid"],
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}


const displayCurrentOrdersRecordBannerSchemeMap={
  "Skate Sharpening":{
    labelNames:["Customer","Model","Hollow","QR",["Duration","Time for Completion"],"Sharpening Type",],
    labelDbKeys:["orderItemAssigneeName","orderItemDesc","orderItemDataSeq","orderItemQrId",["docCreationDate","orderJobStartToJobEndDuration"],"orderItemDataSeq"],
    valueDisplayTypes:["name","name","skateHollowRadius","name",["timeMillis","timeMins"],"skateSharpeningType"],
    valuePersistances:["static","static","static","static",["dynamic","static"],"static"],
    labelNamesOnCompletion:["Customer","Model","QR"],
    btnLabels:[],
    btnAlertLevels:[],
    btnAvlblties:[],
  },
  "Skate Profiling":{
    labelNames:["Customer","Model","Hollow","QR","Profile Radius","Sharpening Type",],
    labelDbKeys:["orderItemAssigneeName","orderItemDesc","orderItemDataSeq","orderItemQrId","orderItemDataSeq","orderItemDataSeq"],
    valueDisplayTypes:["name","name","skateHollowRadius","name","skateProfileRadius","skateSharpeningType"],
    valuePersistances:["static","static","static","static","static","static"],
    labelNamesOnCompletion:["Customer","Model","QR"],
    btnLabels:[],
    btnAlertLevels:[],
    btnAvlblties:[],
  },
  "Skate Repair":{
    labelNames:["Customer","Model","Repair Type","QR","Due Date","Job Cost",],
    labelDbKeys:["orderItemAssigneeName","orderItemDesc","orderItemDataSeq","orderItemQrId","orderDueDate","orderCost"],
    valueDisplayTypes:["name","name","skateHollowRadius","name","time","currency"],
    valuePersistances:["static","static","static","static","static","static"],
    labelNamesOnCompletion:["Customer","Model","QR"],
    btnLabels:[],
    btnAlertLevels:[],
    btnAvlblties:[],
  }

}

const viewPastOrdersSchemeMap={
  // labelNames:["Order ID",strMap.gen.date,strMap.customer.name,"Job Name","Employee Name","Item Name","Job Cost","Payment Mode"],
  // labelDbKeys:["orderId","orderStartDateTime","orderCustomerName","orderJobName","orderResponsibleEmployeeName","orderJobItemDesc","orderCost","orderPaymentMode"],
  labelNames:["Sl No","Order ID",strMap.gen.date,strMap.customer.name,"Job Name","Item Name","Employee Name","Cost","Mode"],
  labelDbKeys:["slNo","orderId","orderStartDateTime","orderCustomerName","orderJobName","orderItemDesc","orderResponsibleEmployeeName","orderCost","orderPaymentMode"],
  btnLabels:[],
  excludedDbDataIndex:[5],
  filterSchemeMap:{
    filterDateNames:["Today","This Week","Last Week", "This Month","By Month","By Year"],
    filterNames:["Customer ID","Customer Name","Employee Name", "Job Name"],
    filterDbKeys:["orderCustomerIds","orderCustomerNames","orderResponsibleEmployeeNames","orderJobNames"],
    filterInputKeys:[["orderCustomerId"],["orderCustomerName"],["orderResponsibleEmployeeName"],["orderJobName"]],
    inputPlaceholderNames:[["Enter ID No"],["Enter Name"],["Enter Name"],["Enter Job Name"]],
    filterSeqIndices:[1,2,3,4],
    filterInputTypes:[["text"],["text"],["text"],["text"]],
  },
  btnLabels:["View Details"],
  valueSeqDbKeys:["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  "orderCustomerUid","orderJobName","orderItemId","orderItemDesc","orderAcceptToJobStartDuration",
  "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  "orderEstdCompletionTime","orderCost","orderPaymentMode","orderCurrency"]
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}

const checkCurrentOrderDetailsSchemeMap={
  labelNames:["Order ID","Item ID", "Item Desc","Order Start Date/Time","Job Completion Date/Time","Order Due Date/Time",strMap.customer.name,"Job Name","Job Type","Job Desc","Employee Name","Job Cost","Order Status","Job Accept to Job Start Duration","Job Start to Completion Duration"],
  labelDbKeys:["orderId","orderItemId","orderItemDesc","orderStartDateTime","orderCompletionDateTime","orderDueDate","orderCustomerName","orderJobName","orderJobSubName","orderJobDesc","orderStageResponsiblePersonnelName","orderCost","orderStatus","orderAcceptToJobStartDuration","orderJobStartToJobEndDuration"],
  valueDisplayTypes:["","","","dateTime","dateTime","dateTime","","","","","","currency","orderStatus","timeMins","timeMins"],
}

const checkPastOrderDetailsSchemeMap={
  labelNames:["Order ID","Order Start Date/Time","Order End Date/Time","Customer ID",strMap.customer.name,"Job Name","Employee Name","Item Name","Job Cost","Job Accept to Job Start Duration","Job Start to Completion Duration"],
  labelDbKeys:["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName","orderJobName","orderResponsibleEmployeeName","orderItemDesc","orderCost","orderAcceptToJobStartDuration","orderJobStartToJobEndDuration"],
  valueDisplayTypes:["","dateTime","dateTime","","","","","","currency","timeMins","timeMins"],
}


const viewBalancesSchemeMap={
  labelNames:["Sl No","Store ID","Store Name","Latest Bill Date","Unbilled Jobs","Billed Balance","Overdue Balance","Total Balance Due"],
  labelDbKeys:["slNo","partyId","partyName","latestBillDate","unbilledJobCount","billedBalance","dueBalance","totalDueBalance"],
  dbPath:"admin/accounts/pbalances",
  filterSchemeMap:{
    filterNames:["Party ID","Store Branch Name"],
    filterDbKeys:["partyIds","partyNames"],
    filterInputKeys:[["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[2,3],
    filterInputTypes:[["text"],["text","text"]],
  },
  btnLabels:["Billing"],
  btnAlertLevels:["high"],
}

const viewUnpaidInvoicesSchemeMap={
  labelNames:["Sl No","Invoice ID",strMap.gen.dateTime,"Party ID","Party Name","Description","Amount","Due Date"],
  labelDbKeys:["slNo","invoiceId","invoiceIssueDate","invoicePartyId","invoicePartyName","invoiceDesc","invoiceTotalAmount","invoicePaymentDueDate"],
  dbPath:"admin/accounts/unpaidInvoices",
  filterSchemeMap:{
    filterNames:["Date","Party ID","Store Branch Name"],
    filterDbKeys:["docCreationDate","invoicePartyIds","invoicePartyNames"],
    filterInputKeys:[["fromDate","toDate"],["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["From","To"],["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["date","date"],["text"],["text","text"]],
  },
  btnLabels:["Paid","Delete"],
  btnAlertLevels:["high","low","high"],
  valueSeqDbKeys:unpaidInvoiceDataSeqKeys
}

export const viewSignUpRequestAdminSchemeMap={
  labelNames:["Sl No","Store Name","Request Date","Contact Person","Contact No","Email"],
  labelDbKeys:["slNo","storeBrandName","docCreationDate","contactPersonName","contactNo","email"],
  dbPath:"admin/accounts/unpaidInvoices",
  filterSchemeMap:{
    filterNames:["Date","Party ID","Store Branch Name"],
    filterDbKeys:["docCreationDate","invoicePartyIds","invoicePartyNames"],
    filterInputKeys:[["fromDate","toDate"],["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["From","To"],["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["date","date"],["text"],["text","text"]],
  },
  stripValGridColsScheme:"grid-cols-cust-6c",
  btnLabels:["Approve","Reject"],
  btnAlertLevels:["high","low","high"],
  listTopic:"signUpRequest"
}

const viewUnpaidInvoicesAdminSchemeMap={
  labelNames:["Sl No","Invoice ID",strMap.gen.dateTime,"Party ID","Party Name","Description","Amount","Due Date"],
  labelDbKeys:["slNo","invoiceId","invoiceIssueDate","invoicePartyId","invoicePartyName","invoiceDesc","invoiceTotalAmount","invoicePaymentDueDate"],
  dbPath:"admin/accounts/unpaidInvoices",
  filterSchemeMap:{
    filterNames:["Party ID","Store Branch Name"],
    filterDbKeys:["invoicePartyIds","invoicePartyNames"],
    filterInputKeys:[["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["text"],["text","text"]],
  },
  btnLabels:["Paid","View"],
  btnAlertLevels:["high","low"],
  valueSeqDbKeys:unpaidInvoiceDataSeqKeys
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}




const viewTransactionsSchemeMap={
  labelNames:["Sl No","Transaction ID",strMap.gen.dateTime,"Party ID","Party Name","Description","Cost","Payment Mode"],
  labelDbKeys:["slNo","transactionId","transactionDate","transactionPartyId","transactionPartyName","transactionDesc","transactionAmount","transactionPaymentMode"],
  excludedDbDataIndex:[5],
  dbPath:"admin/accounts/transactions",
  filterSchemeMap:{
    filterNames:["Customer ID","Customer Name"],
    filterDbKeys:["transactionPartyIds","transactionPartyNames"],
    filterInputKeys:[["partyId"],["partyName"]],
    inputPlaceholderNames:[["Enter ID"],["Enter Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["text"],["text","text"]],
  },
  btnLabels:[""],
  valueSeqDbKeys:["transactionId","transactionDate","transactionPartyId","transactionPartyName","transactionDesc","transactionAmount","transactionPaymentMode","transactionCurrency"]
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],
}

const viewTransactionsAdminSchemeMap={
  labelNames:["Sl No","Transaction ID",strMap.gen.dateTime,"Party ID","Party Name","Description","Cost","Payment Mode"],
  labelDbKeys:["slNo","transactionId","transactionDate","transactionPartyId","transactionPartyName","transactionDesc","transactionAmount","transactionPaymentMode"],
  excludedDbDataIndex:[5],
  dbPath:"admin/accounts/transactions",
  filterSchemeMap:{
    filterNames:["Party ID","Store Branch Name"],
    filterDbKeys:["transactionPartyIds","transactionPartyNames"],
    filterInputKeys:[["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["text"],["text","text"]],
  },
  btnLabels:[""],
  valueSeqDbKeys:["transactionId","transactionDate","transactionPartyId","transactionPartyName","transactionDesc","transactionAmount","transactionPaymentMode"]
}



const viewChartSchemeMap={
  chartTopics:["Job","Time","Customer","Employee"],
  filCategories:[["Year","Job","Time"],["Year","Job","Time"],[],[]],
  filValues:[[[...avlYears,"All"],[...jobTypes,"All"],["Hour","Day","Month"]],
  [[...avlYears,"All"],[...jobTypes,"All"],["Avg Job Time","Avg Wait Time","Deviation"],["Month","Year"]],
  [],[]],
  chartLabels:[[[...dayHours],
  [...daysShort],
  [...monthsShort]],
  [[...monthsShort],[...avlYears,"All"]]],
  axisLabels:[[["Hour of the Day","Day of the Week","Month"],["No of Jobs Done"]],
  [["Avg Job Time (Mins)","Avg Wait Time (Mins)","Deviation from Specified Time (Mins)"],["Month","Year"]],
  [[],[]],[[],[]]],
  filRefDbKeys:[["orderCompletionDateTime","orderJobName","orderCompletionDateTime"],["orderCompletionDateTime","orderJobName","orderCompletionDateTime"],[],[]],
  valueSeqDbKeys:["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
  "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  "orderEstdCompletionTime","orderCost","orderPaymentMode"]
}

const viewQRExcelGenSchemeMap={
  qrCounts:[50,100,200]
}


const viewChart2SchemeMap={
  chartTopics:["Time","Employee"],
  filCategories:[["Year","Job Name","Time Aspect","Time"],["Year","Employee Name","Details","Time"]],
  filValues:[[[...avlYears,"All"],[...jobTypes,"All"],["Month","Year"],["Avg Job Time","Avg Wait Time","Deviation"]],
  [[...avlYears,"All"],["DB Fetch"],["Month","Year"],["Avg Job Time","No of Jobs Completed","Deviation"]]],
  chartLabels:[[[...monthsShort],[...avlYears]],[[...monthsShort],[...avlYears]]],
  axisLabels:[[["Month","Year"],["Avg Job Time (Mins)","Avg Wait Time (Mins)","Deviation (Mins)"]],
  [["Month","Year"],["Avg Job Time (Mins)","No of Jobs Completed","Deviation (Mins)"]]],
  filRefDbKeys:[["orderCompletionDateTime","orderJobName","orderCompletionDateTime",["orderJobStartToJobEndDuration","orderAcceptToJobStartDuration","orderEstdCompletionTime"]],
  ["orderCompletionDateTime","orderResponsibleEmployeeUid","orderCompletionDateTime",["orderJobStartToJobEndDuration","orderId","orderEstdCompletionTime"]]],
  valueSeqDbKeys:["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
  "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  "orderEstdCompletionTime","orderCost","orderPaymentMode"]
}





const viewChart3SchemeMap={
  chartTopics:["Job"],
  filCategories:[["Year","Job","Time"]],
  filValues:[[[...avlYears],[...jobTypes,"All"],["Hour","Day","Month","Date Range"]]],
  dbKeyMainValues:["year",[...jobTypes,"All"]],
  chartLabels:[[[...dayHours],
  [...daysShort],
  [...monthsShort],[...daysOfTheYear]]],
  axisLabels:[[["Hour of the Day","Day of the Week","Month","Days"],["No of Jobs Done"]]],
  filRefDbKeys:[["orderCompletionDateTime","orderJobName","orderCompletionDateTime","orderCompletionDateTime"]],
  // valueSeqDbKeys:["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  // "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
  // "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  // "orderEstdCompletionTime","orderCost","orderPaymentMode"]
  valueSeqDbKeys:[["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
  "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  "orderEstdCompletionTime","orderCost","orderPaymentMode"],["skateId","skateBrandName","skateModelName","skateSizeType","skateSize","skateWidth","skateHollowRadius"
  ,"skateProfileFamily","skateProfileRadius","skateSharpeningType","skateBlade1Type","skateBlade2Type","skateBlade3Type"]]
}


// const viewChart4SchemeMap={
//   chartTopics:["Time","Employee","Skate"],
//   filCategories:[["Year","Job Name","Time Aspect","Time"],["Year","Employee Name","Details","Time"],["Year","Job Name","Skate Spec","Time"]],
//   filValues:[[[...avlYears],[...jobTypes,"All"],["Month","Year","Date Range"],["Avg Job Time","Avg Wait Time","Deviation"]],
//   [[...avlYears],["DB Fetch"],["Month","Year","Date Range"],["Avg Job Time","No of Jobs Completed","Deviation"]], [[...avlYears],["Hollow Radius"],["Month","Year","Date Range"],["5/8\"","9/16\""]]],
//   chartLabels:[[[...monthsShort],[...avlYears],[...generateIncrementNumArray(1,maxDaysInAYear)]],
//   [[...monthsShort],[...avlYears],[...generateIncrementNumArray(1,maxDaysInAYear)]],
//   [[...monthsShort],[...avlYears],[...generateIncrementNumArray(1,maxDaysInAYear)]]],
//   axisLabels:[[["Month","Year","Days of the Year"],["Avg Job Time (Mins)","Avg Wait Time (Mins)","Deviation (Mins)"]],
//   [["Month","Year","Days of the Year"],[...jobTypes,"All"]],
//   [["Month","Year","Days of the Year"],[...jobTypes,"All"]]],
//   filRefDbKeys:[["orderCompletionDateTime","","orderCompletionDateTime",["orderJobStartToJobEndDuration","orderAcceptToJobStartDuration","orderEstdCompletionTime"]],
//   ["orderCompletionDateTime","orderResponsibleEmployeeUid","orderCompletionDateTime",["orderJobStartToJobEndDuration","orderId","orderEstdCompletionTime"]],
//   ["orderCompletionDateTime","orderJobName","orderCompletionDateTime",["skateHollowRadius"]]],
//   filRefDbKeyComputations:[["orderCompletionDateTime","orderJobName","orderCompletionDateTime",["orderJobStartToJobEndDuration","orderAcceptToJobStartDuration","orderEstdCompletionTime"]],
//   ["orderCompletionDateTime","orderResponsibleEmployeeUid","orderCompletionDateTime",["orderJobStartToJobEndDuration","orderId","orderEstdCompletionTime"]],
//   ["orderCompletionDateTime","orderJobName","orderCompletionDateTime",["count"]]],
//   valueSeqDbKeys:[["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
//   "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
//   "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
//   "orderEstdCompletionTime","orderCost","orderPaymentMode"],["skateId","skateBrandName","skateModelName","skateSizeType","skateSize","skateWidth","skateHollowRadius"
//   ,"skateProfileFamily","skateProfileRadius","skateSharpeningType","skateBlade1Type","skateBlade2Type","skateBlade3Type"]]
// }



const viewChart4SchemeMap={
  chartTopics:["Job","Employee"],
  filCategories:[["Year","Job Name","Time Aspect","Time"],["Year","Employee Name","Details","Time"]],
  filValues:[[[...avlYears],[...jobTypes,"All"],["Month","Date Range"],["No of Jobs Completed","Avg Job Time","Avg Wait Time","Deviation"]],
  [[...avlYears],["DB Fetch"],["Month","Date Range"],["No of Jobs Completed","Avg Job Time","Deviation"]]],
  chartLabels:[[[...monthsShort],[...daysOfTheYear]],[[...monthsShort],[...daysOfTheYear]]],
  axisLabels:[[["Month","Days of the Year"],["Count","Avg Time (Mins)","Avg Time (Mins)","Avg Time(Mins)"]],
  [["Month","Days of the Year"],["No of Jobs Completed","Avg Job Time (Mins)","Deviation (Mins)"]]],
  filRefDbKeys:[["orderCompletionDateTime","orderJobName","orderCompletionDateTime",["orderId","orderJobStartToJobEndDuration","orderAcceptToJobStartDuration",["orderJobStartToJobEndDuration","orderEstdCompletionTime"]]],
  ["orderCompletionDateTime","orderResponsibleEmployeeUid","orderCompletionDateTime",["orderId","orderJobStartToJobEndDuration",["orderJobStartToJobEndDuration","orderEstdCompletionTime"]]]],
  // valueSeqDbKeys:["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  // "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
  // "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  // "orderEstdCompletionTime","orderCost","orderPaymentMode"]
    valueSeqDbKeys:[["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
  "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  "orderEstdCompletionTime","orderCost","orderPaymentMode"],["skateId","skateBrandName","skateModelName","skateSizeType","skateSize","skateWidth","skateHollowRadius"
  ,"skateProfileFamily","skateProfileRadius","skateSharpeningType","skateBlade1Type","skateBlade2Type","skateBlade3Type"]]
}

export const viewChart5SchemeMap={
  chartTopics:["Skate"],
  filCategories:[["Year","Skate Spec","Value","Time"]],
  filValues:[[[...avlYears],["Hollow Radius","Profile Radius"],["Month","Date Range"],["Corresponding"]]],
  dbKeyMainValues:["year",["Hollow Radius","Profile Radius"]],
  chartLabels:[[[...monthsShort],[...daysOfTheYear]]],
  axisLabels:[[["Month","Days of the Year"],["No of Skates"]]],
  filRefDbKeys:[["orderCompletionDateTime",null,"orderCompletionDateTime",[["skateHollowRadius"],["skateProfileFamily","skateProfileRadius"]]]],
  filRefDbKeyComputations:[
  ["orderCompletionDateTime","orderJobName","orderCompletionDateTime",["count"]]],
  valueSeqDbKeys:[["orderId","orderStartDateTime","orderCompletionDateTime","orderCustomerId","orderCustomerName",
  "orderCustomerUid","orderJobName","orderItemId","orderJobItemDesc","orderAcceptToJobStartDuration",
  "orderJobStartToJobEndDuration","orderResponsibleEmployeeName","orderResponsibleEmployeeUid",
  "orderEstdCompletionTime","orderCost","orderPaymentMode"],["skateId","skateBrandName","skateModelName","skateSizeType","skateSize","skateWidth","skateHollowRadius"
  ,"skateProfileFamily","skateProfileRadius","skateSharpeningType","skateBlade1Type","skateBlade2Type","skateBlade3Type"]]
}







const viewInvoicesSchemeMap={
  labelNames:["Sl No","Invoice ID",strMap.gen.dateTime,"Party ID","Party Name","Description","Amount","Due Date"],
  labelDbKeys:["slNo","invoiceId","invoiceIssueDate","invoicePartyId","invoicePartyName","invoiceDesc","invoiceTotalAmount","invoicePaymentDueDate"],
  dbPath:"admin/accounts/unpaidInvoices",
  filterSchemeMap:{
    filterNames:["Date","Party ID","Store Branch Name"],
    filterDbKeys:["docCreationDate","invoicePartyIds","invoicePartyNames"],
    filterInputKeys:[["fromDate","toDate"],["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["From","To"],["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["date","date"],["text"],["text","text"]],
  },
  btnLabels:["Paid","Delete"],
  valueSeqDbKeys:unpaidInvoiceDataSeqKeys
 
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}

const viewUnpaidInvoicesStoresSchemeMap={
  labelNames:["Sl No","Invoice ID",strMap.gen.dateTime,"Description","Jobs Charged","Amount","Due Date"],
  labelDbKeys:["slNo","invoiceId","invoiceIssueDate","invoiceDesc","invoiceJobCount","invoiceTotalAmount","invoicePaymentDueDate"],
  dbPath:"admin/accounts/unpaidInvoices",
  filterSchemeMap:{
    filterNames:["Date","Party ID","Store Branch Name"],
    filterDbKeys:["docCreationDate","invoicePartyIds","invoicePartyNames"],
    filterInputKeys:[["fromDate","toDate"],["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["From","To"],["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["date","date"],["text"],["text","text"]],
  },
  valueSeqDbKeys:unpaidInvoiceDataSeqKeys,
  btnLabels:["View"],
  btnAlertLevels:["low"]
}




const viewPaidInvoicesAdminSchemeMap={
  labelNames:["Sl No","Invoice ID","Issue Date","Party ID","Party Name","Description","Amount", "Payment Date"],
  labelDbKeys:["slNo","invoiceId","invoiceIssueDate","invoicePartyId","invoicePartyName","invoiceDesc","invoiceTotalAmount","invoicePaymentDate"],
  dbPath:"admin/accounts/paidInvoices",
  filterSchemeMap:{
    filterNames:["Party ID","Store Branch Name"],
    filterDbKeys:["invoicePartyIds","invoicePartyNames"],
    filterInputKeys:[["partyId"],["storeBrandName","storeBranchName"]],
    inputPlaceholderNames:[["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["text"],["text","text"]],
  },
  btnLabels:["View"],
  valueSeqDbKeys:paidInvoiceDataSeqKeys
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}

const viewPaidInvoicesSchemeMap={
  labelNames:["Sl No","Invoice ID","Issue Date","Party ID","Party Name","Description","Amount", "Payment Date"],
  labelDbKeys:["slNo","invoiceId","invoiceIssueDate","invoicePartyId","invoicePartyName","invoiceDesc","invoiceTotalAmount","invoicePaymentDate"],
  dbPath:"admin/accounts/paidInvoices",
  filterSchemeMap:{
    filterNames:["Date"],
    filterDbKeys:["docCreationDate"],
    filterInputKeys:[["fromDate","toDate"]],
    inputPlaceholderNames:[["From","To"],["ID No"],["Store Brand Name","Branch Name"]],
    filterSeqIndices:[1,2,3],
    filterInputTypes:[["date","date"]],
  },
  btnLabels:[""],
  valueSeqDbKeys:paidInvoiceDataSeqKeys
  //   labelNames:[strMap.customer.name,strMap.customer.IdNo,strMap.ppdCard.schemeName,strMap.ppdCard.schemeIdNo,strMap.transaction.amountToCharge],
  // labelDbKeys:["prepaidCardRequestCustomerName","prepaidCardRequestCustomerId","prepaidCardSchemeName","prepaidCardSchemeId","prepaidCardCost"],

}


const editInvoiceAmountInputSchemeMap={
  inputNames:["invoiceJobCount",
  "invoiceDiscount","invoiceAmount"],
  inputLabels:["Jobs","Discount",
  "Invoice Amount"],
  elemTypes:["standard","standard","standard"],
  inputTypes:["number","number","number"],
  editStatus:[false,true,true],
  selectOptions:[[],[],[]],
  mainKey:"inputNames",
  dbPath:"storeBranches/${userUid}/prepaidCardSchemes/",
  opType:"edit",
  operationType:"Doc Edition",
  inputDomain:"Edit Invoice Amount"
}

// const viewBalancesSchemeMap={
//   labelNames:["Sl No","Store ID","Store Name","Latest Bill Date","Unbilled Balance","Billed Balance","Due Balance","Total Balance Due"],
//   labelDbKeys:["slNo","partyId","partyName","latestBillDate","unbilledBalance","billedBalance","dueBalance","totalDueBalance"],
//   dbPath:"admin/accounts/pbalances",
//   filterSchemeMap:{
//     filterNames:["Party ID","Store Branch Name"],
//     filterDbKeys:["partyIds","partyNames"],
//     filterInputKeys:[["partyId"],["storeBrandName","storeBranchName"]],
//     filterSeqIndices:[2,3],
//     filterInputTypes:[["text"],["text","text"]],
//   },
//   btnLabels:["Invoice","Clear"]
// }

export const skateUnitCardSchemeMap={
labelNames:[],
labelTitleDbNotations:["skateBrandName","skateModelName"],
labelBodyDbNotations:[["skateQrId"],["skateProfileRadius","skateHollowRadius","skateSize","skateWidth"],["skateAssignedMemberName"],["skateSizeType"]],
labelBodyColPositions:[[1,2],[1,2],[1,2],[2,3]],
labelBodyRowPositions:[[2,3],[3,4],[4,5],[4,5]],
}


const viewDashboardBlankSchemeMap={
  viewSectionNames:[],
  fnBtnNames:[],
  fnViews:[]
}

const statSchemeMapStoreBranch={
  statTitles:["Employees","Time","Earnings"],
  notifications:["A","B"],
  statNames:[["Engaged","Idle","Absent","Total"],["Estd Wait Time"],["Cash/Card","Total"]],
}

const statSchemeMapStoreBrand={
  statTitles:["Branches"],
  statNames:[["Total"]]
}

const statSchemeMapSuperAdmin={
  statTitles:["Accounts"],
  notifications:["A"],
  statNames:[["Institutes","Students","Total"]],
}


function arrangeNumArrayInAscendingOrder(numArray){

  return numArray.sort((a,b)=>a-b)

}



const getCurrencySymbol=(currencyKey)=>{
  switch(currencyKey){
    case "USD":
      return "$"
    case "CAD":
      return "$"
    case "GBP":
      return "£"
    case "INR":
      return "₹"
  }
}






function getDate(timestampString){
  const seconds = timestampString.split(",")[0]
  const milliseconds = seconds * 1000;
  const date = new Date(milliseconds)
  return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

function getDateStringFromMillis(dateTimeInMillis){
  const date = new Date(dateTimeInMillis)
  return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`; // - Canadian Standard

  //return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`; - Indian Standard
}

function getDateInputStringFromMillis(dateTimeInMillis){
  const date = new Date(dateTimeInMillis)
  console.log(`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`)
  return `${date.getFullYear()}-${to2Digits(date.getMonth()+1)}-${to2Digits(date.getDate())}`;
}


function getDecimalValue(decComp,count){

  if(decComp.toString().length<count){
    const zerosToBeAdded = count-decComp.toString().length
    console.log(zerosToBeAdded)
    let zeroStrings= "";
    
    for(let x=0;x<zerosToBeAdded;x++){
      zeroStrings+='0'
    }
    console.log(`${decComp.toString}${zeroStrings}`)
    return `${decComp.toString()}${zeroStrings}`
  }else{

    return decComp.toString().substring(0,count);
  }
}

function getDigitSerial(slNo, noOfDigits) {
  const slSize = slNo.toString().length;
  const reqSize = noOfDigits;
  const digitDiff = reqSize - slSize;

  if (digitDiff >= 0) {
    let prefix = "";

    for (let x = 0; x < digitDiff; x++) {
      prefix += "0";
    }
    return `${prefix}${slNo}`;
  } else {
    return slNo.toString();
  }
}


function getStoreLabelValue(labelName,labelValue,storeSettingsObj){
  console.log(`${labelName} and type${typeof labelValue} converted to ${parseInt(labelValue)*1000}`)

  
  //Specific Preference - By Label Name
  if(labelName=="Duration"){
    console.log("runTime=",labelValue)
    console.log(labelValue.toDate().getTime())
    return labelValue.toDate().getTime();
  }else if(labelName=="Job Status" || labelName=="Order Status"){
    return getOrderStatus(labelValue)
  }else if(labelName.includes("Cost") || labelName.includes("Balance") || labelName.includes("Amount")){
      return `${getCurrencySymbol(storeSettingsObj.storeBranchCurrency)}${labelValue}`
  }else if(labelName.includes("Date") || labelName.includes("Time") || labelName.includes("Date/Time")){
    if(typeof labelValue=="object"){
        // const date = labelValue.toDate()
        // return date.toDateString()
       
        return getDateStringFromMillis(labelValue.seconds*1000) 

  }else if(typeof labelValue=="string"){
    return getDateStringFromMillis(parseInt(labelValue)*1000) 
    //const date = new Date(parseInt(labelValue)*1000);
   
  }else if(typeof labelValue=="number"){
      // const date = new Date(labelValue*1000);
      // return date.toDateString()
      return getDateStringFromMillis(labelValue*1000)
  }
 }

  if(skateDetailsSchemeMap.labelNames.includes(labelName)){

    const index = skateDetailsSchemeMap.labelNames.indexOf(labelName);
    return labelValue.split("^")[index]
  }
    

  return labelValue

}


function getOrderStatus(dbStatus){
        
  switch(dbStatus){
  case "accepted": return "Accepted"
  case "jobStarted": return "Job Started"
  case "pendingPayment":return "Pending Payment"
  case "pendingCollection":return "Pending Collection"
  default:return "NA"
  }
  
}

function getStoreLabelValueAdmin(labelName,labelValue,currency){

  if(labelName=="Amount"){
    console.log("labelvalue for amount is",labelValue)
  }

  console.log(labelName,labelValue,typeof labelValue)


  if(labelName.includes("Cost") || labelName.includes("Balance") || labelName=="Amount" ){
      return `${getCurrencySymbol(currency)}${parseFloat(labelValue).toFixed(2)}`
  }else if(labelName.includes("Date") || labelName.includes("Time")){
    if(typeof labelValue=="object"){
        //const date = labelValue.toDate()
       // return date.toDateString()
       const date = labelValue.seconds
        return getDateStringFromMillis(date*1000) 
    }else if(typeof labelValue=="number"){
        // const date = new Date(labelValue*1000);
        // return date.toDateString()
        return getDateStringFromMillis(parseInt(labelValue)*1000)
    }else if(typeof labelValue=="string"){
      if(labelValue=="NA"){
        return labelValue;
      }else{
        //const date = new Date(parseInt(labelValue)*1000);
        return getDateStringFromMillis(parseInt(labelValue)*1000)
      }
      
  }
  }

  return labelValue

}

//Function to display value in settings section
function getSettingsLabelValue(labelName,labelValue){
  console.log(labelName)
  console.log(labelValue)
  if(labelName.includes("Time")){
    const utcToLocalTimeInSecs = getLocalTimeFromUTCInSecs(labelValue)
    // alert(getTimeStringFromMillis(utcToLocalTimeInSecs*1000))
   return getTimeStringFromMillis(utcToLocalTimeInSecs*1000)
  } else if(labelName.includes("Duration")){
    return labelValue/60
     
   }else {
    return labelValue
  }
}

export function getSettingsLabelValueForDualInput(labelName,labelValue,inpNames){

}

function getFSettingsLabelValue(labelName,labelValue){
  console.log(labelValue)
  if(labelName.includes("Time")){

   return getTimeStringFromMillis(labelValue*1000)
    
  } else if(labelName.includes("Duration")){
 
    return labelValue/60
     
   }else {
    return labelValue
  }
}

export function getUTCSecondsFromTimeString(timeString) {
  console.log(timeString)

  const timeInSecs =  parseFloat(timeString.split(":")[0]) * 60 * 60 +
  parseFloat(timeString.split(":")[1]) * 60

  const offsetInMins = new Date().getTimezoneOffset();
  const offsetInSecs = offsetInMins * 60;

  const timeWithOffsetInSecs = timeInSecs + offsetInSecs

  return getFinalDayTimeInSecs(timeWithOffsetInSecs)
  
}

export function getLocalTimeFromUTCInSecs(utcTimeInSecs){

  const localTimeToUTCOffsetInSecs = new Date().getTimezoneOffset() * 60
  const secsInOneDay = 60 * 60 * 24

  const localToUTCOffsetDifferenceCalcInSecs = utcTimeInSecs - localTimeToUTCOffsetInSecs;

  console.log(utcTimeInSecs,localTimeToUTCOffsetInSecs,localToUTCOffsetDifferenceCalcInSecs,getFinalDayTimeInSecs(localToUTCOffsetDifferenceCalcInSecs))

  return getFinalDayTimeInSecs(localToUTCOffsetDifferenceCalcInSecs)

}



// export function getLocalTimeStringFromUTCSeconds(timeString) {
//   console.log(timeString)

//   return (
//     parseFloat(timeString.split(":")[0]) * 60 * 60 +
//     parseFloat(timeString.split(":")[1]) * 60
//   );
// }



//This function finds the timeInSecs which can be converted to day time string. This function is required because it takes into consideration when
//difference between local and utc time is negetive (western zone) or going beyond the total secs in a day (eastern zone)
export function getFinalDayTimeInSecs(localTimeToUTCOffsetInSecs){
  const secsInOneDay = 60 * 60 * 24

  if(localTimeToUTCOffsetInSecs < 0){ // happens when its a western time zone as since the difference can be negetive 
    return secsInOneDay - Math.abs(localTimeToUTCOffsetInSecs)
  }else if(localTimeToUTCOffsetInSecs > secsInOneDay){// happens when its a eastern time zone as since the difference can go beyond the totalSecsInADay 
    return localTimeToUTCOffsetInSecs - secsInOneDay
  }else{
    return localTimeToUTCOffsetInSecs
  }

}


function getTimeStringFromMillis(dateTimeInMillis){
  console.log(dateTimeInMillis)

  const dateTime = new Date(dateTimeInMillis)

  return `${to2Digits(dateTime.getUTCHours())}:${to2Digits(dateTime.getUTCMinutes())}`
}

function getTimeLabel(value,topic){
  switch(topic){
    case "Day":return daysShort[value]
    case "Month":return monthsShort[value]
    case "Hour":return value
    case "Date Range":return daysOfTheYear[value]
  }
}

function getMonthYearShort(dateObj,seperator){

  seperator = seperator==undefined ? "" : seperator
  
  const monthString = monthsShort[dateObj.getMonth()]
  const fullYearString = dateObj.getFullYear();

  return `${monthString}${seperator}${fullYearString.toString().substring(2)}`

}



export function getMonthShort(dateObj){
  return monthsShort[dateObj.getMonth()]
}

export function getMonth(dateObj){
  return months[dateObj.getMonth()]
}

export function getAvlMonthsForTheYear(year){
  const curYear = new Date().getFullYear()

  if(year>curYear){
    return
  }

  if(curYear == year){
    const curMonthNo = new Date().getMonth()
  
    return months.slice(0,curMonthNo+1) //as month starts from 0
  }else if(curYear > year){
    return months
  }else{
    return []
  }

}

export function getYearsFromStartYear(startYear){

  const curYear = new Date().getFullYear()
  const range = curYear-startYear

  const rYears = []

  for(let x=range;x>=0;x--){
    const year = startYear+x;
    rYears.push(year)
  }

  return rYears

}


function getDayOfMonth(dateObj){
  
}

function getDateObjFromDayOfYearAndYear(dayOfYear,year){
  const day1Date = new Date(year,0,0);
  console.log(day1Date)
  return new Date(day1Date.getTime() + dayOfYear*1000*60*60*24)
}

function getMonthYear(dateObj){
  
  const monthString = months[dateObj.getMonth()]
  const fullYearString = dateObj.getFullYear()

  return `${monthString}${fullYearString.toString()}`

}


export function getMonthYearAsLabel(monthYearString){
  const yearFirstCharIndex = (monthYearString.length-1)-3
  const yearString = monthYearString.slice(yearFirstCharIndex)
  const monthString = monthYearString.slice(0,yearFirstCharIndex)
  return `${monthString} ${yearString}`
}


function getOrderDetailsLabelValue(labelName,labelValue,dbData){

  console.log(labelName,labelValue)

if(labelName.includes("Cost")){
    return `${getCurrencySymbol(dbData.orderCurrency)}${labelValue}`
}else if(labelName.includes("Date") || labelName.includes("Time")){
  if(typeof labelValue=="object"){
      const milliseconds = labelValue.seconds * 1000;
      const date = new Date(milliseconds)
      return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }else if(typeof labelValue=="string"){
    const milliseconds = parseInt(labelValue) * 1000;
    const date = new Date(milliseconds)
    return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }
}else if(labelName.includes("Duration")){
  return labelValue!=null?`${parseFloat(labelValue).toFixed(2)} mins`:"NA"
}else if(labelName=="Order Status"){
  return decodeOrderStatus(labelValue)
}

return labelValue


}


export function getOrderDetailsLabelValue2(valueDisplayType,labelValue,dbData){

console.log(valueDisplayType,labelValue)
if(valueDisplayType == "currency"){
    return `${getCurrencySymbol(dbData.orderCurrency)}${labelValue}`
}else if(valueDisplayType=="date" || valueDisplayType == "dateTime" || valueDisplayType == "time"){
  if(typeof labelValue=="object"){
      const milliseconds = labelValue.seconds * 1000;
      const date = new Date(milliseconds)
      return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }else if(typeof labelValue=="string"){
    const milliseconds = parseInt(labelValue) * 1000;
    const date = new Date(milliseconds)
    return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }
}else if(valueDisplayType=="timeMins"){
  return labelValue!=null?`${parseFloat(labelValue).toFixed(2)} mins`:"NA"
}else if(valueDisplayType=="timeMillis"){
  return labelValue.toDate().getTime();
}else if(valueDisplayType=="orderStatus"){
  return decodeOrderStatus(labelValue)
}else if(skateDetailsSchemeMap.dbKeys.includes(valueDisplayType)){
    const index = skateDetailsSchemeMap.dbKeys.indexOf(valueDisplayType);
    return labelValue.split("^")[index]
  }

return labelValue
}


function getInvoiceAmountFromJobCount(jobCount,partyTarriffSeqString){

  const blockComps = partyTarriffSeqString.split("^"); //Array of each tarrif comp (jobs in the tarriff slab x rate - 3x0.03) ex [3x0.02,4x0.1]
  let invoiceAmount=0;

  console.log(blockComps)

  if(blockComps.length==0){//This will mean that there is no rate/job slabs and the rate is standard for any no of jobs
    invoiceAmount = jobCount * parseFloat(blockComps[0]);

  }else{ //Here, rate varies for a job slab
    let remJobCount  = jobCount; //Jobs for which the invoice is billed
    blockComps.every((comp,i)=>{
      const tArr=[]
      const dataObj = getJobCountRangeAndCostStrMap(blockComps,comp,i,remJobCount)
      remJobCount-=dataObj.unbilledJobCount
      if(remJobCount>=0){
        console.log(dataObj.jobCountRangeTotal.toFixed(2));
        invoiceAmount += parseFloat(dataObj.jobCountRangeTotal.toFixed(2))
        return true
      }else{
        return false
      }
    })

  }

  return invoiceAmount
}


function generateIncrementNumArray(firstNum,lastNum){
  const rArray=[];

  for(let x=firstNum;x<=lastNum;x++){
    rArray.push(x);
  }

  return rArray

}


function getDayOfTheYear(date){
    return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));
}


function getJobCountRangeAndCostStrMap(blockComps,comp,i,remainderJobCount){
  //returns a map which gives a) the range of the tarriff block, b)tarriff of the block 
  //and c) string of the range to print on the invoice

    console.log(blockComps,comp,i,remainderJobCount)

      const compComps = comp.split("x")
      const rMap={}
      rMap["jobCountRangeStr"]= ""
      rMap["unbilledJobCount"]= ""
      rMap["jobCountRangeTariff"]=""
      rMap["jobCountRangeTotal"]=""
  
      //precComps = Previous Block Comp
      if(i==0 && blockComps.length==1){ //first and only components
        rMap.jobCountRangeStr = `jobs 1 and above`
        rMap.unbilledJobCount = remainderJobCount;
        rMap.jobCountRangeTariff = compComps[1]
        rMap.jobCountRangeTotal = parseInt(rMap.unbilledJobCount)*parseFloat(compComps[1]);
      }else if(i==0 && blockComps.length>1){ // first component of a non-singular array
        rMap.jobCountRangeStr = `jobs 1 to ${compComps[0]}`
        rMap.unbilledJobCount = remainderJobCount<compComps[0]?remainderJobCount:compComps[0]
        rMap.jobCountRangeTariff = compComps[1]
        rMap.jobCountRangeTotal = parseInt(rMap.unbilledJobCount)*parseFloat(compComps[1]);
      }else if(i>0 && i==blockComps.length-1){ //last component of a non-singular array
        const precComps = blockComps[i-1].split("x");
        rMap.jobCountRangeStr = `jobs ${parseInt(precComps[0])+1} and above`
        rMap.unbilledJobCount = remainderJobCount;//(dMap.invoiceJobCount-parseInt(precComps[0]))
        rMap.jobCountRangeTariff = compComps[1]
        rMap.jobCountRangeTotal = parseInt(rMap.unbilledJobCount)*parseFloat(compComps[1]);
      }else{ //middle component i.e. i>0 && i!=blockComps.length-1
        const precComps = blockComps[i-1].split("x");
        const blkJobCount = (parseInt(compComps[0])-parseInt(precComps[0]))
        rMap.jobCountRangeStr = `jobs ${parseInt(precComps[0])+1} to ${compComps[0]}`
        rMap.unbilledJobCount = remainderJobCount<blkJobCount?remainderJobCount:blkJobCount
      // rMap.unbilledJobCount = (parseInt(compComps[0])-parseInt(precComps[0]))
        rMap.jobCountRangeTariff = compComps[1]
        rMap.jobCountRangeTotal = rMap.unbilledJobCount*parseFloat(compComps[1]);
      }
      console.log (rMap)
      return rMap;
}




function decodeOrderStatus(orderStatus){

  switch(orderStatus){
    case "accepted":return "Accepted"
    case "jobStarted":return "Job Started"
    case "pendingPayment":return "Pending Customer Payment"
    case "pendingCollection":return "Pending Customer Collection"
    default:return"NA"
  }

}

const pageSizes={
  "LETTER":{
    width:215.6,
    height:279.3
  },
  "A4":{
    width:210,
    height:297
  }
}

// settings:{
//   estimatedWaitTime:10,
//   storeBranchStartTime:"0900",
//   storeBranchEndTime:"1700",
//   storeBranchLocation:"00",
//   storeBranchUnitNo:"",
//   storeBranchStreetName:"",
//   storeBranchCityName:"",
//   storeBranchProvinceName:"",
//   storeBranchCountryName:"",
//   storeBranchPostalCode:"",
//   storeContactNo:""
// },


function getBase64ImgFromImageComp(imageComp) {

  const height = 500;
  const width = 500;

  const imgElem = document.createElement('img');
  imgElem.src = imageComp
  imgElem.style.width=width
  imgElem.style.height=height
  imgElem.height=100;
  imgElem.width=100;

  console.log(imgElem)

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  console.log(canvas)
  canvas.width = width
  canvas.height = height
  canvas.style.width = "25px";
  canvas.style.height = "25px";
 
  ctx.drawImage(imgElem,0,0,width ,height)
  // console.log(canvas.toDataURL("image/png"))

  return canvas.toDataURL("image/png");
}

function convertMapOfMapsToMapCollection(nestedMap){
  const keys = Object.keys(nestedMap);
  const rArr=[]

  keys.forEach((key)=>{
      rArr.push(nestedMap[key])
  })

  return rArr
}


function getCloneObj(obj){
  return JSON.parse(JSON.stringify(obj))
}


function getSerialNo(serialString) {
    let lS = serialString;
  
    let fS = lS.split("");
  
    for (let x = 0; x < lS.length; x++) {
      const itIndex = lS.length - (x + 1);
  
      let reset = false;
      let increment = false;
  
      let item = lS[itIndex];
  
      const type =
        item.charCodeAt(0) >= 48 && item.charCodeAt(0) <= 57
          ? "number"
          : "alphabet";
  
      reset = type == "number" ? item == 9 : item.charCodeAt(0) == 90;
      increment = !reset;
  
      if (reset) {
        item = type == "number" ? 0 : "A";
        fS.splice(itIndex, 1, item);
      } else {
        //fS=fS.concat(lS.split("").map((it,index)=>(itIndex==index)?parseInt(it)+1:it));
  
        switch (type) {
          case "number":
            fS.splice(itIndex, 1, parseInt(item) + 1);
          case "alphabet":
            fS.splice(itIndex, 1,String.fromCharCode(item.charCodeAt(0) + 1));
        }
  
        break;
      }

    }
  
    return fS.join("");
  }


  function arraySortDescending(tArray1){
    
    //Sorting the Array as per descending Sl No

    const tMapA = {};

    for (let i = 0; i < tArray1.length - 1; i++) {
      tMapA["elem1"] = tArray1[i];

      for (let j = i + 1; j < tArray1.length; j++) {
        const elem2 = tArray1[j];

        if (tMapA["elem1"] < elem2) {
          tMapA["elem1"] = elem2;


          tArray1.splice(j, 1);


          tArray1.splice(i, 0, elem2);

        }
      }
    }

      return tArray1;
  }
  

  function arraySortAscending(tArray1){
    
    //Sorting the Array as per descending Sl No

    const tMapA = {};

    for (let i = 0; i < tArray1.length - 1; i++) {
      tMapA["elem1"] = tArray1[i];

      for (let j = i + 1; j < tArray1.length; j++) {
        const elem2 = tArray1[j];

        if (tMapA["elem1"] > elem2) {
          tMapA["elem1"] = elem2;


          tArray1.splice(j, 1);
          tArray1.splice(i, 0, elem2);
          
        }
      }
    }

      return tArray1;
  }


  function getArrayAvg(array){

    return getArraySum(array)/array.length;

  }

function getArraySum(array){
  console.log(array)
    return array.reduce((previousValue,currentValue)=>{
      return previousValue+currentValue
    })
  }
  

  function to2Digits(number){

    if(number<10){
      return "0"+number;
    }else if(number>=10 && number <100){
      return number;
    }
    
    }

    function errorValueCond(value){
      if(value==null || value==NaN || value==undefined){
        return true
      }else{
        return false
      }
    }


    function getAvlYears(){

      const todaysDate = new Date();
      const curYear = todaysDate.getFullYear();
      const avlYears = [];

      for(let x=curYear-baseYear;x>=0;x--){
        avlYears.push(baseYear+x);
      }

      return avlYears;
    }


    function toSentenceCase(string){
      const tArr=[]
      const smallString = string.toLowerCase();
      const stringComps=  smallString.split(" ");

      stringComps.forEach((comp)=>{
          const firstCompChar = comp.split("")[0];
          tArr.push(`${firstCompChar.toUpperCase()}${comp.slice(1)}`)
      })

      return tArr.join(" ");

    }




    function createInvoiceMap(coreMap,adminMap,storeMap){

      console.log("coreMap",coreMap)
      console.log("adminMap",adminMap)
      console.log("storeMap",storeMap)

          const todaysDate = new Date().getTime();
          const dueDate = todaysDate + 1000 * 60 * 60 * 24 * 15; //Days x Milliseconds per day

          //Step 1: Fetching the Store Branch Doc - For info on store

          const storeSettingsMap = storeMap.settings;
          const adminSettingsMap = adminMap.settings;

          const invoiceDesc = `Hockey Caddy QR Scans for the Month - ${getMonthYearAsLabel(coreMap.invoiceMonthYear)}`;

          const hstRate = storeSettingsMap.storeBranchProvinceName == "Ontario" ? 13 : 15;

          const invMap = {
            invoiceId:coreMap.invoiceId,
            invoicePartyId: coreMap.partyId,
            invoicePartyUid: coreMap.partyUid,
            invoicePartyName: coreMap.partyName,
            invoicePartyTariffType:coreMap.partyTariffType,
            invoicePartyTariffSeqData:coreMap.partyTariffSeq,
            invoiceJobCount: coreMap.unbilledJobCount,
            invoiceDiscount: coreMap.invoiceDiscount,
            invoiceSubTotalAmount: coreMap.invoiceSubTotalAmount,
            invoiceMonthYear:coreMap.invoiceMonthYear,
            invoiceDesc: invoiceDesc,
            invoicePartyUnitNo:storeSettingsMap.storeBranchUnitNo,
            invoicePartyStreetName:storeSettingsMap.storeBranchStreetName,
            invoicePartyCityName:storeSettingsMap.storeBranchCityName,
            invoicePartyProvinceName:storeSettingsMap.storeBranchProvinceName,
            invoicePartyCountryName:storeSettingsMap.storeBranchCountryName,
            invoicePartyPostalCode:storeSettingsMap.storeBranchPostalCode,
            invoicePartyEmail: storeMap.storeBranchEmail,
            invoicePartyContactNo: storeSettingsMap.storeBranchContactNo,
            invoicePartyContactNoComps: storeSettingsMap.storeBranchContactNoComps,
            invoicePartyContactPersonName: storeSettingsMap.storeBranchContactPersonName,
            invoicePartyContactPersonContactNo: storeSettingsMap.storeBranchContactPersonContactNo,
            invoicePartyContactPersonContactNoComps: storeSettingsMap.storeBranchContactPersonContactNoComps,
            invoiceAdminUnitNo:adminSettingsMap.unitNo,
            invoiceAdminStreetName:adminSettingsMap.streetName,
            invoiceAdminCityName:adminSettingsMap.cityName,
            invoiceAdminProvinceName:adminSettingsMap.provinceName,
            invoiceAdminCountryName:adminSettingsMap.countryName,
            invoiceAdminPostalCode:adminSettingsMap.postalCode,
            invoiceAdminEmail: adminSettingsMap.contactPersonEmail,
            invoiceAdminContactNo: adminSettingsMap.contactNo,
            invoiceAdminContactNoComps: adminSettingsMap.contactNoComps,
            invoiceAdminContactPersonName:adminSettingsMap.contactPersonName,
            invoiceAdminContactPersonEmail:adminSettingsMap.contactPersonEmail,
            invoiceAdminSalesPersonName:adminSettingsMap.salesPersonName,
            invoiceTaxDataSeq:`HST~${hstRate}`,
            invoicePaymentDueDate:new Timestamp(parseInt(dueDate/1000),0),
            invoicePaymentDate:"",
            invoiceIssueDate:Timestamp.now(),
            balanceDocId:coreMap.balanceDocId,
            invoiceStatus:"unpaid"
          };

          console.log(invMap)


          const singleTariffCond = invMap.invoicePartyTariffSeqData.split("^").length < 2 

          let totalJobCount=0;
          let subTotalAmount=0;
          let singleTariffAmount=0;
        
          if(singleTariffCond){
            singleTariffAmount = parseFloat(invMap.invoicePartyTariffSeqData.split("^")[0].split("x")[1]);
            subTotalAmount = invMap.invoiceJobCount * singleTariffAmount;

            console.log(subTotalAmount)
          }else{
               const blockComps = invMap["invoicePartyTariffSeqData"].split("^"); //Array of each tarrif comp (jobs in the tarriff slab x rate - 3x0.03) ex [3x0.02,4x0.1]
               const rows = [];
                let remJobCount  = invMap.invoiceJobCount; //Jobs for which the invoice is billed

              blockComps.every((comp,i)=>{
                const tArr=[]
                const dataObj = getJobCountRangeAndCostStrMap(blockComps,comp,i,remJobCount)
                remJobCount-=dataObj.unbilledJobCount
                if(remJobCount>=0){
                  tArr.push(`${i+1}`)
                  tArr.push(`Fee for ${dataObj.jobCountRangeStr}`)
                  tArr.push(dataObj.jobCountRangeTariff.toString())
                  tArr.push(dataObj.unbilledJobCount.toString()) //pushing to index 3
                  tArr.push(dataObj.jobCountRangeTotal.toFixed(2).toString()) //pushing to index 4
                  rows.push(tArr)
                  return true
                }else{
                  return false
                }
              })
            
              console.log(rows)
            
              rows.forEach((rowData,i)=>{
                totalJobCount+=parseInt(rowData[3]) // as job count is in index 3 of row array
                subTotalAmount+=parseFloat(rowData[4])// as amount is in index 4 of row array
              })
          }
        

          const discountAmount = subTotalAmount*invMap.invoiceDiscount/100;
          const totalAfterDiscount= subTotalAmount-discountAmount;
          invMap["invoiceTaxAmount"] = totalAfterDiscount*(getTotalTaxAmountFromSeq(invMap.invoiceTaxDataSeq))
          invMap["invoiceTotalAmount"] =  invMap["invoiceTaxAmount"]+totalAfterDiscount;
          
        
        
          //const tariffAmount = singleTariffCond ? parseFloat(rows[0][2]) : 0 


          return invMap

    }//end of createInvoiceMap


    function getTotalTaxAmountFromSeq(taxDataSeq){

      const taxComps = taxDataSeq.split("^");
    
      if(taxComps.length == 0){
        return 1
      }else if(taxComps.length ==1 ){
        return taxComps[0].split("~")[1]/100;
      }else{
        return taxComps.reduce((total,item) => total + parseInt(item.split("~")[1]))
      }
    }

   export async function generatePdf2(){

    console.log(pdfFonts)

    pdfMake.fonts = {  
      // download default Roboto font from cdnjs.com
      Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }

      const docDefinition = {

        content: [
          {columns:[

            {
              width:'70%',
              stack:[
              {image:"ucsl-logo.jpg",width:50}],
              alignment:"right"
              
            }
          ]}]
        }

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
              pdfDocGenerator.getBlob((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = `Invoice.pdf`;
              document.body.append(link);

              link.click();
              link.remove();
              setTimeout(() => URL.revokeObjectURL(link.href), 7000);

});

    }


function generateInvoice(invMap){

    console.log(invMap)

    const singleTariffAmount = parseFloat(invMap.invoicePartyTariffSeqData.split("^")[0].split("x")[1]);


    // const base64Img = getBase64ImgFromImageComp(appLogoImg)
     

//Step 6b: Initialize the PDF Make Api
// var PDFMake = require('pdfmake');
// var printer = new PDFMake(fonts);

// var printer = new pdfMake(fonts);

pdfMake.fonts = {  
// download default Roboto font from cdnjs.com
Roboto: {
  normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
  bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
  italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
  bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
},
DMSans: {
  normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
  bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
  italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
  bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
},

}


//Step 6c: Initialize Information to be put up

pdfMake.tableLayouts = {
exampleLayout: {
hLineWidth: function (i, node) {
  if (i === 0 || i === node.table.body.length) {
    return 0;
  }
  return (i === node.table.headerRows) ? 1 : 0.5;
},
vLineWidth: function (i) {
  return 0;
},
hLineColor: function (i) {
  return i === 1 ? '#bbb' : '#bbb';
},
paddingLeft: function (i) {
  return i === 0 ? 0 : 8;
},
paddingRight: function (i, node) {
  return (i === node.table.widths.length - 1) ? 0 : 8;
},
paddingTop: function (i, node) {
  return (i === node.table.widths.length - 1) ? 0 : 8;
},
paddingBottom: function (i, node) {
  return (i === node.table.widths.length - 1) ? 0 : 8;
}
},
tableLayout: {
hLineWidth: function (i, node) {
  return 0.5;
},
vLineWidth: function (i) {
  return 0.5;
},
hLineColor: function (i) {
  return i === 1 ? '#bbb' : '#bbb';
},
paddingLeft: function (i) {
  return 8;
},
paddingRight: function (i, node) {
  return 8;
},
paddingTop: function (i, node) {
  return  8;
},
paddingBottom: function (i, node) {
  return 8;
}
},
monoRowLayout: {
hLineWidth: function (i, node) {           
  return i == 0 ? 1 : 2 
},
vLineWidth: function (i,node) {
  return 0.5
//  return i == 0 ? 0 : 0.5
},
hLineColor: function (i) {
  return i === 1 ? '#bbb' : '#bbb';
},
paddingLeft: function (i) {
  return 8;
},
paddingRight: function (i, node) {
  return (i === node.table.widths.length - 1) ? 0 : 8;
},
paddingTop: function (i, node) {
  return (i === node.table.widths.length - 1) ? 0 : 8;
},
paddingBottom: function (i, node) {
  return (i === node.table.widths.length - 1) ? 0 : 8;
}
},
noBorderSingleColumn: {
hLineWidth: function (i, node) {           
  return 0
},
vLineWidth: function (i,node) {
  return 0
//  return i == 0 ? 0 : 0.5
},
hLineColor: function (i) {
  return i === 1 ? '#bbb' : '#bbb';
},
paddingLeft: function (i) {
  return 0;
},
paddingRight: function (i, node) {
  return 0;
},
paddingTop: function (i, node) {
  return 8;
},
paddingBottom: function (i, node) {
  return 8;
}
},
borderSingleColumn: {
hLineWidth: function (i, node) {           
  return i == 0 ? 0 : 2;
},
vLineWidth: function (i,node) {
  return 0.5
//  return i == 0 ? 0 : 0.5
},
hLineColor: function (i) {
  return i === 1 ? '#bbb' : '#bbb';
},
paddingLeft: function (i) {
  return 8;
},
paddingRight: function (i, node) {
  return 8;
},
paddingTop: function (i, node) {
  return 8;
},
paddingBottom: function (i, node) {
  return 8;
}
}
};




function getDateStringFromDateData(dateData){

  if(typeof dateData == "string" || typeof dateData == "number"){
    if(dateData.toString().length==10){
     return getDateStringFromMillis(parseInt(dateData)*1000);
    }else{
      return getDateStringFromMillis(parseInt(dateData));
    }

  }else if (typeof dateData == "object"){
    return getDateStringFromMillis(dateData.seconds * 1000);

  }else if (typeof dateData == "date"){
    return getDateStringFromMillis(dateData.getTime())
  }
}






const docDefinition = {

content: [
  {columns:[
    {
      width:'30%',
      stack:[{text:'Hockey Caddy'},
      {text:`${invMap.invoiceAdminUnitNo} ${invMap.invoiceAdminStreetName}`},
      {text:`${invMap.invoiceAdminCityName} ${invMap.invoiceAdminProvinceName} ${invMap.invoiceAdminPostalCode}`},
      {text:`${invMap.invoiceAdminCountryName}`},
      {text:`Email: ${invMap.invoiceAdminEmail}`,decoration:"underline"},
      {text:`ATTN: ${invMap.invoiceAdminContactPersonName}`,margin:[0,0,0,0]},
      ],
      fontSize: 10, 
      alignment:"left",
      lineHeight:1.25
    },
    {
      width:'70%',
      stack:[
      {image:"app-logo.png",width:100,height:100}],
      alignment:"right"
      
    }
  ]},
  // if you don't need styles, you can use a simple string to define a paragraph
  {text:"INVOICE",
  fontSize: 48, 
  bold:true,
  decoration:"underline", 
  alignment:
  "center"},


  {columns:[
    {
      width:'60%',
      stack:[{text:'DATE',bold:true},
      {text: getDateStringFromDateData(invMap.invoiceIssueDate)},
      {text:`INVOICE#`,bold:true,marginTop:10},
      {text:invMap.invoiceId},
      {text:'CUSTOMER ID:',bold:true,marginTop:10},
      {text:invMap.invoicePartyId},
      ,
      ],
      fontSize: 10, 
      alignment:"left"
    },
    {
      width:'10%',
      stack:[
        {text:`TO:`,bold:true},],
      alignment:"left"
    },
    {
      width:'30%',
      stack:[{text:`${invMap.invoicePartyName.toUpperCase()}`},
      {text:`${invMap.invoicePartyUnitNo} ${invMap.invoicePartyStreetName}`},
      {text:`${invMap.invoicePartyCityName} ${invMap.invoicePartyProvinceName} ${invMap.invoicePartyPostalCode}`},
      {text:`${invMap.invoicePartyCountryName}`},
      {text:`${invMap.invoicePartyContactNoComps[1]}`},
      {text:`${invMap.invoicePartyEmail}`},
      ,
      ],
      fontSize: 10, 
      alignment:"right",
      lineHeight:1.25
    },
   
  ],
  marginTop:30
},
  {
    columns:[
      {
        width:'40%',
        stack:[{text:'SALESPERSON',bold:true}]
      },
      {
        width:'20%',
        stack:[{text:'JOB',bold:true}]
      },
      {
        width:'12%',
        stack:[{text:'CURR.',bold:true}]
      },
      {
        width:'30%',
        stack:[{text:'PAYMENT TERMS',bold:true}]
      },
      // {
      //   width:'15%',
      //   stack:[{text:'DUE DATE',bold:true}]
      // },
    ],
    marginTop: 40,
    padding:0
  },
  {
    layout: 'monoRowLayout', // optional
    table: {
      // headers are automatically repeated if the table spans over multiple pages
      // you can declare how many rows should be treated as headers
      headerRows: 1,
      widths: [ '40%', '20%','10%', '30%'],  
      body: [
        [ {text:`${invMap.invoiceAdminSalesPersonName}`,fillColor:'#eeeeee'}, {text:'Sales',fillColor:'#eeeeee'},{text:'CAD',fillColor:'#eeeeee'},{text:'Due Upon Receipt',fillColor:'#eeeeee'}
        //,{text:`${getDateStringFromDateData(invMap.invoicePaymentDueDate)}`,fillColor:'#eeeeee'}
      ]
      ],      
    },
    fontSize:10,
    marginTop:5,
  },
  {
    columns:[
      {
        width:'10%',
        stack:[{text:'QTY',bold:true}]
      },
      {
        width:'60%',
        stack:[{text:'DESCRIPTION',bold:true}]
      },
      {
        width:'15%',
        stack:[{text:'UNIT PRICE',bold:true}]
      },
      {
        width:'15%',
        stack:[{text:'LINE TOTAL',bold:true}]
      },
    ],
    marginTop: 20,
    padding:0
  },
  {
    layout: 'monoRowLayout', // optional
    table: {
      // headers are automatically repeated if the table spans over multiple pages
      // you can declare how many rows should be treated as headers
      headerRows: 1,
      widths: [ '10%', '60%', '15%', '15%'],  
      body: [
        [ {text:`${invMap.invoiceJobCount}`},{text:`${invMap.invoiceDesc}`}, {text:`${singleTariffAmount}`},{text:`${invMap.invoiceSubTotalAmount}`}],
        // [ '','',{text:'SUBTOTAL'},{text:'52.50',fillColor:'#eeeeee'}],
        // [ '','',{text:'HST 13%'},{text:'6.80',fillColor:'#eeeeee'}],
        // [ '','',{text:'TOTAL'},{text:'59.30',fillColor:'#eeeeee'}],
      ],      
    },
    fontSize:10,
    margin:[0,5,0,0],
  },

  {
    columns:[
      {
        width:'70%',
        text:""
      },
      {
        width:'15%',
        stack:[
          {
            layout:'noBorderSingleColumn',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: [ '100%'],  
            body: [
              [ {text:'SUBTOTAL'}],
              [ {text:'HST 13%'}],
              [ {text:'TOTAL'}],
            ],      
          },
          fontSize:10,
          margin:0,
        }
        ]
      },

      {
        width:'15%',
        stack:[{
          layout: 'borderSingleColumn', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: [ '100%'],  
            body: [
              [{text:invMap.invoiceSubTotalAmount.toFixed(2),fillColor:'#eeeeee'}],
              [{text:invMap.invoiceTaxAmount.toFixed(2),fillColor:'#eeeeee'}],
              [{text:invMap.invoiceTotalAmount.toFixed(2),fillColor:'#eeeeee'}],
            ],      
          },
          fontSize:10,
          
        },]
      }],
     
  },

    // {
    //   layout: 'exampleLayout', // optional
    //   table: {
    //     // headers are automatically repeated if the table spans over multiple pages
    //     // you can declare how many rows should be treated as headers
    //     headerRows: 1,
    //     widths: [ '10%', '50%', '20%', '10%','10%' ],  
    //     body: [
    //       [ {text:'Sl No',fillColor: '#eeeeee'}, {text:'Description',fillColor: '#eeeeee'}, {text:'Tariff',fillColor: '#eeeeee'},{text:'Job Count',fillColor: '#eeeeee'}, {text:'Total',fillColor: '#eeeeee'}],
    //       ...rows,
    //       [ {colSpan:3,rowSpan:1,text:'Total',fillColor:'#eeeeee'},'','',{text:totalJobCount},{text:totalAmount.toFixed(2)}],
    //       [ {colSpan:3,rowSpan:1,text:'Discount',fillColor:'#eeeeee'},'','',{text:`${invMap.invoiceDiscount}%`},{text:discountAmount.toFixed(2)}],
    //       [ {colSpan:4,rowSpan:1,text:'Amount Payable',fillColor:'#eeeeee'},'','','',{text:invoiceTotal.toFixed(2)}],
    //       [ {colSpan:5,rowSpan:1,text:invoiceAmtInWords,fillColor:'#eeeeee'},"","","",""]
    //     ],      
    //   },
    //   fontSize:10,
    //   margin:[0,20,0,20],
    //   defaultStyle: {
    //     font: 'Helvetica'
    //   }
  
    // }
],
patterns: {
  stripe45d: {
    boundingBox: [1, 1, 4, 4],
    xStep: 3,
    yStep: 3,
    pattern: "1 w 0 1 m 4 5 l s 2 0 m 5 3 l s",
  }
}
};

//pdfMake.createPdf(docDefinition).download();



const pdfDocGenerator = pdfMake.createPdf(docDefinition);
pdfDocGenerator.getBlob((blob) => {
const link = document.createElement("a");
link.href = URL.createObjectURL(blob);
link.download = `Invoice-${invMap.invoiceMonthYear}(${invMap.invoiceId}).pdf`;
document.body.append(link);

link.click();
link.remove();
setTimeout(() => URL.revokeObjectURL(link.href), 7000);

});

//pdfDocGenerator.download()




}





export function getRegexPattern(category){
  switch(category){
    case "entityName": 
    return new RegExp("^[A-Za-z@0-9]([A-Za-z@0-9-]*[A-Za-z0-9]{1,}$|[A-Za-z@0-9- ]*[A-Za-z0-9]{1,}$|[A-Za-z0-9]{0,}$)")
    break;
    case "personName":
      return new RegExp("^[A-Za-z]([A-Za-z]*[A-Za-z]{0,}$|[A-Za-z ]*[A-Za-z]{1,}$)")
      break;
    case "modelName":
      return new RegExp("^[A-Za-z0-9]([A-Za-z0-9-\\/\\\\]*[A-Za-z0-9-\\/\\\\]{0,}$|[A-Za-z0-9 -\\/\\\\]*[A-Za-z0-9]{1,}$)")
    case "email":
      return new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+(\\.{1}[A-Za-z]{2,}){0,1}\\.{1}[A-Za-z]{2,}$")
      break;  
    case "phoneNo":
      return new RegExp("^\\+[0-9]{0,14}[0-9]$")
      break;
    case "positiveNumOrDec":
        return new RegExp("^[0-9][0-9]{0,}([0-9]{0,}$|\\.[0-9]{1,}$)")

    case "password":
        return new RegExp("\\S{6,}")

    case "latitude":return new RegExp("^-{0,1}[0-9]{1,2}.[0-9]{2,}$")
   
    case "longitude":
            return new RegExp("^-{0,1}[0-9]{1,3}.[0-9]{2,}$")
        
    default:
      return new RegExp("^[^\\^][^\\^]{0,}[^\\^]{0,}$")

  } 
}



    function generatePdf(invMap){
                //Step 6a: Define font files - this is required 
                var fonts = {
                  Roboto: {
                    normal: 'fonts/Roboto-Regular.ttf',
                    bold: 'fonts/Roboto-Medium.ttf',
                    italics: 'fonts/Roboto-Italic.ttf',
                    bolditalics: 'fonts/Roboto-MediumItalic.ttf'
                  }
                };
          
              //     //Data for rows in the invoice pdf - for PDF Make
          
              //     const blockComps = invMap["invoicePartyTariffSeqData"].split("^");
              //     const rows = [];
              //     let remJobCount  = invMap.invoiceJobCount;
          
              //     blockComps.every((comp,i)=>{
              //       const tArr=[]
              //       const dataObj = getJobCountRangeAndCostStrMap(blockComps,comp,i,remJobCount)
              //       remJobCount-=dataObj.unbilledJobCount
              //       if(remJobCount>=0){
              //         tArr.push(`${i+1}`)
              //         tArr.push(`Fee for ${dataObj.jobCountRangeStr}`)
              //         tArr.push(dataObj.jobCountRangeTariff.toString())
              //         tArr.push(dataObj.unbilledJobCount.toString())
              //         tArr.push(dataObj.jobCountRangeTotal.toFixed(2).toString())
              //         rows.push(tArr)
              //         return true
              //       }else{
              //         return false
              //       }
              //     })
              
              //     console.log(rows)
              
              //     let totalJobCount=0;
              //     let totalAmount=0;
              
              //     rows.forEach((rowData,i)=>{
              //       totalJobCount+=parseInt(rowData[3])
              //       totalAmount+=parseFloat(rowData[4])
              //     })
          
              // //Step 6b: Initialize the PDF Make Api
              // // var PDFMake = require('pdfmake');
              // // var printer = new PDFMake(fonts);
          
              // // var printer = new pdfMake(fonts);
          
              // pdfMake.fonts = {  
              //   // download default Roboto font from cdnjs.com
              //   Roboto: {
              //     normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
              //     bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
              //     italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
              //     bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
              //   },
              
              // }
          
              //Data for rows in the invoice pdf - for PDF Make
    
              const blockComps = invMap["invoicePartyTariffSeqData"].split("^"); //Array of each tarrif comp (jobs in the tarriff slab x rate - 3x0.03) ex [3x0.02,4x0.1]
              const rows = [];
              let remJobCount  = invMap.invoiceJobCount; //Jobs for which the invoice is billed
    
      
              blockComps.every((comp,i)=>{
                const tArr=[]
                const dataObj = getJobCountRangeAndCostStrMap(blockComps,comp,i,remJobCount)
                remJobCount-=dataObj.unbilledJobCount
                if(remJobCount>=0){
                  tArr.push(`${i+1}`)
                  tArr.push(`Fee for ${dataObj.jobCountRangeStr}`)
                  tArr.push(dataObj.jobCountRangeTariff.toString())
                  tArr.push(dataObj.unbilledJobCount.toString()) //pushing to index 3
                  tArr.push(dataObj.jobCountRangeTotal.toFixed(2).toString()) //pushing to index 4
                  rows.push(tArr)
                  return true
                }else{
                  return false
                }
              })
          
              console.log(rows)
          
              let totalJobCount=0;
              let totalAmount=0;
          
              rows.forEach((rowData,i)=>{
                totalJobCount+=parseInt(rowData[3]) // as job count is in index 3 of row array
                totalAmount+=parseFloat(rowData[4])// as amount is in index 4 of row array
              })
      
          //Step 6b: Initialize the PDF Make Api
          // var PDFMake = require('pdfmake');
          // var printer = new PDFMake(fonts);
      
          // var printer = new pdfMake(fonts);
      
          // pdfMake.fonts = {  
          //   // download default Roboto font from cdnjs.com
          //   Roboto: {
          //     normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          //     bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
          //     italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
          //     bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
          //   },
          
          // }
      
      
          //Step 6c: Initialize Information to be put up
      
          pdfMake.tableLayouts = {
          exampleLayout: {
            hLineWidth: function (i, node) {
              if (i === 0 || i === node.table.body.length) {
                return 0;
              }
              return (i === node.table.headerRows) ? 1 : 0.5;
            },
            vLineWidth: function (i) {
              return 0;
            },
            hLineColor: function (i) {
              return i === 1 ? '#bbb' : '#bbb';
            },
            paddingLeft: function (i) {
              return i === 0 ? 0 : 8;
            },
            paddingRight: function (i, node) {
              return (i === node.table.widths.length - 1) ? 0 : 8;
            }
          }
        };
    
      
      const discountAmount = totalAmount*invMap.invoiceDiscount/100;
      const invoiceTotal = totalAmount-discountAmount;
      
      
        
      const currencyStrComp = parseFloat(invoiceTotal).toFixed(2).toString().split(".");
      
      let invoiceAmtInWords="";
      
      const currencyUnitName="DOLLARS"
      const currencySubUnitName="CENTS"
      
      console.log(currencyStrComp)
      
      if(currencyStrComp.length==2){
      const currencyMainComp = numToWordConverter.toWords(parseInt(currencyStrComp[0])).toUpperCase();
      const currencyDecComp= numToWordConverter.toWords(parseInt(getDecimalValue(currencyStrComp[1],2))).toUpperCase();
      invoiceAmtInWords=`${currencyMainComp} ${currencyUnitName} AND ${currencyDecComp} ${currencySubUnitName}`
      }else{
      const currencyMainComp = numToWordConverter.toWords(parseInt(currencyStrComp[0]));
      invoiceAmtInWords=`${currencyMainComp} ${currencyUnitName}`
      }
      
      function getDecimalValue(decComp,count){
      
        if(decComp.toString().length<count){
          const zerosToBeAdded = count-decComp.toString().length
          console.log(zerosToBeAdded)
          let zeroStrings= "";
          
          for(let x=0;x<zerosToBeAdded;x++){
            zeroStrings+='0'
          }
          console.log(`${decComp.toString}${zeroStrings}`)
          return `${decComp.toString()}${zeroStrings}`
        }else{
      
          return decComp.toString().substring(0,count);
        }
      }
      
      
         const docDefinition = {
      
            content: [
              // if you don't need styles, you can use a simple string to define a paragraph
              {text:invMap.invoiceDesc,
              fontSize: 18, 
              bold:true, 
              alignment:
              "center"},
              {columns:[
                {
                  width:'30%',
                  stack:[{text:'TO,'},
                  {text:`${invMap.invoicePartyName}`},
                  {text:`${invMap.invoicePartyAddress}`},
                  {text:`${invMap.invoicePartyEmail}`},
                  {text:'ATTN:',margin:[0,20,0,0]},
                  {text:`${invMap.invoicePartyContactPersonName}`,bold:true},
                  {text:`${invMap.invoicePartyContactPersonContactNoComps[1]}`,bold:true},
                  ],
                  fontSize: 10, 
                  alignment:"left"
                },
                {
                  width:'70%',
                  stack:[
                  {text:`${getDateStringFromMillis(invMap.invoiceIssueDate.toMillis())}`},
                  {text:`${invMap.invoiceId}`},
                  {text:`${invMap.invoicePartyId}`,bold:true},
                  {text:`DUE DATE: ${getDateStringFromMillis(invMap.invoicePaymentDueDate.toMillis())}`}],
                  fontSize: 10, 
                  alignment:"right",
                  lineHeight:1.4
                }
              ]},
                {
                  layout: 'exampleLayout', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: [ '10%', '50%', '20%', '10%','10%' ],  
                    body: [
                      [ {text:'Sl No',fillColor: '#eeeeee'}, {text:'Description',fillColor: '#eeeeee'}, {text:'Tariff',fillColor: '#eeeeee'},{text:'Job Count',fillColor: '#eeeeee'}, {text:'Total',fillColor: '#eeeeee'}],
                      ...rows,
                      [ {colSpan:3,rowSpan:1,text:'Total',fillColor:'#eeeeee'},'','',{text:totalJobCount},{text:totalAmount.toFixed(2)}],
                      [ {colSpan:3,rowSpan:1,text:'Discount (%)',fillColor:'#eeeeee'},'','',{text:`${invMap.invoiceDiscount}%`},{text:discountAmount.toFixed(2)}],
                      [ {colSpan:4,rowSpan:1,text:'Amount Payable',fillColor:'#eeeeee'},'','','',{text:invoiceTotal.toFixed(2)}],
                      [ {colSpan:5,rowSpan:1,text:invoiceAmtInWords,alignment:"center",fillColor:'#eeeeee'},"","","",""]
                    ],      
                  },
                  fontSize:10,
                  margin:[0,20,0,20],
                  defaultStyle: {
                    font: 'Helvetica'
                  }
              
                }
            ],
          };
      
          //pdfMake.createPdf(docDefinition).download();
      
      
      
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
          pdfDocGenerator.getBlob((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `Invoice-${invMap.invoiceMonthYear}(${invMap.invoiceId}).pdf`;
            document.body.append(link);
      
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      
           });


          }
          
    
          export const decodeFbAuthError = (authErrorCode) => {

            switch(authErrorCode.toLowerCase()){
              case "auth/user-not-found" : return "No User Found"
              case "auth/wrong-password" : return "Wrong Password"
              
              default:return "Error"
            }

           }


           function ifValidNumInput(val){
            return val || val===0
           }




           //@Firebase Functions

           export async function queryAndGetAllDocsInCollection(collectionPath){

              const queryDocSnapshot = await getDocs(collection(db,collectionPath))

              console.log(queryDocSnapshot.size)

              return  convertQueryDocSnapshotToArray(queryDocSnapshot);
           }

       function convertQueryDocSnapshotToArray(queryDocSnapshot){
        
              return Array.from(queryDocSnapshot.docs).map((doc)=>doc.data())
           }
    
           
  
    export {
  BrowserRouter, Link, Route, Routes, Timestamp, activate, addEmployeeInputObj, addPrepaidSchemeInputSchemeMap, addStoreBranchInputObj,
  addSuperAdminInputObj, addToolInputObj, applyActionCode, arrangeNumArrayInAscendingOrder, arrayRemove, arraySortAscending,
  arraySortDescending, arrayUnion, auth, checkActionCode, checkCurrentOrderDetailsSchemeMap,
  checkPastOrderDetailsSchemeMap, child, collection, confirmPasswordReset, connectFunctionsEmulator, convertMapOfMapsToMapCollection, createInvoiceMap, database, db, deleteDoc, displayCurrentOrdersRecordBannerSchemeMap, doc, editEmployeeInputObj, editInvoiceAmountInputSchemeMap, editSettingsStoreBranchInputSchemeMap,
  editSettingsStoreBrandInputSchemeMap,
  editSettingsSuperAdminInputSchemeMap, editToolInputObj, errorValueCond, fetchAndActivate,
  fetchConfig, firebase, functions, generateIncrementNumArray, generateInvoice, generatePdf, get, getArrayAvg,
  getArraySum, getAuth, getBase64ImgFromImageComp, getBlob, getBytes, getCloneObj,
  getCurrencySymbol, getDate, getDateInputStringFromMillis,
  getDateObjFromDayOfYearAndYear, getDateStringFromMillis, getDayOfTheYear, getDecimalValue, getDoc, getFSettingsLabelValue, getFirestore, getInvoiceAmountFromJobCount, getJobCountRangeAndCostStrMap, getMonthYear,
  getMonthYearShort, getOrderDetailsLabelValue, getSerialNo, getSettingsLabelValue, getStoreLabelValue,
  getStoreLabelValueAdmin, getTimeLabel, getTimeStringFromMillis, getValue, httpsCallable, ifValidNumInput, increment, initializeApp, limit, managePrepaidSchemeBannerMap, onAuthStateChanged, onSnapshot, onValue, orderBy, pageSizes, push, query, rbaseQuery, rbaseRef, ref, remoteConfig, runTransaction, sendEmailVerification,
  sendPasswordResetEmail, serverTimestamp, set, setDoc, signInWithEmailAndPassword, skateInputObj, statSchemeMapStoreBranch,
  statSchemeMapStoreBrand,
  statSchemeMapSuperAdmin, storage, strMap, to2Digits, toSentenceCase, update, updateDoc, uploadBytes, useNavigate, verifyPasswordResetCode, viewBalancesSchemeMap, viewChart2SchemeMap,
  viewChart3SchemeMap,
  viewChart4SchemeMap, viewChartSchemeMap, viewCurrentOrdersRecordBannerSchemeMap, viewDashboardBlankSchemeMap, viewDashboardMasterSchemeMap, viewDashboardStoreBranchSchemeMap,
  viewDashboardStoreBrandSchemeMap, viewInvoicesSchemeMap, viewPaidInvoicesAdminSchemeMap, viewPaidInvoicesSchemeMap, viewPastOrdersSchemeMap, viewPrepaidCardRequestRecordBannerSchemeMap, viewQRExcelGenSchemeMap, viewTransactionsAdminSchemeMap, viewTransactionsSchemeMap, viewUnpaidInvoicesAdminSchemeMap,
  viewUnpaidInvoicesSchemeMap, viewUnpaidInvoicesStoresSchemeMap, where
};
  