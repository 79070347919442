
import { child, get } from "firebase/database";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database, db, functions as fbFunctions, httpsCallable, rbaseRef } from "../Utils";
import BtnNormal from "./BtnNormal";
import FnSelectFilterContModule from "./FnSelectFilterContModule";
import { convertRbaseTimestampObjToFbTimestamp, naturalSortMapsByKey } from "./genFunctions";
import LoadSpinner from "./LoadSpinner";
import MarksCont from "./MarksCont";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import { generatePdfStudent } from "./projectFns";

//import { ReactComponent as EmployeeStatIcon } from "../images/employee-stat.svg";
// const qrCode = window.require("qrcode");
// const PDFDocument = window.require("pdfkit");
// const blobStream = window.require("blob-stream");

//@start
export default function FnSelelectFilterContMarks(props) {

  console.log(props)
  const categories = props.schemeMap.categories
  const mapKeys = props.schemeMap.mapKeys
  const catDbKeys = props.schemeMap.catDbKeys
  const navTreeNodeKeys = props.schemeMap.navTreeNodeKeys

  // const [navTreeMaps, setNavTreeMaps] = useState([...getInitNavTreeMaps(props.passMap.navTreeStartIndex,categories)
  // ]);

  const [navTreeMaps, setNavTreeMaps] = useState([]);

  const [navTreePosIndex, setPosIndex] = useState(props.passMap.navTreeStartIndex);
  const [selUnitMap,setSelUnitMap]=useState({});

  const [students,setStudents] = useState([])
  const [showPrompt,setShowPrompt] = useState(false)
  const [promptMap,setPromptMap] = useState({})
  const [showSpinner,setShowSpinner] = useState(false)
  const [totalMarksCount,setTotalMarksCount] = useState(0)

  const [dataMap,setDataMap] = useState({
    students:[],
    totalMarksCount:0
  })

  useEffect(()=>{
    (async () => await initializeData())()

  },[])



  async function initializeData(){

  }




  //@3
  async function functions(fnName,data){


    switch(fnName){
      case "initializeData":{
        try{

          console.log(data)

          const navTreeNodeValMap = data.navTreeNodeValMap;
          const navTreeNodeDbMapObj = data.navTreeNodeDbMapObj;
          const instituteUid = navTreeNodeValMap.instituteUid
          const examName = navTreeNodeValMap.examName
          const className = navTreeNodeValMap.className
          const sectionName = navTreeNodeValMap.sectionName


          console.log(instituteUid,examName,className,sectionName)

          // const examDoc = await getDocs(doc(db, `institutes/t8IvfUhDs0mDloRmdo0S/exams`));

          const collQuery =  query(collection(db,`institutes/${instituteUid}/examMarks`),
      where("examName","==",examName),where("className","==",className),where("sectionName","==",sectionName));

      const collDocs = await getDocs(collQuery)

      console.log(collDocs,collDocs.size,collDocs.docs.length)

      let examMarkMap = {};

      let examDoc = null

      let dataMap={}

      if(collDocs.size==0){

      }else{
        examDoc = collDocs.docs[0].data();
        console.log(examDoc)
        examMarkMap = examDoc.studentExamMarksMap

      }
      dataMap["totalMarksCount"] = navTreeNodeDbMapObj["exam"]["examMaxMarks"]
      dataMap["examDescription"] = navTreeNodeDbMapObj["exam"]["examDescription"]

      // setTotalMarksCount(navTreeNodeDbMapObj["exam"]["examMaxMarks"]);

          const mapOfMapsRbaseCall = await get(child(rbaseRef(
            database
          ),`students/${instituteUid}/${className}/${sectionName}`));
               
          const  mapOfMaps = mapOfMapsRbaseCall.val()
          console.log(mapOfMaps)
    
          const tColl = []
    
          Object.keys(mapOfMaps).forEach((key,i)=>{
                  const tMap = {}
                  tMap["uid"] = key;
                  tMap["mark"] = key in examMarkMap ? examMarkMap[key] : 0;
                  tMap["presentCond"] = key in examMarkMap ? true : false;
                  tMap["studentRollNo"] = mapOfMaps[key].studentRollNo
                  // tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
                  tMap["name"] = mapOfMaps[key].name

                  tColl.push(tMap);
                });

                const sortedStudents = naturalSortMapsByKey(tColl,"studentRollNo");

                dataMap["students"] = sortedStudents

                
                setDataMap(dataMap)
    
          }catch(error){
            console.log("rbase fetch error")
          }
    
        // setViewSequence(1)
      }
      break;
      case "marksChange":{

        const dataMap = data.dataMap

        const mark = data.changeStudentMap.event.target.value;

        const studentUid = data.changeStudentMap.student.uid;
        const studentIndex = data.changeStudentMap.studentIndex;

        const tDataMap = {...dataMap};

        if(parseFloat(mark) <= parseFloat(dataMap.totalMarksCount)){
          tDataMap.students[studentIndex]["mark"] = parseFloat(mark)
          setDataMap(tDataMap)
        }


      
      }
      break;

      case "examPresenceChange":{

        const dataMap = data.dataMap
        const presentCond = data.changeStudentMap.event.target.checked;

        const studentIndex = data.changeStudentMap.studentIndex;

        const tDataMap = {...dataMap};

        tDataMap.students[studentIndex]["presentCond"] = presentCond;
        tDataMap.students[studentIndex]["mark"] = 0;

        setDataMap(tDataMap)
      
      }
      break;
      case "saveMarks":{
        
        console.log(data)

        setShowSpinner(true)

        const dataMap = data.dataMap

        const navTreeNodeDbMapObj = data.navTreeNodeDbMapObj;
        const examMap = navTreeNodeDbMapObj["exam"]
        const instituteMap = navTreeNodeDbMapObj["institute"]

        const studentMarksMap = {}
        const studentUids = []

        for (const [j,studentMap] of dataMap.students.entries()){
          if(studentMap.presentCond){
            studentUids.push(studentMap.uid)
            studentMarksMap[studentMap.uid] = studentMap.mark
          }
        }

        console.log(studentMarksMap,studentUids)

        const payload = {
          examName:data.navTreeNodeValMap.examName,
          examDate:convertRbaseTimestampObjToFbTimestamp(examMap.examDate),
          examMaxMarks:examMap.examMaxMarks,
          examDescription:examMap.examDescription,
          examType:examMap.examType,
          subjectName:examMap.subjectName,
          className:data.navTreeNodeValMap.className,
          sectionName:data.navTreeNodeValMap.sectionName,
          instituteUid:data.navTreeNodeValMap.instituteUid,
          instituteName:instituteMap.name,
          examAttendedStudentUids:studentUids,
          studentExamMarksMap:studentMarksMap
        }


        console.log(payload)


        const fbFunctionsCall = httpsCallable(fbFunctions, "insertMarks");
        const callResult = await fbFunctionsCall(payload);
        setShowSpinner(false)

        const opResult = callResult.data;

        if(!opResult.status){
          console.log(opResult.errorMessage)
        }

        displayPrompt(opResult.message,"Save Result")

      
      }
      break;

      case "generateMarksSheet":{
        console.log(data)

        setShowSpinner(true)

        
        const dataMap = data.dataMap


        const selectionNodeDbMapObj = data.navTreeNodeDbMapObj
        const sheetRowDataMaps = dataMap.students

        const pdfDataMap = {
          selectionNodeDbMapObj:selectionNodeDbMapObj,
          sheetRowDataMaps:sheetRowDataMaps
        }

        await generatePdfStudent({},pdfDataMap,"examMarks",{},"download");

        setShowSpinner(false)
      }

    }
          

  }

function getFnView(dataMap){
  return(<MarksCont 
    students={dataMap.students}
    totalMarksCount={dataMap.totalMarksCount}
    examDescription={dataMap.examDescription}
    marksChangeHandler={(changeStudentMap)=>functions("marksChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}
    totalMarksCountChangeHandler={(newTotalMarksCount)=>setDataMap({...dataMap,totalMarksCount:newTotalMarksCount})}
    examPresenceChangeHandler={(changeStudentMap)=>functions("examPresenceChange",{changeStudentMap:changeStudentMap,dataMap:dataMap})}/>)
}

function getFnBtnView(dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapObj){
  return(<React.Fragment>
    <BtnNormal spClass="" color="greenDark" label="Save" clickHandler={()=> functions("saveMarks",{dataMap:dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
    <BtnNormal spClass="" color="secondaryDark" label="Generate Sheet" clickHandler={()=> functions("generateMarksSheet",{dataMap:dataMap,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
  </React.Fragment>)
}


function displayPrompt(message,title,status){
  setShowSpinner(false)
  setShowPrompt(true);
  setPromptMap({
    status: status ? status : false,
    title:title ? title : "Info",
    message:message
  })
}

//@return
  return (
    <div className="h-screen w-full absolute top-0 p-cust-topHead">
         <FnSelectFilterContModule
                   topicFnView={()=>getFnView(dataMap)}
                   topicFnBtnView={(navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)=>getFnBtnView(dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)}
                   schemeMap={props.schemeMap}
                   passMap={props.passMap}
                   mapOfMaps={props.mapOfMaps}
                   listenMaps={props.listenMaps
                   }
                   fnHandler={(fnName,data)=>functions(fnName,data)}
                   actionHandler={(data)=>props.actionHandler(data)}
                   showSpinnerHandler={(cond)=>setShowSpinner(cond)}
                   backBtnClickHandler={() => props.backBtnClickHandler()}
         />
        {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
       {showSpinner && <LoadSpinner/>}
       </div>
);

}




