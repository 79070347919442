

export function getStartDateOfLastMonth(){
    const todaysDate = new Date()
    return new Date(todaysDate.getFullYear(), todaysDate.getMonth()-1, 1)
}

export function getEndDateOfLastMonth(){
    const todaysDate = new Date()
    return new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1)
}

export function getStartDateOfThisMonth(){
    const todaysDate = new Date()
    return new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1)
}

export function getEndDateOfThisMonth(){
    const todaysDate = new Date()
    return new Date(todaysDate.getFullYear(), todaysDate.getMonth()+1, 0)
}