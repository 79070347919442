import React, { Component } from "react";

export default function ImageCont(props) {

  const colPosition= `col-start-${props.colStart} col-end-${props.colEnd}`
  const center=props.centerCond?`mx-auto`:``
  return (
    <div className={`flex items-center justify-center p-4 rounded-r-lg cursor-pointer bg-quaternary-xlight hover:bg-primary-200`}>
      <img className={`${center} ${props.spClass}`}src={props.image}></img>
    </div>
  );
}
