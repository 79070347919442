import React, { useState } from "react";
import {
  getOrderDetailsLabelValue2
} from "../Utils";
import { ReactComponent as QRIcon } from "../images/qr.svg";
import ActionButton2 from "./ActionButton2";
import BottomContainer from "./BottomContainer";
import ButtonBack from "./ButtonBack";
import ButtonIconTitle from "./ButtonIconTitle";
import DetailTile from "./DetailTile";
import ModalBoxImgDisplay from "./ModalBoxImgDisplay";

export default function DetailsActionBtnCont(props) {
  console.log(props.passMap);

  const [showModalBox,setShowModalBox] = useState(false)
  const [modalMap,setModalMap] = useState({})

  
  const bottomContainer = (
    <BottomContainer
      backBtnClickHandler={() =>
        props.viewChangeHandler(props.passMap.backViewData)
      }
    />
  );

  function actionHandle(fnName){
    if(fnName=="Show QR"){
      setModalMap({
        value:props.passMap.orderItemQrId
      })
        setShowModalBox(true)
    }
  }

  return (
    <div className="grid grid-rows-cust-4c p-cust-topHead">
      <div className="grid grid-rows-cust-8 grid-flow-col p-16">
        {props.passMap.labelNames.map((labelName, i) => (
          <DetailTile
            labelName={labelName}
            labelValue={getOrderDetailsLabelValue2(
              props.passMap.valueDisplayTypes[i],
              props.passMap[props.passMap.labelDbKeys[i]],
              props.passMap
            )}
          />
        ))}
      </div>
       <div className="flex items-center justify-center">     
        {checkForActionBtn(props.passMap) &&
        <div className="flex items-center p-8 bg-quaternary-light rounded-lg">
            {checkAndGenerateActionBtns(props.passMap,props.actionHandler).map((btnName)=>
              <ButtonIconTitle 
              btnIcon={QRIcon} 
              btnTitle={btnName} 
              section="Action Cont"
              btnIconSize="h-8 w-8" 
              btnColorClass=""
              clickHandler={()=>actionHandle(btnName)}/>
            )} 
        </div>
        }
      </div>  
        <div className="absolute bottom-10 w-full flex flex-col justify-center items-center">
          <ButtonBack backBtnClickHandler={()=>props.backBtnClickHandler(props.passMap.backViewData)}/>
        </div>
  
      {showModalBox && <ModalBoxImgDisplay passMap={modalMap} closeClickHandler={()=>setShowModalBox(false)}/>}
      

    </div>
  );
}


function checkForActionBtn(passRecordMap) {
  return true  // returning true as there is fixed options, incase you need to add dynamic options then remove this statement and use the below templete

  const pendingCollCond = passRecordMap.orderStatus == "pendingCollection";
  const collectionNotifSendCond = passRecordMap.orderCollectionNotifSendCond;

  if(pendingCollCond){
  
    const pendingCollIndex = passRecordMap.orderStages.indexOf("pendingCollection");
    const pendingCollectionTime = passRecordMap.orderStageDateTimes[pendingCollIndex];

    const currentTime = new Date();

    const pendingCollectionTimeInSecs = pendingCollectionTime.seconds;
    const currentTimeInSecs = currentTime.getTime()/1000;

    const secsInOneDay = 60*60*24;

    if(collectionNotifSendCond){

      const collectionNotifSendTime = passRecordMap.orderCollectionNotifSendDateTime;
      const timeDiff = currentTimeInSecs - collectionNotifSendTime.seconds;
      
      if(timeDiff>secsInOneDay){
        return true;
      }else{
        return true;
      }


    }else{
      const timeDiff = currentTimeInSecs-pendingCollectionTimeInSecs;

      if(timeDiff>secsInOneDay){
        return true;
      }
    }
  }

  return false;
}




  function checkAndGenerateActionBtns(passRecordMap,actionHandler) {

    return ["Show QR"]

   
    const rMap = {}

    console.log(passRecordMap)

    const revPassData = {
      actionName:"Order Collection Process",
      dbData:passRecordMap
    }

    let btnLabelName = "";
    let btnAvlblty = false;
    let btnAlertLevel = "Low";
  
    const pendingCollCond = passRecordMap.orderStatus == "pendingCollection";
    const collectionNotifSendCond = passRecordMap.orderCollectionNotifSendCond;
  
    if(pendingCollCond){
  
      const pendingCollIndex = passRecordMap.orderStages.indexOf("pendingCollection");
      const pendingCollectionTime = passRecordMap.orderStageDateTimes[pendingCollIndex];
  
      const currentTime = new Date();
  
      const pendingCollectionTimeInSecs = pendingCollectionTime.seconds;
      const currentTimeInSecs = currentTime.getTime()/1000;
  
      const secsInOneDay = 60*60*24;
  
      if(collectionNotifSendCond){
  
        const collectionNotifSendTime = passRecordMap.orderCollectionNotifSendDateTime;
        const timeDiff = currentTimeInSecs - collectionNotifSendTime.seconds;
        
        if(timeDiff>secsInOneDay){
          btnLabelName = "Mark Collected";
          btnAlertLevel = "High";
          btnAvlblty = true;

        }else{
          btnLabelName = "Collection Notification Sent";
          btnAlertLevel = "Disabled";
          btnAvlblty = false;
        }
  
  
      }else{
        const timeDiff = currentTimeInSecs-pendingCollectionTimeInSecs;
  
        if(timeDiff>secsInOneDay){
          btnLabelName = "Send Collection Notification";
          btnAlertLevel = "High";
          btnAvlblty = true;
        }
      }
      revPassData["spActionName"]= btnLabelName 
      
    }


    
    return(<div className="px-16 py-4 bg-quaternary-light"><ActionButton2 btnLabel={btnLabelName} onClickFn={()=>actionHandler(revPassData)}/></div>
 )
  
  }


