import React, { useEffect, useState } from "react";
import { getMonth } from "../Utils";

export default function FilterAssembly(props) {

  const [category,setCategory] = useState("Today")
  const [month,setMonth] = useState(getMonth(new Date())) 
  const [year,setYear] = useState(new Date().getFullYear())

  const [motherSelectionMap,setMotherSelectionMap] = useState({...props.selectionMap});
  const [optionsMap,setOptionsMap] = useState({})
  const [filtValues,setFiltValues] = useState([])
  const [filtOptions,setFiltOptions] = useState([])

  const [fil1Value,setFil1Value] = useState("")
  const [fil2Value,setFil2Value] = useState("")
  const [fil2Selections,setFil2Selections] = useState([...props.selectionMap[Object.keys(props.selectionMap)[0]]])

useEffect(()=>{

  fil1ValueChange(Object.keys(props.selectionMap)[0])


},[])



console.log(props.selectionMap)

function fil1ValueChange(value){

  console.log(value)

  const fil2Selections  = props.selectionMap[value]

  console.log(fil2Selections)



  setFil2Selections(fil2Selections)
  setFil1Value(value)
  setFil2Value(fil2Selections[0])

  const eventMaskObj = {}
  eventMaskObj["target"]={}
  eventMaskObj["target"]["value"] = `${value}^${fil2Selections[0]}`;
  props.onValueSelected(eventMaskObj)


}

function fil2ValueChange(value,fil1Value){
  setFil2Value(value)
  const eventMaskObj = {}
  eventMaskObj["target"]={}
  eventMaskObj["target"]["value"] = `${fil1Value}^${value}`;
  props.onValueSelected(eventMaskObj)
}


  return (
    <div className="flex gap-4">
        <select
        onChange={(e)=>fil1ValueChange(e.target.value)}
        >
         {Object.keys(props.selectionMap).map((selection)=><option value={selection}>{selection}</option>)}
        </select>
        
        <select
           onChange={(e)=>fil2ValueChange(e.target.value,fil1Value)}
           value={fil2Value}
           >
            {fil2Selections.map((selection)=><option value={selection}>{selection}</option>)}
        </select>
    </div>
  );
}
