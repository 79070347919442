import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import ImageCont from "./ImageCont";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordBanner from "./RecordBanner";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";


//const db=getFirestore(firebase);

//function to create an empty field object with keys of the templateObj passed to it
function createInitialObj(templateObj) {
  const obj = {};

  templateObj[templateObj.mainKey].forEach((field) => {
    obj[field] = "";
  });

  return obj;
}

export default function ListCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbNotations);
  console.log(props.passMap);
  console.log(props.passListDataArray);
  return (
    <React.Fragment>
      <div className="grid grid-rows-cust-2c">
        <div>
        {props.passListDataArray.map((dataMap, index) => {
          return (
            <RecordBanner
              key={dataMap.prepaidCardRequestId}
              slNo={index + 1}
              labelNames={props.passMap.labelNames}
              labelDbNotations={props.passMap.labelDbNotations}
              btnAlertLevels={props.passMap.btnAlertLevels}
              btnLabels={checkAndReturnBtnLabels(
                props.passMap,
                props.passListDataArray[index]
              )}
              fnName={props.passMap.fnName}
              passDataMap={dataMap}
              passDataMap2={props.passMap.settings}
              actionHandler={(data) => props.actionHandler(data)}
            />
          );
        })}
        {props.passListDataArray.length == 0 && (
         <NoAvailableDisplay textName="No Records Found"/>
        )}
        </div>
              {bottomContainer}
      </div>

    </React.Fragment>
  );
}

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}
