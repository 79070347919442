import { ref } from "firebase/database";
import React, { useState } from "react";
import { editEmployeeInputObj, editToolInputObj } from "../Utils";
import ActionBtnCont from "./ActionBtnCont";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxMessagePromptWithMultipleOps from "./ModalBoxMessagePromptWithMultipleOps";
import ModalBoxWithInput from "./ModalBoxWithInput";

import { useEffect } from "react";
import {
  child,
  database,
  functions,
  get,
  httpsCallable,
  update
} from "../Utils";
import BottomContainer from "./BottomContainer";
import ButtonIconTitle from "./ButtonIconTitle";
import { getBtnIcon, getColorClass } from "./HelperFns";
import LoadSpinner from "./LoadSpinner";

export default function FnSelectCont(props) {
  console.log(props);
  const [displaySelections, setDisplaySelections] = useState(true);
  const [selectionTopic, setSelectionTopic] = useState(props.passMap.topic);
  const [selectionMapColl, setSelectionMapColl] = useState([
    // { name: "", uid: props.passMap.uid, category: props.passMap.category },
  ]);
  const [selAccountMap, setSelAccountMap] = useState({});

  const [showActionBtnCont, setShowActionBtnCont] = useState(false);
  const [showHelperCont, setShowHelperCont] = useState(false);
  const [helperContType, setHelperContType] = useState("");
  const [inputDataMap, setInputDataMap] = useState(props.inputDataMap);
  const [selColorClass, setSelColorClass] = useState("");
  const [sMap, setSMap] = useState({});

  const [showPromptOp, setShowPromptOp] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [showModalBox, setShowModalBox] = useState(false);
  const [modalMap, setModalMap] = useState({});
  const [showPrompt, setShowPrompt] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    initializeSelections(props.passMap);
  }, []);

  async function initializeSelections(map) {
    console.log(map);
    switch (map.topic) {
      case "Manage Employees":
        {
          setInputDataMap(editEmployeeInputObj);
          const employeesMap = await get(
            child(ref(database), `employees/${map.uid}`)
          );

          console.log(employeesMap.exists())

          if(employeesMap.exists()){

            const mapColl = [];

            Object.keys(employeesMap.val()).forEach((keyName, i) => {
              const map = {};
              map["name"] = employeesMap.val()[keyName].name;
              map["isDisabled"] = employeesMap.val()[keyName].isDisabled;
              map["isAbsent"] = employeesMap.val()[keyName].isAbsent;
              map["isEngaged"]=employeesMap.val()[keyName].isEngaged;
              map["isSelected"] = false;
              map["index"] = i;
              map["uid"] = keyName;
              map["category"] = "Employee";
  
              mapColl.push(map);
            });
  
            console.log(mapColl);
  
            setSelectionMapColl([...mapColl]);
            props.stopSpinner()
          }else{
            setSelectionMapColl([]);
            props.stopSpinner()
          }
       
        }
        break;
      case "Manage Tools": {

        setInputDataMap(editToolInputObj);
        const toolsMap = await get(child(ref(database), `tools/${map.uid}`));

        if(toolsMap.exists()){
          const mapColl = [];

          Object.keys(toolsMap.val()).forEach((keyName, i) => {
            const map = {};
            map["name"] = toolsMap.val()[keyName].name;
            map["isDisabled"] = toolsMap.val()[keyName].isDisabled;
            map["isAbsent"] = false;
            map["isSelected"] = false;
            map["index"] = i;
            map["uid"] = keyName;
            map["category"] = "Tool";
  
            mapColl.push(map);
          });
  
          console.log(mapColl);
          
          setSelectionMapColl([...mapColl]);
          props.stopSpinner()
        }else{

          setSelectionMapColl([]);
          props.stopSpinner()
        }

      }
    }
  }

  async function enterAccountTree(map) {
    if (map.category == "Store") {
      //const storeBrandsRef = ref(database, `storeBrands`);
      const storeBrandsMap = await get(child(ref(database), `storeBrands`));

      const mapColl = [];

      Object.keys(storeBrandsMap.val()).forEach((keyName) => {
        const map = {};
        map["name"] = storeBrandsMap.val()[keyName].name;
        map["uid"] = keyName;
        map["category"] = "Store Brand";

        mapColl.push(map);
      });

      setSelectionMapColl([...mapColl]);
    } else if (map.category == "Store Brand") {
      const storeBranchesMap = await get(
        child(ref(database), `storeBranches/${map.uid}`)
      );

      const mapColl = [];

      Object.keys(storeBranchesMap.val()).forEach((keyName) => {
        const map = {};
        map["name"] = storeBranchesMap.val()[keyName].name;
        map["uid"] = keyName;
        map["category"] = "Store Branch";

        mapColl.push(map);
      });

      setSelectionMapColl([...mapColl]);
    } else if (map.category == "Store Branch") {
      const storeBranchesMap = await get(
        child(ref(database), `employees/${map.uid}`)
      );

      const mapColl = [];

      Object.keys(storeBranchesMap.val()).forEach((keyName) => {
        const map = {};
        map["name"] = storeBranchesMap.val()[keyName].name;
        map["uid"] = keyName;
        map["category"] = "Employee";

        mapColl.push(map);
      });

      setSelectionMapColl([...mapColl]);
    } else if (map.category == "Employee") {
    }
  }

  function selectionFn(e, map, mapIndex) {
    console.log(map);

    setShowActionBtnCont(true)
    const cMapColl = [...selectionMapColl];
    const tMapColl = [];

    cMapColl.forEach((lMap, i) => {
      console.log(lMap)
      if (lMap.isSelected && lMap.uid != map.uid) {
        lMap.isSelected = false;
      } else if (!lMap.isSelected && lMap.uid == map.uid) {
        lMap.isSelected = true;
        setSelAccountMap(lMap);
      }
      console.log(lMap)
      tMapColl.push(lMap);
    });

    setSelectionMapColl([...tMapColl]);

  }

  async function disableAccount(map) {
    const serverCall = httpsCallable(functions, "disableUser");
    const result = await serverCall({ uid: map.uid });
    alert(result.data);
  }

  async function fnHandle(fnType, fnName, selMap) {

    if (fnType == "edit") {
      switch (fnName) {
        case "Manage Employees":
          {
         
            console.log(sMap)

            const payload = {
              userUid:selMap.uid,
              userRole:"Employee",
              parentUid:props.passMap.uid
            }

            setShowSpinner(true);

                try{
                    const cloudFnCall = httpsCallable(functions,"getUserDataByClient");
                    const op = await cloudFnCall(payload);

                    console.log(op.data)

                    if(op === null){
                      
                      setShowSpinner(false);
                      displayPrompt("Error in Fetching Data")
                      return
                    }

                    const result = op.data

                    if(!result.status){
                      setShowSpinner(false);
                      displayPrompt(result.message)
                      return
                    }
                    
                    const resultData = result.data

                    const empUserDataMap = {}
                      empUserDataMap["userName"] = resultData.displayName;
                      empUserDataMap["userEmail"] = resultData.email;
                      empUserDataMap["password"] = "";


                      const opBtnLabels = ["Submit"]
                      const opBtnAlertLevels = ["high"]
                      const opSuccessMsgs  = ["Employee Successfully Edited"]


                      const editFn = async (data) => {
                        const cloudFnCall = httpsCallable(functions,"editEmployeeDetails");
                        const op = await cloudFnCall(data.payload);
                        return op;
                      }
                  
                    setModalMap({
                      ...editEmployeeInputObj,
                      inpValMap:empUserDataMap,
                      extraValMap:payload,
                      isModal:true,
                      fnType: "modal",
                      tabOptionsReqd: false,
                      title:"Edit Employee",
                      opBtnLabels:opBtnLabels,
                      opBtnAlertLevels:opBtnAlertLevels,
                      opSuccessMsgs:opSuccessMsgs,
                      opFns:[editFn]
                    })

                
                     setShowModalBox(true);


              }catch(error){
                displayPrompt(error)
              }
              setShowSpinner(false);
          }
          break;
        case "Manage Tools":
          {
            {
              setSMap({
                ...editToolInputObj,
                uid: selMap.uid,
                creatorUid: props.passMap.uid,
                fnType: "others",
                tabOptionsReqd: false,
              });
              //setScreenTitle("Edit Employee");
            }
            setShowModalBox(false);
            setShowHelperCont(true);
            setHelperContType("input");
          }
          break;
      }
    } else if (fnType == "disable") {

 
      switch (fnName) {
        case "Manage Employees":
          {
            setShowSpinner(true);
   
            
          // const cMapColl = [...selectionMapColl];
          //   const currentMap = cMapColl[selMap.index];

          //   console.log(currentMap)

          //   if(!currentMap.isEngaged){
          //     await update(
          //       child(
          //         ref(database),
          //         `employees/${props.passMap.uid}/${selMap.uid}`
          //       ),
          //       {
          //         isDisabled: true,
          //       }
          //     );

          //     currentMap.isDisabled = true;
          //     cMapColl.splice(currentMap.index, 1, currentMap);
  
          //     setSelectionMapColl([...cMapColl]);

          //     setShowSpinner(false);

          //     displayPrompt("Employee Disabled Successfully")
              
            //await initializeSelections(props.passMap);


            const cMapColl = [...selectionMapColl];
            const currentMap = cMapColl[selMap.index];

            if(!currentMap.isEngaged){

              
            const dialogTitle = "Disable Employee"
            const dialogMsg = "Disabling an employee will prevent the employee to access account. If an employee is in middle of an order, he wont be able to update orders. Do you wish to Proceed?"
      
            const opBtnLabels = ["Proceed to Disable"]
            const opSuccessMsgs  = ["Employee Disabled Successfully"]
            const opBtnAlertLevels = ["high"]
      
            const employeeDisableFn = async () =>{
    
                  await update(
                    child(
                      ref(database),
                      `employees/${props.passMap.uid}/${selMap.uid}`
                    ),
                    {
                      isDisabled: true,
                    }
                  );
    
                  currentMap.isDisabled = true;
                  cMapColl.splice(currentMap.index, 1, currentMap);
            }
      
      
              const opFns = [employeeDisableFn]


            setPromptMap({
              status:false,
              title:dialogTitle,
              message:dialogMsg,
              opBtnLabels:opBtnLabels,
              opBtnAlertLevels:opBtnAlertLevels,
              opSuccessMsgs:opSuccessMsgs,
              opFns:opFns
            })
            
            setShowSpinner(false);
            setShowPromptOp(true)
        
            
      
          }else{
            setShowSpinner(false);
            displayPrompt(`Error:Employee is executing an order`)

          }

          }
          break;
        case "Manage Tools":
          {
            await update(
              child(ref(database), `tools/${props.passMap.uid}/${selMap.uid}`),
              {
                isDisabled: true,
              }
            );

            const cMapColl = [...selectionMapColl];
            const currentMap = cMapColl[selMap.index];
            currentMap.isDisabled = true;
            cMapColl.splice(currentMap.index, 1, currentMap);

            setSelectionMapColl([...cMapColl]);
            //await initializeSelections(props.passMap);

            alert`Tool Disabled Successfully`;
          }
          break;
      }
    } else if (fnType == "enable") {
      console.log("Enableeer")
      switch (fnName) {
        case "Manage Employees":
          {
            setShowSpinner(true);
            const cMapColl = [...selectionMapColl];
            const currentMap = cMapColl[selMap.index];

            console.log(currentMap)

              await update(
                child(
                  ref(database),
                  `employees/${props.passMap.uid}/${selMap.uid}`
                ),
                {
                  isDisabled: false,
                }
              );
              setShowSpinner(false);

              currentMap.isDisabled = false;
              cMapColl.splice(currentMap.index, 1, currentMap);
  
              setSelectionMapColl([...cMapColl]);

              displayPrompt(`Employee Enabled Successfully`)
              
            //await initializeSelections(props.passMap);
   
          }
          break;
        case "Manage Tools":
          {
            await update(
              child(ref(database), `tools/${props.passMap.uid}/${selMap.uid}`),
              {
                isDisabled: false,
              }
            );

            const cMapColl = [...selectionMapColl];
            const currentMap = cMapColl[selMap.index];
            currentMap.isDisabled = false;
            cMapColl.splice(currentMap.index, 1, currentMap);

            setSelectionMapColl([...cMapColl]);

            //await initializeSelections(props.passMap);
            alert`Tool Enabled Successfully`;
          }
          break;
      }
    }else if (fnType == "delete") {

      setShowPromptOp(true);

      const dialogTitle = "Delete Employee"
      const dialogMsg = "Proceeding will delete employee account and all related data which cannot be retrieved later. Do you wish to Proceed?"

      const opBtnLabels = ["Proceed to Delete"]
      const opSuccessMsgs  = ["Employee deleted Successfully"]
      const opBtnAlertLevels = ["high"]


      const employeeDeleteFn = async (payload) =>{

          const cloudFnCall = httpsCallable(functions,"deleteUserAccountByClient");
          const op = await cloudFnCall(payload);

          }

          const employeeDeleteFnPayload = {
            userUid:selMap.uid,
            userRole:"Employee",
            parentUid:props.passMap.uid
          }

        const opFns = [employeeDeleteFn]
        const opFnPayloads = [employeeDeleteFnPayload]
       

      setPromptMap({
        status:false,
        title:dialogTitle,
        message:dialogMsg,
        opBtnLabels:opBtnLabels,
        opBtnAlertLevels:opBtnAlertLevels,
        opSuccessMsgs:opSuccessMsgs,
        opFns:opFns,
        opFnPayloads:opFnPayloads
      
      })

    }
  }


  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  function helperContBackHandle() {
    setShowHelperCont(false);
    setSelColorClass("");
  }

  
  function btnClickHandle(data){
    switch(data){
      case "closeCont":{
        setShowActionBtnCont(false)
      }
      break;
      case "disable":{

      }
    }
  }

  function displayPrompt(message,title,status){
    setShowSpinner(false)
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message
    })
  }

  return (
    <div className="h-screen grid grid-rows-cust-2c p-cust-topHead pb-24">
      <div className={`grid ${showHelperCont ? `grid-cols-cust-2b` : ``} p-4 pb-8`}>
        <div className="grid grid-rows-cust-3c rounded bg-quaternary-light border-std">
          <div className="text-xl flex justify-center">
            {/* <div className="cont-title-red-marker text-2xl p-4 ml-4">{props.passMap.topic}</div> */}
            </div>
        <div key={`${new Date().getTime()}`} className="flex items-center justify-center gap-8">
          <div className="flex flex-wrap items-center justify-center gap-8">
          {selectionMapColl.length!=0 && selectionMapColl.map((map, i) => {
            return (
              <ButtonIconTitle
                key={map.name}
                isDisabled={map.isDisabled}
                btnColorClass={getColorClass(
                  map.isDisabled,
                  map.isSelected,
                  map.isAbsent
                )}
                section="Selection"
                btnTitle={map.name}
                btnIconSize={"h-20 w-20"}
                btnIcon={getBtnIcon(map.category)}
                clickHandler={(e) => selectionFn(e, map, i)}
              ></ButtonIconTitle>
            );
          })}
          </div>
          {selectionMapColl.length==0 && 
          <span>No Records Found</span>}
        </div>
        <div key={`s`}  className="text-xl flex justify-center">
          {showActionBtnCont && <ActionBtnCont topic={props.passMap.topic} 
          selAccMap={selAccountMap}
          fnHandler={(data)=>fnHandle(data,props.passMap.topic,selAccountMap)}
          closeContHandler={()=>setShowActionBtnCont(false)}/>}
        </div>
        </div>
        {/* {showHelperCont && helperContType == "input" && (
          <InputHelperCont
            key={sMap.uid}
            passMap={sMap}
            backBtnClickHandler={() => helperContBackHandle()}
          />
        )} */}
      </div>
      <div className="absolute w-full h-24 bottom-0 flex justify-center items-center">
        {bottomContainer}
        </div>
      {showPromptOp && <ModalBoxMessagePromptWithMultipleOps passMap={promptMap} promptClose={()=>setShowPromptOp(false)}/>}
      {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
      {showModalBox && (
        <ModalBoxWithInput
          passMap={modalMap}
          modalCloseHandler={()=>setShowModalBox(false)}
        />
      )}
      {showSpinner && <LoadSpinner/>}
    </div>
  );
}


