import React, { Component } from "react";
import RecordLabelUnit from "./RecordLabelUnit";
import { getStoreLabelValue, getDate } from "../Utils";

export default function RecordBtnStrip(props) {
  console.log(props);

  function checkAndReturnValue(value, labelName) {
    if (labelName == "Date/Time") {
      return getDate(value);
    } else {
      return value;
    }
  }
  return (
    <div className="w-11/12 grid grid-cols-cust-2e gap-4 mt-1 mx-auto">
      <div
        className={`title-strip w-full grid mt-1 ${
            `grid-cols-cust-7a`
        }
        bg-quartenaryLight rounded-sm border border-borderStd`}
      >
        {props.stripValues.map((value, i) => {
          return (
            <div className="value-strip-unit text-center text-lightBackText">
              {getStoreLabelValue(props.labelNames[i],value,props.passMap2)}
            </div>
          );
        })}
      </div>
      <div className={`grid grid-cols-${props.btnLabels.length} mt-1`}>
        {props.btnLabels.map((btnLabelName, i) => (
          <button
            className={`btn-strip ${props.btnAlertLevels[i]=="high"?"theme-btn-red":"theme-btn-black"}`}
            onClick={() =>
              props.btnClickHandler({
                btnType: btnLabelName,
                stripValues: props.stripValues,
              })
            }
          >
            {btnLabelName}
          </button>
        ))}
      </div>
</div>

  );
}




