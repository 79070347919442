import React from "react";
import { getDate, getStoreLabelValueAdmin } from "../Utils";

export default function RecordBtnStrip7(props) {
  console.log(props);

 // const columnWidths=props.listTopic == "balances" ? `grid-cols-cust-8b` : props.listTopic == "signUpRequest" ? `grid-cols-cust-5a` : `grid-cols-cust-8c`

  function checkAndReturnValue(value, labelName) {
    if (labelName == "Date/Time") {
      return getDate(value);
    } else {
      return value;
    }
  }
  return (
    <div className="w-11/12 grid grid-cols-cust-2e gap-4 mt-1 mx-auto">
      <div className={`title-strip w-full grid mt-1 ${props.stripValGridColsScheme} bg-quartenaryLight rounded-sm border border-borderStd`} >
              {props.labelDbKeys.map((dbNotations, i) => {
                return (
                  <div className="value-strip-unit text-center text-lightBackText">
                    {getStoreLabelValueAdmin(
                      props.labelNames[i],
                      props.stripValueMap[dbNotations],
                      "CAD"
                    )}
                  </div>
                );
              })}
      </div>
      <div className={`grid grid-cols-${props.btnLabels.length} mt-1`}>
        {props.btnLabels.map((btnLabelName, i) => (
          <button
            className={`btn-strip ${props.btnAlertLevels[i]=="high"?"theme-btn-red":"theme-btn-black"}`}
            onClick={() =>
              props.btnClickHandler({
                btnType: btnLabelName,
                stripValueMap: props.stripValueMap,
              })
            }
          >
            {btnLabelName}
          </button>
        ))}
  </div>



      {/* <div className="m-cust-2 grid grid-cols-2">
        {props.btnLabels.map((btnLabelName, i) => (
          <button
            className="btn-strip"
            onClick={() =>
              props.btnClickHandler({
                btnType: btnLabelName,
                stripValueMap: props.stripValueMap,
              })
            }
          >
            {btnLabelName}
          </button>
        ))}
      </div> */}
    </div>
  );
}

// typeof props.passDataMap[props.labelDbKeys[index]] == "object"
//   ? props.passDataMap[props.labelDbKeys[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbKeys[index]]
