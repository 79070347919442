import { React, useState } from "react";
import { getInpOptions } from "../Utils";

export default function DualInputWrapperNew(props) {

  console.log(props)

  const [inpValdOkCond,setInpValdOkCond] = useState(true)
  const [deductedVald,setDeductedVald] = useState(false)
  const [inp1,setInp1] = useState("+91");
  const [inp2,setInp2] = useState(null);

  


  function onChangeHandler(e,position,inp1,inp2,inpName){

    const eMap={}
    eMap["target"] = {}

    const inpVals = [inp1,inp2]
    inpVals[position] = e.target.value

    if(position==0){

      setInp1(e.target.value)
    }else{

      setInp2(e.target.value)
    }

    console.log(inpVals)

    if(props.inpType=="phoneNo"){
     // const value = `${inpVals[0]}${inpVals[1]}`


      eMap.target = {
        value:[inpVals[0],inpVals[1]],
        type:"phoneNo",
        name:props.inpName
      }
      console.log(eMap)

      props.onChangeHandler(eMap)
    }

    // props.onChangeFn(e,inpKey)
    // console.log(e.target.value)

  }



  function getStyleClass(inpValdOkCond){
    return inpValdOkCond ? `` : `bg-secondary-light-transparent`
  }


  return (
    <div >
      {/* {getDualInputs(props,onChangeHandler,inp1,inp2)} */}
      {getDualInputs(props,onChangeHandler,getSelectionVal(props.values ? props.values[0] : null ,props.inpType),props.values ? props.values[1] : null )}
    </div>
  );
}


function getSelectionVal(dbVal,inpType){

  if(inpType=="phoneNo"){
    return dbVal ? dbVal : "+91"
  }else{
    return dbVal
  }
}

function getDualInputs(props,onChangeHandler,inp1,inp2){

  if(props.inpType=="phoneNo"){
    return(
    <div className="flex">
      {getSelection("countryCodes",onChangeHandler,inp1,inp2)}
      <input
          key={props.key}
          className="w-full"
          type={"number"}
          value={inp2}
          onChange={(e)=>onChangeHandler(e,1,inp1,inp2)}
        ></input>
    </div>
    )
  }
}


function getSelection(type,onChangeHandler,inp1,inp2){

  return(
    <select value={inp1} onChange={(e)=>onChangeHandler(e,0,inp1,inp2)}>
      {getInpOptions(type).map((selection)=>
      <option value={selection}>{selection}</option>
      )
      }
    </select>
  )

}
