

import { useEffect, useRef, useState } from "react"
import { uploadIcon, viewIcon } from "./Assets"
import { mimeTypeFileTypeCorrelationMap } from "./DataList"
import { checkCommonElementCountBetween2Array } from "./genFunctions"
import { styleMapProject } from "./viewProps"


export default function FileUploadUnit(props){

    console.log(props)

    const [fileUploadCond, setFileUploadCond]=useState(false)
    const [fileSizeErrCond, setFileSizeErrCond]=useState(false)
    const fileInpElemRef = useRef(null)




    useEffect(()=>{
        if(props.value=="set"){
            setFileUploadCond(true)
        }

    },[props.value])


    function fileInpClickFn(mode){

            if(mode=="upload"){
                if(props.accessMode=="assess"){
                    props.assessorClickHandler()
                }else{
                    if(disableUploadCond){

                    }else{
                        const elem = fileInpElemRef.current;
                        elem.click()
                    }
                }

            }else if(mode=="view"){
          
                props.viewFileHandler()
            }

      }


      function checkAllowedMimeTypeBasedOnAllowedFileType(file,allowedFileTypesStr){

        const fileMimeType = file.type;
        const allowedFileTypes = allowedFileTypesStr.split(",");

         if (fileMimeType in mimeTypeFileTypeCorrelationMap && checkCommonElementCountBetween2Array(mimeTypeFileTypeCorrelationMap[fileMimeType],allowedFileTypes)>0){
            return true
         }else{
            return false;
         }

      }
    

    console.log(props)

    function onChangeFn(e){
        //setFileUploadCond(true)
        const oneMBinBytes = 10000000
        //console.log(e.target.files[0])

        const file = e.target.files[0]
        
        if(file.size > oneMBinBytes || !checkAllowedMimeTypeBasedOnAllowedFileType(file,props.allowedFileTypesStr)){
            setFileSizeErrCond(true)
        }else{
            setFileSizeErrCond(false)
            props.onChange(e)
        }
       
    }

    const btnColor = props.accessMode == "assess" ? props.resubInpCond ? "bg-redPale" : getBtnColor(props,fileSizeErrCond) :  getBtnColor(props,fileSizeErrCond) ;
    const disableUploadCond = props.disableUploadCond ? props.disableUploadCond : false;
    const label = fileSizeErrCond ? "File Size/Type Error " : props.uploadedCond ? "Uploaded": disableUploadCond ? "Not Uploaded":"Upload";




    return(
        <div className={`bg-hover grid ${styleMapProject.gridCols["2-file"]} ${btnColor} text-darkBackText justify-center items-center mx-2 px-2 h-8 rounded-lg border-bg-red cursor-pointer`} >
            <div className="" onClick={()=>fileInpClickFn("upload")}>{label}</div>
            <div className="flex justify-end gap-2">
                {!disableUploadCond &&
                 <img onClick={()=>fileInpClickFn("upload")} className="f-color-white" src={uploadIcon} width="20" height="20"></img>
                }
                {props.uploadedCond==1 &&
                  <img onClick={()=>fileInpClickFn("view")} className="f-color-white" src={viewIcon} width="20" height="20" ></img>
                }
            </div>
            {!disableUploadCond && 
                <input
                    ref={fileInpElemRef} 
                    className="hidden w-full"
                    type="file"
                    accept={props.allowedFileTypesStr}
                    onChange={(e)=>onChangeFn(e)}
                ></input>
            }
        </div>
    )
}


function getBtnColor(props,fileSizeErrCond){

    if(props.accessMode=="resubmit" && !props.disableUploadCond){
        return "bg-redPale"
    }

    if(fileSizeErrCond){
        return "bg-red"
    }else if(props.inpValidCond){
        return props.uploadedCond ? "bg-green" : "bg-secondary"
    }else{
        return "bg-red";
    }
}