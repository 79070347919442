import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserDataContext } from "./Level1";

export default function BottomContainer(props) {
  const userData = useContext(UserDataContext);

  console.log(userData);

  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center">
      <button
        className="btn-action"
        onClick={() => props.backBtnClickHandler()}
      >
        Back
      </button>
    </div>
  );
}
