import React from "react";
import { useNavigate } from "react-router-dom";
import { strMap } from "../Utils";
import { ReactComponent as AcceptedOrderIcon } from "../images/accepted-order.svg";
import { ReactComponent as AddCardIcon } from "../images/add-card.svg";
import { ReactComponent as AddCustomerIcon } from "../images/add-customer.svg";
import { ReactComponent as AddEmployeeIcon } from "../images/add-employee.svg";
import { ReactComponent as AddIcon } from "../images/add.svg";
import { ReactComponent as BillingIcon } from "../images/billing.svg";
import { ReactComponent as ClearOrderIcon } from "../images/clear-order.svg";
import { ReactComponent as CurrentOrderIcon } from "../images/current-order.svg";
import { ReactComponent as CustomerIcon } from "../images/customer-line.svg";
import { ReactComponent as DisplayCurrentOrderIcon } from "../images/display-current-order.svg";
import { ReactComponent as EmployeeStatIcon } from "../images/employee-stat.svg";
import { ReactComponent as InviteCustomerIcon } from "../images/invite-customer.svg";
import { ReactComponent as InvoiceIcon } from "../images/invoice.svg";
import { ReactComponent as JobStatIcon } from "../images/job-stat.svg";
import { ReactComponent as ManageCustomerIcon } from "../images/manage-customer.svg";
import { ReactComponent as ManageEmployeeIcon } from "../images/manage-employee.svg";
import { ReactComponent as ManageUserAccountIcon } from "../images/manage-user-account.svg";
import { ReactComponent as MarkEmployeeIcon } from "../images/mark-employee.svg";
import { ReactComponent as CardRequestIcon } from "../images/mng-card-request.svg";
import { ReactComponent as MngCardIcon } from "../images/mng-card.svg";
import { ReactComponent as OrderIcon } from "../images/order.svg";
import { ReactComponent as PaidInvoiceIcon } from "../images/paid-invoice.svg";
import { ReactComponent as QRSpreadsheetIcon } from "../images/qr-spreadsheet.svg";
import { ReactComponent as UserAddRequestIcon } from "../images/signup-request.svg";
import { ReactComponent as SkateStatIcon } from "../images/skate-stats.svg";
import { ReactComponent as TimeStatIcon } from "../images/stat-time-2.svg";
import { ReactComponent as StoreBranchIcon } from "../images/store-branch.svg";
import { ReactComponent as ArrowIcon } from "../images/transactions.svg";
import { addIcon } from "./Assets";
import FunctionButton from "./FunctionButton";

const inputObj = {
  view: "OrderCont",
};

export default function DashFunctionBox2Project(props) {
  const navigate = useNavigate();

  console.log(props)

  
  return (
    <div className="relative w-fit h-48 p-8 m-cust-1 bg-primaryPale border-std rounded-lg sm:w-full">
      <div className="absolute top-3 left-3 text-secondary">{props.sectionName}</div>
      <div className=" flex flex-row items-center gap-16 text-white">
        {props.fnBtnLabels.map((btnLabel, index) => {
          return (
            <FunctionButton
              key={btnLabel}
              clickHandler={() =>
                props.viewChangeHandler({
                  fnName: btnLabel,
                  fnView: props.fnViews[index],
                })
              }
              btnImage={props.fnBtnImgs[index] ? props.fnBtnImgs[index] : addIcon}
              btnText={btnLabel}
              btnColor="white"
              btnNotification={props.fnNotifications[index]}
            />
          );
        })}
      </div>
    </div>
  );

 

}

function getBtnImage(btnName) {
  switch (btnName) {
    case "Past Orders":
      return OrderIcon;
    case "Accepted Orders":
      return AcceptedOrderIcon;
    case "Current Orders":
      return CurrentOrderIcon;
      case "Display Current Orders":
        return DisplayCurrentOrderIcon;
        case "Invoices":
          return InvoiceIcon;
    case "Paid Invoices":
        return PaidInvoiceIcon;
               case "Unpaid Invoices":
            return InvoiceIcon;
    case "Balances":
              return BillingIcon;
    case "Clear Collections":
     return ClearOrderIcon;
    case "Transactions":
      return ArrowIcon;
    case "Add Prepaid Scheme":
      return AddCardIcon;
    case "Manage Prepaid Scheme":
      return MngCardIcon;
    case strMap.dshBtn.checkPpdCardRequest:
      return CardRequestIcon;
    case "Add Employee":
      return AddEmployeeIcon;
    case "Manage Employees":
      return  ManageEmployeeIcon;
    case "Mark Attendance":
      return  MarkEmployeeIcon;
    case "Add Customer":
      return AddCustomerIcon;
    case "Invite Customers":
      return InviteCustomerIcon;
    case "Manage Customers":
      return ManageCustomerIcon;
    case "Job Stats":
      return JobStatIcon;
    case "Time Stats":
      return TimeStatIcon;
    case "Customer Stats":
      return CustomerIcon;
    case "Employee Stats":
      return EmployeeStatIcon;
    case "Skate Stats":
        return SkateStatIcon;
    case "Manage User Account":
        return  ManageUserAccountIcon;
    case "QR Sequence Sheet":
        return QRSpreadsheetIcon;
    case "Manage Store Branches":
        return StoreBranchIcon;
    case strMap.dshBtn.storeBrandSignUpRequest:
         return UserAddRequestIcon;
    default:
      return AddIcon;
  }
}
