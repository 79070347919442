import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addCustomerExternalInputObj, functions, httpsCallable } from "../Utils";
import InputTile2 from "./InputTile2";
import LoadSpinner from "./LoadSpinner";
import ModalBoxAgreement from "./ModalBoxAgreement";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";


export default function SignUpScreen2(props) {
  const navigate = useNavigate();
  const location = useLocation()

  const schemeMap = addCustomerExternalInputObj

  const [valdCount,setValdCount] = useState(0)
  const [inpValMap,setInpValMap] = useState(createInitInpValMap(schemeMap))
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [storeBrandName, setStoreBrandName] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showModalBox, setShowModalBox] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [hint,setHint] = useState(false)
  // const [storeBranchName, setStoreBranchName] = useState(null);

console.log(location.state)


  function createInitInpValMap(schemeMap){
    const rMap={}
    schemeMap.inputNames.forEach((key)=>rMap[key]="")
    return rMap;
  }

  function displayPrompt(title,message,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }




  function inputChangeFn(e,dbKey,sInpValMap){

    const tempValMap = JSON.parse(JSON.stringify(sInpValMap));

    tempValMap[dbKey] = e.target.value;

    setInpValMap(tempValMap)
  }


  async function modalActionFn(fnName,data){

    setShowModalBox(false)

    let payload = {}


    if(schemeMap.inputDomain=="Customer"){

      payload = {
        ...data,
        name : data.lastName ? `${data.firstName} ${data.lastName}` : `${data.firstName}`,
        contactNo:data.contactNoComps.length == 2 ? `${data.contactNoComps[0]}${data.contactNoComps[1]}`:null,
        noAppCond:true,
        inputDomain:schemeMap.inputDomain
      }

    }else{
      payload = {
        email: data.email,
        password: data.password,
        name: data.storeBrandName,
        settings: {
          logoLocation: "",
          prepaidCardValidity: "Yes",
        },
        inputDomain: "Store Brand",
      };
    }
    
    console.log(payload)

    
    setShowSpinner(true);

    const result = httpsCallable(functions, schemeMap.executionFnName);
    const resultData = await result(payload);
    setShowSpinner(false);


    displayPrompt("Sign Up",JSON.stringify(resultData.data),true)

  }
  

  return (
  <div className="h-screen grid grid-rows-cust-3g bg-quaternary-medium-light justify-center">
      <div  className="absolute right-10 top-5 flex gap-4">
            <a
              href="../data/privacy-policy.pdf"
              target="_blank"
              className="btn-action-sm hover-white"
            >
         
              Privacy Policy
            </a>
            <a
              href="../data/terms-of-use.pdf"
              target="_blank"
              className="btn-action-sm hover-white"
            >
         
              Terms of Use
            </a>
      </div>
      
  <div className="h-full flex items-center justify-center">
    <span className="text-8xl">SIGN UP</span>
  </div>

  <div className="flex flex-col gap-4">
    {schemeMap.inputNames.map((dbKey,i)=>
        <InputTile2
          key={`${schemeMap.inputNames[i]}-${i}`} //passDataObj has all data from props object
          inputName={dbKey}
          inputLabel={schemeMap.inputLabels[i]}
          inputType={schemeMap.inputTypes[i]}
          inputValue={inpValMap[dbKey]}
          changeValdCount={(val)=>setValdCount(valdCount+val)}
          inpMandCond={"mandInpKeys" in schemeMap ? schemeMap.mandInpKeys.includes(schemeMap.inputNames[i]) : false}
          regexDomain={Object.keys(schemeMap).includes("inputRegexDomains")?schemeMap.inputRegexDomains[i]:""}
          onChangeFn={(e) => inputChangeFn(e,dbKey,inpValMap)}
      />
    )}

  </div>
  
  <div className="absolute bottom-10 w-screen">
      {valdCount>=0 &&
            <div className="w-full flex-center gap-8">
              <button
                    onClick={() => setShowModalBox(true)}
                    className="btn-std hover-black bg-primary-600 text-primary-100"
                  >
                    Sign Up
                  </button>

            </div>
      }
      
   </div>



   {showSpinner && <LoadSpinner />}
   {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
   {showModalBox && <ModalBoxAgreement actionBtnClickHandler={()=>modalActionFn("",inpValMap)} modalClose={()=>setShowModalBox(false)} />}
</div>
  )
}

