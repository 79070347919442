import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { applyActionCode, auth, checkActionCode, confirmPasswordReset, resetPasswordInputObj, verifyPasswordResetCode } from '../Utils';
import InputContDelegate from './InputContDelegate';
import LoadSpinner from './LoadSpinner';
import ModalBoxMessagePrompt from './ModalBoxMessagePrompt';



export default function FirebaseAccountActionHandlingScreen(props){
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [mode,setMode] = useState("")
  const [actionCode,setActionCode] = useState("")
  const [continueUrl,setContinueUrl] = useState("")
  const [userMail,setUserEmail] = useState("")
  const [showSpinner,setShowSpinner] = useState(false)

  const modeLabelMap = {
    resetPassword:"Reset Password",
    recoverEmail:"Recover Email",
    verifyEmail:"Verify Email"
  }

  document.addEventListener('DOMContentLoaded',()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const actionCode = urlParams.get('oobCode');
    const continueUrl = urlParams.has('continueUrl') ? urlParams.get('continueUrl') : "";


    console.log("mode and Code",mode,actionCode,continueUrl)

    setMode(mode)
    setActionCode(actionCode)
    setContinueUrl(continueUrl)

    switch(mode){

      case "resetPassword":{

        verifyPasswordResetCode(auth, actionCode).then((email) => {
          setUserEmail(email)
        }).catch((error)=>{
          displayPrompt(modeLabelMap[mode],error.message,true);
        })

      }
      break;
      case "recoverEmail":{
        checkActionCode(auth,actionCode).then((info)=>{
          // setUserEmail(info['data']['email']);
          return applyActionCode(auth, actionCode);
        }).then(()=>{
          displayPrompt(modeLabelMap[mode],"Account Email Reverted Successfully",true);
        }).catch((error)=>{
          displayPrompt(modeLabelMap[mode],error.message,false);
        })
      }
      break;
      case "verifyEmail":{
        applyActionCode(auth,actionCode).then((resp)=>{
          console.log(resp)
          displayPrompt(modeLabelMap[mode],"Account Verified Successfully",true);
        }).catch((error)=>{
          displayPrompt(modeLabelMap[mode],error.message,true);
        })
      }
      break;
    }

    

  },false);




    
  function displayPrompt(title,message,status){
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }


  function submitHandleFn(mode,actionCode,inpObj){

    setShowSpinner(true)

    switch(mode){
      case "resetPassword":{
              confirmPasswordReset(auth, actionCode, inpObj.password).then(() => {
                setShowSpinner(false)
                displayPrompt(modeLabelMap[mode],"Password Reset Successfully",true);

                if(continueUrl){
                  window.location.replace(continueUrl)
                }

              }).catch((error) => {
                console.log("confirmPasswordResetError=",error)
                setShowSpinner(false)
                displayPrompt(modeLabelMap[mode],error.message,false);
 
              });

       }
       break;
      }
    }
    
  


 return(

        <div className="h-screen grid grid-rows-cust-3g bg-secondary-light-transparent justify-center">
          <div className="flex-center">
                  <span className="text-5xl">{modeLabelMap[mode]}</span>      
                  </div>
          {mode!="resetPassword" && 
            <div></div>
          }

          {mode=="resetPassword" && 
          <div className="flex-center flex-col">
            <div>{userMail}</div>
          <InputContDelegate
            schemeMap={resetPasswordInputObj}
            submitClickHandler={(inpObj)=>submitHandleFn(mode,actionCode,inpObj)}
          />

    
        </div>
              }
        {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
        {showSpinner && <LoadSpinner/>}
      </div>

 );
 





}


