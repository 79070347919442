import React, { useEffect, useState } from "react";
import { getOrderDetailsLabelValue2 } from "../Utils";
import RecordLabelDynamicUnit from "./RecordLabelDynamicUnit";
import RecordLabelUnit2 from "./RecordLabelUnit2";

export default function RecordBanner3(props) {

  const [time,setTime] = useState(new Date().getTime())
  console.log(props)

  const orderCompleteStatus = ["pendingPayment","pendingCollection"];

  const isCompletedOrder = "orderStatus" in props.passDataMap ? orderCompleteStatus.includes(props.passDataMap.orderStatus) : false


  useEffect(()=>{

    setInterval(()=>{
      const time = new Date().getTime()
      setTime(time)

    },5000)

  },[])

  console.log(props);
  return (
    <div className="m-2">
      <div className={`h-28 grid grid-cols-cust-2c rounded-xl ${props.bannerStyleMap["backgroundColorClass"]} border-std items-center`}>
        <div className="h-full flex row-span-2 justify-center items-center text-4xl border-sl-no">
          {props.slNo}
        </div>

        <div className="h-full grid grid-rows-cust-2f grid-cols-cust-3e">
          {props.schemeMap.labelNames.map((labelName, i) => {
            if( getDisplayCurrentOrderBannerValue(isCompletedOrder,props.schemeMap.valuePersistances[i]) == "static"){
              return (
                <RecordLabelUnit2
                  key={labelName}
                  showLabelCond={false}
                  textColorScheme={props.bannerStyleMap["color"]}
                  labelName={getDisplayCurrentOrderBannerValue(isCompletedOrder,labelName)}
                  labelValue={getOrderDetailsLabelValue2(
                    getDisplayCurrentOrderBannerValue(isCompletedOrder,props.schemeMap.valueDisplayTypes[i]),
                    props.passDataMap[getDisplayCurrentOrderBannerValue(isCompletedOrder,props.schemeMap.labelDbKeys[i])],
                    props.passDataMap,
                  )}
                />
              );
            }else{
              return (
                <RecordLabelDynamicUnit
                key={labelName}
                showLabelCond={false}
                labelName={getDisplayCurrentOrderBannerValue(isCompletedOrder,labelName)}
                dynamicValue={time}
                labelRefValue={getOrderDetailsLabelValue2(
                  getDisplayCurrentOrderBannerValue(isCompletedOrder,props.schemeMap.valueDisplayTypes[i]),
                  props.passDataMap[getDisplayCurrentOrderBannerValue(isCompletedOrder,props.schemeMap.labelDbKeys[i])],
                  props.passDataMap2,
                )}
              />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}


function getDisplayCurrentOrderBannerValue(orderCompletionStatus,value){

  console.log(value)

  const index = orderCompletionStatus==true ? 1 : 0;

  if(Array.isArray(value)){
    return value[index];
  }

  return value;

}


