import { React, useState } from "react";
import skateLogo from "../images/app-logo.svg";

import {
  functions,
  httpsCallable
} from "../Utils.js";

export default function PrivacyPolicyScreen(props) {
  /*     function clickFunction(){
        props.onClickFnIntermediate();
    } */

    const [email,setEmail] = useState("")
    const [showPrompt,setShowPrompt] = useState(false)
    const [promptMap,setPromptMap] = useState({})
    const [showSpinner,setShowSpinner]=useState(false);

    console.log(props)

    function onChangeFn(e){
      const value = e.target.value;
      setEmail(value)
    }


    async function requestAccountDeletion(email){
      setShowSpinner(true)

      const cloudFnCall = httpsCallable(functions, "requestAccountDeletion");
      const op = await cloudFnCall({
        email:email
      });

      setShowSpinner(false)
        displayPrompt("Account Deletion Request",op.data.message)
     
    }

    
  function displayPrompt(title,message,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }

  return(

    <div className="h-screen p-16 bg-quaternary-light pb-16">

    <div className="w-4/12 flex flex-col items-start">
       <img className="w-40" src={skateLogo} />
       <div className="text-4xl font-bold py-4 text-center">PRIVACY POLICY</div>
    </div>

    <div className="my-16 text-xl">
       Effective date: <b>August 17, 2023</b>
    </div>
    

  <div className="WordSection1 pb-48">
    
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      This Privacy Policy describes how Caddy App Corp. (“Hockey Caddy,” “we,”
      or “us,” or “our”) collects, uses and shares information about people
      (“you” or “your”) who access or use the Hockey Caddy mobile application
      (the “App”), use the services available through the App. or interact with
      us in other ways.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.25pt",
        marginLeft: "-.25pt"
      }}
    >
      Please review this Privacy Policy carefully. By accessing or using our App
      or Services or interacting with us in other ways, you agree to the
      collection, use and processing of your personal information as described
      in this Privacy Policy. If you do not agree to the terms of this Privacy
      Policy, please do not use our App or Services or provide your personal
      information to us.{" "}
    </p>

    <h1 className="text-xl font-bold">
      Welcome to the Hockey Caddy App
      <span style={{ fontWeight: "normal" }}> </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.5pt",
        marginLeft: "-.25pt"
      }}
    >
      Caddy App Corp. (“Hockey Caddy,” “we,” or “us,” or “our”) operates the
      Hockey Caddy mobile application (“App”), which matches customers for skate
      sharpening and repair services with stores that provide those skate
      services. Customers can also use the App to monitor their skate sharpening
      and repair preferences and to receive updates about the status of their
      services orders.&nbsp;{" "}
    </p>
    <h2 className="text-xl font-bold">Scope of This Privacy Policy </h2>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      This Policy describes how Hockey Caddy collects, uses, shares, and
      protects personal information about people who access or use the App or
      interact with us in other ways (“you” or “your”).&nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We use the term “personal information” in this Privacy Policy to refer to
      information that identifies, relates to, describes, or is reasonably
      capable of being associated or linked, directly or indirectly, to
      you.&nbsp;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.25pt",
        marginLeft: "-.25pt"
      }}
    >
      We collect personal information that you submit to us directly, gather
      automatically online by cookies and similar internet technologies, and
      receive from social media and other sources. For example, we collect
      information about you when you:{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "2.25pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Visit the App and any other website, mobile application, online portal
      that we operate and displays a link to this Privacy Policy;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Set up an account on the App;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Use the App to arrange for services or check status;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.1pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Communicate or interact with us in other ways.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "14.35pt",
        marginLeft: "-.25pt"
      }}
    >
      This Privacy Policy does <u>not</u> apply to information that is
      anonymized, which is information that has been modified in a substantial
      way so that it no longer can reasonably identify or be linked to you.
      Except where required by applicable law, this Privacy Policy also does not
      apply to Information we obtain about the employees and representatives of
      business customers, service providers, suppliers, and business partners or
      to Hockey Caddy employees, job applicants, and other personnel.{" "}
    </p>
    <h1 className="text-xl font-bold">
      Personal Information You Provide
      <span style={{ fontWeight: "normal" }}> </span>
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "13.45pt",
        marginLeft: "-.25pt"
      }}
    >
      <i>Account Registration</i>.&nbsp; When you set up and use a Hockey Caddy
      App account, either as a “store” offering skate sharpening/repair services
      or as a retail “customer” for skate sharpening/repair services, we ask for
      your:{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Name of account holder{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      E-mail address{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Phone number{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Street address{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "1.45pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Skater specifics: – name of skater, age, gender, height, skate size, type
      of skate, blade sharpening requirements and preferences{" "}
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Username and Password.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "11.6pt",
        marginLeft: "-.25pt"
      }}
    >
      <i>Customer Service History.&nbsp; </i>When customers use the App, we
      collect information about their service history such as the stores used,
      frequency of use, service charges, and customer satisfaction. The stores
      provide information about the services performed and the charges.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "11.35pt",
        marginLeft: "-.25pt"
      }}
    >
      <i>Payment Mechanism for Stores.&nbsp; </i>The stores will charge
      customers directly for their skate sharpening services and pay Hockey
      Caddy a per transaction fee for use of the App. Stores will need to set up
      a payment mechanism such as a credit card or bank transfer. The store’s
      card or banking information needed to process the transaction fee will be
      collected by a thirdparty payment processor—not by us—and will be subject
      to the processor’s privacy policy, rather than this Privacy Policy.
      Payment processors are not permitted to use personal information for their
      own commercial purposes.<i> </i>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "11.35pt",
        marginLeft: "-.25pt"
      }}
    >
      <i>Requests for Information</i>.&nbsp; When you ask us for information
      about our services, we may ask for your name, e-mail address, phone, and
      information you’re interested in.<b> </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "13.25pt",
        marginLeft: "-.25pt"
      }}
    >
      <i>Surveys</i>
      <b>.</b> When you participate in a survey, we ask for your opinions and
      thoughts and may also ask you to provide your name, contact information,
      and other personal information.{" "}
    </p>
        <h1 className="text-xl font-bold">Automated Data Collection </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".4pt",
        marginLeft: "-.25pt"
      }}
    >
      When you access or use the App, we and our third-party analytics and
      advertising providers may automatically record certain data from your
      device by using various types of technology, such as cookies, clear gifs
      or web beacons (collectively, “cookies”). This data collected this way may
      include your device address or ID, web browser and/or device type, the web
      pages or sites visited just before or just after using the App, the pages
      or other content you viewed in the App, and the dates and times of the
      visit, access, or use of the App. You can use the settings or tools
      offered on your device or browser to refuse or restrict certain cookies or
      to delete cookies that have already been stored. (See the section titled
      “How You Can Restrict{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.65pt",
        marginLeft: "-.25pt"
      }}
    >
      Cookies Automated Data Collection.”){" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.2pt",
        marginLeft: "36.5pt"
      }}
    >
      <i>Log in and identification—</i>We recognize and verify you and your
      device when you log in to access and use your account by storing data
      including, for example, your password, device and browser type, Internet
      Service Provider (ISP) and Internet Protocol (IP) addresses, device and
      browser identifiers and information (including device, application, or
      browser type, version, plug-in type and version, operating system, and
      other technical data).<i></i>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "4.5pt",
        marginLeft: "36.5pt"
      }}
    >
      <i>Location Data</i>.&nbsp;{" "}
      <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
      &nbsp;&nbsp;When you install the App, you will be asked for permission to
      collect geolocation data (GPS) from your device. GPS data enables you to
      use optional features of the App. such as to locate the nearest store or
      how far you are from a particular store. You can remove or add your
      permission to collect GPS data at any time using your device
      settings.&nbsp;&nbsp;{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "36.5pt" }}>
      <i>Data analytics. </i>We track and log web usage data about your
      interactions with the App including, for example, browsing history, usage
      rates, settings preferences, content interactions (including searches,
      page views, downloads, and prints), and user journey history (including
      clickstreams and page navigation, timestamps, content viewed or searched
      for, page interactions, and similar data).<i> </i>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "26.6pt",
        marginLeft: "36.5pt"
      }}
    >
      <i>Advertising.&nbsp; </i>Cookies used for advertising purposes may be set
      when you use the App by us or our advertising partners. We and our
      advertising partners may use cookies to build a profile of your interests
      and show you relevant advertisements on our App and on other mobile
      applications and websites. Advertising cookies store data that can
      uniquely identify your browser and device. If you do not allow these
      cookies, you will still receive advertisements, but they will be less
      targeted specifically to <b>you. </b>
    </p>
        <h1 className="text-xl font-bold">
      How We Use Personal Information We Collect{" "}
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.45pt",
        marginLeft: "-.25pt"
      }}
    >
      We use personal information to conduct our business, stay in contact with
      our customers, and meet their expectations, including to&nbsp;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Register new user accounts{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".6pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Provide, operate, maintain, improve, and promote the App;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "2.3pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Send transactional messages through the App or by text or email, including
      responses to your comments, questions, and requests{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "2.25pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Provide customer and store user service and support; and send technical
      notices, updates, security alerts, and support and administrative messages{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "2.25pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Personalize the App services, including by providing features,
      opportunities, and advertisements that match your interests and
      preferences{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "2.55pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Send promotional communications, such as providing you with information
      about products and services, features, surveys, newsletters, offers,
      promotions, contests, and events; and provide other news or information
      about us and our partners. (You can opt-out of receiving marketing
      communications as explained under “How You Can Restrict Automated Data
      Collection and Marketing Communications”){" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".45pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Monitor and analyze trends, usage, and activities in connection with the
      App;&nbsp;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "2.25pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Protect the App and customer and store users by investigating suspicious
      activity and unauthorized access;&nbsp;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "12.6pt",
        marginLeft: "54.0pt",
        textIndent: "-36.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Comply with valid legal process and respond to governmental requests.{" "}
    </p>
        <h1 className="text-xl font-bold">How We Share Personal Information </h1>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>Third-Party Service Providers:&nbsp; </i>We share information,
      including personal information, with third-party service providers that
      provide hosting for and maintenance of the App, e-mail marketing, backup,
      storage, database, payment processing, analytics and other services for
      us. These third-party service providers may have access to or process your
      personal information for the purpose of providing these services for us.
      The third-party service providers are not permitted to use the personal
      information we share with them for their own commercial&nbsp;{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>Stores.&nbsp; </i>Our store service partners can access the service
      requests you enter into the App. They use the App to contact you, arrange
      for the services you want, and update you about the status of your order.
      Customers pay the stores directly for skate sharpening and repair services
      and we recommend that you find out from them how they collect, store and
      protect your payment and other personal information.<i> </i>
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>App Analytics</i>: We may collect, and we may engage third-party
      analytics providers such as Google Analytics to collect, metrics and
      information regarding your use of the App, including evaluating how
      visitors use our App to develop new features, improve existing features or
      inform sales and marketing strategies. When we process data for analytics
      purposes, any personal information is anonymized. For information about
      how Google handles personal information collected for data analytics
      purposes, see <a href="https://support.google.com/analytics/answer/6004245"></a> &nbsp;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "7.9pt",
        marginLeft: "0cm",
        textIndent: "0cm",
        lineHeight: "107%"
      }}
    >
      &nbsp;
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>Digital Advertisers</i>:&nbsp; We may use third-party advertising
      companies to serve advertisements on the App and on selected websites and
      mobile applications operated by others. These companies may set their own
      cookies or use similar web technologies to collect information about your
      online and mobile device activities over time and across different mobile
      applications and websites. These companies then direct advertisements to
      you based on your interests as suggested by the history of your visits to
      this App and other apps.{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>Affiliates</i>: We may share information, including personal
      information, with companies affiliated with Hockey Caddy, and they will
      use that information only for the purposes that are consistent with those
      described in this Privacy Policy.{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>Legal Process, Law Enforcement and Compliance. </i>We may disclose
      personal information or other information if required to do so by law or
      in the good-faith belief that such action is necessary to comply with
      applicable laws, in response to a facially valid court order, judicial or
      other government subpoena or warrant, or to otherwise cooperate with law
      enforcement or other governmental agencies.{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>Security and Protection</i>. We also reserve the right to disclose
      personal information or other information that we believe, in good faith,
      is appropriate or necessary to (i) take precautions against liability,
      (ii) protect ourselves, customers, stores, and others from fraudulent,
      abusive, or unlawful uses or activity, (iii) investigate and defend
      ourselves against any third-party claims or allegations, (iv) protect the
      security or integrity of the App and any facilities or equipment used to
      make the App available, or (v) protect our property or other legal rights,
      enforce our contracts, or protect the rights, property, or safety of
      others.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "14.35pt",
        marginLeft: "-.25pt"
      }}
    >
      <i>With Your Consent</i>: We may also share personal information with
      third parties when we have your consent to do so.{" "}
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "4.8pt",
        marginLeft: "-.25pt"
      }}
    >
      How You Can Restrict Automated Data Collection{" "}
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".4pt",
        marginLeft: "-.25pt"
      }}
    >
      By using the App, you consent to the processing of your personal
      information by both Hockey Caddy and the App analytics services providers,
      including Google Analytics. You can restrict how cookies and other
      internet technologies are used to track and log data about your computer
      and mobile device usage of our App by managing the preference settings on
      your browser or device. You can learn how Google collects data on its
      partner sites and how to restrict that data collection at
      https://policies.google.com/technologies/partner-sites?hl=en-US
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      .{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Note that if you restrict the use of cookies some of the features on our
      App may not be fully functional.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.25pt",
        marginLeft: "-.25pt"
      }}
    >
      The controls you may be able to use include:{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "5.1pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Managing the settings on your web browser or device{" "}
    </p>
    <p
      className="MsoNormal"
      style={{ marginLeft: "36.0pt", textIndent: "-18.0pt" }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Installing the Google Analytics opt-out browser add-on, which is described
      at the Google Analytics Opt-out Browser Add-on page at{" "}
      <a href="https://tools.google.com/dlpage/gaoptout">
        <span style={{ color: "black", textDecoration: "none" }}>
          https://tools.google.com/dlpage/gaoptout
        </span>
      </a>
      <a href="https://tools.google.com/dlpage/gaoptout">
        <span style={{ color: "black", textDecoration: "none" }}> </span>
      </a>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "5.35pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Communicating directly with third parties that we authorize to collect
      data on our App as explained at:{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "5.45pt",
        marginLeft: "90.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span style={{ fontFamily: "Wingdings" }}>
        §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
      </span>
      <u>Google</u>:&nbsp;{" "}
      <a href="https://policies.google.com/privacy">
        <span style={{ color: "black", textDecoration: "none" }}>
          https://policies.google.com/privacy
        </span>
      </a>
      <a href="https://policies.google.com/privacy">
        <span style={{ color: "black", textDecoration: "none" }}> </span>
      </a>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "5.2pt",
        marginLeft: "90.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span style={{ fontFamily: "Wingdings" }}>
        §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
      </span>
      <u>Facebook</u>:&nbsp;{" "}
      <a href="https://www.facebook.com/privacy/explanation">
        <span style={{ color: "black", textDecoration: "none" }}>
          https://www.facebook.com/privacy/explanation
        </span>
      </a>
      <a href="https://www.facebook.com/privacy/explanation">
        <span style={{ color: "black", textDecoration: "none" }}> </span>
      </a>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "7.1pt",
        marginLeft: "90.0pt",
        textIndent: "-18.0pt"
      }}
    >
      <span style={{ fontFamily: "Wingdings" }}>
        §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
      </span>
      <u>Twitter</u>:&nbsp; https://twitter.com/en/privacy{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "5.55pt",
        marginLeft: "36.0pt",
        textIndent: "-18.0pt"
      }}
    >
      •
      <span style={{ font: '7.0pt "Times New Roman"' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Purchasing a utility from an independent software provider that you
      install on your computer browsers to limit tracking of your browsing
      activity.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "14.35pt",
        marginLeft: "-.25pt"
      }}
    >
      Do Not Track (DNT) is an optional browser setting that is used by some
      website operators and third parties.&nbsp; Our App does not respond to DNT
      signals.<b> </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "6.1pt",
        marginLeft: "-.25pt",
        lineHeight: "107%"
      }}
    >
      <b>
        <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
          Your Communications Choices.&nbsp;{" "}
        </span>
      </b>
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <i>E-mail marketing and advertising</i>.&nbsp; You can opt-out of
      receiving marketing communications from us by following the “unsubscribe”
      instructions included in each of our e-mail marketing communications. You
      can also opt-out by emailing us at info@hockeycaddy.ca and providing the
      names/email address that you want us to remove from our marketing database
      and add to our ‘Do Not Contact’ list. If you opt-out of marketing
      messages, we may still send e-mails to you that involve your orders or
      responses to questions. It may take up to ten days to process your request
      to unsubscribe from e-mail marketing messages.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.25pt",
        marginLeft: "-.25pt"
      }}
    >
      <i>Text messages. </i>&nbsp;If you previously gave us permission to send
      recurring automated promotional and personalized marketing communications
      to you by text message, you can withdraw that consent at any time by
      replying STOP to our text message. It may take up to seven days to process
      your request to STOP text marketing messages.&nbsp;{" "}
    </p>
        <h1 className="text-xl font-bold">
      Retention and Protection of Personal Information
    </h1>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We will retain your personal information as long as you maintain an
      account with the App, and as necessary to comply with our legal
      obligations, resolve any disputes, and enforce our agreements.{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We maintain commercially reasonable security procedures and practices
      appropriate to the nature of the categories and specific items of personal
      information we collect.&nbsp; However, neither people nor systems can
      operate in a fully secure manner and no method of transmission over the
      Internet or method of electronic data storage is fully secure.&nbsp; While
      we use reasonable efforts to protect your personal information and select
      stores and third-party service providers we believe also use reasonable
      precautions, we cannot guarantee the security of the personal information
      you provide to us.{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Our App permits you to create an account.&nbsp; When you do you will be
      prompted to create a password.&nbsp; You are responsible for maintaining
      the confidentiality of your password, and you are responsible for any
      access to or use of your account by someone else who has obtained your
      password, even if that person was not authorized by you.&nbsp; You should
      notify us of any unauthorized use of your password or account, and, if any
      unauthorized use is suspected, you should immediately reset your password.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "14.6pt",
        marginLeft: "-.25pt"
      }}
    >
      If you have reason to believe that your interaction with us is no longer
      secure (for example, if you feel that the security of any account you have
      with us has been compromised), please immediately notify us of the
      problem.{" "}
    </p>
    <h1
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "11.25pt",
        marginLeft: "-.25pt"
      }}
    >
      Social Media and Third-party Links{" "}
    </h1>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      <u>Social Media</u>.&nbsp; You may be able to connect to our App using a
      social media account such as Instagram. If you authorize us to connect
      with you using your social media account, we may access and store your
      name, email address, location, profile picture, other images you post,
      URL, and any other personal information that the social media platform
      makes available to us. We may use and disclose that information in
      accordance with this Privacy Policy.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "15.15pt",
        marginLeft: "-.25pt"
      }}
    >
      <u>Third-Party Links</u>.&nbsp; The App may include links to websites
      operated by third parties that have information we think may be of
      interest to you.&nbsp; If you click on a third-party link, you will be
      directed away from our App to that third-party website and any information
      you provide you submit to that third party website or is collected from
      your usage, will be available to the thirdparty operator. The third-party
      website operator may collect your IP address or device identifier and
      other internet browsing data and may set a cookie to enable their features
      to function and track your usage.&nbsp; We recommend that you review the
      privacy policies of these websites before sharing any information with
      them.&nbsp; We have no control over and assume no responsibility for the
      content, accuracy, privacy policies, or practices of any third-party
      websites or services.{" "}
    </p>
        <h1 className="text-xl font-bold">Children’s Personal Information </h1>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Only individuals who are of age to enter into a legally binding contract
      (age 18 in most states) are permitted to register for an account on the
      App. If you register for an account on the App, you are representing to us
      that you are age 18 or reside where you have reached the age of majority.
      Parents or legal guardians may use the App to obtain skate services for
      minor-aged children and will need to submit certain personal information
      about the child that is necessary to provide the services.
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.5pt",
        marginLeft: "-.25pt"
      }}
    >
      Hockey Caddy does not knowingly collect any personal information about
      children under the age of 13 without verifiable consent from a parent or
      legal guardian. We encourage parents and legal guardians to monitor their
      children’s Internet usage and to help enforce this Privacy Policy by
      instructing their children never to provide personal information through
      the App without permission. If you have reason to believe that a child
      under the age of 13 has provided personal information to us through the
      App, please contact us. See “How to Contact Us” below.{" "}
    </p>
        <h1 className="text-xl font-bold">Your Rights </h1>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      You may be entitled under the applicable law of your country, state, or
      province to ask Hockey Caddy for a copy of your information, to correct
      it, erase or restrict its processing, or to ask us to transfer some of
      this information to other companies. You may also have rights to object to
      some processing, including for direct marketing purposes. These rights may
      be limited in some situations – for example, where we can demonstrate we
      have a legal requirement to process or keep your personal information. You
      may submit a request to exercise your data privacy rights by contacting us
      as explained in the section titled “How to Contact Us.” Please note that
      we may require you to provide proper personal identification and
      confirmation of your place of legal residence before we comply with any
      request regarding personal information.{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you reside in Canada, you have the right to request that your personal
      information be updated or amended to correct inaccuracies or incomplete
      information, request deletion of your personal information, or request
      that we no longer use it. You are responsible for the accuracy of the
      information you provide when using the App. To access, review or update
      your information for accuracy, please login to your App account or contact
      us using the information below. Your right to access or correct your
      personal information is subject to applicable legal restrictions. We may
      take reasonable steps to verify your identity before granting access or
      making corrections.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: ".4pt",
        marginLeft: "-.25pt"
      }}
    >
      Residents of Canada may withdraw consent to our collection, use or
      disclosure of personal information at any time, subject to reasonable
      notice and any legal or contractual restrictions.{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      To notify us that you wish to withdraw your consent, you may contact us by
      email at: info@hockeycaddy.ca . Revoking your consent to our collection,
      use and/or disclosure of your information for certain purposes may limit
      or block our ability to provide you with services using the App.{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.25pt",
        marginLeft: "-.25pt"
      }}
    >
      We hope that we can satisfy any queries you may have about the way in
      which we process your personal information. If you have any concerns about
      how we process your personal information, please contact us as explained
      in the section titled “How to Contact Us.”{" "}
    </p>
        <h1 className="text-xl font-bold">Changes to this Privacy Policy </h1>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      We reserve the right to amend this Privacy Policy at any time in order to
      address Hockey Caddy’s future developments, changes in industry trends, or
      changes in legal requirements. If we materially alter the terms of this
      Privacy Policy, we will post the revised Privacy Policy on the App and
      provide other notification as required by applicable law.&nbsp;{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      Any changes will become effective upon the posting of the revised Privacy
      Policy on the App. You can determine when the Privacy Policy was last
      revised by referring to the "Effective Date" legend on the top of this
      Privacy Policy.&nbsp;&nbsp;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.5pt",
        marginLeft: "-.25pt"
      }}
    >
      By continuing to use the App following such changes, you will be deemed to
      have agreed to such changes.&nbsp; If you do not agree with any or all of
      the terms of our Privacy Policy as in effect at any time, you should not
      continue to use the App.&nbsp;&nbsp;{" "}
    </p>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "7.9pt",
        marginLeft: "0cm",
        textIndent: "0cm",
        lineHeight: "107%"
      }}
    >
      <b>
        <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>&nbsp;</span>
      </b>
    </p>
        <h1 className="text-xl font-bold">
      How to Access and Manage Your Account&nbsp;{" "}
    </h1>
    <p
      className="MsoNormal"
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "10.5pt",
        marginLeft: "-.25pt"
      }}
    >
      You can update, correct, or delete your App account information by logging
      into your account and making the necessary changes.{" "}
    </p>
        <h1 className="text-xl font-bold">How to Contact Us </h1>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      If you have questions or complaints regarding this Privacy Policy or about
      Hockey Caddy’s privacy practices, please contact us:{" "}
    </p>
    <p className="MsoNormal" style={{ marginLeft: "-.25pt" }}>
      info@hockeycaddy.ca&nbsp;{" "}
    </p>
  </div>

  </div>

  )


  }
