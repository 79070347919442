
import { getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { collection, db, query, where } from "../Utils";
import BtnNormal from "./BtnNormal";
import StripListFilterContAdvance from "./StripListFilterContAdvance";
import { getPercentage } from "./genFunctions";


export default function StripListFilterContViewAttendance(props) {

  console.log(props)
  const [pageStripValueMapColl,setPageStripValueMapColl] = useState([])


  useEffect(()=>{

    (async() => await functions("initializeData",{pageStripValueMapColl:props.pageStripValueMapColl}))()

  },[props.pageStripValueMapColl])


 async function functions(fnName,data){

  const passMap = props.passMap

    switch(fnName){
        case "initializeData":{

          if(props.stripValueMapSource=="parent"){

            setPageStripValueMapColl([...data.pageStripValueMapColl])

          }else{

          const {instituteUid} = passMap;
          const {instituteAcademicYear} = passMap
          const {className} = passMap
          const {sectionName} = passMap
          const {studentUid} = passMap

          
          const studentSubjectAttendanceMaps = []

          const queryStatement = query(
          collection(db,`institutes/${instituteUid}/attendance`),
          where(`instituteAcademicYear`,"==",instituteAcademicYear),
          where("className","==",className),
          where("sectionName","==",sectionName)
        );

          const querySnapshot = await getDocs(queryStatement);

          const attendanceInfoMap = {
            classesConductedCount:0,
            classesAttendedCount:0,
            avgAttendance:0,
            avgAttendanceData:[],
          }

          querySnapshot.forEach((doc) => {

            const docData = doc.data();
            console.log(docData)

              attendanceInfoMap.classesConductedCount += docData.totalClassCount;
              attendanceInfoMap.classesAttendedCount += docData.studentAttendanceMap[studentUid];

              studentSubjectAttendanceMaps.push(doc.data())
          });

          const avgAttendance = getPercentage(attendanceInfoMap.classesAttendedCount,attendanceInfoMap.classesConductedCount,2)

          const avgAttendanceData = [avgAttendance,attendanceInfoMap.classesConductedCount]
        
          attendanceInfoMap.avgAttendanceData = [...avgAttendanceData]
          attendanceInfoMap.avgAttendance = avgAttendance
 
          setPageStripValueMapColl(studentSubjectAttendanceMaps)    

        }
    }
  

}

 }



  return (
    <div className={`w-full gap-y-4 pt-4`}>
      {props.stripValueMapSource=="parent" &&
      <div className="flex justify-end py-4 px-2">
                <BtnNormal
                  spClass=""
                  label="Back to Dashboard"
                  color="black"
                  clickHandler={()=>props.backBtnClickHandler()}
                />
      </div> }
      <StripListFilterContAdvance
        schemeMap={props.schemeMap}
        pageAttributeMap={{studentUid:props.passMap.studentUid}}
        pageStripValueMapColl={pageStripValueMapColl}
      />
    </div>
  );
}

