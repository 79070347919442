import React, { useState } from "react";
import { getRegexPattern } from "../Utils";
import DualInputWrapperNew from "./DualInputWrapperNew";
import FileUploadUnit from "./FileUploadUnit";
import { convertJSDateStrToFbTimestampInEventObj, getDateFromISO8601DateTimeString, getDateStrFromJSDate, getDateTimeFromISO8601DateTimeString } from "./genFunctions";
import { getInpBackgroundColor } from "./projectFns";
import { styleMapProject } from "./viewProps";



export default function InputTileNew(props){

    console.log(props.inpKey,props.inpValidCond)

    console.log(props)

    const controlModes = ["assess","resubmit"]


    //@1 States
    const [regexResult,setRegexResult] = useState(true)
    const regexPattern = getRegexPattern(props.inpRegexDomain)


    //@2 Functions

    //@21 
    function onChangeHandler(e,regexPattern,inpKey){
        props.onChangeFn(e,inpKey)
        console.log(e.target.value)
        setRegexResult(regexPattern.test(e.target.value))
      }


      function getInputElement(propsObj,regResult){
        
        if(propsObj.accessMode=="assess" || (propsObj.accessMode=="resubmit" && !propsObj.resubInpCond)){
          console.log(propsObj)
          if(propsObj.inpType=="file"){
            return(
              <div className="h-8 w-full">
                <FileUploadUnit
                key={1}
                value={propsObj.inpValue}
                allowedFileTypesStr={propsObj.inpExtraDataMap.allowedFileTypesStr}
                disableUploadCond={true}
                uploadedCond={propsObj.inpValue}
                inpValidCond={true}
                accessMode={props.accessMode}
                resubInpCond={props.resubInpCond}
                viewFileHandler={()=>props.outputFnHandler("viewFile",{inpKey:propsObj.inpKey})}
                assessorClickHandler={()=>props.simpleInpAssessorClickHandler()}
                onChange={(e)=>onChangeHandler(e,regexPattern,propsObj.inpKey)}
              /></div>
            )
          }else{
            return(<div onClick={()=>props.simpleInpAssessorClickHandler()}
            className={`flex items-center px-2 h-full text-lightBackText w-full text-l ${props.resubInpCond==true ? `bg-redPale` : `bg-quaternary-light`}`}>{props.inpValue}</div>)
          }

        }else{

          if(propsObj.inpType=="file"){
            return(
              <div className="h-8 w-full">
            <FileUploadUnit
            key={1}
            value={propsObj.inpValue}
            accessMode={props.accessMode}
            allowedFileTypesStr={propsObj.inpExtraDataMap.allowedFileTypesStr}
            uploadedCond={propsObj.inpValue}
            inpValidCond={true}
            viewFileHandler={()=>props.outputFnHandler("viewFile",{inpKey:propsObj.inpKey})}
            onChange={(e)=>onChangeHandler(e,regexPattern,propsObj.inpKey)}
           /></div>
        )
  
          }else if(propsObj.inpType=="fixed"){
            return(<div className={`flex items-center px-2 h-full text-lightBackText w-full text-l bg-quaternary-light`}>{props.inpValue}</div>)
            
          }else if(propsObj.inpType=="number-dec"){
            return(<input
            className={`px-2 h-full text-lightBackText w-full text-l ${getInpBackgroundColor(props.accessMode,regResult)}`}
            value={propsObj.inpValue}
            accessMode={props.accessMode}
            step=".01"
            type="number"
            name={propsObj.inpKey}
            onChange={(e)=>onChangeHandler(e,regexPattern,propsObj.inpKey)}
        ></input>)
          }else if(propsObj.inpType=="phoneNo"){
           return(
            <DualInputWrapperNew
              values={getFormattedInpValue(propsObj.inpLabel, propsObj.inpValue)}
              inpType={propsObj.inpType}
              inpKey={propsObj.inpKey}
              checked={propsObj.inpValue}
              // disabled={!props.editStatus}
              onChangeHandler={(e)=>onChangeHandler(e,regexPattern,propsObj.inpKey)}
              // onChangeFn={(eMap)=>onChangeHandler(eMap,regexPattern,deductedVald,2)}
            />)
        }else if(propsObj.inpType=="date-timestamp"){
          return(
            <input
            className={`px-2 h-full text-lightBackText w-full text-l ${getInpBackgroundColor(props.accessMode,regResult)}`}
            value={propsObj.inpValue ? getDateStrFromJSDate(propsObj.inpValue.toDate(),"protocol") : null}
            accessMode={props.accessMode}
            type="date"
            name={propsObj.inpKey}
            onChange={(e)=>onChangeHandler(convertJSDateStrToFbTimestampInEventObj(e),regexPattern,propsObj.inpKey)}
        ></input>)
       }else{
            return(<input
            className={`px-2 h-full text-lightBackText w-full text-l ${getInpBackgroundColor(props.accessMode,regResult)}`}
        // value={getSettingsLabelValue(props.inpLabel, props.inpValue)}
            value={getFormattedInpValue(propsObj.inpType,propsObj.inpValue)}
            accessMode={props.accessMode}
            type={propsObj.inpType}
            name={propsObj.inpKey}
            // checked={props.inpValue}
            // disabled={!props.editStatus}
            onChange={(e)=>onChangeHandler(e,regexPattern,propsObj.inpKey)}
        ></input>)
          }
        }


      }


      function getFormattedInpValue(inpType,inpValue){

        switch(inpType){
          case "date" : return getDateFromISO8601DateTimeString(inpValue)
          case "datetime-local" : return getDateTimeFromISO8601DateTimeString(inpValue)
          default : return inpValue
        }
      }


      function getDisplayElement(propsObj){

      }

      const tileColor = !Object.keys(props).includes("inpValidCond") || props.inpValidCond ?  "bg-secondary" : "bg-primary" ;
      const labelCompClass = "flex items-center"
      const height = props.inpLabel.length>35 ? `` : `h-12`;
      // const width = props.width ? `w-cust-${props.width}` : `w-cust-35rem`;

      //@3 return
    return(  
      <div className={`grid ${props.orientation=="vertical" ? `grid-rows w-cust-15rem` : `grid-cols-cust-2h gap-1` } bg-${props.inpValidCond ? styleMapProject.inputElem.background: styleMapProject.inputElem.backgroundInpInvalid} p-3 rounded`}>
      {/* <div className={`${labelCompClass} justify-center`}>{props.slNo}</div> */}
      <label className={`${labelCompClass} justify-start px-2`}>{`${props.inpLabel} ${props.inpMandCond ? `*` : ``}`}</label>
      <div className={`${labelCompClass}`}>
          {getInputElement(props,regexResult)}
      </div>   
      {!props.inpValidCond && <span className="text-xs text-darkBackText"># Invalid Input</span>}
    </div>

    )    
}