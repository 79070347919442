import React from "react";
import BottomContainer from "./BottomContainer";
import { skateMaintenanceJobs } from "./DataList";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordBanner from "./RecordBanner";
import RecordBanner3 from "./RecordBanner3";


//const db=getFirestore(firebase);

//function to create an empty field object with keys of the templateObj passed to it
function createInitialObj(templateObj) {
  const obj = {};

  templateObj[templateObj.mainKey].forEach((field) => {
    obj[field] = "";
  });

  return obj;
}

export default function BannerListListenerCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbKeys);
  console.log(props.passMap);
  console.log(props.passListDataArray);

  const customerDisplayCond = props.passMap.fnName == "Show Current Orders";

  const gridColScheme = props.passListDataArray.length == 0 ? "" : customerDisplayCond ? "grid grid-cols-cust-2eq" : ""

  const noTextDesc = customerDisplayCond ? "No Orders" : "No Records"

  return (
    <React.Fragment>
      <div className="h-screen grid grid-rows-cust-2c p-cust-topHead">
        <div className={`${gridColScheme} auto-rows-max p-4 overflow-y-auto `}>
        {!customerDisplayCond && props.passListDataArray.map((dataMap, index) => {
          return (
            <RecordBanner
              key={dataMap.prepaidCardRequestId}
              slNo={index + 1}
              bannerColorScheme={getOrderStatusColor(dataMap)}
              labelNames={props.passMap.labelNames}
              labelDbKeys={props.passMap.labelDbKeys}
              btnAlertLevels={props.passMap.btnAlertLevels}
            //   btnLabels={checkAndReturnBtnLabels(
            //     props.passMap,
            //     dataMap
            //  //   props.passListDataArray[index]
            //   )}
              btnDataMap={checkAndReturnBtnData(
                props.passMap,
                dataMap
                //   props.passListDataArray[index]
              )}
              fnName={props.passMap.fnName}
              passDataMap={dataMap}
              passMap={props.passMap}
              passDataMap2={props.passMap.settings}
              actionHandler={(data) => props.actionHandler(data)}
            />
          );
        })}
            {customerDisplayCond && props.passListDataArray.map((dataMap, index) => {
          return (
            <RecordBanner3 //For Displaying Current Orders on Store Screen
              key={dataMap.prepaidCardRequestId}
              slNo={index + 1}
              bannerStyleMap={getBannerColorSchemeMap("order",dataMap)}
              schemeMap={props.passMap[dataMap["orderJobName"]]}
              // labelNames={props.passMap["Skate Sharpening"].labelNames}
              // labelDbKeys={props.passMap["Skate Sharpening"].labelDbKeys}
              fnName={props.passMap.fnName}
              passDataMap={dataMap}
              passMap={props.passMap}
              passDataMap2={props.passMap.settings}
              actionHandler={(data) => props.actionHandler(data)}
            />
          );
        })}

        {props.passListDataArray.length == 0 && (
          <div className="h-full">
         <NoAvailableDisplay textName={noTextDesc}/>
         </div>
        )}
        </div>
        <div className="absolute w-full bottom-10 flex justify-center align-center">
        {bottomContainer}
        </div>
      </div>

    </React.Fragment>
  );
}

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const btnLabels = [...passMap.btnLabels];

  const fnName = passMap.fnName;
  const storePayConfCond = passMap.settings.storeBranchPayConfirmationCond;
  const pendingPaymentCond = passArrayElement.orderStatus == "pendingPayment";

  const collectionNotifSendCond = passArrayElement.orderCollectionNotifSendCond;


  switch(passArrayElement.orderStatus){
    case "accepted":
      btnLabels.push("Cancel Order");
    case "pendingCollection":
      btnLabels.push("Mark Collected");  
  }

    if ((!passArrayElement.orderPaymentStatus && storePayConfCond) || pendingPaymentCond) {
      btnLabels.push("Paid");
    }

    return btnLabels;

}



function getOrderStatusColor(data){
  if("orderStatus" in data){
    if(data.orderStatus=="pendingPayment" || data.orderStatus=="pendingCollection"){
      return "green"
    }
  }
  return "red"
}

function getBannerColorSchemeMap(type,data){


  const styleMap = {
    color:"red",
    backgroundColorClass:"banner-back-normal"
  }

  switch(type){
    case "order":{

      const jobNames = skateMaintenanceJobs;
      const jobBannerBackColorsClass = ["banner-back-gradient-red","banner-back-gradient-blue","banner-back-gradient-green"]

      if("orderStatus" in data){
        if(data.orderStatus=="pendingPayment" || data.orderStatus=="pendingCollection"){
          styleMap.color = "green";
        }else{
          const jobIndex = jobNames.indexOf(data.orderJobName)
          styleMap.color = "red";
          styleMap.backgroundColorClass = jobBannerBackColorsClass[jobIndex]
        }
      }
    }
  }

  return styleMap
}


function checkAndReturnBtnData(passMap, passArrayElement) {

  const rMap = {}

  const btnLabels = [...passMap.btnLabels];
  const btnAlertLevels = [...passMap.btnAlertLevels];
  const btnAvlblties = [...passMap.btnAvlblties];

  const fnName = passMap.fnName;
  const storePayConfCond = passMap.settings.storeBranchPayConfirmationCond;
  const pendingPaymentCond = passArrayElement.orderStatus == "pendingPayment";

  // const pendingCollCond = passArrayElement.orderStatus == "pendingCollection";
  // const collectionNotifSendCond = passArrayElement.orderCollectionNotifSendCond;

  
  switch(passArrayElement.orderStatus){
    case "accepted":
      btnLabels.push("Cancel Order");
      break;
    case "pendingCollection":
      btnLabels.push("Mark Collected");
      break;  
  }



    if ((!passArrayElement.orderPaymentStatus && storePayConfCond) || pendingPaymentCond) {
        if(passArrayElement.orderStatus!="accepted"){
          btnLabels.push("Paid");
          btnAlertLevels.push("High");
          btnAvlblties.push(true);
        }
    }

    rMap["btnLabels"] = btnLabels;
    rMap["btnAlertLevels"] = btnAlertLevels;
    rMap["btnAvlblties"] = btnAvlblties;


    return rMap;

}
