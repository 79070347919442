import React, { Component, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  electron,
  pageSizes,
  getSerialNo,
  db,
  doc,
  getDoc,
  updateDoc,
  runTransaction,
  Timestamp,
  serverTimestamp,
} from "../Utils";
import BottomContainer from "./BottomContainer";

import QRCode from "react-qr-code";





export default function ModalBoxImgDisplay(props) {
 

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="flex flex-col bg-quaternary-light m-4 p-cust-4 gap-y-24 flex items-center justify-center">

          <QRCode value={props.passMap.value} />
        
        <button className="btn-action" onClick={() => props.closeClickHandler()}>
          Close
        </button>
      </div>
    </div>
  )
}
