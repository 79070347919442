import React from "react";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordMapStripAdvance from "./RecordMapStripAdvance";


export default function StripListFilterContAdvance(props) {

  console.log(props)
  function getGridPositionMobile(colStartEnds,rowStartEnds){

    console.log(colStartEnds,rowStartEnds)
    console.log(`sm:col-start-${colStartEnds[0]} sm:col-end-${colStartEnds[1]} sm:row-start-${rowStartEnds[0]} sm:row-end-${rowStartEnds[1]}`)
  
    return `sm:col-start-${colStartEnds[0]} sm:col-end-${colStartEnds[1]} sm:row-start-${rowStartEnds[0]} sm:row-end-${rowStartEnds[1]}`
  
  }

  function getBorderClassMobile(gridRowStartEndSchemeArray,gridColStartEndSchemeArray){

    let rowBorderClass = gridRowStartEndSchemeArray[0] != 1 ? `sm:border-t` : ``
    let colBorderClass = gridColStartEndSchemeArray[0] != 1 ? `sm:border-l` : ``

    console.log(rowBorderClass + colBorderClass)

    return `${rowBorderClass} ${colBorderClass}`
  }

  return (
    <div className="p-4">
        <div className={`grid mt-1 ${props.schemeMap.stripGridColsSchemeMain} bg-secondary rounded-sm border border-borderStd sm:text-sm`}>
            <div className={`flex-center`}>
              SL NO
            </div>

            <div
              className={`grid ${props.schemeMap.stripGridColsSchemeBody} ${props.schemeMap.stripGridColRowSchemeMobile ? props.schemeMap.stripGridColRowSchemeMobile:``}`}
            >
              {props.schemeMap.cellLabelNames.map((cellLabelName,i) => {
                    if(i!=0){
                return (
                  <div className={`border-l text-center text-lightBackText break-all ${getGridPositionMobile(props.schemeMap.stripGridMobileLabelColStartEnds[i],props.schemeMap.stripGridMobileLabelRowStartEnds[i])} ${getBorderClassMobile(props.schemeMap.stripGridMobileLabelRowStartEnds[i],props.schemeMap.stripGridMobileLabelColStartEnds[i])} sm:p-1 `}>
                    {cellLabelName}
                  </div>
                );
              }
              })}
            </div>
        </div>
      {props.pageStripValueMapColl.length != 0 &&

      <React.Fragment>

          {props.pageStripValueMapColl.map((stripValueMap,i) => {
            return (
              <RecordMapStripAdvance
                stripValueMap={{...stripValueMap,...props.pageAttributeMap}}
                schemeMap={props.schemeMap}
                autoGenSlNoCond={true}
                slNo={i+1}
                stripColor={ null}
                pageLimit={20}
                pageNo={1}
              />
            )})}
      
          </React.Fragment>

      }

      {props.pageStripValueMapColl.length == 0 && 
                      <NoAvailableDisplay textName="No Records Found"/>
        }

    
    </div>
  );
}

