import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions as fbFunctions } from "../Utils";
import BtnNormal from "./BtnNormal";
import { callFbFunction } from "./DbHelperFns";
import InputWithControllerCont from "./InputWithControllerCont";
import ModalBoxOpConfPrompt6 from "./ModalBoxOpConfPrompt6";
import { createBlankValueMap, generateBlankInpValdMap, generateInitValMapFromPreInpMap, getOpPromptMap } from "./projectFns";

export default function InputWithControllerContInsertDb(props){

    console.log(props);

    const [showSpinner, setShowSpinner] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [showPromptOp, setShowPromptOp] = useState(false);
    const [promptOpMap, setPromptOpMap] = useState({});
    const [valdCount, setValdCount] = useState(0);
    const [inpValMap,setInpValMap] = useState(props.initInpValMap)
    const [inpValdMap,setInpValdMap] = useState(generateBlankInpValdMap(props.inpSchemeMap))


    const [showPhotoViewer,setShowPhotoViewer] = useState(false)

    const [openFileMap,setOpenFileMap] = useState({})
    const [showModalBoxFileViewer,setShowModalBoxFileViewer] = useState(false)
  
    console.log(inpValdMap)
  


  function functions(fnName,data){

    switch(fnName){
      case "addInstitute":{

        console.log(data)

        const inpSchemeMap = data.inpSchemeMap

        const opFn = async () =>{

          const payload = data.inpValMap
          const fbFunctionsCall = httpsCallable(fbFunctions,inpSchemeMap.opDbFnName);
          const callResult = await fbFunctionsCall(payload);
          return callResult.data
        }

          const afterOpCompletionFn = () => {
            // setTopBarValMap({...topBarValMap,pageName:"Dashboard"});setBodyContView("Dashboard");
          };
          const promptMap = getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn);

          setPromptOpMap(promptMap)
          setShowPromptOp(true)

        setShowPrompt(true)

      }
      break;
      case "addExam":{

        console.log(data)

        const inpSchemeMap = data.inpSchemeMap

        const opFn = async () =>{

          const payload = data.inpValMap
          const fbFunctionsCall = httpsCallable(fbFunctions,inpSchemeMap.opDbFnName);
          const callResult = await fbFunctionsCall(payload);
          return callResult.data
        }

          const afterOpCompletionFn = () => {
            // setTopBarValMap({...topBarValMap,pageName:"Dashboard"});setBodyContView("Dashboard");
          };
          const promptMap = getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn);

          setPromptOpMap(promptMap)
          setShowPromptOp(true)

        setShowPrompt(true)

      }
      break;
      case "addSubject":{

        console.log(data)

        const inpSchemeMap = data.inpSchemeMap

        const opFn = async () =>{

          const payload = data.inpValMap
          const fbFunctionsCall = httpsCallable(fbFunctions,inpSchemeMap.opDbFnName);
          const callResult = await fbFunctionsCall(payload);
          return callResult.data
        }

          const afterOpCompletionFn = () => {
            // setTopBarValMap({...topBarValMap,pageName:"Dashboard"});setBodyContView("Dashboard");
          };
          const promptMap = getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn);

          setPromptOpMap(promptMap)
          setShowPromptOp(true)

        setShowPrompt(true)

      }
      break;
      case "addPrincipal":{

        console.log(data)

        const inpSchemeMap = data.inpSchemeMap

        const opFn = async () =>{
          const payload = data.inpValMap
          const callResult = await callFbFunction(inpSchemeMap.opDbFnName,payload)
          return callResult
        }

          const afterOpCompletionFn = () => {
            // setTopBarValMap({...topBarValMap,pageName:"Dashboard"});setBodyContView("Dashboard");
          };
          const promptMap = getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn);

          setPromptOpMap(promptMap)
          setShowPromptOp(true)

        setShowPrompt(true)

      }
      break;
      case "addStudent":{

        console.log(data)

        const inpValMap = {...data.inpValMap}

        inpValMap["contactNo"] = inpValMap.studentParentContactNoComps.length == 2 ? `${inpValMap.studentParentContactNoComps.join("")}`:null;
        inpValMap.contactNoComps = [...inpValMap.studentParentContactNoComps]

        const payload = {
          ...inpValMap
        }


        const inpSchemeMap = data.inpSchemeMap

        const opFn = async () =>{
          // const payload = data.inpValMap
          const fbFunctionsCall = httpsCallable(fbFunctions,inpSchemeMap.opDbFnName);
          const callResult = await fbFunctionsCall(payload);
          return callResult.data
        }


          const afterOpCompletionFn = () => {
            // setTopBarValMap({...topBarValMap,pageName:"Dashboard"});setBodyContView("Dashboard");
          };
          const promptMap = getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn);

          setPromptOpMap(promptMap)
          setShowPromptOp(true)

        setShowPrompt(true)

      }
      break;
      case "addSuperAdmin":{

        console.log(data)

        const inpSchemeMap = data.inpSchemeMap

        const payload = {...data.inpValMap,inputDomain:"Super Admin"}
        

        const opFn = async () =>{

          const fbFunctionsCall = httpsCallable(fbFunctions,inpSchemeMap.opDbFnName);
          const callResult = await fbFunctionsCall(payload);
          return callResult.data
        }

          const afterOpCompletionFn = () => {
            // setTopBarValMap({...topBarValMap,pageName:"Dashboard"});setBodyContView("Dashboard");
          };
          const promptMap = getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn);

          setPromptOpMap(promptMap)
          setShowPromptOp(true)

        setShowPrompt(true)

      }
      break;
    }

  }
  
  

  const inpColClass = "flex content-center";

    return (
      <div className="relative h-full grid content-center justify-center">

                 <BtnNormal
                  spClass="absolute top-5 right-5"
                  label="Back to Dashboard"
                  color="black"
                  clickHandler={()=>props.backBtnClickHandler()}/>
               
        <InputWithControllerCont
                    initInpValMap={generateInitValMapFromPreInpMap(createBlankValueMap(props.inpSchemeMap),props.preInpMap)}
                    inpElemArrangmentScheme="two-column"
                    inpSchemeMap={props.inpSchemeMap}
                    submitClickHandler={(data)=>functions(props.inpSchemeMap.insertViewFnName,data)}
        />
        {/* {showPrompt && 
              <ModalBoxMessagePrompt 
              passMap={promptMap}
              promptCloseHandler={()=>setShowPrompt(false)}
              />} */}

        {showPromptOp && 
              <ModalBoxOpConfPrompt6
                passMap={promptOpMap}
                showSpinnerHandler={(visibilityCond)=>setShowSpinner(visibilityCond)}
                promptClose={()=>setShowPromptOp(false)}
                // opCompletionHandler={(closeCallBack)=>closeCallBack()}
              />

              }
      </div>

    );
}