export const firebaseConfigDev = {
  apiKey: "AIzaSyB5KYR54pEFGfBFUHU-olxTXr1chpUjzic",
  authDomain: "ace-campus-1989.firebaseapp.com",
  databaseURL: "https://ace-campus-1989-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ace-campus-1989",
  storageBucket: "ace-campus-1989.appspot.com",
  messagingSenderId: "140515844498",
  appId: "1:140515844498:web:148093715bb6551e39a14b"
};



export const firebaseConfigProd = {
  apiKey: "AIzaSyCsjyKR-wcJjL-KP5azxo-AxAODiwlPJco",
  authDomain: "ace-campus.firebaseapp.com",
  databaseURL: "https://ace-campus-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ace-campus",
  storageBucket: "ace-campus.appspot.com",
  messagingSenderId: "623487236190",
  appId: "1:623487236190:web:ae306af42adb01ce8a9ccf",
  measurementId: "G-WNRCN8W3S6"
};


