import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import RecordStrip from "./RecordStrip";
import ImageCont from "./ImageCont";
import RecordBanner from "./RecordBanner";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";

const pageLimit = 15;

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}

function getStripValuesColl(docs, dbNotations, stripValuesColl) {
  const rArray = [];
  docs.forEach((doc) => {
    const valueSeqs = doc.data().stringDataSeqs;
  });
}

function extractArray(docs, dbNotations) {
  const rArray = [];
  const valueSeqsArray = [];
  // dbNotations.forEach((item) => {
  //   const label = `${item}s`;
  //   rMap[label] = [];
  // });

  //const pageLimit = 3;

  docs.forEach((doc) => {
    const valueSeqs = doc.data().stringDataSeqs;

    valueSeqs.forEach((valueSeq, i) => {
      const values = valueSeq.split("^");
      valueSeqsArray.push(values);

      // values.forEach((value, i) => {
      //   const dbNotation = dbNotations[i];
      //   const key = `${dbNotation}s`;

      //   rMap[key].push(value);
      // });
    });
  });

  let tArray = [];

  valueSeqsArray.forEach((valueSeq, i) => {
    tArray.push(valueSeq);

    if ((i + 1) % pageLimit == 0) {
      rArray.push(tArray);
      tArray = [];
    }
  });

  console.log(rArray);

  return rArray;
}

export default function ListCont2(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbNotations);
  console.log(props.passMap);

  const [stripValuesDataMap, setStripValuesDataMap] = useState({
    stripValuesSeqs: [],
    slNos: [],
  });
  const [stripFilteredValuesDataMap, setStripFilteredValuesDataMap] = useState({
    stripValuesSeqs: [],
    slNos: [],
  });
  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  const [slNoArray, setSlNoArray] = useState([]);
  const [filterName, setFilterName] = useState([]);
  const [recordCount, setRecordCount] = useState(
    props.passMap.latestTransactionSl
  );
  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
      ),
      orderBy("docCreationDate", "desc"),
      limit(2)
    );
    const dbDocs = await getDocs(collQuery);
    setStripValuesDataMap(
      extractAndSetValuesDataMap(dbDocs, stripValuesDataMap, 1)
    );
  }

  function extractAndSetValuesDataMap(dbDocs, stripValuesDataMap, pageNo) {
    const tArray1 = [];
    let tArray2 = [];

    console.log(stripValuesDataMap);

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringDataSeqs;
      const slNos = doc.data().slNos;

      console.log(doc.data());

      valueSeqs.forEach((valueSeq, i) => {
        tArray1.push(valueSeq.split("^"));
      });

      tArray2 = tArray2.concat(slNos);
    });

    stripValuesDataMap.stripValuesSeqs = [
      ...stripValuesDataMap.stripValuesSeqs,
      ...tArray1,
    ];
    stripValuesDataMap.slNos = [...stripValuesDataMap.slNos, ...tArray2];

    displayPage(pageNo, stripValuesDataMap);

    console.log(stripValuesDataMap);
    return stripValuesDataMap;
  }

  console.log(pageNo);

  function displayPage(pageNo, stripValuesDataMap) {
    setPageNo(pageNo);
    console.log(pageNo);
    const latestSl = props.passMap.latestTransactionSl;

    const slNoArray = stripValuesDataMap.slNos;

    // const pageLimit = 3;

    const bottomSlOfPage =
      latestSl + 1 - pageNo * pageLimit < 1
        ? 1
        : latestSl + 1 - pageNo * pageLimit;
    const topSlOfPage =
      bottomSlOfPage + pageLimit - 1 < 1
        ? 1
        : latestSl - (pageNo - 1) * pageLimit;

    console.log(bottomSlOfPage, topSlOfPage);

    console.log(slNoArray);

    const slQueryArray = [];

    [topSlOfPage, bottomSlOfPage].forEach((item) => {
      if (slNoArray.indexOf(item) == -1) {
        slQueryArray.push(item);
      }
    });

    if (slQueryArray.length == 0) {
      assignStripValues(topSlOfPage, bottomSlOfPage, stripValuesDataMap);
    } else {
      fetchAndSetStripValues(
        topSlOfPage,
        bottomSlOfPage,
        slQueryArray,
        stripValuesDataMap,
        pageNo
      );
    }
  }

  async function fetchAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    stripValuesDataMap,
    pageNo
  ) {
    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
      ),
      where("slNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setStripValuesDataMap(
      extractAndSetValuesDataMap(dbDocs, stripValuesDataMap, pageNo)
    );
  }

  function assignStripValues(startSlNo, endSlNo, stripValuesDataMap) {
    const rArray = [];

    console.log("here1");

    const slNoArray = stripValuesDataMap.slNos;
    const stripValuesColl = stripValuesDataMap.stripValuesSeqs;

    console.log(stripValuesColl);

    const mIndexStart = slNoArray.indexOf(startSlNo);
    const mIndexEnd = slNoArray.indexOf(endSlNo);

    for (let y = startSlNo; y >= endSlNo; y--) {
      rArray.push(stripValuesColl[slNoArray.indexOf(y)]);
    }

    console.log(rArray);

    setStripValuesSeqs(rArray);
  }

  function renderPagination(recordCount, valuesDataMap) {
    const pageCount = Math.ceil(recordCount / pageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(x + 1, valuesDataMap)}
          className="btn-page"
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  function renderFilteredPagination(recordCount, valuesDataMap) {
    const pageCount = Math.ceil(recordCount / pageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayFilteredPage(x + 1, valuesDataMap)}
          className="btn-page"
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  async function displayPagesBasedOnFilter(fromDate, toDate) {
    setViewMode("filter");

    const fromTimestamp = Timestamp.fromDate(new Date(fromDate));
    const toTimestamp = Timestamp.fromDate(new Date(toDate));

    console.log(fromTimestamp.seconds, toTimestamp.seconds);

    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
      ),
      where("docModificationDate", "<=", toTimestamp),
      where("docModificationDate", ">=", fromTimestamp)
    );

    const dbDocs = await getDocs(collQuery);

    console.log(dbDocs.size);
    setStripFilteredValuesDataMap(
      extractAndSetFilteredValuesDataMap(dbDocs, 1)
    );
  }

  function extractAndSetFilteredValuesDataMap(dbDocs, pageNo) {
    const tArray1 = [];
    let tArray2 = [];

    const rMap = {};
    rMap["stripValuesSeqs"] = [];
    rMap["slNos"] = [];

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringDataSeqs;
      const slNos = doc.data().slNos;

      console.log(doc.data());

      valueSeqs.forEach((valueSeq, i) => {
        tArray1.push(valueSeq.split("^"));
      });

      tArray2 = tArray2.concat(slNos);
    });

    rMap.stripValuesSeqs = [...tArray1];
    rMap.slNos = [...tArray2];

    console.log(rMap);

    displayFilteredPage(pageNo, rMap);

    setRecordCount(rMap.slNos.length);

    //displayFiltPage(pageNo, rMap);

    //console.log(stripFilteredValuesDataMap);
    return rMap;
  }

  function displayFilteredPage(pageNo, valuesDataMap) {
    setPageNo(pageNo);
    console.log(pageNo);

    const rArray = [];

    const size = valuesDataMap.slNos.length;

    const startIndex =
      pageLimit * pageNo - pageLimit > size - 1
        ? size - 1
        : pageLimit * pageNo - pageLimit;
    const endIndex =
      startIndex + pageLimit - 1 > size - 1
        ? size - 1
        : startIndex + pageLimit - 1;

    console.log(startIndex, endIndex);

    if (startIndex == endIndex) {
      rArray.push(valuesDataMap.stripValuesSeqs[startIndex]);
    } else {
      for (let x = startIndex; x <= endIndex; x++) {
        rArray.push(valuesDataMap.stripValuesSeqs[x]);
      }
    }

    setStripValuesSeqs([...rArray]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center">
        <input id="date-from" type="date"></input>
        <input id="date-to" type="date"></input>
        <button
          onClick={() =>
            displayPagesBasedOnFilter(
              document.getElementById("date-from").value,
              document.getElementById("date-to").value
            )
          }
        >
          Go
        </button>
      </div>
      <div className="bg-primary-100">
        <div
          className={`title-strip grid w-11/12 mx-auto mt-1 grid-cols-cust-8b bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
        {stripValuesSeqs.length != 0 &&
          stripValuesSeqs.map((stripValues) => {
            return (
              <RecordStrip
                stripValues={stripValues}
                labelNames={props.passMap.labelNames}
              />
            );
          })}
      </div>
      <div className="flex mx-auto gap-5">
        {viewMode == "normal" &&
          renderPagination(recordCount, stripValuesDataMap)}

        {viewMode == "filter" &&
          renderFilteredPagination(recordCount, stripFilteredValuesDataMap)}
      </div>
      {bottomContainer}
    </React.Fragment>
  );
}
