import React from "react";
import { getStoreLabelValue } from "../Utils";
import BannerButton from "./BannerButton";
import RecordLabelUnit from "./RecordLabelUnit";

export default function RecordBanner(props) {

  console.log(props);
  return (
    <div className="m-4">
      <div className="grid grid-cols-cust-2c rounded-cust-1 bg-quaternary-light border-std items-center">
        <div className="h-full flex row-span-2 justify-center items-center text-4xl border-sl-no">
          {props.slNo}
        </div>

        <div className="h-full grid grid-rows-cust-2f grid-cols-cust-4a">
          {props.labelNames.map((labelName, index) => {
            return (
              <RecordLabelUnit
                key={labelName}
                colorScheme={props.bannerColorScheme}
                labelName={labelName}
                labelValue={getStoreLabelValue(
                  labelName,
                  props.passDataMap[props.labelDbKeys[index]],
                  props.passDataMap2
                )}
              />
            );
          })}
        </div>
      </div>
      {props.btnDataMap.btnLabels.length > 0 && (
        <div className="flex justify-end">
          {props.btnDataMap.btnLabels.map((btnLabel, i) => (
            <BannerButton
              key = {btnLabel}
              btnLabel = {btnLabel}
              btnAlertLevel = {props.btnDataMap.btnAlertLevels[i]}
              btnAvlblty = {props.btnDataMap.btnAvlblties[i]}
              // btnData={props.btnData.btnDataMaps[i]}
              actionHandler={() =>
                props.actionHandler({
                  // dbData: props.passDataMap,
                  // actionName: btnLabel,
                  // fnName: props.fnName,
                  actionName: btnLabel,
                  actionReqdCond: props.btnDataMap.btnAvlblties[i],
                  //fnName: props.fnName,
                  //dbData: {...props.passDataMap,...props.passMap}, //If Prepaid Card has to be enabled props.passMap may be required
                  dbData:{...props.passDataMap}
                })
              }
            />
          ))}
        </div>
      )}
    </div>
  );
}

// typeof props.passDataMap[props.labelDbNotations[index]] == "object"
//   ? props.passDataMap[props.labelDbNotations[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbNotations[index]]
