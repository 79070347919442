import React, { useContext, useEffect, useState } from "react";
import ActionButton from "./ActionButton";
import { checkAndFinalizeInpDataObj, createEditInpObj, createInitialObj, getInputValue, revisePassMap } from "./HelperFns";
import InputElementsCont2 from "./InputElementsCont2";
import { UserDataContext } from "./Level1";

export default function InputContDelegate(props) {


  const userContext = useContext(UserDataContext); //Main User Data of the Current User Broadcasted from Level 1

  console.log(userContext);
  console.log(props.schemeMap);

  console.log(props)

  const initPassDataObj = { ...revisePassMap(props.schemeMap) }; 

  const initInpObj = 
  props.schemeMap.opType == "edit"
    ? createEditInpObj(props.dataMap,props.schemeMap)
    : createInitialObj(initPassDataObj); //Template Object of the 'Input Data Object'

  const [inpDataObj, setInpDataObj] = useState({ ...initInpObj });
  const [passDataObj, setPassDataObj] = useState({ ...initPassDataObj });

  const [inputDomain, setInputDomain] = useState("");
  const [editStatusArray, setEditStatusArray] = useState([
    ...props.schemeMap.editStatus,
  ]);

  const [inpImg, setInpImg] = useState("");

  const [showPrompt, setShowPrompt] = useState(false);
  const [controlObj,setControlObj] = useState({})

  // const [submitClicked, setSubmitClicked] = useState(false);
  const [curTabName, setCurTabName] = useState("General");
  const [tabChanged, setTabChanged] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPromptConf, setShowPromptConf] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [valdCount, setValdCount] = useState(0);

  console.log("In Input Cont ");
  useEffect(() => {}, [curTabName]);


  console.log(inpDataObj)
  console.log(props.submitClickedCond)


  function inputChangeFn(e) {

    const passDataObj = props.schemeMap
    
    console.log(e)
    const rInputName = e.target.name;
    const rInputType = e.target.type;
    let rInputValue = getInputValue(e.target);
    console.log(e.target);
    console.log(rInputValue);

    if (rInputType == "file") {
      setInpImg(URL.createObjectURL(rInputValue));
    }

    if (rInputName == "ovrStoreAvlCond") {
      //Enabling Store Availability Option when override is true
      const tMap = { ...passDataObj };
      const tArr = [...editStatusArray];
      const mIndex = tMap["inputNames"].indexOf("storeAvlCond");
      const mIndex1 = tMap["inputNames"].indexOf("storeStartTime");
      const mIndex2 = tMap["inputNames"].indexOf("storeEndTime");

      console.log(tArr);
      console.log(tMap)
      if (rInputValue) {
        tArr.splice(mIndex, 1, true);
        tArr.splice(mIndex1, 1, false);
        tArr.splice(mIndex2, 1, false);
      } else {
        tArr.splice(mIndex, 1, false);
        tArr.splice(mIndex1, 1, true);
        tArr.splice(mIndex2, 1, true);
      }
      setEditStatusArray([...tArr]);
    } else if (rInputName == "ovrWaitTimeCond") {

    }else if (rInputName == "ovrWaitTime") {
      rInputValue = rInputValue*60
    }

    console.log(inpDataObj);
    console.log(inputDomain);
    const tempObj = { ...inpDataObj }; //copying the 'Input Object State' into a temp object to then set as 'Input Object State'
    // Above doing this so, is because if tempObj=inpDataObj, then changing the tempObj will also chaneg the input object and
    //then when I set the State, the state will not update as the temp and input objects are the same


    if (rInputName == "invoiceDiscount") {
      const actInvoiceAmount = props.schemeMap.dataMap.invoiceAmount;
      tempObj["invoiceAmount"] = (actInvoiceAmount * (1-(rInputValue/100))).toFixed(2);
    }else if(rInputName == "invoiceAmount"){
      const actInvoiceAmount = props.schemeMap.dataMap.invoiceAmount;
      tempObj["invoiceDiscount"] = ((1-(rInputValue/actInvoiceAmount))*100).toFixed(2);
    }

    tempObj[rInputName] = rInputValue;

    setInpDataObj(checkAndFinalizeInpDataObj(tempObj)); //Input Object Set
    console.log("setinpDataObj=",checkAndFinalizeInpDataObj(tempObj));
    console.log(inpDataObj);
  }

  function tabClickHandler(tabName) {
    setCurTabName(tabName);
  }



  const hScreenCond = props.schemeMap.fnType == "modal" ? false : true
  const deleteFnReqd = props.schemeMap.deleteFnReqdCond

  return (
    <div className={`grid justify-items-center p-2`}>
                    <InputElementsCont2
                      passDataObj={props.schemeMap}
                      inpDataObj={inpDataObj}
                      inpChangeFn={(e)=>inputChangeFn(e)}
                      changeValdCountFn={(val)=>setValdCount(valdCount+val)}
                    />
                    <ActionButton btnLabel="Submit" clickHandler={()=>props.submitClickHandler(inpDataObj)}/>
    </div>
  );
}
