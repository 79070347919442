import React, { Component } from "react";

export default function InputSwitch(props) {
  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value
  return (
    <div className="grid grid-cols-cust-2g flex-row gap-1 bg-quaternary-xlight p-3">
      <label>{props.inputLabel}</label>
      <div className="flex">
        <div className={`flex items-center px-3 border-2 border-primary rounded-l-lg cursor-pointer ${!props.inputValue?`bg-primary text-quaternary-light`:`text-black`}`} onClick={()=>props.onChangeFn(false)}>
          OFF 
        </div>

         <div className={`flex items-center px-3 border-2 border-primary rounded-r-lg cursor-pointer ${props.inputValue?`bg-primary text-quaternary-light`:`text-black`}`} onClick={()=>props.onChangeFn(true)}>
          ON
        </div>
      </div>

    </div>
  );
}
