import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import RecordStrip from "./RecordStrip";
import FilterInput from "./FilterInput";
import ImageCont from "./ImageCont";
import RecordBanner from "./RecordBanner";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";
import NoAvailableDisplay from "./NoAvailableDisplay";

const pageLimit = 5;

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}

function getDbCollPath(props){
  switch(props.passMap.listTopic){
    case "Transaction":{
      return `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
    }
    case "Past Order":{
      return `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
    }
  }
}


export default function StripListFilterCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbNotations);
  console.log(props.passMap);

  const [compListDataMap, setCompListDataMap] = useState({
    valueSeqs: [],
    slNos: [],
  });

  const [compFilListDataMap, setCompFilListDataMap] = useState({
    valueSeqs: [],
  });

  const [pageStripValuesColl,setPageStripValuesColl]=useState([])


  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  const [slNoArray, setSlNoArray] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});
  const [recordCount, setRecordCount] = useState(
    0
  );
  const [pageNo, setPageNo] = useState(0);
  const [viewMode, setViewMode] = useState("normal");

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    const tMap = {};


      tMap["collQuery"] = query(
        collection(
          db,
          getDbCollPath(props)
        ),
        orderBy("docCreationDate", "desc"),
        limit(2) //Limiting 2, because each transaction document will hold 100 transaction values, so 2 docs will obviously fill the page
      );
    

    const dbDocs = await getDocs(tMap["collQuery"]);

    console.log(dbDocs.docs[0].data())

    if (dbDocs.size != 0) {

    const iRecordCount =
      props.passMap.listTopic == "Transaction"
        ? props.passMap.latestTransactionSl
        : dbDocs.docs[0].data().latestRecordSlNo;

        console.log(iRecordCount)

        setRecordCount(iRecordCount)

      

      setCompListDataMap(
        extractAndSetViewDataMap(iRecordCount,dbDocs,compListDataMap, 1)
      );
    }else{

      props.stopSpinner()
    }
  }

  function extractAndSetViewDataMap(
    sRecordCount,
    dbDocs,
    compListDataMap,
    pageNo
  ) {
    const tArray1 = [];
    const tArray2 = [];
    let slNoCount=0;

    console.log(dbDocs.docs[0])

    dbDocs.docs.forEach((doc,i) => {
      const valueSeqs = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;

    

      console.log(valueSeqs);

      //Extraction of data for the strips

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");

        console.log(valueSeqArr)

  
          slNoCount++;

          const uArr=[]
  
          props.passMap.labelDbNotations.forEach((dbNotation)=>{
            console.log(dbNotation)
      
            const mIndex = props.passMap.valueSeqDbKeys.indexOf(dbNotation)
            console.log(mIndex)
            if(dbNotation=="slNo"){ //Checking if key is slNo,as sl No is to be put first and it is not in the database
              uArr.unshift(slNos[i])
              tArray2.push(slNos[i])
            }else{
              if(mIndex!=-1){ //checking if the key is common in both the string value seq and the required db key list
                uArr.push(valueSeqArr[mIndex])
              }
            }
          })

        tArray1.push(uArr);
       
        

      });

    });

    compListDataMap.valueSeqs = [...compListDataMap.valueSeqs,...tArray1];
    compListDataMap.slNos = [...compListDataMap.slNos,...tArray2];

    //const sRecordCount = compListDataMap.valueSeqs.length;
    setRecordCount(sRecordCount)

    displayPage(sRecordCount, pageNo, compListDataMap);

    console.log(compListDataMap);
    props.stopSpinner()
  
    return compListDataMap;
  }

  console.log(pageNo);



  function displayPage(sRecordCount, sPageNo, compListDataMap) {
    // if (sPageNo !== pageNo) {
      setPageNo(sPageNo);
      console.log(sPageNo);
      const latestSl =
        props.passMap.listTopic == "Transaction"
          ? props.passMap.latestTransactionSl
          : sRecordCount;
      const slNoArray = compListDataMap.slNos;

      console.log(latestSl);

      const startSlOfPage =
        pageLimit * (sPageNo - 1) + 1 > sRecordCount
          ? sRecordCount
          : pageLimit * (sPageNo - 1) + 1;
      const endSlOfPage =
        pageLimit * sPageNo > sRecordCount ? sRecordCount : pageLimit * sPageNo;

      console.log(endSlOfPage, startSlOfPage);

      console.log(slNoArray);

      const slQueryArray = [];

      [startSlOfPage, endSlOfPage].forEach((item) => {
        if (slNoArray.indexOf(item) == -1) {
          console.log(item)
          slQueryArray.push(item);
        }
      });

      if(props.passMap.dbGlobalScope){

      }else{
        
      }

      if (slQueryArray.length == 0) {
        console.log("adkdasjkd")
        assignStripValues(startSlOfPage, endSlOfPage, compListDataMap); //If values of page asked are available
      } else {
        console.log("adkdasjkd1")
        fetchAndSetStripValues(
          startSlOfPage,
          endSlOfPage,
          slQueryArray,
          compListDataMap,
          sPageNo
        );
      }
    // }
  }

  console.log(pageStripValuesColl)

  function assignStripValues(startSlNo, endSlNo, compListDataMap) {
    const rArray = [];

    console.log("here1");

    const slNoArray = [...compListDataMap.slNos];
    const stripValuesColl = [...compListDataMap.valueSeqs];

    console.log(stripValuesColl);

    const mIndexStart = slNoArray.indexOf(startSlNo);
    const mIndexEnd = slNoArray.indexOf(endSlNo);

    for (let y = startSlNo; y <= endSlNo; y++) {
      const stripValueArray = [...stripValuesColl[slNoArray.indexOf(y)]];
      //stripValueArray.unshift(y);
      rArray.push(stripValueArray);
    }

    console.log(rArray);

    setPageStripValuesColl(rArray);
    props.stopSpinner()
  }

  async function fetchAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    compListDataMap,
    pageNo
  ) {

    //const docName = props.passMap.listTopic=="Transaction"?"storeBranchTransactions":props.passMap.listTopic=="Past Order"?"pastOrders":""

    const collQuery = query(
      collection(
        db,
        getDbCollPath(props)
      ),
      where("recordNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setCompListDataMap(
      extractAndSetViewDataMap(dbDocs, compListDataMap, pageNo)
    );
  }

  function renderPagination(
    sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {

    console.log(sRecordCount);
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    const rArray = [];

    console.log(sListValuesCollMap);

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(sRecordCount, x + 1, sListValuesCollMap)}
          className={`p-cust-3 ${
            x + 1 == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  function renderFilterPagination(sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo) {
    const pageCount = Math.ceil(recordCount / sPageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayFiltPage(sRecordCount, x + 1, sListValuesCollMap)}
          className={`p-cust-3 ${
            x + 1 == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  

  function renderFilPagination(){

  }



  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

 

  async function filterClickFn(e,filterName){
      console.log(e.target.parentElement)
      console.log(filterName)
      setViewMode("filter")

      let filDbArrayName = ""

      console.log("before")

      switch(filterName){
        case "Date":{

        }
        break;
        case "Party ID":{

          filDbArrayName=props.passMap.listTopic=="transaction"?"transactionPartyIds":"orderCustomerIds"
        }
        break;
        case "Party Name":{
          filDbArrayName=props.passMap.listTopic=="transaction"?"transactionPartyNames":"orderCustomerNames"
         
        }
        break;
        case "Employee Name":{

        }
        break;
        case "Job Name":{

        }
        break;
      }

      const inputNodes = e.target.parentElement.getElementsByTagName("input");

      const filValue = inputNodes[0].value


    
      const collQuery = query(
        collection(
          db,
          getDbCollPath(props)
        ),
        where(filDbArrayName,"array-contains",filValue),
        orderBy("docCreationDate", "desc") 
      );

      const dbDocs = await getDocs(collQuery);

      console.log(dbDocs.size)

      generateFilterDataMap(dbDocs,filValue,filDbArrayName);


  }

  function generateFilterDataMap(dbDocs,filValue,filDbArrayName){

    const tArray1 = [];

    const map={}

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringSeqDatas

      //Extraction of data for the strips

      let slCount=0;

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");
        const uArr=[]

        const filDbKey = filDbArrayName.substring(0,filDbArrayName.length-1)

        const filKeyIndex = props.passMap.valueSeqDbKeys.indexOf(filDbKey);

        if(valueSeqArr[filKeyIndex]==filValue){ //Do push operation only if the filterValue is available in the seq
          slCount++; //Initialize the Sl No
        props.passMap.labelDbNotations.forEach((dbNotation)=>{
     
          const mIndex = props.passMap.valueSeqDbKeys.indexOf(dbNotation)
          if(dbNotation=="slNo"){ //Checking if key is slNo,as sl No is to be put first and it is not in the database
            uArr.unshift(slCount)
          }else{
            if(mIndex!=-1){ //checking if the key is common in both the string value seq and the required db key list
              uArr.push(valueSeqArr[mIndex])
            }
          }
        })
        tArray1.push(uArr);
      }
      });

    });

    map["valueSeqs"]=[...tArray1];


    console.log(map)
    setCompFilListDataMap({...map})

 
    const iRecordCount = map.valueSeqs.length
    setRecordCount(iRecordCount)
    displayFiltPage(iRecordCount,1, map);
  }


  function displayFiltPage(sRecordCount,sPageNo,compFilListDataMap){

      setPageNo(sPageNo);
  
      const latestSl = sRecordCount;
  
      const slNoArray = compFilListDataMap.slNos;
  
      const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > sRecordCount
        ? sRecordCount
        : pageLimit * (sPageNo - 1) + 1;
  
    const endSlOfPage =
      pageLimit * sPageNo > sRecordCount ? sRecordCount : pageLimit * sPageNo;
  
      const rArray = [];

  
      for (let y = startSlOfPage; y <= endSlOfPage; y++) {
        console.log(compFilListDataMap)
        console.log(startSlOfPage)
        const stripValueArray = [...compFilListDataMap.valueSeqs[y-1]];
        //stripValueArray.unshift(y);
        rArray.push(stripValueArray);
      }
  
      console.log(rArray);
  
      setPageStripValuesColl(rArray);
  
    
  }
  

  return (
    <div className="h-screen grid grid-rows-cust-2c gap-y-4">
 {pageStripValuesColl.length != 0 && 
 <div className="grid grid-rows-cust-2i" >
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-row">
          <select onChange={(e) => setFilterName(e.target.value)}>
            {props.passMap.filterSchemeMap.filterNames.map(
              (filterOptionName, i) => {
                return (
                  <option key={i} value={filterOptionName}>
                    {filterOptionName}
                  </option>
                );
              }
            )}
          </select>
          <div>
            <FilterInput
              filterName={filterName}
              filterSchemeMap={filterSchemeMap}
              filterValueMap={filterValueMap}
              inputChangeFn={(data) => inputChangeFn(data)}
              filterClickFn={(data,filterName)=>filterClickFn(data,filterName)}
            />
          </div>
          {/* <button
            className="btn-filter mx-1"
            onClick={() =>
              displayPagesBasedOnFilter(filterName, filterValueMap)
            }
          >
            Go
          </button> */}
        </div>
        <div className="flex mt-8 border-page-no">
          {viewMode=="normal" && renderPagination(pageLimit, compListDataMap, recordCount, pageNo)}
          {viewMode=="filter" && renderFilterPagination(pageLimit, compFilListDataMap, recordCount, pageNo)}
        </div>
      </div>
      

      <div className="">

        <div
          className={`title-strip grid w-11/12 mx-auto mt-1 grid-columns-${props.passMap.labelDbNotations.length}a bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
       
          {pageStripValuesColl.map((stripValues) => {
            return (
              <RecordStrip
                stripValues={stripValues}
                labelNames={props.passMap.labelNames}
                passMap2={props.passMap.settings}
              />)
            })
          }
        </div>
      </div>//End of Body Cont
}
        {pageStripValuesColl.length == 0 && 
                      <NoAvailableDisplay textName="No Records Found"/>
        }

      {/* <div className="flex mx-auto gap-5">
        {viewMode == "normal" && renderPagination(viewMode,recordCount, compListDataMap)}

        {viewMode == "filter" &&
          renderFilteredPagination(recordCount, compFilListDataMap)}
      </div> */}
       <div className="absolute bottom-0 h-24 w-full flex justify-center items-center gap-4">
        {bottomContainer}
      </div>
    </div>
  );
}



// function getDbCollPath(props){
//   switch(props.listTopic){
//     case "Transaction":{
//       return `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
//     }
//     case "Past Order":{
//       return `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
//     }
//   }
// }