import React from "react";






export default function ModalBoxMessagePromptWithOperation(props) {

  console.log(props)
 

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-4 px-4 gap-y-24 items-center rounded-lg">
        <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
        {props.passMap.title}
        </div>
        <div className="flex items-center justify-center text-center">{props.passMap.message}</div>
        <div className="w-full">
          <div className="flex items-center justify-center gap-4">
          <button className="btn-action" onClick={async() => await props.passMap.opFn()}>
            {props.passMap.opBtn}
          </button>
            <button className="btn-action" onClick={() => props.promptClose()}>
            Close
          </button>
          </div>
        </div>
      </div>
    </div>
  )
}
