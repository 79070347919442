import React from "react";

import BtnNormalModal from "./BtnNormalModal";




// export default function ModalBoxOpConfPrompt(props) {

//   const [showMessage,setShowMessage]= useState(false)
//   const [message,setMessage] = useState("")
//   const [opCompleted,setOpCompleted] = useState(false)

//   console.log(props) 

//   async function opFunction(fn){

//     const result = await fn();
//     console.log(result)
//     setOpCompleted(true)
//     props.opCompletionHandler()
//     setShowMessage(true)
//     setMessage(result)
//   }

//   function promptCloseFn(actionType,opCompletionStatus){
//     if(actionType=="delete"){

//       props.opCompletionHandler(props.passMap.actionType,opCompletionStatus)
//     }
//     props.promptClose()
//   }

//   return (
//     <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
//       <div className="grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-4 px-4 gap-y-24 items-center rounded-lg">
//         <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
//         {showMessage ? "Result" : "Confirmation"}
//         </div>
//         <div className="flex items-center justify-center text-center">{showMessage ? message : props.passMap.message}</div>
//         <div className="w-full">
//           <div className="flex items-center justify-center gap-4">
//             {!showMessage && 
//                 <ButtonModalOp  label="Yes, Proceed" alertLevel="high" clickHandler={async () => await opFunction(props.passMap.opFn)} />
//               }
//             <button className="btn-action-sm hover-black" onClick={() => promptCloseFn(props.passMap.actionType,opCompleted)}>
//             {showMessage ? "Ok" : "Close"}
//           </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }


export default function ModalBoxOpConfPrompt(props) {

  console.log(props) 

  async function opFunction(fn){

    fn();
    props.promptClose()
  }

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-4 px-4 gap-y-24 items-center rounded-lg">
        <div className="h-full w-fit flex mx-auto pt-4 px-8 text-2xl font-bold">
        Confirmation
        </div>
        <div className="flex items-center justify-center text-center">{props.passMap.message}</div>
        <div className="w-full">
          <div className="flex items-center justify-center gap-4">

                <BtnNormalModal label="Yes, Proceed" alertLevel="high" clickHandler={() => opFunction(props.passMap.opFn)} />
                <BtnNormalModal label="Close" color="black" alertLevel="low" clickHandler={() => props.promptClose()} />
            
            {/* <button className="btn-action-sm hover-black" onClick={() => props.promptClose()}>
            Close
          </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}