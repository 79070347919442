// import XLSX from "xlsx/xlsx.mjs";

import React, { useState } from 'react';

const XLSX = require('xlsx')

export default function SpreadsheetBatchInputCont(props){

    console.log(props)

    const [spreadsheetRecords,setSpreadsheetRecords] = useState([])

    function fileInputChangeFn(e){
        console.log(e.target.files[0])

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);

        fileReader.onload = (ev) => {
            const data = ev.target.result;
            const workbook = XLSX.read(data,{type:'binary'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName]
            const parsedData = XLSX.utils.sheet_to_json(sheet);

            console.log(parsedData)

        }
    }

    return(
    <div className=" h-screen flex-center">
        {spreadsheetRecords.length == 0 &&
        <input type="file" onChange={(e)=>fileInputChangeFn(e)} />
        }
        {spreadsheetRecords.length > 0 &&
            <React.Fragment>
             <div></div>
            </React.Fragment>
        }

    </div>
    )
}