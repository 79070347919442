import React, { Component } from "react";

export default function BannerButton(props) {
  console.log(props);
  const hoverColor = props.btnAlertLevel == "High"?`hover-red`:props.btnAlertLevel == "Disabled" ? ``:`hover-black`;
  return (
    <button
      className={`p-cust-3 banner-button ${hoverColor}`}
      disabled={props.btnAlertLevel == "Disabled"}
      style={{
        borderColor: `  ${
          props.btnAlertLevel == "High"
            ? `rgb(199 60 60)`
            : props.btnAlertLevel == "Low"
            ? `rgb(0,0,0)`
            : props.btnAlertLevel == "Disabled"
            ? `rgb(150,150,150)`
            : `rgb(199 60 60)`
        }`,
      }}
      onClick={() => props.actionHandler()}
    >
      {props.btnLabel}
    </button>
  );
}
