import React, { useState } from "react";
import BtnNormalModal from "./BtnNormalModal";




export default function ModalBoxMessagePromptWithMultipleOps(props) {

  console.log(props)

  const [opCompletedCond,setOpCompletedCond] = useState(false)
  const [opInProgressCond,setOpInProgressCond] = useState(false)
  const [opMessage,setOpMessage] = useState(false)

  async function opFn(fn,i){


    setOpInProgressCond(true)

    //optional convertor
    const fnPayload = "opFnPayloads" in props.passMap ? props.passMap.opFnPayloads[i] : {}
    console.log(fnPayload)


    try{
    const op = await fn(fnPayload);
    console.log(op)
    setOpCompletedCond(true);
    setOpMessage(props.passMap.opSuccessMsgs[i]);
    setOpInProgressCond(false)
    }catch(error){
      console.log(error.message)
      setOpMessage(error.message);
      setOpInProgressCond(false)
    }
  }
 

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-6 px-4 gap-y-24 items-center rounded-lg">
        <div className="h-full w-fit flex mx-auto  pt-4 px-8 text-2xl font-bold">
        {props.passMap.title}
        </div>
        <div className="flex items-center justify-center text-center">{opCompletedCond ? opMessage : props.passMap.message}</div>
        <div className="w-full">
          <div className="flex items-center justify-center gap-4">

          {!opCompletedCond && !opInProgressCond &&
            <React.Fragment>
              {props.passMap.opBtnLabels.map((btnLabel,i)=>
                <BtnNormalModal label={btnLabel} alertLevel={"opBtnAlertLevels" in props.passMap ? props.passMap.opBtnAlertLevels[i] : "low" } clickHandler={async() => await opFn(props.passMap.opFns[i],i)}/>
              )}
            </React.Fragment>
          }
          
          {opInProgressCond && <div className="loader"></div>}
            <BtnNormalModal label="Close" alertLevel="default" clickHandler={() => props.promptClose()}/>
          </div>
        </div>
      </div>
    </div>
  )
}
