import React, { Component } from "react";
import RecordLabelUnit from "./RecordLabelUnit";
import { getStoreLabelValue, getDate } from "../Utils";

export default function RecordStrip(props) {
  console.log(props);

  function checkAndReturnValue(value, labelName) {
    if (labelName == "Date/Time") {
      return getDate(value);
    } else {
      return value;
    }
  }
  return (
    <div className={`title-strip grid w-11/12 mx-auto mt-1 grid-cols-cust-${props.stripValues.length}a bg-quartenaryLight rounded-sm border border-borderStd`}>
      {props.stripValues.map((value, i) => {
        return (
          <div className="value-strip-unit text-center text-lightBackText">
            {/* //{checkAndReturnValue(value, props.labelNames[i])} */}
            {getStoreLabelValue(props.labelNames[i],value,props.passMap2)}
          </div>
        );
      })}
    </div>
  );
}

// typeof props.passDataMap[props.labelDbNotations[index]] == "object"
//   ? props.passDataMap[props.labelDbNotations[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbNotations[index]]
