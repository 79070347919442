import React, { useState } from "react";
import { getRegexPattern, getSettingsLabelValue } from "../Utils";
import DualInputWrapper from "./DualInputWrapper";

export default function InputTile2(props) {

  // const [regexResult,setRegexResult] = useState(true)
  // const [regexPatternA,setRegexPatternA] = useState("")
  const [inpValdOkCond,setInpValdOkCond] = useState(true)
  const [deductedVald,setDeductedVald] = useState(false)

  console.log(props.inputName)
  console.log(props)

  function checkAndDisable(editStatus, inputName) {
    if (!editStatus) {
      return true;
    } else {
    }
  }

  // useEffect(()=>{

  //   if(props.inpMandCond){
  //     setDeductedVald(true)
  //     props.changeValdCount(-1)
  //   }

  // },[]);



  const regexPattern = getRegexPattern(props.regexDomain);


  function onChangeHandler(e,regexPattern,deductedVald,noOfInputs){

    props.onChangeFn(e)
    console.log(e.target.value)


    let inpVal = e.target.value;

    if(noOfInputs==2){
      inpVal =  `${inpVal[0]}${inpVal[1]}`
    }


    const valdResult = !props.inpMandCond && inpVal === "" ? true : regexPattern.test(inpVal);

    if(!valdResult && !deductedVald){
      setDeductedVald(true)
      props.changeValdCount(-1)
    }

    if(valdResult && deductedVald){
      setDeductedVald(false)
      props.changeValdCount(1)
    }

  
    setInpValdOkCond(valdResult)

  }

  // function onChangeHandler(e,regexDomain){
  //   props.onChangeFn(e)
  //   console.log(e.target.value)
  
  //   console.log(regexPattern.test(e.target.value))
  //   setRegexResult(regexPattern.test(e.target.value))

  // }


  const dualInputTypes = ["phoneNo"]
  const isDualInput = dualInputTypes.includes(props.inputType)

  return (
    <div className={`grid grid-cust-cols-2 gap-2`}>
      <label>{`${props.inputLabel}${props.inpMandCond ? `*` : ``}`}</label>
      {!isDualInput && props.inputType!="textarea" && 
        <input
          className="m-0 rounded-sm"
          value={props.inputValue}
          type={props.inputType}
          name={props.inputName}
          onChange={(e)=>onChangeHandler(e,regexPattern,deductedVald,1)}
          // onChange={(event) => onChangeHandler(event,regexPattern)}
        ></input>
      }

    {!isDualInput && props.inputType=="textarea" && 
        <textarea
          className="m-0 rounded-sm p-2"
          value={props.inputValue}
          name={props.inputName}
          onChange={(e)=>onChangeHandler(e,regexPattern,deductedVald,1)}
          // onChange={(event) => onChangeHandler(event,regexPattern)}
        ></textarea>
      }
          {isDualInput && 
        <DualInputWrapper
        values={getSettingsLabelValue(props.inputLabel, props.inputValue)}
        inpType={props.inputType}
        inpName={props.inputName}
        checked={props.inputValue}
        disabled={!props.editStatus}
        onChangeFn={(eMap)=>onChangeHandler(eMap,regexPattern,deductedVald,2)}
        />
      }
      {!inpValdOkCond && <span className="text-secondary text-xs">*Invalid Input</span>}
    </div>
  );
}




