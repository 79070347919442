
import React, { useEffect, useState } from "react";

import { getDbMultiDependencyInpOptions } from "./projectFns";
import { styleMapProject } from "./viewProps";

export default function InputMultiDependencyListAsync(props) {
  console.log(props)

  const [selectOptionMaps,setSelectOptionMaps] = useState([])

  useEffect(()=>{
    (async () => {
      try {

        console.log("run")

        const dbMultiDependencySelectionSchemeMap = props.dbMultiDependencySelectionSchemeMap

        const selectionsFromDb = await getDbMultiDependencyInpOptions(dbMultiDependencySelectionSchemeMap,props.inpValMap)

        console.log(props.inpValMap,selectionsFromDb)

        const labelKeyName = dbMultiDependencySelectionSchemeMap.selectOptionsLabelKey
        const valueKeyName = dbMultiDependencySelectionSchemeMap.selectOptionsValueKey

        const finalSelectionMaps = selectionsFromDb.map((selectionMap)=>{return {label:selectionMap[labelKeyName],value:selectionMap[valueKeyName]}})

        setSelectOptionMaps(finalSelectionMaps)

      } catch (err) {
        console.log(err)
        console.log('Error Initializing Page Data');
      }
    })();
  
  },[props.inpValMap]);


  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value
  return (
    <div className={`grid grid-cols-cust-2g gap-1 bg-${styleMapProject.inputElem.background} p-3`}>
      <label>{props.inpLabel}</label>
      {props.inpType=="select" && <select
        className="pl-2"
        type={props.inpType}
        name={props.inpKey}
        onChange={props.onChangeFn}
        // defaultValue={props.fieldValue}
        value={props.inpValue }
      >
        <option value="">Please Select</option>
        {selectOptionMaps.map((optionMap, i) => (
          <option key={optionMap.value} value={optionMap.value}>
            {optionMap.label}
          </option>
        ))}
      </select>}

      {props.inpType=="fixed" && 
        <div>{getFixedOptionLabel(props.inpValue,selectOptionMaps)}</div> 
      }

    </div>
  );
}


function getFixedOptionLabel(inpValue,selectOptionMaps){

  for (const optionMap of selectOptionMaps){

    if(optionMap.value==inpValue){
      return optionMap.label
    }


  }
    return "NA"
}