import React from "react";

export default function DetailTile(props) {
  return (
    <div className="grid grid-cols-cust-2f h-16 p-2">
      <div className="flex items-center justify-center text-center bg-quaternary-light">
        {props.labelName}
      </div>
      <div className="flex items-center justify-center text-center text-secondary border-desc-light">
        {props.labelValue}
      </div>
    </div>
  );
}
