import React, { Component, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  electron,
  pageSizes,
  getSerialNo,
  db,
  doc,
  getDoc,
  updateDoc,
  runTransaction,
  Timestamp,
  serverTimestamp,
} from "../Utils";
import BottomContainer from "./BottomContainer";


import { read,utils, writeFileXLSX } from "xlsx";

export default function ModalBoxExcel(props) {

  const [qrCount,setQRCount]=useState(50)
  
async function generateExcel(qrCount){
 
  const adminQrDoc = await getDoc(doc(db,`admin/qrCode`));
  const latestQrSl = adminQrDoc.data().latestQrCodeSl;
  const latestSheetQrId = adminQrDoc.data().latestQrCodeSheetId;

  const rows=[]

  for(let x=1;x<=qrCount;x++){
    const qrSl = latestQrSl+x;
    const tMap = {}

    tMap["QR Codes"]=`QR${qrSl}`;
    rows.push(tMap);
  }

  const newQrSl=latestQrSl+qrCount;
  const newQrCodeSheetId = getSerialNo(latestSheetQrId)


  const worksheet = utils.json_to_sheet(rows);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "QR Codes");
  writeFileXLSX(workbook, `QR Codes(${newQrCodeSheetId}).xlsx`, { compression: true });

  await updateDoc(doc(db,`admin/qrCode`),{
    docModificationDate:serverTimestamp(),
    latestQrCodeSl:newQrSl,
    latestQrCodeSheetId:newQrCodeSheetId
  });

// const rows = [
//   { "": "George Washington", birthday: "1732-02-22" },
//   { "": "John Adams", birthday: "1735-10-19" },
//   // ... one row per President
// ]

}

function qrCountClick(e,count){

  Array.from(e.target.parentElement.children).forEach((child)=>{
    child.classList.remove("page-block-click")
  })

  e.target.classList.add("page-block-click")
  setQRCount(count)
}

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="relative h-3/5 w-4/5 bg-quaternary-light grid items-center justify-center">
      <div className="h-full flex justify-center cont-title-red-marker pt-2 text-xl">
        GENERATE QR SPREADSHEET
      </div>
       <div className="flex flex-col items-center justify-center gap-8">
            <div className="flex items-center justify-center gap-16">
              {props.passMap.qrCounts.map((qrCount)=>
                <div className="flex items-center justify-center page-block" value={qrCount} onClick={(e)=>qrCountClick(e,qrCount)}>
                  {qrCount}
                </div>
              )}
           </div>
            <button className="btn-action btn-alert-high" onClick={() => generateExcel(qrCount)}>Generate</button>
       </div>
        <button className="absolute top-5 right-5 btn-close hover-black" onClick={() => props.closeClickHandler()}>
          X
        </button>
      </div>
    </div>
   );
}
