import React, { useEffect, useState } from "react";
import BottomContainer from "./BottomContainer";
import { skateMaintenanceJobs } from "./DataList";
import RecordBanner3 from "./RecordBanner3";
import RecordBanner4 from "./RecordBanner4";


//const db=getFirestore(firebase);

//function to create an empty field object with keys of the templateObj passed to it
function createInitialObj(templateObj) {
  const obj = {};

  templateObj[templateObj.mainKey].forEach((field) => {
    obj[field] = "";
  });

  return obj;
}

export default function BannerListListenerCont2(props) {

console.log(props)

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  const [iterationsElapsed,setIterationsElapsed] = useState(1)

  useEffect(()=>{

    setInterval(()=>{
      
      setIterationsElapsed((prevCount)=> prevCount+1)

    },5000)

  },[])

  const firstListLimit = 5;
  const secondListLimit = 8;

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbKeys);
  console.log(props);
  console.log(props.passListDataArray);

  const customerDisplayCond = props.passMap.fnName == "Show Current Orders";

  //const gridColScheme = props.passListDataArray.length == 0 ? "" : customerDisplayCond ? "grid grid-cols-cust-2eq" : ""

  const noTextDesc = customerDisplayCond ? "No Orders" : "No Records"


  function shouldRender(index,limit,length,iterationsElapsed){


    const totalTurnCount = Math.ceil(length/limit)

    const turnNo = iterationsElapsed % totalTurnCount == 0 ? totalTurnCount : iterationsElapsed % totalTurnCount;

    const startSlNo = ((turnNo-1) * limit) + 1
    const endSlNo = turnNo*limit;

    const viewUnitSlNo = index+1;


    return viewUnitSlNo >= startSlNo && viewUnitSlNo <= endSlNo
  }

  return (
    <React.Fragment>
      <div className="h-screen grid grid-rows-cust-2c p-cust-topAndBtm">
        <div className={`grid grid-cols-cust-2n auto-rows-max p-4`}>
          <div className={`relative h-fit bg-quaternary-light px-2 pb-2 rounded-lg mr-1`}>
          {/* <div className={`w-full flex justify-center text-2xl text-quaternary-dark py-6`}>LINE UP</div> */}
          <div className="w-fit flex mx-auto cont-title-red-marker-thin pt-2 pb-4 text-2xl text-quaternary-dark">
                {"LINE UP"}
          </div>

          {props.firstListDataArray.length>0 && 
          <React.Fragment>
          <div className="absolute right-5 top-5 text-quaternary-dark">
            {getTurnLabel(firstListLimit,props.firstListDataArray.length,iterationsElapsed)}
          </div>
          {props.firstListDataArray.map((dataMap, index) => {
            if(shouldRender(index,firstListLimit,props.firstListDataArray.length,iterationsElapsed)){
              return (
                <RecordBanner3 //For Displaying Current Orders on Store Screen
                  key={dataMap.prepaidCardRequestId}
                  slNo={index + 1}
                  bannerStyleMap={getBannerColorSchemeMap("order",dataMap)}
                  schemeMap={props.passMap[dataMap["orderJobName"]]}
                  // labelNames={props.passMap["Skate Sharpening"].labelNames}
                  // labelDbKeys={props.passMap["Skate Sharpening"].labelDbKeys}
                  fnName={props.passMap.fnName}
                  passDataMap={dataMap}
                  passMap={props.passMap}
                  passDataMap2={props.passMap.settings}
                  actionHandler={(data) => props.actionHandler(data)}
                />
              );
            }
           })
          }
          </React.Fragment>
          }
        {props.firstListDataArray.length==0 && 
          <div className="flex-center rounded-lg h-cust-30rem text-accent-green text-3xl font-medium">No Orders</div>
          }

        </div>

        <div className={`relative h-fit bg-accent-green-extra-transparent px-2 pb-2 rounded-lg ml-1`}>
        {/* <div className={`w-full flex justify-center text-2xl py-6 text-quaternary-dark`}>COMPLETED</div> */}
        <div className="w-fit flex mx-auto cont-title-red-marker-thin pt-2 pb-4 px-2 text-2xl text-quaternary-dark">
                {"COMPLETED"}
        </div>

        {props.secondListDataArray.length>0 && 
        <React.Fragment>
        <div className="absolute right-5 top-5 text-quaternary-dark">
          {getTurnLabel(secondListLimit,props.secondListDataArray.length,iterationsElapsed)}
        </div>
        {props.secondListDataArray.map((dataMap, index) => {
        if(shouldRender(index,secondListLimit,props.secondListDataArray.length,iterationsElapsed)){
          return (
            <RecordBanner4 //For Displaying Current Orders on Store Screen
              key={dataMap.prepaidCardRequestId}
              slNo={index + 1}
              bannerStyleMap={getBannerColorSchemeMap("order",dataMap)}
              schemeMap={props.passMap[dataMap["orderJobName"]]}
              // labelNames={props.passMap["Skate Sharpening"].labelNames}
              // labelDbKeys={props.passMap["Skate Sharpening"].labelDbKeys}
              fnName={props.passMap.fnName}
              passDataMap={dataMap}
              passMap={props.passMap}
              passDataMap2={props.passMap.settings}
              actionHandler={(data) => props.actionHandler(data)}
            />
          );
        }
        })}
        </React.Fragment>

        }
        {props.secondListDataArray.length==0 && 
        <div className="flex-center rounded-lg  h-cust-30rem text-accent-green text-3xl">No Orders</div>
        }

      </div>

        {/* {props.passListDataArray.length == 0 && (
          <div className="h-full">
         <NoAvailableDisplay textName={noTextDesc}/>
         </div>
        )} */}
        </div>
        <div className="absolute bottom-5 right-5 flex justify-center align-center">
        {bottomContainer}
        </div>
        <div className="absolute flex-center gap-4 bottom-2 left-4 h-14 w-60 rounded-xl p-2 text-secondary border-primary"><div className="whitespace-nowrap">WAIT TIME</div><div className="text-3xl font-bold whitespace-nowrap">{props.passMap.ovrWaitTimeCond ? `${props.passMap.ovrWaitTime.toFixed(2)} Mins` : `${props.passMap.estdWaitTime.toFixed(2)} Mins`}</div></div>
      </div>

    </React.Fragment>
  );
}

function getTurnLabel(limit,length,iterationsElapsed){

  const totalTurnCount = Math.ceil(length/limit)

  const turnNo = iterationsElapsed % totalTurnCount == 0 ? totalTurnCount : iterationsElapsed % totalTurnCount;

  return `${turnNo} OF ${totalTurnCount}`

}


function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const btnLabels = [...passMap.btnLabels];

  const fnName = passMap.fnName;
  const storePayConfCond = passMap.settings.storeBranchPayConfirmationCond;
  const pendingPaymentCond = passArrayElement.orderStatus == "pendingPayment";

  const collectionNotifSendCond = passArrayElement.orderCollectionNotifSendCond;


  switch(passArrayElement.orderStatus){
    case "accepted":
      btnLabels.push("Cancel Order");
    case "pendingCollection":
      btnLabels.push("Mark Collected");  
  }

    if ((!passArrayElement.orderPaymentStatus && storePayConfCond) || pendingPaymentCond) {
      btnLabels.push("Paid");
    }

    return btnLabels;

}



function getOrderStatusColor(data){
  if("orderStatus" in data){
    if(data.orderStatus=="pendingPayment" || data.orderStatus=="pendingCollection"){
      return "green"
    }
  }
  return "red"
}

function getBannerColorSchemeMap(type,data){


  const styleMap = {
    color:"red",
    backgroundColorClass:"banner-back-normal"
  }

  switch(type){
    case "order":{

      const jobNames = skateMaintenanceJobs;
      const jobBannerBackColorsClass = ["banner-back-gradient-red","banner-back-gradient-blue","banner-back-gradient-green"]

      if("orderStatus" in data){
        if(data.orderStatus=="pendingPayment" || data.orderStatus=="pendingCollection"){
          styleMap.color = "green";
        }else{
          const jobIndex = jobNames.indexOf(data.orderJobName)
          styleMap.color = "red";
          styleMap.backgroundColorClass = jobBannerBackColorsClass[jobIndex]
        }
      }
    }
  }

  return styleMap
}


function checkAndReturnBtnData(passMap, passArrayElement) {

  const rMap = {}

  const btnLabels = [...passMap.btnLabels];
  const btnAlertLevels = [...passMap.btnAlertLevels];
  const btnAvlblties = [...passMap.btnAvlblties];

  const fnName = passMap.fnName;
  const storePayConfCond = passMap.settings.storeBranchPayConfirmationCond;
  const pendingPaymentCond = passArrayElement.orderStatus == "pendingPayment";

  // const pendingCollCond = passArrayElement.orderStatus == "pendingCollection";
  // const collectionNotifSendCond = passArrayElement.orderCollectionNotifSendCond;

  
  switch(passArrayElement.orderStatus){
    case "accepted":
      btnLabels.push("Cancel Order");
      break;
    case "pendingCollection":
      btnLabels.push("Mark Collected");
      break;  
  }


    if ((!passArrayElement.orderPaymentStatus && storePayConfCond) || pendingPaymentCond) {
        if(passArrayElement.orderStatus!="accepted"){
          btnLabels.push("Paid");
          btnAlertLevels.push("High");
          btnAvlblties.push(true);
        }
    }

    rMap["btnLabels"] = btnLabels;
    rMap["btnAlertLevels"] = btnAlertLevels;
    rMap["btnAvlblties"] = btnAvlblties;


    return rMap;

}
