import React from "react";
import StripListContSinglePage from "./StripListContSinglePage";


export default function StripListContSinglePageWithResult(props) {

  console.log(props)


  return (
    <div className={`grid w-full gap-y-4`}>
        <div className="flex flex-center text-3xl text-primaryTransparent50">Upload Result</div>
        <StripListContSinglePage 
          schemeMap={props.schemeMap} 
          resultMode={true} 
          successfullBatchSlNos={props.successfullBatchSlNos}    
          pageStripValueDataMap={props.pageStripValueDataMap}
        />
    </div>
  );
}

