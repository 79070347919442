import React, { useEffect, useState } from "react";

export default function ButtonIconTitle2(props) {
  const [iconSize, setIconSize] = useState("");
  const [iconImage, setIconImage] = useState("");
  const [buttonColorClass, setButtonColorClass] = useState("");

  console.log(props.btnColorClass)
  console.log(props)

  useEffect(() => {
    //renderIconSize(props.section);


  }, []);

  function renderIconSize(section) {
    switch (section) {
      case "Dashboard Function":
        {
          setIconSize("h-20 w-20");
        }
        break;
      case "Selection":
        {
          setIconSize("h-20 w-20");
        }
        break;
      case "Top Bar":
        {
          setIconSize("h-8 w-8");
        }
      case "Action Cont":
        {
            setIconSize("h-8 w-8");
        }
      default:
          {
              setIconSize("h-8 w-8");
          }
        break;
    }
  }

  // function renderIcon() {
  //   switch (map.category) {
  //     case "Employee": {
  //       return (
  //         <EmployeeIcon
  //           className={`${iconSize} ${props.colorInvert ? "invert" : ""}`}
  //           onClick={() => props.clickHandler()}
  //         />
  //       );
  //     }
  //   }
  // }

  function buttonClicked(e) {
    props.clickHandler(e);
    setButtonColorClass("svg-red");
  }

  return (
    <div
      className={`flex items-center flex-col ${props.btnColorClass} cursor-pointer btn-hover`}
      tabIndex={1}
      onClick={(e) => props.clickHandler(e)}
    >
      <span className="text-xs">{props.btnTitle2}</span>
      <img src={props.btnImg} className={`${props.btnImgSize} svg-${props.btnColor} m-2`} />
      <span>{props.btnTitle}</span>
    </div>
  );
}


