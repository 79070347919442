import React, { useEffect, useState } from "react";
import { styleMapProject } from "./viewProps";

export default function AutoGenInputNew(props) {
  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value

  const [value,setValue] = useState("")

  useEffect(()=>{

    const eMap = {
      target:{
        value:props.inpValue,
        name:props.inpKey,
        type:"text"
      }
    }

    props.onChangeFn(eMap)

  },[props.inpValue])


  return (
    <div className={`grid grid-cols-cust-2g gap-1 bg-${styleMapProject.inputElem.background} p-3`}>
      <label>{props.inpLabel}</label>
      <div className="flex justify-center">
        {props.inpValue}
      </div>
    </div>
  );
}
