import React, { useState } from "react";
import { getToolTipLabelPostionClass } from "./viewFns";



export default function BtnNormalCompact(props) {

  console.log(props)
  const [showToolTip, setShowToolTip] = useState(false)

  const textColor = !props.textColor ? `text-darkBackText`: ``;
  const imgLabelCond = props.img && props.label;
  const padding = props.noPaddingCond ? "" : "px-6"


  const tootTipLabelPositionClass = getToolTipLabelPostionClass(props.toolTipLabelPostion)

  return (
    <div onMouseOver={()=>{if(props.toolTipLabel){setShowToolTip(true)}}} onMouseOut={()=>setShowToolTip(false)} className={`btn-hover ${imgLabelCond ? `grid grid-cols-cust-2j` : ``} ${padding} cursor-pointer ${textColor} rounded ${props.spClass} bg-${props.color}`} onClick={props.clickHandler}>
    <div className="relative">
      <div className={`flex items-center justify-center ${imgLabelCond ? `mr-2` : ``}`}><img className={props.svgColorClass} src={props.img} width={props.imgWidth} ></img></div>
      {props.label && <div className="none flex items-center justify-center font-bold text-center whitespace-nowrap">{props.label}</div>}
      {showToolTip && 
          <div className={` absolute bg-golden rounded text-darkBackText text-sm text-nowrap font-semiBold ${tootTipLabelPositionClass}` }>{props.toolTipLabel}</div>
      }
    </div>
  </div>

  //   <div className={`btn-hover ${imgLabelCond ? `grid grid-cols-cust-2j` : ``} cursor-pointer ${textColor} px-6 rounded ${props.spClass} ${styleProxyMap.color[`${props.color}`]}`} onClick={props.clickHandler}>
  //     <div className={`flex items-center justify-center ${imgLabelCond ? `mr-2` : ``}`}><img className={props.svgColorClass} src={props.img} width={props.imgWidth}></img></div>
  //     {props.label && <div className="none flex items-center justify-center font-bold text-center whitespace-nowrap">{props.label}</div>}
  //  </div>
  );
}