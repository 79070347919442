import React from "react";
import BtnNormalSelAction from "./BtnNormalSelAction";


export default function ActionBtnCont4(props) {
  console.log(props);

  return (
    <div className="flex items-center gap-8">

         <BtnNormalSelAction spClass="border border-primary text-primary text-sm" color="" label="Enter" clickHandler={() => props.fnHandler("enter")}/>
         <BtnNormalSelAction spClass="border-2 border-primary text-primary text-sm" color="" label="Edit" clickHandler={() => props.fnHandler("edit")}/>
         <BtnNormalSelAction spClass="border-2 border-primary text-primary text-sm" color="" label="Delete" clickHandler={() => props.fnHandler("delete")}/>
         {!props.selAccMap.isDisabled &&    
         <BtnNormalSelAction spClass="border-2 border-primary text-primary text-sm" color="" label="Disable" clickHandler={() => props.fnHandler("disable")}/>
          }
         {props.selAccMap.isDisabled &&    
          <BtnNormalSelAction spClass="border-2 border-primary text-primary text-sm" color="" label="Enable" clickHandler={() => props.fnHandler("enable")}/>
          }

          {/* <button
            className="btn-action-2"
            onClick={() => props.fnHandler("enter")}
          >
            Enter
          </button>
          {/* {props.selAccMap.category== "Store Branch" && (
            <button
              className="btn-action-2"
              onClick={() => props.fnHandler("editTariff")}
            >
              Edit Tariff
            </button>
          )} */}

          {/* {
          <button
            className="btn-action-2"
            onClick={() => props.fnHandler("edit")}
          >
            Edit
          </button>
          }

        {
          <button
            className="btn-action-2 red-stroke"
            onClick={() => props.fnHandler("delete")}
          >
            Delete
          </button>
          }
 
      {!props.selAccMap.isDisabled &&           
        <button
            className="btn-action-2 red-stroke"
            onClick={() => props.fnHandler("disable")}
          >
            Disable
          </button>}

          {props.selAccMap.isDisabled && 
          <button
            className="btn-action-2 black"
            onClick={() => props.fnHandler("enable")}
          >
            Enable
          </button>
          }  */}

{/* {!props.selAccMap.category!="Store" &&   
          <button
            className="btn-action-2"
            onClick={()=>props.fnHandler("back")}
          >
            Back
          </button>} */}
{/*          


{/*  
        <div>
          <label for="disabled">Disabled</label>
          <input
            name="disabled"
            type="checkbox"
            checked={props.isDisabled}
            //onChange={(e) => disableClicked(e)}
          />
        </div> */}
    </div>
  );
}

