import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  db,
  generateIncrementNumArray,
  generateInvoice,
  limit,
  orderBy,
  styleProxyMap,
  toSentenceCase
} from "../Utils";
import BottomContainer from "./BottomContainer";
import FilterInput from "./FilterInput";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordBtnStrip3 from "./RecordBtnStrip3";

const pageLimit = 10;

//@start
export default function StripListFilterBtnCont2(props) {

  console.log(props)

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbKeys);
  console.log(props.passMap);
  console.log(props);


  const [motherListDataMap, setMotherListDataMap] = useState({
    valueSeqs: [],
    slNos: [],
  });
  const [motherFilListDataMap, setMotherFilListDataMap] = useState({
    valueSeqs: [],
  });
  const [pageStripValueMapColl, setPageStripValueMapColl] = useState([]);
  const [slNoArray, setSlNoArray] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});

  const [uidDocIdTabMap, setUidDocIdTabMap] = useState({});

  const [recordCount, setRecordCount] = useState(0);
  const [filtRecordCount, setFiltRecordCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");
  const [isLoading,setIsLoading] = useState(true)
  const [showSpinner,setShowSpinner] = useState(false)

  useEffect(() => {
    console.log(props)
    initializePageWithData(props);
  }, [props]);

  async function initializePageWithData(props) {

    console.log(props.listTopic)

    const collQuery = query(
      collection(db, getDbCollPath(props)),
      where("docCreationDate", "!=", false),
      orderBy("docCreationDate", "desc"),limit(2)
    ); //Fetching only the the latest 2 documents based on docCreation Date as 
    //we need data to display on a paginated view and since the collection will be perenially increasing we just show the latest docs and based on the users query for old docs fetch it seperately and show

    const dbDocs = await getDocs(collQuery);
    console.log(`size=${dbDocs.size}`)

    if(dbDocs.size!=0){
      console.log("1")
      const data = await extractAndSetValuesDataMap(dbDocs,motherListDataMap,1)
     setMotherListDataMap(data);
    }else{
      setPageStripValueMapColl([])
      props.stopSpinner()
      setIsLoading(false);
    }
  }

  async function extractAndSetValuesDataMap(dbDocs,sMotherListDataMap,pageNo) {

    console.log("2")
    console.log(`size=${dbDocs.size}`)
    
  
    const latestSlNo = dbDocs.docs[0].data().recordSlNos[dbDocs.docs[0].data().recordSlNos.length-1]

    setRecordCount(latestSlNo);

    //const pageNoLimits = getPageNoLimits(pageNo,latestSlNo)

    const valueSeqDbKeys= props.passMap.valueSeqDbKeys;

    //creating the empty data map
    const eMap = {};
    const tArray1=[];

    eMap["slNos"]=[]
    props.passMap.valueSeqDbKeys.forEach((key)=>{
      eMap[`${key}s`] = [];
    })


    dbDocs.forEach((doc) => {
      const stringSeqDatas = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;

      stringSeqDatas.forEach((stringSeqData,i)=>{

          const stringSeqDataArray = stringSeqData.split("^");
          const slNo = slNos[i]

          eMap["slNos"].push(slNo) // Pushing the Sl No of the record

          Object.keys(eMap).forEach((key)=>{ //Pushing rest of the data of the record
            const keySingular = key.substring(0,key.length-1)
            const keyIndex = valueSeqDbKeys.indexOf(keySingular)
            if(keyIndex!=-1){
              eMap[key].push(stringSeqDataArray[keyIndex])
            }
          })

          console.log(eMap);

      })

    });

    //Adding the db extract map to the Mother Map

    if(Object.keys(sMotherListDataMap).length==0){
      Object.keys(eMap).forEach((key)=>{
        eMap[key]=[...sMotherListDataMap[key],...eMap[key]]
      })
  }

    console.log(eMap)
    //console.log(stripValueMapColl);
   // setStripValuesMap({...eMap})
     displayPage(pageNo, {...eMap});
    return eMap;
  }//end of extractAndSetValuesDataMap


  function extractAndSetFiltValuesDataMap(dbDocs,filValue,filDbKeyArrayName) {
    

    const valueSeqDbKeys= props.passMap.valueSeqDbKeys;

    //creating the empty data map
    const eMap = {};
    const tArray1=[];

    eMap["slNos"]=[]
    valueSeqDbKeys.forEach((key)=>{
      eMap[`${key}s`] = [];
    })

    //index of the filDbKey in the value seq
    const mIndex = valueSeqDbKeys.indexOf(filDbKeyArrayName.substring(0,filDbKeyArrayName.length-1))

    let slNoCount=0

    dbDocs.forEach((doc) => {
      const stringSeqDatas = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;



      stringSeqDatas.forEach((stringSeqData,i)=>{

          const stringSeqDataArray = stringSeqData.split("^");
          const slNo = slNoCount

//Checking if the record matches the filter condition
          if(stringSeqDataArray[mIndex]==filValue){
             slNoCount++;
            eMap["slNos"].push(slNoCount) // Pushing the Sl No of the record

            Object.keys(eMap).forEach((key)=>{ //Pushing rest of the data of the record
              const keySingular = key.substring(0,key.length-1)
              const keyIndex = valueSeqDbKeys.indexOf(keySingular)
              if(keyIndex!=-1){
                eMap[key].push(stringSeqDataArray[keyIndex])
              }
            })
          }
          console.log(eMap);

      })

    });


    const sRecordCount = eMap["slNos"].length
    setRecordCount(eMap["slNos"].length);

    console.log(eMap)
    //console.log(stripValueMapColl);
   // setStripValuesMap({...eMap})
    displayFiltPage(pageNo, {...eMap});
    return eMap;
  }



  function displayPage(sPageNo, sMotherListDataMap) {
    
    setPageNo(sPageNo);
    setViewMode("normal")

    const motherRecordSlNos = sMotherListDataMap.slNos
    const latestSl = motherRecordSlNos.length;

    setRecordCount(latestSl);


    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * sPageNo > latestSl ? latestSl : pageLimit * sPageNo;

      
    console.log(startSlOfPage, endSlOfPage);
    console.log(motherRecordSlNos)

//Step: Checking for missing Sl No Record in the Mother Data
    const slQueryArray = [];

    [startSlOfPage, endSlOfPage].forEach((item) => {
      if (motherRecordSlNos.indexOf(item) == -1) {
        slQueryArray.push(item);
      }
    });

    if (slQueryArray.length == 0) {
      assignStripValues(startSlOfPage, endSlOfPage, sMotherListDataMap);
    } else {
      fetchDbAndSetStripValues(
        startSlOfPage,
        endSlOfPage,
        slQueryArray,
        sMotherListDataMap,
        sPageNo
      );
    }

  
  }

  async function fetchDbAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    sMotherListDataMap,
    pageNo
  ) {

    const collQuery = query(
      collection(
        db,
        getDbCollPath(props)
      ),
      where("recordSlNos", "array-contains-any", slQueryArray)
    );
    

    const dbDocs = await getDocs(collQuery);
    setMotherListDataMap(
      extractAndSetValuesDataMap(dbDocs,sMotherListDataMap,pageNo)
    );
  }

  function assignStripValues(startSlNo, endSlNo, sMotherListDataMap) {
    const rArray = [];

    const mIndexStart = startSlNo;
    const mIndexEnd = endSlNo;

    console.log(mIndexStart,mIndexEnd)

    const slNosArray = sMotherListDataMap.slNos;

    //empty data map


    const map2s = {};   // if it is maiden time, then sMotherListDataMap will be {}

    // //Creating an Intialized Array Map
    //   map2s["slNos"]=[] 
    //   props.passMap.labelDbKeys.forEach((dbKey)=>{
    //     map2s[`${dbKey}s`] = [];
    //   })

    console.log(sMotherListDataMap)
  
    const tArr=[];
        for (let y = mIndexStart; y <= mIndexEnd; y++) {
          const tMap = {};
          const mIndex = slNosArray.indexOf(y);
          tMap["slNo"]=y;
          props.passMap.valueSeqDbKeys.forEach((dbKey)=>{  
            tMap[dbKey]=sMotherListDataMap[`${dbKey}s`][mIndex]   
            })
          tArr.push(tMap)
        }

    console.log(tArr);

    setPageStripValueMapColl([...tArr]);
    setIsLoading(false)
  }



  function renderPagination(
    sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {

    console.log(sRecordCount);
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    const rArray = [];

    console.log(sListValuesCollMap);

    // for (let x = 1; x <=pageCount; x++) {
    //   const element = (
    //     <button
    //       onClick={() => displayPage( x, sListValuesCollMap)}
    //       className={`p-cust-3 ${
    //         x == sPageNo ? `border-page-no-indicator` : ``
    //       }`}
    //     >{`${x}`}</button>
    //   );
    //   rArray.push(element);
    // }

    // return rArray;
    const element = (
      <select value={sPageNo} className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayPage( e.target.value, sListValuesCollMap)}>
        {generateIncrementNumArray(1,pageCount).map(slNo =>
          <option value={slNo}>{slNo}</option>
        )
        }
      </select>
  );

    return element;
  }

  function renderFilterPagination(sPageLimit,
    sMotherFilListDataMap,
    sRecordCount,
    sPageNo) {
    const pageCount = Math.ceil(recordCount / sPageLimit);

    // const rArray = [];

    // for (let x = 0; x < pageCount; x++) {
    //   const element = (
    //     <button
    //       onClick={() => displayFiltPage(x + 1, sMotherFilListDataMap)}
    //       className={`p-cust-3 ${
    //         x + 1 == sPageNo ? `border-page-no-indicator` : ``
    //       }`}
    //     >{`${x + 1}`}</button>
    //   );
    //   rArray.push(element);
    // }

    // return rArray;

    const element = (
      <select className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayFiltPage(e.target.value, sMotherFilListDataMap)}>
        {generateIncrementNumArray(1,pageCount).map(pageNo =>
          <option value={pageNo}>{pageNo}</option>
        )
        }
      </select>
  );

    return element;
  }

  async function displayPagesBasedOnFilter(sFilterName, sFilterValueMap) {
    setViewMode("filter");

    console.log("hellothere");

    const filterSchemeMap = { ...props.passMap.filterSchemeMap };

    const filterNameIndex = filterSchemeMap.filterNames.indexOf(sFilterName);
    const filterInputKeyNames =
      filterSchemeMap.filterInputKeys[filterNameIndex];

    const filterInputValue =
      sFilterName == "Store Branch Name"
        ? `${sFilterValueMap.storeBrandName}-${sFilterValueMap.storeBranchName}`
        : sFilterValueMap[filterSchemeMap.filterInputKeys[filterNameIndex][0]];

    const curFilterValMap = {
      filterName: sFilterName,
      filterDbKey: filterSchemeMap.filterDbKeys[filterNameIndex],
      filterInputKeys: filterSchemeMap.filterInputKeys[filterNameIndex],
      filterSeqIndex: filterSchemeMap.filterSeqIndices[filterNameIndex],
      filterInputValue: filterInputValue,
    };

    console.log(props.listTopic);

    const collQuery = query(
      collection(db, `admin/accounts/${props.listTopic}`),
      where(
        curFilterValMap.filterDbKey,
        "array-contains",
        curFilterValMap.filterInputValue
      )
    );

    const filtDbDocs = await getDocs(collQuery);

    console.log(filtDbDocs.size);
    setMotherFilListDataMap(
      extractAndSetFilteredValuesMapColl(filtDbDocs, 1, curFilterValMap)
    );
  }

  function extractAndSetFilteredValuesMapColl(
    filtDbDocs,
    pageNo,
    sCurFilterValMap
  ) {
    const tArray = [];
    let nMap = {};

    nMap["count"] = 0;



    const dbNotations = [
      "invoiceId",
      "invoiceIssueDate",
      "invoicePartyId",
      "invoicePartyName",
      "invoicePartyUid",
      "invoiceDesc",
      "invoiceAmount",
      "invoicePaymentDueDate",
    ];

    filtDbDocs.docs.forEach((doc) => {
      const tSubArray = [];
      const stringSeqDatas = doc.data().stringSeqDatas;

      const uids = [];
      const docIds = [];
      const map = {}; //for docid and uid relation

      stringSeqDatas.forEach((stringSeq) => {
        const stringArray = stringSeq.split("^");

        if (
          stringArray[sCurFilterValMap.filterSeqIndex] ==
          sCurFilterValMap.filterInputValue
        ) {
          const tMap = {}; //each sequence data will be in the form of a map
          nMap["count"] += 1;

          //Creating a UID and DOC Id Array to facilitate invoice record transfer/deletion later on.
          const stringValues = stringSeq.split("^");
          uids.push(stringValues[dbNotations.indexOf("invoicePartyUid")]);
          docIds.push(doc.ref.id);

          //Converting the String Seq into a map
          dbNotations.forEach((dbNotation, i) => {
            tMap[dbNotation] = "";
            tMap[dbNotation] = stringValues[i];
          });

          tMap["stringSeqData"] = stringSeq;
          tMap["slNo"] = nMap["count"];
          tMap["docId"] = doc.ref.id;

          map["docIds"] = [...docIds];
          map["uids"] = [...uids];

          console.log(tMap);
          console.log(map);

          setUidDocIdTabMap({ ...map });

          tArray.push(tMap);
        }
      });
    });
    console.log(tArray);

    if (tArray.length != 0) {
      displayFiltPage(pageNo, tArray);
    }

    return tArray;


  }



  function displayFiltPage(sPageNo, sMotherFilListDataMap) {

    setPageNo(sPageNo);

    const rArray = [];


    //const size = valuesDataMap.stripValuesSeqs.length;
    const latestSl = sMotherFilListDataMap.slNos.length;

    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * sPageNo > latestSl ? latestSl : pageLimit * sPageNo;

      
    assignStripValues(startSlOfPage, endSlOfPage, sMotherFilListDataMap);


  }



  async function filterClickFn(e,filterName,motherListDataMap){
    console.log(e.target.parentElement)
    console.log(filterName)
    setViewMode("filter")

    const inputNodes = e.target.parentElement.getElementsByTagName("input");

    let filtValue="";

    let filDbKeyArrayName = ""

    console.log("before")

    switch(filterName){
      case "Date":{

      }
      break;
      case "Party ID":{

        filDbKeyArrayName=props.listTopic=="balances"?"partyIds":props.listTopic=="transactionAdmin"?"transactionPartyIds":"invoicePartyIds"
        filtValue = inputNodes[0].value
      }
      break;
      case "Store Branch Name":{
        filDbKeyArrayName=props.listTopic=="balances"?"partyNames":props.listTopic=="transactionAdmin"?"transactionPartyNames":"invoicePartyNames"
        filtValue = toSentenceCase(`${inputNodes[0].value} - ${inputNodes[1].value}`);
      }
      break;
      case "Employee Name":{

      }
      break;
      case "Job Name":{

      }
      break;
    }

    generateFilterDataMap(filtValue,filDbKeyArrayName,motherListDataMap);


}

async function generateFilterDataMap(filValue,filDbKeyArrayName,sMotherListDataMap){

  const tArray1 = [];
  const map={}

  const dbCollQuery = query(collection(db,getDbCollPath(props))
  ,where(filDbKeyArrayName,"array-contains",filValue)
  ,orderBy("docCreationDate","desc"));

  const dbDocs = await getDocs(dbCollQuery);

setMotherFilListDataMap(extractAndSetFiltValuesDataMap(dbDocs,filValue,filDbKeyArrayName))



}

async function btnClicked(data,tabMap,props) {
setShowSpinner(true);
  const map = data.stripValueMap;
  switch (data.btnType) {
    case "View":{

      setShowSpinner(true)

      const invoiceId =  map.invoiceId;

      let invMap = {}

      console.log(map)


      const docDbCall = query(collection(db,`admin/accounts/invoices`),where("invoiceId","==",invoiceId));
      const dbDocs = await getDocs(docDbCall);
  
      invMap = dbDocs.docs[0].data();

      generateInvoice(invMap);

  
  
        console.log("hello")
        setShowSpinner(false);
        




    }
    break;
    case "View Details":{

      const uData={};
      uData["fnName"] = "Check Past Orders";
      uData["dbData"] = data.stripValueMap;

      props.actionHandler(uData);

      console.log(data);
      console.log(tabMap)
    }
    break;
    break;
    default: {
      console.log("No Options");
    }
  }
}



  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  function getDbCollPath(props){
    switch(props.listTopic){
      case "paidInvoiceAdmin":{
        return `admin/accounts/paidInvoices`
      }
      case "pastOrder":{
        return `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
      }
    }
  }

  if(!isLoading){
      props.stopSpinner()
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  const stripColWidth=props.listTopic=="paidInvoiceAdmin"?`grid-columns-8a`:"pastOrder"?`grid-columns-9a`:`grid-columns-8c`

  // function resetFilterClickFn(sMotherListDataMap){
  //   displayPage(1,sMotherListDataMap)
  // }

  async function resetFilterClickFn(sMotherListDataMap){
    setViewMode("normal");
    setFilterName(props.passMap.filterSchemeMap.filterNames[0])
    displayPage(1,sMotherListDataMap)
  }

  //@render
  return (
    <React.Fragment>
    {!isLoading && 
  <div className={`grid ${styleProxyMap.gridRows["2c"]} p-cust-topHead`}>
   {pageStripValueMapColl.length != 0 && 
   <div className="grid " >
        <div className="flex flex-col justify-center items-center py-12">
          <div className="flex flex-row">
          <select className="bg-primary rounded px-8 text-darkBackText" onChange={(e) => setFilterName(e.target.value)} value={filterName}>
              {props.passMap.filterSchemeMap.filterNames.map(
                (filterOptionName, i) => {
                  return (
                    <option key={i} value={filterOptionName}>
                      {filterOptionName}
                    </option>
                  );
                }
              )}
            </select>
            <div>
              <FilterInput
                filterName={filterName}
                filterSchemeMap={filterSchemeMap}
                filterValueMap={filterValueMap}
                inputChangeFn={(data) => inputChangeFn(data)}
                resetFilterClickFn={(data)=>resetFilterClickFn(motherListDataMap,data)}
                filterClickFn={(data,filterName)=>filterClickFn(data,filterName)}
              />
            </div>
            {/* <button
              className="btn-filter mx-1"
              onClick={() =>
                displayPagesBasedOnFilter(filterName, filterValueMap)
              }
            >
              Go
            </button> */}
          </div>
          <div className="flex mt-8">
            {viewMode=="normal" && renderPagination(pageLimit, motherListDataMap, recordCount, pageNo)}
            {viewMode=="filter" && renderFilterPagination(pageLimit, motherFilListDataMap, filtRecordCount, pageNo)}
          </div>
        </div>
        
  
        <div className="px-4">
  
        <div className="grid grid-cols-cust-2e mx-auto mt-1 text-sm">
        <div className={`grid mx-auto w-full ${stripColWidth} bg-secondary rounded-sm border border-borderStd `}
          //className={`grid mx-auto w-full ${columnWidth} bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit flex justify-center items-center text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
          <div className="flex items-center justify-center text-darkBackText bg-secondary text-center rounded-sm border border-borderStd mx-2">
            Action
          </div>
      </div>
         
            {pageStripValueMapColl.map((stripValueMap) => {
              return (
                <RecordBtnStrip3
                  stripValueMap={stripValueMap}
                  labelDbKeys={props.passMap.labelDbKeys}
                  labelNames={props.passMap.labelNames}
                  btnLabels={props.passMap.btnLabels}
                  listTopic={props.listTopic}
                  btnClickHandler={(data) => btnClicked(data,uidDocIdTabMap,props)}
              />)
              })
            }
          </div>
        </div>//End of Body Cont
  }
          {pageStripValueMapColl.length == 0 && 
                        <NoAvailableDisplay textName="No Records Found"/>
          }
  
  <div className="absolute w-full bottom-10 flex justify-center align-center">
        {bottomContainer}
        </div>  
      </div>
      }
      </React.Fragment>
    );
}



