import { useEffect, useState } from "react";
import { getRbaseValueByPath } from "./projectFns";

export default function TextDisplayRbaseAsync(props){

    const [text,setText] = useState("")

    useEffect(()=>{
        (async () => {
          try {

            const value = await getRbaseValueByPath(props.rbasePath)

            setText(value)
            
          } catch (err) {

          }
        })();
      
      },[]);



    return(<div>
        {text} 
    </div>)
}