import { useContext, useEffect, useState } from "react";
import React, {
  viewDashboardBlankSchemeMap
} from "../Utils";
import NotifBlock from "./NotifBlock";

import DashFunctionBox2Project from "./DashFunctionBox2Project";
import { UserDataContext } from "./Level1";
import { getStatSchemeMap } from "./projectFns";
import StatCont from "./StatCont";

function getNotifDataMap(userData){

  console.log(userData)

  //const inputInfos = userData.inputInfos

  const rArray=[]
  const tArray=[]

  if(userData.role != "Store Branch"){

    return rArray

  }else{

  //Array of mandatory input info
  const mandInputInfos = ["serviceAdded","contactDetailsAdded","locationAdded","employeeAdded","toolAdded"];
  
  const inputInfos=userData.inputInfos==undefined?[]:userData.inputInfos

  //creating an array with the infos that are not satisfied by the store
  mandInputInfos.forEach((mandInputInfo)=>{
    if(!inputInfos.includes(mandInputInfo)){
        tArray.push(mandInputInfo);
    }
  });

  //Iterating through each non-complied mand Info and adding the notification dataMap for that info
  tArray.forEach((inputInfo)=>{

    switch(inputInfo){
      case "serviceAdded":{
        const tMap={}
        tMap["alertLevel"] = "high"
        tMap["date"]="NA"
        tMap["desc"]="No Service is Added, please add inorder to start operations"
        rArray.push(tMap)
      }
      break;
      case "contactDetailsAdded":{
        const tMap={}
        tMap["alertLevel"] = "high"
        tMap["date"]="NA"
        tMap["desc"]="Please add your contact details inorder to start operation"
        rArray.push(tMap)
      }
      break;
      case "locationAdded":{
        const tMap={}
        tMap["alertLevel"] = "high"
        tMap["date"]="NA"
        tMap["desc"]="Please add your location, inorder to be seen in the map search results"
        rArray.push(tMap)
      }
      break;
      case "employeeAdded":{
        const tMap={}
        tMap["alertLevel"] = "high"
        tMap["date"]="NA"
        tMap["desc"]="Please add an employee inorder to start taking orders"
        rArray.push(tMap)
      }
      break;
      // case "toolAdded":{
      //   const tMap={}
      //   tMap["alertLevel"] = "high"
      //   tMap["date"]="NA"
      //   tMap["desc"]="Please add a tool inorder to start taking orders"
      //   rArray.push(tMap)
      // }
      // break;
    }

  })

  console.log(rArray)

  return rArray
}
  
}





//@Function Component Starts

export default function Dashboard(props) {

  const userData = useContext(UserDataContext);
  const showStatCond = Object.keys(props.roleDataMap).length!==0 || userData.role=="Super Admin"

  console.log(userData)
  //const [schemeMap, setSchemeMap] = useState(viewDashboardStoreBranchSchemeMap);

  const primaryColorClass = "bg-primaryPale";
  const secondaryTransColorClass = "bg-secondaryTransparent20"
  const btnTabActiveColor = "rgba(255, 216, 0, 1)"

  const [widgetTopic,setWidgetTopic]=useState("Stats");
  const statSchemeMap = getStatSchemeMap(props.role)
  

  
  
  

  const schemeMap =
    Object.keys(props.dashSchemeMap).length == 0
      ? viewDashboardBlankSchemeMap
      : props.dashSchemeMap;

  console.log(schemeMap);
  console.log(props);


  useEffect(()=>{
    const notifArray = getNotifDataMap(userData);
    console.log(notifArray)
    if(notifArray.length>0){
      setWidgetTopic("Notifications")
    }else{
      setWidgetTopic("Stats")
    }
  },[userData])



  function onTabBtnClick(e,topic){
    console.log(e.target)
    setWidgetTopic(topic)

    if(Array.from(e.target.classList).includes("btn-tab")){
      Array.from(e.target.parentElement.children).forEach((child)=>{
        child.style.background = ""
        child.style.background = ""
      })
    }else{
      e.target.parentElement.style.background=""
      // Array.from(e.target.parentElement.children).forEach((child)=>{
      //   child.style.background = ""
      //   child.style.background = ""
      // })
    }


    console.log(e.target.classList);

    if(Array.from(e.target.classList).includes("btn-tab")){

      e.target.style.background=btnTabActiveColor
    }else{

      e.target.parentElement.style.background=btnTabActiveColor
    }

//    e.target.style.background="rgb(180,0,0)"
  }

  return (
    <div className={`h-full grid grid-cols-cust-2b sm:flex sm:flex-col-reverse sm:justify-end sm:items-start`}>
      <div className="flex flex-col bg-slate-300 items-start px-2 sm:w-full"> 
        {schemeMap.viewSectionNames.map((sectionName, index) => {
                      if(!(sectionName == "Test" && props.productionMode)){
          return (

            <DashFunctionBox2Project
              key={sectionName}
              sectionName={sectionName}
              fnBtnImgs={schemeMap["fnBtnImgs"][index]}
              fnBtnLabels={schemeMap["fnBtnNames"][index]}
              fnViews={schemeMap["fnViews"][index]}
              fnNotifications={schemeMap["fnNotifications"][index]}
              viewChangeHandler={(data) => props.viewChangeHandler(data)}
            />
          );
                      }
        })}
      </div>
      <div className="fixed right-0 w-2/5 h-cust-90vh p-2 sm:static sm:w-full sm:h-cust-20rem">
        <div className="h-full grid grid-rows-cust-2i rounded-lg bg-primaryPale py-4">
          {/* <div className="h-16 grid grid-cols-2">
          <div className={`flex justify-center items-center btn-tab hover-secondary border-std text-darkBackText gap-4 ${widgetTopic=="Stats" ? primaryColorClass :``} `} onClick={(e) => onTabBtnClick(e,"Stats")}>
            <ButtonIcon
              section="Dashboard Widget"
              Image={StatIcon}
              colorInvert={true}
              // clickHandler={(e) => onTabBtnClick(e,"Stats")}
            />
            STATS
            </div>
            <div className={`flex justify-center items-center btn-tab hover-secondary border-std text-darkBackText gap-4 ${widgetTopic=="Notifications" ? primaryColorClass :``} `} onClick={(e) => onTabBtnClick(e,"Notifications")}>
                <ButtonIcon
                section="Dashboard Widget"
                Image={NotificationIcon}
                colorInvert={true}
                //clickHandler={(e) => onTabBtnClick(e,"Notifications")}
              />
              Notification
            </div>
          </div> */}
          <div className="">
            {widgetTopic=="Stats" && showStatCond &&
              <StatCont 
                dataMapObj={{dbMap:props.dataMap,roleSettingMap:props.roleDataMap.settings,userDataMap:userData}}
                schemeMap={statSchemeMap}
              />
            }

            {widgetTopic=="Notifications" && showStatCond &&
              <NotifBlock 
              notifDataMap={getNotifDataMap(userData)}
              statSchemeMap={statSchemeMap}/>
            }
            </div>
          </div>
        </div>
      </div>

  );
}

