import React from "react";

export default function NoAvailableDisplay(props) {

  
  return (
      <div className={`flex items-center justify-center bg-quaternary-medium rounded-lg border-std`}>
        {props.textName}
      </div>
  );
}
