import React, { Component, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import skateLogo from '../images/skate-logo.svg';


export default function ResetPasswordScreen(props){

    const navigate = useNavigate();

    const [email,setEmail] = useState(null);


    function handleChange(event) {
        if (event.target.name == "email") {
          setEmail(event.target.value);
        } 
      }
    
    
    
    function resetPassword(userName,password){
    
       
    
        //navigate("/dashboard");
       
    }

 return(

        <div className="h-screen grid grid-rows-cust-3g bg-secondary-light-transparent justify-center">
        <div className="h-full flex items-center justify-center">
          <span className="text-3xl">RESET PASSWORD</span>
        </div>
        <div className="flex flex-col">
          <label className="text-sm">ACCOUNT EMAIL</label>
                <input
                  className="m-0 rounded-sm"
                  type="email"
                  name="email"
                  onChange={(event) => handleChange(event)}
                ></input> 

                          <button
            onClick={() => resetPassword(email)}
            className="btn-std hover-black mx-auto mt-16 bg-primary-600 text-primary-100"
          >
            Reset Password
          </button>  
        </div>

        <div className="flex justify-center items-center">
            {props.userMode=="store" &&
            <div className="flex">
              <button
                onClick={() => navigate("/")}
                className="btn-std hover-black"
              >
                Back to Login
              </button>
            </div>
            }
         </div>
      </div>
 
 
 );
 





}


