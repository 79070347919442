import React, { Component, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TabContainer from "./TabContainer";
import {
  functions,
  httpsCallable,
  electron,
  pageSizes,
  getSerialNo,
  db,
  query,
  collection,
  where,
  doc,
  getDoc,
  updateDoc,
  runTransaction,
  Timestamp,
  serverTimestamp,
  database,
  rbaseRef,
  onValue,
  set,
  child,
  get,
  push,
  update,
  getArrayAvg,
  getArraySum,
  getTimeLabel,
} from "../Utils";
import BottomContainer from "./BottomContainer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart, Line } from "react-chartjs-2";
import { useEffect } from "react";
import { getDocs } from "firebase/firestore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = "DMSans";

export default function ChartCont2(props) {
  
  console.log(props.passMap.topic);

  const labels = ["January", "February", "March"];

  const data = {
    labels,
    datasets: [
      // {
      //   label: "Dataset 1",
      //   data: [0, 0, 0],
      //   borderColor: "rgb(255, 99, 132)",
      //   backgroundColor: "rgba(255, 99, 132, 0.5)",
      // }
    ],
  };
  const topicIndex = props.passMap.chartTopics.indexOf(props.passMap.topic);

  const [datasetKey, setDatasetKey] = useState("");
  const [chartTopic, setChartTopic] = useState(props.passMap.topic);
  const [compDataMap, setCompDataMap] = useState({});
  const [filValues, setFilValues] = useState([
    ...props.passMap.filValues[topicIndex],
  ]);
  const [chartReady,setChartReady]=useState(false)

  const [filCategories, setFilCategories] = useState([
    ...props.passMap.filCategories[topicIndex],
  ]);
  const [fil1Value, setFil1Value] = useState(
    props.passMap.filCategories[topicIndex][0]
  );
  const [fil2Value, setFil2Value] = useState(
    props.passMap.filValues[topicIndex][0][0]
  );
  const [fil3Value, setFil3Value] = useState(
    props.passMap.filValues[topicIndex][1][0]
  );
  const [fil4Value, setFil4Value] = useState(
    props.passMap.filValues[topicIndex][2][0]
  );
  const [filAValue, setFilAValue] = useState(
    props.passMap.filValues[topicIndex][3][0]
  );
  const [xAxisLabel,setXAxisLabel]=useState(props.passMap.axisLabels[topicIndex][0][0])
  const [yAxisLabel,setYAxisLabel]=useState(props.passMap.axisLabels[topicIndex][1][0])


  const options = {
    responsive: true,
    scales: {
      x: {
         title:{
          display:true,
          align:"centre",
          text:props.passMap.axisLabels[topicIndex][0][0],
          color:"#000000",
          font:{
            size:22,
            weight:"500",
        
          }
         },
      },
      y: {
        title:{
          display:true,
          align:"centre",
          text:props.passMap.axisLabels[topicIndex][1][0],
          color:"#000000",
          font:{
            size:22,
            weight:"500"
          }
        }
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };


  options.scales.x.title.text=xAxisLabel
  options.scales.y.title.text=yAxisLabel

  const [chartData, setChartData] = useState({ ...data });
  const [chartOptions, setChartOptions] = useState({ ...options });

  const [tabFocusIndex,setTabFocusIndex]=useState(0);

  useEffect(() => {
    initializePageWithData(
      compDataMap,
      fil2Value,
      fil3Value,
      fil4Value,
      filAValue
    );
  }, []);

  console.log(compDataMap);

  async function initializePageWithData(
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue
  ) {
    // const fil2Value = props.passMap.chartFilValues[topicIndex][0][0];
    // const fil3Value = props.passMap.chartFilValues[topicIndex][1][0];
    // const fil4Value = props.passMap.chartFilValues[topicIndex][2][0];

    if (!Object.keys(compDataMap).includes(fil2Value)) {
      const collQuery = query(
        collection(db, `storeBranches/${props.passMap.uid}/pastOrders`),
        where("orderYear", "==", fil2Value)
      );

      const docColl = await getDocs(collQuery);

      console.log(fil2Value);

      setCompDataMap(
        await extractAndSetDataMap(
          docColl,
          compDataMap,
          fil2Value,
          fil3Value,
          fil4Value,
          filAValue
        )
      );
    }
  }

  async function extractAndSetDataMap(
    docColl,
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue
  ) {
    console.log("comin");
    console.log(docColl.size);
    console.log(fil2Value, fil3Value, fil4Value);
    const tMap = { ...compDataMap };

    // if (!Object.keys(tMap).includes(fil2Value)) {
    tMap[fil2Value] = {};
    tMap["All"] = tMap["All"] || {};
    tMap["All"]["All"] = tMap["All"]["All"] || {};

    let fil3Values = [];
    const fil4Values = filValues[2];
    const filAValues = filValues[3];

    let aFil3Value = "";

    if (fil3Value == "DB Fetch") {
      const employeesRef = rbaseRef(database, `employees/${props.passMap.uid}`);

      const empData = await get(employeesRef);

      const data = { ...empData.val() };
      const tArr = [];
      Object.keys(data).forEach((key) => {
        tArr.push(`${data[key]["name"]}^${key}`);
      });
      fil3Values = [...tArr];
      fil3Values.push("All");
      console.log(fil3Values);

      const tFilValues = [...filValues];
      tFilValues.splice(1, 1, fil3Values);
      setFilValues(tFilValues);
      aFil3Value = fil3Values[0].split("^")[1];
      setFil3Value(aFil3Value);
    } else {
      fil3Values = filValues[1];
      aFil3Value = fil3Value;
    }

    //Creating Scheme Map for the Data Map
    fil3Values.forEach((iFil3Value) => {
      const fil3Value =
        iFil3Value.split("^").length > 1
          ? iFil3Value.split("^")[1]
          : iFil3Value.split("^")[0];
      tMap[fil2Value][fil3Value] = {}; //Eg tMap[2022][Skate Sharpening]={}
      tMap["All"][fil3Value] = tMap["All"][fil3Value] || {};

      filAValues.forEach((filAValue) => {
        tMap[fil2Value][fil3Value][filAValue] = {};
        tMap["All"][fil3Value][filAValue] =
          tMap["All"][fil3Value][filAValue] || {};
        tMap["All"]["All"][filAValue] = tMap["All"]["All"][filAValue] || {};
        fil4Values.forEach((fil4Value, i) => {
          const labelsForFil4 = props.passMap.chartLabels[topicIndex][i];
          const zeroAvgArr = labelsForFil4.map((it) => {
            return 0;
          });
          const zeroItArr = labelsForFil4.map((it) => {
            return [];
          });
          tMap[fil2Value][fil3Value][filAValue][fil4Value] = [...zeroAvgArr];
          tMap[fil2Value][fil3Value][filAValue][`${fil4Value}Items`] = [
            ...zeroItArr,
          ]; //Eg tMap[2022][Skate Sharpening][Hour]=[0,0,0,0,0]
          tMap["All"][fil3Value][filAValue][fil4Value] = tMap["All"][fil3Value][
            filAValue
          ][fil4Value] || [...zeroAvgArr];
          tMap["All"][fil3Value][filAValue][`${fil4Value}Items`] = tMap["All"][
            fil3Value
          ][filAValue][`${fil4Value}Items`] || [...zeroItArr];

          tMap["All"]["All"][filAValue][fil4Value] = tMap["All"]["All"][
            filAValue
          ][fil4Value] || [...zeroAvgArr];
          tMap["All"]["All"][filAValue][`${fil4Value}Items`] = tMap["All"][
            "All"
          ][filAValue][`${fil4Value}Items`] || [...zeroItArr];
        });
      });
    });

    console.log(tMap);

    const fil4Index = fil4Values.indexOf(fil4Value);
    const fil4ChartLabelsColl = [...props.passMap.chartLabels[topicIndex]];

    // const monthFil4Index =
    //   filValues[2].indexOf("Month");
    // const dayFil4Index =
    //   filValues[2].indexOf("Day");

    const tArr = [];

    docColl.docs.forEach((doc, i) => {
      const valueSeqs = doc.data().stringSeqDatas;

      console.log(valueSeqs);

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqArr = valueSeq.split("^");

        console.log(valueSeqArr);

        const filRefDbKeys = props.passMap.filRefDbKeys[topicIndex]; //Ex- "orderCompletionDateTime"
        const valueSeqDbKeys = props.passMap.valueSeqDbKeys;
        const refValues = [];

        console.log(filRefDbKeys);
        console.log(valueSeqDbKeys);

        filRefDbKeys.forEach((filRefKey, i) => {
          if (i != 3) {
            const mIndex = valueSeqDbKeys.indexOf(filRefKey);
            refValues.push(valueSeqArr[mIndex]); //Final Output Ex - [8712128721,"Skate Profiling",8712128721]
          } else {
            // if it is a variable key selection filter ex - Avg Wait Time, Avg Job Time etc
            const varFilRefDbKeys = [...filRefKey];
            const tArr = [];
            varFilRefDbKeys.forEach((varFilRefKey) => {
              const mIndex = valueSeqDbKeys.indexOf(varFilRefKey);
              tArr.push(valueSeqArr[mIndex]);
            });
            refValues.push(tArr);
          }
        });

        console.log(refValues);

        console.log(fil2Value, getCompRefValue(refValues[0], fil1Value));

        //eg if (2022==getCompRefValue(3438923992389,"Year"))
        if (fil2Value == getCompRefValue(refValues[0], fil1Value)) {
          const fil3Entry = refValues[1]; //Ex - Skate Sharpening

          console.log(fil3Entry);

          filAValues.forEach((filAValue, i) => {
            let filAEntry = 0;
            let resultOp = filAValue.includes("No of ") ? "Count" : "Average";

            if (filAValue == "Deviation") {
              const indexOfAvgJobTime = valueSeqDbKeys.indexOf(
                "orderJobStartToJobEndDuration"
              );
              const jobCompletionTime = valueSeqArr[indexOfAvgJobTime];
                console.log(refValues[3][i])

              filAEntry = parseFloat(refValues[3][i]) - jobCompletionTime;
            } else {
              filAEntry = parseFloat(refValues[3][i]);
            }

            fil4Values.forEach((fil4Value, i) => {
              //Inserting Values for all fil4Labels (ex- hour, day, month) to the final child array which will be the data for the chart
              const fil4Entry = getCompRefValue(refValues[2], fil4Value);


              const fil4EntryIndex = fil4ChartLabelsColl[i].indexOf(fil4Entry);
              console.log(fil4Entry,fil4Value,fil4EntryIndex);

              tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                fil4EntryIndex
              ].push(filAEntry); //Incrementing the position of the fil4Entry

              tMap[fil2Value][fil3Entry][filAValue][fil4Value][fil4EntryIndex] =
                resultOp == "Count"
                  ? tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                      fil4EntryIndex
                    ].length
                  : getArrayAvg(
                      tMap[fil2Value][fil3Entry][filAValue][
                        `${fil4Value}Items`
                      ][fil4EntryIndex]
                    ); //Incrementing the position of the fil4Entry

              tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                fil4EntryIndex
              ].push(filAEntry); //Incrementing the position of the fil4Entry

              tMap[fil2Value]["All"][filAValue][fil4Value][fil4EntryIndex] =
                resultOp == "Count"
                  ? tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                      fil4EntryIndex
                    ].length
                  : getArrayAvg(
                      tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                        fil4EntryIndex
                      ]
                    ); //Incrementing the position of the fil4Entry

              tMap["All"][fil3Entry][filAValue][`${fil4Value}Items`][
                fil4EntryIndex
              ].push(filAEntry); //Incrementing the position of the fil4Entry

              tMap["All"][fil3Entry][filAValue][fil4Value][fil4EntryIndex] =
                resultOp == "Count"
                  ? tMap["All"][fil3Entry][filAValue][`${fil4Value}Items`][
                      fil4EntryIndex
                    ].length
                  : getArrayAvg(
                      tMap["All"][fil3Entry][filAValue][`${fil4Value}Items`][
                        fil4EntryIndex
                      ]
                    ); //Incrementing the position of the fil4Entry

              tMap["All"]["All"][filAValue][`${fil4Value}Items`][
                fil4EntryIndex
              ].push(filAEntry); //Incrementing the position of the fil4Entry

              tMap["All"]["All"][filAValue][fil4Value][fil4EntryIndex] =
                resultOp == "Count"
                  ? tMap["All"]["All"][filAValue][`${fil4Value}Items`][
                      fil4EntryIndex
                    ].length
                  : getArrayAvg(
                      tMap["All"]["All"][filAValue][`${fil4Value}Items`][
                        fil4EntryIndex
                      ]
                    ); //Incrementing the position of the fil4Entry

              // if (fil4Value == "Year") {
              //   const yearAgnosticIndex = fil4ChartLabelsColl[i].indexOf("All");

              //   tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
              //     yearAgnosticIndex
              //   ].push(filAEntry); //Incrementing the position of the fil4Entry

              //   tMap[fil2Value][fil3Entry][filAValue][fil4Value][
              //     fil4EntryIndex
              //   ] = getArrayAvg(
              //     tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
              //       yearAgnosticIndex
              //     ]
              //   ); //Incrementing the position of the fil4Entry

              //   tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
              //     yearAgnosticIndex
              //   ].push(filAEntry); //Incrementing the position of the fil4Entry

              //   tMap[fil2Value]["All"][filAValue][fil4Value][
              //     yearAgnosticIndex
              //   ] = getArrayAvg(
              //     tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
              //       yearAgnosticIndex
              //     ]
              //   ); //Incrementing the position of the fil4Entry

              //   tMap["All"][fil3Entry][filAValue][`${fil4Value}Items`][
              //     yearAgnosticIndex
              //   ].push(filAEntry); //Incrementing the position of the fil4Entry

              //   tMap["All"][fil3Entry][filAValue][fil4Value][
              //     yearAgnosticIndex
              //   ] = getArrayAvg(
              //     tMap["All"][fil3Entry][filAValue][`${fil4Value}Items`][
              //       yearAgnosticIndex
              //     ]
              //   ); //Incrementing the position of the fil4Entry

              //   tMap["All"]["All"][filAValue][`${fil4Value}Items`][
              //     yearAgnosticIndex
              //   ].push(filAEntry); //Incrementing the position of the fil4Entry

              //   tMap["All"]["All"][filAValue][fil4Value][yearAgnosticIndex] =
              //     getArrayAvg(
              //       tMap["All"]["All"][filAValue][`${fil4Value}Items`][
              //         yearAgnosticIndex
              //       ]
              //     ); //Incrementing the position of the fil4Entry
              // }

              // tMap[fil2Value]["All"][filAValue][fil4Value][fil4EntryIndex] +=
              //   filAEntry;
              // tMap["All"][fil3Entry][filAValue][fil4Value][fil4EntryIndex] +=
              //   filAEntry;
              // tMap["All"]["All"][filAValue][fil4Value][fil4EntryIndex] +=
              //   filAEntry;
              //Ex - tMap[2022][Skate Sharpening][Hour]=[0,0,1,0,0]
            });
          });
        }
      });
    });

    console.log(tMap);
    setChartReady(true)
    props.stopSpinner()

    updateChart(tMap, fil2Value, aFil3Value, fil4Value, filAValue);
    return { ...tMap };
    // }
  }

  function tabClickHandler(selectedIndex,tabName,compDataMap, fil2Value, fil3Value, filAValue) {
    //setTabChanged(true);
    setTabFocusIndex(selectedIndex)

    options.scales.x.title.text = props.passMap.axisLabels[topicIndex][0][selectedIndex]


    //setCurTabName(tabName);
    fil4ValueChange(tabName, compDataMap, fil2Value, fil3Value, filAValue)
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  function updateChart(
    sCompDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue
  ) {
    const mIndex = filValues[2].indexOf(fil4Value);
    const labels = props.passMap.chartLabels[topicIndex][mIndex];

    console.log(sCompDataMap);
    console.log(fil3Value);

    const data = {
      labels: labels,
      datasets: [
        {
          label: fil2Value,
          data: sCompDataMap[fil2Value][fil3Value][filAValue][fil4Value],
          borderColor: "#C73C3C",
          backgroundColor: "#C73C3C",
        },
      ],
    };

    console.log(data);

    // setDatasetKey(`${fil2Value}-1`);
    setChartOptions({ ...options });

    setChartData({ ...data });
  }

  function fil2ValueChange(e, compDataMap, fil3Value, fil4Value, filAValue) {
    const recFil2Value =
      e.target.value == "All" ? e.target.value : parseInt(e.target.value);
    setFil2Value(recFil2Value);

    if (recFil2Value != "All") {
      initializePageWithData(
        compDataMap,
        recFil2Value,
        fil3Value,
        fil4Value,
        filAValue
      );
    } else {
      setChartReady(true)
      props.stopSpinner()
      updateChart(compDataMap, recFil2Value, fil3Value, fil4Value, filAValue);
    }
    console.log(e.target.value);
  }

  function fil3ValueChange(e, compDataMap, fil2Value, fil4Value, filAValue) {
    const recFil3Value = e.target.value;
    setFil3Value(recFil3Value);
    console.log(compDataMap);
    console.log(e.target.value);
    // initializePageWithData(
    //   compDataMap,
    //   fil2Value,
    //   e.target.value,
    //   fil4Value
    // );
    setChartReady(true)
    props.stopSpinner()
    updateChart(compDataMap, fil2Value, recFil3Value, fil4Value, filAValue);
  }

  function filAValueChange(e, compDataMap, fil2Value, fil3Value) {
    const recFilAValue = e.target.value;
    const selIndex =  props.passMap.filValues[topicIndex][3].indexOf(recFilAValue)
    options.scales.y.title.text = props.passMap.axisLabels[topicIndex][1][selIndex]
    setFilAValue(recFilAValue);
    updateChart(compDataMap, fil2Value, fil3Value, fil4Value, recFilAValue);
  }

  function fil4ValueChange(
    recFil4Value,
    compDataMap,
    fil2Value,
    fil3Value,
    filAValue
  ) {
    setFil4Value(recFil4Value);
    setChartReady(true)
    props.stopSpinner()
    updateChart(compDataMap, fil2Value, fil3Value, recFil4Value, filAValue);
  }

  const tabNames=["Month","Year"]

  return (
    <div className="h-cust-90vh grid grid-rows-cust-3f">
      <div className="flex justify-center items-center gap-12">
        <div className="flex gap-4">
          <div>{filCategories[0]}</div>
          <select
            key={`${props.passMap.topic}-1`}
            onChange={(e) =>
              fil2ValueChange(e, compDataMap, fil3Value, fil4Value, filAValue)
            }
          >
            {filValues[0].map((optionName) => {
              return <option value={optionName}>{optionName}</option>;
            })}
          </select>

        </div>
        <div className="flex gap-4">
            <div>{filCategories[1]}</div>
            <select
              key={`${props.passMap.topic}-2`}
              onChange={(e) =>
                fil3ValueChange(e, compDataMap, fil2Value, fil4Value, filAValue)
              }
            >
              {filValues[1].map((optionName) => {
                return (
                  <option
                    value={
                      optionName.split("^").length == 2
                        ? optionName.split("^")[1]
                        : optionName.split("^")[0]
                    }
                  >
                    {optionName.split("^")[0]}
                  </option>
                );
              })}
            </select>
        </div>

          <div className="flex gap-4">
              <div>{filCategories[2]}</div>
              <select
                key={`${props.passMap.topic}-3`}
                onChange={(e) =>
                  filAValueChange(e, compDataMap, fil2Value, fil3Value, fil4Value)
                }
              >
                {filValues[3].map((optionName) => {
                  return <option value={optionName}>{optionName}</option>;
                })}
              </select>
        </div>
        <div className="flex gap-4">
        <TabContainer
          // tabNameList={props.passMap.inputCategories}
          tabNameList={tabNames}
          tabFocusIndex={tabFocusIndex}
          tabClickHandler={(focusIndex) => tabClickHandler(focusIndex,tabNames[focusIndex],compDataMap, fil2Value, fil3Value, filAValue)}
        />
            {/* {filValues[2].map((optionName) => {
              return (
                <button
                  onClick={(e) =>
                    fil4ValueChange(
                      optionName,
                      compDataMap,
                      fil2Value,
                      fil3Value,
                      filAValue
                    )
                  }
                >
                  {optionName}
                </button>
              );
            })} */}
        </div>

        {/* <button
          onClick={(e) =>
            fil4ValueChange("Day", compDataMap, fil2Value, fil3Value, filAValue)
          }
        >
          Day
        </button>
        <button
          onClick={(e) =>
            fil4ValueChange(
              "Month",
              compDataMap,
              fil2Value,
              fil3Value,
              filAValue
            )
          }
        >
          Month
        </button> */}
      </div>
      <div className="w-cust-70rem mx-auto items-center justify-center">
      {chartReady&&
        <Line options={{ ...chartOptions }} data={{ ...chartData }} />
      }
      </div>
      {bottomContainer}
    </div>
  );
}

function getCompRefValue(value, filValue) {
  switch (filValue) {
    case "Year": {
      return new Date(parseInt(value) * 1000).getFullYear();
    }
    case "Month": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getMonth(), "Month");
    }
    case "Day": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getDay(), "Day");
    }
    case "Hour": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getHours(), "Hour");
    }
  }
}
