import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserDataContext } from "./Level1";

export default function ButtonBack(props) {
  return (
    <button className="btn-action" onClick={() => props.backBtnClickHandler()}>
      Back
    </button>
  );
}
