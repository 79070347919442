import React, { useState } from "react";
import BtnNormalModal from "./BtnNormalModal";

export default function ModalDelegateInput(props){


    const [valdCount,setValdCount] = useState(1)

    const [data,setData] = useState({})


    
    return(<div>
      <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
          <div className="relative grid grid-rows-cust-3i h-3/6 w-2/5 bg-quaternary-light m-4 items-center justify-center rounded-lg">
              <div className="btn-modal-close"  onClick={() => props.modalCloseHandler()}>
                X
            </div>
              <div className="h-full w-fit flex mx-auto  pt-4 px-8 text-2xl font-bold">
                {props.passMap.title}
              </div>
    
            <div>
            {props.inpSchemeMap.inpLabels.map((inpLabel,i)=>
                <input
                    name={props.inpSchemeMap.inpKeys[i]}
                    type={props.inpSchemeMap.inpTypes[i]}
                    onChange={(e)=>setData({event:e})}
                />
            
            )}

            </div>
            <div className="flex justify-center">     
             {valdCount >= 0 && 
               <React.Fragment>      
                    {props.passMap.opBtnLabels.map((btnLabel,i)=>
                    <BtnNormalModal key={i} color="primary" label="Submit" clickHandler={()=>{props.delegateFnHandler(data);props.modalCloseHandler()}} />
                    )}
              </React.Fragment>
            }           
            </div>
    
          </div>
   
      </div>
        
    </div>)
}