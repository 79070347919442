// START FOR InputElementsCont.jsx 
//function to create an empty field object with keys of the templateObj passed to it

import {
  getInpOptions,
  getInpOptionsParentValues,
} from "../Utils.js";

import { inpDynOptionsParentKeyMap } from "./DataList.js";


import DynamicInputList from "./DynamicInputList.jsx";
import FixedText from "./FixedText.jsx";
import ImgInputTile from "./ImgInputTile.jsx";
import InputList from "./InputList.jsx";
import InputListWithWritable from "./InputListWithWritable.jsx";
import InputSwitch from "./InputSwitch.jsx";
import InputTile from "./InputTile.jsx";
import InputTileDependant from "./InputTileDependant.jsx";


  //START FOR InputElementsContBasic.jsx



  export function checkAndRenderInputElements(
    passDataObj,
    inputDataObj,
    inputChangeFn,
    changeValdCountFn,
    editStatusArray
  ) {

  
    return Object.keys(inputDataObj).map((key, i) => {
      switch (passDataObj.elemTypes[i]) {
        case "standard":
          return (
            <InputTile
              key={`${passDataObj.inputNames[i]}-${i}`} //passDataObj has all data from props object
              inputName={passDataObj.inputNames[i]}
              inputType={passDataObj.inputTypes[i]}
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              onChangeFn={(e) => inputChangeFn(e, passDataObj)}
              inputLabel={passDataObj.inputLabels[i]}
              inpMandCond={"mandInpKeys" in passDataObj ? passDataObj.mandInpKeys.includes(passDataObj.inputNames[i]) : false}
              editStatus={editStatusArray != null ? editStatusArray[i] : true}
              opType={passDataObj.opType}
              fieldValue={
                passDataObj.opType == "edit"
                  ? passDataObj.inpValMap[passDataObj.inputNames[i]]
                  : ""
              }
              changeValdCount={(val)=>changeValdCountFn(val)}
              regexDomain={"inputRegexDomains" in passDataObj ? passDataObj.inputRegexDomains[i] : ""}
            />
          );
        case "switch":
          return (
            <InputSwitch
              key={passDataObj.inputNames[i]}        
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              onChangeFn={(value) => inputChangeFn(value,passDataObj.inputNames[i],"switch")}
             // switchStatus={inputDataObj[passDataObj.inputNames[i]]}
              inputLabel={passDataObj.inputLabels[i]}
              fieldValue={passDataObj.inpValMap[passDataObj.inputNames[i]]}
            />
          );
          case "standard-dependant":
            return (
              <InputTileDependant
                key={`${passDataObj.inputNames[i]}-${i}`} //passDataObj has all data from props object
                inputName={passDataObj.inputNames[i]}
                inputType={passDataObj.inputTypes[i]}
                inputValue={inputDataObj[passDataObj.inputNames[i]]}
                inputDataObj={inputDataObj}
                onChangeFn={(e) => inputChangeFn(e, passDataObj)}
                inputLabel={passDataObj.inputLabels[i]}
                editStatus={editStatusArray != null ? editStatusArray[i] : true}
                opType={passDataObj.opType}
                passMap={passDataObj}
                fieldValue={
                  passDataObj.opType == "edit"
                    ? passDataObj.inpValMap[passDataObj.inputNames[i]]
                    : ""
                }
                regexDomain={Object.keys(passDataObj).includes("inputRegexDomains")?passDataObj.inputRegexDomains[i]:""}
                orientation="horizontal"
              />
            );
          
        case "selection":
          return (
            <InputList
              key={passDataObj.inputNames[i]}
              inputType={passDataObj.inputTypes[i]}
              inputName={passDataObj.inputNames[i]}
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              onChangeFn={(e) => inputChangeFn(e)}
              inputLabel={passDataObj.inputLabels[i]}
              // selectOptions={passDataObj.selectOptions[i]}
              selectOptions={getInpOptions(passDataObj.inputNames[i])}
              fieldValue={
                passDataObj.opType == "edit"
                  ? passDataObj.inpValMap[passDataObj.inputNames[i]]
                  : passDataObj.selectOptions[i].length > 0
                  ? passDataObj.selectOptions[i][0]
                  : ""
              }
            />
          );
  
          case "dynamic-selection":
            return (
              <DynamicInputList
                key={passDataObj.inputNames[i]}
                inputType={passDataObj.inputTypes[i]}
                inputName={passDataObj.inputNames[i]}
                inputValue={inputDataObj[passDataObj.inputNames[i]]}
                onChangeFn={(e) => inputChangeFn(e)}
                inputLabel={passDataObj.inputLabels[i]}
                parentValues={getInpOptionsParentValues(inputDataObj,passDataObj.inputNames[i],inpDynOptionsParentKeyMap[passDataObj.inputNames[i]])}
                fieldValue={
                  passDataObj.opType == "edit"
                    ? passDataObj.inpValMap[passDataObj.inputNames[i]]
                    : passDataObj.selectOptions[i].length > 0
                    ? passDataObj.selectOptions[i][0]
                    : ""
                }
              />
            );
            case "image":
              return (
                <ImgInputTile                  
                key={`${passDataObj.inputNames[i]}-${i}`} //passDataObj has all data from props object
                inputName={passDataObj.inputNames[i]}
                inputType={"file"}
                onChangeFn={(e) => inputChangeFn(e, passDataObj)}
                inputLabel={passDataObj.inputLabels[i]}
                editStatus={editStatusArray != null ? editStatusArray[i] : true}
                colStart={2}
                colEnd={3}
                opType={passDataObj.opType}
                fieldValue={
                  passDataObj.opType == "edit"
                    ? passDataObj.inpValMap[passDataObj.inputNames[i]]
                    : ""
                }
                />
              );
       
        case "display":
          return (
            <FixedText
              key={passDataObj.inputNames[i]}
              inputName={passDataObj.inputNames[i]}
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              inputLabel={passDataObj.inputLabels[i]}
            />
          );
          break;
        default:
          return <div>No View</div>;
      }
    });
  }


  export function checkAndRenderInputElements2(
    passDataObj,
    inputDataObj,
    inputChangeFn,
    changeValdCountFn,
    editStatusArray
  ) {
  
    return Object.keys(passDataObj.elemTypes).map((elemType, i) => {
      switch (passDataObj.elemTypes[i]) {
        case "standard":
          return (
            <InputTile
              key={`${passDataObj.inputNames[i]}-${i}`} //passDataObj has all data from props object
              inputName={passDataObj.inputNames[i]}
              inputType={passDataObj.inputTypes[i]}
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              onChangeFn={(e) => inputChangeFn(e, passDataObj)}
              inputLabel={passDataObj.inputLabels[i]}
              inpMandCond={"mandInpKeys" in passDataObj ? passDataObj.mandInpKeys.includes(passDataObj.inputNames[i]) : false}
              editStatus={editStatusArray != null ? editStatusArray[i] : true}
              opType={passDataObj.opType}
              fieldValue={
                passDataObj.opType == "edit"
                  ? inputDataObj[passDataObj.inputNames[i]]
                  : ""
              }
              changeValdCount={(val)=>changeValdCountFn(val)}
              regexDomain={"inputRegexDomains" in passDataObj ? passDataObj.inputRegexDomains[i] : ""}
            />
          );
        case "switch":
          return (
            <InputSwitch
              key={passDataObj.inputNames[i]}        
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              onChangeFn={(value) => inputChangeFn(value,passDataObj.inputNames[i],"switch")}
             // switchStatus={inputDataObj[passDataObj.inputNames[i]]}
              inputLabel={passDataObj.inputLabels[i]}
              fieldValue={passDataObj.inpValMap[passDataObj.inputNames[i]]}
            />
          );
          case "standard-dependant":
            return (
              <InputTileDependant
                key={`${passDataObj.inputNames[i]}-${i}`} //passDataObj has all data from props object
                inputName={passDataObj.inputNames[i]}
                inputType={passDataObj.inputTypes[i]}
                inputValue={inputDataObj[passDataObj.inputNames[i]]}
                inputDataObj={inputDataObj}
                onChangeFn={(e) => inputChangeFn(e, passDataObj)}
                inputLabel={passDataObj.inputLabels[i]}
                editStatus={editStatusArray != null ? editStatusArray[i] : true}
                opType={passDataObj.opType}
                passMap={passDataObj}
                fieldValue={
                  passDataObj.opType == "edit"
                    ? inputDataObj[passDataObj.inputNames[i]]
                    : ""
                }
                regexDomain={Object.keys(passDataObj).includes("inputRegexDomains")?passDataObj.inputRegexDomains[i]:""}
                orientation="horizontal"
              />
            );
          
        case "selection":
          return (
            <InputList
              key={passDataObj.inputNames[i]}
              inputType={passDataObj.inputTypes[i]}
              inputName={passDataObj.inputNames[i]}
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              onChangeFn={(e) => inputChangeFn(e)}
              inputLabel={passDataObj.inputLabels[i]}
              // selectOptions={passDataObj.selectOptions[i]}
              selectOptions={getInpOptions(passDataObj.inputNames[i])}
              fieldValue={
                passDataObj.opType == "edit"
                  ? inputDataObj[passDataObj.inputNames[i]]
                  : passDataObj.selectOptions[i].length > 0
                  ? passDataObj.selectOptions[i][0]
                  : ""
              }
            />
          );
  
          case "dynamic-selection":
            return (
              <DynamicInputList
                key={passDataObj.inputNames[i]}
                inputType={passDataObj.inputTypes[i]}
                inputName={passDataObj.inputNames[i]}
                inputValue={inputDataObj[passDataObj.inputNames[i]]}
                onChangeFn={(e) => inputChangeFn(e)}
                inputLabel={passDataObj.inputLabels[i]}
                parentValues={getInpOptionsParentValues(inputDataObj,passDataObj.inputNames[i],inpDynOptionsParentKeyMap[passDataObj.inputNames[i]])}
                fieldValue={
                  passDataObj.opType == "edit"
                    ? inputDataObj[passDataObj.inputNames[i]]
                    : passDataObj.selectOptions[i].length > 0
                    ? passDataObj.selectOptions[i][0]
                    : ""
                }
              />
            );
            case "selection-writable":
                return (
                  <InputListWithWritable
                    key={passDataObj.inputNames[i]}
                    inputTypes={passDataObj.inputTypes[i]}
                    inputNames={passDataObj.inputNames[i]}
                    inputValues={[inputDataObj[passDataObj.inputNames[i][0]],inputDataObj[passDataObj.inputNames[i][1]]]}
                    onChangeFn={(e) => inputChangeFn(e)}
                    inputLabel={passDataObj.inputLabels[i]}
                    // selectOptions={passDataObj.selectOptions[i]}
                    selectOptions={getInpOptions(passDataObj.inputNames[i][0])}
                    editStatus={passDataObj.editStatus[i]}
                    fieldValue={
                      passDataObj.opType == "edit"
                        ? [inputDataObj[passDataObj.inputNames[i][0]],inputDataObj[passDataObj.inputNames[i][1]]]
                        : passDataObj.selectOptions[i].length > 0
                        ? passDataObj.selectOptions[i][0]
                        : ""
                    }
                    changeValdCount={(val)=>changeValdCountFn(val)}
                    regexDomain={"inputRegexDomains" in passDataObj ? passDataObj.inputRegexDomains[i] : ""}
                  />
                );
            case "image":
              return (
                <ImgInputTile                  
                key={`${passDataObj.inputNames[i]}-${i}`} //passDataObj has all data from props object
                inputName={passDataObj.inputNames[i]}
                inputType={"file"}
                onChangeFn={(e) => inputChangeFn(e, passDataObj)}
                inputLabel={passDataObj.inputLabels[i]}
                editStatus={editStatusArray != null ? editStatusArray[i] : true}
                colStart={2}
                colEnd={3}
                opType={passDataObj.opType}
                fieldValue={
                  passDataObj.opType == "edit"
                    ? inputDataObj[passDataObj.inputNames[i]]
                    : ""
                }
                />
              );
       
        case "display":
          return (
            <FixedText
              key={passDataObj.inputNames[i]}
              inputName={passDataObj.inputNames[i]}
              inputValue={inputDataObj[passDataObj.inputNames[i]]}
              inputLabel={passDataObj.inputLabels[i]}
            />
          );
          break;
        default:
          return <div>No View</div>;
      }
    });
  }


  export function getInputElement(
    props,
    onChangeHandler,
    deductedVald,
    styleClass
  ) {

    switch (props.inpType) {
      case "number":
        return(
          <input
          key={props.key}
          className={styleClass}
          value={props.inpValue}
          type={props.inpType}
          min={"min" in props ? props.inpMinVal : ""}
          max={"max" in props ? props.inpMaxVal : ""}
          disabled={!props.inpEditStatus}
          name={props.inpKey}
          onChange={(e)=>onChangeHandler(e,props.inpKey,deductedVald)}
        ></input>
      )
      case "number-dec":
        return(
          <input
          key={props.key}
          className={styleClass}
          value={props.inpValue}
          type="number"
          min={"inpMinVal" in props ? props.inpMinVal : ""}
          max={"inpMaxVal" in props ? props.inpMaxVal : ""}
          step={"inpStepVal" in props ? props.inpStepVal : "0.01"}
          disabled={!props.inpEditStatus}
          name={props.inpKey}
          onChange={(e)=>onChangeHandler(e,props.inpKey,deductedVald)}
        ></input>
      )
      default:
        return (
          <input
          key={props.key}
          className=""
          value={props.inpValue}
          type={props.inpType}
          disabled={!props.editStatus}
          name={props.inpKey}
          onChange={(e)=>onChangeHandler(e,props.inpKey,props.regexDomain)}
        ></input>
        )
    }

  }



  export function getBtnImgSize(context){
      switch (context) {
        case "Dashboard Function": return "h-20 w-20";
        case "Banner Function": return "h-10 w-10";
        case "Top Bar": return "h-8 w-8";            
        case "Dashboard Widget":return "h-6 w-6";          
      }

  }


  export function getBtnImgWidth(context){
    switch (context) {
      case "Dashboard Function": return 100;
      case "Banner Function": return "h-10 w-10";
      case "Top Bar": return "h-8 w-8";            
      case "Dashboard Widget":return "h-6 w-6";
      default :return 60;               
    }

}