import React from "react";
import appLogo from "../images/app-logo.svg";
import AppInfoTile from "./AppInfoTile";






export default function ModalBoxAppInfo(props) {

  function promptCloseFn(){
    props.promptClose()

    console.log(props.passMap)

    if("completionFn" in props.passMap){
      props.passMap.completionFn()
    }


  }
 

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="grid bg-quaternary-light m-4 p-4 rounded-lg">
        <div className="grid grid-cols-2 p-8">
          <div className="flex flex-col gap-2">
            <AppInfoTile label="App Version" value={process.env.REACT_APP_VERSION}/>
            <AppInfoTile label="Release Month" value={process.env.REACT_APP_RELEASE_MONTH}/>
            <AppInfoTile label="Contact" value="info@ace-campus.com"/>
            <AppInfoTile label="Developer" value="Ncode Media"/>
            <AppInfoTile label="Copyright" value="© 2024 Ace Campus"/>
          </div>
          <div className="w-full flex justify-end"><img className="h-40" src={appLogo} /></div>
        </div>

        <div className="flex items-center justify-center">
          <button className="btn-action" onClick={() => promptCloseFn()}>
          OK
        </button>
        </div>
      </div>
    </div>
  )
}
