


import { ref as rbaseRef, remove } from "firebase/database";

import { collection, collectionGroup, deleteDoc, doc, getDoc, getDocs, query, runTransaction, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { getBlob, ref as storageRef } from "firebase/storage";
import { database, db, functions, httpsCallable, storage } from "../Utils";
import { getNewSerialNo } from "./HelperFns";

let opResult={
  status:false,
  message:"Function Call Error",
  errorMessage:""
}


export async function addDbFn(fnName,payload,domainMap){

  if(fnName == "Add Skate"){

    const path = `customers/${payload.customerUid}/${payload.docUid}`
    const customerUid = domainMap.customerUid
    const customerId = domainMap.customerId

    return await runTransaction(db,async (t)=>{

      const skateCustomerDoc =  await t.get(doc(db,`customers`,`${customerUid}`));
      const latestSkateSl = skateCustomerDoc.data().latestSkateSl;
      const newSkateSl = getNewSerialNo(latestSkateSl);

      const newSkateId = `${customerId}${newSkateSl}`

    const skateCollPath = `customers/${customerUid}/skates`
    const dbPathRef = doc(collection(db,skateCollPath))

    const docPayload = {
      docUid:dbPathRef.id,
      docCreationDate:serverTimestamp(),
      skateAssignedMemberId:"NA",
      skateQrId:"NA",
      skateCustomerUid:customerUid,
      skateCustomerId:customerId,
      skateCustomerName:domainMap.customerName,
      skateId:newSkateId,
      ...payload
    }
      t.update(doc(db,`customers`,`${customerUid}`),{latestSkateSl:newSkateSl,docModificationDate:serverTimestamp()})
      t.set(doc(db,skateCollPath,dbPathRef.id),docPayload)
    }).then(()=>{
      return "Successfully Added"
    }).catch((error)=>{
      return "Add Error"
    })

  }




}

export async function updateDbFn(fnName,payload,domainMap,recordDataMap){

  if(fnName == "Edit Skate"){

    console.log(payload)

              


     const skateCollPath = `customers/${domainMap.customerUid}/skates`
      return updateDoc(doc(db,skateCollPath,`${recordDataMap.docUid}`),{...payload,docModificationDate:serverTimestamp()}).then(()=>{
          return "Successfully Updated"
      }).catch((error)=>{
        return "Update Error"
      })
    }

  }



  export async function deleteDbFn(fnName,payload,domainMap,recordDataMap){

    if(fnName == "Edit Skate"){
  
      console.log(payload,recordDataMap)

      const passMap = {
        queryValue : recordDataMap.skateId,
        queryKey: "orderItemId",
        queryDomain:"currentOrders"
      }
  

      const deleteFeasibilityMap = await checkDeleteFeasibiliy("skate",passMap)

        if(!deleteFeasibilityMap.result){
            return deleteFeasibilityMap.message
        }

        const skateCollPath = `customers/${domainMap.customerUid}/skates`
      
        return deleteDoc(doc(db,skateCollPath,`${recordDataMap.docUid}`)).then(async ()=>{
          await deleteValuesByPathRbase(database, `qrIds/${recordDataMap.skateQrId}`);
          return "Successfully Deleted"
        }).catch((error)=>{
          console.log(error)
          return `Delete Error`
        })
      }
  
    }



    async function checkDeleteFeasibiliy(topic,passMap){

      switch(topic){
        case "skate":{

          console.log(passMap)

          //Check if there is a current order running

          const querySnapshot = await getDocs(query(collectionGroup(db,passMap.queryDomain),where(passMap.queryKey,"==",passMap.queryValue)));

            return {
              result:querySnapshot.docs.length==0,
              message : querySnapshot.docs.length>0 ? "It seems there is an order linked to the skate, please kindly finish the order before deleting the skate" : ""
            }
    
          
        }
      }
    }



   async  function deleteValuesByPathRbase(rBaseInitObj, pathToDelete){

    return remove(rbaseRef(rBaseInitObj,pathToDelete));

    }


    export async function getFirestoreDocSnapshotByPath(path){

      const docSnapshot = await getDoc(doc(db,path))

      return docSnapshot

    }


    export async function getFirestoreDocKeyValue(path,key){

      const docSnapshot = await getDoc(doc(db,path))

      return docSnapshot.data()[key]

    }

    //URL.createObjectURL(await getFile(passDataObj.dataMap.storeImage))


    export async function getFbStorageFile(path,filename){

      const ref = storageRef(storage,`${path}/${filename}`);

      const storageBlob = getBlob(ref);

      storageBlob.lastModifiedData = new Date();
      storageBlob.name = filename;

      const file = storageBlob

      return file;
    }

    export async function callFbFunction(fnName,payload){

      const fbFunctionCall = httpsCallable(functions,fnName)

      try{
        const resultObj  = await fbFunctionCall(payload)
        opResult = resultObj.data

      }catch(error){
        opResult.errorMessage=error.message
      }

      return opResult
    }









