import React, { Component, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import pdfData from "../docs/terms-of-use.pdf"
import {
  electron,
  pageSizes,
  getSerialNo,
  db,
  doc,
  getDoc,
  updateDoc,
  runTransaction,
  Timestamp,
  serverTimestamp,
} from "../Utils";
import BottomContainer from "./BottomContainer";


export default function ModalBoxAgreement(props) {
  const qrCanvas = useRef(null);

  const [agrmtSelectCond, setAgrmtSelectCond] = useState(false);
  const [opState, setOpState] = useState("QR Generation");



  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
      <div className="relative grid grid-rows-cust-3h h-5/6 w-cust-70rem bg-quaternary-light m-4 items-center justify-center">
        <div className="h-full w-fit flex mx-auto cont-title-red-marker pt-4 px-8 text-2xl font-bold">
          TERMS OF USE
        </div>

        <div className="btn-modal-close"  onClick={() => props.modalClose()}>
            X
        </div>

        <div className="h-full flex-col gap-8">
          <object className="w-cust-65rem" data={pdfData} type="application/pdf" width="100%" height="100%">
                          {/* <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p> */}
                      </object>

            <div className="flex gap-4">
                <input
                type="checkbox"
                onChange={(e)=>{setAgrmtSelectCond(e.target.checked)}}
                ></input>
                <label>I agree</label>
            </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-2">

          {agrmtSelectCond &&
          <button className="btn-action width-auto px-4 py-1" onClick={() => props.actionBtnClickHandler()}>
            Proceed to Sign Up
          </button>
          }
        </div>

 

      </div>
    </div>
  )
}
