import React, { Component } from "react";
import RecordLabelUnit from "./RecordLabelUnit";
import ActionButton from "./ActionButton";
import { getStoreLabelValueAdmin, getDate } from "../Utils";

export default function RecordStrip3(props) {
  console.log(props);

  function checkAndReturnValue(value, labelName) {
    if (labelName == "Date/Time") {
      return getDate(value);
    } else {
      return value;
    }
  }
  return (
    <div className="w-11/12 mt-1 mx-auto grid">
      <div
        className={`title-strip w-full grid mt-1 grid-cols-cust-7c
         bg-quartenaryLight rounded-sm border border-borderStd`}
      >
        {props.labelDbNotations.map((dbNotations, i) => {
          return (
            <div className="value-strip-unit text-center text-lightBackText">
              {getStoreLabelValueAdmin(
                props.labelNames[i],
                props.stripValueMap[dbNotations],
                "CAD"
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

// typeof props.passDataMap[props.labelDbNotations[index]] == "object"
//   ? props.passDataMap[props.labelDbNotations[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbNotations[index]]
