import React, { useContext, useEffect, useState } from "react";
import ActionButton from "./ActionButton";
import { checkAndFinalizeInpDataObj, checkMandInputs, createDbRecord, createEditInpObj, createInitialObj, deleteDbRecord, getInputUploadObj, getInputValue, revisePassMap } from "./HelperFns";
import InputElementsCont from "./InputElementsCont";
import InputElementsCont2 from "./InputElementsCont2";
import { UserDataContext } from "./Level1";
import LoadSpinner from "./LoadSpinner";
import ModalBoxOpConfPrompt2 from "./ModalBoxOpConfPrompt2";
import TabContainer from "./TabContainer";

export default function InputCont2(props) {
  const userContext = useContext(UserDataContext); //Main User Data of the Current User Broadcasted from Level 1

  console.log(userContext);
  console.log(props.schemeMap);

  console.log(props)

  const initPassDataObj = { ...revisePassMap(props.schemeMap) }; 

  const initInpObj = 
  props.schemeMap.opType == "edit"
    ? createEditInpObj(props.dataMap,props.schemeMap)
    : createInitialObj(initPassDataObj); //Template Object of the 'Input Data Object'

  const [inpDataObj, setInpDataObj] = useState({ ...initInpObj });
  const [passDataObj, setPassDataObj] = useState({ ...initPassDataObj });

  const [inputDomain, setInputDomain] = useState("");
  const [editStatusArray, setEditStatusArray] = useState([
    ...props.schemeMap.editStatus,
  ]);

  const [inpImg, setInpImg] = useState("");

  const [showPrompt, setShowPrompt] = useState(false);
  const [controlObj,setControlObj] = useState({})

  const [submitClicked, setSubmitClicked] = useState(false);
  const [curTabName, setCurTabName] = useState("General");
  const [tabChanged, setTabChanged] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPromptConf, setShowPromptConf] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [valdCount, setValdCount] = useState(0);

  console.log("In Input Cont ");
  useEffect(() => {}, [curTabName]);



  function submitClose() {
    setSubmitClicked(false);
    setShowSpinner(false);
  }

  function submitClickStart(actionType,inpDataObj) {
    console.log(inpDataObj)

    const inpUploadObj = getInputUploadObj(inpDataObj,props.schemeMap)

    let opFn=()=>{
      alert("No Function Assigned")
    }

    let confmMsg = ""

    switch(actionType){

      case "add":{
        confmMsg = `Do you want to proceed to add the ${props.schemeMap.inputTopic}?`

      }
      break;
      case "update":{

         confmMsg = `Do you want to proceed to ${props.schemeMap.opType} the ${props.schemeMap.inputTopic}?`

                 
         const mandInpCheckCond = props.schemeMap.mandInpKeys ? checkMandInputs(props.schemeMap.mandInpKeys,inpUploadObj) : true;
        
         if(!mandInpCheckCond){
           props.displayPromptHandler("Missing Inputs", "Please enter all mandatory inputs")
           props.submitProcessHandler(false)
          //  props.submitCloseHandler();
           return
         }
        
          opFn = async () =>{
            props.submitProcessHandler(true)
            // setShowSpinner(true);

            // const type = passDataObj.operationType; //Capturing the Operation Type ex - Doc Addition, Account Creation
            // let dbWriteOk=true;


          return await createDbRecord(props.schemeMap,inpUploadObj,props.domainMap,props.dataMap)
        }

      }
      break;
  
        case "delete":{
          confmMsg = `Deleting will permanently erase records. Do you want to proceed to delete the ${props.schemeMap.inputTopic}?`
          opFn = async () =>{
            props.submitProcessHandler(true)

           return await deleteDbRecord(props.schemeMap,inpUploadObj,props.domainMap,props.dataMap)
        }
      }

    }

  getConfirmationAndSubmit(confmMsg,opFn,actionType)
}






  function inputChangeFn(e) {

    const passDataObj = props.schemeMap
    
    console.log(e)
    const rInputName = e.target.name;
    const rInputType = e.target.type;
    let rInputValue = getInputValue(e.target);
    console.log(e.target);
    console.log(rInputValue);

    if (rInputType == "file") {
      setInpImg(URL.createObjectURL(rInputValue));
    }

    if (rInputName == "ovrStoreAvlCond") {
      //Enabling Store Availability Option when override is true
      const tMap = { ...passDataObj };
      const tArr = [...editStatusArray];
      const mIndex = tMap["inputNames"].indexOf("storeAvlCond");
      const mIndex1 = tMap["inputNames"].indexOf("storeStartTime");
      const mIndex2 = tMap["inputNames"].indexOf("storeEndTime");

      console.log(tArr);
      console.log(tMap)
      if (rInputValue) {
        tArr.splice(mIndex, 1, true);
        tArr.splice(mIndex1, 1, false);
        tArr.splice(mIndex2, 1, false);
      } else {
        tArr.splice(mIndex, 1, false);
        tArr.splice(mIndex1, 1, true);
        tArr.splice(mIndex2, 1, true);
      }
      setEditStatusArray([...tArr]);
    } else if (rInputName == "ovrWaitTimeCond") {

    }else if (rInputName == "ovrWaitTime") {
      rInputValue = rInputValue*60
    }

    console.log(inpDataObj);
    console.log(inputDomain);
    const tempObj = { ...inpDataObj }; //copying the 'Input Object State' into a temp object to then set as 'Input Object State'
    // Above doing this so, is because if tempObj=inpDataObj, then changing the tempObj will also chaneg the input object and
    //then when I set the State, the state will not update as the temp and input objects are the same


    if (rInputName == "invoiceDiscount") {
      const actInvoiceAmount = props.schemeMap.dataMap.invoiceAmount;
      tempObj["invoiceAmount"] = (actInvoiceAmount * (1-(rInputValue/100))).toFixed(2);
    }else if(rInputName == "invoiceAmount"){
      const actInvoiceAmount = props.schemeMap.dataMap.invoiceAmount;
      tempObj["invoiceDiscount"] = ((1-(rInputValue/actInvoiceAmount))*100).toFixed(2);
    }


    tempObj[rInputName] = rInputValue;

    setInpDataObj(checkAndFinalizeInpDataObj(tempObj)); //Input Object Set
    console.log("setinpDataObj=",checkAndFinalizeInpDataObj(tempObj));
    console.log(inpDataObj);
  }


  function tabClickHandler(tabName) {
    setCurTabName(tabName);
  }

  const viewTabContainer = (
    <TabContainer
      tabNameList={props.schemeMap.inputCategories}
      tabClickHandler={(name) => tabClickHandler(name)}
    />
  );


  function opCompletionFn(fnType,completionStatus){
    props.submitProcessHandler(false)
    if(fnType=="delete" && completionStatus){
      props.backBtnClickHandler(2)
    }

  }



  function getConfirmationAndSubmit(confirmationMessage,opFn,actionType){

    setShowPromptConf(true)

    setPromptMap({
      message:confirmationMessage,
      opFn:opFn,
      actionType:actionType
    });

  }

  const hScreenCond = props.schemeMap.fnType == "modal" ? false : true
  const deleteFnReqd = props.schemeMap.deleteFnReqdCond

  return (
    <div className={`${hScreenCond ? `h-screen` : ``} grid justify-items-center p-2`}>
      {props.schemeMap.tabOptionsReqd && viewTabContainer}
      {!props.schemeMap.tabOptionsReqd && <div></div>}
      {props.schemeMap.fnType == "others" && (
        <InputElementsCont
          passMap={props.schemeMap} //passDataObj has all data from props object
          submitClicked={submitClicked}
          submitCloseHandler={() => submitClose()}
          tabName=""
          mandInpKeys={props.schemeMap.mandInpKeys}
          changeValdCount = {(val)=>setValdCount(valdCount+val)}
        />
      )}

      {props.schemeMap.fnType == "modal" && (
                    <InputElementsCont2
                      passDataObj={props.schemeMap}
                      inpDataObj={inpDataObj}
                      inpChangeFn={(e)=>inputChangeFn(e)}
                      changeValdCountFn={(val)=>setValdCount(valdCount+val)}
                    />
      )}
      {showSpinner && <LoadSpinner />}
          <div className="flex gap-4 justify-center items-center p-16">
            {valdCount >=0 &&
              <ActionButton
                sizeType="medium"
                alertLevel="high"
                clickHandler={() => submitClickStart("update",inpDataObj)}
                btnLabel="Submit"
              />
            }
            {deleteFnReqd && 
              <ActionButton
                 sizeType="medium"
                 alertLevel="high"
                clickHandler={() => submitClickStart("delete",inpDataObj)}
                btnLabel="Delete"
              />
            }

              <ActionButton
                 sizeType="medium"
                 alertLevel="low"
                clickHandler={() => props.backBtnClickHandler()}
                btnLabel="Back"
              />
     
          </div>
      
    {showPromptConf && <ModalBoxOpConfPrompt2 opCompletionHandler={(fnType,completionStatus)=>opCompletionFn(fnType,completionStatus)} passMap={promptMap} promptClose={()=>setShowPromptConf(false)}/>}
    </div>
  );
}
