import React from "react";
import { getDateTimeFromMySqlTimestampString, getOnlyDateFromMySqlTimestampString } from "./genFunctions";

export default function RecordMapStrip(props) {
  console.log(props);



  function getMapRecordCellValue(stripValueMap,cellDbKey,cellDataType,props){

    console.log(cellDataType,stripValueMap)

    const statusKeys =["new","assessed","submitted","resubmissionRequested","resubmission","resubmitted","inReview","resubmissionReceived","reportGenerated"]
    const statusLabels = ["New","Assessed","Submitted","Resubmission Requested","Resubmission","Resubmitted","In Review","Resubmission Received","Report Generated"]

    if(Array.isArray(cellDataType)){ //for map data types
      console.log("array area")
      const mainCellDataType = cellDataType[0];

      switch(mainCellDataType){
        case "map" : return getMapValueForRecordMapStrip(props.stripValueMap,cellDbKey)
      }


      function getMapValueForRecordMapStrip(stripValueMap,cellDbKeyCompStr){
        const realisedCellDbKeyComps =  cellDbKeyCompStr.split(".").map((it)=> it[0]=="$" ? stripValueMap[it.slice(1)] : it);
        console.log(realisedCellDbKeyComps)
        console.log(realisedCellDbKeyComps.reduce((ac,cv)=> ac[cv],stripValueMap))
        return realisedCellDbKeyComps.reduce((ac,cv)=> ac[cv],stripValueMap)
      }


    }else{ // for simple data types
      const initCellValue = stripValueMap[cellDbKey]
      switch(cellDataType){
        case "status":  return initCellValue ? statusLabels[statusKeys.indexOf(initCellValue)] : "NA";
        case "dateTime": return initCellValue ? getDateTimeFromMySqlTimestampString(initCellValue) : "NA"
        case "date": return initCellValue ? getOnlyDateFromMySqlTimestampString(initCellValue) : "NA"
        case "slNo": return props.autoGenSlNoCond ? props.pageLimit * (props.pageNo-1) + props.slNo : initCellValue
        case "arrayData" : return initCellValue.join(", ")
        case "boolean" : return initCellValue == 0 ? "No" : "Yes";
        case "list": return Array.isArray(initCellValue) ? initCellValue.join(" , ") : initCellValue;
        default:return initCellValue
      }
    }

  }

  function getSlNo(propsObj){
    return props.pageLimit * (props.pageNo-1) + props.slNo
  }

  const stripColorClass = props.stripColor ? `bg-${props.stripColor}` : `bg-quaternaryLight`

  return (
    <div className={`title-strip grid w-11/12 mx-auto mt-1 ${props.schemeMap.stripGridColsSchemeMain} ${stripColorClass} rounded-sm border border-borderStd ${props.boldTextCond ? `font-bold` : ``}`}>
      <div className="flex-center border-r border-secondaryTransparent25">
        {getSlNo(props)}
      </div>
      <div className={` grid ${props.schemeMap.stripGridColsSchemeBody}`}>
      {props.schemeMap.cellDbKeys.map((cellDbKey,i) => {
                if(i!=0){
                return (
                  <div className="value-strip-unit text-center text-lightBackText">
                      {getMapRecordCellValue(props.stripValueMap,cellDbKey,props.schemeMap.cellDataTypes[i],props)}
                  </div>
                );
                }
              })}
              </div>

        
            
{/* 
      {props.schemeMap.cellDbKeys.map((cellDbKey,i) => {
        return (
          <div className="value-strip-unit text-center text-lightBackText">
            {getMapRecordCellValue(props.stripValueMap[cellDbKey],props.schemeMap.cellDataTypes[i],props)}
          </div>
        );
      })} */}
    </div>
  );
}

