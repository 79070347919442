import { React } from "react";

import { checkAndRenderInputElements } from "./HelperViewFns";


//@start
export default function InputElementsContModal(props) {

  console.log(props)

const rows = (props.layout=="versatile")?``:``
const cols = (props.imgRequired)?`grid-cols-2 w-cust-60rem`:`w-cust-30rem`


  return (
    <div className={`grid ${props.imgRequired ? `grid-cols-cust-2i gap-1` : ``} justify-items-center p-10 h-fit`}>
      <div className={`grid gap-2`}>
        {checkAndRenderInputElements(
            props.passDataObj,
            props.inpDataObj,
            props.inpChangeFn,
            props.changeValdCountFn,
            props.passDataObj.editStatus
          )}
        </div>
    </div>
  );

 
}
