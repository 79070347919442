import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { getCountFromServer, getDoc, getDocs, orderBy, serverTimestamp, writeBatch } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  collection,
  database,
  db,
  doc,
  generateIncrementNumArray,
  get,
  getDateObjFromDayOfYearAndYear,
  getDayOfTheYear,
  getTimeLabel,
  query,
  rbaseRef,
  where
} from "../Utils";
import BottomContainer from "./BottomContainer";
import FloatingButton from "./FloatingButton";
import InputDate from "./InputDate";
import TabContainer from "./TabContainer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = "DMSans";

export default function ChartCont4(props) {
  
  console.log(props.passMap.topic);

  const labels = ["January", "February", "March"];

  const data = {
    labels,
    datasets: [
      // {
      //   label: "Dataset 1",
      //   data: [0, 0, 0],
      //   borderColor: "rgb(255, 99, 132)",
      //   backgroundColor: "rgba(255, 99, 132, 0.5)",
      // }
    ],
  };
  const topicIndex = props.passMap.chartTopics.indexOf(props.passMap.topic);
  
  const averageOutputLabels  = ["Avg Job Time","Avg Wait Time","Deviation"]


  const [datasetKey, setDatasetKey] = useState("");
  const [chartTopic, setChartTopic] = useState(props.passMap.topic);
  const [compDataMap, setCompDataMap] = useState({});
  const [filValues, setFilValues] = useState([
    ...props.passMap.filValues[topicIndex]
  ]);
  const [chartReady,setChartReady]=useState(false)

  const [filCategories, setFilCategories] = useState([
    ...props.passMap.filCategories[topicIndex],
  ]);
  const [fil1Value, setFil1Value] = useState(
    props.passMap.filCategories[topicIndex][0]
  );
  const [fil2Value, setFil2Value] = useState(
    props.passMap.filValues[topicIndex][0][0]
  );
  const [fil3Value, setFil3Value] = useState(
    props.passMap.filValues[topicIndex][1][0]
  );
  const [fil4Value, setFil4Value] = useState(
    props.passMap.filValues[topicIndex][2][0]
  );
  const [filAValue, setFilAValue] = useState(
    props.passMap.filValues[topicIndex][3][0]
  );


  const [fil5aValue, setFil5aValue] = useState(
    "0000-00-00"
  );
  const [fil5bValue, setFil5bValue] = useState(
    "0000-00-00"
  );

  const [xAxisLabel,setXAxisLabel]=useState(props.passMap.axisLabels[topicIndex][0][0])
  const [yAxisLabel,setYAxisLabel]=useState(props.passMap.axisLabels[topicIndex][1][0])

  console.log(props.passMap)

  const options = {
    responsive: true,
    scales: {
      x: {
         title:{
          display:true,
          align:"centre",
          text:props.passMap.axisLabels[topicIndex][0][0],
          color:"#000000",
          font:{
            size:22,
            weight:"500",
        
          }
         },
      },
      y: {
        title:{
          display:true,
          align:"centre",
          text:props.passMap.axisLabels[topicIndex][1][0],
          color:"#000000",
          font:{
            size:22,
            weight:"500"
          }
        }
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };


  options.scales.x.title.text=xAxisLabel
  options.scales.y.title.text=yAxisLabel

  const [chartData, setChartData] = useState({ ...data });
  const [chartOptions, setChartOptions] = useState({ ...options });

  const [tabFocusIndex,setTabFocusIndex]=useState(0);

  useEffect(() => {
    initializePageWithData(
      compDataMap,
      fil2Value,
      fil3Value,
      fil4Value,
      filAValue,
      filValues
    );
  }, []);

  console.log(compDataMap);

  async function initializePageWithData(
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue,
    filValues
  ) {

    //``3

    //this step checks and gets dynamic filter values, example in case of employees where in the list is unknown and depends on the database 
    const filValuesMap = await checkUpdateAndReturnFilValueMapBasedOnFil3Value(fil3Value);

    const uFilValues = filValuesMap["updateReqd"] ? filValuesMap["filValues"] : filValues;
    const uFil3Value = filValuesMap["updateReqd"] ? filValuesMap["fil3Value"] : fil3Value;

    props.startSpinner()

    const statTopic = props.passMap.topic.toLowerCase()
    const specLabel =  props.passMap.specLabel

    const storeBranchRootPath = `storeBranches/${props.passMap.uid}`;
    const storeGenDetailsCollPath = `${storeBranchRootPath}/storeBranchGenDetails`
    const statTopicCollectionPath = `${storeGenDetailsCollPath}/statData/${statTopic}Stats`;



    let tMap = {...compDataMap}



    const fil2Values =  uFilValues[0]
    const fil3Values = uFilValues[1];
    const fil4Values = uFilValues[2];
    const filAValues = uFilValues[3];

    const fil4ChartLabelsColl = [...props.passMap.chartLabels[topicIndex]];

    const currentYearCond = fil2Value == fil2Values[0]
    let pastOrderFetchReqd = false;
    let statDataMapWriteReqd = false;
    let syncSlNoWriteReqd = false;

    const chartDataDbFetchAndWriteReqd = !(fil2Value in tMap);

    let dataSyncRefreshCond = false;


    if(chartDataDbFetchAndWriteReqd){

        const dbDoc = await getDoc(doc(db,`${storeGenDetailsCollPath}`,`statData`));

        const dataSyncLatestPastOrderSlNo = dbDoc.data()["dataSyncLatestPastOrderSlNoMap"][statTopic];
        const dataSyncRefreshRefDateTime = dbDoc.data()["dataSyncRefreshRefDateTimeMap"][statTopic];

        let selectedYearPastOrderLatestSlNo = null;

        let yearStatDataMapPresentCond = false;

        //this map is used to store the doc ids of each statDataMap so that it will be useful in updating the corresponding docs later
        //the template will be map = {"Hollow Radius":"hsdihadhsdihddiuhfdui"}
        let statDataDocIdMap = {};

        if(dataSyncLatestPastOrderSlNo!=0){

          const docCountCall = await getCountFromServer(query(collection(db,`${statTopicCollectionPath}`),where("year","==",fil2Value)));

            yearStatDataMapPresentCond = docCountCall.data().count > 0;

          }

        const noStatDataMapCond = dataSyncLatestPastOrderSlNo==0 || !yearStatDataMapPresentCond

        console.log(dataSyncLatestPastOrderSlNo,yearStatDataMapPresentCond)


        tMap = {
          [fil2Value]:{}
        }


    //A. creating a template map or getting the dataMap which doubles up as a template

      if(noStatDataMapCond){ 
        //for a new account, past orders have never been referred to get data
        //create a dataMap template

        tMap = getChart4DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values,filAValues);
        pastOrderFetchReqd = true;

      }else{

          //check if the selected year stat data doc is available
          const dbDocsColl = await getDocs(query(collection(db,`${statTopicCollectionPath}`),where("year","==",fil2Value)));

          const specValues = []

         const refreshRefDateTime = dbDocsColl.docs[0].data().refreshRefDateTime 
         dataSyncRefreshCond =  dataSyncRefreshRefDateTime > refreshRefDateTime


          dbDocsColl.docs.forEach((doc,i)=>{

            const specValue = doc.data()[specLabel];

            specValues.push(specValue);

              if(!dataSyncRefreshCond){ 
                //here the tMap is filled with data from the statDataMap, suppose dataSyncRefresh is asked for no data is collected

                const dataMap = JSON.parse(doc.data().data);

                tMap[fil2Value][specValue] = {...dataMap[fil2Value][specValue]} // tMap[2024]["Skate Sharpening"]

             }

 
             //the doc ids will be required even if dataRefresh is asked for because we want to write on the existing statDocs
             statDataDocIdMap[specValue] = {
              docId: doc.id,
              refreshRefDateTime:refreshRefDateTime
             }
            
          });

          console.log(fil3Values,specValues);

          if(dataSyncRefreshCond){
            //since for dataSyncRefresh we will need to re-fresh all data, hence we are assigning a template object
            tMap = getChart4DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values,filAValues);
          }else{

          //Making consideration for dynamic fil3Values Ex - Employees

          const templateNeededValues = [];

          for(const iFil3Value of fil3Values){

            const aFil3Value = iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[1] : iFil3Value.split("^")[0]; 

    

            if(!specValues.includes(aFil3Value)){
              templateNeededValues.push(iFil3Value);
            }

    

          }

          console.log(templateNeededValues)

          if(templateNeededValues.length>0){
            tMap = getChart4DataTemplateMap(tMap,fil2Value,templateNeededValues,fil4Values,filAValues);
          }

          }


          pastOrderFetchReqd = dataSyncRefreshCond || currentYearCond;

      }

      console.log(tMap)
      

      //B. Getting External Data if required and writing the updated data

      if(pastOrderFetchReqd){

        const querySyncSlNo = dataSyncRefreshCond ? 0 : currentYearCond ? dataSyncLatestPastOrderSlNo : 0;


        let collQuery = "";
        collQuery = query(collection(db,`${storeBranchRootPath}/pastOrders`),where("orderYear","==",fil2Value),where("latestRecordSlNo",">",querySyncSlNo),orderBy("latestRecordSlNo","desc"))

      //getting content from past orders
      const pastOrderDocColl = await getDocs(collQuery);

      statDataMapWriteReqd = !pastOrderDocColl.empty

      if(statDataMapWriteReqd){
          //Functions : 1) Adding the pastOrder data to base tMap
          //2) Update the stat skate datamap on db
          //3) Update the sync no on db

          selectedYearPastOrderLatestSlNo = pastOrderDocColl.docs[0].data().latestRecordSlNo;

          syncSlNoWriteReqd = selectedYearPastOrderLatestSlNo > dataSyncLatestPastOrderSlNo
              
          tMap = extractAndAddDataToChart4Map(props,{...tMap},topicIndex,pastOrderDocColl,fil2Value,fil3Values,fil4Values,filAValues,querySyncSlNo);

          console.log(tMap)

          const batch = writeBatch(db);

          for(const specValue of fil3Values){

            //for clause 1
              const mSpecValue = specValue.split("^").length > 1 ? specValue.split("^")[1] : specValue;

              const dataMap = {
                [fil2Value]:{
                  [mSpecValue]:tMap[fil2Value][mSpecValue]
                }
              }

              const uploadMap = {
                year:fil2Value,
                [specLabel]:mSpecValue,
                data:JSON.stringify(dataMap),
           
              }

              const docUpdateAndNotCreateCond = !noStatDataMapCond || specValue in statDataDocIdMap;
                
              const dynamicKeyConsiderationCond = mSpecValue in statDataDocIdMap; // Example if a new employee is added then a new document has to be created
          
              if(docUpdateAndNotCreateCond && dynamicKeyConsiderationCond){
                batch.update(doc(db,statTopicCollectionPath,statDataDocIdMap[mSpecValue]["docId"]),{...uploadMap,refreshRefDateTime:dataSyncRefreshRefDateTime,docModificationDate:serverTimestamp()})
              }else{
                batch.set(doc(collection(db,statTopicCollectionPath)),{...uploadMap,refreshRefDateTime:dataSyncRefreshRefDateTime,docCreationDate:serverTimestamp()})
              }
 
          }

       
          if(syncSlNoWriteReqd){
            batch.update(doc(db,`${storeGenDetailsCollPath}`,'statData'),{[`dataSyncLatestPastOrderSlNoMap.${statTopic.toLowerCase()}`]:selectedYearPastOrderLatestSlNo})
      }

          batch.commit()
      }

    }

    }
    
  
    console.log(tMap)
    updateChart(tMap, fil2Value, uFil3Value, fil4Value, filAValue,uFilValues);
    
    setCompDataMap({...tMap})

    setChartReady(true)
    props.stopSpinner()



    //setChartData(tMap)


    function extractAndAddDataToChart4Map(props,map,topicIndex,pastOrderDocColl,fil2Value,fil3Values,fil4Values,filAValues,querySyncSlNo){
      //2: getting refValues
      pastOrderDocColl.docs.forEach((doc, i) => {
        //putting this condition because to factor for the fact to only extract part of the records (new records) from the past order doc
        //this happens when an updated StatMap has to update itself when a new past order record is created 
   

          const valueSeqs = doc.data().stringSeqDatas;

          for(let i=valueSeqs.length-1;i>=0;i--){

            const recordSlNo = doc.data().recordSlNos[i];

            if(recordSlNo <= querySyncSlNo) { 
              //this is to save time and computing power, since we are iterating backward and we only need to extract the data of new past orders records
              //comparing the latestSyncSlNo withe the record sl no will only iterate over new past order records
              break;
            }
  
           // valueSeqs.forEach((valueSeq, i) => {
                  const valueSeq = valueSeqs[i]
                  const valueSeqSegmentColl = valueSeq.split("~").map((it)=>it.split("^"));      
          
                  const filRefDbKeys = props.passMap.filRefDbKeys[topicIndex]; //Ex- "orderCompletionDateTime"
                  const valueSeqDbKeys = props.passMap.valueSeqDbKeys;
                  const refValues = [];
          
          
                  filRefDbKeys.forEach((filRefKey, j) => {
                    if (j != 3) {
          
                      const value = filRefKey===null ? fil3Value : findAndGetRefValueFromDataSeq(valueSeqSegmentColl,valueSeqDbKeys,filRefKey)            
                      refValues.push(value)
        
                    } else {
                      // if it is a variable key selection filter ex - Avg Wait Time, Avg Job Time etc
                      const filRefDbKeys = [...filRefKey];
                      const tArr = [];
                      filRefDbKeys.forEach((filRefKey) => {
        
                        tArr.push(findAndGetRefValueFromDataSeq(valueSeqSegmentColl,valueSeqDbKeys,filRefKey));
              
                      });
                      refValues.push(tArr);
                    }
                  });

                  ///refValues [644263874738,"Skate Sharpening",3732623678236,[7834278943,732849789324,987234879342]]
          
          
                //3 : Filling the Chart Data Map based on conditional
                  if (fil2Value == getCompRefValue(refValues[0], fil1Value)) {
       
                      const fil3Entry = refValues[1];

                        
                        for(let k=0; k<filAValues.length; k++){

                          const filAValue = filAValues[k];
                          let filAEntry = refValues[3][k];

                          console.log(filAEntry)
                          
                          if(!filAEntry){
                            //exiting from the iteration since the ultimate result is to calculate average, null values are not required
                            continue;
                          }

                          filAEntry = getTimeChartComputedValues(filAValue,filAEntry)

                          fil4Values.forEach((fil4Value, m) => {
                        
                            //Inserting Values for all fil4Labels (ex- hour, day, month) to the final child array which will be the data for the chart
                            const fil4Entry = getCompRefValue(refValues[2], fil4Value);
                         
                            const fil4EntryIndex = fil4ChartLabelsColl[m].indexOf(fil4Entry); // ex - index of Jan in Months, 5 in 365 days

  //``1
                            if(averageOutputLabels.includes(filAValue)){ // for output types like average
                              
                              const entryArray = [...tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                                fil4EntryIndex
                              ]];

                            const oldAvgVal = entryArray[0];
                            const oldAvgSetCount = entryArray[1];
                            const newAvgSetCount = oldAvgSetCount + 1;

                            const newAvgVal = ((oldAvgVal * oldAvgSetCount) + filAEntry) / newAvgSetCount
                            const avgRefArray = [newAvgVal,newAvgSetCount];

                            //item array
                               tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                                 fil4EntryIndex
                               ] = avgRefArray; //Adding each element so that future computation can be done
                               tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                                fil4EntryIndex
                              ] = avgRefArray; //Adding each element so that future computation can be done

                            // const itemArray = tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][fil4EntryIndex]

                            //value array
                              // map[fil2Value][fil3Entry][filAValue][fil4Value][fil4EntryIndex] = newAvgVal

                            }else{ // for output types like count
    
                              tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                                fil4EntryIndex
                              ] += 1;
                              tMap[fil2Value]["All"][filAValue][`${fil4Value}Items`][
                                fil4EntryIndex
                              ] += 1;

                            }
        
                          });

                        }

         
                    
            
          
          
                 
          
                  }
        
              
  
          //});
          }


      });
      return map;
    };

    function getTimeChartComputedValues(labelName,refValue){

      switch(labelName){
        case "Deviation":{
          console.log(refValue)
          const refValues = refValue.split("^");
          const orderJobStartToJobEndDuration = refValues[0]
          const orderEstdCompletionTime = refValues[1]

          console.log(parseFloat(orderJobStartToJobEndDuration),parseFloat(orderEstdCompletionTime))
  
          return parseFloat(orderJobStartToJobEndDuration) - parseFloat(orderEstdCompletionTime);
        }
        default: return parseFloat(refValue)
  
      }
  
    }
  
  
    function getChart4DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values,filAValues){
  
      const map = {...tMap};
  
      // if (!Object.keys(tMap).includes(fil2Value)) {
      // map[fil2Value] = {};
    
  
      console.log(fil3Values)
  
      //STEP 1 : Creating Scheme Map for Chart Data
      fil3Values.forEach((iFil3Value) => {


        //For clause 1
        const aFil3Value =
          iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[1] : iFil3Value.split("^")[0];  


  
        map[fil2Value][aFil3Value] = {}; //Eg map[2022][Skate Sharpening]={}
  
  
        filAValues.forEach((filAValue) => {
          map[fil2Value][aFil3Value][filAValue] = {};
  
  
          fil4Values.forEach((fil4Value, i) => {
            const labelsForFil4 = props.passMap.chartLabels[topicIndex][i];
   //``2
   
            // map[fil2Value][aFil3Value][filAValue][fil4Value] = [...zeroAvgArr];

            if(averageOutputLabels.includes(filAValue)){ //for average
              const zeroItArr = labelsForFil4.map((it) => {return [0,0];});
              map[fil2Value][aFil3Value][filAValue][`${fil4Value}Items`] = [...zeroItArr]; //Eg map[2022][Skate Sharpening][Hour]=[[0,0],[0,0]]
            }else{//for count
               const zeroArr = labelsForFil4.map((it) => {return 0;});
               map[fil2Value][aFil3Value][filAValue][`${fil4Value}Items`] = [...zeroArr]; //Eg map[2022][Skate Sharpening][Hour]=[0,0,0,0,0]
            }
  
          });
        });
      });
  
      console.log(map)
      return map;
    }




  }




  function findAndGetRefValueFromDataSeq(seqValuesColl,seqKeysColl,filRefKey){

    console.log(seqValuesColl)

        if(Array.isArray(filRefKey)){

                const values = []

                const sFilRefKeys = [...filRefKey]

                //these are for old past order records where the skate info was not added
                if(seqKeysColl.length == 1){

                  sFilRefKeys.forEach((key,i)=>{
                      const index = seqKeysColl[0].indexOf(key);
                      values.push(seqValuesColl[0][index])
                  });

                  return values.join("^")

                  // const index = seqKeysColl[0].indexOf(filRefKey)
                  // return seqValuesColl[0][index]

                }else if(seqKeysColl.length > 1){
                  //for new past orders which have skate info
                //seqKeysColl ex = [["orderStart","orderCurrency","orderPersonName"],["skateHollow","skateProfile"]
             
                  sFilRefKeys.forEach((key)=>{

                    if(seqKeysColl[0].includes(key)){
                      const indexI = seqKeysColl[0].indexOf(key)
                      values.push(seqValuesColl[0][indexI])
                      //return seqValuesColl[0][indexI]
                    }else{
                      for(let i=1;i<seqKeysColl.length;i++){ // starts from index = 1 of the seqKeysColl
                        if(seqKeysColl[i].includes(key)){
                          const indexJ = seqKeysColl[i].indexOf(key);

                          //checking below if the values collection has the index that is in iteration to avoid out of index error
                          if(seqValuesColl.length > i && seqValuesColl[i].length > indexJ){
                            values.push(seqValuesColl[i][indexJ])
                            //return seqValuesColl[i][indexJ]
                          }else{
                            values.push(null)
                          // return null
                          }
                        }
                      }
                    }
                  })
                }

                return values.join("^")


          }else{ //key is a string

            if(seqKeysColl.length == 1){

              const index = seqKeysColl[0].indexOf(filRefKey)
              return seqValuesColl[0][index]

            }else if(seqKeysColl.length > 1){
              if(seqKeysColl[0].includes(filRefKey)){
                const indexI = seqKeysColl[0].indexOf(filRefKey)
                return seqValuesColl[0][indexI]
              }else{
                for(let i=1;i<seqKeysColl.length;i++){
                  if(seqKeysColl[i].includes(filRefKey)){
                    const indexJ = seqKeysColl[i].indexOf(filRefKey);
                    if(seqValuesColl.length > i && seqValuesColl[i].length > indexJ){
                      return seqValuesColl[i][indexJ]
                    }else{
                      return null
                    }
                  }
                }
              }
            }

           }


  }

  function findAndGetRefValueFromDataSeq2(seqValuesColl,seqKeysColl,sFilRefKeys){
    const values = []

    if(seqKeysColl.length == 1){

      sFilRefKeys.forEach((key,i)=>{
          const index = seqKeysColl[0].indexOf(key);
          values.push(seqValuesColl[0][index])
      });

      return values.join("^")

      // const index = seqKeysColl[0].indexOf(filRefKey)
      // return seqValuesColl[0][index]

    }else if(seqKeysColl.length > 1){

      sFilRefKeys.forEach((key,i)=>{

        if(seqKeysColl[0].includes(key)){
          const indexI = seqKeysColl[0].indexOf(key)
          values.push(seqValuesColl[0][indexI])
          //return seqValuesColl[0][indexI]
        }else{
          for(let i=1;i<seqKeysColl.length;i++){
            if(seqKeysColl[i].includes(key)){
              const indexJ = seqKeysColl[i].indexOf(key);
              if(seqValuesColl.length > i && seqValuesColl[i].length > indexJ){
                values.push(seqValuesColl[i][indexJ])
                //return seqValuesColl[i][indexJ]
              }else{
               // return null
              }
            }
          }
        }
      })
    }

    return values.join("^")
  }



  function tabClickHandler(selectedIndex,tabName,compDataMap, fil2Value, fil3Value, filAValue) {
    //setTabChanged(true);
    setTabFocusIndex(selectedIndex)

    options.scales.x.title.text = props.passMap.axisLabels[topicIndex][0][selectedIndex]


    //setCurTabName(tabName);
    fil4ValueChange(tabName, compDataMap, fil2Value, fil3Value, filAValue)
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  function updateChart(
    sCompDataMap,
    fil2Value,
    fil3Value,
    fil4Value,
    filAValue,
    filValues
  ) {
    const mIndex = filValues[2].indexOf(fil4Value);
    const labels = props.passMap.chartLabels[topicIndex][mIndex];


    //Setting Y Axis Label as the user may change the year when after selecting a FilAValue not at index 0 
    assignYAxisLabel(props.passMap,options,filAValue);



    //Setting Y Axis Label as the user may change the year when after selecting a FilAValue not at index 0 
    const selIndex =  props.passMap.filValues[topicIndex][3].indexOf(filAValue)
    options.scales.y.title.text = props.passMap.axisLabels[topicIndex][1][selIndex]
    

    const colors = ["#C73C3C","#3C3C3C","#C7C7C7","#007500"]

    let data = {}


    
    if(fil3Value=="All"){

      const fFilValues = [...filValues[1]];
      //if it is topic index 1, it means the fil3Values are dynamically generated - example employees. 
      //We cannot have a set values for employees and hence it has to fetch the database

      console.log(fFilValues)

      // fFilValues.pop();

      const datasets = [];

      fFilValues.forEach((value,i)=>{


        const map = {};
        const valueStr = value.toString();
       
        const fFil3Value = valueStr.split("^").length > 1 ? valueStr.split("^")[1] : valueStr
        const fFil3Label = valueStr.split("^").length > 1 ? valueStr.split("^")[0] : valueStr
       
        //console.log(valueStr.split("^"),valueStr.split("^").length,valueStr.split("^")[1],value,valueStrValComp,valueStrLabelComp)

        const chartValues = getChartValuesBasedOnOutput(filAValue,sCompDataMap[fil2Value][fFil3Value][filAValue][`${fil4Value}Items`])

        map["label"] = fFil3Label;
        map["data"] = chartValues;
        map["borderColor"] = colors[i]
        map["backgroundColor"] = colors[i]

        datasets.push(map);
      })

      data={
        labels: labels,
        datasets: datasets
      }


    }else{

    // for clause 1
    const fFil3Value = fil3Value.split("^").length > 1 ? fil3Value.split("^")[1] : fil3Value
    const fFil3Label = fil3Value.split("^").length > 1 ? fil3Value.split("^")[0] : fil3Value 

    console.log(sCompDataMap,fil2Value,fFil3Value,filAValue)

    const chartValues = getChartValuesBasedOnOutput(filAValue,sCompDataMap[fil2Value][fFil3Value][filAValue][`${fil4Value}Items`]);

    
    data = {
      labels: labels,
      datasets: [
        {
          label: fFil3Label,
          data: chartValues,
          borderColor: "#C73C3C",
          backgroundColor: "#C73C3C",
        },
      ],
    };

  }


    console.log(data);

    // setDatasetKey(`${fil2Value}-1`);
    setChartOptions({ ...options });

    setChartData({ ...data });
  }

  function updateChartByDateRange(sCompDataMap, fil2Value, fil3Value,filAValue, dateRangeStartStr,dateRangeEndStr,filValues) {
    //const mIndex = props.passMap.filValues[topicIndex][2].indexOf(fil4Value);
   // const labels = props.passMap.chartLabels[topicIndex][mIndex];

   options.scales.x.title.text = "Date (Day/Month)";
   assignYAxisLabel(props.passMap,options,filAValue)

  //  console.log(sCompDataMap)
  //  console.log(fil2Value,fil3Value,filAValue)
  //  console.log(sCompDataMap[fil2Value][fil3Value]["Date Range"])

    //const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][fil3Value][filAValue]["Date Range"]);

    let data = {}
    const colors = ["#C73C3C","#3C3C3C","#C7C7C7"]

    if(fil3Value=="All"){

      const alLFil3Values = [...filValues[1]];

      alLFil3Values.pop();

      const datasets = [];

      alLFil3Values.forEach((iFil3Value,i)=>{
        const map = {};
       // const valueStr = iFil3Value.toString();

        const fFil3Value = iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[1] : iFil3Value
        const fFil3Label = iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[0] : iFil3Value 

   

        const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][fFil3Value][filAValue]["Date RangeItems"]);

        const chartValues = dateRangeLabelAndData["rangeLabelValues"].map((it)=>it[0]);

        data["labels"] = dateRangeLabelAndData["rangeLabels"]
        map["label"] = fFil3Label;
        map["data"] = chartValues;
        map["borderColor"] = colors[i]
        map["backgroundColor"] = colors[i]

        datasets.push(map);
      })

     data["datasets"] = [...datasets]


    }else{

      console.log(fil2Value,fil3Value,filAValue)

      const fFil3Value = fil3Value.split("^").length > 1 ? fil3Value.split("^")[1] : fil3Value
      const fFil3Label = fil3Value.split("^").length > 1 ? fil3Value.split("^")[0] : fil3Value 

      const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][fFil3Value][filAValue]["Date RangeItems"]);

      const chartValues = getChartValuesBasedOnOutput(filAValue,dateRangeLabelAndData["rangeLabelValues"]);

    data = {
      labels: dateRangeLabelAndData["rangeLabels"],
      datasets: [
        {
          label: fFil3Label,
          data: chartValues,
          borderColor: "#C73C3C",
          backgroundColor: "#C73C3C",
        },
      ],
    };

  }


  
    setChartOptions({ ...options });

    setChartData({ ...data });
  }


  function getChartValuesBasedOnOutput(outputLabel,values){

    if(averageOutputLabels.includes(outputLabel)){
      return values.map((it)=>it[0]);
    }else{
      return values;
    }
  }




  function getDateRangeLabelAndDataMap(startDate,endDate,chartValues){

    console.log(chartValues)
    console.log(startDate,endDate)

    const rMap={};

    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    console.log(date2.getFullYear())
    console.log(date1.getFullYear())

    if(isNaN(date1.getFullYear())){
      date1.setFullYear(date2.getFullYear())
      date1.setMonth(0);
      date1.setDate(1);
    }else if(isNaN(date2.getFullYear())){
      date2.setFullYear(date1.getFullYear())
      date2.setMonth(11);
      date2.setDate(31);
    }

    console.log(date1,date2)

    const date1Map = {};
    const date2Map = {};

    date1Map["dayOfTheYear"] = getDayOfTheYear(date1);
    date2Map["dayOfTheYear"] = getDayOfTheYear(date2);


    const startIndex =  date1Map["dayOfTheYear"]-1; //Exact Index as in Array.slice the start index is inclusive. Also as year array starts from 1 and not 0, so day 1 will have an index of 1-1=0;
    const endIndex = date2Map["dayOfTheYear"]; //End Index in Array.slice is exclusive.

   console.log(chartValues)

    rMap["rangeLabelValues"] = chartValues.slice(startIndex,endIndex);
    rMap["rangeLabels"] = getDateRangeLabels(date1,date2);

    function getDateRangeLabels(date1,date2){

    const date1Map = {};
    const date2Map = {};

      date1Map["dayOfTheYear"] = getDayOfTheYear(date1);
      date2Map["dayOfTheYear"] = getDayOfTheYear(date2);
      // date1Map["month"] = getMonth(date1);
      // date2Map["month"] = getMonth(date2);

      const rangeIncrements = generateIncrementNumArray(date1Map["dayOfTheYear"],date2Map["dayOfTheYear"]);

      const arr = [...rangeIncrements]

      const arr1 = arr.map((it,i)=>
        getValue(it,date1.getFullYear())
    )

    console.log(arr1)

    function getValue(dayOfYear,year){        
    const dateObj = getDateObjFromDayOfYearAndYear(dayOfYear,year)
    console.log(dateObj)
    const monthNo = dateObj.getMonth()+1;
    const dayOfMonth = dateObj.getDate();
    console.log(`${dayOfMonth}/${monthNo}`)
    return `${dayOfMonth}/${monthNo}`
    }

      return arr1;


    }


    return rMap



  }

  function fil5aValueChange(recFil5aValue,fil5bValue, compDataMap, fil2Value, fil3Value,filAValue) {
    console.log(recFil5aValue)
    console.log(new Date(recFil5aValue))
    setFil5aValue(recFil5aValue);
    console.log(compDataMap);
    updateChartByDateRange(compDataMap, fil2Value, fil3Value,filAValue,recFil5aValue,fil5bValue,filValues,filValues);
  }

  function fil5bValueChange(fil5aValue,recFil5bValue, compDataMap, fil2Value, fil3Value,filAValue) {
    setFil5bValue(recFil5bValue);

    updateChartByDateRange(compDataMap, fil2Value, fil3Value,filAValue, fil5aValue,recFil5bValue,filValues);
  }

  function fil2ValueChange(e, compDataMap, fil3Value, fil4Value, filAValue,filValues) {



    const recFil2Value =
      e.target.value == "All" ? e.target.value : parseInt(e.target.value);
    setFil2Value(recFil2Value);

    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");

    if (recFil2Value != "All") {
      initializePageWithData(
        compDataMap,
        recFil2Value,
        fil3Value,
        fil4Value,
        filAValue,
        filValues
      );
    } else {
      setChartReady(true)
      props.stopSpinner()
      updateChart(compDataMap, recFil2Value, fil3Value, fil4Value, filAValue,filValues);
    }
    console.log(e.target.value);
  }

  function fil3ValueChange(e, compDataMap, fil2Value, fil4Value, filAValue,filValues) {



    const recFil3Value = e.target.value;
    setFil3Value(recFil3Value);


    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");


    setChartReady(true)
    props.stopSpinner()
    updateChart(compDataMap, fil2Value, recFil3Value, fil4Value, filAValue,filValues,filValues);
  }

  function filAValueChange(e, compDataMap, fil2Value, fil3Value) {
    const recFilAValue = e.target.value;
    const selIndex =  props.passMap.filValues[topicIndex][3].indexOf(recFilAValue)
    options.scales.y.title.text = props.passMap.axisLabels[topicIndex][1][selIndex]

    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");

    setFilAValue(recFilAValue);
    updateChart(compDataMap, fil2Value, fil3Value, fil4Value, recFilAValue,filValues,filValues);
  }

  function fil4ValueChange(
    recFil4Value,
    compDataMap,
    fil2Value,
    fil3Value,
    filAValue
  ) {
    



    setFil4Value(recFil4Value);
    setChartReady(true)
    props.stopSpinner()
    updateChart(compDataMap, fil2Value, fil3Value, recFil4Value, filAValue,filValues);
  }

  const tabNames=props.passMap.filValues[topicIndex][2]

    //To prevent date range from appearing when the year is selected as All
    function getTabNames(tabNames,fil2Value){
      if(fil2Value=="All"){
  
        const index = tabNames.indexOf("Date Range");
        tabNames.splice(index,1);
      
      }
        return tabNames;
      
    }


    function assignYAxisLabel(passMap,chartOptions,filAValue){
    
      const selIndex =  passMap.filValues[topicIndex][3].indexOf(filAValue)
      chartOptions.scales.y.title.text = passMap.axisLabels[topicIndex][1][selIndex]

  }

  return (
    <div className="grid p-cust-topHead pb-28">
      <div className="flex justify-center items-center gap-16">
        <div className="flex gap-4 p-8">
          <div>{filCategories[0]}</div>
          <select
            key={`${props.passMap.topic}-1`}
            onChange={(e) =>
              fil2ValueChange(e, compDataMap, fil3Value, fil4Value, filAValue,filValues)
            }
          >
            {filValues[0].map((optionName) => {
              return <option value={optionName}>{optionName}</option>;
            })}
          </select>

        </div>
        <div className="flex gap-4">
            <div>{filCategories[1]}</div>
            <select
              key={`${props.passMap.topic}-2`}
              onChange={(e) =>
                fil3ValueChange(e, compDataMap, fil2Value, fil4Value, filAValue,filValues)
              }
            >
              {filValues[1].map((optionName) => {
                return (
                  <option
                    value={
                      optionName
                      // optionName.split("^").length == 2
                      //   ? optionName.split("^")[1]
                      //   : optionName.split("^")[0]
                    }
                  >
                    {optionName.split("^")[0]}
                  </option>
                );
              })}
            </select>
        </div>

          <div className="flex gap-4">
              <div>{filCategories[2]}</div>
              <select
                key={`${props.passMap.topic}-3`}
                onChange={(e) =>
                  filAValueChange(e, compDataMap, fil2Value, fil3Value, fil4Value)
                }
              >
                {filValues[3].map((optionName) => {
                  return <option value={optionName}>{optionName}</option>;
                })}
              </select>
        </div>
        <div className="flex gap-4">
        <TabContainer
          // tabNameList={props.passMap.inputCategories}
          tabNameList={getTabNames(tabNames,fil2Value)}
          tabFocusIndex={tabFocusIndex}
          tabClickHandler={(focusIndex) => tabClickHandler(focusIndex,tabNames[focusIndex],compDataMap, fil2Value, fil3Value, filAValue)}
        />

{fil4Value=="Date Range" && fil2Value != "All" &&
        <div>
            <InputDate
              key={`a`} //passDataObj has all data from props object
              inputName={""}
              inputValue={fil5aValue}
              selYearValue={fil2Value}
              onChangeFn={(e) => fil5aValueChange(e.target.value,fil5bValue,compDataMap, fil2Value, fil3Value, filAValue)}
            />
        <InputDate
              key={`b`} //passDataObj has all data from props object
              inputName={""}
              inputValue={fil5bValue}
              selYearValue={fil2Value}
           
              onChangeFn={(e) => fil5bValueChange(fil5aValue,e.target.value,compDataMap, fil2Value, fil3Value, filAValue)}
            />
        </div>

        }


        </div>

      </div>
      <div className="w-9/12 mx-auto items-center justify-center">
      {chartReady&&
        <Line options={{ ...chartOptions }} data={{ ...chartData }} />
      }
      </div>
      <FloatingButton btnLabel="Back" positionRight="10" positionTop="20" btnClickHandler={()=>props.backBtnClickHandler()} />
      {/* <div className="absolute w-full h-24 bottom-0 flex justify-center items-center">
        {bottomContainer}
        </div> */}
    </div>
  );

  async function checkUpdateAndReturnFilValueMapBasedOnFil3Value(value){

    const map = {}
  
    if(value=="DB Fetch"){
      const employeesRef = rbaseRef(database, `employees/${props.passMap.uid}`);
  
      const empData = await get(employeesRef);
  
      const data = { ...empData.val() };
      const tArr = [];
      Object.keys(data).forEach((key) => {
        tArr.push(`${data[key]["name"]}^${key}`);
      });
  
      const tFil3Values = [...tArr];
      tFil3Values.push("All");
  
      console.log(tFil3Values);
  
      const tFilValues = [...filValues];
      tFilValues.splice(1, 1, tFil3Values);
      setFilValues(tFilValues);
  
      setFil3Value(tFilValues[1][0]);

      map["updateReqd"] = true
      map["filValues"] = tFilValues;
      map["fil3Value"] = tFilValues[1][0]
  
      return map;
    }else{
      map["updateReqd"] = false;
      return map;
    }
  
  }
}

function getCompRefValue(value, filValue) {
  switch (filValue) {
    case "Year": {
      return new Date(parseInt(value) * 1000).getFullYear();
    }
    case "Month": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getMonth(), "Month");
    }
    case "Day": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getDay(), "Day");
    }
    case "Hour": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getHours(), "Hour");
    }
    case "Date Range": {
      return getTimeLabel(getDayOfTheYear(new Date(parseInt(value) * 1000)), filValue);
    }
  }
}


// async function checkUpdateAndSetDBFetchValues(indexToCheck,filValues){

//   const filValue = filValues[indexToCheck][0];

//   if(filValue=="DB Fetch"){
//         const employeesRef = rbaseRef(database, `employees/${props.passMap.uid}`);

//         const empData = await get(employeesRef);

//         const data = { ...empData.val() };
//         const tArr = [];
//         Object.keys(data).forEach((key) => {
//           tArr.push(`${data[key]["name"]}^${key}`);
//         });

//         const tFil3Values = [...tArr];
//         tFil3Values.push("All");

//         console.log(tFil3Values);

//         const tFilValues = [...filValues];
//         tFilValues.splice(1, indexToCheck, tFil3Values);
//         setFilValues(tFilValues);

//         setFil3Value(tFilValues[indexToCheck][0]);

//         return tFilValues
//   }

//   return filValues

// }




/*
Clauses
1) Two value types for selections, one for display and one for corelation. example for employee we will have Nathan^ueur283212jd,so we have a display Name which could change in the course of time but the unique id wont change
*/

