import { React } from "react";
import { checkAndRenderInputElements2 } from "./HelperViewFns";



//@start
export default function InputElementsCont2(props) {



const rows = (props.layout=="versatile")?``:``
const cols = (props.imgRequired || props.passDataObj.elemTypes.length > 6)?`grid-cols-2 w-cust-60rem`:`w-cust-30rem`


  return (
    <div className={`grid ${props.imgRequired ? `grid-cols-cust-2i gap-1` : ``} justify-items-center p-10 h-fit`}>
      <div className={`grid gap-2 ${rows} ${cols}`}>
        {checkAndRenderInputElements2(
            props.passDataObj,
            props.inpDataObj,
            props.inpChangeFn,
            props.changeValdCountFn
          )}
        </div>
    </div>
  );

 
}
