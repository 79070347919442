import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  createInvoiceMap,
  db,
  editInvoiceAmountInputSchemeMap,
  functions,
  generateInvoice,
  getInvoiceAmountFromJobCount,
  getMonthYear,
  httpsCallable,
  limit,
  orderBy,
  styleProxyMap,
  toSentenceCase
} from "../Utils";
import BottomContainer from "./BottomContainer";
import FilterInput from "./FilterInput";
import LoadSpinner from "./LoadSpinner";
import ModalBoxInvoice from "./ModalBoxInvoice";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordBtnStrip2 from "./RecordBtnStrip2";

const pageLimit = 12;


//@start
export default function StripListFilterBtnCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

 

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbKeys);
  console.log(props.passMap);


  const [compListDataMapColl, setCompListDataMapColl] = useState([]);
  const [compFilListDataMapColl, setCompFilListDataMapColl] = useState([]);

  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  //const [stripValueMapColl, setStripValueMapColl] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});
  const [pageStripValueMapColl, setPageStripValueMapColl] = useState([]);
  const [partyUidUnpaidInvoiceDocIdCorrelationMap, setPartyUidUnpaidInvoiceDocIdCorrelationMap] = useState({});
  const [slNoArray, setSlNoArray] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");
  const [isLoading,setIsLoading] = useState(true);
  const [showSpinner,setShowSpinner] = useState(false)
  const [showModalBoxInvoice,setShowModalBoxInvoice] = useState(false)
  const [invoiceDataMap,setInvoiceDataMap] = useState({})
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});


  useEffect(() => {
  
    initializePageWithData();
  }, []);

  async function initializePageWithData() {


    const collQuery = query(
      collection(db, `admin/accounts/unpaidInvoices`),
      where("docCreationDate", "!=", false),
      orderBy("docCreationDate", "desc")
    );

    const collQuery2 = query(
      collection(db, `admin/accounts/balances`),
      where("docCreationDate", "!=", false),
      orderBy("docCreationDate", "desc")
    );

    const unpaidInvoiceDocs = await getDocs(collQuery);

    if (props.listTopic == "balances") {
      const balanceDocs = await getDocs(collQuery2);
      if (balanceDocs.empty) {
        setPageStripValueMapColl([]);
        setIsLoading(false)
      } else {
         setCompListDataMapColl(
          await extractAndSetValuesMapColl(balanceDocs, unpaidInvoiceDocs)
        );
      }
    } else if(props.listTopic=="unpaidInvoiceAdmin") {
      if (unpaidInvoiceDocs.empty) {

        setPageStripValueMapColl([]);
        setIsLoading(false)
      } else {
        setCompListDataMapColl(extractAndSetValuesMapColl2(unpaidInvoiceDocs));
      }
    }
  }

  //For Unpaid Invoice
  function extractAndSetValuesMapColl2(sUnpaidInvoiceDocs) {


    const tArray=[]


    let nMap = {};
    nMap["count"] = 0;



    const valueSeqDbKeys = props.passMap.valueSeqDbKeys;

  
    sUnpaidInvoiceDocs.docs.forEach((doc) => {

      const stringSeqDatas = doc.data().stringSeqDatas;

      const partyUids = [];
      const unpaidInvoiceDocIds = [];

      const unpaidInvDocIdUidMap = {}; //This Map basically has two relation arrays of uid and docId, so it basically says
      //For example map.uids:[uid1, uid2, uid1] and map.docIds:[docId1,docId1,docId2]
      //This will go into the strip ui row, so when I click the ui row, i get access of this map. 
      //This is required inorder to delete party data from the search arrays when marking invoices as paid.

      stringSeqDatas.forEach((stringSeq) => {
        const tMap = {}; //each sequence data will be in the form of a map
        nMap["count"] += 1;
        const partyUidIndex=valueSeqDbKeys.indexOf("invoicePartyUid")

        //Creating a Party UID and Unpaid Invoice Doc Id Array to facilitate invoice record transfer/deletion later on.
        const stringSeqArray = stringSeq.split("^");
        
        partyUids.push(stringSeqArray[partyUidIndex]);
        unpaidInvoiceDocIds.push(doc.ref.id);

        //Assigning the uidDocIdMap
        unpaidInvDocIdUidMap["unpInvoiceDocIds"] = [...unpaidInvoiceDocIds];
        unpaidInvDocIdUidMap["partyUids"] = [...partyUids];

        //Converting the String Seq into a map and this will be the strip values
        valueSeqDbKeys.forEach((key, i) => {
          tMap[key] = "";
          tMap[key] = stringSeqArray[i];
        });

        tMap["stringSeqData"] = stringSeq;
        tMap["slNo"] = nMap["count"];
        tMap["unpInvoiceDocId"] = doc.ref.id;

        console.log(tMap);

        setPartyUidUnpaidInvoiceDocIdCorrelationMap({ ...unpaidInvDocIdUidMap });

        tArray.push(tMap);
      });
    });
    console.log(tArray);

    if(tArray.length==0){//if there are documents but no records in the unpaid invoice db docs then
      setIsLoading(false) // this will tell the app to stop loading and understand that there are no documents
    }

      if (tArray.length != 0) {
        displayPage(pageNo,tArray);
      }
  
      setRecordCount(tArray.length);

    
    return tArray;

  }



  async function extractAndSetValuesMapColl(sBalanceDocs, sInvoiceDocs) { //Fetches the entire strip values

    //Step : Initialization of Intiial Variables
    const dbNotations = ["partyUids", "partyIds", "partyNames"];
    const map = {};

    //to create arrayfields and insert in the database, it will help in firebase search later on.
    dbNotations.forEach((dbNotation) => {
      map[dbNotation] = [];
    });

    map["partyUnbldJobCounts"] = []; //To hold job counts of all stores
    map["partyUnbldJobDatas"]=[];
    map["partyTariffTypes"] = []; 
    map["partyTariffSeqs"] = []; 
    map["balanceDocsDocIds"]=[]

 
    //Step : Extracting Data From Balance Job Docs and Assiging to initial variabels 
    //await sBalanceDocs.docs.forEach(async (dbDoc) => {
    for(let dbDoc of sBalanceDocs.docs){
      dbNotations.forEach((dbNotation) => {
        map[dbNotation] = dbDoc.data()[dbNotation]; //assigning the respective db Arrays From Database Docuement
      });
      //await map.partyUids.forEach(async (partyUid) => {
        for(let partyUid of map.partyUids){ //map.partyUids is created in the above loop operation
          
        map["partyUnbldJobCounts"].push(dbDoc.data()[partyUid]["unbilledJobCount"]); //Pushing the job count into the array as they are in dbDoc[uid].jobCOunt
        map["partyUnbldJobDatas"].push(dbDoc.data()[partyUid])

        const tariffType= dbDoc.data()[partyUid]["tariffType"];
        map["partyTariffTypes"].push(tariffType)


        if(tariffType=="general"){
          // map["partyTariffSeqs"].push("njssuww")
         const adminGenDocRef = await getDoc(doc(db,"admin","accounts"));

         map["partyTariffSeqs"].push(adminGenDocRef.data().feeTariffStrSeqData)
         //map["partyTariffSeqs"].push("2x0.3^4x0.25^6x0.2^0x0.05")
         console.log(map["partyTariffSeqs"])

        }else{
         map["partyTariffSeqs"].push(dbDoc.data()[partyUid]["tariffStrSeq"])
        }
      
        map["balanceDocsDocIds"].push(dbDoc.ref.id)
      
      //});
      }
    //});
    }

    console.log("Point 2")
    console.log(map);

    setRecordCount(map["partyUids"].length);

    // End of Step : Extraction From Balance Docs

    // Start of Step : Exctracting Data from Unpaid Invoice Docs

    //Here we iterate through the Invoice Docs that we have fetched and passed to this function, extract
    //the stringSeqs, -split the data and put it in arrays named "invStripValuesColl", 

    const dbNotationsInvoice = [
      "invoiceNos",
      "invoiceIssueDates",
      "partyIds",
      "partyNames",
      "partyUids",
      "invoiceDescs",
      "invoiceAmts",
      "invoiceJobs",
      "invoiceDiscounts",
      "invoicePaymentDueDates",
    ];


    const uidIndex = dbNotationsInvoice.indexOf("partyUids"); 
    const dueDateIndex = dbNotationsInvoice.indexOf("invoicePaymentDueDates");
    const invoiceAmtIndex = dbNotationsInvoice.indexOf("invoiceAmts");
    const issueDateIndex = dbNotationsInvoice.indexOf("invoiceIssueDates");

    const map2 = {}; // map which holds data for each uid/branch 

    //Start of Step : Iterate through the Passed Unpaid Invoice Docs to extract teh data
    // await sInvoiceDocs.forEach(async (dbDoc) => {

      sInvoiceDocs.forEach((dbDoc)=>{
      const stringSeqs = dbDoc.data().stringSeqDatas;  //Contains all the data of a single unpaid invoice in a string
      // const partyUids = dbDoc.data().partyUids;
      // const keyNotations = ["invStripValuesColl","docIds"];

      if (stringSeqs.length == 0) { //Very rare cases this will occur, because ideally if there are no string seq there should be no doc
      } else {

        //iterating through each string sequence to extract data
        stringSeqs.forEach((stringSeq) => {
          const stringArray = stringSeq.split("^");
          const partyUid = stringArray[uidIndex];

          if (Object.keys(map2).indexOf(partyUid) == -1) { //Check if a new map has to be created. If map does not have store as the key, then create new map for it
            map2[partyUid] = {};
            map2[partyUid]["invStripValuesColl"] = [];
            map2[partyUid]["partyStripSeqDatas"] = [];
            map2[partyUid]["partyStripSeqDocIds"] = [];
          }
          map2[partyUid]["invStripValuesColl"].push(stringArray);
          map2[partyUid]["partyStripSeqDatas"].push(stringSeq);
          map2[partyUid]["partyStripSeqDocIds"].push(dbDoc.ref.id);
        });
      }
    })


    const stripValueMapColl = [];

    await map["partyUids"].forEach(async (partyUid, i) => {

      const tMap = {};
      tMap["partyUid"] = partyUid;
      tMap["dueBalance"] = 0;
      tMap["billedBalance"] = 0;
      tMap["unbilledJobCount"] = 0;
      tMap["totalDueBalance"] = 0;
      tMap["partyStripSeqDatas"] = [];
      tMap["partyStripSeqDocIds"] = [];

      if (Object.keys(map2).indexOf(partyUid) == -1) {
        tMap["latestBillDate"] = "NA";  //Since the 
      } else {
        tMap["latestBillDate"] =
          parseInt(map2[partyUid]["invStripValuesColl"][0][issueDateIndex]) /
          1000;

        tMap["partyStripSeqDatas"] = [...map2[partyUid]["partyStripSeqDatas"]];
        tMap["partyStripSeqDocIds"] = [
          ...map2[partyUid]["partyStripSeqDocIds"],
        ];

        //Iterating through a UID/Store Collected Data and Determining the Latest Bill Date/Billed Balance/Due balance
        map2[partyUid]["invStripValuesColl"].forEach((invStripValues) => {
          const todaysDate = new Date().getTime();
          const issueDate = parseInt(invStripValues[issueDateIndex])*1000;
          const dueDate = parseInt(invStripValues[dueDateIndex])*1000;

          console.log(`todaysDate = ${todaysDate}`)
          console.log(`dueDate = ${dueDate}`)

          // if (issueDate > tMap["latestBillDate"]) {
          //   tMap["latestBillDate"] = issueDate;
          // }

          tMap["latestBillDate"] = issueDate > tMap["latestBillDate"] ? issueDate/1000 : tMap["latestBillDate"];

          if (todaysDate > dueDate) {
            tMap["dueBalance"] += parseFloat(invStripValues[invoiceAmtIndex]);
          } else {
            tMap["billedBalance"] += parseFloat(invStripValues[invoiceAmtIndex]);
          }
        });

        tMap["totalDueBalance"] =
          parseFloat(tMap["billedBalance"]) + parseFloat(tMap["dueBalance"]);
      }
      tMap["billedBalance"]=tMap["billedBalance"].toFixed(2)
      tMap["dueBalance"]=tMap["dueBalance"].toFixed(2)
      tMap["totalDueBalance"] = tMap["totalDueBalance"].toFixed(2)

      tMap["unbilledJobCount"]=parseInt(map["partyUnbldJobCounts"][i])
      tMap["partyTariffType"]=map["partyTariffTypes"][i]
      tMap["partyTariffSeq"] = map["partyTariffSeqs"][i]
      tMap["balanceDocId"]=map["balanceDocsDocIds"][i];
      tMap["partyName"] = map["partyNames"][i];
      tMap["partyId"] = map["partyIds"][i];
        

      tMap["unbilledJobData"] = map["partyUnbldJobDatas"][i]

      console.log(tMap);

      stripValueMapColl.push(tMap);
    });

    console.log(stripValueMapColl);
    displayPage(pageNo, stripValueMapColl);
    return stripValueMapColl;
  }

  console.log(pageNo);

  function displayPage(sPageNo, sStripValueMapColl) {

    setPageNo(sPageNo);

    console.log(sStripValueMapColl);
    const latestSl = sStripValueMapColl.length;
    // const pageLimit = 3;

    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo  - 1) + 1;
    const endSlOfPage =
      pageLimit * sPageNo  > latestSl ? latestSl : pageLimit * sPageNo;

    assignStripValues(startSlOfPage, endSlOfPage, sStripValueMapColl);
  }

  function assignStripValues(startSlNo, endSlNo, sStripValueMapColl) {
    const rArray = [];

    console.log(sStripValueMapColl);


    const mIndexStart = startSlNo - 1;
    const mIndexEnd = endSlNo - 1;

    console.log(startSlNo,endSlNo);

    for (let y = mIndexStart; y <= mIndexEnd; y++) {
      const tMap = { ...sStripValueMapColl[y] };
      tMap["slNo"] = y + 1;

      rArray.push(tMap);
    }

    console.log("pageStripValueMapColl=",rArray);

    setPageStripValueMapColl(rArray);
    setIsLoading(false)
    props.stopSpinner()
  }


  async function fetchAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    stripValuesDataMap,
    pageNo
  ) {
    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
      ),
      where("slNos", "array-contains-any", slQueryArray)
    );
    

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setCompListDataMapColl(
      extractAndSetValuesMapColl(dbDocs, stripValuesDataMap, pageNo)
    );
  }




  function renderPagination(
    sPageLimit,
    sListValuesMapColl,
    sRecordCount,
    sPageNo
  ) {

    console.log(sListValuesMapColl)
    console.log(sRecordCount);

    const recordCount = sListValuesMapColl.length;
    const pageCount = Math.ceil(recordCount / sPageLimit);

    const rArray = [];

    console.log(sListValuesMapColl);

    for (let x = 1; x <= pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(x, sListValuesMapColl)}
          className={`p-cust-3 ${
            x == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  function renderFilterPagination(sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo) {
    const pageCount = Math.ceil(recordCount / sPageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayFiltPage(sRecordCount, x + 1, sListValuesCollMap)}
          className={`p-cust-3 ${
            x + 1 == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  async function displayPagesBasedOnFilter(sFilterName, sFilterValueMap) {
    setViewMode("filter");

    console.log("hellothere");

    const filterSchemeMap = { ...props.passMap.filterSchemeMap };

    const filterNameIndex = filterSchemeMap.filterNames.indexOf(sFilterName);
    const filterInputKeyNames =
      filterSchemeMap.filterInputKeys[filterNameIndex];

    const filterInputValue =
      sFilterName == "Store Branch Name"
        ? `${sFilterValueMap.storeBrandName}-${sFilterValueMap.storeBranchName}`
        : sFilterValueMap[filterSchemeMap.filterInputKeys[filterNameIndex][0]];

    const curFilterValMap = {
      filterName: sFilterName,
      filterDbKey: filterSchemeMap.filterDbKeys[filterNameIndex],
      filterInputKeys: filterSchemeMap.filterInputKeys[filterNameIndex],
      filterSeqIndex: filterSchemeMap.filterSeqIndices[filterNameIndex],
      filterInputValue: filterInputValue,
    };

    console.log(props.listTopic);

    const collQuery = query(
      collection(db, `admin/accounts/${props.listTopic}`),
      where(
        curFilterValMap.filterDbKey,
        "array-contains",
        curFilterValMap.filterInputValue
      )
    );

    const filtDbDocs = await getDocs(collQuery);

    console.log(filtDbDocs.size);
    setCompFilListDataMapColl(
      extractAndSetFilteredValuesMapColl(filtDbDocs, 1, curFilterValMap)
    );
  }

  function extractAndSetFilteredValuesMapColl(
    filtDbDocs,
    pageNo,
    sCurFilterValMap
  ) {
    const tArray = [];
    let nMap = {};

    nMap["count"] = 0;

    // const dbNotations = [
    //   "invoiceId","invoicePartyId","invoicePartyUid","invoicePartyName"
    //   ,"invoicePartyTariffSeqData","invoiceJobCount","invoiceSubTotalAmount",
    //   "invoiceDiscount","invoiceTaxDataSeq","invoiceTotalAmount","invoiceDesc","invoicePartyUnitNo",
    //   "invoicePartyStreetName",
    //   "invoicePartyCityName",
    //   "invoicePartyProvinceName",
    //   "invoicePartyCountryName",
    //   "invoicePartyPostalCode",
    //   "invoicePartyEmail",
    //   "invoicePartyContactNo",
    //   "invoicePartyContactPersonName",
    //   "invoicePartyContactPersonContactNo",
    //   "invoiceAdminUnitNo",
    //   "invoiceAdminStreetName",
    //   "invoiceAdminCityName",
    //   "invoiceAdminProvinceName",
    //   "invoiceAdminCountryName",
    //   "invoiceAdminPostalCode",
    //   "invoiceAdminEmail",
    //   "invoiceAdminContactNo",
    //   "invoiceAdminContactPersonName",
    //   "invoiceAdminContactPersonEmail",
    //   "invoiceAdminSalesPersonName",
    //   "invoicePaymentDueDate",
    //   "invoiceIssueDate"
    // ]

    const dbNotations = props.passMap.valueSeqDbKeys;

    filtDbDocs.docs.forEach((doc) => {
      const tSubArray = [];
      const stringSeqDatas = doc.data().stringSeqDatas;

      const uids = [];
      const docIds = [];
      const map = {}; //for docid and uid relation

      stringSeqDatas.forEach((stringSeq) => {
        const stringArray = stringSeq.split("^");

        if (
          stringArray[sCurFilterValMap.filterSeqIndex] ==
          sCurFilterValMap.filterInputValue
        ) {
          const tMap = {}; //each sequence data will be in the form of a map
          nMap["count"] += 1;

          //Creating a UID and DOC Id Array to facilitate invoice record transfer/deletion later on.
          const stringSeqArray = stringSeq.split("^");
          uids.push(stringSeqArray[dbNotations.indexOf("invoicePartyUid")]);
          docIds.push(doc.ref.id);

          //Converting the String Seq into a map
          dbNotations.forEach((dbNotation, i) => {
            tMap[dbNotation] = "";
            tMap[dbNotation] = stringSeqArray[i];
          });

          tMap["stringSeqData"] = stringSeq;
          tMap["slNo"] = nMap["count"];
          tMap["docId"] = doc.ref.id;

          map["docIds"] = [...docIds];
          map["uids"] = [...uids];

          console.log(tMap);
          console.log(map);

          setPartyUidUnpaidInvoiceDocIdCorrelationMap({ ...map });

          tArray.push(tMap);
        }
      });
    });
    console.log(tArray);

    if (tArray.length != 0) {
      displayFiltPage(pageNo, tArray);
    }

    return tArray;
  }

  function displayFiltPage(pageNo, valuesMapColl) {
    setPageNo(pageNo);
    console.log(pageNo);

    const rArray = [];

    //const size = valuesDataMap.stripValuesSeqs.length;
    const size = valuesMapColl.length;

    const startIndex =
      pageLimit * pageNo - pageLimit > size - 1
        ? size - 1
        : pageLimit * pageNo - pageLimit;
    const endIndex =
      startIndex + pageLimit - 1 > size - 1
        ? size - 1
        : startIndex + pageLimit - 1;

    console.log(startIndex, endIndex);

    if (startIndex == endIndex) {
      rArray.push(valuesMapColl[startIndex]);
    } else {
      for (let x = startIndex; x <= endIndex; x++) {
        rArray.push(valuesMapColl[x]);
      }
    }
    console.log([...rArray]);
    setPageStripValueMapColl([...rArray]);
  }

  async function btnClicked(data, correlationMap) {

    const map = data.stripValueMap;
    console.log("mainMap=",map)
    switch (data.btnType) {
      case "Invoice":
        {
          setShowSpinner(true)
          console.log(map)
     
          const todaysDate = new Date().getTime();
          const dueDate = todaysDate + 1000 * 60 * 60 * 24 * 15; //Days x Milliseconds per day

          //Step 1: Fetching the Store Branch Doc - For info on store
          const storeGenDetailDoc = await getDoc(doc(db, `storeBranches/${map["partyUid"]}`));

          const storeEmail = storeGenDetailDoc.data().storeBranchEmail;
          const storeSettings = storeGenDetailDoc.data().settings;
          const storeAddress = `${storeSettings.storeBranchUnitNo}, ${storeSettings.storeBranchStreetName}, ${storeSettings.storeBranchCityName}, ${storeSettings.storeBranchProvinceName}, ${storeSettings.storeBranchCountryName} - ${storeSettings.storeBranchPostalCode}`;
          const storeContactNo = storeSettings.storeBranchContactNo;
          const storeBranchContactPersonName=storeSettings.storeBranchContactPersonName;
          const storeBranchContactPersonContactNo=storeSettings.storeBranchContactPersonContactNo;

        //Step 2: Creation of the Server Call Payload - Adding details from the Strip Map and the 
        //general Info that we got from Step 1
          // const partyTariffSeq=(map["partyTariffType"]=="general")?props.passMap.feeTariffStrSeqData:map["partyTariffSeq"];
          const partyTariffSeq=map["partyTariffSeq"]

          const payload = {
            partyId: map["partyId"],
            partyUid: map["partyUid"],
            partyName: map["partyName"],
            partyTariffType:map["partyTariffType"],
            partyTariffSeq:partyTariffSeq,
            unbilledJobCount: map["unbilledJobCount"],
            invoiceBalanceDue: map["dueBalance"],
            invoiceSubject: "App Commission",
            invoicePartyAddress: storeAddress,
            invoicePartyEmail: storeEmail,
            invoicePartyContactNo: storeContactNo,
            invoicePartyContactPersonName: storeBranchContactPersonName,
            invoicePartyContactPersonContactNo: storeBranchContactPersonContactNo,
            balanceDocId:map["balanceDocId"]
          };

          //Step 3: Server Call
          const serverCall = httpsCallable(functions,"sendInvoice");
          const result = await serverCall(payload);

          const completionFn = () =>{
            props.backBtnClickHandler()
          }

          displayPrompt(result.data,"",completionFn);
          setShowSpinner(false)
          // props.backBtnClicked()


        }
        break;

      case "Billing":
        {
          setShowSpinner(true)
          console.log("Edit Started");

          console.log(editInvoiceAmountInputSchemeMap)

          const jobCount = map["unbilledJobCount"];
          const partyTariffSeqData = map["partyTariffSeq"];
          const invoiceAmount =  getInvoiceAmountFromJobCount(jobCount,partyTariffSeqData)

          const jobData = map["unbilledJobData"];

          const billingMonths = jobData.monthYears;

          function getInvoiceDataMapByMonths(jobData){
              const monthYears = jobData.monthYears;
              const invDataMap = {}

              const curMonthYear = getMonthYear(new Date());
              const curMonthYearIndex = monthYears.indexOf(curMonthYear)
              
              if(curMonthYearIndex !== -1){
                monthYears.splice(curMonthYearIndex,1)
              }


              monthYears.forEach((monthYear,i)=>{

                invDataMap[monthYear]={
                  invoiceJobCount:jobData[monthYear],
                  invoiceDiscount:0,
                  invoiceAmount:getInvoiceAmountFromJobCount(jobData[monthYear],partyTariffSeqData),
                  invoiceMonthYear:monthYear,
                  ...map
                }
              })

              return invDataMap
          }



          setInvoiceDataMap({
            ...editInvoiceAmountInputSchemeMap,
            // dataMap:{
            //   invoiceJobCount:jobCount,
            //   invoiceDiscount:0,
            //   invoiceAmount:invoiceAmount,
            //   ...map
            // },
            motherDataMap:getInvoiceDataMapByMonths(jobData),
            selections:billingMonths,
            isModal:true,
            fnType: "modal",
            tabOptionsReqd: false,
          })

          setShowModalBoxInvoice(true);
          setShowSpinner(false)
      }
      break;
      case "Clear":
        {
          console.log(data.stripValueMap)
          const dbCollQuery2 = query(
            collection(db, `admin/accounts/balances`),
            where("partyUids", "array-contains", map["partyUid"]),
            limit(1)
          );

          const balanceDoc = await getDocs(dbCollQuery2);
          const balanceDocId = balanceDoc.docs[0].ref.id;

          const docIds = data.stripValueMap.partyStripSeqDocIds;
          const stringSeqDatas = data.stripValueMap.partyStripSeqDatas;

          const mainInvoiceIds = [];
//Extracting Invoice ID's from the Strip Value Map based on which the balances are calculated

            const invoiceIdsColl=[]
            let tMap=[];
          stringSeqDatas.forEach((stringSeq) => {

            if(tMap.length>9){
              invoiceIdsColl.push(tMap);
              tMap=[];
              tMap.push(stringSeq.split("^")[0]);
            }else{
              tMap.push(stringSeq.split("^")[0]);
            }
              
          });

          const mainInvoiceDocIds = [];

          invoiceIdsColl.forEach(async(invoiceIds)=>{

            const mainInvoiceCollQuery = query(
              collection(db, `admin/accounts/invoices`),
              where("invoiceId", "in", invoiceIds)
            );

            const mainInvoiceDoc = await getDocs(mainInvoiceCollQuery);
  
            mainInvoiceDoc.docs.forEach((doc, i) => {
              mainInvoiceDocIds.push(doc.ref.id);
            });

          })



          // const mainInvoiceCollQuery = query(
          //   collection(db, `admin/accounts/invoices`),
          //   where("invoiceId", "in", mainInvoiceIds)
          // );

          // const mainInvoiceDoc = await getDocs(mainInvoiceCollQuery);
          // const mainInvoiceDocIds = [];

          // mainInvoiceDoc.docs.forEach((doc, i) => {
          //   mainInvoiceDocIds.push(doc.ref.id);
          // });

          const payload = {
            ...data.stripValueMap,
            balanceDocId: balanceDocId,
            mainInvoiceDocIds: mainInvoiceDocIds,
           // mainInvoiceIds: mainInvoiceIds,
           mainInvoiceIds:invoiceIdsColl
          };

          console.log(payload);

          const serverCall = httpsCallable(functions, "testInvoice");
          const result = await serverCall(payload);
          displayPrompt(result.data);
          setShowSpinner(false)
          props.backBtnClicked()
        }
        break;

      case "Paid":
        {
          setShowSpinner(true)
          console.log(map);
          console.log(correlationMap);
          console.log("stripValueMap=",data.stripValueMap)
          console.log(data)
          

          // const dbCollQuery = db
          //   .collection(`admin/accounts/balances`)
          //   .where("partyUids", "array-contains", payload.invoicePartyUid)
          //   .limit(1);

          // const balanceDocs = await dbCollQuery.get();

          //Step 1: Fetching the admin account balance document where the store is featured - 
          //To get important details of the invoice

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDoc = await getDocs(dbCollQuery)
          const balanceDocId = balanceDoc.docs[0].ref.id;


    //Step 2: Fetching the Invoice Doc - 
          const invoiceCollQuery = query(
            collection(db, `admin/accounts/invoices`),
            where("invoiceId", "==", data.stripValueMap.invoiceId),
            limit(1)
          );

          const invoiceDoc = await getDocs(invoiceCollQuery);
          const invoiceDocId = invoiceDoc.docs[0].ref.id;

          //The indices (basically get the no of records a party has in the unpaid invoice document) where partyuid in the unpaid invoice doc, in the particular unpaid invoice doc // To identify if there is only one 
          // matching index - so that we can delete the party from the search arrays of the unpaid Invoice Document
          const filteredIndices = []; 

          // correlationMap is the PartyUID Unpaid Invoice Doc ID Correlation Map, 
          //so it has Correlation between many Party UIds and Unp Invoice Doc Ids
          correlationMap.partyUids.forEach((partyUid, i) => {
            if (
              partyUid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.unpInvoiceDocId == correlationMap.unpInvoiceDocIds[i]
            ) { //In this condition we are identifying the index
              filteredIndices.push(i);
            }
          });

          // Step: Checking if there is only one record of the party in the unp invoice document
          //Get the First and Last Occurence of the Uid in the Party UID - Unpaid Invoice Doc Id
          const firstIndex = correlationMap.partyUids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = correlationMap.partyUids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          // So if the first and last index in the uid Map of Correlation Map is same or the filteredIndices lenght is 1 then we can conclude that the party has only one record
          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            invoiceDocId: invoiceDocId,
            balanceDocId: balanceDocId,
          };

          const serverCall = httpsCallable(functions, "payInvoice");
          const result = await serverCall(payload);
          const completionFn = () =>{
            props.backBtnClickHandler()
          }

          displayPrompt(result.data,"",completionFn);
          setShowSpinner(false)
        }
        break;

    case "View":{
        setShowSpinner(true)

        const invoiceId =  map.invoiceId;

        let invMap = {}

        console.log(map)
  
  
        const docDbCall = query(collection(db,`admin/accounts/invoices`),where("invoiceId","==",invoiceId));
        const dbDocs = await getDocs(docDbCall);
    
        invMap = dbDocs.docs[0].data();

        generateInvoice(invMap);
  
    
    
          console.log("hello")
          setShowSpinner(false);
          
  
  
      }


      break;
     
     
      case "Delete":
        setShowSpinner(true)
        {
          const firstIndex = correlationMap.uids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = correlationMap.uids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDocs = await getDocs(dbCollQuery);
          const balanceDocId = balanceDocs.docs[0].ref.id;

          const mainInvoiceDoc = await getDocs(
            query(
              collection(db, `admin/accounts/invoices`),
              where("invoiceId", "==", data.stripValueMap.invoiceId),
              limit(1)
            )
          );

          const mainInvoiceDocId = mainInvoiceDoc.docs[0].ref.id;

          const filteredIndices = [];

          correlationMap.uids.forEach((uid, i) => {
            if (
              uid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.unpInvoiceDocId == correlationMap.docIds[i]
            ) {
              filteredIndices.push(i);
            }
          });

          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            balanceDocId: balanceDocId,
            mainInvoiceDocId: mainInvoiceDocId,
          };

          const serverCall = httpsCallable(functions, "deleteInvoice");
          const result = await serverCall(payload);
          const completionFn = () =>{
            props.backBtnClickHandler()
          }

          displayPrompt(result.data,"",completionFn);
          setShowSpinner(false)
        }
        break;
      default: {
        console.log("No Options");
      }
    }
  }

  async function resetFilterClickFn(compListDataMapColl){
    setViewMode("normal");
    setFilterName(props.passMap.filterSchemeMap.filterNames[0])
    displayPage(1,compListDataMapColl);
  }

  async function filterClickFn(e,filterName,compListDataMapColl){
    console.log(e.target.parentElement)
    console.log(filterName)
    setViewMode("filter")

    const inputNodes = e.target.parentElement.getElementsByTagName("input");
    console.log(inputNodes);

    let filtValue = ""
    let filDbKeyArrayName = ""

    switch(filterName){
      case "Date":{

      }
      break;
      case "Party ID":{


        filDbKeyArrayName=props.listTopic=="transaction"?"transactionPartyIds":props.listTopic=="balances"?"partyIds":"invoicePartyIds"

        filtValue = inputNodes[0].value
      }
      break;
      case "Store Branch Name":{
        filDbKeyArrayName=props.listTopic=="transaction"?"transactionPartyNames":props.listTopic=="balances"?"partyNames":"invoicePartyNames"
        filtValue = toSentenceCase(`${inputNodes[0].value} - ${inputNodes[1].value}`);
        
      }
      break;
      case "Employee Name":{

      }
      break;
      case "Job Name":{

      }
      break;
    }



    generateFilterDataMapColl(filtValue,filDbKeyArrayName,compListDataMapColl);

}

function generateFilterDataMapColl(filtValue,filDbKeyArrayName,compListDataMapColl){

console.log("filtValue="+filtValue,"filtDbKeyArrayName="+filDbKeyArrayName);

  const tArray1 = [];
  const map={}

  const filDbKey = filDbKeyArrayName.substring(0,filDbKeyArrayName.length-1);


  //Extraction of data for the strips
  console.log(filtValue,filDbKeyArrayName,compListDataMapColl)

  compListDataMapColl.forEach((listDataMap) => {

    if(listDataMap[filDbKey]==filtValue){
      tArray1.push(listDataMap)
    }

  })

  console.log(tArray1)

  // setCompFilListDataMapColl({...map})
  setCompFilListDataMapColl([...tArray1])
  

  const iRecordCount = tArray1.length
  setRecordCount(iRecordCount)
  displayFiltPage(iRecordCount,1, tArray1);
}

function displayFiltPage(sRecordCount,sPageNo,compFilListDataMapColl){

  setPageNo(sPageNo);

  const latestSl = sRecordCount;

  const startSlOfPage =
  pageLimit * (sPageNo - 1) + 1 > sRecordCount
    ? sRecordCount
    : pageLimit * (sPageNo - 1) + 1;

const endSlOfPage =
  pageLimit * sPageNo > sRecordCount ? sRecordCount : pageLimit * sPageNo;

  const rArray = [];




  for (let y = startSlOfPage; y <= endSlOfPage; y++) {
    console.log(compFilListDataMapColl)
    console.log(startSlOfPage)
    const stripValueMap = compFilListDataMapColl[y-1];
    console.log(stripValueMap)
    stripValueMap["slNo"] = y
    //stripValueArray.unshift(y);
    rArray.push(stripValueMap);
  }

  console.log(rArray);

  setPageStripValueMapColl(rArray);
}

  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  async function modalBtnClickFn(data){ //Function executes when any button in the modal is clicked on
    console.log(data)

  

    const map = data.dataMap;
    const inputMap = data.inputDataMap;
    const fnName = inputMap.fnName // this states which button was clicked on


    console.log(map)

    if(fnName=="Send Invoice" || fnName=="Preview Invoice"){

      setShowSpinner(true);

      const invoiceId = fnName=="Preview Invoice" ? "INVOICE NOT GENERATED : FOR VIEWING PURPOSE ONLY" : map.invoiceId;


      const todaysDate = new Date().getTime();
      const dueDate = todaysDate + 1000 * 60 * 60 * 24 * 15; //Days x Milliseconds per day

      //Step 1: Fetching the Store Branch Doc - For info on store
      const storeGenDetailDoc = await getDoc(doc(db, `storeBranches/${map["partyUid"]}`));

 
         const storeDataMap = storeGenDetailDoc.data();
      // const storeAddress = `${storeSettings.storeBranchUnitNo}, ${storeSettings.storeBranchStreetName}, ${storeSettings.storeBranchCityName}, ${storeSettings.storeBranchProvinceName}, ${storeSettings.storeBranchCountryName} - ${storeSettings.storeBranchPostalCode}`;
      // const storeContactNo = storeSettings.storeBranchContactNo;
      // const storeBranchContactPersonName=storeSettings.storeBranchContactPersonName;
      // const storeBranchContactPersonContactNo=storeSettings.storeBranchContactPersonContactNo;

      // const invoiceDesc = "Hockey Caddy QR Scans"

      const adminDataMap = props.passMap;

      const hstRate = storeDataMap.settings.storeBranchProvinceName == "Ontario" ? 13 : 15;

      const coreInvMap = {
        invoiceId:invoiceId,
        partyId: map["partyId"],
        partyUid: map["partyUid"],
        partyName: map["partyName"],
        partyTariffType:map["partyTariffType"],
        partyTariffSeq:map["partyTariffSeq"],
        //unbilledJobCount: map["unbilledJobCount"],
        unbilledJobCount: map["invoiceJobCount"],
        invoiceMonthYear:map["invoiceMonthYear"],
        invoiceDiscount: inputMap.invoiceDiscount,
        invoiceSubTotalAmount: inputMap.invoiceAmount,
       // invoiceDesc: invoiceDesc,
        balanceDocId:map["balanceDocId"],
       // invoiceTaxDataSeq:`HST~${hstRate}`,
       //invoicePaymentDueDate:new Timestamp(dueDate/1000,0),
       // invoiceIssueDate:Timestamp.now(),
       // invoiceStatus:"unpaid"
      }

      const invMap = createInvoiceMap(coreInvMap,adminDataMap,storeDataMap);



        console.log(invMap);


        if(fnName=="Send Invoice"){
    
          setShowModalBoxInvoice(false)
          console.log("Sending Invoice")

        //Step 3: Server Call
        const serverCall = httpsCallable(functions, "sendInvoice");
        const result = await serverCall(invMap);

          const completionFn = () =>{
            props.backBtnClickHandler()
          }

          displayPrompt(result.data,"",completionFn);
          setShowSpinner(false)

        }else if(fnName == "Preview Invoice"){
          generateInvoice(invMap)
          setShowSpinner(false)
        }

        
    }

    else if(fnName=="Back"){
      setShowModalBoxInvoice(false);
    }

  }

  if(!isLoading){
      props.stopSpinner()
  }
  const columnWidths=props.listTopic=="balances"?`grid-columns-8b`:`grid-columns-8c`

  function displayPrompt(message,title,completionFn,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message,
      completionFn:completionFn ? completionFn : ()=>{}
    })
  }

  //@render
  return (
    <React.Fragment>
    {!isLoading && 
  <div className={`grid ${styleProxyMap.gridRows["2c"]} p-cust-topHead`}>
   {pageStripValueMapColl.length != 0 && 
   <div className="grid" >
        <div className="flex flex-col justify-center items-center py-12">
          <div className="flex flex-row">
            <select onChange={(e) => setFilterName(e.target.value)} value={filterName}>
              {props.passMap.filterSchemeMap.filterNames.map(
                (filterOptionName, i) => {
                  return (
                    <option key={i} value={filterOptionName}>
                      {filterOptionName}
                    </option>
                  );
                }
              )}
            </select>
            <div>
              <FilterInput
                filterName={filterName}
                filterSchemeMap={filterSchemeMap}
                filterValueMap={filterValueMap}
                inputChangeFn={(data) => inputChangeFn(data)}
                filterClickFn={(data,filterName)=>filterClickFn(data,filterName,compListDataMapColl)}
                resetFilterClickFn={(data)=>resetFilterClickFn(compListDataMapColl,data)}
              />
            </div>
            {/* <button
              className="btn-filter mx-1"
              onClick={() =>
                displayPagesBasedOnFilter(filterName, filterValueMap)
              }
            >
              Go
            </button> */}
          </div>
          <div className="flex mt-8 border-page-no">
            {viewMode=="normal" && renderPagination(pageLimit, compListDataMapColl, recordCount, pageNo)}
            {viewMode=="filter" && renderFilterPagination(pageLimit, compFilListDataMapColl, recordCount, pageNo)}
          </div>
        </div>
        
  
        <div className="">
  
        <div className="w-11/12 grid gap-4 grid-cols-cust-2e mx-auto mt-1 text-sm">
        <div
          className={`grid mx-auto w-full ${columnWidths} bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit flex justify-center items-center text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
          <div className="flex items-center justify-center text-darkBackText bg-secondary text-center rounded-sm border border-borderStd ">
            Action
          </div>
      </div>
         
            {pageStripValueMapColl.map((stripValueMap) => {
              return (
                <RecordBtnStrip2
                stripValueMap={stripValueMap}
                labelDbKeys={props.passMap.labelDbKeys}
                labelNames={props.passMap.labelNames}
                btnLabels={props.passMap.btnLabels}
                btnAlertLevels={props.passMap.btnAlertLevels}
                listTopic={props.listTopic}
                btnClickHandler={(data) => btnClicked(data, partyUidUnpaidInvoiceDocIdCorrelationMap)}
              />)
              })
            }
          </div>
        </div>//End of Body Cont
  }
          {pageStripValueMapColl.length == 0 && 
                        <NoAvailableDisplay textName="No Records Found"/>
          }
    
    <div className="absolute w-full bottom-10 flex justify-center align-center">
        {bottomContainer}
        </div>
      </div>
      }
          {showSpinner && <LoadSpinner />}
          {showModalBoxInvoice && 
          <ModalBoxInvoice 
            passMap={invoiceDataMap} 
            btnClickHandler={(data)=>modalBtnClickFn(data)}
          />
          }
       {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
       {showSpinner && <LoadSpinner />}
      </React.Fragment>
    );
}


