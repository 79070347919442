import React, { Component,useState } from "react";
import { getSettingsLabelValue,getFSettingsLabelValue } from "../Utils";

export default function InputTileDependant(props) {


  console.log(props);

  const [regexResult,setRegexResult] = useState(true)
  const [regexPatternA,setRegexPatternA] = useState("")

  console.log(props.inputName)

  const regexPattern = getRegexPattern(props.regexDomain);


  function checkDisabilityStatus(propsData){

    if(propsData.inputName=="ovrWaitTime"){
      const depValue = propsData.inputDataObj.ovrWaitTimeCond
      return !depValue;
      
    }else if(propsData.inputName=="storeAvlCond"){
      const depValue = propsData.inputDataObj.ovrStoreAvlCond

      console.log(depValue)
      return !depValue;
      
    }

  }


  function getRegexPattern(category){
    switch(category){
      case "entityName": 
      return new RegExp("^[A-Za-z@0-9]([A-Za-z@0-9]*[A-Za-z0-9]{0,}$|[A-Za-z@0-9 ]*[A-Za-z0-9]{1,}$)")
      break;
      case "personName":
        return new RegExp("^[A-Za-z]([A-Za-z]*[A-Za-z]{0,}$|[A-Za-z ]*[A-Za-z]{1,}$)")
        break;
      case "email":
        return new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+(\\.{1}[A-Za-z]{2,}){0,1}\\.{1}[A-Za-z]{2,}$")
        break;
      case "phoneNo":
        return new RegExp("^\\+[0-9]{0,14}[0-9]$")
        break;
      case "password":
          return new RegExp("\\S{6,}")
          break;
      default:
        return new RegExp("")

    }
  }

  function onChangeHandler(e,regexPattern){
    props.onChangeFn(e)
    console.log(e.target.value)
  
    setRegexResult(regexPattern.test(e.target.value))

  }

  const isLocationData=props.inputName.toLowerCase().includes("longitude") || props.inputName.toLowerCase().includes("latitude");

  return (
    <div className="grid grid-cols-cust-2h gap-1 bg-quaternary-xlight p-3">
      <label>{props.inputLabel}</label>
      {isLocationData &&
      <input
        className="pl-2"
        value={getSettingsLabelValue(props.inputLabel, props.inputValue)}
        type={props.inputType}
        name={props.inputName}
        min={
          props.inputName == "storeBranchLatitude"
            ? -90.00
            : props.inputName == "storeBranchLongitude"
            ? -180.00
            : 0
        }
        max={
          props.inputName == "storeBranchLatitude"
            ? 90.00
            : props.inputName == "storeBranchLongitude"
            ? 180.00
            : 0
        }
        step={
          props.inputName == "storeBranchLatitude" || props.inputName == "storeBranchLongitude"?.01:1 
        }
        checked={props.inputValue}
        disabled={!props.editStatus}
        onChange={props.onChangeFn}
      ></input>

      }
      {!props.inputName.toLowerCase().includes("latitude") &&  !props.inputName.toLowerCase().includes("longitude") && 

<input
    className="pl-2"
    value={getSettingsLabelValue(props.inputLabel, props.inputValue)}
    type={props.inputType}
    name={props.inputName}
    checked={props.inputValue}
    disabled={checkDisabilityStatus(props)}
    onChange={(e)=>onChangeHandler(e,regexPattern)}
></input>
      }
      {!regexResult && <span className="text-secondary text-xs">*Invalid Input</span>}
    </div>
  );
}
