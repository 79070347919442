import {
  collection,
  doc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  arrangeNumArrayInAscendingOrder,
  db,
  functions,
  generateIncrementNumArray,
  getDoc,
  httpsCallable,
  limit,
  orderBy,
  toSentenceCase
} from "../Utils";
import BottomContainer from "./BottomContainer";
import FilterInput from "./FilterInput";
import FloatingButton from "./FloatingButton";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordStrip2 from "./RecordStrip2";

const pageLimit = 15;

//@start
export default function StripListFilterCont3(props) {

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbKeys);
  console.log(props.passMap);

  const [motherListDataMap, setMotherListDataMap] = useState({});
  const [motherFilListDataMap, setMotherFilListDataMap] = useState({
    valueSeqs: [],
  });
  const [pageStripValueMapColl, setPageStripValueMapColl] = useState([]);
  const [slNoArray, setSlNoArray] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});

  const [uidDocIdTabMap, setUidDocIdTabMap] = useState({});

  const [recordCount, setRecordCount] = useState(0);
  const [filtRecordCount, setFiltRecordCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");
  const [isLoading,setIsLoading] = useState(true)
  const [reverseSlNosCond,setReverseSlNosCond] = useState(true)

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    const collQuery = query(
      collection(db, getDbCollPath(props)),
      where("docCreationDate", "!=", false),
      orderBy("docCreationDate","desc"),limit(2)
    );

    const dbDocs = await getDocs(collQuery);

    if(dbDocs.size!=0){
      setMotherListDataMap(
        extractAndSetValuesDataMap(dbDocs,motherListDataMap,1));
    }else{
      setPageStripValueMapColl([])
      props.stopSpinner()
      setIsLoading(false);
    }


  }



  function extractAndSetValuesDataMap(dbDocs,sMotherListDataMap,pageNo) {
    
    let latestSlNo=""
    

    if(Object.keys(sMotherListDataMap).length==0){
      latestSlNo = dbDocs.docs[0].data().recordSlNos[dbDocs.docs[0].data().recordSlNos.length-1]
       setRecordCount(latestSlNo);
    }else{
      latestSlNo = arrangeNumArrayInAscendingOrder(sMotherListDataMap.dbSlNos).reverse()[0]
    }

    console.log(latestSlNo);

    //const pageNoLimits = getPageNoLimits(pageNo,latestSlNo)

    const valueSeqDbKeys= props.passMap.valueSeqDbKeys;

    
    //creating the empty data map
    let eMap = {};
    const tArray1=[];

    eMap["slNos"]=[]
    eMap["dbSlNos"]=[]
    valueSeqDbKeys.forEach((key)=>{
      eMap[`${key}s`] = [];
    })

    console.log(eMap);
    console.log(valueSeqDbKeys)


    dbDocs.forEach((doc) => {
      const stringSeqDatas = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;
      

      stringSeqDatas.forEach((stringSeqData,i)=>{

        const stringSeqDataArray = stringSeqData.split("^");
        const dbSlNo = slNos[i]

          const appSlNo = reverseSlNosCond?latestSlNo-dbSlNo+1:dbSlNo; //For displaying latest record first - As we need the latest record to be tabulated first hence we need to reverse the slNos


          // console.log(stringSeqDataArray)

          eMap["slNos"].push(appSlNo) // Pushing the Sl No of the record
          eMap["dbSlNos"].push(dbSlNo) // required to find the latestSlNo in this function - refer top

          Object.keys(eMap).forEach((key)=>{ //Pushing rest of the data of the record
            const keySingular = key.substring(0,key.length-1)
            const keyIndex = valueSeqDbKeys.indexOf(keySingular)
            console.log(key,keyIndex)
            if(keyIndex!=-1){
              eMap[key].push(stringSeqDataArray[keyIndex]) //key field holds an array
            }
          })

          console.log(eMap);

      })

    });

    //Adding the db extract map to the MotherMap if Mother Map has already been populated

    if(Object.keys(sMotherListDataMap).length!=0){
      Object.keys(eMap).forEach((key)=>{
        eMap[key]=[...sMotherListDataMap[key],...eMap[key]]
      })
  }

    console.log(eMap)
    //console.log(stripValueMapColl);
   // setStripValuesMap({...eMap})
    displayPage(pageNo, {...eMap},latestSlNo);
    return eMap;
  }//end of extractAndSetValuesDataMap


  
  function displayPage(sPageNo, sMotherListDataMap,sRecordCount) {
    
    setPageNo(sPageNo);
    setViewMode("normal")

    console.log(sMotherListDataMap)
    const motherRecordSlNos = sMotherListDataMap.slNos
    const motherRecordDbSlNos = sMotherListDataMap.dbSlNos

    const latestSl = sRecordCount



    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * sPageNo > latestSl ? latestSl : pageLimit * sPageNo;

      
      console.log(startSlOfPage, endSlOfPage);
      console.log(motherRecordSlNos)
  
      const startDbSlOfPage = reverseSlNosCond?sRecordCount-startSlOfPage+1:startSlOfPage
      const endDbSlOfPage = reverseSlNosCond?sRecordCount-endSlOfPage+1:endSlOfPage
  
  //Step: Checking for missing Sl No Record in the Mother Data
      const slQueryArray = [];
      [startDbSlOfPage, endDbSlOfPage].forEach((item) => {
        if (motherRecordDbSlNos.indexOf(item) == -1) {
          slQueryArray.push(item);
        }
      });
  

  
      if (slQueryArray.length == 0) {
        assignStripValues(startSlOfPage, endSlOfPage, sMotherListDataMap);
      } else {
        fetchDbAndSetStripValues(
          startDbSlOfPage,
          endDbSlOfPage,
          slQueryArray,
          sMotherListDataMap,
          sPageNo
        );
      }
    }


  function extractAndSetFiltListDataMap(dbDocs,filValue,filDbKeyArrayName) {
    

    const valueSeqDbKeys= props.passMap.valueSeqDbKeys;

    //creating the empty data map
    let eMap = {};
    const tArray1=[];

    eMap["slNos"]=[]
    eMap["dbSlNos"]=[]
    valueSeqDbKeys.forEach((key)=>{
      eMap[`${key}s`] = [];
    })

    //index of the filDbKey in the value seq
    const mIndex = valueSeqDbKeys.indexOf(filDbKeyArrayName.substring(0,filDbKeyArrayName.length-1))

    console.log(mIndex,filDbKeyArrayName,filValue)

    let slNoCount=0

    dbDocs.forEach((doc) => {
      const stringSeqDatas = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;

      const tMap={}
      tMap["slNos"]=[]
      valueSeqDbKeys.forEach((key)=>{
        tMap[`${key}s`] = [];
      })


      stringSeqDatas.forEach((stringSeqData,i)=>{


          const stringSeqDataArray = stringSeqData.split("^");
          const slNo = slNoCount

    console.log(stringSeqDataArray[mIndex],filValue)
//Checking if the record matches the filter condition
          if(stringSeqDataArray[mIndex]==filValue){
             slNoCount++;
            tMap["slNos"].push(slNoCount) // Pushing the Sl No of the record

            Object.keys(tMap).forEach((key)=>{ //Pushing rest of the data of the record
              const keySingular = key.substring(0,key.length-1)
              const keyIndex = valueSeqDbKeys.indexOf(keySingular)
              if(keyIndex!=-1){
                tMap[key].push(stringSeqDataArray[keyIndex])

            
              }
            })
          }
          console.log(eMap);

      })

      Object.keys(tMap).forEach((key)=>{
        if(key!="slNos"){
          tMap[key].reverse()
        }
      });
    

    console.log(tMap);

    Object.keys(tMap).forEach((key)=>{
      eMap[key] = eMap[key].concat(tMap[key])
    });
    console.log(eMap);

    });


    const reverseEMap = {}

    if(!reverseSlNosCond){
    Object.keys(eMap).forEach((key)=>{
        reverseEMap[key]=[...eMap[key]]
        reverseEMap[key].reverse()
    })
    reverseEMap["slNos"] = [...eMap["slNos"]]
   }

   eMap = reverseSlNosCond ? {...eMap} : {...reverseEMap};
  //  console.log(reverseEMap)
  //  console.log(eMap)

    const sFiltRecordCount = eMap["slNos"].length
    console.log(`filterCount=${sFiltRecordCount}`)

    setFiltRecordCount(eMap["slNos"].length);

   // const rMap = reverseSlNosCond ? reverseEMap : eMap;


    //console.log(stripValueMapColl);
   // setStripValuesMap({...eMap})
    displayFiltPage(1, {...eMap});
    return eMap
  }

  function displayFiltPage(sPageNo, sMotherFilListDataMap) {

    setPageNo(sPageNo);


    const rArray = [];

    const latestSl = sMotherFilListDataMap.slNos.length;

   // const latestSl = arrangeNumArrayInAscendingOrder(sMotherFilListDataMap.slNos).reverse()[0]

    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * sPageNo > latestSl ? latestSl : pageLimit * sPageNo;

      
    assignStripValues(startSlOfPage, endSlOfPage, sMotherFilListDataMap);


  }







  async function fetchDbAndSetStripValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    sMotherListDataMap,
    pageNo
  ) {



    const collQuery = query(
      collection(
        db,
        getDbCollPath(props)
      ),
      where("recordSlNos", "array-contains-any", slQueryArray)
    );
    

    const dbDocs = await getDocs(collQuery);
    setMotherListDataMap(
      extractAndSetValuesDataMap(dbDocs,sMotherListDataMap,pageNo)
    );
  }

  function assignStripValues(startSlNo, endSlNo, sMotherListDataMap) {
    const rArray = [];

    const mIndexStart = startSlNo;
    const mIndexEnd = endSlNo;

    console.log(mIndexStart,mIndexEnd)

    const slNosArray = sMotherListDataMap.slNos;

    //empty data map


    const map2s = {};   // if it is maiden time, then sMotherListDataMap will be {}

    // //Creating an Intialized Array Map
    //   map2s["slNos"]=[] 
    //   props.passMap.labelDbKeys.forEach((dbKey)=>{
    //     map2s[`${dbKey}s`] = [];
    //   })
  
    const tArr=[];
        for (let y = mIndexStart; y <= mIndexEnd; y++) {
          const tMap = {};
          const mIndex = slNosArray.indexOf(y);
          tMap["slNo"]=y;
          props.passMap.valueSeqDbKeys.forEach((dbKey)=>{  
            tMap[dbKey]=sMotherListDataMap[`${dbKey}s`][mIndex]   
            })
          tArr.push(tMap)
        }

    console.log(tArr);

    setPageStripValueMapColl([...tArr]);
    setIsLoading(false)
  }



  function renderPagination(
    sPageLimit,
    motherListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {

    console.log(sRecordCount);
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    console.log(`date in renderPagination=${new Date().getMilliseconds()}`)

    const rArray = [];
    console.log(motherListValuesCollMap);

    // for (let x = 1; x <=pageCount; x++) {
    //   const element = (
    //     <button
    //        key="a"
    //       onClick={() => displayPage(x, motherListValuesCollMap,sRecordCount)}
    //       className={`p-cust-3 ${
    //         x == sPageNo ? `border-page-no-indicator` : ``
    //       }`}
    //     >{`${x}`}</button>
    //   );
    //   rArray.push(element);
    // }

    // return rArray;

    const element = (
      <select value={sPageNo} className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayPage(e.target.value,motherListValuesCollMap,sRecordCount)}>
        {generateIncrementNumArray(1,pageCount).map(slNo =>
          <option value={slNo}>{slNo}</option>
        )
        }
      </select>
  );

  return element;
  }

  function renderFilterPagination(sPageLimit,
    sMotherFilListDataMap,
    sRecordCount,
    sPageNo) {

    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    console.log(`date in renderFilPage=${new Date().toTimeString()}`)

    // const rArray = [];

    // for (let x = 1; x <= pageCount; x++) {
    //   const element = (
    //     <button
    //       onClick={() => displayFiltPage(x, sMotherFilListDataMap)}
    //       className={`p-cust-3 ${
    //         x == sPageNo ? `border-page-no-indicator` : ``
    //       }`}
    //     >{`${x}`}</button>
    //   );
    //   rArray.push(element);
    // }

    // return rArray;

    const element = (
      <select className="bg-secondary px-4 rounded text-darkBackText" onChange={(e)=>displayFiltPage(e.target.value, sMotherFilListDataMap)}>
        {generateIncrementNumArray(1,pageCount).map(pageNo =>
          <option value={pageNo}>{pageNo}</option>
        )
        }
      </select>
  );

    return element;
  }





  async function btnClicked(data, tabMap) {
    const map = data.stripValueMap;
    switch (data.btnType) {
      case "Invoice":
        {

          console.log(map)
     
          const todaysDate = new Date().getTime();
          const dueDate = todaysDate + 1000 * 60 * 60 * 24 * 15; //Days x Milliseconds per day

          //Step 1: Fetching the Store Branch Doc - For info on store
          const storeGenDetailDoc = await getDoc(
            doc(db, `storeBranches/${map["partyUid"]}`)
          );

          const storeEmail = storeGenDetailDoc.data().storeBranchEmail;
          const storeSettings = storeGenDetailDoc.data().settings;
          const storeAddress = `${storeSettings.storeBranchUnitNo}, ${storeSettings.storeBranchStreetName}, ${storeSettings.storeBranchCityName}, ${storeSettings.storeBranchProvinceName}, ${storeSettings.storeBranchCountryName} - ${storeSettings.storeBranchPostalCode}`;
          const storeContactNo = storeSettings.storeBranchContactNo;
          const storeBranchContactPersonName=storeSettings.storeBranchContactPersonName;
          const storeBranchContactPersonContactNo=storeSettings.storeBranchContactPersonContactNo;

        //Step 2: Creation of the Server Call Payload - Adding details from the Strip Map and the 
        //general Info that we got from Step 1
          const partyTariffSeq=(map["partyTariffType"]=="general")?props.passMap.feeTariffDetails.tariffStrSeqData:map["partyTariffSeq"];
        
          const payload = {
            partyId: map["partyId"],
            partyUid: map["partyUid"],
            partyName: map["partyName"],
            partyTariffType:map["partyTariffType"],
            partyTariffSeq:partyTariffSeq,
            unbilledJobCount: map["unbilledJobCount"],
            invoiceBalanceDue: map["dueBalance"],
            invoiceSubject: "App Commission",
            invoicePartyAddress: storeAddress,
            invoicePartyEmail: storeEmail,
            invoicePartyContactNo: storeContactNo,
            invoicePartyContactPersonName: storeBranchContactPersonName,
            invoicePartyContactPersonContactNo: storeBranchContactPersonContactNo,
            balanceDocId:map["balanceDocId"]
          };

          //Step 3: Server Call
          const serverCall = httpsCallable(functions, "sendInvoice");
          const result = await serverCall(payload);
          alert(result.data);

        }
        break;
      case "Clear":
        {
          const dbCollQuery2 = query(
            collection(db, `admin/accounts/balances`),
            where("partyUids", "array-contains", map["partyUid"]),
            limit(1)
          );

          const balanceDoc = await getDocs(dbCollQuery2);
          const balanceDocId = balanceDoc.docs[0].ref.id;

          const docIds = data.stripValueMap.stripSeqDocIds;
          const stringSeqDatas = data.stripValueMap.stripSeqDatas;

          const mainInvoiceIds = [];

          stringSeqDatas.forEach((stringSeq) => {
            mainInvoiceIds.push(stringSeq.split("^")[0]);
          });

          const mainInvoiceCollQuery = query(
            collection(db, `admin/accounts/invoices`),
            where("invoiceId", "in", mainInvoiceIds)
          );

          const mainInvoiceDoc = await getDocs(mainInvoiceCollQuery);
          const mainInvoiceDocIds = [];

          mainInvoiceDoc.docs.forEach((doc, i) => {
            mainInvoiceDocIds.push(doc.ref.id);
          });

          const payload = {
            ...data.stripValueMap,
            balanceDocId: balanceDocId,
            mainInvoiceDocIds: mainInvoiceDocIds,
            mainInvoiceIds: mainInvoiceIds,
          };

          console.log(payload);

          const serverCall = httpsCallable(functions, "payInvoices");
          const result = await serverCall(payload);
          alert(result.data);

        }
        break;

      case "Paid":
        {
          console.log(map);
          console.log(tabMap);

          // const dbCollQuery = db
          //   .collection(`admin/accounts/balances`)
          //   .where("partyUids", "array-contains", payload.invoicePartyUid)
          //   .limit(1);

          // const balanceDocs = await dbCollQuery.get();

          //Step 1: Fetching the admin account balance document where the store is featured - 
          //To get important details of the invoice

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDoc = await getDocs(dbCollQuery).docs[0];
          const balanceDocId = balanceDoc.ref.id;


    //Step 2: Fetching the Invoice Doc - 
          const invoiceCollQuery = query(
            collection(db, `admin/accounts/invoices`),
            where("invoiceId", "==", data.stripValueMap.invoiceId),
            limit(1)
          );

          const invoiceDoc = await getDocs(invoiceCollQuery).docs[0];
          const invoiceDocId = invoiceDoc.ref.id;

          const filteredIndices = []; //indices where the store in question has records in the specific doc ref.

          tabMap.uids.forEach((uid, i) => {
            if (
              uid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.docId == tabMap.docIds[i]
            ) {
              filteredIndices.push(i);
            }
          });

          //Get the First and Last Occurence of the Uid in the Party UID - Unpaid Invoice Doc Id
          const firstIndex = tabMap.uids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = tabMap.uids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            invoiceDocId: invoiceDocId,
            balanceDocId: balanceDocId,
          };

          const serverCall = httpsCallable(functions, "payInvoice");
          const result = await serverCall(payload);
          alert(result.data);
        }
        break;

      case "Gen":{
        const serverCall = httpsCallable(functions, "genInvoice");
        //const result = await serverCall(payload);
       // alert(result.data);
      }
      break;
      case "Delete":
        {
          const firstIndex = tabMap.uids.indexOf(
            data.stripValueMap.invoicePartyUid
          );
          const lastIndex = tabMap.uids.lastIndexOf(
            data.stripValueMap.invoicePartyUid
          );

          const dbCollQuery = query(
            collection(db, `admin/accounts/balances`),
            where(
              "partyUids",
              "array-contains",
              data.stripValueMap.invoicePartyUid
            ),
            limit(1)
          );

          const balanceDocs = await getDocs(dbCollQuery);
          const balanceDocId = balanceDocs.docs[0].ref.id;

          const mainInvoiceDoc = await getDocs(
            query(
              collection(db, `admin/accounts/invoices`),
              where("invoiceId", "==", data.stripValueMap.invoiceId),
              limit(1)
            )
          );

          const mainInvoiceDocId = mainInvoiceDoc.docs[0].ref.id;

          const filteredIndices = [];

          tabMap.uids.forEach((uid, i) => {
            if (
              uid == data.stripValueMap.invoicePartyUid &&
              data.stripValueMap.docId == tabMap.docIds[i]
            ) {
              filteredIndices.push(i);
            }
          });

          const isFinalElement =
            firstIndex == lastIndex || filteredIndices.length == 1;

          const payload = {
            ...data.stripValueMap,
            isFinalElement: isFinalElement,
            balanceDocId: balanceDocId,
            mainInvoiceDocId: mainInvoiceDocId,
          };

          const serverCall = httpsCallable(functions, "deleteInvoice");
          const result = await serverCall(payload);
          alert(result.data);
        }
        break;
      default: {
        console.log("No Options");
      }
    }
  }

  async function filterClickFn(e,filterName,motherListDataMap){
    console.log(e.target.parentElement)
    console.log(filterName)
    setViewMode("filter")

    console.log("topic name="+props.listTopic)

    let filtValue = ""
    const inputNodes = e.target.parentElement.getElementsByTagName("input");


    let filValType=""

    let filDbKeyArrayName = ""

    console.log("before")

    switch(filterName){
      case "Date":{

      }
      break;
      case "Customer ID":
      case "Party ID":{
        if(props.listTopic=="pastOrder"){
          filDbKeyArrayName="orderCustomerIds"
        }else if(props.listTopic=="transactionAdmin" || props.listTopic=="transaction"  ){
          filDbKeyArrayName="transactionPartyIds"
        }
        filtValue = `${inputNodes[0].value}`;
      }
      break;
      case "Customer Name":
      case "Party Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderCustomerNames":"transactionPartyNames"
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "Employee Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderResponsibleEmployeeNames":""
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
    
      }
      break;
      case "Job Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderJobNames":""
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value}`);
      }
      break;
      case "Store Branch Name":{
        filDbKeyArrayName=props.listTopic=="pastOrder"?"orderJobNames":props.listTopic=="transaction"?"transactionPartyNames":"transactionPartyNames"
        filValType="name"
        filtValue = toSentenceCase(`${inputNodes[0].value} - ${inputNodes[1].value}`);
      }
      break;
    }


    console.log(`fildDbKey = ${filDbKeyArrayName}, filValue = ${filtValue}`)

    const caseCorrectedFilValue = filValType=="name"?toSentenceCase(filtValue):filtValue

    generateFilterDataMap(caseCorrectedFilValue,filDbKeyArrayName,motherListDataMap);


}


async function generateFilterDataMap(filValue,filDbKeyArrayName,sMotherListDataMap){

  const tArray1 = [];
  const map={}

  console.log(`viewMode in generateFilterMapFn = ${viewMode}`)

  console.log(toSentenceCase(filValue))

  const dbCollQuery = query(collection(db,getDbCollPath(props))
  ,where(filDbKeyArrayName,"array-contains",filValue)
  ,orderBy("docCreationDate","desc"));

  const dbDocs = await getDocs(dbCollQuery);

  if(dbDocs.docs.length==0){
    setPageStripValueMapColl([])
  }else{
    setMotherFilListDataMap(extractAndSetFiltListDataMap(dbDocs,filValue,filDbKeyArrayName))
  }



}



  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  function resetFilterClickFn(sMotherListDataMap,event){

 
    setViewMode("normal");
    setFilterName(props.passMap.filterSchemeMap.filterNames[0])
    displayPage(1,sMotherListDataMap)
  }

  // function getDbCollPath(props){
  //   switch(props.listTopic){
  //     case "paidInvoice":{
  //       return `admin/accounts/paidInvoices`
  //     }
  //     case "Past Order":{
  //       return `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
  //     }
  //   }
  // }

  if(!isLoading){
      props.stopSpinner()
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  const stripColWidth=props.listTopic=="transaction"?`grid-columns-8d`:props.listTopic=="pastOrder"?`grid-columns-9a`:`grid-columns-8c`

  //@render
  return (
    <React.Fragment>
    {!isLoading && 
  <div className="h-screen grid grid-rows-cust-2c p-cust-topHead">

   <div className="" >
        <div className="flex flex-col justify-center items-center py-12">
          <div className="flex flex-row">
            <select className="bg-primary rounded px-8 text-darkBackText" onChange={(e) => setFilterName(e.target.value)} value={filterName}>
              {props.passMap.filterSchemeMap.filterNames.map(
                (filterOptionName, i) => {
                  return (
                    <option key={i} value={filterOptionName}>
                      {filterOptionName}
                    </option>
                  );
                }
              )}
            </select>
            <div>
              <FilterInput
                filterName={filterName}
                filterSchemeMap={filterSchemeMap}
                filterValueMap={filterValueMap}
                inputChangeFn={(data) => inputChangeFn(data)}
                filterClickFn={(data,filterName)=>filterClickFn(data,filterName)}
                resetFilterClickFn={(event)=>resetFilterClickFn(motherListDataMap,event)}
              />
            </div>
            {/* <button
              className="btn-filter mx-1"
              onClick={() =>
                displayPagesBasedOnFilter(filterName, filterValueMap)
              }
            >
              Go
            </button> */}
          </div>
          <div className="flex mt-8">
            {viewMode=="normal" && renderPagination(pageLimit, motherListDataMap, recordCount, pageNo)}
            {viewMode=="filter" && renderFilterPagination(pageLimit, motherFilListDataMap, filtRecordCount, pageNo)}
          </div>
        </div>
        
  
        <div className="px-4">
  
        <div className="grid gap-4 mx-auto mt-1 text-sm">
        <div
          className={`grid mx-auto w-full ${stripColWidth} bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit flex justify-center items-center text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
      </div>
         
            {pageStripValueMapColl.length > 0 && pageStripValueMapColl.map((stripValueMap) => {
              return (
                <RecordStrip2
                  stripValueMap={stripValueMap}
                  labelDbKeys={props.passMap.labelDbKeys}
                  labelNames={props.passMap.labelNames}
                  btnLabels={props.passMap.btnLabels}
                  listTopic={props.listTopic}
                  stripColWidth={stripColWidth}
                  passDataMap2={props.passMap.settings}
                  btnClickHandler={(data) => btnClicked(data, uidDocIdTabMap)}
              />)
              })
            }
            
          {pageStripValueMapColl.length == 0 && 
                        <NoAvailableDisplay textName="No Records Found"/>
          }
          </div>
        </div>

  
          <FloatingButton btnLabel="Back" positionRight="10" positionTop="20" btnClickHandler={()=>props.backBtnClickHandler()}/>
      </div>
      }
      </React.Fragment>
    );
}



function getDbCollPath(props){
  console.log(props.listTopic)
  switch(props.listTopic){
    case "paidInvoice":{
      return `storeBranches/${props.passMap.storeBranchUid}/paidInvoices`
    }
    case "paidInvoiceAdmin":{
      return `admin/accounts/paidInvoices`
    }
    case "transactionAdmin":{
      return `admin/accounts/transactions`
    }
    case "transaction":{
      return `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
    }
    case "pastOrder":{
      return `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
    }
  }
}

