import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { doc, getCountFromServer, getDoc, getDocs, orderBy, serverTimestamp, writeBatch } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  collection,
  db,
  generateIncrementNumArray,
  getArraySum,
  getDateObjFromDayOfYearAndYear,
  getDayOfTheYear,
  getTimeLabel,
  query,
  where
} from "../Utils";
import BottomContainer from "./BottomContainer";
import FloatingButton from "./FloatingButton";
import InputDate from "./InputDate";
import TabContainer from "./TabContainer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.size = 18;

export default function ChartCont3(props) {


  console.log(props.passMap);

  const labels = ["January", "February", "March"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [4, 5, 8],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: [9, 4, 7],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const topicIndex = props.passMap.chartTopics.indexOf(props.passMap.topic);
  const filValues = [...props.passMap.filValues[topicIndex]]

  const tabNames=props.passMap.filValues[topicIndex][2]

  const [datasetKey, setDatasetKey] = useState("");
  const [chartTopic, setChartTopic] = useState(props.passMap.topic);
  const [chartReady,setChartReady]=useState(false)
  const [compDataMap, setCompDataMap] = useState({});
  const [filCategories, setFilCategories] = useState([
    ...props.passMap.filCategories[topicIndex],
  ]);
  const [filterMode,setFilterMode] = useState("normal");
  const [fil1Value, setFil1Value] = useState(
    props.passMap.filCategories[topicIndex][0]
  );
  const [fil2Value, setFil2Value] = useState(
    props.passMap.filValues[topicIndex][0][0]
  );
  const [fil3Value, setFil3Value] = useState(
    props.passMap.filValues[topicIndex][1][0]
  );
  const [fil4Value, setFil4Value] = useState(
    props.passMap.filValues[topicIndex][2][0]
  );
  const [fil5aValue, setFil5aValue] = useState(
    "0000-00-00"
  );
  const [fil5bValue, setFil5bValue] = useState(
    "0000-00-00"
  );


  const [showTotal,setShowTotal] = useState(false)
  
  const [totalMap,setTotalMap] = useState({})
  const [tabFocusIndex,setTabFocusIndex]=useState(0);
  //const [curTabName, setCurTabName] = useState("Hour");
  //const [tabChanged, setTabChanged] = useState(false);

  const [xAxisLabel,setXAxisLabel]=useState(props.passMap.axisLabels[topicIndex][0][0])
  const [yAxisLabel,setYAxisLabel]=useState(props.passMap.axisLabels[topicIndex][1][0])


  const options = {
    responsive: true,
    scales: {
      x: {
         title:{
          display:true,
          align:"centre",
          text:xAxisLabel,
          color:"#000000",
          font:{
            size:14,
            weight:"500",
        
          }
         },
      },
      y: { 
        title:{
          display:true,
          align:"centre",
          text:yAxisLabel,
          color:"#000000",
          font:{
            size:14,
            weight:"500"
          }
        },
        min:0,
        type:'linear',

    },

    // ticks:{
    //   callback: function (value) { if (Number.isInteger(value)) { return value; } }
    // },
  },
    plugins: {
      // title:{
      //   display:true,
      //   text:"Nishanth"
      // },
      legend: {
    
        // title:{
        //   display:true,
        //   text:"Nishantyh"
        // },
        position: "right",
      },
    },
  };

  const [chartData, setChartData] = useState({ ...data });
  const [chartOptions, setChartOptions] = useState({ ...options });

  useEffect(() => {
 
    initializePageWithData(compDataMap, fil2Value, fil3Value, fil4Value);
  }, []);

  useEffect(() => {
    
  }, [xAxisLabel,yAxisLabel]);

  console.log(compDataMap);

  async function initializePageWithData(
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value
  ) {

    props.startSpinner();

    const statTopic = props.passMap.topic.toLowerCase()
    const specLabel =  props.passMap.specLabel

    const storeBranchRootPath = `storeBranches/${props.passMap.uid}`;
    const storeGenDetailsCollPath = `${storeBranchRootPath}/storeBranchGenDetails`
    const statTopicCollectionPath = `${storeGenDetailsCollPath}/statData/${statTopic}Stats`;

    let tMap = {...compDataMap}

    const fil2Values =  filValues[0]
    const fil3Values = filValues[1];
    const fil4Values = filValues[2];


    const currentYearCond = fil2Value == fil2Values[0]
    let pastOrderFetchReqd = false;
    let statDataMapWriteReqd = false;
    let syncSlNoWriteReqd = false;

    const chartDataDbFetchAndWriteReqd = !(fil2Value in tMap);

    let dataSyncRefreshCond = false;


    if (chartDataDbFetchAndWriteReqd) {

      const dbDoc = await getDoc(doc(db,`${storeGenDetailsCollPath}`,`statData`));

      const dataSyncLatestPastOrderSlNo = dbDoc.data()["dataSyncLatestPastOrderSlNoMap"][statTopic];
      const dataSyncRefreshRefDateTime = dbDoc.data()["dataSyncRefreshRefDateTimeMap"][statTopic];

      let selectedYearPastOrderLatestSlNo = null;

      let yearStatDataMapPresentCond = false;

      //this map is used to store the doc ids of each statDataMap so that it will be useful in updating the corresponding docs later
      //the template will be map = {"Hollow Radius":"hsdihadhsdihddiuhfdui"}
      let statDataDocIdMap = {};

      if(dataSyncLatestPastOrderSlNo!=0){

        const docCountCall = await getCountFromServer(query(collection(db,`${statTopicCollectionPath}`),where("year","==",fil2Value)));

        yearStatDataMapPresentCond = docCountCall.data().count > 0;

      }

      const noStatDataMapCond = dataSyncLatestPastOrderSlNo==0 || !yearStatDataMapPresentCond

          //A. creating a template map or getting the dataMap which doubles up as a template

          if(noStatDataMapCond){ 
            //for a new account, past orders have never been referred to get data
            //create a dataMap template
    
            tMap = getChart3DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values);
            pastOrderFetchReqd = true;
  
          }else{
              //check if the selected year stat data doc is available
              const dbDocsColl = await getDocs(query(collection(db,`${statTopicCollectionPath}`),where("year","==",fil2Value)));
    
              tMap = {
                [fil2Value]:{}
              }
    
             const refreshRefDateTime = dbDocsColl.docs[0].data().refreshRefDateTime 
             dataSyncRefreshCond =  dataSyncRefreshRefDateTime > refreshRefDateTime
    

    
              dbDocsColl.docs.forEach((doc,i)=>{
    
                const year = doc.data().year;
                const specValue = doc.data()[specLabel];
              
    
    
                  if(!dataSyncRefreshCond){ 
                    //here the tMap is filled with data from the statDataMap, suppose dataSyncRefresh is asked for no data is collected
    
                    const dataMap = JSON.parse(doc.data().data);
                 
                    tMap[fil2Value][specValue] = {...dataMap[fil2Value][specValue]}
    
                 }
    
     
                 //the doc ids will be required even if dataRefresh is asked for because we want to write on the existing statDocs
                 statDataDocIdMap[specValue] = {
                  docId: doc.id,
                  refreshRefDateTime:refreshRefDateTime
                 }
                
              })
    
              if(dataSyncRefreshCond){
                //since for dataSyncRefresh we will need to re-fresh all data, hence we are assigning a template object
                tMap = getChart3DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values);
              }
    
              pastOrderFetchReqd = dataSyncRefreshCond || currentYearCond;
    
          }

      
    
      //B. Getting External Data if required and writing the updated data

      if(pastOrderFetchReqd){

        const querySyncSlNo = dataSyncRefreshCond ? 0 : currentYearCond ? dataSyncLatestPastOrderSlNo : 0;


        let collQuery = "";
        collQuery = query(collection(db,`${storeBranchRootPath}/pastOrders`),where("orderYear","==",fil2Value),where("latestRecordSlNo",">",querySyncSlNo),orderBy("latestRecordSlNo","desc"))

      //getting content from past orders
      const pastOrderDocColl = await getDocs(collQuery);

      statDataMapWriteReqd = !pastOrderDocColl.empty

      if(statDataMapWriteReqd){
          //Functions : 1) Adding the pastOrder data to base tMap
          //2) Update the stat skate datamap on db
          //3) Update the sync no on db

          selectedYearPastOrderLatestSlNo = pastOrderDocColl.docs[0].data().latestRecordSlNo;

          syncSlNoWriteReqd = selectedYearPastOrderLatestSlNo > dataSyncLatestPastOrderSlNo
              
          tMap = extractAndAddDataToChartMap(props,{...tMap},topicIndex,pastOrderDocColl,fil2Value,fil3Values,fil4Values,querySyncSlNo);

          const batch = writeBatch(db);

          for(const specValue of fil3Values){
              const dataMap = {
                [fil2Value]:{
                  [specValue]:tMap[fil2Value][specValue]
                }
              }

              const uploadMap = {
                year:fil2Value,
                [specLabel]:specValue,
                data:JSON.stringify(dataMap),
           
              }

              const docUpdateAndNotCreateCond = !noStatDataMapCond || specValue in statDataDocIdMap;
          
              if(docUpdateAndNotCreateCond){
                batch.update(doc(db,statTopicCollectionPath,statDataDocIdMap[specValue]["docId"]),{...uploadMap,refreshRefDateTime:dataSyncRefreshRefDateTime,docModificationDate:serverTimestamp()})
              }else{
                batch.set(doc(collection(db,statTopicCollectionPath)),{...uploadMap,refreshRefDateTime:dataSyncRefreshRefDateTime,docCreationDate:serverTimestamp()})
              }
 
          }

          if(syncSlNoWriteReqd){
                batch.update(doc(db,`${storeGenDetailsCollPath}`,'statData'),{[`dataSyncLatestPastOrderSlNoMap.${chartTopic.toLowerCase()}`]:selectedYearPastOrderLatestSlNo})
          }

          batch.commit()
      }

    }

    }

    console.log(tMap)
    updateChart(tMap, fil2Value, fil3Value, fil4Value,filValues);
    
    setCompDataMap({...tMap})

    setChartReady(true)
    props.stopSpinner()


    function extractAndAddDataToChartMap(props,map,topicIndex,pastOrderDocColl,fil2Value,fil3Values,fil4Values,querySyncSlNo){
      //2: getting refValues
      pastOrderDocColl.docs.forEach((doc, i) => {


        //putting this condition because to factor for the fact to only extract part of the records (new records) from the past order doc
        //this happens when an updated StatMap has to update itself when a new past order record is created 
   

          const valueSeqs = doc.data().stringSeqDatas;

          for(let i=valueSeqs.length-1;i>=0;i--){

            const recordSlNo = doc.data().recordSlNos[i];

            if(recordSlNo <= querySyncSlNo) { 
              //this is to save time and computing power, since we are iterating backward and we only need to extract the data of new past orders records
              //comparing the latestSyncSlNo withe the record sl no will only iterate over new past order records
              break;
            }
  
           // valueSeqs.forEach((valueSeq, i) => {
                  const valueSeq = valueSeqs[i]
                  const valueSeqSegmentColl = valueSeq.split("~").map((it)=>it.split("^"));      
          
                  const filRefDbKeys = props.passMap.filRefDbKeys[topicIndex]; //Ex- "orderCompletionDateTime"
                  const valueSeqDbKeys = props.passMap.valueSeqDbKeys;
                  const refValues = [];
          
          
                  filRefDbKeys.forEach((filRefKey, j) => {
                    // if (j != 3) {
          
                      const value = findAndGetRefValueFromDataSeq(valueSeqSegmentColl,valueSeqDbKeys,filRefKey)            
                      refValues.push(value)
        
                    // } else {
                    //   // if it is a variable key selection filter ex - Avg Wait Time, Avg Job Time etc
                    //   const varFilRefDbKeys = [...filRefKey];
                    //   const tArr = [];
                    //   varFilRefDbKeys.forEach((varFilRefKeys) => {
        
                    //     tArr.push(findAndGetRefValueFromDataSeq2(valueSeqSegmentColl,valueSeqDbKeys,varFilRefKeys));
              
                    //   });
                    //   refValues.push(tArr);
                    // }
                  });
          
                  //refValues - [1928321213,Skate Sharpening,789132732]

                //3 : Filling the Chart Data Map based on conditional
                  if (fil2Value == getCompRefValue(refValues[0], fil1Value)) {

                    const fil3Entry = refValues[1]; //Ex - Skate Sharpening
                    
                          fil4Values.forEach((fil4Value, m) => {
                            //Inserting Values for all fil4Labels (ex- hour, day, month) to the final child array which will be the data for the chart
                            const fil4Entry = getCompRefValue(refValues[2], fil4Value);
                          
                            const fil4ChartLabelsColl = [...props.passMap.chartLabels[topicIndex]];
                            const fil4EntryIndex = fil4ChartLabelsColl[m].indexOf(fil4Entry);
            
                              //  tMap[fil2Value][fil3Entry][filAValue][`${fil4Value}Items`][
                              //    fil4EntryIndex
                              //  ].push(1); //Incrementing the position of the fil4Entry
                              map[fil2Value][fil3Entry][fil4Value][fil4EntryIndex] += 1
        
                          });
          
                
          
          
                 
          
                  }
        
              
  
          //});
          }


      });
      return map;
    };
          
  }


  function getChart3DataTemplateMap(tMap,fil2Value,fil3Values,fil4Values){

    const map = {...tMap};

    // if (!Object.keys(tMap).includes(fil2Value)) {
    map[fil2Value] = {};

    console.log(fil3Values)

    //STEP 1 : Creating Scheme Map for Chart Data
    fil3Values.forEach((iFil3Value) => {
      const aFil3Value =
        iFil3Value.split("^").length > 1 ? iFil3Value.split("^")[1] : iFil3Value.split("^")[0];

      map[fil2Value][aFil3Value] = {}; //Eg map[2022][Skate Sharpening]={}

        fil4Values.forEach((fil4Value, i) => {
          const labelsForFil4 = props.passMap.chartLabels[topicIndex][i];
          const zeroAvgArr = labelsForFil4.map((it) => {return 0;});
          // const zeroItArr = labelsForFil4.map((it) => {return [];});
          map[fil2Value][aFil3Value][fil4Value] = [...zeroAvgArr];
      //    map[fil2Value][fil3Value][filAValue][`${fil4Value}Items`] = [...zeroItArr]; //Eg map[2022][Skate Sharpening][Hour]=[0,0,0,0,0]


        });

    });


    return map;
  }

  function extractAndSetDataMap(
    docColl,
    compDataMap,
    fil2Value,
    fil3Value,
    fil4Value
  ) {
    console.log("comin");
    console.log(docColl.size);
    console.log(fil2Value, fil3Value, fil4Value);
    const tMap = { ...compDataMap };

    // if (!Object.keys(tMap).includes(fil2Value)) {
    tMap[fil2Value] = {};
    tMap["All"] = tMap["All"] || {};
    tMap["All"]["All"] = tMap["All"]["All"] || {};

    const fil3Values = props.passMap.filValues[topicIndex][1];
    const fil4Values = props.passMap.filValues[topicIndex][2];

    //Creating Scheme Map for the Data Map

    fil3Values.forEach((fil3Value) => {
      tMap[fil2Value][fil3Value] = {}; //Eg tMap[2022][Skate Sharpening]={}
      tMap["All"][fil3Value] = tMap["All"][fil3Value] || {};
      fil4Values.forEach((fil4Value, i) => {
        console.log(fil4Value,topicIndex)
        const labelsForFil4 = props.passMap.chartLabels[topicIndex][i];
      
        //creating zero array, Ex - tMap[2022][Skate Sharpening]["Day"]=[0,0,0,0,0,0,0]
        const zeroArr = labelsForFil4.map((it) => {
          return 0;
        }); 


        tMap[fil2Value][fil3Value][fil4Value] = [...zeroArr]; //Eg tMap[2022][Skate Sharpening][Hour]=[0,0,0,0,0]
        tMap["All"][fil3Value][fil4Value] = tMap["All"][fil3Value][
          fil4Value
        ] || [...zeroArr];
      });
    });

    console.log(tMap);

    const fil4Index = fil4Values.indexOf(fil4Value);
    const fil4ChartLabelsColl = [...props.passMap.chartLabels[topicIndex]];

    // const monthFil4Index =
    //   props.passMap.filValues[topicIndex][2].indexOf("Month");
    // const dayFil4Index =
    //   props.passMap.filValues[topicIndex][2].indexOf("Day");

    const tArr = [];

    docColl.docs.forEach((doc, i) => {
      const valueSeqs = doc.data().stringSeqDatas;

      console.log(valueSeqs);

      valueSeqs.forEach((valueSeq, i) => {
        const valueSeqSegmentColl = valueSeq.split("~").map((it)=>it.split("^"));
        // const valueSeqArr = valueSeq.split("^");

        // console.log(valueSeqArr,props.passMap);

        const filRefDbKeys = props.passMap.filRefDbKeys[topicIndex]; //Ex- "orderCompletionDateTime"
        const valueSeqDbKeys = props.passMap.valueSeqDbKeys;
        const refValues = [];

        filRefDbKeys.forEach((filRefKey, i) => {
          refValues.push(findAndGetRefValueFromDataSeq(valueSeqSegmentColl,valueSeqDbKeys,filRefKey))
          // const mIndex = valueSeqDbKeys.indexOf(filRefKey);
          // refValues.push(valueSeqArr[mIndex]); //Final Output Ex - [8712128721,"Skate Profiling",8712128721]
        });

        console.log(refValues);

        console.log(fil2Value, getCompRefValue(refValues[0], fil1Value));

        //eg if (2022==getCompRefValue(3438923992389,"Year"))
        if (fil2Value == getCompRefValue(refValues[0], fil1Value)) {
          const fil3Entry = refValues[1]; //Ex - Skate Sharpening

          fil4Values.forEach((fil4Value, i) => {//Ex - fil4Value - Hour, Day, Month, Date Range
            //Inserting Values for all fil4Labels (ex- hour, day, month) to the final child array which will be the data for the chart
            const fil4Entry = getCompRefValue(refValues[2], fil4Value); //Ex - If Hour then 4 or 5 or 13
            const fil4EntryIndex = fil4ChartLabelsColl[i].indexOf(fil4Entry);
            console.log(tMap,fil2Value,fil3Entry,fil4Value, fil4EntryIndex);
            tMap[fil2Value][fil3Entry][fil4Value][fil4EntryIndex] += 1; //Incrementing the position of the fil4Entry
            tMap[fil2Value]["All"][fil4Value][fil4EntryIndex] += 1;
            tMap["All"][fil3Entry][fil4Value][fil4EntryIndex] += 1;
            tMap["All"]["All"][fil4Value][fil4EntryIndex] += 1;
            //Ex - tMap[2022][Skate Sharpening][Hour]=[0,0,1,0,0]
          });
        }
      });
    });

    console.log(tMap);
    setChartReady(true)
    props.stopSpinner()

    updateChart(tMap, fil2Value, fil3Value, fil4Value);

    return tMap;
    // }
  }

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );



  function updateChart(sCompDataMap, fil2Value, fil3Value, fil4Value) {
    const mIndex = props.passMap.filValues[topicIndex][2].indexOf(fil4Value);
    const labels = props.passMap.chartLabels[topicIndex][mIndex];

    console.log(sCompDataMap);

    let data = {}

    console.log("fil2ValueB",fil2Value)

    const colors = ["#C73C3C","#3C3C3C","#C7C7C7"]

    

    if(fil3Value=="All"){

      const filValues = [...props.passMap.filValues[topicIndex][1]];

      filValues.pop();

      const datasets = [];

      filValues.forEach((value,i)=>{
        const map = {};
        const valueStr = value.toString();
        // map["label"] = valueStr;
        map["label"] = `${valueStr} (Total : ${getArraySum(sCompDataMap[fil2Value][valueStr][fil4Value])})`
        map["data"] = sCompDataMap[fil2Value][valueStr][fil4Value];
        map["borderColor"] = colors[i]
        map["backgroundColor"] = colors[i]

        datasets.push(map);
      })

      data={
        labels: labels,
        datasets: datasets
      }


    }else{

      data = {
        labels: labels,
        // labels:`${labels} (${getArraySum(sCompDataMap[fil2Value][fil3Value][fil4Value])})`,
        datasets: [
          {
            // label: fil3Value,
            label:`${fil3Value} (Total : ${getArraySum(sCompDataMap[fil2Value][fil3Value][fil4Value])})`,
            data: sCompDataMap[fil2Value][fil3Value][fil4Value],
            borderColor: "#C73C3C",
            backgroundColor: "#C73C3C",
          },
        ],
      };

    }

    console.log(data);

    setShowTotal(true);
    setTotalMap(getTotalMap(data))


    // setDatasetKey(`${fil2Value}-1`);
    setChartOptions({ ...options });

    setChartData({ ...data });
  }


  function getTotalMap(data){

    console.log(data)

    const dataset = data.datasets[0]["data"];
    console.log(dataset)

    const total = getArraySum(dataset)
    console.log(total)

    const map = {
      label:"Total Jobs",
      value:total
    }

    return map
  }


  function updateChartByDateRange(sCompDataMap, fil2Value, fil3Value, dateRangeStartStr,dateRangeEndStr) {
    
    const mIndex = props.passMap.filValues[topicIndex][2].indexOf(fil4Value);
   // const labels = props.passMap.chartLabels[topicIndex][mIndex];

   options.scales.x.title.text = "Date (Day/Month)"


    let data={}
  
    const colors = ["#C73C3C","#3C3C3C","#C7C7C7"]

   


    if(fil3Value=="All"){

      const filValues = [...props.passMap.filValues[topicIndex][1]];

      filValues.pop();

      const datasets = [];

      filValues.forEach((value,i)=>{
        const map = {};
        const valueStr = value.toString();

        const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][valueStr]["Date Range"]);
        data["labels"] = dateRangeLabelAndData["rangeLabels"]
        map["label"] = valueStr;
        map["data"] = dateRangeLabelAndData["rangeLabelValues"];
        map["borderColor"] = colors[i]
        map["backgroundColor"] = colors[i]

        datasets.push(map);
      })

     data["datasets"] = [...datasets]


    }else{

      const dateRangeLabelAndData = getDateRangeLabelAndDataMap(dateRangeStartStr,dateRangeEndStr,sCompDataMap[fil2Value][fil3Value]["Date Range"]);

    data = {
        labels: dateRangeLabelAndData["rangeLabels"],
        datasets: [
          {
            label: fil3Value,
            label: `${fil3Value} (Total : ${getArraySum(dateRangeLabelAndData["rangeLabelValues"])})`,
            data: dateRangeLabelAndData["rangeLabelValues"],
            borderColor: "#C73C3C",
            backgroundColor: "#C73C3C",
          },
        ],
      };
    }

    // setShowTotal(true);
    // setTotalMap(getTotalMap(data))


    console.log(data);

    // setDatasetKey(`${fil2Value}-1`);
    setChartOptions({ ...options });

    setChartData({ ...data });
  }


  function findAndGetRefValueFromDataSeq(seqValuesColl,seqKeysColl,filRefKey){

    if(seqKeysColl.length == 1){

      const index = seqKeysColl[0].indexOf(filRefKey)
      return seqValuesColl[0][index]

    }else if(seqKeysColl.length > 1){
      if(seqKeysColl[0].includes(filRefKey)){
        const indexI = seqKeysColl[0].indexOf(filRefKey)
        return seqValuesColl[0][indexI]
      }else{
        for(let i=1;i<seqKeysColl.length;i++){
          if(seqKeysColl[i].includes(filRefKey)){
            const indexJ = seqKeysColl[i].indexOf(filRefKey);
            if(seqValuesColl.length > i && seqValuesColl[i].length > indexJ){
              return seqValuesColl[i][indexJ]
            }else{
              return null
            }
          }
        }
      }
    }

  }
  
  function getDateRangeLabelAndDataMap(startDate,endDate,chartValues){

    console.log(chartValues)
    console.log(startDate,endDate)

    const rMap={};

    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    console.log(date2.getFullYear())
    console.log(date1.getFullYear())

    if(isNaN(date1.getFullYear())){
      date1.setFullYear(date2.getFullYear())
      date1.setMonth(0);
      date1.setDate(1);
    }else if(isNaN(date2.getFullYear())){
      date2.setFullYear(date1.getFullYear())
      date2.setMonth(11);
      date2.setDate(31);
    }

    console.log(date1,date2)

    const date1Map = {};
    const date2Map = {};

    date1Map["dayOfTheYear"] = getDayOfTheYear(date1);
    date2Map["dayOfTheYear"] = getDayOfTheYear(date2);


    const startIndex =  date1Map["dayOfTheYear"]-1; //Exact Index as in Array.slice the start index is inclusive. Also as year array starts from 1 and not 0, so day 1 will have an index of 1-1=0;
    const endIndex = date2Map["dayOfTheYear"]; //End Index in Array.slice is exclusive.

   

    rMap["rangeLabelValues"] = chartValues.slice(startIndex,endIndex);
    rMap["rangeLabels"] = getDateRangeLabels(date1,date2);

    function getDateRangeLabels(date1,date2){

    const date1Map = {};
    const date2Map = {};

      date1Map["dayOfTheYear"] = getDayOfTheYear(date1);
      date2Map["dayOfTheYear"] = getDayOfTheYear(date2);
      // date1Map["month"] = getMonth(date1);
      // date2Map["month"] = getMonth(date2);

      const rangeIncrements = generateIncrementNumArray(date1Map["dayOfTheYear"],date2Map["dayOfTheYear"]);

      const arr = [...rangeIncrements]

      const arr1 = arr.map((it,i)=>
        getValue(it,date1.getFullYear())
    )

    console.log(arr1)

    function getValue(dayOfYear,year){        
    const dateObj = getDateObjFromDayOfYearAndYear(dayOfYear,year)
    console.log(dateObj)
    const monthNo = dateObj.getMonth()+1;
    const dayOfMonth = dateObj.getDate();
    console.log(`${dayOfMonth}/${monthNo}`)
    return `${dayOfMonth}/${monthNo}`
    }

      return arr1;


    }


    return rMap
  }


  function fil2ValueChange(e, compDataMap, fil3Value, fil4Value) {
    const recFil2Value =
      e.target.value == "All" ? e.target.value : parseInt(e.target.value);
    setFil2Value(recFil2Value);

    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");
    

    if (recFil2Value != "All") {
      initializePageWithData(compDataMap, recFil2Value, fil3Value, fil4Value);
    } else {
      updateChart(compDataMap, recFil2Value, fil3Value, fil4Value);
    }
    console.log(e.target.value);
  }





  function fil3ValueChange(e, compDataMap, fil2Value, fil4Value) {
    const recFil3Value = e.target.value;

    setFil3Value(recFil3Value);
    console.log("fil3Value is",e.target.value);


    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");
    
    updateChart(compDataMap, fil2Value, recFil3Value, fil4Value); 
  }

  function fil4ValueChange(recFil4Value, compDataMap, fil2Value, fil3Value) {
    setFil4Value(recFil4Value);

    setFil5aValue("0000-00-00");
    setFil5bValue("0000-00-00");

    updateChart(compDataMap, fil2Value, fil3Value, recFil4Value);
  }

  function fil5aValueChange(recFil5aValue,fil5bValue, compDataMap, fil2Value, fil3Value) {
    console.log(recFil5aValue)
    console.log(new Date(recFil5aValue))
    setFil5aValue(recFil5aValue);
    updateChartByDateRange(compDataMap, fil2Value, fil3Value, recFil5aValue,fil5bValue);
  }

  function fil5bValueChange(fil5aValue,recFil5bValue, compDataMap, fil2Value, fil3Value) {
    setFil5bValue(recFil5bValue);
    updateChartByDateRange(compDataMap, fil2Value, fil3Value, fil5aValue,recFil5bValue);
  }

  function tabClickHandler(selectedIndex,tabName,compDataMap, fil2Value, fil3Value) {
    //setTabChanged(true);
    setTabFocusIndex(selectedIndex)
    //setCurTabName(tabName);

    options.scales.x.title.text = props.passMap.axisLabels[topicIndex][0][selectedIndex]
    //options.scales.y.title.text = 


    // setXAxisLabel(props.passMap.axisLabels[topicIndex][0][selectedIndex])
    fil4ValueChange(tabName, compDataMap, fil2Value, fil3Value)
  }



  //To prevent date range from appearing when the year is selected as All
  function getTabNames(tabNames,fil2Value){
    if(fil2Value=="All"){

      const index = tabNames.indexOf("Date Range");
      tabNames.splice(index,1);
    
    }
      return tabNames;
    
  }

  return (
    <div className="grid p-cust-topHead pb-28">
      <div className="flex justify-center items-center gap-16">
        <div className="flex gap-4 p-8">
          <div>Year</div>
          <select
            onChange={(e) =>
              fil2ValueChange(e, compDataMap, fil3Value, fil4Value)
            }
          >
            {props.passMap.filValues[topicIndex][0].map((optionName) => {
              return <option>{optionName}</option>;
            })}
          </select>
        </div>
        
        <div className="flex gap-4">
          <div>
            Job</div>
          <select
            onChange={(e) =>
              fil3ValueChange(e, compDataMap, fil2Value, fil4Value)
            }
          >
            {props.passMap.filValues[topicIndex][1].map((optionName) => {
              return <option value={optionName}>{optionName}</option>;
            })}
          </select>
        </div>

           {/* Buttons for Day,Year, Month
          */}
        
        <TabContainer
          // tabNameList={props.passMap.inputCategories}
          tabNameList={getTabNames(tabNames,fil2Value)}
          tabFocusIndex={tabFocusIndex}
          tabClickHandler={(focusIndex) => tabClickHandler(focusIndex,tabNames[focusIndex],compDataMap, fil2Value, fil3Value)}
        />
        {fil4Value=="Date Range" && fil2Value != "All" &&
        <div>
            <InputDate
              key={`a`} //passDataObj has all data from props object
              inputName={""}
              inputValue={fil5aValue}
              selYearValue={fil2Value}
   
              onChangeFn={(e) => fil5aValueChange(e.target.value,fil5bValue,compDataMap, fil2Value, fil3Value)}
            />
        <InputDate
              key={`b`} //passDataObj has all data from props object
              inputName={""}
              inputValue={fil5bValue}
              selYearValue={fil2Value}
           
              onChangeFn={(e) => fil5bValueChange(fil5aValue,e.target.value,compDataMap, fil2Value, fil3Value)}
            />
        </div>

        }

      </div>
      <div className="w-9/12 mx-auto items-center justify-center">
        {chartReady &&
        <Line options={{ ...chartOptions }} data={{ ...chartData }} />
        }
      </div>
      {/* {showTotal &&
      <div className="absolute left-10 top-20 flex">
        <span>{totalMap.label}</span>
        <span>{totalMap.value}</span>
      </div>
      } */}
      {/* <div className="absolute w-full h-24 bottom-0 flex justify-center items-center">
        {bottomContainer}
        </div> */}
        <FloatingButton btnLabel="Back" positionRight="10" positionTop="20" btnClickHandler={()=>props.backBtnClickHandler()} />
    </div>
  );
}

function getCompRefValue(value, filValue) {
  switch (filValue) {
    case "Year": {
      return new Date(parseInt(value) * 1000).getFullYear();
    }
    case "Month": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getMonth(), "Month");
    }
    case "Day": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getDay(), "Day");
    }
    case "Hour": {
      return getTimeLabel(new Date(parseInt(value) * 1000).getHours(), "Hour");
    }
    case "Date Range": {
      return getTimeLabel(getDayOfTheYear(new Date(parseInt(value) * 1000)), filValue);
    }
  }
}
