import React, { Component } from "react";

export default function FixedText(props) {
  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value
  return (
    <div className="grid grid-cols-cust-2g gap-1 bg-quaternary-xlight p-3">
      <label>{props.inputLabel}</label>
      <div className="flex justify-center">
        {props.inputValue}
      </div>
    </div>
  );
}
