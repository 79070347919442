import React from "react";

export default function BlankRecordStrip(props) {
  console.log(props);

  return (
    <div className="w-11/12 mt-1 mx-auto">
      <div
        className={`title-strip w-full grid mt-1
         bg-quartenaryLight rounded-sm border border-borderStd`}
      >
        <div className="value-strip-unit text-center text-lightBackText">
          NO RECORDS FOUND
        </div>
      </div>
    </div>
  );
}

// typeof props.passDataMap[props.labelDbNotations[index]] == "object"
//   ? props.passDataMap[props.labelDbNotations[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbNotations[index]]
