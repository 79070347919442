import React, { useEffect, useRef, useState } from "react";


// export default function MapCont(props) {
//   const center = {
//     lat: -3.745,
//     lng: -38.523
//   }

//   return (
//   <Wrapper apiKey={"AIzaSyCe-65tykWXRtZ22bY1AuOG1Dv5B6AzSd8"}>
//     <MyMapComponent center={center} zoom={4}/>
//   </Wrapper>
//   );
// }


export default function MyMapComponent(props) {
  const ref = useRef();
  const [latLong,setLatLong] = useState("")

  const [markers,setMarkers] = useState([]);

  console.log(markers);

  useEffect(() => {

    const center = props.center;
    const zoom = props.zoom;

    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      draggableCursor: 'default'
    });

    if (markers.length==0){
      const marker = new window.google.maps.Marker({position:center});
      const tMarkers = [marker];
      tMarkers.push(marker);
      setMarkers([...tMarkers])

    }else{
      markers.forEach((marker)=>{
        marker.setMap(map);
      })
    }




    console.log("loaded map");




    map.addListener("click",(mapsMouseEvent)=>{

      if(markers.length !=0){
        markers.forEach((marker)=>{
          marker.setMap(null);
        });
        markers.length=0;
      };
      
      const marker = new window.google.maps.Marker({position:mapsMouseEvent.latLng});
      
      const tMarkers = [marker];
      tMarkers.push(marker);

      setMarkers([...tMarkers])

      //const tMarkers = [marker];
      // marker.setMap(map);

      props.passLatLong(mapsMouseEvent.latLng.toJSON());

      console.log(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2))
    })
  });

  return (
  <div><div ref={ref} id="map" className={`${props.classValues} map`}/>
  <div>{latLong}</div>
  </div>
  );
}
