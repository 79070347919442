import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import ImageCont from "./ImageCont";
import LoadSpinner from "./LoadSpinner";
import RecordBanner2 from "./RecordBanner2";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";

//const db=getFirestore(firebase);

//function to create an empty field object with keys of the templateObj passed to it
function createInitialObj(templateObj) {
  const obj = {};

  templateObj[templateObj.mainKey].forEach((field) => {
    obj[field] = "";
  });

  return obj;
}

export default function BannerListFilterCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  const [listValuesCollMap, setListValuesCollMap] = useState({
    listValuesColl: [],
    slNos: [],
  });
  const [pageListValuesColl, setPageListValuesColl] = useState([]);
  const [pageLimit, setPageLimit] = useState(2);
  const [recordCount, setRecordCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  //const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    console.log(props.passMap.storeBranchUid);
    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/pastOrders`
      ),
      orderBy("docCreationDate", "desc"),
      limit(2)
    );

    const dbDocs = await getDocs(collQuery);

    if (!dbDocs.empty) {
      setListValuesCollMap(
        extractAndSetValuesCollMap(dbDocs, listValuesCollMap, 1)
      );
    } else {
      alert("no docs");
    }
  }

  function extractAndSetValuesCollMap(dbDocs, listValuesCollMap, pageNo) {
    const tMap = {};
    let tColl = [];
    let tArray1 = [];

    const dbNotations = [
      "orderIds",
      "orderStartDateTimes",
      "orderCustomerNames",
      "orderJobNames",
      "orderCosts",
      "orderPaymentModes",
      "orderResponsibleEmployeeNames",
      "orderJobItemDescs",
      // "orderCompletionDateTimes",
      // "orderCustomerUids",
      // "orderResponsibleEmployeeUids",
      // "orderAcceptToJobStartDurations",
      // "orderJobStartToJobEndDurations",
      // "orderEstdWaitTimes",
      // "orderItemIds",
    ];

    const sRecordCount =
      dbDocs.docs[0].data().recordSlNos[
        dbDocs.docs[0].data().recordSlNos.length - 1
      ];

    console.log(sRecordCount);

    setRecordCount(sRecordCount);

    dbDocs.forEach((doc) => {
      const docData = doc.data();
      const slNos = doc.data().recordSlNos;

      tArray1 = tArray1.concat(slNos);

      console.log(doc.data().recordSlNos[doc.data().recordSlNos.length - 1]);

      for (let x = 0; x < slNos.length; x++) {
        const tArray = [];
        //creating the list value array and pushing it to listValuesColl
        dbNotations.forEach((dbNotation) => {
          tArray.push(docData[dbNotation][x]);
        });
        tColl.push(tArray);
      }
    });

    //Sorting the Array as per descending Sl No

    const tMapA = {};

    for (let i = 0; i < tArray1.length - 1; i++) {
      tMapA["elem1"] = tArray1[i];

      for (let j = i + 1; j < tArray1.length; j++) {
        const elem2 = tArray1[j];

        if (tMapA["elem1"] < elem2) {
          tMapA["elem1"] = elem2;
          const tListValues = tColl[j];

          tArray1.splice(j, 1);
          tColl.splice(j, 1);

          tArray1.splice(i, 0, elem2);
          tColl.splice(i, 0, tListValues);
        }
      }
    }

    tMap.listValuesColl = [...listValuesCollMap.listValuesColl, ...tColl];

    tMap.slNos = [...listValuesCollMap.slNos, ...tArray1];

    displayPage(pageNo, tMap, sRecordCount);

    console.log(tMap);
    return tMap;
  }

  function displayPage(sPageNo, sListValuesCollMap, sRecordCount) {
    setPageNo(sPageNo);
    console.log(sPageNo);

    console.log(sListValuesCollMap);

    const slNoArray = sListValuesCollMap.slNos;

    console.log(sRecordCount);

    // const pageLimit = 3;

    const bottomSlOfPage =
      sRecordCount + 1 - sPageNo * pageLimit < 1
        ? 1
        : sRecordCount + 1 - sPageNo * pageLimit;
    const topSlOfPage =
      bottomSlOfPage + pageLimit - 1 < 1
        ? 1
        : sRecordCount - (sPageNo - 1) * pageLimit;

    console.log(bottomSlOfPage, topSlOfPage);

    console.log(slNoArray);

    const slQueryArray = [];

    [topSlOfPage, bottomSlOfPage].forEach((item) => {
      if (slNoArray.indexOf(item) == -1) {
        slQueryArray.push(item);
      }
    });

    if (slQueryArray.length == 0) {
      assignListValues(topSlOfPage, bottomSlOfPage, sListValuesCollMap);
    } else {
      fetchAndSetListValues(
        topSlOfPage,
        bottomSlOfPage,
        slQueryArray,
        sListValuesCollMap,
        sPageNo
      );
    }
  }

  function assignListValues(startSlNo, endSlNo, sListValuesCollMap) {
    const rArray = [];

    console.log("here1");

    const slNoArray = sListValuesCollMap.slNos;
    const listValuesColl = sListValuesCollMap.listValuesColl;

    for (let y = startSlNo; y >= endSlNo; y--) {
      rArray.push(listValuesColl[slNoArray.indexOf(y)]);
    }

    console.log(rArray);

    setPageListValuesColl(rArray);
    props.stopSpinner();
  }

  async function fetchAndSetListValues(
    startSlNo,
    endSlNo,
    slQueryArray,
    sListValuesCollMap,
    sPageNo
  ) {
    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
      ),
      where("recordSlNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setListValuesCollMap(
      extractAndSetValuesCollMap(dbDocs, sListValuesCollMap, sPageNo)
    );
  }

  function renderPagination(
    sPageLimit,
    sListValuesCollMap,
    sRecordCount,
    sPageNo
  ) {
    const pageCount = Math.ceil(sRecordCount / sPageLimit);

    const rArray = [];

    console.log(sListValuesCollMap);

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(x + 1, sListValuesCollMap, sRecordCount)}
          className={`p-cust-3 ${
            x + 1 == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbNotations);
  console.log(props.passMap);
  console.log(props.passListDataArray);
  //const pageNos = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div className="">
      <div className="h-40 flex flex-col justify-center items-center">
        <div>
          <input
            className="p-cust-2 border-std"
            id="date-from"
            type="date"
          ></input>

          <input
            className="p-cust-2 border-std"
            id="date-to"
            type="date"
          ></input>
          <button
            className="p-cust-3 border-std"
            // onClick={() =>
            //   displayPagesBasedOnFilter(
            //     document.getElementById("date-from").value,
            //     document.getElementById("date-to").value
            //   )
            // }
          >
            Go
          </button>
        </div>

        <div className="flex mt-8 border-page-no">
          {renderPagination(pageLimit, listValuesCollMap, recordCount, pageNo)}
        </div>
      </div>
      <div className="bg-primary-100">
        {pageListValuesColl.map((listValues, index) => {
          return (
            <RecordBanner2
              key={index + 1}
              index={index}
              slNo={index + 1}
              labelNames={props.passMap.labelNames}
              listValues={listValues}
              btnLabels={checkAndReturnBtnLabels(
                props.passMap,
                props.passListDataArray[index]
              )}
              fnName={props.passMap.fnName}
              passDataMap2={props.passMap.settings}
              actionHandler={(data) => props.actionHandler(data)}
            />
          );
        })}
        {props.passListDataArray.length == 0 && (
          <div className="h-full flex items-center justify-center">
            No Records Found
          </div>
        )}
      </div>
      {bottomContainer}
      {/* {showSpinner && <LoadSpinner />} */}
    </div>
  );
}

function checkAndReturnBtnLabels(passMap, passArrayElement) {
  const fnName = passMap.fnName;

  if (fnName == "Check Current Orders") {
    if (passArrayElement.orderStatus == "pendingPayment") {
      return [...passMap.btnLabels, "Confirm Payment"];
    } else {
      return passMap.btnLabels;
    }
  } else {
    return passMap.btnLabels;
  }
}
