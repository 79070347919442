
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ReactComponent as CustomerIcon } from "../images/customer.svg";
import { ReactComponent as EmployeeIcon } from "../images/mechanic.svg";
import { ReactComponent as StoreIcon } from "../images/store-branch.svg";
import { ReactComponent as StoreBrandIcon } from "../images/store-brand.svg";
import {
  child,
  database,
  db,
  editUserInputObj,
  functions as fbFunctions,
  get,
  httpsCallable,
  query,
  rbaseRef,
  update,
  where
} from "../Utils";
import ActionBtnContDyn from "./ActionBtnContDyn";
import BottomContainer from "./BottomContainer";
import BtnNormal from "./BtnNormal";
import BtnNormalSelAction from "./BtnNormalSelAction";
import ButtonIconTitle from "./ButtonIconTitle";
import { getBtnImgSize } from "./HelperViewFns";
import LoadSpinner from "./LoadSpinner";
import MarksCont from "./MarksCont";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxMessagePromptWithMultipleOps from "./ModalBoxMessagePromptWithMultipleOps";
import ModalBoxWithInput from "./ModalBoxWithInput";
import { generatePdfStudent, getRbasePathForAccountSelection, getRbaseValueByPath } from "./projectFns";
import { addMarksSelectionSchemeMap } from "./viewProps";
//import { ReactComponent as EmployeeStatIcon } from "../images/employee-stat.svg";
// const qrCode = window.require("qrcode");
// const PDFDocument = window.require("pdfkit");
// const blobStream = window.require("blob-stream");

//@start
export default function FnSelelectFilterCont2(props) {

  console.log(props)
  const categories = props.schemeMap.categories
  const mapKeys = props.schemeMap.mapKeys
  const catDbKeys = props.schemeMap.catDbKeys
  const navTreeNodeKeys = props.schemeMap.navTreeNodeKeys

  const [showActionBtnCont, setShowActionBtnCont] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPromptOp, setShowPromptOp] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [showModalBoxInput, setShowModalBoxInput] = useState(false);
  const [modalMap, setModalMap] = useState({});
  const [showPrompt, setShowPrompt] = useState(false);
  const [navTreeNodeIds,setNavTreeNodeIds] = useState([]);
  const [navTreeNodeValues,setNavTreeNodeValues] = useState([]);
  const [navTreeNodeValMap,setNavTreeNodeValMap] = useState({...props.passMap.initNodeValMap});
  const [navTreeNodeDbMapObj,setNavTreeNodeDbMapObj] = useState({});
  const [viewSequence,setViewSequence] = useState(0)

  const [students,setStudents] = useState([])


  const navFlowCond = true;
  const intermediateSelectCond = false;
  const navTreeLength = props.schemeMap.mapKeys.length;



  // const [navTreeMaps, setNavTreeMaps] = useState([...getInitNavTreeMaps(props.passMap.navTreeStartIndex,categories)
  // ]);

  const [navTreeMaps, setNavTreeMaps] = useState([]);

  const [navTreePosIndex, setPosIndex] = useState(props.passMap.navTreeStartIndex);
  const [selUnitMap,setSelUnitMap]=useState({});


  useEffect(()=>{
    (async () => await initializeData())()

  },[])



  async function initializeData(){

    const startIndex = props.passMap.navTreeStartIndex
    const initDataMapKey = props.schemeMap.mapKeys[0]

    const selectionSource = props.schemeMap.selectionSources[startIndex];
    const dbMapObj = selectionSource=="memory" ?  props.listenMaps[props.schemeMap.mapKeys[startIndex]] : await getRbaseValueByPath(getRbasePathForAccountSelection(props.schemeMap.selectionRbaseDataPath[startIndex],props.passMap.initNodeValMap))

    console.log(getRbasePathForAccountSelection(props.schemeMap.selectionRbaseDataPath[startIndex],props.passMap.initNodeValMap))
    console.log(await getRbaseValueByPath(getRbasePathForAccountSelection(props.schemeMap.selectionRbaseDataPath[startIndex],props.passMap.initNodeValMap)))

    const unitNavTreeMap={
      category:categories[props.passMap.navTreeStartIndex],
      parentUid: startIndex == 0 ? "NA" : props.passMap.parentUid,
      mapOfMaps:props.mapOfMaps,
      selections:[...generateSelectionsFromMapOfMaps(dbMapObj)]
    }



    console.log(unitNavTreeMap)

    const intiNavTreeMaps = [];
    const initNavTreeNodeIds = [];
    const initNavTreeNodeValMaps = [];
    const initNavTreeNodeDbMaps = []

    for(let x=0; x<=startIndex; x++){
      if(x<startIndex){
        intiNavTreeMaps.push({})
        initNavTreeNodeIds.push("")
        initNavTreeNodeDbMaps.push({})

        // tNavTreeNodeIds.push(selMap[navTreeNodeType])
        // tNavTreeNodeValMap[navTreeNodeKey] = selMap[navTreeNodeType]
        // tNavTreeNodeDbMap[catDbKey] = selMap["dbMap"]

      }else{
        intiNavTreeMaps.push(unitNavTreeMap)
      }
    }
    
    setNavTreeMaps(intiNavTreeMaps)
    setNavTreeNodeIds(initNavTreeNodeIds)
    // setNavTreeNodeValMap(init)


//For Smaller Scope Access Roles like Institute
    const initNavTreeNodeDbMapObj = await getNavTreePreSelectionMaps(addMarksSelectionSchemeMap,startIndex,props.passMap.initNodeValMap)

    async function getNavTreePreSelectionMaps(schemeMap,navTreeStartIndex,dataMap){
        const tNavTreeNodeDbMapObj = {}

        for(let x=0; x<navTreeStartIndex; x++){
          const preSelectionRbasePath = schemeMap.preSelectionRbaseDataPaths[x];
          const catDbKey = schemeMap.catDbKeys[x];

          const nodeDbMapOfIndex = await getRbaseValueByPath(getRbasePathForAccountSelection(preSelectionRbasePath,dataMap));

          tNavTreeNodeDbMapObj[catDbKey] = nodeDbMapOfIndex;
        }

        return tNavTreeNodeDbMapObj
    }

    
   setNavTreeNodeDbMapObj(initNavTreeNodeDbMapObj)

  }




  function getInitNavTreeMaps(startIndex,categories){

    console.log(startIndex,categories)

    const maps = []

    for(let x=0;x<=startIndex;x++){
        if(x==startIndex){
          const initDataMapKey = props.schemeMap.mapKeys[0]
          maps.push({
            category:categories[props.passMap.navTreeStartIndex],
            parentUid:"NA",
            selections:[...generateSelectionsFromMapOfMaps(props.listenMaps[initDataMapKey],startIndex)]})
        }else{
          maps.push({})
        }

    }
    console.log(maps)
    return maps
  }


  const navTreeStartIndex = props.passMap.navTreeStartIndex;

  const selectionFilterColor = "invert(10%) sepia(65%) saturate(7439%) hue-rotate(5deg) brightness(99%) contrast(117%)"

  console.log(props)

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );


  //@2
  async function functions(fnName,data){
  
    console.log(data)

    let dialogTitle = "" 
    let dialogMsg = ""
    let opBtnLabels = ""
    let opSuccessMsgs  = ""
    let opBtnAlertLevels = ""

    const sPosIndex = data.posIndex;
    const sNavTreeMaps = "navTreeMaps" in data ? data.navTreeMaps : [];
    const selMap = "selMap" in data ? data.selMap : {};
    const navTreeNodeIds = "navTreeNodeIds" in data ? data.navTreeNodeIds : []
    const navTreeNodeValMap = "navTreeNodeValMap" in data ? data.navTreeNodeValMap : {}
    const navTreeNodeDbMapObj = "navTreeNodeDbMapObj" in data ? data.navTreeNodeDbMapObj : {}

    if(fnName=="select"){

      setSelUnitMap(selMap);
      setShowActionBtnCont(true);
      updateNavTreeMaps(sPosIndex,sNavTreeMaps,selMap,"select")

    }else if(fnName=="enter"){

      console.log(data)

      let proceedToEnter = true;
      console.log(selMap)
      const navTreeNodeType = props.schemeMap.navTreeNodeTypes[sPosIndex]; //ex - name, uid
      const catDbKey = props.schemeMap.catDbKeys[sPosIndex];
      const navTreeNodeKey = props.schemeMap.navTreeNodeKeys[sPosIndex];
      const tNavTreeNodeIds = [...navTreeNodeIds]
      const tNavTreeNodeValMap = {...navTreeNodeValMap}
      const tNavTreeNodeDbMap = {...navTreeNodeDbMapObj}

      console.log(navTreeNodeIds,sPosIndex)

      if(tNavTreeNodeIds.length == sPosIndex){ //destination tree has not been navigated to beofore, this condnto check if the next tree has already been navigated before or not, if it has navigated before then the condition will be false else will be true

        tNavTreeNodeIds.push(selMap[navTreeNodeType])
        tNavTreeNodeValMap[navTreeNodeKey] = selMap[navTreeNodeType]
        tNavTreeNodeDbMap[catDbKey] = selMap["dbMap"]

        console.log(tNavTreeNodeValMap,navTreeNodeKey)

      }else{ // tree has been navigated before
        tNavTreeNodeIds.splice(sPosIndex,1,selMap[navTreeNodeType])
        tNavTreeNodeValMap[navTreeNodeKey] = selMap[navTreeNodeType]
      }

      {

      }

      console.log(tNavTreeNodeIds,tNavTreeNodeValMap,tNavTreeNodeDbMap)
      setNavTreeNodeIds(tNavTreeNodeIds)
      setNavTreeNodeValMap(tNavTreeNodeValMap)
      setNavTreeNodeDbMapObj(tNavTreeNodeDbMap)

      // const childCategory = ["Class","Section","Student"];
      // const categoryChildMaps = ["fetchFromDb_classes","fetchFromDb_sections","fetchFromDb_students"];

      // if(categories.includes(category)){

      //   const index = categories.indexOf(category);

      //   const categoryUid = selMap.uid;
      //   const childMap = categoryChildMaps[index]
      //   const childCount = categoryUid in props.listenMaps[childMap] ? Object.keys(props.listenMaps[childMap][categoryUid]).length : 0;

      //   if(childCount==0){
      //     setPromptMap({
      //       status:false,
      //       title:`No ${childCategory[index]}`,
      //       message:`There is no ${childCategory[index]} under this ${category}`,
      //       opBtnAlertLevels:[["low"]],
      //     })

      //     proceedToEnter = false;

      //     setShowPrompt(true)

      //    }
      // }


      const endOfSelectionCond = sPosIndex == navTreeLength-1;


      if(proceedToEnter){
        if(endOfSelectionCond){
          actFunctions("initializeData",{...data,navTreeNodeValMap:tNavTreeNodeValMap,navTreeNodeDbMapObj:tNavTreeNodeDbMap})
        }else{
          updateNavTreeMaps(sPosIndex+1,sNavTreeMaps,selMap,"",tNavTreeNodeIds,tNavTreeNodeValMap)
        }
     
      }

    }else if(fnName=="back"){
      setShowActionBtnCont(false)

      const tNavTreeNodeIds = [...navTreeNodeIds]
      
      tNavTreeNodeIds.pop()
      setNavTreeNodeIds(tNavTreeNodeIds)
      updateNavTreeMaps(sPosIndex-1,sNavTreeMaps,selMap,"",tNavTreeNodeIds)

    }else if(fnName=="backViewSequence"){
        setViewSequence(0)
    }else if(fnName=="edit"){
      setShowSpinner(true);

      const category = categories[sPosIndex]
      const catDbKey = catDbKeys[sPosIndex]
      const parentUid = sNavTreeMaps[sNavTreeMaps.length-1].parentUid;
      const rBasePath = parentUid=="NA" ? `${catDbKey}/${selMap.uid}` :  `${catDbKey}/${parentUid}/${selMap.uid}`;

            const payload = {
              userUid:selMap.uid,
              userDomain:category, //have to include userDomain also as we have to go through two cloud functions, one (updateUserLoginDetails) has role as userDomain and other (getUserDataByClient) role as userRole
              userRole:category,
              parentUid:parentUid
            }

            console.log(payload)

                try{
                    const cloudFnCall = httpsCallable(functions,"getUserDataByClient");
                    const op = await cloudFnCall(payload);

          
                    if(op === null){

                      setShowSpinner(false);
                      displayPrompt("Error in Fetching Data")
                      return
                    }

                    const result = op.data;
                    console.log(result)

                    if(!result.status){
                      setShowSpinner(false);
                      displayPrompt(result.message)
                      return
                    }
                    
                    const resultData = result.data

                    const userMap = {}
                      // userMap["userName"] = resultData.displayName;
                      userMap["userEmail"] = resultData.email;
                      userMap["password"] = "";


                      const opBtnLabels = ["Submit"]
                      const opBtnAlertLevels = ["high"]
                      const opSuccessMsgs  = [`${category} Successfully Edited`]


                      const editFn = async (data) => {
                        const cloudFnCall = httpsCallable(functions,"updateUserLoginDetails");
                        const op = await cloudFnCall(data.payload);
                        return op;
                      }
                  
                    setModalMap({
                      ...editUserInputObj,
                      inpValMap:userMap,
                      extraValMap:payload,
                      isModal:true,
                      fnType: "modal",
                      tabOptionsReqd: false,
                      title:`Edit ${category}`,
                      opBtnLabels:opBtnLabels,
                      opBtnAlertLevels:opBtnAlertLevels,
                      opSuccessMsgs:opSuccessMsgs,
                      opFns:[editFn]
                    })

            
                     setShowModalBoxInput(true);


              }catch(error){
                displayPrompt(error)
              }
              setShowSpinner(false);

    }else if(fnName=="enable"){

      setShowSpinner(true);
      const category = categories[sPosIndex]
      const catDbKey = catDbKeys[sPosIndex]
      const parentUid = sNavTreeMaps[sNavTreeMaps.length-1].parentUid;
      const rBasePath = parentUid=="NA" ? `${catDbKey}/${selMap.uid}` :  `${catDbKey}/${parentUid}/${selMap.uid}`;


      await update(child(rbaseRef(database),rBasePath),{isDisabled:false})            

        const payload = {
         userUid:selMap.uid,
         userRole:category,
         parentUid:parentUid
       }

        const cloudFnCall = httpsCallable(functions,"enableUserAccountByClient");
        const op = await cloudFnCall(payload);

        console.log("callResult",op.data)
 
      if(op.data.status){
        updateNavTreeMaps(sPosIndex,sNavTreeMaps,selMap,"enable");
      }

        setShowSpinner(false);

    }else if(fnName=="disable"){

      console.log(sNavTreeMaps)

      const category = categories[sPosIndex]
      const catDbKey = catDbKeys[sPosIndex]
      const parentUid = sNavTreeMaps[sNavTreeMaps.length-1].parentUid;
        
      const dialogTitle = `Disable ${category}` 
      const dialogMsg = `Disabling the ${category} will prevent the particular ${category} account access.Do you wish to Proceed?`

      const opBtnLabels = ["Proceed to Disable"]
      const opSuccessMsgs  = [`${category} Disabled Successfully`]
      const opBtnAlertLevels = ["high"]

      const rBasePath = parentUid=="NA" ? `${catDbKey}/${selMap.uid}` :  `${catDbKey}/${parentUid}/${selMap.uid}`;


      console.log(catDbKey,parentUid,selMap.uid,rBasePath)


      const disableFn = async () =>{
             await update(child(rbaseRef(database),rBasePath),{isDisabled:true})            


             const payload = {
              userUid:selMap.uid,
              userRole:category,
              parentUid:parentUid
            }

             const cloudFnCall = httpsCallable(functions,"disableUserAccountByClient");
             const op = await cloudFnCall(payload);
            
              updateNavTreeMaps(sPosIndex,sNavTreeMaps,selMap,"disable")
         
      }


        const opFns = [disableFn]


      setPromptMap({
        status:false,
        title:dialogTitle,
        message:dialogMsg,
        opBtnLabels:opBtnLabels,
        opBtnAlertLevels:opBtnAlertLevels,
        opSuccessMsgs:opSuccessMsgs,
        opFns:opFns
      })
      
      setShowSpinner(false);
      setShowPromptOp(true)

    }else if(fnName=="delete"){

      const category = categories[sPosIndex]
      const catDbKey = catDbKeys[sPosIndex]
      const parentUid = sNavTreeMaps[sNavTreeMaps.length-1].parentUid;
      
      let clearToDelete = true;

      if(category=="Store Brand"){

        const brandUid = selMap.uid;

        const branchCount = brandUid in props.listenMaps["storeBranchesMap"] ? Object.keys(props.listenMaps["storeBranchesMap"][brandUid]).length : 0;


        clearToDelete = branchCount == 0;

        if(!clearToDelete){

          setPromptMap({
            status:false,
            title:"Deletion Not Possible",
            message:`It seems the brand that your deleting has ${branchCount} No/Nos of branch assigned to it. Please ensure all the branches are deleted before you delete the brand.`,
            opBtnAlertLevels:[["low"]],
          })

         }
        

      }


      if(clearToDelete){


      const payload = {
        userUid:selMap.uid,
        userRole:category,
        parentUid:parentUid
      }

    
      dialogTitle = `Delete ${category}` 
      dialogMsg = `Deleting the ${category} will delete all data associated with the ${category} account.Do you wish to Proceed?`

      opBtnLabels = ["Proceed to Delete"]
      opSuccessMsgs  = [`${category} Deleted Successfully`]
      opBtnAlertLevels = ["high"]

      const deleteFn = async () =>{

        const cloudFnCall = httpsCallable(functions,"deleteUserAccountByClient");
        const op = await cloudFnCall(payload);

        if(op === null){
          
          setShowSpinner(false);
          displayPrompt("Error in Fetching Data")
          return
        }

        const result = op.data

        if(!result.status){
          setShowSpinner(false);
          displayPrompt(result.message)
          return
        }
                
          updateNavTreeMaps(sPosIndex,sNavTreeMaps,selMap,"")
      }


        const opFns = [deleteFn]


      setPromptMap({
        status:false,
        title:dialogTitle,
        message:dialogMsg,
        opBtnLabels:opBtnLabels,
        opBtnAlertLevels:opBtnAlertLevels,
        opSuccessMsgs:opSuccessMsgs,
        opFns:opFns
      })
      
      setShowSpinner(false);
      setShowPromptOp(true)

    }else{
      setShowSpinner(false);
      setShowPrompt(true)
    }


    }else if(fnName=="selectActionClick"){
      console.log(data)
      const clickedBtn = data.btnSchemeMap.clickedBtnLabel;
      if(clickedBtn==props.schemeMap.selectionBtnLabels[0]){//add or edit marks
        setViewSequence(1)
      }
    }

  }

//@3
  async function actFunctions(fnName,data){


    switch(fnName){
      case "initializeData":{
        try{

          console.log(data)

          const navTreeNodeValMap = data.navTreeNodeValMap;
          const instituteUid = navTreeNodeValMap.instituteUid
          const examName = navTreeNodeValMap.examName
          const className = navTreeNodeValMap.className
          const sectionName = navTreeNodeValMap.sectionName


          console.log(instituteUid,examName,className,sectionName)

          // const examDoc = await getDocs(doc(db, `institutes/t8IvfUhDs0mDloRmdo0S/exams`));

          const collQuery =  query(collection(db,`institutes/${instituteUid}/examMarks`),
      where("examName","==",examName),where("className","==",className),where("sectionName","==",sectionName));

      const collDocs = await getDocs(collQuery)

      console.log(collDocs,collDocs.size,collDocs.docs.length)

      let examMarkMap = {};

      if(collDocs.size==0){

      }else{
        const doc = collDocs.docs[0].data();
        console.log(doc)

        examMarkMap = doc.studentExamMarksMap
      }

          const mapOfMapsRbaseCall = await get(child(rbaseRef(
            database
          ),`students/${instituteUid}/${className}/${sectionName}`));
               
          const  mapOfMaps = mapOfMapsRbaseCall.val()
          console.log(mapOfMaps)
    
          const tColl = []
    
          Object.keys(mapOfMaps).forEach((key,i)=>{
                  const tMap = {}
                  tMap["uid"] = key;
                  tMap["mark"] = key in examMarkMap ? examMarkMap[key] : 0;
                  tMap["presentCond"] = key in examMarkMap ? true : false;
                  tMap["studentRollNo"] = mapOfMaps[key].studentRollNo
                  // tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
                  tMap["name"] = mapOfMaps[key].name

                  tColl.push(tMap);
                });
    
                setStudents(tColl)
    
          }catch(error){
            console.log("rbase fetch error")
          }
    
    
    
        setViewSequence(1)
      }
      break;
      case "marksChange":{
        console.log(data.changeStudentMap,data.students);

        const mark = data.changeStudentMap.event.target.value;

        const studentUid = data.changeStudentMap.student.uid;
        const studentIndex = data.changeStudentMap.studentIndex;

        const tStudents = [...data.students];

        tStudents[studentIndex]["mark"] = parseFloat(mark);


        setStudents(tStudents)
      
      }
      break;

      case "examPresenceChange":{


        const presentCond = data.changeStudentMap.event.target.checked;

        const studentIndex = data.changeStudentMap.studentIndex;

        const tStudents = [...data.students];

        tStudents[studentIndex]["presentCond"] = presentCond;
        tStudents[studentIndex]["mark"] = 0;

        setStudents(tStudents)
      
      }
      break;
      case "saveMarks":{
        
        console.log(data)

        setShowSpinner(true)

        const navTreeNodeDbMapObj = data.navTreeNodeDbMapObj;
        const examMap = navTreeNodeDbMapObj["exam"]
        const instituteMap = navTreeNodeDbMapObj["institute"]

        const studentMarksMap = {}
        const studentUids = []

        for (const [j,studentMap] of data.students.entries()){
          if(studentMap.presentCond){
            studentUids.push(studentMap.uid)
            studentMarksMap[studentMap.uid] = studentMap.mark
          }
        }

        console.log(studentMarksMap,studentUids)

        const payload = {
          examName:data.navTreeNodeValMap.examName,
          examDate:examMap.examDate,
          examType:examMap.examType,
          subjectName:examMap.subjectName,
          className:data.navTreeNodeValMap.className,
          sectionName:data.navTreeNodeValMap.sectionName,
          instituteUid:data.navTreeNodeValMap.instituteUid,
          instituteName:instituteMap.name,
          examAttendedStudentUids:studentUids,
          studentExamMarksMap:studentMarksMap
        }


        console.log(payload)


        const fbFunctionsCall = httpsCallable(fbFunctions, "insertMarks");
        const callResult = await fbFunctionsCall(payload);
        setShowSpinner(false)

        const opResult = callResult.data;

        if(!opResult.status){
          console.log(opResult.errorMessage)
        }

        displayPrompt(opResult.message,"Save Result")

      
      }
      break;

      case "generateMarksSheet":{
        console.log(data)

        setShowSpinner(true)

        await generatePdfStudent({},data.students,"examMarks",{},"download");

        setShowSpinner(false)
      }

    }
          





  }

  function displayPrompt(message,title,status){
    setShowSpinner(false)
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message
    })
  }


  async function disableAccount(map) {
    const serverCall = httpsCallable(functions, "disableUser");
    const result = await serverCall({ uid: map.uid });
    alert(result.data);
  }

 


  async function updateNavTreeMaps(newPosIndex,navTreeMaps,selMap,action,navTreeNodeIds,navTreeNodeValMap){

    console.log(selMap)
    console.log(newPosIndex)

    const tNavTreeMaps = JSON.parse(JSON.stringify(navTreeMaps))
    const selUid = selMap.uid;
   

    if(navTreeMaps.length-1 > newPosIndex){//it means back is pressed

      tNavTreeMaps.pop()
      const curNavTreeMap = tNavTreeMaps[newPosIndex]

      // if(newPosIndex==0){
      //   setShowActionBtnCont(false)
      //   tNavTreeMaps.push(curNavTreeMap)
      // }else{
      const selectionMaps =  curNavTreeMap.selections;
      curNavTreeMap["selections"] = updateSelections(selectionMaps,"default");
      tNavTreeMaps.pop()
      tNavTreeMaps.push(curNavTreeMap)
      // }

    }else if(navTreeMaps.length-1 == newPosIndex){//this is to stay in the same heirarchy level and perform changes in the present list of accounts - like select, disable, enable
      console.log(navTreeMaps)
      const curNavTreeMap = tNavTreeMaps[navTreeMaps.length-1]
      const parentUid = curNavTreeMap.parentUid
      console.log(parentUid,mapKeys[newPosIndex])
      //const mapOfMaps =  parentUid=="NA" ? props.listenMaps[mapKeys[newPosIndex]] : convertSelectionsToMapOfMaps(navTreeMaps[newPosIndex].selections);
      const mapOfMaps =  navTreeMaps[newPosIndex].mapOfMaps

      console.log(mapKeys[newPosIndex],curNavTreeMap.parentUid,newPosIndex,selUid,mapOfMaps)

      curNavTreeMap["selections"] = updateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex,selUid,action);

      tNavTreeMaps.pop()
      tNavTreeMaps.push(curNavTreeMap)

    }else if(newPosIndex > navTreeMaps.length-1){//going deeper the heirerarchy - enter
      setShowActionBtnCont(false)

      const seqUnitMap = {}
      seqUnitMap["category"] = categories[newPosIndex];
      seqUnitMap["parentUid"] = selUid;

      const mapOfMapsKey = mapKeys[newPosIndex];

      let mapOfMaps = {}

      //adding the next navTreeMap based on the selection
      if(mapOfMapsKey.split("_")[0] == "fetchFromRbase"){

        const rbaseNodeName = mapOfMapsKey.split("_")[1];
        const rbasePathStr = props.schemeMap.selectionRbaseDataPath[newPosIndex]
        //const rbasePath = getRbasePathForAccountSelection(rbaseNodeName,navTreeNodeIds);
        const rbasePath = getRbasePathForAccountSelection(rbasePathStr,navTreeNodeValMap);

        console.log(rbasePath);

        // function getRbasePathForAccountSelection(rbaseNodeName,navTreeNodeIds){
        //   console.log(navTreeNodeIds)
        //   const path = `${rbaseNodeName}/${navTreeNodeIds.join("/")}`
        //   return path;
        // }

        function getRbasePathForAccountSelection(rbasePathStr,navTreeNodeValMap){
          console.log(navTreeNodeIds)
          const path = rbasePathStr.split("/").map((pathComp)=>{
            if(pathComp[0]=="$"){
              const keyComp = pathComp.slice(1);
              const actualPath = navTreeNodeValMap[keyComp]
              return actualPath
            }else{
              return pathComp
            }
          }).join("/")
          // const path = `${rbaseNodeName}/${navTreeNodeIds.join("/")}`
          return path;
        }

        const mapOfMapsRbaseRef = rbaseRef(
          database
        );

        try{
        const mapOfMapsRbaseCall = await get(child(mapOfMapsRbaseRef,rbasePath));
              mapOfMaps = mapOfMapsRbaseCall.val()
              console.log(mapOfMaps)
        }catch(error){
          console.log("rbase fetch error")
        }
      }


      // mapOfMaps = selUid=="NA" ? props.listenMaps[mapKeys[newPosIndex]] : props.listenMaps[mapKeys[newPosIndex]][selUid];
      console.log(selUid)
      console.log(mapOfMaps)
      seqUnitMap["mapOfMaps"] = mapOfMaps
      seqUnitMap["selections"] = mapOfMaps ? generateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex) : [];
      tNavTreeMaps.push(seqUnitMap)
    }

    console.log(tNavTreeMaps)

    setPosIndex(newPosIndex)
    setNavTreeMaps(tNavTreeMaps);
  }

  function convertSelectionsToMapOfMaps(selections){
    console.log(selections)
    const mapOfMaps={};
    selections.forEach((map,i)=>{
      mapOfMaps[map.uid] = map;
    })
  }
  
function generateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex){

  console.log(mapOfMaps,newPosIndex)

  const tColl = []
        
  Object.keys(mapOfMaps).forEach((key,i)=>{
    const tMap = {}
    tMap["uid"] = key;
    tMap["name"] = mapOfMaps[key].name
    tMap["isDisabled"] = mapOfMaps[key].isDisabled
    tMap["isSelected"] = false;
    tMap["category"] = categories[newPosIndex]
    tMap["dbMap"] = mapOfMaps[key]
    tColl.push(tMap);
  });

  console.log(tColl)

  return tColl;
}

function updateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex,selUid,action){
  console.log(mapOfMaps,newPosIndex,selUid,action)

  const tColl = []
        
  Object.keys(mapOfMaps).forEach((key,i)=>{
    const tMap = {}
    tMap["uid"] = key;
    tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
    tMap["name"] = mapOfMaps[key].name
    tMap["isSelected"] = selUid==key ? true : action=="default" ? mapOfMaps[key].isSelected : false; 
    tMap["isDisabled"] = (action=="enable" || action=="disable") && selUid==key ? action=="disable" : mapOfMaps[key].isDisabled
    tMap["category"] = categories[newPosIndex]

    if(selUid==key){
      setSelUnitMap(tMap)
    }

    if(action=="default" && mapOfMaps[key].isSelected){
      console.log(tMap)
      setSelUnitMap(tMap)
    }

    tColl.push(tMap);
  });

  console.log(tColl)
  return tColl;
}


function updateSelections(maps,action){
  console.log(maps)
        
  return maps.map((it,i)=>{

    console.log(it,i,action)
    // it["isSelected"] = action=="default" ? maps[i].isSelected : false; 
    it["isSelected"] = false; 

    // if(action=="default" && maps[i].isSelected){
    //   console.log(it)
    //   setSelUnitMap(it)
    // }
    return it
  });
}


function unitSelectionFn(e,navTreePosIndex,navTreeMaps,map){

  console.log(navTreePosIndex,navTreeMaps,map)

  console.log(e);
  // if(navTreePosIndex==navTreeStartIndex){
  //   updateNavTreeMaps(navTreePosIndex+1,navTreeMaps,map)
  // }else{
    setSelUnitMap(map);
    setShowActionBtnCont(true);
    updateNavTreeMaps(navTreePosIndex+1,navTreeMaps,map,"select")
  // }
}

//@return
  return (
  <div className="h-screen w-full absolute top-0 p-cust-topHead">
    <div className={`relative h-full`}>
      {viewSequence==0 && //Selection Screen
      <div className="m-4 grid grid-rows-cust-3c rounded bg-secondaryTransparent10 border-std">
        <div className="text-xl flex justify-center">
          <div className="text-2xl font-bold text-primary p-4 ml-4">{`Select ${categories[navTreePosIndex]}`}</div>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-8 overflow-y-auto">
          {/* {renderSelections(navTreePosIndex)} */}
          {navTreeMaps.length > 0 && navTreeMaps[navTreePosIndex].selections.length != 0 && navTreeMaps[navTreePosIndex].selections.map((map,i)=>
                    <ButtonIconTitle
                    key={map.name}
                    isDisabled={map.isDisabled}
                    btnColorClass={getColorClass(
                      map.isDisabled,
                      map.isSelected,
                      map.isAbsent
                    )}
                    section="Selection"
                    btnColor="primary"
                    btnTitle={map.name}
                    btnImgSize={getBtnImgSize("Dashboard Function")}
                    btnImg={props.schemeMap.selectionImgs[navTreePosIndex]}
                    //clickHandler={(e) => selectionFn(e, map, i)
                    clickHandler={(e)=>functions(getUnitClickCourseAction(intermediateSelectCond,navTreePosIndex,navTreeLength),{event:e,posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,selMap:map,navTreeNodeIds:navTreeNodeIds,navTreeNodeValMap:navTreeNodeValMap,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}
                    ></ButtonIconTitle>
          )}
          {(navTreeMaps.length == 0 || navTreeMaps[navTreePosIndex].selections.length==0) && 
          <span>No Records Found</span> 
          }
        </div>
        <div key={`skksks`}  className="text-xl flex justify-center items-center gap-x-8">
          {showActionBtnCont && <ActionBtnContDyn topic={props.passMap.topic} 
            selAccMap={selUnitMap}
            btnLabels={props.schemeMap.selectionBtnLabels}
            fnHandler={(fnName,data)=>functions(fnName,{btnSchemeMap:data,posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,selMap:selUnitMap,navTreeNodeIds:navTreeNodeIds})}
            closeContHandler={()=>setShowActionBtnCont(false)}/>} 
          {navTreePosIndex > navTreeStartIndex &&
            <BtnNormalSelAction spClass="border border-primary text-primary text-sm" color="" label="Back" clickHandler={() => functions("back",{posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,navTreeNodeIds:navTreeNodeIds})}/>}

        </div>
      </div>
      }
      {viewSequence==1 && 
            <div className="h-full m-4 grid grid-rows-cust-3c rounded bg-secondaryTransparent10 border-std">
              <div className="text-xl flex justify-center">
                <div className="text-2xl font-bold text-primary p-4 ml-4">{`Add Marks`}</div>
              </div>

              <MarksCont 
              students={students} 
              marksChangeHandler={(changeStudentMap)=>actFunctions("marksChange",{changeStudentMap:changeStudentMap,students:students})}
              examPresenceChangeHandler={(changeStudentMap)=>actFunctions("examPresenceChange",{changeStudentMap:changeStudentMap,students:students})}
              />
            {/* <div className="flex items-center justify-center gap-8">
            </div> */}
            <div key={`skksks`}  className="text-xl flex justify-center items-center gap-x-8">
              {showActionBtnCont && 
              <ActionBtnContDyn topic={props.passMap.topic} 
                selAccMap={selUnitMap}
                btnLabels={props.schemeMap.selectionBtnLabels}
                fnHandler={(fnName,data)=>functions(fnName,{btnSchemeMap:data,posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,selMap:selUnitMap,navTreeNodeIds:navTreeNodeIds})}
                closeContHandler={()=>setShowActionBtnCont(false)}/>} 
              {navTreePosIndex > navTreeStartIndex &&
                <BtnNormalSelAction spClass="border border-primary text-primary text-sm" color="" label="Back" clickHandler={() => functions("backViewSequence",{posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,navTreeNodeIds:navTreeNodeIds})}/>}  
            </div>
          </div>
      }
      <div className="absolute right-10 top-10 flex gap-2">
        {viewSequence==1 && 
             <React.Fragment>
                <BtnNormal spClass="" color="greenDark" label="Save" clickHandler={()=> actFunctions("saveMarks",{students:students,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
                <BtnNormal spClass="" color="secondaryDark" label="Generate Sheet" clickHandler={()=>  actFunctions("generateMarksSheet",{students:students,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
              </React.Fragment>
        }
        <BtnNormal spClass="" color="black" label="Back to Dashboard" clickHandler={()=> props.backBtnClickHandler()}/>
      </div>
    </div>


      {showPromptOp && <ModalBoxMessagePromptWithMultipleOps passMap={promptMap} promptClose={()=>setShowPromptOp(false)}/>}
      {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
      {showModalBoxInput && (
        <ModalBoxWithInput
          passMap={modalMap}
          modalCloseHandler={()=>setShowModalBoxInput(false)}
        />
      )}
      {showSpinner && <LoadSpinner/>}
  </div>
);

}

function getUnitClickCourseAction(intermediateSelectCond,navTreePosIndex,navTreeLength){

  if(intermediateSelectCond){
    return "select"
  }else{
    // if(navTreePosIndex == navTreeLength-1){
    //   return "fnView"
    // }else{
      return "enter"
    // }
  }

}

function getBtnIcon(btnName) {
  switch (btnName) {
    case "Customer":
      return CustomerIcon
      case "Store":
        return StoreIcon
        case "Store Brand":
          return StoreBrandIcon
          case "Store Branch":
            return StoreIcon
            case "Employee":
              return EmployeeIcon 
    default:
      return StoreIcon;
  }
}

function getColorClass(disabled, selected, absent) {
  console.log(`disabled=${disabled}, selected=${selected}, absent=${absent}`)
  if (disabled) {
    if (selected) {
      return "svg-pink";
    }
    return "svg-grey-light";
  } else {
    if (absent) {
      if (selected) {
        return "svg-red";
      }
      return "svg-grey";
    } else {
      if (selected) {
        return "svg-red";
      }
      return "";
    }
  }
}

