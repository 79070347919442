
export default function MarksCont(props){
    console.log(props)


    return(<div className="block h-full p-6">
        <div className="flex flex-center gap-8">
            <div>
                <span>Exam Topic : </span>
                <span className="font-bold">{props.examDescription}</span>
            </div>
            <div>
                <span>Total Marks </span>
                {/* {props.editCond && 
                    <input type="number"
                        value={props.totalMarksCount}
                        onChange={e=>props.totalMarksCountChangeHandler(e.target.value)}
                    />
                }
                {!props.editCond &&  */}
                    <span className="font-bold">{props.totalMarksCount}</span>
                {/* } */}
            </div>

         </div>
         <div className="flex flex-col flex-wrap h-full overflow-x-auto gap-4 p-4 text-sm">
            {props.students.length > 0 && props.students.map((student,i)=>{
                if(props.editCond){
                    return(
                        <div className="grid-cols-cust-4f grid h-8 w-cust-25rem bg-primary rounded">
                        <div className="text-primary flex items-center px-2 border-r bg-secondary rounded-l">{student.studentRollNo}</div>
                        <div className="text-darkBackText flex items-center px-2 ">{student.name}</div>
                        <div className="flex-center">
                             <input className="" type="checkbox" checked={student.presentCond} onChange={(e)=>props.examPresenceChangeHandler({event:e,student:student,studentIndex:i})}/>
                        </div>
                        <input type="number"
                            disabled={!student.presentCond}
                            value={student.presentCond ? student.mark : 0}
                            max={100}
                            min={0}
                            onChange={e=>props.marksChangeHandler({event:e,student:student,studentIndex:i})}
                        />
                        </div>
                    )
                }else{
                    return(
                        <div className="grid-cols-cust-3i grid h-8 w-cust-25rem bg-primary rounded">
                        <div className="text-primary flex items-center px-2 border-r bg-secondary rounded-l">{student.studentRollNo}</div>
                        <div className="text-darkBackText flex items-center px-2 ">{student.name}</div>
                        <div className="flex flex-center text-secondary">{student.presentCond ? student.mark : "NA"}</div>
                        </div>
                    )
                }
            }

)}
 {props.students.length == 0 && <div className="h-full flex flex-center text-3xl">No Records Found </div>}
        </div>

    </div>)
}