import React from "react";

export default function RecordLabelUnit3(props) {

  const textColor = props.textColorScheme=="green" ? props.index == 0 ? "text-quaternary-light" : "text-primary" : "text-secondary-2"
  const firstObjClass = props.index == 0 ? `col-span-2 bg-accent-green rounded-t-lg` : `text-sm rounded-b-lg`

  return (
    <div className={` h-7 grid ${firstObjClass} justify-center items-center border-inner-light`}>
      <div className={`font-medium ${textColor} font-montserrat break-words`}>{props.labelValue}</div>
    </div>
  );
}
