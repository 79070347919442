// import XLSX from "xlsx/xlsx.mjs";

import { httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import { functions as fbFunctions } from "../Utils";
import BtnNormal from './BtnNormal';
import { excelDateToJSDate, getDateOnlyFromISO8601DateTimeString } from './genFunctions';
import InputWithControllerCont from './InputWithControllerCont';
import LoadSpinner from './LoadSpinner';
import ModalBoxOpConfPrompt6 from './ModalBoxOpConfPrompt6';
import { getOpPromptMap } from './projectFns';
import StripListContSinglePage from './StripListContSinglePage';
import StripListContSinglePageWithResult from './StripListContSinglePageWithResult';
import TextDisplayAsync from './TextDisplayAsync';
import { addAndViewStudentUploadListSchemeMap, addStudentsBatchPreInpSchemeMap, viewBatchUploadStudentsListSchemeMap } from './viewProps';

const XLSX = require('xlsx')

export default function BatchInputContStudents(props){

    console.log(props)
    const [showPreInputSelectionView,setShowPreInputSelectionView] = useState(true)
    const [showSpreadsheetInput,setShowSpreadsheetInput] = useState(false)
    const [showPreInputDisplayView,setShowPreInputDisplayView] = useState(false)
    const [spreadsheetRecords,setSpreadsheetRecords] = useState([])
    const [successfullBatchSlNos,setSuccessfullBatchSlNos] = useState([])



    const [preInpBatchMap,setPreInpBatchMap] = useState({})
    const [viewSequence,setViewSequence] = useState(0)
    const [showSpinner,setShowSpinner] = useState(false)

    const[showPromptOp,setShowPromptOp] = useState(false)
    const[promptOpMap,setPromptOpMap] = useState({})
    const [showListCont,setShowListCont] = useState(false)
    const [showResultListCont,setShowResultListCont] = useState(false)

    const [multipleUploadMode,setMultipleUploadMode] = useState(false)
    const [uploadStartSlNo,setUploadStartSlNo] = useState(0)
    const [uploadEndSlNo,setUploadEndSlNo] = useState(0)

    const recordUploadLimit = 20

    function getInitInpValMap(inpSchemeMap){
        const initInpValMap = {}
        inpSchemeMap.inpKeys.forEach((inpKey)=>initInpValMap[inpKey]="")

        return initInpValMap  
    }


    async function functions(fnName,data){

        switch(fnName){
            case "preInputSubmission":{
                console.log(data)
                setPreInpBatchMap(data.inpValMap)
                setShowPreInputSelectionView(false)
                setShowPreInputDisplayView(true)
                setShowSpreadsheetInput(true)
            };
            break;
            case "fileInpChange":{

                const e = data.event;

                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(e.target.files[0]);
        
                fileReader.onload = (ev) => {
                    const data = ev.target.result;
                    const workbook = XLSX.read(data,{type:'binary'});
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName]
                    const parsedData = XLSX.utils.sheet_to_json(sheet,{cellDates:true});


                    let multipleUploadModeCond = parsedData.length>recordUploadLimit

                    parsedData.forEach((rowMap,i)=>{
                        console.log(rowMap)
                        const excelDate = rowMap["studentDOB"];

                        if(typeof excelDate == "number"){
                            const jsDate = excelDateToJSDate(excelDate)
                            rowMap["studentDOB"] = getDateOnlyFromISO8601DateTimeString(jsDate.toISOString())
                            //status assignment
                        }

                        rowMap["uploadStatus"] = multipleUploadModeCond ? (i+1) < recordUploadLimit ? "inQueue" : "outOfQueue" : "inProgress";
                    })


           
                    setMultipleUploadMode(multipleUploadModeCond)
                    
        
                    console.log(parsedData)
                    setUploadStartSlNo(1);
                    setUploadEndSlNo(multipleUploadModeCond ? recordUploadLimit : parsedData.length)
                    setSpreadsheetRecords(parsedData)
                    setShowSpreadsheetInput(false)
                    setShowListCont(true)
                    // setViewSequence(1)
                }
            }
            break;

            case "batchAddStudents":{
                console.log(data)

                const selectedBatchInpMaps = data.batchInpMaps.slice(data.uploadStartSlNo-1,data.uploadEndSlNo)

                const payload = {
                    preInpBatchMap:data.preInpBatchMap,
                    batchInpMaps:selectedBatchInpMaps
                }


                const afterOpCompletionFn = (dbOpResult) => {functions("showBatchUploadSummary",{batchInpMaps:data.batchInpMaps,dbOpResult:dbOpResult,uploadStartSlNo:data.uploadStartSlNo,uploadEndSlNo:data.uploadEndSlNo,successfullBatchSlNos:data.successfullBatchSlNos})};

                const opFn = async () => {
                    const fbFunctionsCall = httpsCallable(fbFunctions,"batchAddStudents");
                    const callResult = await fbFunctionsCall(payload);
                    return callResult.data
                }

                const promptMap = getOpPromptMap(addAndViewStudentUploadListSchemeMap,opFn,afterOpCompletionFn)
 
                setPromptOpMap(promptMap)
                setShowPromptOp(true)

                // setShowSpinner(true)

                // setShowSpinner(false)
                // console.log(result.data)
        
            }
            break;
            case "showBatchUploadSummary":{
                console.log(data)
                const successfullBatchSlNos = data.dbOpResult.payload.batchSuccessfullRecordSlNos
                const uploadStartSlNo = data.uploadStartSlNo;
                const uploadEndSlNo = data.uploadEndSlNo;
                const batchInpMaps = data.batchInpMaps;

                // const motherSuccessfullBatchSlNos = data.successfullBatchSlNos;

                const realisedSuccessfullBatchSlNos =  successfullBatchSlNos.map((it)=>parseInt(it)+parseInt(uploadStartSlNo)-1);

                const updatedBatchInpMaps = checkAndUpdateStatusofBatchInpMaps(batchInpMaps,uploadStartSlNo,uploadEndSlNo,"postOperation",realisedSuccessfullBatchSlNos)

                // const finalSuccessfullBatchSlNos = motherSuccessfullBatchSlNos.concat(realisedSuccessfullBatchSlNos)

                // setShowListCont(false)
                // setShowResultListCont(true)
                setSpreadsheetRecords(updatedBatchInpMaps)
                // setSuccessfullBatchSlNos(finalSuccessfullBatchSlNos)
            }
            break;
            case "resetSelection":{
                setShowPreInputDisplayView(false)
                setShowPreInputSelectionView(true);
                setShowSpreadsheetInput(false);
                setShowResultListCont(false)
                setSuccessfullBatchSlNos([])
                setShowListCont(false)
            }
            break;
            case "uploadSlNoChange":{
                console.log(data)

                const slNoMap = data.slNoMap
                const batchInpMaps = data.batchInpMaps

                setUploadStartSlNo(parseInt(slNoMap.startSlNo))
                setUploadEndSlNo(parseInt(slNoMap.endSlNo))


                const updatedBatchInpMaps = checkAndUpdateStatusofBatchInpMaps(batchInpMaps,slNoMap.startSlNo,slNoMap.endSlNo,"changeRange",[]);


                setSpreadsheetRecords(updatedBatchInpMaps)

            }
        }
    }


    function getPreInpDisplayView(schemeMap,i,preInpBatchMap){

        const dbFetchCond = Object.keys(schemeMap.preInpDbFetchSchemeMapObj).includes(schemeMap.preInpKeys[i]);
        const preInpKey = schemeMap.preInpKeys[i]

        if(dbFetchCond){
            const rbaseSchemeMap = schemeMap.preInpDbFetchSchemeMapObj[preInpKey];

            const rbasePath  = `${rbaseSchemeMap.rbasePath}/${preInpBatchMap[rbaseSchemeMap.rbaseNodeKeyName]}/${rbaseSchemeMap.rbaseValueKeyName}`

            return <TextDisplayAsync rbasePath={rbasePath}/>

        }else{
            return <div>{preInpBatchMap[preInpKey]}</div>;
        }
    }



    return(
    <div className="absolute pt-16 h-screen w-full flex flex-col top-0 p-cust-topHead">
            <div className="flex justify-end gap-2 p-2">
                {!showPreInputSelectionView && 
                            <BtnNormal
                            label="Reset Selection"
                            color="red"
                            clickHandler={()=>functions("resetSelection",{})}
                          />
                }
                <BtnNormal
                  label="Back to Dashboard"
                  color="black"
                  clickHandler={()=>props.backBtnClickHandler()}
                />

            </div>
        {viewSequence==0 && 
            <div className="w-full h-full grid grid-rows-cust-2b gap-4">
                <div className="flex rounded px-8 py-4 justify-center gap-12">
                    {showPreInputSelectionView && 
                        <InputWithControllerCont inpElemArrangmentScheme="horizontal" inpSchemeMap = {addStudentsBatchPreInpSchemeMap} initInpValMap={getInitInpValMap(addStudentsBatchPreInpSchemeMap)} submitClickHandler = {(data)=>functions("preInputSubmission",data)} />
                    }
                    {showPreInputDisplayView && viewBatchUploadStudentsListSchemeMap.preInpLabels.map((preInpLabel,i)=>
                        <div className="flex gap-2 text-xl">
                            <div className="font-bold">{preInpLabel}</div>
                            {getPreInpDisplayView(viewBatchUploadStudentsListSchemeMap,i,preInpBatchMap)}
                        </div>
                    )}
                </div>
                <div className="flex justify-center w-full">
                    {showSpreadsheetInput && <div className="h-48 flex flex-col justify-center items-center px-6 py-2 bg-secondaryTransparent30 text-sm">
                        <div className="m-4 text-primary font-bold">Please select the spreadsheet containing the student data </div>
                        <input type="file" onChange={(e)=>functions("fileInpChange",{event:e})} />
                        </div>}
                    {showListCont && 
                        <div className="w-full flex flex-col justify-start items-center gap-8">
                        <StripListContSinglePage 
                            schemeMap={viewBatchUploadStudentsListSchemeMap}  
                            pageStripValueDataMap={{pageStripValueMapColl:spreadsheetRecords,uploadStartSlNo:uploadStartSlNo,uploadEndSlNo:uploadEndSlNo,multipleUploadCond:multipleUploadMode}} pageStripValueMapColl={spreadsheetRecords}
                            uploadSlNoChangeHandler={(data)=>functions("uploadSlNoChange",{slNoMap:data,batchInpMaps:spreadsheetRecords})}
                        />
                        <BtnNormal color="primary" label="Upload" clickHandler={()=>functions("batchAddStudents",{preInpBatchMap:preInpBatchMap,batchInpMaps:spreadsheetRecords,uploadStartSlNo:uploadStartSlNo,uploadEndSlNo:uploadEndSlNo,successfullBatchSlNos:successfullBatchSlNos})} />
                        </div>
                    }
                    {showResultListCont && 
                        <div className="w-full flex flex-col justify-start items-center gap-8">
                         <StripListContSinglePageWithResult 
                            schemeMap={viewBatchUploadStudentsListSchemeMap} 
                         successfullBatchSlNos={successfullBatchSlNos} 
                          pageStripValueDataMap={{pageStripValueMapColl:spreadsheetRecords,uploadStartSlNo:uploadStartSlNo,uploadEndSlNo:uploadEndSlNo,multipleUploadCond:multipleUploadMode}}
                          spreadsheetRecords={spreadsheetRecords}/>
                        </div>
                    }
                </div>
            </div>
        }
{/* 
        {viewSequence==1 && 
        <div className="w-full h-full block">
            <div className="flex gap-8 justify-center">
                {viewBatchUploadStudentsListSchemeMap.preInpLabels.map((preInpLabel,i)=>
                    <div className="flex gap-2">
                        <div>{preInpLabel}</div>
                        {getPreInpDisplayView(viewBatchUploadStudentsListSchemeMap,i,preInpBatchMap)}
                    </div>
                )}
            </div>

            <div className="w-full flex flex-col items-center gap-8">
                <StripListContSinglePage schemeMap={viewBatchUploadStudentsListSchemeMap} pageStripValueMapColl={spreadsheetRecords}/>
                <BtnNormal color="primary" label="Upload" clickHandler={()=>functions("batchAddStudents",{preInpBatchMap:preInpBatchMap,batchInpMaps:spreadsheetRecords})} />
            </div>
         </div>
        } */}

        {/* {checkIfAllInputsOk(inpValMap,inpValdMap,props.inpSchemeMap) &&
                <BtnNormal
                label="Submit"
                color="green"
                clickHandler={()=>props.submitClickHandler({inpValMap:inpValMap,inpSchemeMap:props.inpSchemeMap})}
                />
              } */}
        {/* {spreadsheetRecords.length == 0 &&
    <input type="file" onChange={(e)=>fileInputChangeFn(e)} />
        }
        {spreadsheetRecords.length > 0 &&
            <React.Fragment>
             <div></div>
            </React.Fragment>
        } */}
    {showPromptOp && 
              <ModalBoxOpConfPrompt6
                passMap={promptOpMap}
                showSpinnerHandler={(visibilityCond)=>setShowSpinner(visibilityCond)}
                promptClose={()=>setShowPromptOp(false)}
               // opCompletionHandler={(closeCallBack)=>closeCallBack()}
              />

              }
    {showSpinner && <LoadSpinner />}
    </div>
    )
}



function checkAndUpdateStatusofBatchInpMaps(batchInpMaps,uploadStartSlNo,uploadEndSlNo,opType,successfullBatchSlNos){

    const tBatchInpMaps = JSON.parse(JSON.stringify(batchInpMaps))

    tBatchInpMaps.forEach((batchInpMap,i)=>{

        if(i+1>=uploadStartSlNo && i+1<=uploadEndSlNo){

            if(opType=="postOperation"){

                batchInpMap["uploadStatus"] = successfullBatchSlNos.includes(i+1) ? "successfull" : "failed"
            }else{
                batchInpMap["uploadStatus"] = "inQueue"
            }

         

        }else{
          
            if(batchInpMap["uploadStatus"]=="successfull" || batchInpMap["uploadStatus"]=="failed"){

            }else{
                batchInpMap["uploadStatus"]="outOfQueue"
            }

        }
        

    })



    return tBatchInpMaps

}
