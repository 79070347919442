import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import FilterInput from "./FilterInput";
import RecordStrip from "./RecordStrip";
import ImageCont from "./ImageCont";
import RecordBanner from "./RecordBanner";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";

const pageLimit = 2;

export default function FilterStripListCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  console.log(props);

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbNotations);
  console.log(props.passMap);

  const [stripValuesDataMap, setStripValuesDataMap] = useState({
    stripValuesSeqs: [],
    recordSlNos: [],
  });
  const [stripFilteredValuesDataMap, setStripFilteredValuesDataMap] = useState({
    stripValuesSeqs: [],
    recordSlNos: [],
  });
  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  const [recordSlNoArray, setRecordSlNoArray] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [recordCount, setRecordCount] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    const dbPath = getDbPath(props.listTopic);

    function getDbPath(listTopic) {
      switch (listTopic) {
        case "transactions": {
          return `admin/accounts/transactions`;
        }
        case "paidInvoices": {
          return `admin/accounts/paidInvoices`;
        }
        case "unpaidInvoices": {
          return `admin/accounts/unpaidInvoices`;
        }
        case "storeBranchTransactions": {
          return `admin/accounts/paidInvoices`;
        }
      }
    }

    console.log(dbPath);

    const collQuery = query(
      collection(db, dbPath),
      orderBy("docCreationDate", "desc"),
      limit(2)
    );
    const dbDocs = await getDocs(collQuery);

    if (dbDocs.empty) {
      console.log("here");
    } else {
      const iRecordCount =
        dbDocs.docs[0].data().recordSlNos[
          dbDocs.docs[0].data().recordSlNos.length - 1
        ];

      setRecordCount(iRecordCount);

      setStripValuesDataMap(
        extractAndSetValuesDataMap(iRecordCount, dbDocs, stripValuesDataMap, 1)
      );
    }
  }

  function extractAndSetValuesDataMap(
    sRecordCount,
    dbDocs,
    stripValuesDataMap,
    pageNo
  ) {
    const cStripValues = [];
    let cSlNos = [];

    console.log(stripValuesDataMap);

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringSeqDatas;
      const recordSlNos = doc.data().recordSlNos;

      console.log(doc.data());

      valueSeqs.forEach((valueSeq, i) => {
        const valueArray = valueSeq.split("^");
        if (props.listTopic == "paidInvoices") {
          valueArray.splice(4, 1); //Excluding partyUid data from the listValue Array
        }
        valueArray.unshift(recordSlNos[i]);
        cStripValues.push(valueArray);
      });

      cSlNos = cSlNos.concat(recordSlNos);
    });

    stripValuesDataMap.stripValuesSeqs = [
      ...stripValuesDataMap.stripValuesSeqs,
      ...cStripValues,
    ];
    stripValuesDataMap.recordSlNos = [
      ...stripValuesDataMap.recordSlNos,
      ...cSlNos,
    ];

    displayPage(sRecordCount, pageNo, stripValuesDataMap);

    console.log(stripValuesDataMap);
    return stripValuesDataMap;
  }

  console.log(pageNo);

  function displayPage(sRecordCount, pageNo, stripValuesDataMap) {
    setPageNo(pageNo);
    console.log(pageNo);

    const recordSlNoArray = stripValuesDataMap.recordSlNos;

    const startSlOfPage =
      pageLimit * (pageNo - 1) + 1 > sRecordCount
        ? sRecordCount
        : pageLimit * (pageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * pageNo > sRecordCount ? sRecordCount : pageLimit * pageNo;

    // const pageLimit = 3;

    // const bottomSlOfPage =
    //   sRecordCount + 1 - pageNo * pageLimit < 1
    //     ? 1
    //     : sRecordCount + 1 - pageNo * pageLimit;
    // const topSlOfPage =
    //   bottomSlOfPage + pageLimit - 1 < 1
    //     ? 1
    //     : sRecordCount - (pageNo - 1) * pageLimit;

    console.log(startSlOfPage, endSlOfPage);

    console.log(recordSlNoArray);

    const slQueryArray = [];

    [startSlOfPage, endSlOfPage].forEach((item) => {
      if (recordSlNoArray.indexOf(item) == -1) {
        slQueryArray.push(item);
      }
    });

    if (slQueryArray.length == 0) {
      assignStripValues(startSlOfPage, endSlOfPage, stripValuesDataMap);
    } else {
      fetchAndSetStripValues(
        sRecordCount,
        startSlOfPage,
        endSlOfPage,
        slQueryArray,
        stripValuesDataMap,
        pageNo
      );
    }
  }

  async function fetchAndSetStripValues(
    sRecordCount,
    startSlNo,
    endSlNo,
    slQueryArray,
    stripValuesDataMap,
    pageNo
  ) {
    const collQuery = query(
      collection(
        db,
        `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
      ),
      where("slNos", "array-contains-any", slQueryArray)
    );

    console.log("here2");
    const dbDocs = await getDocs(collQuery);
    setStripValuesDataMap(
      extractAndSetValuesDataMap(
        sRecordCount,
        dbDocs,
        stripValuesDataMap,
        pageNo
      )
    );
  }

  function assignStripValues(startSlNo, endSlNo, stripValuesDataMap) {
    const rArray = [];

    console.log("here1");

    const recordSlNoArray = stripValuesDataMap.recordSlNos;
    const stripValuesColl = stripValuesDataMap.stripValuesSeqs;

    console.log(stripValuesColl);

    const mIndexStart = recordSlNoArray.indexOf(startSlNo);
    const mIndexEnd = recordSlNoArray.indexOf(endSlNo);

    for (let y = startSlNo; y <= endSlNo; y++) {
      rArray.push(stripValuesColl[recordSlNoArray.indexOf(y)]);
    }

    console.log(rArray);

    setStripValuesSeqs(rArray);
  }

  function renderPagination(recordCount, valuesDataMap) {
    const pageCount = Math.ceil(recordCount / pageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(recordCount, x + 1, valuesDataMap)}
          className="btn-page"
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  function renderFilteredPagination(recordCount, valuesDataMap) {
    const pageCount = Math.ceil(recordCount / pageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayFilteredPage(x + 1, valuesDataMap)}
          className="btn-page"
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }

  async function displayPagesBasedOnFilter(sFilterName, sFilterValueMap) {
    console.log("hell Ya");
    setViewMode("filter");

    const map = {};
    map["collQuery"] = "";

    const filterSchemeMap = { ...props.passMap.filterSchemeMap };

    const filterNameIndex = filterSchemeMap.filterNames.indexOf(sFilterName);
    const filterInputKeyNames =
      filterSchemeMap.filterInputKeys[filterNameIndex];

    const filterInputValue =
      sFilterName == "Store Branch Name"
        ? `${sFilterValueMap.storeBrandName}-${sFilterValueMap.storeBranchName}`
        : sFilterValueMap[filterSchemeMap.filterInputKeys[filterNameIndex][0]];

    const curFilterValMap = {
      filterName: sFilterName,
      filterDbKey: filterSchemeMap.filterDbKeys[filterNameIndex],
      filterInputKeys: filterSchemeMap.filterInputKeys[filterNameIndex],
      filterSeqIndex: filterSchemeMap.filterSeqIndices[filterNameIndex],
      filterInputValue: filterInputValue,
    };

    console.log(curFilterValMap);

    const collQuery = query(
      collection(db, `admin/accounts/${props.listTopic}`),
      where(
        curFilterValMap.filterDbKey,
        "array-contains",
        curFilterValMap.filterInputValue
      )
    );

    // switch (sFilterName) {
    //   case "Party ID":
    //     {
    //       console.log("here123");
    //       map["collQuery"] = query(
    //         collection(db, `admin/accounts/${props.listTopic}`),
    //         where(
    //           "transactionPartyIds",
    //           "array-contains",
    //           sFilterValueMap["partyId"]
    //         )
    //       );
    //     }
    //     break;
    //   case "Date":
    //     {
    //       map["collQuery"] = query(
    //         collection(db, `admin/accounts/${props.listTopic}`),
    //         where("partyIds", "array-contains", sFilterValueMap["partyIds"])
    //       );
    //     }
    //     break;
    //   case "Store Branch Name":
    //     {
    //       const storeFullName = `${sFilterValueMap["storeBrandName"]} - ${sFilterValueMap["storeBranchName"]}`;
    //       map["collQuery"] = query(
    //         collection(db, `admin/accounts/${props.listTopic}`),
    //         where("transactionPartyNames", "array-contains", storeFullName)
    //       );
    //     }
    //     break;
    // }

    // const collQuery = query(
    //   collection(
    //     db,
    //     `storeBranches/${props.passMap.storeBranchUid}/storeBranchTransactions`
    //   ),
    //   where("docModificationDate", "<=", toTimestamp),
    //   where("docModificationDate", ">=", fromTimestamp)
    // );

    const dbDocs = await getDocs(collQuery);

    console.log(dbDocs.size);

    setStripFilteredValuesDataMap(
      extractAndSetFilteredValuesDataMap(dbDocs, 1, curFilterValMap)
    );
  }

  function extractAndSetFilteredValuesDataMap(
    dbDocs,
    pageNo,
    sCurFilterValMap
  ) {
    const tArray1 = [];
    let tArray2 = [];

    const rMap = {};
    rMap["stripValuesSeqs"] = [];
    rMap["slNos"] = [];

    dbDocs.forEach((doc) => {
      const valueSeqs = doc.data().stringSeqDatas;
      const slNos = doc.data().recordSlNos;

      console.log(sCurFilterValMap);

      valueSeqs.forEach((valueSeq, i) => {
        const mIndex = sCurFilterValMap.filterSeqIndex;
        if (valueSeq.split("^")[mIndex] == sCurFilterValMap.filterInputValue) {
          const valueArray = valueSeq.split("^");
          if (props.listTopic == "paidInvoices") {
            valueArray.splice(4, 1); //Excluding partyUid data from the listValue Array
          }
          tArray1.push(valueArray);
        }
      });

      tArray2 = tArray2.concat(slNos);
    });

    rMap.stripValuesSeqs = [...tArray1];
    rMap.slNos = [...tArray2];

    console.log(rMap);

    displayFilteredPage(pageNo, rMap);

    setRecordCount(rMap.slNos.length);

    displayFilteredPage(pageNo, rMap);

    //console.log(stripFilteredValuesDataMap);
    return rMap;
  }

  function displayFilteredPage(pageNo, valuesDataMap) {
    setPageNo(pageNo);
    console.log(pageNo);

    console.log(valuesDataMap);

    const rArray = [];

    const size = valuesDataMap.stripValuesSeqs.length;

    const startSlOfPage =
      pageLimit * (pageNo - 1) + 1 > size ? size : pageLimit * (pageNo - 1) + 1;
    const endSlOfPage = pageLimit * pageNo > size ? size : pageLimit * pageNo;

    // const startIndex =
    //   pageLimit * pageNo - pageLimit > size - 1
    //     ? size - 1
    //     : pageLimit * pageNo - pageLimit;
    // const endIndex =
    //   startIndex + pageLimit - 1 > size - 1
    //     ? size - 1
    //     : startIndex + pageLimit - 1;

    if (startSlOfPage == endSlOfPage) {
      const tArray = [...valuesDataMap.stripValuesSeqs[startSlOfPage - 1]];
      tArray.unshift(startSlOfPage);
      rArray.push(tArray);
    } else {
      for (let x = startSlOfPage; x <= endSlOfPage; x++) {
        const tArray = [...valuesDataMap.stripValuesSeqs[x]];
        tArray.unshift(x);
        rArray.push(tArray);
      }
    }

    setStripValuesSeqs([...rArray]);
  }

  const [filterValueMap, setFilterValueMap] = useState({});

  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  return (
    <React.Fragment>
      <div className="m-4 flex justify-center">
        <select onChange={(e) => setFilterName(e.target.value)}>
          {filterSchemeMap.filterNames.map((filterOptionName, i) => {
            return (
              <option key={i} value={filterOptionName}>
                {filterOptionName}
              </option>
            );
          })}
        </select>
        <div>
          <FilterInput
            filterName={filterName}
            filterSchemeMap={filterSchemeMap}
            filterValueMap={filterValueMap}
            inputChangeFn={(data) => inputChangeFn(data)}
          />
        </div>
        <button
          onClick={() => displayPagesBasedOnFilter(filterName, filterValueMap)}
        >
          Go
        </button>
      </div>
      <div className="bg-primary-100">
        <div
          className={`title-strip grid w-11/12 mx-auto mt-1 grid-cols-cust-8b bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
        {stripValuesSeqs.length != 0 &&
          stripValuesSeqs.map((stripValues) => {
            return (
              <RecordStrip
                stripValues={stripValues}
                labelNames={props.passMap.labelNames}
              />
            );
          })}
      </div>
      <div className="flex mx-auto gap-5">
        {viewMode == "normal" &&
          renderPagination(recordCount, stripValuesDataMap)}

        {viewMode == "filter" &&
          renderFilteredPagination(recordCount, stripFilteredValuesDataMap)}
      </div>
      {bottomContainer}
    </React.Fragment>
  );
}
