import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserDataContext } from "./Level1";

export default function TabContainer(props) {
  const userData = useContext(UserDataContext);

  console.log(props)
  console.log(userData);

  const navigate = useNavigate();

  // function tabClickHandler(focusIndex,tabName){
  //   props.tabClickHandler(focusIndex,tabName);
  // }

  return (
    <div className="flex items-center justify-center gap-x-1">
      {props.tabNameList.map((tabName,i) => (
        <button className={`btn-tab ${i==props.tabFocusIndex?"bg-primary text-darkBackText border-primary ":""}`} key={tabName} onClick={() => props.tabClickHandler(i,tabName)}>
          {tabName}
        </button>
      ))}
    </div>
  );
}
