import React, { useEffect, useState } from "react";

export default function AutoGenInput(props) {
  //const intendedValue = props.fieldValue ? "Yes" : "No"; //Matching the Value of fieldValue with intented Value

  const [value,setValue] = useState("")

  useEffect(()=>{

    const eMap = {
      target:{
        value:props.inputValue,
        name:props.inputName,
        type:"text"
      }
    }

    props.onChangeFn(eMap)

  },[props.inputValue])


  return (
    <div className="grid grid-cols-cust-2g gap-1 bg-quaternary-xlight p-3">
      <label>{props.inputLabel}</label>
      <div className="flex justify-center">
        {props.inputValue}
      </div>
    </div>
  );
}
