import React, { Component } from "react";
import { useEffect, useState, useContext } from "react";
import OrderListView from "./OrderListView";
import OrderDetailsView from "./OrderDetailsView";
import BottomContainer from "./BottomContainer";
import SubmitButton from "./SubmitButton";
import InputTile from "./InputTile";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList";
import FilterInput from "./FilterInput";
import RecordStrip3 from "./RecordStrip3";
import ImageCont from "./ImageCont";
import RecordBanner from "./RecordBanner";
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  onSnapshot,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  skateInputObj,
  serverTimestamp,
  db,
  firebase,
  setDoc,
  getAuth,
  getFirestore,
  firebaseConfig,
  initializeApp,
  onAuthStateChanged,
  useNavigate,
  httpsCallable,
  functions,
  storage,
  ref,
  getDoc,
  orderBy,
  limit,
  uploadBytes,
  getBytes,
  getBlob,
} from "../Utils";
import { UserDataContext } from "./Level1";

const pageLimit = 2;

export default function FilterStripListCont(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

  console.log(props);

  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbNotations);
  console.log(props.passMap);

  const [stripValuesDataMap, setStripValuesDataMap] = useState({
    stripValuesSeqs: [],
    recordSlNos: [],
  });
  const [stripFilteredValuesDataMap, setStripFilteredValuesDataMap] = useState({
    stripValuesSeqs: [],
    recordSlNos: [],
  });
  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  const [recordSlNoArray, setRecordSlNoArray] = useState([]);
  const [compStripDataMapColl, setCompStripDataMapColl] = useState([]);
  const [pageStripDataMapValuesColl, setPageStripDataMapValuesColl] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [recordCount, setRecordCount] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");

  useEffect(() => {
    initializePageWithData();
  }, []);

  async function initializePageWithData() {
    const dbPath = getDbPath(props.listTopic);

    function getDbPath(listTopic) {
      switch (listTopic) {
        case "transactions": {
          return `admin/accounts/transactions`;
        }
        case "paidInvoices": {
          return `admin/accounts/paidInvoices`;
        }
        case "unpaidInvoices": {
          return `admin/accounts/unpaidInvoices`;
        }
        case "storeBranchTransactions": {
          return `admin/accounts/paidInvoices`;
        }
      }
    }

    console.log(dbPath);

    const collQuery = query(
      collection(db, dbPath),
      where("invoicePartyIds","array-contains",props.passMap.storeBranchId),
    );
    const dbDocs = await getDocs(collQuery);

    if (dbDocs.empty) {
      console.log("here");
    } else {

      // const iRecordCount =
      //   dbDocs.docs[0].data().recordSlNos[
      //     dbDocs.docs[0].data().recordSlNos.length - 1
      //   ];

      // setRecordCount(iRecordCount);

      setCompStripDataMapColl(
        extractAndSetStripValues(dbDocs, compStripDataMapColl, 1)
      );
      }
    }
  

  function extractAndSetStripValues(
    dbDocs,
    stripValuesDataMap,
    pageNo
  ) {

    console.log(dbDocs.size)
    const valueDataMapColl=[];

    dbDocs.docs.forEach((doc)=>{

      const docValueSeqs=doc.data().stringSeqDatas;

      docValueSeqs.forEach((valueSeq)=>{
        const partyId=valueSeq.split("^")[props.passMap.valueSeqDbKeys.indexOf("invoicePartyId")];

        if(props.passMap.storeBranchId==partyId){
          const tMap={}
          valueSeq.split("^").forEach((value,i)=>{
            const key = props.passMap.valueSeqDbKeys[i]
            tMap[key]=value         
          })
          tMap["slNo"]=valueDataMapColl.length+1
          valueDataMapColl.push(tMap)
        }
      })
    })
    console.log(valueDataMapColl)
    const iRecordCount = valueDataMapColl.length
    setRecordCount(iRecordCount);

    displayPage(iRecordCount, valueDataMapColl, 1);


    return valueDataMapColl;
  }

  console.log(pageNo);

  function displayPage(sRecordCount, compStripDataMapColl,pageNo) {
    setPageNo(pageNo);
    console.log(sRecordCount);

    const startSlOfPage =
      pageLimit * (pageNo - 1) + 1 > sRecordCount
        ? sRecordCount
        : pageLimit * (pageNo - 1) + 1;
    const endSlOfPage =
      pageLimit * pageNo > sRecordCount ? sRecordCount : pageLimit * pageNo;

    // const pageLimit = 3;

    // const bottomSlOfPage =
    //   sRecordCount + 1 - pageNo * pageLimit < 1
    //     ? 1
    //     : sRecordCount + 1 - pageNo * pageLimit;
    // const topSlOfPage =
    //   bottomSlOfPage + pageLimit - 1 < 1
    //     ? 1
    //     : sRecordCount - (pageNo - 1) * pageLimit;

    console.log(startSlOfPage, endSlOfPage);

    // const slQueryArray = [];

    // [startSlOfPage, endSlOfPage].forEach((item) => {
    //   if (recordSlNoArray.indexOf(item) == -1) {
    //     slQueryArray.push(item);
    //   }
    // });

    assignStripValues(startSlOfPage, endSlOfPage, compStripDataMapColl);

    // if (slQueryArray.length == 0) {
    //   assignStripValues(startSlOfPage, endSlOfPage, stripValuesDataMap);
    // } else {
    //   fetchAndSetStripValues(
    //     sRecordCount,
    //     startSlOfPage,
    //     endSlOfPage,
    //     slQueryArray,
    //     stripValuesDataMap,
    //     pageNo
    //   );
    // }
  }



  function assignStripValues(startSlNo, endSlNo, compStripDataMapColl) {
    const rArray = [];

    console.log("here1");

    // const recordSlNoArray = stripValuesDataMap.recordSlNos;
    // const stripValuesColl = stripValuesDataMap.stripValuesSeqs;


    // const mIndexStart = recordSlNoArray.indexOf(startSlNo);
    // const mIndexEnd = recordSlNoArray.indexOf(endSlNo);

    for (let y = startSlNo; y <= endSlNo; y++) {
      rArray.push(compStripDataMapColl[y-1]);
    }

    console.log(rArray);

    setPageStripDataMapValuesColl(rArray);
  }

  function renderPagination(recordCount, valuesDataMap) {
    const pageCount = Math.ceil(recordCount / pageLimit);

    const rArray = [];

    for (let x = 0; x < pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(recordCount, x + 1, valuesDataMap)}
          className="btn-page"
        >{`${x + 1}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }


  const [filterValueMap, setFilterValueMap] = useState({});

  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  console.log(pageStripDataMapValuesColl)

  return (
    <React.Fragment>
      <div className="m-4 flex justify-center">
        <select onChange={(e) => setFilterName(e.target.value)}>
          {filterSchemeMap.filterNames.map((filterOptionName, i) => {
            return (
              <option key={i} value={filterOptionName}>
                {filterOptionName}
              </option>
            );
          })}
        </select>
        <div>
          <FilterInput
            filterName={filterName}
            filterSchemeMap={filterSchemeMap}
            filterValueMap={filterValueMap}
            inputChangeFn={(data) => inputChangeFn(data)}
          />
        </div>

      </div>
      <div className="bg-primary-100">
        <div
          className={`title-strip grid w-11/12 mx-auto mt-1 grid-cols-cust-7c bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
        {pageStripDataMapValuesColl.length != 0 &&
          pageStripDataMapValuesColl.map((stripValues) => {
            return (
              <RecordStrip3
                labelDbNotations={props.passMap.labelDbNotations}
                stripValueMap={stripValues}
                labelNames={props.passMap.labelNames}
                btnLabels={[]}
              />
            );
          })}
      </div>
      <div className="flex mx-auto gap-5">
        {viewMode == "normal" &&
          renderPagination(recordCount, compStripDataMapColl)}

      </div>
      {bottomContainer}
    </React.Fragment>
  );
}
