import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  addStoreBrandModalInputSchemeMap,
  db,
  functions,
  httpsCallable,
  orderBy,
  styleProxyMap
} from "../Utils";
import BottomContainer from "./BottomContainer";
import LoadSpinner from "./LoadSpinner";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxOpConfPrompt3 from "./ModalBoxOpConfPrompt3";
import ModalBoxWithInput2 from "./ModalBoxWithInput2";
import NoAvailableDisplay from "./NoAvailableDisplay";
import RecordBtnStrip2 from "./RecordBtnStrip2";

const pageLimit = 12;


//@start
export default function StripListBtnCont2(props) {
  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );

 
  console.log(props)
  //console.log(props.passMap.dataMaps);
  console.log(props.passMap.labelNames);
  console.log(props.passMap.labelDbKeys);
  console.log(props.passMap);


  const [compListDataMapColl, setCompListDataMapColl] = useState([]);
  const [compFilListDataMapColl, setCompFilListDataMapColl] = useState([]);

  //const [stripValuesColl, setStripValuesColl] = useState([]);
  const [stripValuesSeqs, setStripValuesSeqs] = useState([]);
  //const [stripValueMapColl, setStripValueMapColl] = useState([]);
  const [filterName, setFilterName] = useState(
    props.passMap.filterSchemeMap.filterNames[0]
  );
  const [filterSchemeMap, setFilterSchemeMap] = useState(
    props.passMap.filterSchemeMap
  );
  const [filterValueMap, setFilterValueMap] = useState({});
  const [pageStripValueMapColl, setPageStripValueMapColl] = useState([]);
  const [partyUidUnpaidInvoiceDocIdCorrelationMap, setPartyUidUnpaidInvoiceDocIdCorrelationMap] = useState({});
  const [slNoArray, setSlNoArray] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [viewMode, setViewMode] = useState("normal");
  const [isLoading,setIsLoading] = useState(true);
  const [showSpinner,setShowSpinner] = useState(false)
  const [showModalBoxInvoice,setShowModalBoxInvoice] = useState(false)
  const [invoiceDataMap,setInvoiceDataMap] = useState({})
  const [showPrompt, setShowPrompt] = useState(false);
  const [showOpPrompt, setShowOpPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [showModalBoxInput,setShowModalBoxInput] = useState(false)
  const [modalInpMap,setModalInpMap] = useState({})

  useEffect(() => {
  
    initializePageWithData();
  }, []);

  async function initializePageWithData() {

    const collQuery = query(
      collection(db, `requests`),
      where("status", "==", "pending"),
      orderBy("docCreationDate", "desc")
    );

    const dbDocs = await getDocs(collQuery);

      if (dbDocs.empty) {
        setPageStripValueMapColl([]);
        setIsLoading(false)
      } else {
        setCompListDataMapColl(extractAndSetValuesMapColl2(dbDocs));
      }
    
  }

  //For Unpaid Invoice
  function extractAndSetValuesMapColl2(dbDocs) {

    const tArray=[]

    let nMap = {};
    nMap["count"] = 0;

    const dbKeys = props.passMap.labelDbKeys;

    dbDocs.docs.forEach((doc,i)=>{
      // let valMap={}

      // dbKeys.forEach((dbKey,j)=>{
      //   if(dbKey=="slNo"){
      //     valMap[dbKey] = i+1;
      //   }else{
      //     valMap[dbKey] = doc.data()[dbKey];
      //   }
      // });

      tArray.push(doc.data());
    });

    console.log(tArray)

    if(tArray.length==0){//if there are documents but no records in the unpaid invoice db docs then
      setIsLoading(false) // this will tell the app to stop loading and understand that there are no documents
    }else{
      displayPage(pageNo,tArray);
    }

    setRecordCount(tArray.length);

    return tArray;

  }


  console.log(pageNo);

  function displayPage(sPageNo, sStripValueMapColl) {

    setPageNo(sPageNo);

    console.log(sStripValueMapColl);
    const latestSl = sStripValueMapColl.length;
    // const pageLimit = 3;

    const startSlOfPage =
      pageLimit * (sPageNo - 1) + 1 > latestSl
        ? latestSl
        : pageLimit * (sPageNo  - 1) + 1;
    const endSlOfPage =
      pageLimit * sPageNo  > latestSl ? latestSl : pageLimit * sPageNo;

    assignStripValues(startSlOfPage, endSlOfPage, sStripValueMapColl);
  }

  function assignStripValues(startSlNo, endSlNo, sStripValueMapColl) {
    const rArray = [];

    console.log(sStripValueMapColl);


    const mIndexStart = startSlNo - 1;
    const mIndexEnd = endSlNo - 1;

    console.log(startSlNo,endSlNo);

    for (let y = mIndexStart; y <= mIndexEnd; y++) {
      const tMap = { ...sStripValueMapColl[y] };
      tMap["slNo"] = y + 1;

      rArray.push(tMap);
    }

    console.log("pageStripValueMapColl=",rArray);

    setPageStripValueMapColl(rArray);
    setIsLoading(false)
    props.stopSpinner()
  }



  function renderPagination(
    sPageLimit,
    sListValuesMapColl,
    sRecordCount,
    sPageNo
  ) {

    console.log(sListValuesMapColl)
    console.log(sRecordCount);

    const recordCount = sListValuesMapColl.length;
    const pageCount = Math.ceil(recordCount / sPageLimit);

    const rArray = [];

    console.log(sListValuesMapColl);

    for (let x = 1; x <= pageCount; x++) {
      const element = (
        <button
          onClick={() => displayPage(x, sListValuesMapColl)}
          className={`p-cust-3 ${
            x == sPageNo ? `border-page-no-indicator` : ``
          }`}
        >{`${x}`}</button>
      );
      rArray.push(element);
    }

    return rArray;
  }


  async function btnClicked(data) {

    const map = data.stripValueMap;
    console.log("mainMap=",map)
    switch (data.btnType) {
      case "Approve":{
    //    setShowSpinner(true);

    console.log(data.stripValueMap)

        setShowModalBoxInput(true);

        const opFn = async (payload) =>{
          const result = httpsCallable(functions, "signUpStoreBrandByAdmin");
          return await result(payload);
        }

        //creating pre input map, the values in the input that are pre populated for convenience
        const preInpValMap = {}

        addStoreBrandModalInputSchemeMap.inputNames.forEach((key,i)=>{
          const preInpCond = addStoreBrandModalInputSchemeMap.preInpConds[i];
            if(preInpCond){
              preInpValMap[key] = data.stripValueMap[key]
            }
        });
        
          console.log(preInpValMap)

        setModalInpMap({
          ...addStoreBrandModalInputSchemeMap,
          opBtnLabels:[],
          opBtnLabelSubmit:"Sign Up",
          preInpValMap:preInpValMap,
          title:"Sign Up Store",
          extraValMap:{
            docUid:data.stripValueMap.docUid
          },
          opFnSubmit:opFn
        })

      }
      break;
      case "Reject":{

        setShowOpPrompt(true);

        const opFn = async (payload) =>{
          const result = httpsCallable(functions, "rejectSignUpStoreBrandByAdmin");
          return await result(payload);
        }

        const opMap = {
          message:"Do you want to proceed to reject the sign-up request?",
          opFn:opFn,
          valMap:{
            docUid:data.stripValueMap.docUid
          }
        };

        setPromptMap(opMap);
      }
      break;
      default: {
        console.log("No Options");
      }
    }
  }



  function inputChangeFn(data) {
    const map = { ...filterValueMap };
    map[data.target.name] = data.target.value;
    setFilterValueMap({ ...map });
  }

  if(!isLoading){
      props.stopSpinner()
  }
  const columnWidths=props.listTopic == "balances" ? `grid-cols-cust-8b` : props.listTopic == "signUpRequest" ? `grid-cols-cust-6c` : `grid-cols-cust-8c`

  function displayPrompt(message,title,completionFn,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message,
      completionFn:completionFn ? completionFn : ()=>{}
    })
  }

  //@render
  return (
    <React.Fragment>
    {!isLoading && 
  <div className={`grid ${styleProxyMap.gridRows["2c"]} p-cust-topHead`}>
   {pageStripValueMapColl.length != 0 && 
   <div className="grid" >
        <div className="flex flex-col justify-center items-center py-12">
          <div className="flex mt-8 border-page-no">
            {viewMode=="normal" && renderPagination(pageLimit, compListDataMapColl, recordCount, pageNo)}
          </div>
        </div>
        
        <div className="">
  
        <div className="w-11/12 grid gap-4 grid-cols-cust-2e mx-auto mt-1 text-sm">
        <div
          className={`grid mx-auto w-full ${columnWidths} bg-secondary rounded-sm border border-borderStd `}
        >
          {props.passMap.labelNames.map((labelName, index) => {
            return (
              <div className="title-strip-unit flex justify-center items-center text-center text-darkBackText">
                {labelName}
              </div>
            );
          })}
        </div>
          <div className="flex items-center justify-center text-darkBackText bg-secondary text-center rounded-sm border border-borderStd ">
            Action
          </div>
      </div>
         
            {pageStripValueMapColl.map((stripValueMap,i) => {
              return (
                <RecordBtnStrip2
                stripValueMap={stripValueMap}
                labelDbKeys={props.passMap.labelDbKeys}
                labelNames={props.passMap.labelNames}
                btnLabels={props.passMap.btnLabels}
                btnAlertLevels={props.passMap.btnAlertLevels}
                listTopic={props.listTopic}
                btnClickHandler={(data) => btnClicked(data)}
              />)
              })
            }
          </div>
        </div>//End of Body Cont
  }
          {pageStripValueMapColl.length == 0 && 
                        <NoAvailableDisplay textName="No Records Found"/>
          }
    
    <div className="absolute w-full bottom-10 flex justify-center align-center">
        {bottomContainer}
        </div>
      </div>
      }
       {showModalBoxInput && <ModalBoxWithInput2
          passMap={modalInpMap}
          modalCloseHandler={()=>setShowModalBoxInput(false)}/>}

       {showOpPrompt && <ModalBoxOpConfPrompt3 passMap={promptMap} promptClose={()=>setShowOpPrompt(false)}/>}
       {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
       {showSpinner && <LoadSpinner />}

      </React.Fragment>
    );
}


