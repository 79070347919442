import React, { useContext, useEffect, useState } from "react";
import BtnNormal from "./BtnNormal";
import CategoryInputElementsCont from "./CategoryInputElementsCont";
import InputElementsCont from "./InputElementsCont";
import { UserDataContext } from "./Level1";
import LoadSpinner from "./LoadSpinner";
import ModalBoxOpConfPrompt from "./ModalBoxOpConfPrompt";
import TabContainer from "./TabContainer";

export default function InputCont(props) {
  const userContext = useContext(UserDataContext); //Main User Data of the Current User Broadcasted from Level 1

  console.log(userContext);
  console.log(props.passMap);

  const [submitClicked, setSubmitClicked] = useState(false);
  const [curTabName, setCurTabName] = useState("General");
  const [tabChanged, setTabChanged] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPromptConf, setShowPromptConf] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [valdCount, setValdCount] = useState(0);

  console.log("In Input Cont ");
  useEffect(() => {}, [curTabName]);

  function functions(fnName,data){

    switch(fnName){

    case "submitClickStart":{

      const opFn = () =>{
        setShowSpinner(true);
        if(props.passMap.fnType == "modal"){
          
        }else{
          setSubmitClicked(true);
        }
      }

      getConfirmationAndSubmit(data.confmMsg,opFn)

    }
   }
  }


  function submitClose() {
    setSubmitClicked(false);
    setShowSpinner(false);
  }

  function submitClickStart(confmMsg) {

    const opFn = () =>{
      setShowSpinner(true);
      if(props.passMap.fnType == "modal"){
        
      }else{
        setSubmitClicked(true);
      }
    }
    
    getConfirmationAndSubmit(confmMsg,opFn)
  }

  function tabClickHandler(tabName) {
    setTabChanged(true);
    setCurTabName(tabName);
  }

  function checkAndRenderSettingsSection(passDataObj, tabName, submitClicked) {
    console.log(passDataObj);
    console.log(tabName);
    switch (tabName) {
      case "General":
        return (
          <InputElementsCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            submitClicked={submitClicked}
            submitCloseHandler={() => submitClose()}
            tabName={tabName}
          />
        );
        break;

      case "Service":
        return (
          <CategoryInputElementsCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            submitClicked={submitClicked}
            submitCloseHandler={() => submitClose()}
            tabName={tabName}
          />
        );
        break;
      case "Location":
        return (
          <InputElementsCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            submitClicked={submitClicked}
            submitCloseHandler={() => submitClose()}
            tabName={tabName}
          />
        );
        break;
      case "Availability":
        return (
          <InputElementsCont
            key={`${tabName}`}
            passMap={getSpecificCategorySettingsInputDataMap(
              passDataObj,
              tabName
            )} //passDataObj has all data from props object
            submitClicked={submitClicked}
            submitCloseHandler={() => submitClose()}
            tabName={tabName}
          />
        );
        break;
      default:
        return <React.Fragment />;
    }
  }

  function tabClickHandler(tabName) {
    setCurTabName(tabName);
  }

  const viewTabContainer = (
    <TabContainer
      tabNameList={props.passMap.inputCategories}
      tabClickHandler={(name) => tabClickHandler(name)}
    />
  );

  function getSpecificCategorySettingsInputDataMap(obj, categoryName) {
    console.log(obj)
    console.log(obj.inputCategories);
    console.log(categoryName);

    const catSpecificKeys = [...obj.categorySpecificKeys, "dataMap"];
    //catSpecificKeys.push("dataMap"); // Pushing "Data Map" as this will not be present in the scheme Map

    const catIndex = obj.inputCategories.indexOf(categoryName);
    const rMap = createInitialCategoryObj(obj, catIndex);

    console.log(rMap);

    const objKeys = Object.keys(obj);
    const catUnspecificKeys = objKeys.filter(
      (key) => !catSpecificKeys.includes(key)
    );

    console.log(catUnspecificKeys);
    console.log(catSpecificKeys);

    catSpecificKeys.forEach((key) => {
      console.log(obj);
      rMap[key] = obj[key][catIndex];
    });

    catUnspecificKeys.forEach((key) => {
      rMap[key] = obj[key];
    });

    return rMap;
  }

  function createInitialCategoryObj(templateObj, catIndex) {
    const obj = {};

    templateObj[templateObj.mainKey][catIndex].forEach((field) => {
      obj[field] = "";
    });

    return obj;
  }


  function getConfirmationAndSubmit(confirmationMessage,opFn){

    setShowPromptConf(true)

    setPromptMap({
      message:confirmationMessage,
      opFn:opFn
    });

  }

  const hScreenCond = props.passMap.fnType == "modal" ? false : true

  return (
    <div className={`${hScreenCond ? `h-screen` : ``} grid justify-items-center p-2`}>
      {props.passMap.tabOptionsReqd && viewTabContainer}
      {!props.passMap.tabOptionsReqd && <div></div>}
      {props.passMap.fnType == "settings" &&
        checkAndRenderSettingsSection(props.passMap, curTabName, submitClicked)}
      {props.passMap.fnType == "others" && (
        <InputElementsCont
          passMap={props.passMap} //passDataObj has all data from props object
          submitClicked={submitClicked}
          submitCloseHandler={() => submitClose()}
          tabName=""
          mandInpKeys={props.passMap.mandInpKeys}
          changeValdCount = {(val)=>setValdCount(valdCount+val)}
        />
      )}

      {props.passMap.fnType == "modal" && (
                    <InputElementsCont
                      passMap={props.passMap} 
                      modalBtnClicked={props.modalBtnClicked}
                      modalDataMap={props.modalDataMap}
                      modalBtnClickHandler={(data) => props.modalBtnClickHandler(data)}
                      modalBtnClickCloseHandler={() => props.modalBtnClickCloseHandler()}
                      valdCount={valdCount}
                      changeValdCount = {(val)=>setValdCount(valdCount+val)}
                    />
      )}
      {showSpinner && <LoadSpinner />}

      {props.passMap.fnType != "modal" &&                    //Excluding for Modals
          <div className="flex gap-4 justify-center items-center">
            {valdCount >= 0 &&
              // <ButtonSubmit
              //   submitClickHandler={() => submitClickStart(props.passMap.confmMsg)}
              //   btnLabel="Submit"
              // />

              <BtnNormal color="primary" label="Submit" clickHandler={() => functions("submitClickStart",{confmMsg:props.passMap.confmMsg})}/>
            }
            <BtnNormal color="black" label="Back" clickHandler={() => props.backBtnClickHandler()}/>
          </div>
      }
    {showPromptConf && <ModalBoxOpConfPrompt passMap={promptMap} promptClose={()=>setShowPromptConf(false)}/>}
    </div>
  );
}
