import React from "react";
import { appLogoIcon } from "./Assets";

export default function LoadSpinner(props) {


  return (
    <div className={`flex justify-center items-center ${props.isModal ? `h-full w-full rounded-lg` : `h-screen w-screen`} absolute bg-loadingBack top-0  z-40`}>
      <img src={appLogoIcon} className="h-32 animate-pulse"></img>
    </div>
  );
}
