import React from "react";

export default function FilterInput(props) {
  console.log(props);


  return <React.Fragment>{renderFilterInput(props)}
            <button
            className="btn-filter mx-1 hover-black"
            onClick={(e) =>
              props.filterClickFn(e,props.filterName)
            }
          >
            Go
          </button>
          <button
            className="btn-filter mx-1 hover-black"
            onClick={(e) =>{
              const inputNodes = e.target.parentElement.getElementsByTagName("input");

              Array.from(inputNodes).forEach((inputElem)=>{
                console.log(inputElem)
                  inputElem.value = "";
              })
              props.resetFilterClickFn(e)
              }
            }
          >
            Reset
          </button></React.Fragment>;
}

function renderFilterInput(props) {
  const filterName = props.filterName;
  const filterSchemeMap = { ...props.filterSchemeMap };
  const filterNameIndex = filterSchemeMap.filterNames.indexOf(filterName);

  const results = [];

  filterSchemeMap.filterInputKeys[filterNameIndex].forEach((inputKey, i) => {
    const inputType = filterSchemeMap.filterInputTypes[filterNameIndex][i]

    let element = ""

    if(inputType == "select"){

      element = (
        <select
        className="ml-2 filter-inputs"
        name={inputKey}
        onChange={(e) => props.inputChangeFn(e)}
        >
          <option>+1</option>
          <option>+91</option>
        </select>
          // key={i}
          // name={inputKey}
          // type={filterSchemeMap.filterInputTypes[filterNameIndex][i]}
          // placeholder={filterSchemeMap.inputPlaceholderNames[filterNameIndex][i]}
          // onChange={(e) => props.inputChangeFn(e)}
       
      );

    }else{
      element = (
        <input
          key={i}
          className="filter-inputs"
          name={inputKey}
          type={filterSchemeMap.filterInputTypes[filterNameIndex][i]}
          placeholder={filterSchemeMap.inputPlaceholderNames[filterNameIndex][i]}
          onChange={(e) => props.inputChangeFn(e)}
        />
      );
    }


    results.push(element);
  });

  return results;
}
