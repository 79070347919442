import { useState } from "react"
import BtnNormal from "./BtnNormal"
import { callFbFunction } from "./DbHelperFns"
import LoadSpinner from "./LoadSpinner"
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt"




export default function ChangePassword(props) {


    const [inpValMap,setInpValMap] = useState({password:"",reEnteredPassword:""})
    const [showPrompt,setShowPrompt] = useState(false)
    const [promptMap,setPromptMap] = useState({})
    const [showSpinner,setShowSpinner] = useState(false)


    function checkSubmitFeasiblity(inpValMap){

        if(inpValMap.password){
            return inpValMap.password == inpValMap.reEnteredPassword
        }

        return false

    }

    async function functions(fnName,data){
        switch(fnName){
            case "submitInputs":{
                setShowSpinner(true)
                const result = await callFbFunction("changeUserPassword",data.inpValMap);
                setShowSpinner(false)

                displayOpResult(result)

            }
        }
    }



    
      function displayOpResult(opResult){

        setShowPrompt(true);
        setPromptMap({
          status: opResult.status,
          title:"Result",
          message:opResult.message,
          errorMessage:opResult.errorMessage
        })
      }
 

    return(

            <div className="relative flex flex-center gap-4">
                <div className="absolute right-10 top-5">
                     <BtnNormal
                    label="Back to Dashboard"
                    color="black"
                    clickHandler={()=>props.backBtnClickHandler()}
                    />
                </div>
                <div className="flex flex-col gap-4">
                    <div>
                            <label>Password</label>
                            <input type="password" 
                            value={inpValMap.password}
                            onChange={(e)=>setInpValMap({...inpValMap,password:e.target.value})}/>
                        </div>
                    <div>
                        <label>Retype Password</label>
                        <input type="text"
                        value={inpValMap.reEnteredPassword}
                        onChange={(e)=>setInpValMap({...inpValMap,reEnteredPassword:e.target.value})}/>
                    </div>
            
                    {checkSubmitFeasiblity(inpValMap) &&
                    <BtnNormal
                    color="green"
                    label="Submit"
                    clickHandler={()=>functions("submitInputs",{inpValMap:inpValMap})}
                    />
                    }

            </div>
            {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
            {showSpinner && <LoadSpinner/>}
        </div>
      
       
    )


}