import React from "react";
import InputWithControllerCont from "./InputWithControllerCont";
import { getInitInpValMap } from "./projectFns";

export default function ModalBoxWithInputNew(props) {

console.log(props)

function functions(fnName,data){

}

  return (
      <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-modalBack">
          <div className="relative grid grid-cust-rows-3h w-2/5 bg-quaternary-light m-4 pb-4 px-4 gap-y-24 items-center rounded-lg">
              <div className="absolute top-5 right-10 border border-red text-red px-6 rounded hover:bg-red hover:text-darkBackText cursor-pointer"  onClick={() => props.closeClickHandler()}>
                X
              </div>
              <div className="h-full w-fit flex mx-auto pt-4 px-8 text-2xl font-bold">
                {props.passMap.title}
              </div>
              <div className="flex items-center justify-center">
              <InputWithControllerCont orientation="vertical" inpSchemeMap = {props.inpSchemeMap} initInpValMap={getInitInpValMap(props.inpSchemeMap)} submitClickHandler = {(data)=>props.submitClickHandler(data)} />
              </div>
         </div>
     </div>
  );
}
