

import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../Utils";
import BtnNormal from "./BtnNormal";
import { callFbFunction } from "./DbHelperFns";
import FnSelectFilterContModule from "./FnSelectFilterContModule";
import LoadSpinner from "./LoadSpinner";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxOpConfPrompt6 from "./ModalBoxOpConfPrompt6";
import { getOpPromptMap } from "./projectFns";
import StripListFilterContViewAttendance from "./StripListFilterContViewAttendance";
import { viewStudentAttendanceAdvanceSchemeMap } from "./viewProps";
//import { ReactComponent as EmployeeStatIcon } from "../images/employee-stat.svg";
// const qrCode = window.require("qrcode");
// const PDFDocument = window.require("pdfkit");
// const blobStream = window.require("blob-stream");

//@start
export default function FnSelelectFilterContOthers(props) {

  console.log(props)
  const categories = props.schemeMap.categories
  const mapKeys = props.schemeMap.mapKeys
  const catDbKeys = props.schemeMap.catDbKeys
  const navTreeNodeKeys = props.schemeMap.navTreeNodeKeys

  // const [navTreeMaps, setNavTreeMaps] = useState([...getInitNavTreeMaps(props.passMap.navTreeStartIndex,categories)
  // ]);

  const [navTreeMaps, setNavTreeMaps] = useState([]);

  const [navTreePosIndex, setPosIndex] = useState(props.passMap.navTreeStartIndex);
  const [selUnitMap,setSelUnitMap]=useState({});

  const [students,setStudents] = useState([])
  const [showSpinner,setShowSpinner] = useState(false)

  const [showPrompt,setShowPrompt] = useState(false)
  const [showPromptOp,setShowPromptOp] = useState(false)
  const [promptMap,setPromptMap] = useState({})
  const [promptOpMap,setPromptOpMap] = useState({})


  const [totalClassCount,setTotalClassCount] = useState(0)
  const [attendanceDate,setAttendanceDate] = useState("")

  const [dataMap,setDataMap] = useState({})
  
  const [viewTopicName,setViewTopicName] = useState("")

  useEffect(()=>{
    // (async () => await initializeData())()

  },[])

  async function initializeData(){

  }


  //@3
  async function functions(fnName,data){

    console.log(fnName,data)

    switch(fnName){
      case "deleteExam":{
        const payload = {
          examName:data.selMap.dbMap.examName,
          instituteAcademicYear:data.navTreeNodeDbMapObj.institute.instituteAcademicYear,
          instituteUid:data.navTreeNodeValMap.instituteUid,
          className:data.navTreeNodeValMap.className,
          sectionName:data.navTreeNodeValMap.sectionName,
          subjectName:data.navTreeNodeValMap.subjectName,
        }

        console.log(payload)

        //check for examMarks corresponding to this exam

        const querySnapshot = await getDocs(query(collection(db,`institutes/${payload.instituteUid}/examMarks`),where("examName","==",payload.examName),where("instituteAcademicYear","==",payload.instituteAcademicYear)));



        const messageBeforeDeletion = querySnapshot.size > 0 ? "It seems you have added marks for this exam, proceeding to delete will erase all exam linked data. Do you wish to proceed?" : "Do you want to proceed to delete the exam?"


        console.log(data)

        const promptSchemeMap={
          opLabel:"Delete Exam",
          opConfirmationMsg:messageBeforeDeletion,
          opBtnLabel:"Yes,Proceed",
          opBtnAction:"deleteDbData",
          opSuccessMsg:"Exam Successfully Deleted",
        }


        const afterOpCompletionFn = () => {};

        const opFn = async () => {
            const callResult = await callFbFunction("deleteExam",payload);
            return callResult
        }

        const promptMap = getOpPromptMap(promptSchemeMap,opFn,afterOpCompletionFn)

        setPromptOpMap(promptMap)
        setShowPromptOp(true)
      }

   

    }
          
  }


  async function functionsView(fnName,data){

        switch(fnName){
        case "viewStudentAttendance":{
          console.log(data)

          const dataMap = data.dataMap;

          setDataMap({...dataMap,passMap:{
            ...data.navTreeNodeDbMapObj.student,
            studentUid:data.selMap.uid,
            instituteUid:data.navTreeNodeValMap.instituteUid,
            className:data.navTreeNodeValMap.className,
            sectionName:data.navTreeNodeValMap.sectionName,
            instituteAcademicYear:data.navTreeNodeDbMapObj.institute.instituteAcademicYear,
            instituteName:data.navTreeNodeDbMapObj.institute.instituteName,
          }})

          setViewTopicName(fnName)

        }
        break;
      }
  }


  function getFnView(viewTopicName,dataMap){

        console.log(dataMap)

        switch(viewTopicName){
          case "viewStudentAttendance":{
            return (
              <StripListFilterContViewAttendance
                stripValueMapSource="internal"
                schemeMap={viewStudentAttendanceAdvanceSchemeMap}
                passMap={{...dataMap.passMap
                }}
              />
            )
          }
        }
  
  }
  
  function getFnBtnView(viewTopicName,dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapObj){

    switch(viewTopicName){

      case "other":{
        return(<React.Fragment>
          <BtnNormal spClass="" color="greenDark" label="Save" clickHandler={()=> functions("saveAttendance",{students:students,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj,totalClassCount:totalClassCount,attendanceDate:attendanceDate})}/>
          <BtnNormal spClass="" color="secondaryDark" label="Generate Attendance Sheet" clickHandler={()=> functions("generateAttendanceSheet",{students:students,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj,totalClassCount:totalClassCount,attendanceDate:attendanceDate})}/>
        </React.Fragment>)
      }
    }

  }
  
  
  function displayPrompt(message,title,status){
    setShowSpinner(false)
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message
    })
  }



//@return
  return (
    <div className="h-screen w-full absolute top-0 p-cust-topHead">
         <FnSelectFilterContModule
                   topicFnLabel={props.schemeMap.topicFnLabel}
                   topicFnView={()=>getFnView(viewTopicName,dataMap)}
                   topicFnBtnView={(navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)=>getFnBtnView(viewTopicName,dataMap,navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapOb)}
                   schemeMap={props.schemeMap}
                   passMap={props.passMap}
                   mapOfMaps={props.mapOfMaps}
                   listenMaps={props.listenMaps
                   }
                   fnHandler={async (fnName,data) => await functions(fnName,{...data,dataMap})}
            
                   viewChangeFnHandler={(fnName,data)=>functionsView(fnName,{...data,dataMap})}
                   actionHandler={(data)=>props.actionHandler(data)}
                   backBtnClickHandler={() => props.backBtnClickHandler()}
         />
        {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
        {showPromptOp && 
              <ModalBoxOpConfPrompt6
                passMap={promptOpMap}
                showSpinnerHandler={(visibilityCond)=>setShowSpinner(visibilityCond)}
                promptClose={()=>setShowPromptOp(false)}
               // opCompletionHandler={(closeCallBack)=>closeCallBack()}
              />

              }
          
       {showSpinner && <LoadSpinner/>}
       </div>
);

}






