import React, { useEffect, useState } from "react";
import { getOrderDetailsLabelValue2 } from "../Utils";
import RecordLabelUnit3 from "./RecordLabelUnit3";

export default function RecordBanner4(props) {

  const [time,setTime] = useState(new Date().getTime())
  console.log(props)

  const orderCompleteStatus = ["pendingPayment","pendingCollection"];

  const isCompletedOrder = "orderStatus" in props.passDataMap ? orderCompleteStatus.includes(props.passDataMap.orderStatus) : false


  useEffect(()=>{

    setInterval(()=>{
      const time = new Date().getTime()
      setTime(time)

    },5000)

  },[])

  console.log(props);
  return (
    <div className="mx-2 my-4">
      <div className={`h-14 grid rounded-xl ${props.bannerStyleMap["backgroundColorClass"]} border-std items-center`}>

        <div className="h-full grid grid-rows-cust-2f grid-cols-cust-2b">
          {props.schemeMap.labelNamesOnCompletion.map((labelName, i) => {
              return (
                <RecordLabelUnit3
                  key={labelName}
                  index={i}
                  textColorScheme={props.bannerStyleMap["color"]}
                  labelName={getDisplayCurrentOrderBannerValue(isCompletedOrder,labelName)}
                  labelValue={getOrderDetailsLabelValue2(
                    getDisplayCurrentOrderBannerValue(isCompletedOrder,props.schemeMap.valueDisplayTypes[props.schemeMap.labelNames.indexOf(labelName)]),
                    props.passDataMap[getDisplayCurrentOrderBannerValue(isCompletedOrder,props.schemeMap.labelDbKeys[props.schemeMap.labelNames.indexOf(labelName)])],
                    props.passDataMap,
                  )}
                />
              );
          })}
        </div>
      </div>
    </div>
  );
}


function getDisplayCurrentOrderBannerValue(orderCompletionStatus,value){

  console.log(value)

  const index = orderCompletionStatus==true ? 1 : 0;

  if(Array.isArray(value)){
    return value[index];
  }

  return value;

}


