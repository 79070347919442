

// export const inpOptionsMap = {
//     storeBranchProvinceNames:inputOptionsMap.storeBranchProvinceName,
//     storeBranchCityeNames:inputOptionsMap.storeBranchCityName
//   }


const applicableCountries =  ["Canada"]
const applicableCurrencies =  ["CAD"]
export const skateMaintenanceJobs =  ["Skate Sharpening","Skate Profiling","Skate Repair"]
const countryCodes = ["+91"]

const skateBrandNames = ["Bauer", "True", "CCM", "Easton", "Graf", "Nike","Others"]

const skateSizeTypes = ["Youth","Junior","Intermediate","Senior"]
const skateSharpeningTypes = ["Manual","Sparx","Inside High","No Preference","Others"]
const skateProfileFamilies = ["Single Radius", "Combination Radius","Zuperior Radius","Quad Radius", "Ellipse","Bandy","Goalie", "Old School","CagOne","Elite Blade Performance","BladeMaster","Other"]
const skateWidths = [
    "F1",
    "F2",
    "F3",
    "D",
    "EE"
]

const  skateHollowRadii = [
    "5/16\"",
    "3/8\"",
    "7/16\"",
    "1/2\"",
    "9/16\"",
    "5/8\"",
    "11/16\"",
    "3/4\"",
    "13/16\"",
    "7/8\"",
    "15/16\"",
    "1\"",
    "1 1/16\"",
    "1 1/8\"",
    "1 3/16\"",
    "1 1/4\"",
    "1 5/16\"",
    "1 3/8\"",
    "1 7/16\"",
    "1 1/2\"",
    "FBV 3/8\"",
    "FBV 1/2\"",
    "FBV 5/8\"",
    "FBV 3/4\""
]


const instituteNames = ["KV College"]
const instituteClassNames = ["I","II","III","IV","V","VI","VII","VIII","IX","X","XI","XII","I Year","II Year"];
const instituteSectionNames = ["A","B","C","D","PCMS","PCMB","PCMC","PCME"];
const humanGenders = ["Male","Female"];
const humanBloodGroups = ["A+","A-","B+","B-","O+","O-","AB+","AB-"];
const courseSubjects = ["Maths","Physics","Chemistry","Biology","English","Hindi","Kannada"];
const examTypes = ["Board","KCET","NEET"]
const academicStartYears = [2024,2025]

export const inpOptionsMap = {
    applicableCountries:applicableCountries,
    applicableCurrencies:applicableCurrencies,
    skateMaintenanceJobs:skateMaintenanceJobs,
    countryCodes:countryCodes,
    skateBrandNames:skateBrandNames,
    skateSharpeningTypes:skateSharpeningTypes,
    skateSizeTypes:skateSizeTypes,
    skateWidths:skateWidths,
    skateHollowRadii:skateHollowRadii,
    skateProfileFamilies:skateProfileFamilies,
    instituteClassNames:instituteClassNames,
    instituteSectionNames:instituteSectionNames,
    humanGenders:humanGenders,
    humanBloodGroups:humanBloodGroups,
    instituteNames:instituteNames,
    courseSubjects:courseSubjects,
    examTypes:examTypes,
    academicStartYears:academicStartYears

   }


   export const inpOptionsKeyAliasMap = {
    studentClass:"instituteClassNames",
    studentSection:"instituteSectionNames",
    studentGender:"humanGenders",
    studentInstituteName:"instituteNames",
    instituteName:"instituteNames",
    studentBloodGroup:"humanBloodGroups",
    examSubject:"courseSubjects",
    subjectName:"courseSubjects",
    className:"instituteClassNames",
    sectionName:"instituteSectionNames",
    sectionNames:"instituteSectionNames",
    examClass:"instituteClassNames",
    examType:"examTypes",
    examInstitute:"instituteNames",
    instituteAcademicYear:"academicStartYears"
  }

  export const keyAliasMap = {
    examClass : "className"
  }


  export const inpDynOptionsParentKeyMap = {
    storeBranchProvinceName:["storeBranchCountryName"],
    storeBranchCityName:["storeBranchCountryName","storeBranchProvinceName"],
    skateSize:["skateSizeType"],
    skateProfileRadius:["skateProfileFamily"],
    studentClass:["studentInstituteName"],
    studentSection:["studentInstituteName","studentClass"],
    examClass:["examInstitute"],
    examType:["examInstitute"]
  }



export const mimeTypeFileTypeCorrelationMap = {
    "image/png":[".png","image/png","image/*"],
    "image/jpg":[".jpg",".jpeg","image/jpg","image/*"],
    "image/jpeg":[".jpg",".jpeg","image/jpg","image/*"],
    "application/pdf":[".pdf","application/pdf","application/*"]
  }
  

  export const shortCodeMap={
    "examType":{
        "KCET" : "K",
        "NEET" : "N",
        "Board": "B"
    },
    "subjectName":{
        "Maths" : "MT",
        "Physics" : "PH",
        "Chemistry": "CY",
        "Biology" : "BI",
        "Kannada" :"KN",
        "English" : "EN",
        "Hindi" : "HD"
    },
    "className":{
        "I Year" : "11",
        "II Year" : "12",
        "I YEAR" : "11",
        "II YEAR" : "12",
        "I": "01",
        "II" : "02",
        "III" :"03",
        "IV" : "04",
        "V" : "05",
        "VI": "06",
        "VII" : "07",
        "VIII" :"08",
        "IX" : "09",
        "X" : "10",
        "XI" : "11",
        "XII" : "12"
    },
    "sectionName":{
        "A" : "A",
        "B" : "B",
        "C" : "C",
        "D" : "D",
        "All" : "Z"
    }
  }



export const inputDepOptionsMap = {
    instituteClassNames:{
        "":[],
        "KV College":["I","II","III","IV"]
    },
    instituteSectionNames:{
        "":[],
        "KV College":{"":[],"I":["A","B","C","D"]}
    },
    skateProfileRadii:{
        // "":[],
        "Single Radius" : [
            "7 foot",
            "8 foot",
            "9 foot",
            "10 ft",
            "10.5 foot",
            "11 ft",
            "12 ft",
            "13 ft",
            "14 ft",
            "15 ft",
            "17 ft",
            "19 ft",
            "21 feet",
            "23 feet"
            ],
        "Combination Radius" : [
            "Detroit 1 (10ft-20Ft)",
            "Detroit 2 (13ft-26ft)",
            "Win 1 (9.5ft-10.5ft)",
            "Win 2 (10ft-11ft)",
            "Combo 10-13ft",
            "Combo 9-10ft",
            "Toronto 1 (7ft-10ft)",
            "Toronto 2 (9ft-15ft)",
            "Toronto 3 (11ft-17ft)"
            ],
        "Zuperior Radius" : [
            "ZUP 3xs (5-9-13ft)",
            "ZUP 2XS (5-10-13ft)",
            "ZUP XS (5-11-13ft)",
            "Zup Small (6-12-20ft)",
            "Zup Med (6-13-20ft)",
            "Zup Large (6-14-20ft)",
            "Zup V (6-17-20ft)"
            ],
        "Quad Radius" : [
            "Quad 3XS (4-5-7-10ft)",
            "Quad 2XS (5-7-9-11ft)",
            "Quad XS (6-8-11-12ft)",
            "Quad Zero (6-9-11-13ft)",
            "Quad 0.5 (8-10-12-14ft)",
            "Quad 1 (6-9-12-15ft)",
            "Quad 2 (7-10-13-16ft)",
            "Quad 1.5 (7-9-13-18ft",
            "Quad 2.5 (7-10-14-20ft)"
            ],
        "Ellipse" : [
            "Ellipse 3xs",
            "Ellipse 2XS",
            "Ellipse XS",
            "Ellipse Zero",
            "Ellipse 1",
            "Ellipse 2"
            ],
        "Bandy" : [
            "Bandy Small (6-15-21ft)",
            "Bandy Med (6-17-21ft)",
            "Bandy Large (6-19-21ft)"
            ],
        "Goalie" : [
            "Goalie Win (24-28ft)",
            "Goalie Sam SR (10ft-50mm-27ft)",
            "Goalie Sam JR (9ft-40mm-25ft)",
            "Goalie Sam Youth (8ft-30mm-22ft)",
            "25 Ft Single Rad",
            "27 Ft Single Rad",
            "29 Ft Single Rad",
            "30 Ft Single Rad",
            "Goalie Ellipse Senior",
            "Goalie Ellipse junior",
            "Goalie Ellipse Youth"
            ],
        "Old School" : [
            "Hudson 1 (8ft-40mm-11ft)",
            "Hudson 2 (8ft-50mm-12ft)",
            "Hudson 3 (8ft-50mm-11ft)",
            "Hudson 4 (8ft-60mm-13ft)",
            "Hudson 5 (9ft-50mm-10.5ft)"
            ],
        "CagOne" : [
            "15-25",
            "15-30",
            "20-35",
            "20-40",
            "25-35",
            "25-40",
            "25-45",
            "30-40",
            "30-45",
            "25-50",
            "30-50",
            "35-50",
            "30-60",
            "35-60",
            "40-60"
            ],
        "Elite Blade Performance" : [
            "7-12 DUO",
            "7-13 DUO",
            "7-14 DUO",
            "8-12 DUO",
            "8-13 DUO",
            "8-14 DUO",
            "9-13 DUO",
            "EL3 QUAD (6-9-11-26)",
            "EL2 Quad (7-10-13-16)",
            "EL1 Quad (6-9-11-13)",
            "SCS-1",
            "SCS-2",
            "SCS-3",
            "40MM CENTER FLAT",
            "50MM CENTER FLAT",
            "60MM CENTER FLAT",
            "70MM CENTER FLAT",
            "80MM CENTER FLAT",
            "30 FOOT",
            "29 FOOT",
            "28 FOOT",
            "28-30FT",
            "26-30FT",
            "24-30FT",
            "22-30FT",
            "24-28FT"
            ],
        "BladeMaster" : [
            "MARK 2",
            "MARK 3",
            "MARK 5",
            "7 FT",
            "9FT",
            "10 FT",
            "11FT",
            "13 FT",
            "28 FT",
            "9-10FT",
            "9-11FT",
            "10-11FT",
            "10-12 FT"
            ],
        
        "Other":["NA"]
    },
    skateSizes:{
        "":[],
        "Youth" : [
            "6Y",
            "6.5Y",
            "7Y",
            "7.5Y",
            "8Y",
            "8.5Y",
            "9Y",
            "9.5Y",
            "10Y",
            "10.5Y",
            "11Y",
            "11.5Y",
            "12Y",
            "12.5Y",
            "13Y",
            "13.5Y"
        ],
        "Junior": [
            "1",
            "1.5",
            "2",
            "2.5",
            "3",
            "3.5"
        ],
        "Intermediate": [
            "4",
            "4.5",
            "5",
            "5.5",
            "6",
            "6.5"
        ],
        "Senior": [
            "7",
            "7.5",
            "8",
            "8.5",
            "9",
            "9.5",
            "10",
            "10.5",
            "11",
            "11.5",
            "12",
            "12.5",
            "13",
            "13.5",
            "14"
        ]
    },
    provinces:{
        "":[],
        "Canada":["Alberta","British Columbia","Manitoba","New Brunswick","Newfoundland And Labrador",
        "Northwest Territories","Nova Scotia","Ontario","Prince Edward Island","Quebec",
        "Saskatchewan","Yukon"]
    },
    cities:{
        "":[],
        "Canada":{
        "Alberta" :[
            "Airdrie"
            ,"Grande Prairie"
            ,"Red Deer"
            ,"Beaumont"
            ,"Hanna"
            ,"St. Albert"
            ,"Bonnyville"
            ,"Hinton"
            ,"Spruce Grove"
            ,"Brazeau"
            ,"Irricana"
            ,"Strathcona County"
            ,"Breton"
            ,"Lacombe"
            ,"Strathmore"
            ,"Calgary"
            ,"Leduc"
            ,"Sylvan Lake"
            ,"Camrose"
            ,"Lethbridge"
            ,"Swan Hills"
            ,"Canmore"
            ,"McLennan"
            ,"Taber"
            ,"Didzbury"
            ,"Medicine Hat"
            ,"Turner Valley"
            ,"Drayton Valley"
            ,"Olds"
            ,"Vermillion"
            ,"Edmonton"
            ,"Onoway"
            ,"Wood Buffalo"
            ,"Ft. Saskatchewan"
            ,"Provost"
            ], 
            "British Columbia" :[
            "Burnaby"
            ,"Lumby"
            ,"City of Port Moody"
            ,"Cache Creek"
            ,"Maple Ridge"
            ,"Prince George"
            ,"Castlegar"
            ,"Merritt"
            ,"Prince Rupert"
            ,"Chemainus"
            ,"Mission"
            ,"Richmond"
            ,"Chilliwack"
            ,"Nanaimo"
            ,"Saanich"
            ,"Clearwater"
            ,"Nelson"
            ,"Sooke"
            ,"Colwood"
            ,"New Westminster"
            ,"Sparwood"
            ,"Coquitlam"
            ,"North Cowichan"
            ,"Surrey"
            ,"Cranbrook"
            ,"North Vancouver"
            ,"Terrace"
            ,"Dawson Creek"
            ,"Tumbler"
            ,"Delta"
            ,"Osoyoos"
            ,"Vancouver"
            ,"Fernie"
            ,"Parksville"
            ,"Invermere"
            ,"Peace River"
            ,"Vernon"
            ,"Kamloops"
            ,"Penticton"
            ,"Victoria"
            ,"Kaslo"
            ,"Port Alberni"
            ,"Whistler"
            ,"Langley"
            ,"Port Hardy"
            ],
            "Manitoba" :[
            "Birtle"
            ,"Flin Flon"
            ,"Swan River"
            ,"Brandon"
            ,"Snow Lake"
            ,"The Pas"
            ,"Cranberry Portage"
            ,"Steinbach"
            ,"Thompson"
            ,"Dauphin"
            ,"Stonewall"
            ,"Winnipeg"
            ], 
            "New Brunswick" :[
            "Cap-Pele"
            ,"Miramichi"
            ,"Saint John"
            ,"Fredericton"
            ,"Moncton"
            ,"Saint Stephen"
            ,"Grand Bay-Westfield"
            ,"Oromocto"
            ,"Shippagan"
            ,"Grand Falls"
            ,"Port Elgin"
            ,"Sussex"
            ,"Memramcook"
            ,"Sackville"
            ,"Tracadie-Sheila"
            ], 
            "Newfoundland And Labrador" :[
            "Argentia"
            ,"Corner Brook"
            ,"Paradise"
            ,"Bishop's Falls"
            ,"Labrador City"
            ,"Portaux Basques"
            ,"Botwood"
            ,"Mount Pearl"
            ,"St. John's"
            ,"Brigus"
            ],
            "Northwest Territories" :[
            "Town of Hay River"
            ,"Town of Inuvik"
            ,"Yellowknife"
            ], 
            "Nova Scotia" :[
            "Amherst"
            ,"Hants County"
            ,"Pictou"
            ,"Annapolis"
            ,"Inverness County"
            ,"Pictou County"
            ,"Argyle"
            ,"Kentville"
            ,"Queens"
            ,"Baddeck"
            ,"County of Kings"
            ,"Richmond"
            ,"Bridgewater"
            ,"Lunenburg"
            ,"Shelburne"
            ,"Cape Breton"
            ,"Lunenburg County"
            ,"Stellarton"
            ,"Chester"
            ,"Mahone Bay"
            ,"Truro"
            ,"Cumberland County"
            ,"New Glasgow"
            ,"Windsor"
            ,"East Hants"
            ,"New Minas"
            ,"Yarmouth"
            ,"Halifax"
            ,"Parrsboro"
            ],
            "Ontario" :[
            "Ajax"
            ,"Halton"
            ,"Peterborough"
            ,"Atikokan"
            ,"Halton Hills"
            ,"Pickering"
            ,"Barrie"
            ,"Hamilton"
            ,"Port Bruce"
            ,"Belleville"
            ,"Hamilton-Wentworth"
            ,"Port Burwell"
            ,"Blandford-Blenheim"
            ,"Hearst"
            ,"Port Colborne"
            ,"Blind River"
            ,"Huntsville"
            ,"Port Hope"
            ,"Brampton"
            ,"Ingersoll"
            ,"Prince Edward"
            ,"Brant"
            ,"James"
            ,"Quinte West"
            ,"Brantford"
            ,"Kanata"
            ,"Renfrew"
            ,"Brock"
            ,"Kincardine"
            ,"Richmond Hill"
            ,"Brockville"
            ,"King"
            ,"Sarnia"
            ,"Burlington"
            ,"Kingston"
            ,"Sault Ste. Marie"
            ,"Caledon"
            ,"Kirkland Lake"
            ,"Scarborough"
            ,"Cambridge"
            ,"Kitchener"
            ,"Scugog"
            ,"Chatham-Kent"
            ,"Larder Lake"
            ,"Souix Lookout CoC Sioux Lookout"
            ,"Chesterville"
            ,"Leamington"
            ,"Smiths Falls"
            ,"Clarington"
            ,"Lennox-Addington"
            ,"South-West Oxford"
            ,"Cobourg"
            ,"Lincoln"
            ,"St. Catharines"
            ,"Cochrane"
            ,"Lindsay"
            ,"St. Thomas"
            ,"Collingwood"
            ,"London"
            ,"Stoney Creek"
            ,"Cornwall"
            ,"Loyalist Township"
            ,"Stratford"
            ,"Cumberland"
            ,"Markham"
            ,"Sudbury"
            ,"Deep River"
            ,"Metro Toronto"
            ,"Temagami"
            ,"Dundas"
            ,"Merrickville"
            ,"Thorold"
            ,"Durham"
            ,"Milton"
            ,"Thunder Bay"
            ,"Dymond"
            ,"Nepean"
            ,"Tillsonburg"
            ,"Ear Falls"
            ,"Newmarket"
            ,"Timmins"
            ,"East Gwillimbury"
            ,"Niagara"
            ,"Toronto"
            ,"East Zorra-Tavistock"
            ,"Niagara Falls"
            ,"Uxbridge"
            ,"Elgin"
            ,"Niagara-on-the-Lake"
            ,"Vaughan"
            ,"Elliot Lake"
            ,"North Bay"
            ,"Wainfleet"
            ,"Flamborough"
            ,"North Dorchester"
            ,"Wasaga Beach"
            ,"Fort Erie"
            ,"North Dumfries"
            ,"Waterloo"
            ,"Fort Frances"
            ,"North York"
            ,"Gananoque"
            ,"Norwich"
            ,"Welland"
            ,"Georgina"
            ,"Oakville"
            ,"Wellesley"
            ,"Glanbrook"
            ,"Orangeville"
            ,"West Carleton"
            ,"Gloucester"
            ,"Orillia"
            ,"West Lincoln"
            ,"Goulbourn"
            ,"Osgoode"
            ,"Whitby"
            ,"Gravenhurst"
            ,"Oshawa"
            ,"Wilmot"
            ,"Grimsby"
            ,"Ottawa"
            ,"Windsor"
            ,"Guelph"
            ,"Ottawa-Carleton"
            ,"Woolwich"
            ,"Haldimand-Norfork"
            ,"Owen Sound"
            ,"York"
            ],
            "Prince Edward Island" :[
            "Alberton"
            ,"Montague"
            ,"Stratford"
            ,"Charlottetown"
            ,"Souris"
            ,"Summerside"
            ,"Cornwall"
            ],
            "Quebec" :[
            "Alma"
            ,"Fleurimont"
            ,"Longueuil"
            ,"Amos"
            ,"Gaspe"
            ,"Marieville"
            ,"Anjou"
            ,"Gatineau"
            ,"Mount Royal"
            ,"Aylmer"
            ,"Hull"
            ,"Montreal"
            ,"Beauport"
            ,"Joliette"
            ,"Montreal Region"
            ,"Bromptonville"
            ,"Jonquiere"
            ,"Montreal-Est"
            ,"Brosssard"
            ,"Lachine"
            ,"Quebec"
            ,"Chateauguay"
            ,"Lasalle"
            ,"Saint-Leonard"
            ,"Chicoutimi"
            ,"Laurentides"
            ,"Sherbrooke"
            ,"Coaticook"
            ,"LaSalle"
            ,"Sorel"
            ,"Laval"
            ,"Thetford Mines"
            ,"Dorval"
            ,"Lennoxville"
            ,"Victoriaville"
            ,"Drummondville"
            ,"Levis"
            ], 
            "Saskatchewan" :[
            "Avonlea"
            ,"Melfort"
            ,"Swift Current"
            ,"Colonsay"
            ,"Nipawin"
            ,"Tisdale"
            ,"Craik"
            ,"Prince Albert"
            ,"Unity"
            ,"Creighton"
            ,"Regina"
            ,"Weyburn"
            ,"Eastend"
            ,"Saskatoon"
            ,"Wynyard"
            ,"Esterhazy"
            ,"Shell Lake"
            ,"Yorkton"
            ,"Gravelbourg"
            ],
            "Yukon" :[
            "Carcross"
            ,"Whitehorse"
            ]
        }
      }
    }



    export const chartOptionsMap = {
        "Hollow Radius" : skateHollowRadii,
        "Profile Radius" : inputDepOptionsMap["skateProfileRadii"]
    }
       



