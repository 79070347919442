
import React, { useEffect, useState } from "react";
import BtnNormal from "./BtnNormal";
import StripListFilterContAdvance from "./StripListFilterContAdvance";
import { getStudentExamMarksDataMapObj } from "./projectFns";


export default function StripListFilterContViewMarks(props) {

  console.log(props)
  const [pageStripValueMapColl,setPageStripValueMapColl] = useState([])


  useEffect(()=>{

    (async() => await functions("initializeData",{pageStripValueMapColl:props.pageStripValueMapColl}))()

  },[props.pageStripValueMapColl])


 async function functions(fnName,data){

  const passMap = props.passMap

    switch(fnName){
        case "initializeData":{

          if(props.stripValueMapSource=="parent"){

            setPageStripValueMapColl([...data.pageStripValueMapColl])

          }else{

            const studentExamMarksObj = await getStudentExamMarksDataMapObj(passMap);

            setPageStripValueMapColl(studentExamMarksObj.allExamMaps)  

        //   const {instituteUid} = passMap;
        //   const {instituteAcademicYear} = passMap
        //   const {className} = passMap
        //   const {sectionName} = passMap
        //   const {studentUid} = passMap

        //   // console.log(instituteUid,instituteAcademicYear,className,sectionName)
        //  const startTimestampOfLastMonth = Timestamp.fromDate(getStartDateOfLastMonth())
        //  const endTimestampOfThisMonth =  Timestamp.fromDate(getEndDateOfThisMonth())


        //  console.log(startTimestampOfLastMonth,endTimestampOfThisMonth)

        //   const queryStatement = query(
        //   collection(db,`institutes/${instituteUid}/examMarks`),
        //   where(`instituteAcademicYear`,"==",instituteAcademicYear),
        //   where("className","==",className),
        //   where("sectionName","==",sectionName),
        //   where("examDate",">=",startTimestampOfLastMonth),
        //   where("examDate","<=",endTimestampOfThisMonth),
        // );

        //   const querySnapshot = await getDocs(queryStatement);


        //   const attendedExamMaps = []; // to hold present student exam marks map
        //   const absentExamMaps = []; // To hold completed orders

        //   const marksInfoMap = {
        //     examConductedCount:querySnapshot.size,
        //     examAttendedCount:0,
        //     avgMarks:0,
        //     avgMarksData:[],
        //     totalMarks:0
        //   }

        //   querySnapshot.forEach((doc) => {

        //     const docData = doc.data();

        //     if(docData.examAttendedStudentUids.includes(studentUid)){
        //       marksInfoMap.examAttendedCount += 1;
        //       attendedExamMaps.push({...docData,examPresentCond:true})
        //       marksInfoMap.totalMarks += docData.studentExamMarksMap[studentUid];
        //     }else{
        //       absentExamMaps.push({...docData,examPresentCond:false})
        //     }

        //   });

        //   const avgMarksData = [marksInfoMap.totalMarks,marksInfoMap.examAttendedCount]
        
        //   marksInfoMap.avgMarksData = [...avgMarksData]
        //   marksInfoMap.avgMarks = marksInfoMap.totalMarks/marksInfoMap.examAttendedCount

                  

        }
    }
  

}

 }



  return (
    <div className={`w-full gap-y-4 pt-4`}>
      {props.stripValueMapSource=="parent" &&
      <div className="flex justify-end py-4 px-2">
                <BtnNormal
                  spClass=""
                  label="Back to Dashboard"
                  color="black"
                  clickHandler={()=>props.backBtnClickHandler()}
                />
      </div> }
      <StripListFilterContAdvance
        schemeMap={props.schemeMap}
        pageAttributeMap={{studentUid:props.passMap.studentUid}}
        pageStripValueMapColl={pageStripValueMapColl}
      />
    </div>
  );
}

