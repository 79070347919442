import React, { Component } from "react";
import RecordLabelUnit from "./RecordLabelUnit";
import ActionButton from "./ActionButton";
import { getStoreLabelValueAdmin, getDate, styleProxyMap } from "../Utils";

export default function RecordBtnStrip3(props) {
  console.log(props);

  // const columnWidth=  props.listTopic=="paidInvoiceAdmin"?`grid-columns-8a`:`grid-columns-8c`
  const columnWidth=props.listTopic=="paidInvoiceAdmin"?`grid-columns-8a`:"pastOrder"?`${styleProxyMap.gridCols["9c"]}`:`grid-columns-8c`


  function checkAndReturnValue(value, labelName) {
    if (labelName == "Date/Time") {
      return getDate(value);
    } else {
      return value;
    }
  }
  return (
    <div className="grid grid-cols-cust-2e mt-1 mx-auto">
      <div className={`title-strip w-full grid mt-1 ${columnWidth} bg-quartenaryLight rounded-sm border border-borderStd`} >
              {props.labelDbKeys.map((dbKey, i) => {
                return (
                  <div className="value-strip-unit text-center text-lightBackText">
                    {getStoreLabelValueAdmin(
                      props.labelNames[i],
                      props.stripValueMap[dbKey],
                      "CAD"
                    )}
                  </div>
                );
              })}
      </div>
      {props.btnLabels.length!=0 &&
      <div className={`grid grid-cols-${props.btnLabels.length} mt-1 px-2`}>
        {props.btnLabels.map((btnLabelName, i) => (
          <button
            className={`btn-strip theme-btn-black`}
            onClick={() =>
              props.btnClickHandler({
                btnType: btnLabelName,
                stripValueMap: props.stripValueMap,
              })
            }
          >
            {btnLabelName}
          </button>
        ))}
  </div>
}



      {/* <div className="m-cust-2 grid grid-cols-2">
        {props.btnLabels.map((btnLabelName, i) => (
          <button
            className="btn-strip"
            onClick={() =>
              props.btnClickHandler({
                btnType: btnLabelName,
                stripValueMap: props.stripValueMap,
              })
            }
          >
            {btnLabelName}
          </button>
        ))}
      </div> */}
    </div>
  );
}

// typeof props.passDataMap[props.labelDbKeys[index]] == "object"
//   ? props.passDataMap[props.labelDbKeys[index]]
//       .toDate()
//       .toDateString()
//   : props.passDataMap[props.labelDbKeys[index]]
