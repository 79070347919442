import React from "react";

export default function RecordLabelUnit(props) {

  const textColor = props.colorScheme=="green" ? "text-accent-green" : "text-secondary-2"

  return (
    <div className="p-2 grid grid-cols-cust-2d bg-primary-400 items-center text-center border-inner-light">
      <div className="text-sm">{props.labelName}</div>
      <div className={`font-medium ${textColor} font-montserrat break-words`}>{props.labelValue}</div>
    </div>
  );
}
