import React from "react";

export default function ActionButton(props) {
  const sizeClass = props.sizeType == "medium" ? "-md" : props.sizeType == "small" ? "-sm" : ""
  const alertLevel = props.alertLevel;
  return (
    <button
     className={`btn-action${sizeClass} btn-alert-${alertLevel}`}
     onClick={()=>props.clickHandler()} >
      {props.btnLabel}
    </button>
  );
}
