import React from "react";


export default function Loader2(props) {
  return (

    <div className="h-full flex flex-center w-full">

    <div className="loader"></div>
    </div>


  );
}
