import React from "react";
import Input from "./Input";
import Switch from "./Switch";

export default function InputRowBlock(props) {
  console.log(props);

  function renderRowBlock(props) {
    console.log(props.inputDataObj);

    return props.passMap.inputNames.map((inputName, i) => {
      if (inputName == "slNo") {
        return (
          <div className="flex justify-center items-center bg-primary-300">
            {`${props.rowIndex + 1}`}
          </div>
        );
      } else if (inputName == "jobNames") {
        return (
          <div className="flex justify-center items-center bg-primary-300">
            {`${props.inputDataObj[inputName][props.rowIndex]}`}
          </div>
        );
      } else if (inputName == "isJobOffered") {
        return (
          <div className="flex justify-center items-center bg-primary-300">
            <Switch
              key={`${inputName}-${props.rowIndex}`}
              inputType={props.passMap.inputTypes[i]}
              inputName={inputName}
              inputValue={props.inputDataObj[inputName][props.rowIndex]}
              onChangeFn={(e) => props.inputChangeFn(e, props.rowIndex,props.passMap.inputTypes[i])}
              opType={props.passMap.opType}
              fieldValue={
                props.passMap.opType == "edit"
                  ? props.passMap.dataMap[inputName][props.rowIndex]
                  : ""
              }
            />
          </div>
        );
      } else {
        console.log(props.passMap.dataMap[inputName][props.rowIndex]);
        return (
          // <InputUnitTile
          //   key={`${inputName}-${props.rowIndex}`}
          //   inputType={props.passMap.inputTypes[i]}
          //   inputName={inputName}
          //   inputValue={props.inputDataObj[inputName][props.rowIndex]}
          //   onChangeFn={(e) => props.inputChangeFn(e, props.rowIndex)}
          //   editStatus={props.inputDataObj["isJobOffered"][props.rowIndex]}
          //   opType={props.passMap.opType}
          //   fieldValue={
          //     props.passMap.opType == "edit"
          //       ? props.passMap.dataMap[inputName][props.rowIndex]
          //       : ""
          //   }
          // />
          <Input
            key={`${inputName}-${props.rowIndex}`}
            inpType={props.passMap.inputTypes[i]}
            inpKey={inputName}
            inpValue={props.inputDataObj[inputName][props.rowIndex]}
            inpMandCond={"mandInpKeys" in props.passMap ? props.passMap.mandInpKeys.includes(inputName) : false}
            inpEditStatus={props.inputDataObj["isJobOffered"][props.rowIndex]}
            regexDomain={props.passMap.inputRegexDomains[i]}
            changeValdCount={(val)=>props.changeValdCount(val)}
            onChangeFn={(e) => props.inputChangeFn(e, props.rowIndex,props.passMap.inputTypes[i])}

          />
        );
      }
    });
  }

  return <React.Fragment>{renderRowBlock(props)}</React.Fragment>;
}
