import React from 'react';
import { BrowserRouter, Route, Routes } from './Utils';
import AccountDeleteRequestScreen from './components/AccountDeleteRequestScreen';
import ChangeEmailScreen from './components/ChangeEmailScreen';
import FirebaseAccountActionHandlingScreen from './components/FirebaseAccountActionHandlingScreen';
import Level1 from './components/Level1';
import LoginScreen from './components/LoginScreen';
import LoginScreenStudent from './components/LoginScreenStudent';
import LoginSelection from './components/LoginSelection';
import PrivacyPolicyScreen from './components/PrivacyPolicyScreen';
import ResetPasswordScreen from './components/ResetPasswordScreen';
import SignUpScreen from './components/SignUpScreen';
import SignUpScreen2 from './components/SignUpScreen2';
import SupportScreen from './components/SupportScreen';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<LoginScreen userMode="store"/>}/> */}
      <Route path="/" element={<LoginSelection userMode="store"/>}/>
      <Route path="/adminLogin" element={<LoginScreen backgroundColor="white" userMode="store"/>}/>
      <Route path="/login" element={<LoginScreenStudent userMode="store"/>}/>
      <Route path="/viveka" element={<LoginScreenStudent userMode="store"/>}/>
      <Route path="/admin" element={<LoginScreen backgroundColor="white" userMode="admin"/>}/>
      <Route path="/signup/" element={<SignUpScreen userMode="store"/>}/>
      <Route path="/signupCustomer/" element={<SignUpScreen2 userMode="store"/>}/>
      <Route path="/accounts/actions/" element={<FirebaseAccountActionHandlingScreen userMode="store"/>}/>
      <Route path="/support/" element={<SupportScreen userMode="store"/>}/>
      <Route path="/account-deletion-request/" element={<AccountDeleteRequestScreen userMode="store"/>}/>
      <Route path="/privacy-policy/" element={<PrivacyPolicyScreen userMode="store"/>}/>
      <Route path="/resetPassword/" element={<ResetPasswordScreen userMode="store"/>}/>
      <Route path="/changeEmail/" element={<ChangeEmailScreen userMode="store"/>}/>
      <Route path="/dashboard//*" element={<Level1/>}/>
    </Routes>
</BrowserRouter>
  );
}

export default App;
