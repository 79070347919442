
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { functions, httpsCallable } from "../Utils.js";
import InputTile2 from './InputTile2';
import LoadSpinner from './LoadSpinner.jsx';
import ModalBoxMessagePrompt from './ModalBoxMessagePrompt';


export default function ChangeEmailScreen(props){

    const navigate = useNavigate();
    const location = useLocation()

    const [newEmail,setNewEmail] = useState("")
    const [modalMap, setModalMap] = useState({});
    const [showPrompt, setShowPrompt] = useState(false);
    const [promptMap, setPromptMap] = useState({});
    
    const [showSpinner, setShowSpinner] = useState(false);


    console.log(location)




    function inputChangeFn(event) {

          setNewEmail(event.target.value);
        
      }
    
    
    
    async function requestChangeEmail(email){

      setShowSpinner(true)

      const payload = {
        oldEmail:location.state.userEmail,
        email:email,
      }

      const cloudFnCall = httpsCallable(functions,"changeUserEmail");

      try{
        
        const result = await cloudFnCall(payload);
        displayPrompt(result.data)

      }catch(error){
        displayPrompt(error)
      }
      
      setShowSpinner(false) 
    }


    function displayPrompt(message,title,status){
      setShowSpinner(false)
      setShowPrompt(true);
      setPromptMap({
        status: status ? status : false,
        title:title ? title : "Info",
        message:message
      })
    }
  

 return(

        <div className="h-screen grid grid-rows-cust-3g bg-secondary-light-transparent justify-center">
        <div className="h-full flex items-center justify-center">
          <span className="text-5xl">CHANGE EMAIL</span>
        </div>
        <div className="flex flex-col gap-8 ">
          <div className="flex gap-4 bg-cust-white py-2 px-4 rounded text-secondary">
            <div className="text-sm">CURRENT EMAIL</div>
            <div className="text-sm">{location.state.userEmail}</div>
         </div>

         <InputTile2 
         inputLabel="New Email"
         inputName="newEmail" 
         inputValue={newEmail} 
         inputType="email" 
         onChangeFn={(e)=>inputChangeFn(e)}/>
        
          {/* <label className="text-sm">NEW EMAIL</label>
                <input
                  className="m-0 rounded-sm"
                  type="email"
                  name="email"
                  onChange={(event) => handleChange(event)}
                ></input>  */}

          <button
            onClick={() => requestChangeEmail(newEmail)}
            className="btn-std hover-black mx-auto mt-16 bg-primary-600 text-primary-100"
          >
            Submit
          </button>  
        </div>

        <div className="flex justify-center items-center">
            {props.userMode=="store" &&
            <div className="flex">
              <button
                onClick={() => navigate("/")}
                className="btn-std hover-black"
              >
                Back to Login
              </button>
            </div>
            }
         </div>
         {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
         {showSpinner && <LoadSpinner />}
      </div>
 
 
 );
 





}


